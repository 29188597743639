import React, { Fragment } from "react";
import { Row, Col } from "antd";

const Description = ({ description }) => {
  return (
    <Fragment>
      <Row className="sideItem">
        <Col span={24}>
          <h2 className="timelineAboutpadding">Description</h2>
          <p
            className="timelinedesc overflowThumb"
            style={{
              maxHeight: 250,
              overflowY: "auto",
              padding: "0px 0.5rem"
            }}>
            {description?.length > 0 ? description : `Description not found or doesn't exist`}
          </p>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Description;
