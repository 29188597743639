import { Popover, Grid } from "antd";
import React from "react";
import { BsBookmarkStar, BsBookmarkStarFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { TogglePlayerMarkedEvents } from "../../Config/API/events";
import { setEVENT } from "../../Store/Features/EventSlice";
import { Info } from "../Alerts/Message";
import useVariables from "../hooks/useVariables";

const { useBreakpoint } = Grid;

function BookmarkEvent({ style = {} }) {
  const { PID, FLAG } = useVariables();
  const { id } = useParams();
  const dispatch = useDispatch();
  const event = useSelector((state) => state.event.event);
  const breakpoints = useBreakpoint();

  // const organizerToggleapi = async (flag) => {
  //   try {
  //     const values = { eventId: id, organizerId: PID };
  //     if (flag) values["flag"] = true;
  //     const res = await ToggleOrgMarkedEvents(values);
  //     const data = await res.json();
  //     if (data.type === "success") dispatch(setEVENT(data.result));
  //     else {
  //       Info(data.result);
  //     }
  //     console.log(data);
  //   } catch (e) {
  //     console.log();
  //   }
  // };
  const playerToggleapi = async (flag) => {
    try {
      const values = { eventId: id, playerId: PID };
      if (flag) values["flag"] = true;
      const res = await TogglePlayerMarkedEvents(values);
      const data = await res.json();
      if (data.type === "success") dispatch(setEVENT(data.result));
      else {
        Info(data.result);
      }
      console.log(data);
    } catch (e) {
      console.log();
    }
  };
  const condition = !breakpoints["md"] && !breakpoints["sm"] && !breakpoints["xs"];
  const styles = {
    cursor: "pointer",
    color: "var(--blue)",
    fontSize: "1.2rem",
    position: "relative",
    top: ".6rem",
    ...style
  };

  return (
    <Popover title={condition ? (event?.marked ? "Remove Bookmark" : "Bookmark") : null}>
      {FLAG === "organizer" ? null : event?.marked ? (
        // (
        //   event?.marked ? (
        //     <BsBookmarkStarFill onClick={() => organizerToggleapi(true)} style={styles} />
        //   ) : (
        //     <BsBookmarkStar
        //       onClick={() => organizerToggleapi()}
        //       style={{ ...styles, color: "red" }}
        //     />
        //   )
        // )
        <BsBookmarkStarFill onClick={() => playerToggleapi(true)} style={styles} />
      ) : (
        <BsBookmarkStar onClick={() => playerToggleapi()} style={{ ...styles, color: "red" }} />
      )}
    </Popover>
  );
}

export default BookmarkEvent;
