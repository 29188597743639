import { Button } from "antd";
import React from "react";

function MatchTeam({ t, draw, item, lose, loading, Orderloading, assignWinner }) {
  const classes = "btnMobileDimensions draftbutton equalWidhtButton m-0";

  return (
    <div className="MatchCardTeamInfo">
      <div>
        {t === 1 ? item?.match?.team1?.name : item?.match?.team2?.name}
        {((t === 1 && lose === "t2") || (t === 2 && lose === "t1")) && !draw ? (
          <p style={{ color: "var(--green)" }}>(Winner)</p>
        ) : null}
      </div>

      {draw || (t === 1 && lose === "t1") || (t === 2 && lose === "t2") ? (
        <Button
          loading={loading}
          type="primary"
          onClick={() => {
            if (!Orderloading && !loading) {
              assignWinner(item, t);
            }
          }}
          className={`${classes}  ${Orderloading ? "GREY" : undefined} `}>
          {loading ? "" : "Assign Winner"}
        </Button>
      ) : (
        <Button
          loading={loading}
          type="primary"
          style={{ opacity: "0", userSelect: "none" }}
          className={`${classes}  ${Orderloading ? "GREY" : undefined} `}>
          Assign Winner
        </Button>
      )}
    </div>
  );
}

export default MatchTeam;
