import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import UploadPostcard from "../UploadPostcard";
import { error } from "../../Alerts/Message";
import { KEYS } from "../../../Config/Keys";
import Loader from "../Loader";
import { useSelector } from "react-redux";
import { PROFILE } from "../../../Store/Features/ProfileSlice";
import { useCurrentPage } from "../../hooks/useCurrentPage";

import { EditPostWithMedia, EditPostWithoutMedia } from "../../../Config/API/posts";

function EditModal({ setPOSTS, data, setopen, open }) {
  const [text, setText] = useState("");
  const [uploading, setuploading] = useState(false);
  const [uploadingMedia, setuploadingMedia] = useState("");
  const [initialLoading, setinitialLoading] = useState(false);
  const [isFileRemoved, setIsFileRemoved] = useState(false);

  const [IMAGE, setimage] = useState({
    file: {},
    type: "image",
    preview: ""
  });
  const [VIDEO, setvideo] = useState({
    file: {},
    type: "video",
    preview: ""
  });
  const [Luploading, setLuploading] = useState(false);

  var id = useSelector(PROFILE);
  id = id?._id;
  const { isTeam, isPanel } = useCurrentPage();

  const onPost = async () => {
    const err = "Add Something before updating post";
    try {
      let user = "";
      let file = null;
      let vals = new FormData();
      let values = {
        text: text,
        id: data?._id
      }; // for post without media

      const checkfile = () => {
        if (
          IMAGE?.preview &&
          Object?.keys(IMAGE).indexOf("file") > -1 &&
          Object?.keys(IMAGE.file).length !== 0
        ) {
          file = IMAGE.file;
        } else if (
          VIDEO?.preview &&
          Object?.keys(VIDEO).indexOf("file") > -1 &&
          Object?.keys(VIDEO.file).length !== 0
        ) {
          file = VIDEO.file;
        }
        if (String(text).trim().length === 0 && !IMAGE?.preview && !VIDEO?.preview)
          throw new Error(err);
      };
      await checkfile();

      if (file !== null) vals.append("fileType", uploadingMedia);
      if (file !== null) vals.append("file", file);
      vals.append("text", text);
      vals.append("id", data?._id);
      setinitialLoading(true);

      user = id;
      if (isTeam) {
        vals.append("user", user);
        vals.append("userRole", "Team");
        vals.append("flag", "teamTimeline");

        values["user"] = user;
        values["userRole"] = "Team";
        values["flag"] = "teamTimeline";
      } else if (isPanel) {
        vals.append("user", user);
        vals.append("userRole", "Panel");
        vals.append("flag", "panelTimeline");

        values["user"] = user;
        values["userRole"] = "Panel";
        values["flag"] = "panelTimeline";
      }

      if (user && data?._id) {
        if (file === null && !isFileRemoved) {
          console.log("without media", values);
          const res = await EditPostWithoutMedia(values);
          const d = await res.json();
          setPOSTS(d?.data);
        } else if (file !== null || isFileRemoved) {
          console.log("with media");
          const res = await EditPostWithMedia(vals);
          const d = await res.json();
          setPOSTS(d?.data);
        }
        setuploading(false);
        setinitialLoading(false);
        setopen(false);
      } else {
        error("some fields are missing refresh or relogin");
      }
      setinitialLoading(false);
      setopen(false);
    } catch (e) {
      error(String(e.message));
      console.log(e.message);
      setinitialLoading(false);
      if (e.message !== err) setopen(false);
    }
  };
  useEffect(() => {
    if (!data || data === undefined || data === null) {
      setinitialLoading(true);
    } else {
      setText(data?.text);
      if (data?.file?.type !== "") {
        if (data?.file?.type === "image") {
          setuploadingMedia("image");
          setimage({
            file: {},
            type: "image",
            preview: KEYS.API + "/" + data?.file?.file
          });
        }
        if (data?.file?.type === "video") {
          setuploadingMedia("video");
          setvideo({
            file: {},
            type: "video",
            preview: KEYS.API + "/" + data?.file?.file
          });
        }
      }
      setinitialLoading(false);
    }
    setinitialLoading(false);
    return () => {
      setimage(null);
      setvideo(null);
      setuploading(null);
      setuploadingMedia(null);
      setinitialLoading(null);
      setText(null);
      setLuploading(null);
    };
  }, [data]);
  useEffect(() => {
    if (uploadingMedia === "video") {
      setimage({
        file: {},
        type: "image",
        preview: ""
      });
    } else if (uploadingMedia === "image") {
      setvideo({
        file: {},
        type: "video",
        preview: ""
      });
    } else if (uploadingMedia === "error") {
      setimage({
        file: {},
        type: "image",
        preview: ""
      });
      setvideo({
        file: {},
        type: "video",
        preview: ""
      });
    }
  }, [uploadingMedia]);
  if (!open) {
    return null;
  }
  return (
    <Modal
      title="Edit Post"
      className="editPost"
      confirmLoading
      mask
      maskClosable
      onCancel={() => {
        setopen(false);
      }}
      closable={false}
      centered
      destroyOnClose={true}
      visible={open}
      footer={false}>
      {initialLoading ? (
        <Loader noShadow={true} />
      ) : (
        <UploadPostcard
          setIsFileRemoved={setIsFileRemoved}
          edit="EditModalPost"
          setuploading={setuploading}
          user={data?.user}
          IMAGE={IMAGE}
          setimage={setimage}
          VIDEO={VIDEO}
          setvideo={setvideo}
          loading={uploading}
          border
          onPost={onPost}
          setText={setText}
          text={text}
          uploadingMedia={uploadingMedia}
          setuploadingMedia={setuploadingMedia}
          Luploading={Luploading}
          setLuploading={setLuploading}
        />
      )}
    </Modal>
  );
}

export default EditModal;
