import { Row, Col, Typography } from "antd";
import React, { Fragment } from "react";

const TestimonialCard = () => {
  return (
    <Fragment>
      <Row className="testimonialCardRow">
        <Col lg={8} md={24} xs={24}>
          <img className="testimonialCardImg" src="/images/pic1.jpg" alt="img" />
        </Col>
        <Col lg={16} md={24} xs={24}>
          <div className="testimonialcardtext">
            <p>May 8, 2020</p>
            <h1 style={{ fontSize: "25px" }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </h1>
            <p></p>
            <Typography>
              <Typography.Paragraph
                style={{
                  maxWidth: "95%",
                  textAlign: "justify",
                  maxHeight: "100%"
                }}
                ellipsis={{ rows: 15 }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec
                turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat
                duis diam lacus arcu. Faucibus venenatis felis id augue sit cursus pellentesque enim
                arcu. Elementum felis magna pretium in tincidunt. Suspendisse sed magna eget nibh in
                turpis. Consequat duis diam lacus arcu.Lorem ipsum dolor sit amet, consectetur
                adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse
                sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus venenatis
                felis id augue sit cursus pellentesque enim arcu. Elementum felis magna pretium in
                tincidunt. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus
                arcu.
              </Typography.Paragraph>
            </Typography>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

export default TestimonialCard;
