import React from "react";
import { Route, Routes } from "react-router-dom";
import PlayerEventsHistory from "./OrganizerEventsHistory";
import OrganizerTLayout from "../../layout/organizerTLLayout/OrganizerTLayout";
import "../../../styles/organizer/TimelineOrganizer.css";

const OrganizerEventsHistoryWrapper = () => {
  return (
    <Routes>
      <Route path="/" element={<OrganizerTLayout />}>
        <Route index path="/" element={<PlayerEventsHistory />} />
      </Route>
    </Routes>
  );
};

export default OrganizerEventsHistoryWrapper;
