import { PanelProfileToggleApi } from "../../../../Config/API/profiles";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { success, error } from "../../../../common/Alerts/Message";
import { PROFILE, setProfile } from "../../../../Store/Features/ProfileSlice";
import { useDispatch, useSelector } from "react-redux";

export const useEditProfile = () => {
  const [loading, setloading] = useState();
  const { id } = useParams();
  const dispatch = useDispatch();
  const profile = useSelector(PROFILE);

  const request = async (res, data, values) => {
    setloading(true);

    res = await PanelProfileToggleApi(values);
    data = await res.json();
    if (data?.type === "success") {
      dispatch(
        setProfile({
          profileId: data?.data?.profile,
          ...data?.data
        })
      );
      success(data?.result);
      console.log(data);
    } else {
      error(data?.result);
    }
    setloading(false);
  };

  const ChangeProfileStatus = async (e) => {
    try {
      var values = {
          id: id,
          profileType: e
        },
        res = null,
        data = null;
      if (e) request(res, data, values, "panel");
    } catch (e) {
      error(e?.message);
      setloading(false);
    }
  };
  return {
    loading,
    setloading,
    ChangeProfileStatus,
    profile
  };
};
