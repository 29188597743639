import React from "react";
import { Menu, Dropdown } from "antd";
import { BsThreeDots } from "react-icons/bs";
import { useSelector } from "react-redux";
import { PROFILE } from "../../../../Store/Features/ProfileSlice";
import useVariables from "../../../hooks/useVariables";
function EditDots({ Editing, Post, data }) {
  const { PID } = useVariables();
  const admin = useSelector(PROFILE);
  return PID
    ? // Editing && (data?.user?._id === id || admin?.profileId === id) &&
      Editing && (data?.user?._id === PID || admin?.profileId === PID) && (
        <div className="threeDots" style={{ padding: 0 }}>
          <Dropdown overlay={<Menu items={Post} />} trigger={["click"]}>
            <a className={`ant-dropdown-link Nav_a`}>
              <BsThreeDots style={{ marginLeft: "0" }} />
            </a>
          </Dropdown>
        </div>
      )
    : null;
}

export default EditDots;
