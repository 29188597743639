// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Container {
}
.Container.paddX {
  padding-inline: 7.5%;
}
.Container.paddY {
  padding-block: 1.8%;
}
@media screen and (max-width: 576px) {
  .Container.paddY {
    padding-block: 5%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/Landing/Container.css"],"names":[],"mappings":"AAAA;AACA;AACA;EACE,oBAAoB;AACtB;AACA;EACE,mBAAmB;AACrB;AACA;EACE;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".Container {\n}\n.Container.paddX {\n  padding-inline: 7.5%;\n}\n.Container.paddY {\n  padding-block: 1.8%;\n}\n@media screen and (max-width: 576px) {\n  .Container.paddY {\n    padding-block: 5%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
