import React, { useMemo, useRef, useState } from "react";
import debounce from "lodash/debounce";
import { Select, Spin } from "antd";
import { FaSearch } from "react-icons/fa";
const suffix = (
  <FaSearch
    style={{
      fontSize: 16,
      color: "gray"
    }}
  />
);
function DebounceSelect({ debounceTimeout = 800 }) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState([]);

  const fetchRef = useRef(0);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchUserList(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }
        setOptions(
          newOptions.map((e) => {
            return {
              label: (
                <div>
                  <img alt="" src={e.image} />
                  <span>{e.label}</span>
                </div>
              ),
              value: e.value
            };
          })
        );
        setFetching(false);
      });
    };
    return debounce(loadOptions, debounceTimeout);
  }, [debounceTimeout]);
  return (
    <div className={"homeSeachBar"}>
      <Select
        labelInValue
        mode={"multiple"}
        dropdownClassName="homeSeachbarDropdown"
        placeholder="Explore"
        filterOption={false}
        suffixIcon={suffix}
        showArrow
        onSearch={debounceFetcher}
        notFoundContent={fetching ? <Spin size="medium" /> : null}
        value={value}
        options={options}
        onChange={(newValue) => {
          setValue(newValue);
        }}
      />
    </div>
  );
}

// Usage of DebounceSelect

async function fetchUserList(username) {
  console.log("fetching user", username);
  return fetch("https://randomuser.me/api/?results=5")
    .then((response) => response.json())
    .then((body) =>
      body.results.map((user) => ({
        label: `${user.name.first} ${user.name.last}`,
        value: user.login.username,
        image: "/images/pic1.jpg"
      }))
    );
}

export default DebounceSelect;
