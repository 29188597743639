import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { FaCalendarAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
// import { Info } from "../../../../../../../common/Alerts/Message";
import { MATCHES, setMatches } from "../../../../../../../Store/Features/MatchesSlice";
import MatchesCard from "../../../cards";

function DraggableFixtures({ items, setSwappedEle, SwappedEle, copy, setcopy, sortedpoints }) {
  const [loading, setLoading] = useState(false); //temp button loading
  const [draggedElemnt, setDraggedElement] = useState(false); //temp drag ele index
  const [datetime, setDateAndtime] = useState([]); // copy of date and time
  const Matches = useSelector(MATCHES);
  const dispatch = useDispatch();

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const arr = SwappedEle?.ele;
    if (!arr.includes(result?.source?.index)) arr?.push(result.source.index);

    if (!arr.includes(result.destination.index)) arr?.push(result.destination.index);

    const itemsData = reorder(items, result.source.index, result.destination.index);

    // const check = (i, s, e) => {
    //     return (i === s && String(e?.match?.win).length > 1) ? true : false
    // }
    // const find = items?.find((e, i) => check(i, result.source.index, e) || check(i, result.destination.index, e))

    // if (find)
    //     return Info("Can't Swap match which is already played")

    setLoading(false);
    setDraggedElement(false);

    var swappedarr = [];
    let found = 0;
    itemsData?.forEach((e, i) => {
      if (e?._id !== copy[i]?._id) {
        var temp = { ...copy[i] };
        temp["match"] = e?.match;
        swappedarr.push(temp);
        found = 1;
      } else swappedarr.push(e);
    });
    if (found === 1) {
      setSwappedEle({
        status: "idle",
        ele: arr
      });
      setcopy(swappedarr);
      dispatch(setMatches({ ...Matches, matchesSlot: swappedarr }));
    }
  };
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    let temp;
    temp = result[startIndex];
    result[startIndex] = result[endIndex];
    result[endIndex] = temp;
    return result;
  };

  useEffect(() => {
    setDateAndtime(
      items?.map((e) => {
        return { start: e?.start, end: e?.end };
      })
    );
    return () => {
      setDateAndtime([]);
    };
  }, []);
  const getdate = (item) =>
    moment(item?.start).format("MMM") + " " + moment(item?.start)?.date() + " AT ";

  const gettime = (item) => {
    let time = "";
    let i = String(item?.start);
    let temp = i.slice(i.indexOf("T") + 1, i.indexOf("T") + 6);
    time = temp + " - ";
    i = String(item?.end);
    temp = i.slice(i.indexOf("T") + 1, i.indexOf("T") + 6);
    return (time += temp);
  };
  if (datetime?.length === 0) return null;

  return (
    <DragDropContext
      onDragStart={(e) => {
        setLoading(true);
        setDraggedElement(e?.source.index);
      }}
      onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {copy.map((item, index) => (
              <Fragment key={index}>
                <p
                  style={{
                    fontSize: "12px",
                    color: "red",
                    margin: "15px 0px 0px 5px"
                  }}>
                  <FaCalendarAlt />
                  <span style={{ fontWeight: "550", margin: "0 .3rem 0 .7rem" }}>
                    {getdate(datetime[index])}
                  </span>
                  <span style={{ color: "#129B30", fontWeight: "550" }}>
                    <span> {gettime(datetime[index])}</span>
                  </span>
                </p>
                {loading && draggedElemnt === index ? (
                  <div
                    style={{
                      opacity: "1",
                      zIndex: "-10",
                      userSelect: "none",
                      minHeight: "120px"
                    }}
                  />
                ) : null}
                <Draggable key={index} draggableId={index.toString()} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}>
                      <MatchesCard
                        Orderloading={loading}
                        item={item}
                        SwappedEle={SwappedEle}
                        index={index}
                        sortedpoints={sortedpoints}
                      />
                    </div>
                  )}
                </Draggable>
              </Fragment>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default DraggableFixtures;
