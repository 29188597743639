import React, { Fragment, forwardRef } from "react";
import FeedCard from "./FeedCard";

function ShowCards({ data, index, POSTS, setPOSTs }, ref) {
  return (
    <Fragment>
      {/* {data?.organizer && <EventCard data={data} />} */}
      {data && <FeedCard ref={ref} data={data} index={index} POSTS={POSTS} setPOSTs={setPOSTs} />}
    </Fragment>
  );
}

export default forwardRef(ShowCards);
