import React from "react";
import { Fragment } from "react";

function index({
  noFollowers,
  noFollowings,
  noMembers,
  noShadow,
  noPanel,
  noMedia,
  noEvent,
  noTeam,
  noText,
  width
}) {
  return (
    <div
      className="NoDataWrapper"
      style={{
        boxShadow: noShadow ? "unset" : undefined,
        height: noShadow ? "unset" : undefined,
        gap: noShadow ? "10px" : undefined,
        padding: noShadow ? "0px" : undefined,
        margin: noShadow ? "0px" : undefined
      }}>
      <Fragment>
        <img
          style={{
            height: noShadow && !width ? "50px" : undefined,
            width: width ? width : undefined,
            objectFit: width ? "contain" : undefined
          }}
          src="/images/empty.png"
          alt="img"
          className="noDataimg"
        />
        {noText ? null : (
          <h2
            style={{
              fontSize: noShadow ? "smaller" : undefined
            }}>
            {noFollowers && "No Followers Yet"}
            {noFollowings && "No Followings Yet"}
            {!noFollowings &&
              !noFollowers &&
              !noMembers &&
              !noMedia &&
              !noTeam &&
              !noPanel &&
              !noEvent &&
              "No Posts Yet"}
            {noTeam && "No Teams Yet!"}
            {noPanel && "No Panels Yet!"}
            {noMembers && " No Members Yet!"}
            {noMedia && " No Media Files Yet"}
            {noEvent && " No Event Yet"}
          </h2>
        )}
      </Fragment>
    </div>
  );
}

export default index;
