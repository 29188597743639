import { KEYS } from "../Keys";

export const GetInboxData = async (values) => {
  const response = await fetch(KEYS.API + "/chat/getinbox", {
    // signal: controller.signal,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify({ id: values })
  });
  return response;
};
export const GetMessagesData = async (values) => {
  const response = await fetch(KEYS.API + "/chat/getinboxmessages", {
    // signal: controller.signal,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify({ room: values })
  });
  return response;
};

export const GetDiscussionMessages = async (values) => {
  const response = await fetch(KEYS.API + "/chat/get-discussions", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};
