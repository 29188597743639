import React from "react";
import { Row, Col } from "antd";
import FadeInImage from "../../Images/FadeInImage";
import Loader from "../../cards/Loader";
import { KEYS } from "../../../Config/Keys";
import NoData from "../../cards/noData";
function MediaImageTab({ loading, MEDIA }) {
  const isimg = MEDIA?.find((e) => e?.file?.type === "image");
  return (
    //  Image Card Takes a prop "src"
    // Either provide it or render default
    <Row justify="">
      {!loading ? (
        isimg ? (
          MEDIA?.map(
            (e, i) =>
              e?.file?.type === "image" && (
                <Col key={i} className="mediaCol" sm={11} xs={24} md={11} lg={11} xl={6}>
                  <FadeInImage
                    preview
                    type={"media"}
                    className="MeduaHeight"
                    src={KEYS?.API + "/" + e?.file?.file}
                  />
                </Col>
              )
          )
        ) : (
          <Loader />
        )
      ) : (
        <Col style={{ paddingBlock: "10px" }} span={24}>
          <NoData noMedia />
        </Col>
      )}
    </Row>
  );
}

export default MediaImageTab;
