import {
  AssignKnockOutWinner,
  ReAssignKnockOutWinner
} from "../../../../../../../Config/API/events";
import { error, success } from "../../../../../../../common/Alerts/Message";
import {
  setRoundes,
  load,
  unload,
  ROUNDES
} from "../../../../../../../Store/Features/kobracketslice";
import { Seed, SeedItem, SeedTeam } from "react-brackets";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { Fragment } from "react";
import { Button } from "antd";
import moment from "moment";
import { useState } from "react";
import { Truncate } from "../../../../../../../common/Text/Truncate";
import { useContext } from "react";

export const useKSEED = () => {
  const [edit, setedit] = useState([]);
  const { id } = useParams();
  const rounds = useSelector(ROUNDES);
  const dispatch = useDispatch();

  const assignbracketswinner = async (M_id, T_id) => {
    try {
      dispatch(load());
      var response = null,
        data = null,
        values = null;
      values = {
        eventId: id,
        matchId: M_id,
        winner: T_id
      };
      console.log(values);

      response = await AssignKnockOutWinner(values);
      data = await response.json();
      if (data?.type === "success") {
        success("winner Assigned Successfully!");
        dispatch(setRoundes(data?.result));
      } else {
        error(data?.result);
      }
      dispatch(unload());
    } catch (e) {
      dispatch(unload());
      console.log(e);
      error(e.message);
    }
  };

  const Reassignbracketswinner = async (M_id, T_id, reassign) => {
    try {
      dispatch(load());
      var response = null,
        data = null,
        values = null;
      values = {
        eventId: id,
        matchId: M_id,
        winner: T_id,
        reassign
      };

      response = await ReAssignKnockOutWinner(values);
      data = await response.json();
      console.log(data);
      if (data?.type === "success") {
        success("winner Assigned Successfully!");
        dispatch(setRoundes(data?.result));
        setedit(edit.filter((e) => e !== M_id));
      } else {
        error(data?.result);
      }
      dispatch(unload());
    } catch (e) {
      dispatch(unload());
      console.log(e);
      error(e.message);
    }
  };

  const SeedObjectContext = React.createContext();

  const SeedObjectsProvider = ({ seed, children }) => {
    const team1 = seed?.match?.team1;
    const team2 = seed?.match?.team2;
    const M_id = seed?.match?._id;

    const winner = seed?.match?.win;

    const includes1 = winner === team1?._id && team1 !== undefined;
    const includes2 = winner === team2?._id && team2 !== undefined;

    const isWinner = includes1 || includes2;
    const notshowButtons = isWinner || !team1 || !team2;
    const isLast = rounds?.length === seed?.roundIndex;
    const isEditable = true;

    const addClass1 = () => {
      assignbracketswinner(M_id, team1?._id);
    };
    const addClass2 = () => {
      assignbracketswinner(M_id, team2?._id);
    };
    const reassign1 = () => {
      Reassignbracketswinner(M_id, team2?._id, team1?._id);
    };
    const reassign2 = () => {
      Reassignbracketswinner(M_id, team1?._id, team2?._id);
    };

    const object = {
      end: seed?.end,
      start: seed?.start,
      notshowButtons,
      isEditable,
      addClass1,
      addClass2,
      reassign1,
      reassign2,
      includes1,
      includes2,
      isWinner,
      isLast,
      winner,
      team1,
      team2,
      M_id
    };
    return (
      <SeedObjectContext.Provider value={{ ...object }}>{children}</SeedObjectContext.Provider>
    );
  };

  // seed Teams component Wrapper ,parent of 2 teams
  const SeedTeamsComponent = () => {
    const {
      M_id,
      team1,
      team2,
      isLast,
      includes1,
      includes2,
      isWinner,
      addClass1,
      addClass2,
      notshowButtons
    } = useContext(SeedObjectContext);

    if (edit?.includes(M_id)) return null;
    return (
      <div className="seedItemTeamWrapper">
        {/* {children} */}

        <SeedTeamWrapper
          team={team1}
          isLast={isLast}
          condw={includes1}
          condl={includes2}
          isWinner={isWinner}
          callback={addClass1}
          condb={!notshowButtons}
        />
        <SeedTeamWrapper
          team={team2}
          isLast={isLast}
          condw={includes2}
          condl={includes1}
          isWinner={isWinner}
          callback={addClass2}
          condb={!notshowButtons}
          condition={team2 === "loser"}
        />
      </div>
    );
  };

  // single teams ,child
  const SeedTeamWrapper = ({
    team,
    condw,
    condl,
    condb,
    isLast,
    callback,
    isWinner,
    condition
  }) => {
    const classes = "btnMobileDimensions draftbutton equalWidhtButton m-0";
    if (condition) return null;
    return (
      <SeedTeam className="seedTeam">
        <span>{team?.name || "T.B.D"}</span>
        <span style={{ display: isLast ? "none" : undefined }}>
          {condb ? (
            <Button onClick={callback} className={classes} type="primary">
              winner
            </Button>
          ) : null}
          <Fragment>
            {isWinner !== undefined ? (
              <Fragment>
                {condw ? (
                  <span
                    style={{
                      color: "var(--green)",
                      fontSize: "smaller",
                      fontWeight: "900",
                      marginRight: "3px"
                    }}>
                    (Winner)
                  </span>
                ) : null}
                {condl ? (
                  <span
                    style={{
                      color: "var(--red)",
                      fontSize: "smaller",
                      fontWeight: "900",
                      marginRight: "3px"
                    }}>
                    (Loser)
                  </span>
                ) : null}
              </Fragment>
            ) : null}
          </Fragment>
        </span>
      </SeedTeam>
    );
  };

  // seed Edit Teams component Wrapper ,parent of 2 teams
  const SeedTeamsEditComponent = () => {
    const { M_id, team1, team2, includes1, includes2, reassign1, reassign2 } =
      useContext(SeedObjectContext);

    if (!edit?.includes(M_id)) return null;
    return (
      <div className="seedItemTeamWrapper">
        {/* {children} */}
        <SeedEditTeamWrapper team={team1} condw={includes1} callback={reassign1} />
        <SeedEditTeamWrapper
          team={team2}
          condw={includes2}
          callback={reassign2}
          condition={team2 === "loser"}
        />
      </div>
    );
  };

  //edit single team ,child
  const SeedEditTeamWrapper = ({ team, condw, callback, condition }) => {
    const classes = "btnMobileDimensions draftbutton equalWidhtButton m-0";
    if (condition) return null;
    return (
      <SeedTeam className="seedTeam">
        <span>{team?.name || "T.B.D"}</span>
        <span>
          {condw ? null : (
            <Button onClick={callback} className={classes} type="primary">
              winner
            </Button>
          )}
          <Fragment>
            {condw ? (
              <span
                style={{
                  color: "var(--green)",
                  fontSize: "smaller",
                  fontWeight: "900",
                  marginRight: "3px"
                }}>
                (Winner)
              </span>
            ) : null}
          </Fragment>
        </span>
      </SeedTeam>
    );
  };

  // SeedItem Date
  const SeedDateComponent = () => {
    const { start, team2 } = useContext(SeedObjectContext);
    return (
      <div
        style={{
          opacity: team2 === "loser" ? "0" : "1"
        }}
        className="seedItemDate">
        {team2 !== "loser"
          ? new Date(start).toDateString() +
            "  " +
            new Date(start).toLocaleTimeString() +
            " - " +
            moment(start).fromNow()
          : moment(new Date()).fromNow()}
      </div>
    );
  };

  // toggle edit button callback
  const handleedit = (M_id) => {
    let e = [...edit];
    if (e?.includes(M_id)) {
      setedit(e?.filter((a) => a !== M_id));
    } else {
      e = [...e, M_id];
      setedit(e);
    }
  };

  // edit toggle button
  const EditSeedItemButton = () => {
    const { M_id, isLast, isWinner, isEditable } = useContext(SeedObjectContext);
    if (!isWinner || isLast || !isEditable) return null;
    return (
      <Truncate
        lines={1}
        level={4}
        wrapperclassName="SeedEditButtonWrapper"
        className="SeedEditButton btnMobileDimensions"
        styles={{
          color: edit?.includes(M_id) ? "red" : "green"
        }}
        text={edit?.includes(M_id) ? "Cancel Edit" : "Edit"}
        onClick={() => handleedit(M_id)}
      />
    );
  };

  const KSEED = ({ seed, breakpoint, roundIndex }) => {
    return (
      <Seed className="Seed" mobileBreakpoint={breakpoint}>
        <SeedObjectsProvider seed={{ ...seed, roundIndex }}>
          <SeedItem className="seedItem">
            <EditSeedItemButton />
            <SeedTeamsComponent />
            <SeedTeamsEditComponent />
          </SeedItem>
          <SeedDateComponent />
        </SeedObjectsProvider>
      </Seed>
    );
  };

  return { KSEED };
};
