import { message } from "antd";

message.config({ maxCount: 1 });

export const success = (e) => {
  return message.success({
    content: String(e),
    className: "notificationMessage"
  });
};

export const error = (text) => {
  message.error({
    content: String(text),
    className: "notificationMessage"
  });
};

export const warning = (text) => {
  message.warning(text);
};

export const Info = (text) => {
  message.info({
    content: String(text),
    className: "notificationMessage"
  });
};
