// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 576px) {
  .ceheading,
  .headingPlayer {
    font-size: 28px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/mediaqueries/utility.css"],"names":[],"mappings":"AAAA;EACE;;IAEE,eAAe;EACjB;AACF","sourcesContent":["@media screen and (max-width: 576px) {\n  .ceheading,\n  .headingPlayer {\n    font-size: 28px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
