import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import React from "react";
const { Panel } = Collapse;

const DescriptionAccordian = ({ description }) => (
  <Collapse
    bordered={false}
    defaultActiveKey={["DescriptionAccordiant"]}
    expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
    className="site-collapse-custom-collapse   sideItem">
    <Panel header="Description" key="1" className="site-collapse-custom-panel">
      <p
        className="timelinedesc overflowThumb"
        style={{
          maxHeight: 250,
          overflowY: "auto",
          padding: "0px 0.5rem",
          paddingBottom: 0
        }}>
        {description?.length > 0
          ? description
          : `It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point   of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using `}
      </p>
    </Panel>
  </Collapse>
);

export default DescriptionAccordian;
