import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  brackets: [], //winner
  loserbrackets: [], //losers
  finalistbrackets: [], //finalist
  teams: [],
  isBYE: false,
  activeround: 0,
  loading: false,
  againMatch: false
};

export const kobracketslice = createSlice({
  name: "roundes",
  initialState,
  reducers: {
    setactiveround: () => {
      // state.activeround = 0 || action.payload;
    },
    setloserrounds: (state, action) => {
      state.loserbrackets = action.payload;
    },
    setfinalistrounds: (state, action) => {
      state.finalistbrackets = action.payload;
    },
    setRoundes: (state, action) => {
      state.brackets = action.payload;
    },
    setTeams: (state, action) => {
      state.teams = action.payload;
    },
    setBYE: (state, action) => {
      state.isBYE = action.payload;
    },
    setIsAgainMatch: (state, action) => {
      state.againMatch = action.payload;
    },
    unload: (state) => {
      state.loading = false;
    },
    load: (state) => {
      state.loading = true;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  load,
  unload,
  setBYE,
  setTeams,
  setRoundes,
  setactiveround,
  setloserrounds,
  setfinalistrounds,
  setIsAgainMatch
} = kobracketslice.actions;
export const activeround = (state) => state.ko.activeround;
export const ROUNDES = (state) => state.ko.brackets;
export const isLoading = (state) => state.ko.loading;

export default kobracketslice.reducer;
