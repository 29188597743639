import React, { useEffect } from "react";
import { Form, Button } from "antd";
import OtpInput from "react-otp-input";
import Swal from "sweetalert2";
import { success } from "../../../common/Alerts/Message";
import { sendOTPAPI } from "../../../Config/API/user";
import { useState } from "react";
function OTPForm({ visibleForm, otp, setotp, setVisibleForm, OTPSubmit, loading, email }) {
  const [countdown, setCountdown] = useState(120);
  const [ResendBtn, setResendBtn] = useState(false);
  const [sending, setsending] = useState(false);
  const MailSubmit = async () => {
    // return setVisibleForm(3);
    console.log(email);
    if (!email) {
      return Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please fill required field",
        confirmButtonColor: "#0085FF",
        allowOutsideClick: false
      });
    }

    let response;
    try {
      setsending(true);
      response = await sendOTPAPI({ email });
      const data = await response.json();
      if (data?.type === "success") {
        success("OTP resent!");
        setCountdown(120);
        setResendBtn(false);
      }
      if (data.type === "failure") {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: data.result,
          confirmButtonColor: "#0085FF",
          allowOutsideClick: false
        });
      }
      setsending(false);
    } catch (err) {
      setsending(false);
      console.log(err);
    }

    // Calculation
  };
  useEffect(() => {
    if (countdown > 0) {
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000); // Update the countdown every second (1000 milliseconds)

      return () => clearInterval(timer); // Cleanup the timer when the component unmounts
    }
    if (countdown === 0) {
      setResendBtn(true);
    }
  }, [countdown]);

  return (
    <React.Fragment>
      {visibleForm === 2 && (
        <div className="login-form">
          <div className="des">
            {/* <h2>Enter OTP</h2> */}
            <p>Type the OTP you recieved in mail</p>
          </div>
          <Form className="item" onFinish={OTPSubmit}>
            <Form.Item>
              <OtpInput
                value={otp}
                onChange={(e) => setotp(e)}
                numInputs={4}
                containerStyle={{
                  display: "flex",
                  width: "100%",
                  gap: "1rem",
                  justifyContent: "center",
                  flexWrap: "wrap",
                  marginBottom: "2rem"
                }}
                // separator={<span style={{ width: "16px" }}></span>}
                isInputNum={true}
                shouldAutoFocus={true}
                inputStyle={{
                  border: "1px solid var(--blue)",
                  borderRadius: "8px",
                  width: "54px",
                  height: "54px",
                  fontSize: "16px",
                  color: "#000",
                  fontWeight: "400",
                  caretColor: "blue"
                }}
                focusStyle={{
                  border: "2px solid var(--blue)",
                  outline: "none"
                }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "var(--blue)"
                }}>
                {countdown !== 0 && <p>wait for {countdown} seconds to resend OTP</p>}
              </div>
              <div
                style={{
                  width: "78%",
                  margin: "-15px  auto 0 auto",
                  display: "flex",
                  justifyContent: "center"
                }}>
                {ResendBtn === true && (
                  <Button
                    loading={sending}
                    onClick={() => !loading && MailSubmit()}
                    style={{
                      background: "transparent",
                      color: "var(--blue)",
                      cursor: "pointer"
                    }}
                    type="text">
                    Resend OTP
                  </Button>
                )}

                {/* <span

                  onClick={MailSubmit}
                  style={{
                    background: 'transparent',
                    border: 'none',
                    color: 'var(--blue)',
                    cursor: "pointer"
                  }}
                >
                  Resend OTP
                </span> */}
              </div>
            </Form.Item>

            <div
              style={{
                justifyContent: "center",
                display: "flex",
                gap: "20px"
              }}>
              <Button
                className="submitButton"
                style={{ width: "150px" }}
                type="primary"
                onClick={() => setVisibleForm(1)}>
                Back
              </Button>
              <Button
                style={{ width: "150px" }}
                className="submitButton"
                loading={loading}
                type="primary"
                htmlType="submit"
                // onClick={OTPSubmit}
              >
                Next
              </Button>
            </div>
          </Form>
        </div>
      )}
    </React.Fragment>
  );
}

export default OTPForm;
