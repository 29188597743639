import React, { Fragment, useEffect } from "react";
import { Col, Row } from "antd";
import DiscussionPlayer from "./DiscussionPlayer";
// import RightBar from "../../../common/righbar";
// import { SideBar } from "../../../common/sidebar";
import ScrollToTop from "../../../common/cards/ScrollToTop";
import { useFetchProfileFunc } from "../../../common/hooks/useFetchProfileFunc";
import Loader from "../../../common/cards/Loader";
import useVariables from "../../../common/hooks/useVariables";
const HomePlayer = () => {
  const { SetInititalProfile, loading } = useFetchProfileFunc();
  const { viewer } = useVariables();
  const id = viewer?.id;
  const name = viewer?.name;
  useEffect(() => {
    if (id === null || id === undefined || id === "" || !id || !name) {
      SetInititalProfile("player");
      console.log("called");
      console.log(loading);
    }
  }, [id]);
  return (
    <Fragment>
      {loading || !id || id === null || id === undefined ? (
        <Loader fullpage loading="white" />
      ) : null}
      <ScrollToTop />
      <Row justify="center" className="rowPaddingHome">
        {/* <SideBar description experience /> */}
        <Col
          xs={{ span: 24, order: 3 }}
          sm={{ span: 24, order: 3 }}
          md={{ span: 24, order: 3 }}
          lg={{ span: 11, order: 2 }}>
          <DiscussionPlayer />
        </Col>
        {/* <RightBar /> */}
      </Row>
    </Fragment>
  );
};

export default HomePlayer;
