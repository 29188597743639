// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eventcard {
  border-radius: 4px;
  box-shadow: 0px 3.19492px 3.99364px rgba(0, 0, 0, 0.19);
  margin-bottom: 5%;
  margin-top: 0.5%;
  cursor: pointer;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/styles/Card/eventcard/eventcard.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,uDAAuD;EACvD,iBAAiB;EACjB,gBAAgB;EAChB,eAAe;EACf,WAAW;AACb","sourcesContent":[".eventcard {\n  border-radius: 4px;\n  box-shadow: 0px 3.19492px 3.99364px rgba(0, 0, 0, 0.19);\n  margin-bottom: 5%;\n  margin-top: 0.5%;\n  cursor: pointer;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
