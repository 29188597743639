const useFieldsVaildator = () => {
  const Exist = (value) => {
    if (value !== null && value !== undefined) {
      return true;
    }
    return false;
  };
  const inputTextVaildator = (text) => {
    const newtext = String(text).trim().length > 0;
    if (Exist(text) && newtext) {
      return true;
    }
    return false;
  };
  const inputArrayVaildator = (arr) => {
    if (Array.isArray(arr) && Exist(arr)) {
      return arr.length === 0 ? "empty" : true;
    } else return false;
  };

  const inputNumberVaildator = (num) => {
    if (Exist(num)) {
      const isNAN = isNaN(num);
      if (isNAN) return false;

      return num === 0 ? "neutral" : num > 0 ? "positive" : "negative";
    } else return false;
  };
  const imageFileValidator = (image) => {
    if (image?.type?.startsWith("image/") && Exist(image)) {
      return true;
    } else return false;
  };
  const videoFileValidator = (video) => {
    if (video?.type?.startsWith("video/") && Exist(video)) {
      return true;
    } else return false;
  };
  return {
    inputArrayVaildator,
    inputNumberVaildator,
    inputTextVaildator,
    imageFileValidator,
    videoFileValidator
  };
};

export default useFieldsVaildator;
