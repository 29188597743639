import React, { Fragment, useEffect, useState } from "react";
import { Button, Skeleton, List, Pagination } from "antd";
import { GetAllOrganizers } from "../../../../Config/API/organizer";
import { InviteToPanel } from "../../../../Config/API/panel";
import Swal from "sweetalert2";
import { error, success } from "../../../../common/Alerts/Message";
import { KEYS } from "../../../../Config/Keys";
import { Link } from "react-router-dom";
import FadeInImage from "../../../../common/Images/FadeInImage";
import { fallbackprofile } from "../../../../common/Utils";
import useVariables from "../../../../common/hooks/useVariables";
import useDebounce from "../../../../common/hooks/useDebounce";

function AllPanelMembers({ panelId, input, members }) {
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [requested, setrequested] = useState([]);

  // pagination
  const debouncedInput = useDebounce(input, 500); // 500ms delay
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const limit = 25;

  // Modal
  const [, setname] = useState("");
  const { viewer } = useVariables();
  const id = viewer?.id;

  const getAll = async (page) => {
    try {
      setLoading(true);
      const newOffset = (page - 1) * limit;

      const response = await GetAllOrganizers(newOffset, limit, input);
      const data = await response.json();

      if (data.type === "success") {
        // remove organizers that are already part of the panel
        var filter = [];

        data?.data?.forEach((organizer) => {
          var found = false;
          members?.forEach((pmem) => {
            if (pmem?.organizer?._id === organizer?._id) {
              found = true;
              return;
            }
          });
          if (found === false && organizer?._id !== id)
            if (found === false) {
              filter.push(organizer);
            }
        });
        setList([...filter]);
        setTotalPages(Math.ceil(data?.totalCount / limit));
        setCurrentPage(page);
      } else {
        error("Can't add to panel");
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
      error(e.message);
    }
  };

  const invite = async (invitingid) => {
    if (panelId) {
      try {
        setLoading(true);
        const response = await InviteToPanel({
          id: panelId,
          organizerid: invitingid,
          organizersentid: id,
          type: "Add"
        });
        const data = await response.json();

        if (data.type === "success") {
          success(data?.result);
          setrequested([...requested, invitingid]);
          const members = JSON.parse(sessionStorage.getItem("members"));
          sessionStorage.setItem("members", JSON.stringify([...members, invitingid]));
        } else if (data?.type === "failure") {
          error(data?.result);
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        error(e?.message);
        console.log(e);
      }
    } else {
      error("No panel Id detected! try again later");
    }
  };

  useEffect(() => {
    if (input && input?.length > 0) {
      getAll(1);
    }
  }, [debouncedInput]);

  function confirm(name, id) {
    Swal.fire({
      icon: "question",
      text: `Are you sure you want to invite ${name} `,
      showCancelButton: true,
      confirmButtonText: "Invite",
      cancelButtonColor: "var(--red)"
    }).then((e) => {
      if (e.isConfirmed) {
        setname(name);
        invite(id);
      }
    });
  }
  useEffect(() => {
    const m = JSON.parse(sessionStorage.getItem("members"));
    if (!Array.isArray(m)) sessionStorage.setItem("members", JSON.stringify([]));
    else {
      setrequested(JSON.parse(sessionStorage.getItem("members")));
    }

    return () => {
      setList([]);
      setname(null);
    };
  }, []);

  const goToPage = (pageNumber) => {
    getAll(pageNumber);
  };
  return (
    <React.Fragment>
      {input && input?.length > 0 ? (
        <>
          {" "}
          <List
            style={{ padding: "2.5% ", overflowX: "hidden" }}
            loading={{ spinning: loading, size: "large" }}
            itemLayout={"horizontal"}
            dataSource={list}
            grid={{
              gutter: [200, 30],
              column: 2,
              sm: 1,
              xs: 1,
              md: 1,
              lg: 2,
              xl: 2,
              xxl: 2
            }}
            renderItem={(item) => (
              <List.Item
                className="ListItem"
                style={{
                  display: "flex",
                  alignItems: "center ",
                  flexWrap: "wrap",
                  gap: "10px",
                  minWidth: "250px !important"
                }}
                actions={[
                  <Button
                    className="addtocalendar"
                    key="list-loadmore-edit"
                    style={{
                      marginBottom: "unset",
                      minWidth: "100px",
                      height: "35px",
                      color: "white",
                      border: requested?.includes(item?._id) ? "none" : undefined,
                      background: requested?.includes(item?._id) ? "var(--grey)" : undefined,
                      cursor: requested?.includes(item?._id) ? "not-allowed" : undefined
                    }}
                    onClick={() => {
                      if (!requested?.includes(item?._id)) confirm(item?.name, item?._id);
                    }}>
                    {requested?.includes(item?._id) ? "Invited" : "Invite"}
                  </Button>
                ]}>
                <Skeleton avatar title={false} loading={item?.loading} active>
                  <List.Item.Meta
                    style={{ alignItems: "center", gap: "10px" }}
                    avatar={
                      <FadeInImage
                        type={"Fadenavprofile"}
                        fallaback={fallbackprofile}
                        src={KEYS.API + "/" + item?.profilepic}
                      />
                    }
                    title={
                      <Fragment>
                        <Link
                          state={"organizer"}
                          to={`/view/organizer/${item?._id}/timeline`}
                          style={{ fontWeight: "550" }}>
                          {String(item?.name).length <= 20 ? item?.name : item?.name + "..."}
                        </Link>
                        <small style={{ display: "block", marginBottom: "-5px" }}>
                          {item?.experience?.role}
                        </small>
                        <small style={{ display: "block", marginBottom: "-5px" }}>
                          {item?.username ? `( ${item?.username} )` : ""}
                        </small>
                      </Fragment>
                    }
                  />
                  {/* <div>content</div> */}
                </Skeleton>
              </List.Item>
            )}
          />
          <Pagination
            current={currentPage}
            total={totalPages * limit}
            pageSize={limit}
            showSizeChanger={false}
            onChange={goToPage}
          />
        </>
      ) : (
        "Search a name to view organizers"
      )}
    </React.Fragment>
  );
}

export default AllPanelMembers;
