import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setMatchObj } from "../../../../../../Store/Features/MatchesSlice";

export const useSortedpoints = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const sorteddata = (data) => {
    if (!data?.teams) return;
    const find = data?.matchesSlot?.find((e) => e?.match?.win === "");

    var newdata = { ...data };

    const teamsdata = data?.teams?.map((e) =>
      e?.map((a) => {
        return { ...a, key: a?.team?._id };
      })
    );
    var teams = teamsdata;
    teams?.forEach((teams) => {
      for (let i = 0; i < teams?.length; i++) {
        for (let j = i; j < teams?.length; j++) {
          let condition1 = teams[j]?.points > teams[i]?.points;
          // let condition2 = teams[j]?.points === teams[i]?.points;
          // let condition3 = teams[j]?.lose < teams[i]?.lose;

          if (condition1) {
            let teamp = teams[i];
            teams[i] = teams[j];
            teams[j] = teamp;
          }
          // else if (condition2 && condition3) {
          //     let teamp = teams[i]
          //     teams[i] = teams[j]
          //     teams[j] = teamp
          // }
        }
      }
    });
    if (find == undefined && data?.matchesSlot?.length >= 1) {
      teams = teams?.map((e) =>
        e?.map((a, i) => {
          return {
            ...a,
            key: a?.team?._id,
            Q: i < parseInt(state?.koteams / data?.teams?.length) ? "Q" : ""
          };
        })
      );
    }
    newdata["teams"] = teams;
    dispatch(
      setMatchObj({
        quailfy: find || data?.matchesSlot?.length === 0 ? false : true,
        matches: newdata,
        len: parseInt(state?.koteams / data?.teams?.length) - 1
      })
    );
  };

  return { sorteddata };
};
