// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./Container.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./Nav.css";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./Slider.css";
import ___CSS_LOADER_AT_RULE_IMPORT_3___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./Footer.css";
import ___CSS_LOADER_AT_RULE_IMPORT_4___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./Searchbar.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_3___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.margin-b-20 {
  margin-bottom: 20px !important;
}
.homefont {
  padding: 0px;
  font-size: 60px;
  font-weight: 400;
  margin-top: 70px;
  font-weight: 400;
  line-height: 65px;
}
.homefont + p {
  font-weight: 500;
  line-height: 1.5;
}
.homeContent {
  padding-top: 2.2rem;
}
.homeimage {
  object-fit: cover;
  right: -10%;
  position: relative;
}
.homeContent .ant-input-affix-wrapper {
  border: 1px solid #c1b8b8;
}
.homeContent .slick-list {
  padding: 0px !important;
}
@media screen and (max-width: 768px) {
  .homefont {
    font-size: 36px;
  }
  .homefont + p {
    text-align: justify;
  }
}
@media screen and (max-width: 880px) and (min-width: 768px) {
  .homefont {
    font-size: 48px;
  }
}
@media screen and (max-width: 1364px) {
  .homefont {
    margin-top: 30px;
  }
}
@media screen and (max-width: 767px) {
  .homefont {
    font-size: 50px;
  }
  .homeimage {
    right: 0;
  }
}
@media screen and (max-width: 450px) {
  .homefont {
    font-size: 40px;
    font-weight: 500;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/Landing/index.css"],"names":[],"mappings":"AAKA;EACE,8BAA8B;AAChC;AACA;EACE,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,iBAAiB;AACnB;AACA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,iBAAiB;EACjB,WAAW;EACX,kBAAkB;AACpB;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,uBAAuB;AACzB;AACA;EACE;IACE,eAAe;EACjB;EACA;IACE,mBAAmB;EACrB;AACF;AACA;EACE;IACE,eAAe;EACjB;AACF;AACA;EACE;IACE,gBAAgB;EAClB;AACF;AACA;EACE;IACE,eAAe;EACjB;EACA;IACE,QAAQ;EACV;AACF;AACA;EACE;IACE,eAAe;IACf,gBAAgB;EAClB;AACF","sourcesContent":["@import \"./Container.css\";\n@import \"./Nav.css\";\n@import \"./Slider.css\";\n@import \"./Footer.css\";\n@import \"./Searchbar.css\";\n.margin-b-20 {\n  margin-bottom: 20px !important;\n}\n.homefont {\n  padding: 0px;\n  font-size: 60px;\n  font-weight: 400;\n  margin-top: 70px;\n  font-weight: 400;\n  line-height: 65px;\n}\n.homefont + p {\n  font-weight: 500;\n  line-height: 1.5;\n}\n.homeContent {\n  padding-top: 2.2rem;\n}\n.homeimage {\n  object-fit: cover;\n  right: -10%;\n  position: relative;\n}\n.homeContent .ant-input-affix-wrapper {\n  border: 1px solid #c1b8b8;\n}\n.homeContent .slick-list {\n  padding: 0px !important;\n}\n@media screen and (max-width: 768px) {\n  .homefont {\n    font-size: 36px;\n  }\n  .homefont + p {\n    text-align: justify;\n  }\n}\n@media screen and (max-width: 880px) and (min-width: 768px) {\n  .homefont {\n    font-size: 48px;\n  }\n}\n@media screen and (max-width: 1364px) {\n  .homefont {\n    margin-top: 30px;\n  }\n}\n@media screen and (max-width: 767px) {\n  .homefont {\n    font-size: 50px;\n  }\n  .homeimage {\n    right: 0;\n  }\n}\n@media screen and (max-width: 450px) {\n  .homefont {\n    font-size: 40px;\n    font-weight: 500;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
