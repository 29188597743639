import React, { useContext } from "react";
import { Form } from "antd";
import { FormikContext } from "formik";
import { Input } from "antd";

function InputTextField({
  name,
  label,
  placeholder,
  type = "text",
  required = false,
  hasFeedback = false,
  ...props
}) {
  const { handleChange, handleBlur, values, errors } = useContext(FormikContext);

  // render field based on type
  const renderedField = (type) => {
    switch (type) {
      case "password":
        return (
          <Input.Password
            id={name}
            name={name}
            type={type}
            placeholder={placeholder}
            onChange={handleChange}
            className={props?.className}
            onBlur={handleBlur}
            value={values?.[name]}
            style={{
              borderRadius: "5px",
              lineHeight: "38px",
              marginTop: "5px"
            }}
          />
        );
      case "textArea":
        return (
          <Input.TextArea
            id={name}
            name={name}
            type={type}
            placeholder={placeholder}
            onChange={handleChange}
            className={props?.className}
            onBlur={handleBlur}
            value={values?.[name]}
            style={{
              borderRadius: "5px",
              lineHeight: "38px",
              marginTop: "5px"
            }}
            {...props}
          />
        );

      default:
        return (
          <Input
            id={name}
            name={name}
            type={type}
            placeholder={placeholder}
            onChange={handleChange}
            className={props?.className}
            onBlur={handleBlur}
            value={values?.[name]}
            style={{
              borderRadius: "5px",
              lineHeight: "38px",
              marginTop: "5px"
            }}
            {...props}
          />
        );
    }
  };

  return (
    <>
      <Form.Item
        label={label}
        required={required}
        hasFeedback={hasFeedback}
        help={errors?.[name] || props?.error ? errors[name] || props?.error : undefined}
        validateStatus={errors?.[name] || props?.error ? "error" : "success"}>
        {renderedField(type)}
      </Form.Item>
    </>
  );
}

export default InputTextField;
