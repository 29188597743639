import * as Yup from "yup";
import { TOURNAMENT_TYPES, minUserNameLength } from "./constants";
import dayjs from "dayjs";

// login validations
export const loginValidation = (signInPhone) =>
  Yup.object().shape({
    email: signInPhone
      ? Yup.string()
      : Yup.string().email("Enter valid email address").required("Email is required"),
    password: Yup.string().required("Password is required"),
    phoneNumber: signInPhone
      ? Yup.string()
          .min(10, "Phone number must at least be 10 digits")
          .required("Phone number is required")
      : Yup.string().notRequired()
  });

// signUp validations
export const signUpValidations = (isSponsor) =>
  Yup.object().shape({
    firstName: Yup.string().required("This field is required"),
    lastName: isSponsor ? Yup.string() : Yup.string().required("This field is required"),
    nickName: Yup.string(),
    email: Yup.string().email("Enter valid email address").required("Email is required"),
    password: Yup.string()
      .min(7, "Password length must be greater than 7 characters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .required("Please confirm your password")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
    phoneNumber: Yup.string()
      .min(10, "Phone number must at least be 10 digits")
      .required("Phone number is required"),
    termsAndConditions: Yup.boolean()
      .required()
      .test("is-true", (value) => value === true),
    DOB: Yup.string()
      .test("is-age-valid", "People older than 10 years can create signup only", function (DOB) {
        const todayDate = new Date();
        const year = todayDate.getFullYear();
        const choosedDate = new Date(DOB);
        const choosedYear = choosedDate.getFullYear();
        let age = year - choosedYear;
        if (age < 10) {
          return false;
        } else if (
          age === 10 &&
          todayDate.getMonth() <= choosedDate.getMonth() &&
          choosedDate.getDate() + 1 < todayDate.getDate()
        ) {
          return false;
        }
        return true;
      })
      .required("This field is required")
  });

// create profile: Player
export const createPlayerProfileValidations = (isEdit) =>
  Yup.object().shape({
    firstName: isEdit ? Yup.string() : Yup.string().required("This field is required"),
    lastName: isEdit ? Yup.string() : Yup.string().required("This field is required"),
    name: isEdit ? Yup.string().required("This field is required") : Yup.string(),
    nickName: Yup.string(),
    username: Yup.string()
      .min(minUserNameLength, `Username must be at least ${minUserNameLength} characters`)
      .required("User name is required") // This makes the field required and provides a custom error message.
      .matches(
        /^[a-zA-Z_][a-zA-Z0-9_]*$/, // This regular expression ensures the username starts with a letter or underscore and can be followed by letters, numbers, or underscores.
        "Username can only contain letters, numbers, and underscores, without spaces, and cannot start with a number" // Custom error message for when the username does not match the pattern.
      ),
    location: Yup.string().required("This field is required"),
    DOB: Yup.string()
      .test(
        "is-age-valid",
        "People older than 10 years can create organizer profile only",
        function (DOB) {
          const todayDate = new Date();
          const year = todayDate.getFullYear();
          const choosedDate = new Date(DOB);
          const choosedYear = choosedDate.getFullYear();
          let age = year - choosedYear;
          if (age < 10) {
            return false;
          } else if (
            age === 10 &&
            todayDate.getMonth() <= choosedDate.getMonth() &&
            choosedDate.getDate() + 1 < todayDate.getDate()
          ) {
            return false;
          }
          return true;
        }
      )
      .required("This field is required"),
    selectedSport: Yup.string().required("This field is required")
    // experience: Yup.object()
    //   .shape({
    //     name: Yup.string(),
    //     date: Yup.string(),
    //     location: Yup.string(),
    //     teamName: Yup.string(),
    //     role: Yup.string(),
    //     awards: Yup.string(),
    //     description: Yup.string(),
    //     pictures: Yup.object()
    //   })
    //   .test(
    //     "experience-required",
    //     "Please add All the fields of Experience",
    //     function (experience) {
    //       if (experience.name) {
    //         if (
    //           !experience.date ||
    //           !experience.location ||
    //           !experience.teamName ||
    //           !experience.role ||
    //           !experience.description ||
    //           !experience.pictures?.preview
    //         ) {
    //           return false;
    //         }
    //       }
    //       return true;
    //     }
    //   )
  });

// create profile: Organizer
export const createPlayerOrganizerValidations = (isEdit) =>
  Yup.object().shape({
    firstName: isEdit ? Yup.string() : Yup.string().required("This field is required"),
    lastName: isEdit ? Yup.string() : Yup.string().required("This field is required"),
    name: isEdit ? Yup.string().required("This field is required") : Yup.string(),
    username: Yup.string()
      .min(minUserNameLength, `Username must be at least ${minUserNameLength} characters`)
      .required("User name is required") // This makes the field required and provides a custom error message.
      .matches(
        /^[a-zA-Z_][a-zA-Z0-9_]*$/, // This regular expression ensures the username starts with a letter or underscore and can be followed by letters, numbers, or underscores.
        "Username can only contain letters, numbers, and underscores, without spaces, and cannot start with a number" // Custom error message for when the username does not match the pattern.
      ),
    location: Yup.string().required("This field is required"),
    DOB: Yup.string()
      .test(
        "is-age-valid",
        "People older than 10 years can create organizer profile only",
        function (DOB) {
          const todayDate = new Date();
          const year = todayDate.getFullYear();
          const choosedDate = new Date(DOB);
          const choosedYear = choosedDate.getFullYear();
          let age = year - choosedYear;
          if (age < 10) {
            return false;
          } else if (
            age === 10 &&
            todayDate.getMonth() <= choosedDate.getMonth() &&
            choosedDate.getDate() + 1 < todayDate.getDate()
          ) {
            return false;
          }
          return true;
        }
      )
      .required("This field is required")
    // experience: Yup.object()
    //   .shape({
    //     name: Yup.string(),
    //     date: Yup.string(),
    //     location: Yup.string(),
    //     teamName: Yup.string(),
    //     role: Yup.string(),
    //     awards: Yup.string(),
    //     description: Yup.string(),
    //     pictures: Yup.object()
    //   })
    //   .test(
    //     "experience-required",
    //     "Please add All the fields of Experience",
    //     function (experience) {
    //       if (experience.name) {
    //         if (
    //           !experience.date ||
    //           !experience.location ||
    //           !experience.teamName ||
    //           !experience.role ||
    //           !experience.description ||
    //           !experience.pictures?.preview
    //         ) {
    //           return false;
    //         }
    //       }
    //       return true;
    //     }
    //   )
  });

// create team validations
export const createTeamValidations = () =>
  Yup.object().shape({
    name: Yup.string().required("This field is required"),
    username: Yup.string()
      .min(minUserNameLength, `Username must be at least ${minUserNameLength} characters`)
      .required("User name is required") // This makes the field required and provides a custom error message.
      .matches(
        /^[a-zA-Z_][a-zA-Z0-9_]*$/, // This regular expression ensures the username starts with a letter or underscore and can be followed by letters, numbers, or underscores.
        "Username can only contain letters, numbers, and underscores, without spaces, and cannot start with a number" // Custom error message for when the username does not match the pattern.
      ),
    description: Yup.string(),
    sportsType: Yup.string().required("This field is required"),
    selectedSport: Yup.string().required("This field is required"),
    location: Yup.string().required("This field is required"),
    establishedDate: Yup.string().required("This field is required")
  });

// create panel validations
export const createPanelValidations = () =>
  Yup.object().shape({
    name: Yup.string().required("This field is required"),
    username: Yup.string()
      .min(minUserNameLength, `Username must be at least ${minUserNameLength} characters`)
      .required("User name is required") // This makes the field required and provides a custom error message.
      .matches(
        /^[a-zA-Z_][a-zA-Z0-9_]*$/, // This regular expression ensures the username starts with a letter or underscore and can be followed by letters, numbers, or underscores.
        "Username can only contain letters, numbers, and underscores, without spaces, and cannot start with a number" // Custom error message for when the username does not match the pattern.
      ),
    description: Yup.string(),
    establishedDate: Yup.string().required("This field is required"),
    location: Yup.string().required("This field is required")
  });

// create event validations
export const createEventValidations = () =>
  Yup.object().shape({
    name: Yup.string().required("This field is required"),
    description: Yup.string(),
    location: Yup.string().required("This field is required"),
    city: Yup.string().required("This field is required"),
    sportsType: Yup.string().required("Sports type is required first"),
    selectedSport: Yup.string().required("This field is required"),
    startDate: Yup.string().required("Start date is required"),
    endDate: Yup.string()
      .required("Select End Date")
      .when("startDate", (startDate, schema) => {
        return schema.test({
          test: (endDate) => new Date(endDate) > new Date(startDate),
          message: "Ending Date should be greater than the Starting Date"
        });
      }),
    deadLineDate: Yup.string()
      .required("Deadline date is required")
      .when("startDate", (startDate, schema) => {
        const isStartDate = dayjs(startDate).isValid();

        return schema.test({
          test: (deadLineDate) =>
            isStartDate ? new Date(deadLineDate) < new Date(startDate) : true,
          message: "Deadline should be less than or equal to starting date"
        });
      }),
    fee: Yup.number().min(0, "Fee can not be a negative value"),
    awards: Yup.array().min(1, "At least one is required").required("This field is required"),
    maxParticipants: Yup.number().when("minParticipants", (minParticipants, schema) => {
      return schema.test({
        test: (maxParticipants) =>
          maxParticipants >= minParticipants || maxParticipants === undefined,
        message: "Max Participants should be greater than or equal to minimum Participants"
      });
    }),
    minParticipants: Yup.number().when("typeOfMatch", (typeOfMatch, schema) => {
      return typeOfMatch?.[0] === TOURNAMENT_TYPES.doubleElimination
        ? schema
            .min(4, "Minimum Participants is required and should be 4")
            .required("Minimum Participants is required and should be 4")
        : schema
            .min(2, "Minimum Participants is required and should be 2")
            .required("Minimum Participants is required and should be 2");
    }),
    typeOfMatch: Yup.string().required("This field is required"),
    rules: Yup.string().required("This field is required"),
    panel: Yup.string().required("This field is required"),
    coordinates: Yup.object().shape({
      latitude: Yup.string().required(),
      longitude: Yup.string().required()
    }),
    wildCardEntry: Yup.boolean(),
    wildCard: Yup.array().when("wildCardEntry", {
      is: true,
      then: (schema) =>
        schema
          .of(
            Yup.object().shape({
              name: Yup.string().required("Wild Card Name is required"),
              registrationFee: Yup.number()
                .typeError("Entry Fee must be a number")
                .positive("Entry Fee must be a positive number")
                .required("Entry Fee is required"),
              noOfSlots: Yup.number()
                .typeError("No of slots must be a number")
                .integer("No of slots must be an integer")
                .positive("No of slots must be a positive number")
                .required("No of slots is required")
            })
          )
          .min(1, "At least one entry is required ")
    })
  });

// create sponsor
export const createSponsorProfileValidations = () =>
  Yup.object().shape({
    name: Yup.string().required("This field is required"),
    location: Yup.string().required("This field is required"),
    DOB: Yup.string()
      .test("is-age-valid", "People older than 10 years can create profile only", function (DOB) {
        const todayDate = new Date();
        const year = todayDate.getFullYear();
        const choosedDate = new Date(DOB);
        const choosedYear = choosedDate.getFullYear();
        let age = year - choosedYear;
        if (age < 10) {
          return false;
        } else if (
          age === 10 &&
          todayDate.getMonth() <= choosedDate.getMonth() &&
          choosedDate.getDate() + 1 < todayDate.getDate()
        ) {
          return false;
        }
        return true;
      })
      .required("This field is required")
  });

export const createCampaignValidations = () =>
  Yup.object().shape({
    name: Yup.string().required(),
    description: Yup.string().required()
  });

export const createCampaignActivityValidations = () =>
  Yup.object().shape({
    name: Yup.string().required("This field is required"),
    sportType: Yup.string(),
    activityType: Yup.string().required("This field is required"),
    date: Yup.string()
  });

export const withdrawalFormValidations = () =>
  Yup.object().shape({
    accountNumber: Yup.string().required("Account number is required"),
    accountTitle: Yup.string().required("Bank title is required"),
    bankName: Yup.string().required("Bank name is required"),
    amount: Yup.number()
      .required("Amount is required")
      .min(0, "Amount must be greater than or equal to 0"),
    mobileNumber: Yup.string(),
    fileList: Yup.array().min(1, "File is required").required("file is required")
  });
