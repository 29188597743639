import MapCard from "../../../../../common/cards/MapCard/MapCard";
import { Button, Col, DatePicker, Form, Input, InputNumber, Row, Select, Typography } from "antd";
import NameAndType from "../NameAndType/index";
import moment from "moment";
import React, { useEffect, useState } from "react";
import useVariables from "../../../../../common/hooks/useVariables";
import { GetPanelsOfOwner as GetPanelsOfOwnerApi } from "../../../../../Config/API/panel";
import InputTextField from "../../../../../common/FormItem/InputTextField";
import ChooseAwardsModal from "./ChooseAwardsModal";
import ChoosePanelModal from "./ChoosePanelModal";

import { error } from "../../../../../common/Alerts/Message";
import { useParams } from "react-router-dom";
import { LOCATIONS } from "../../../../../Config/helpers/constants";

const { RangePicker } = DatePicker;
const { Option } = Select;

function Page1({
  panelModal,
  setPanelModal,
  isVisible,
  setFieldValue,
  values,
  errors,
  sportAwards,
  setSportAwards,
  registrationComplete
}) {
  const [panels, setPanels] = useState(null);
  const [awardsOpen, setAwardsOpen] = useState(false);
  const { viewer } = useVariables();
  const { eventID } = useParams();

  const fetchPanelData = async () => {
    const id = viewer?.id;
    if (id) {
      try {
        const response = await GetPanelsOfOwnerApi(id);
        const data = await response.json();
        if (data.type === "success") {
          const filterData = data?.result?.map((panel) => {
            return {
              value: panel._id,
              label: panel.name
            };
          });
          setPanels(filterData);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  useEffect(() => {
    if (viewer?.id && !eventID) {
      setPanelModal(true);
    }

    if (viewer?.id) {
      fetchPanelData();
    }
  }, [viewer?.id, eventID]);

  const children = [];
  for (let i = 10; i < 12; i++) {
    children.push(<Option key={i}>{i.toString(12) + i}</Option>);
  }

  const setCoordinates = (value) => {
    setFieldValue("coordinates", value);
  };

  const setLocation = (value) => {
    setFieldValue("location", value);
  };

  const setStartEndDate = (dateString) => {
    setFieldValue("startDate", dateString[0]);
    setFieldValue("endDate", dateString[1]);
  };

  if (!isVisible) return null;
  return (
    <>
      {" "}
      <Row style={{ paddingBottom: "6%" }}>
        <Col lg={12} md={12} className="ceinputformpadding">
          {/* event name */}
          <InputTextField
            name="name"
            label="Name of Event"
            required
            placeholder="e.g   “ Spartans Club “"
          />

          {/* event description */}
          <InputTextField
            name="description"
            label="Description"
            placeholder="e.g   “ Cyberpunks FTS Collectibles “"
          />

          {/* select game and game type */}
          <NameAndType
            setFieldValue={setFieldValue}
            values={values}
            errors={errors}
            isEdit={eventID ? true : false}
          />

          {/* location */}
          <Form.Item
            className="border profileItems"
            help={errors?.location ? errors?.location : undefined}
            validateStatus={errors?.location ? "error" : "success"}>
            <label className="boldlabel required">Location</label>
            <MapCard
              padding
              setCoordinate={setCoordinates}
              setLocation={setLocation}
              data={{ coordinate: values?.coordinates }}
            />
            <Input
              name="location"
              onChange={(e) => setFieldValue("location", e.target.value)}
              value={values?.location}
              className="ceinputfield"
              placeholder="e.g Islamabad"
            />
            <Form.Item
              className="border profileItems"
              help={errors?.city ? errors?.city : undefined}
              validateStatus={errors?.city ? "error" : "success"}>
              <label className="boldlabel">Select City</label>
              <Select
                value={values?.city}
                placeholder={"Select city"}
                style={{
                  width: "43%",
                  border: "1px solid",
                  borderRadius: "7px",
                  color: "#b1b1b1"
                }}
                onChange={(e) => setFieldValue("city", e)}
                options={LOCATIONS?.length > 0 ? LOCATIONS : []}
              />
            </Form.Item>
          </Form.Item>
          <Form.Item
            className="profileItems"
            help={
              errors?.startDate ? errors?.startDate : errors?.endDate ? errors?.endDate : undefined
            }
            validateStatus={errors?.startDate || errors?.endDate ? "error" : "success"}>
            <label className="boldlabel required">Start Date and End Date</label>
            <RangePicker
              disabled={registrationComplete}
              inputReadOnly
              disabledDate={(current) => {
                return current && current < moment().add(-1, "day");
              }}
              style={{ marginTop: "8px", maxWidth: "240px" }}
              value={[
                values?.startDate ? moment(values?.startDate) : undefined,
                values?.endDate ? moment(values?.endDate) : undefined
              ]}
              onChange={(_e, dateString) => setStartEndDate(dateString)}
            />
          </Form.Item>
        </Col>
        <Col md={12} lg={12} className="ceinputformpadding">
          <Form.Item
            className="border profileItems"
            help={errors?.panel ? errors?.panel : undefined}
            validateStatus={errors?.panel ? "error" : "success"}>
            <label className="boldlabel">Select Panel</label>
            <Select
              value={values?.panel}
              placeholder={"Select Panel"}
              style={{
                width: "43%",
                border: "1px solid",
                borderRadius: "7px",
                color: "#b1b1b1"
              }}
              onChange={(e) => setFieldValue("panel", e)}
              options={panels?.length > 0 ? panels : ""}
            />
          </Form.Item>
          {/* Fee */}
          <Form.Item
            className="profileItems"
            help={errors?.fee ? errors?.fee : undefined}
            validateStatus={errors?.fee ? "error" : "success"}>
            <label className="boldlabel">Registration Fees</label>

            <InputNumber
              name="fee"
              value={values?.fee}
              onChange={(e) => setFieldValue("fee", e)}
              style={{ width: "200px" }}
            />
          </Form.Item>
          {/* <InputTextField
            name="fee"
            label="Registration Fees"
            type="number"
            placeholder="e.g   “ 27“  US Dollar ($)"
            suffix={
              <Space direction="vertical">
                <FaArrowUp className="m-0 p-0 fs-8" />
                <FaArrowDown className="m-0 p-0 fs-8" />
              </Space>
            }
          /> */}
          {/* deadline date */}
          <Form.Item
            className="halfwidth profileItems"
            help={errors?.deadLineDate ? errors?.deadLineDate : undefined}
            validateStatus={errors?.deadLineDate ? "error" : "success"}>
            <label className="boldlabel required">Registration Deadline</label>
            <DatePicker
              disabledDate={(current) => {
                return current && current < moment().add(-1, "day");
              }}
              disabled={registrationComplete}
              inputReadOnly
              className="mt8"
              value={values?.deadLineDate ? moment(values?.deadLineDate) : undefined}
              onChange={(_e, formatted) => setFieldValue("deadLineDate", formatted)}
            />
          </Form.Item>
          <Form.Item
            className=" profileItems halfwidth"
            help={errors?.awards ? errors?.awards : undefined}
            validateStatus={errors?.awards ? "error" : "success"}>
            <label className="boldlabel required">Awards and Prizes</label>
            <Button
              onClick={() => {
                if (values?.selectedSport?.length > 0) {
                  setAwardsOpen(true);
                } else {
                  error("Choose sports firsts");
                }
              }}>
              {values?.awards?.length > 0 ? "Update Awards" : "Choose Awards"}
            </Button>
          </Form.Item>

          <section>
            <Typography.Title level={3}>Chosen Awards</Typography.Title>
            {values?.awards?.length > 0 ? (
              <ol>
                {values?.awards?.map((award, index) => (
                  <React.Fragment key={index}>
                    <li>
                      {sportAwards?.find((itemAward) => itemAward?._id === award?.awardId)?.title ||
                        "-"}
                    </li>
                    <ul>
                      {award?.awards?.map((subAward, subIndex) => (
                        <li key={subIndex}>
                          <Typography.Text type="success">
                            Rank : {subAward?.rank} :{" "}
                          </Typography.Text>
                          <Typography.Text type="secondary">
                            Award Type: {subAward?.awardType}{" "}
                            {subAward?.type === "cash" ? ":" : null}
                          </Typography.Text>
                          {subAward?.awardType === "cash" && (
                            <Typography.Text type="warning">
                              Value:{subAward?.awardValue}
                            </Typography.Text>
                          )}
                        </li>
                      ))}
                    </ul>
                  </React.Fragment>
                ))}
              </ol>
            ) : (
              <Typography.Text>No Awards chosen yet, please select awards</Typography.Text>
            )}
          </section>
        </Col>
      </Row>
      {/* Choose Awards */}
      {awardsOpen && (
        <ChooseAwardsModal
          values={values}
          error={errors}
          open={awardsOpen}
          setOpen={setAwardsOpen}
          setFieldValue={setFieldValue}
          sportAwards={sportAwards}
          setSportAwards={setSportAwards}
        />
      )}
      {/* Panel Modal */}
      {panelModal && (
        <ChoosePanelModal
          open={panelModal}
          setOpen={setPanelModal}
          setFieldValue={setFieldValue}
          panels={panels}
          values={values}
        />
      )}
    </>
  );
}

export default Page1;
