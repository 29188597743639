import { KEYS } from "../Keys";

export const GetAllSearchResults = async (values, offset, limit) => {
  const response = await fetch(KEYS.API + `/search/allsearch?skip=${offset}&limit=${limit}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};
