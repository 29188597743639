import { Col, Row } from "antd";
import React, { Fragment } from "react";
import InviteCard from "../Card/InviteCard";
import NoData from "../../../../common/cards/noData";

const InviteMembers = ({ team }) => {
  const arr = team?.members?.filter((e) => !e?.active && e?.type?.toLowerCase() === "add");

  return (
    <Fragment>
      <Row>
        {arr?.length > 0 ? (
          arr?.map((e, i) =>
            e?.player !== null ? (
              <Col
                className="pomemberShadow TPcard"
                key={i}
                xxl={6}
                xl={8}
                lg={{ span: 12 }}
                md={{ span: 12 }}
                sm={24}
                xs={24}>
                <InviteCard team={e} />
              </Col>
            ) : null
          )
        ) : (
          <NoData noMembers />
        )}
      </Row>
    </Fragment>
  );
};

export default InviteMembers;
