import { Switch } from "antd";
import React, { Fragment } from "react";
import { useEditProfile } from "./Functions";
function ProfileStatuSwtich() {
  const { loading, ChangeProfileStatus, profile } = useEditProfile();
  const onChange = async (e) => {
    // e==true=>private, e==false=>public
    ChangeProfileStatus(e === true ? "private" : "public");
  };
  const checked = profile?.profileType === "private" ? true : false;
  return (
    <Fragment>
      <Switch
        size="default"
        checked={checked}
        onChange={onChange}
        loading={loading}
        checkedChildren="private"
        unCheckedChildren="public"
      />
    </Fragment>
  );
}

export default ProfileStatuSwtich;
