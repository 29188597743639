import React, { Fragment } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TestimonialCard from "../../common/cards/TestimonialsCard/TestimonialCard";
import { Row } from "antd";
const Testimonials = () => {
  const settings = {
    className: "center",
    dots: true,
    pauseOnHover: true,
    arrows: false,
    centerMode: true,
    infinite: true,
    // centerPadding: "200px",
    // autoplay: true,
    slidesToShow: 1,
    speed: 500,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          className: "center",
          dots: true,
          centerMode: false,
          arrows: false,
          infinite: false,
          slidesToShow: 1,
          speed: 500
        }
      },
      {
        breakpoint: 992,
        settings: {
          className: "center",
          dots: true,
          centerMode: false,
          arrows: false,
          infinite: false,
          slidesToShow: 1,
          speed: 500
        }
      }
    ]
  };
  return (
    <Fragment>
      <Row justify="center">
        <h1 style={{ fontSize: "42px", fontWeight: "400", marginTop: "40px" }}> Testimonials</h1>
      </Row>
      <div style={{ width: "100%", marginBottom: "60px" }}>
        <Slider {...settings}>
          <div>
            <TestimonialCard />
          </div>
          <div>
            <TestimonialCard />
          </div>
          <div>
            <TestimonialCard />
          </div>
        </Slider>
      </div>
    </Fragment>
  );
};

export default Testimonials;
