import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import useVariables from "../../../common/hooks/useVariables";
import { ROLES } from "../../../Config/helpers/constants";

function AuthSponsor() {
  const { FLAG, USERID, PID } = useVariables();
  const navigate = useNavigate();

  useEffect(() => {
    if (!USERID) navigate("/signin?sponsor=true");
    else if (FLAG === ROLES.player || FLAG === ROLES.organizer || !PID)
      navigate("/", { replace: true });
  }, [FLAG, PID, USERID, navigate]);
  if (FLAG && PID) return <Outlet />;
  return null;
}
export default AuthSponsor;
