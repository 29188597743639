import React, { useEffect } from "react";
import { Fragment } from "react";
import { Row, Col } from "antd";
import Testimonials from "./Testimonials";
import HowItWorks from "./HowItWorks";
import { useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import Searchbar from "./Searchbar/Searchbar";
const options = [];
for (let i = 10; i < 36; i++) {
  options.push({
    value: i.toString(36) + i,
    label: i.toString(36) + i
  });
}
// const handleChange = (value) => {
//   console.log(`selected ${value}`);
// };
const Home = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
    if (state === "failed") {
      Swal.fire({
        icon: "error",
        text: "Failed to Connect to server"
      });
    }
  }, [state, navigate]);

  return (
    <Fragment>
      <Row>
        <Col className="center-content" xs={24} sm={24} md={24} lg={11}>
          <h1 className="homefont margin-b-20">Reimagned Future of Sports</h1>

          <p className="margin-b-20">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
            has been the industry&apos;s standard dummy text ever since the 1500s, when an unknown.
          </p>
          <div style={{ padding: "0px" }}>
            {/* <Input
              size="large"
              style={{ borderRadius: "6px", lineHeight: "32px" }}
              placeholder="Explore"
              prefix={<FaSearch color="#8F8F8F" />}
            /> */}
            <Searchbar />
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={13} style={{ position: "relative" }}>
          <img className="homeimage" alt="img" width="95%" src="/images/homesports.png" />
        </Col>
      </Row>
      <HowItWorks />
      <Testimonials />
    </Fragment>
  );
};

export default Home;
