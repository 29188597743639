import React, { Fragment } from "react";
import { Row, Col } from "antd";
import FollowerFollowing from "./Follower_Following";
import { SideBar } from "../../common/sidebar";
import { useCurrentPage } from "../../common/hooks/useCurrentPage";
import { useLocation } from "react-router-dom";
function FollowFollowingContainer({ current }) {
  const { teamPanel } = useCurrentPage();
  const { state } = useLocation();
  return (
    <Fragment>
      <Row className="rowPadding">
        <SideBar
          description
          experience
          ratingPanel={teamPanel}
          location={state === "team" || state === "panel"}
          md
        />
        <Col xs={24} sm={24} md={24} lg={14} xl={16}>
          <FollowerFollowing current={current} />
        </Col>
      </Row>
    </Fragment>
  );
}

// Id    : is the id of profile we are watching e.g player , organizer etc
// TPId  : is Either Teams or Panels id , Each team or panel needs id in url for working

export default FollowFollowingContainer;
