import React from "react";
import { Menu, Dropdown } from "antd";
import { BsThreeDots } from "react-icons/bs";
import { PROFILE } from "../../../../Store/Features/ProfileSlice";
import { useSelector } from "react-redux";
import { EVENT } from "../../../../Store/Features/EventSlice";
import useVariables from "../../../hooks/useVariables";
function EditDots({ Editing, Post }) {
  const profile = useSelector(PROFILE);
  const event = useSelector(EVENT);
  const { PID } = useVariables();

  return PID && Editing ? (
    PID === profile?.profileId || PID === event?.organizer?._id || PID === profile?._id ? (
      <div className="threeDots" style={{ padding: 0 }}>
        <Dropdown overlay={<Menu items={Post} />} trigger={["click"]}>
          <a className={`ant-dropdown-link Nav_a`}>
            <BsThreeDots style={{ marginLeft: "0" }} />
          </a>
        </Dropdown>
      </div>
    ) : null
  ) : Editing ? (
    <div className="threeDots" style={{ padding: 0 }}>
      <Dropdown overlay={<Menu items={Post} />} trigger={["click"]}>
        <a className={`ant-dropdown-link Nav_a`}>
          <BsThreeDots style={{ marginLeft: "0" }} />
        </a>
      </Dropdown>
    </div>
  ) : null;
}

export default EditDots;
