import { useState } from "react";
import { RateEvent } from "../../Config/API/events";
import { error } from "../Alerts/Message";

const useRating = ({ defaultRating, rating, setRating, eventId }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);

  const showRatingModal = () => {
    setIsVisible(true);
  };

  const handleOk = async () => {
    setLoading(true);
    try {
      const res = await RateEvent({ rating, comment, eventId });
      const data = await res.json();

      if (data?.type === "success") {
        setIsVisible(false);
        setRating(data?.data?.rating || rating);
        setComment("");
      } else {
        setRating(0);
        setIsVisible(false);
        setComment("");
        error(data?.message || "Error occurred while rating");
      }
    } catch (error) {
      error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setIsVisible(false);
    setRating(defaultRating || 0);
  };

  return {
    showRatingModal,
    handleCancel,
    handleOk,
    comment,
    setComment,
    isVisible,
    ratingLoading: loading
  };
};

export default useRating;
