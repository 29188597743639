import { KEYS } from "../Keys";

export const GetPlayerPosts = async (id) => {
  const response = await fetch(KEYS.API + "/player/getplayerposts", {
    // signal: controller.signal,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify({ userId: id })
  });
  return response;
};

export const GetOrganizerPosts = async (values) => {
  const response = await fetch(KEYS.API + "/organizer/getorganizerposts", {
    // signal: controller.signal,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify({ userId: values })
  });
  return response;
};

export const GetTeamPosts = async (values) => {
  const response = await fetch(KEYS.API + "/team/getteamposts", {
    // signal: controller.signal,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify({ teamId: values })
  });
  return response;
};

export const GetPanelPosts = async (values, controller, page = 1, limit = 10) => {
  const response = await fetch(KEYS.API + `/panel/getpanelposts?page=${page}&limit=${limit}`, {
    // signal: controller.signal,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify({ panelId: values })
  });
  return response;
};
