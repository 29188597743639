import React from "react";
import { Col } from "antd";
import EventHistory from "./EventHistory";
import ExploreEvents from "./ExploreEvents";

function RightBar({ exploreevents, eventHistoryLInk, current }) {
  return (
    <Col
      className="scroll"
      span={24}
      lg={{ span: 6, order: 3 }}
      md={{ order: 2 }}
      sm={{ order: 2 }}
      xs={{ order: 2 }}>
      {exploreevents ? <ExploreEvents /> : null}
      <EventHistory linkTo={eventHistoryLInk} current={current} />
    </Col>
  );
}

export default RightBar;
