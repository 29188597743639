import React, { Fragment, useEffect, useState } from "react";
import { Col, Row } from "antd";
import RightBar from "../../../../common/righbar";
import { SideBar } from "../../../../common/sidebar";
import PastEventCard from "../../../../common/cards/Events/PastEventCard";
import { GetPlayerCurrentPastEvents } from "../../../../Config/API/events";
import { error } from "../../../../common/Alerts/Message";
import Loader from "../../../../common/cards/Loader";
import { useSelector } from "react-redux";
import { PROFILE } from "../../../../Store/Features/ProfileSlice";
import NoData from "../../../../common/cards/noData";
import EventChangeTabs from "../../../Organizer/Events/EventChangeTabs";
import { ROLES } from "../../../../Config/helpers/constants";

const PastEvents = () => {
  const ID = useSelector(PROFILE);

  const [Events, setEvents] = useState([]);
  const [loading, setloading] = useState(false);
  const getallevents = async () => {
    try {
      setloading(true);
      const response = await GetPlayerCurrentPastEvents(ID?._id, "past");
      const data = await response.json();

      setEvents(data?.result);
      setloading(false);
    } catch (e) {
      error(e || "An Error Occured");
      setloading(false);
    }
  };
  useEffect(() => {
    if (ID?._id) {
      getallevents();
    }
    return () => {
      setEvents(null);
      setloading(null);
    };
  }, [ID?._id]);
  return (
    <Fragment>
      <Row justify="space-between" className="rowPadding">
        {loading && <Loader fullpage loading={"white"} />}
        <SideBar description experience />
        <Col
          xs={{ span: 24, order: 3 }}
          sm={{ span: 24, order: 3 }}
          md={{ span: 24, order: 3 }}
          lg={{ span: 11, order: 2 }}>
          <Row className="mobileto">
            <EventChangeTabs type="past" user={ROLES.player} />

            {Events?.length > 0 ? (
              Events?.map((e, i) => <PastEventCard data={e} key={i} />)
            ) : (
              <NoData noEvent />
            )}
          </Row>
        </Col>
        <RightBar />
      </Row>
    </Fragment>
  );
};

export default PastEvents;
