import Swal from "sweetalert2";

export const serverError = (text) => {
  Swal.fire({
    icon: "error",
    title: text || "Internal Server Error",
    text: "Please try again later",
    confirmButtonColor: "#0085FF",
    allowOutsideClick: false
  });
};
