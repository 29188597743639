import React, { useRef } from "react";
import { Fragment, useState } from "react";
import { Row, Col, Form, Button } from "antd";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { signInAPI } from "../../Config/API/user";
import { ROLES } from "../../Config/helpers/constants";

import { error, Info } from "../../common/Alerts/Message";
import { Formik } from "formik";
import { loginValidation } from "../../Config/helpers/validations";
import InputTextField from "../../common/FormItem/InputTextField";

const Login = () => {
  const [SignInPhone, setSignInPhone] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const isSponsor = searchParams.get("sponsor");

  const onLogin = async (values) => {
    let response;

    let payload = { password: values?.password };
    if (SignInPhone) {
      payload = {
        ...payload,
        phone: values?.phoneNumber
      };
    } else {
      payload = {
        ...payload,
        email: values?.email
      };
    }

    try {
      setLoading(true);
      response = await signInAPI(payload);
      const data = await response.json();
      setLoading(false);

      if (data.type === "success") {
        localStorage.setItem("userToken", data.token);
        localStorage.setItem("userDetails", JSON.stringify(data.userDetails));

        if (data.userDetails.role.toLowerCase() === ROLES.organizer) {
          navigate("/organizer");
        }

        if (data.userDetails.role.toLowerCase() === ROLES.player) {
          navigate("/player");
        }

        if (data.userDetails.role.toLowerCase() === ROLES.sponsor) {
          navigate("/sponsor");
        }
      }
      if (data.type === "failure") {
        error(data?.result);
      }
      if (data.type === "failureEmail") {
        error(data?.result);
      }
    } catch (err) {
      setLoading(false);
      error(err);
    }
  };

  const counter = useRef(0);
  const { state } = useLocation();
  if (state === "error") {
    if (counter.current === 0) {
      Info("You are not authorized! Login Again");
      counter.current++;
    }
  }

  const initialValues = {
    email: "",
    password: "",
    phoneNumber: ""
  };
  return (
    <Fragment>
      <Row className="topRowPadding" justify="center">
        <Col md={12}>
          <Row className="middleRowPadding justifycenter">
            <Link to="/">
              <img alt="logo" height="100px" className="logouser" src="/images/logo.png" />
            </Link>
          </Row>
          <Row className="downMiddleRowPadding justifycenter">
            <h1 className="gameFont">Join the Game!</h1>
          </Row>
          <Row className="insidePadding justifycenter">
            <h1 className="insideFont">Go inside the best gamers social network!</h1>
          </Row>
          <Row className="signinAs justifycenter">
            {isSponsor ? <h1>Sponsor Sign In</h1> : <h1>Sign in as</h1>}
          </Row>
          <Row className="buttonsPadding" style={{ justifyContent: "space-evenly" }}>
            <button className="socialMediaButtons">
              <img alt="google.png" height="22px" width="22px" src="/images/google.png"></img>
            </button>
            <button className="socialMediaButtons">
              <img alt="twitter.png" height="21px" width="28px" src="/images/twitter.png"></img>
            </button>
            <button className="socialMediaButtons">
              <img alt="facebook.png" height="24px" width="24px" src="/images/fb.png"></img>
            </button>
            <button className="socialMediaButtons">
              <img
                alt="Discort.png"
                height="24px"
                width="24px"
                src="https://assets-global.website-files.com/6257adef93867e50d84d30e2/636e0a6a49cf127bf92de1e2_icon_clyde_blurple_RGB.png"></img>
            </button>
          </Row>
          <Row className="signInLink mt-5" justify="center">
            {!isSponsor ? (
              <p>
                Sign in as a Sponsor?
                <Link
                  to="/signin?sponsor=true"
                  onClick={() => setSearchParams("sponsor=yes")}
                  style={{ color: "#0085FF" }}>
                  {" "}
                  Sponsor Sign In
                </Link>
              </p>
            ) : (
              <p>
                Sign In as a player/organizer ?
                <Link to="/signin" onClick={() => setSearchParams("")} style={{ color: "#0085FF" }}>
                  {" "}
                  Sign In
                </Link>
              </p>
            )}
          </Row>
        </Col>
        <Col md={12}>
          <Row>
            <Col span={24}>
              <h1 className="signInCenter">{isSponsor ? "Sponsor Sign In" : "Sign In"}</h1>
            </Col>
          </Row>
          <Row justify="center">
            <Col md={2} lg={4}></Col>
            <Col md={20} lg={16} xs={24} sm={24}>
              {/* FORM */}
              <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={loginValidation(SignInPhone)}
                onSubmit={onLogin}>
                {({ handleSubmit, isValid, setFieldValue, dirty }) => (
                  <>
                    <Form
                      name="basic"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      initialValues={{ remember: true }}
                      onFinish={handleSubmit}>
                      {SignInPhone ? (
                        <InputTextField
                          name="phoneNumber"
                          label="Your Phone Number"
                          placeholder="Enter your Phone Number"
                          type="number"
                          hasFeedback
                          required
                        />
                      ) : (
                        <InputTextField
                          name="email"
                          label="Your Email"
                          placeholder="Enter your email"
                          type="email"
                          hasFeedback
                          required
                        />
                      )}

                      <InputTextField
                        name="password"
                        label="Your Password"
                        placeholder="Enter your password"
                        type="password"
                        hasFeedback
                        required
                      />

                      <Form.Item
                        style={{
                          display: "flex",
                          justifyContent: "flex-end"
                        }}>
                        <Link
                          to={"/forgotpassword"}
                          style={{
                            marginLeft: "auto",
                            color: "var(--blue)",
                            fontWeight: "500"
                          }}
                          type="link">
                          Forgot Password?
                        </Link>
                      </Form.Item>
                      <Form.Item style={{ justifyContent: "center" }}>
                        <Button
                          loading={loading}
                          className="submitButton"
                          type="primary"
                          htmlType="submit"
                          disabled={!(isValid && dirty)}>
                          Sign in
                        </Button>
                      </Form.Item>
                    </Form>
                    <Row justify="center">
                      {SignInPhone === false ? (
                        <>
                          <p>
                            Want to signIn with phone number?{" "}
                            <span
                              onClick={() => {
                                setFieldValue("email", "");
                                setSignInPhone(true);
                              }}
                              style={{ color: "#0085FF", cursor: "pointer" }}>
                              Click here
                            </span>
                          </p>
                        </>
                      ) : (
                        <>
                          <p>
                            Wanna Signin with email?{" "}
                            <span
                              onClick={() => {
                                setFieldValue("phoneNumber", "");
                                setSignInPhone(false);
                              }}
                              style={{ color: "#0085FF", cursor: "pointer" }}>
                              Click here
                            </span>
                          </p>
                        </>
                      )}
                    </Row>
                  </>
                )}
              </Formik>

              <Row justify="center">
                <p>
                  Do not have account?{" "}
                  <Link
                    style={{ color: "#0085FF" }}
                    to={isSponsor ? "/register?sponsor=true" : "/register"}>
                    Sign up
                  </Link>
                </p>
              </Row>
            </Col>
            <Col md={2} lg={4}></Col>
          </Row>
        </Col>
      </Row>
    </Fragment>
  );
};
export default Login;
