import React, { Fragment, useState, useEffect } from "react";
import { Button, Col, Row } from "antd";
import StaticFixtures from "./StaticFixtures";
import DraggableFixtures from "./DraggableFixtures";
import { error, Info, success } from "../../../../../../../common/Alerts/Message";
import Loader from "../../../../../../../common/cards/Loader";
import { CSSTransition } from "react-transition-group";

import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import {
  MATCHES,
  setMatches,
  load,
  unload,
  isLoading
} from "../../../../../../../Store/Features/MatchesSlice";
import {
  UpdateGroupFixtures,
  CreateGroupSlots,
  MoveToKnockOuts
} from "../../../../../../../Config/API/events";

const FixtureWrapper = ({ sortedpoints }) => {
  const Matches = useSelector(MATCHES);
  const loading = useSelector(isLoading);
  const dispatch = useDispatch();

  const [SwappedEle, setSwappedEle] = useState({
    status: "idle",
    ele: []
  }); //api loading

  const { state } = useLocation();
  const { id } = useParams();

  const getFixtures = async () => {
    try {
      dispatch(load());
      var res, data;
      res = await CreateGroupSlots({ eventId: state || id });
      data = await res.json();
      console.log(data);
      if (data?.type === "success") {
        success("Fixtures Loaded");
        await sortedpoints(data?.data);
        dispatch(setMatches(data?.data));
      } else {
        error(data?.result);
      }
      dispatch(unload());
    } catch (e) {
      console.log(e);
      error(e.message);
      dispatch(unload());
    }
  };

  if (Object.keys(Matches)?.length === 0) return null;

  return (
    <Fragment>
      {Matches?.matchesSlot?.length === 0 ? (
        <Row justify="center">
          <Button
            type="primary"
            style={{ fontWeight: "600", marginTop: "20px", marginBottom: "4%" }}
            className="blueButton equalWidhtButton"
            onClick={() => {
              getFixtures();
            }}>
            Generate Fixture
          </Button>
        </Row>
      ) : (
        <FixtureContent
          items={Matches?.matchesSlot}
          id={id}
          loading={loading}
          SwappedEle={SwappedEle}
          setSwappedEle={setSwappedEle}
          sortedpoints={sortedpoints}
        />
      )}
    </Fragment>
  );
};

const FixtureContent = ({ items, id, loading, SwappedEle, setSwappedEle, sortedpoints }) => {
  const isNotWinner = items?.find((e) => e?.match?.win === undefined || !e?.match?.win);

  const [isEditable, setIsEditble] = useState(false);
  const [copyItems, setcopyItems] = useState(items);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => setcopyItems(items), [items]);

  useEffect(() => {
    return () => {
      setcopyItems([]);
    };
  }, []);

  //Match Swap Api
  const SwapAPI = async () => {
    try {
      setSwappedEle({
        status: "loading",
        ele: [...SwappedEle.ele]
      });

      var values = {
          matches: copyItems,
          eventId: id
        },
        res = null,
        data = null;
      console.log(copyItems, items);
      console.log("orignal", items);
      console.log("swapped", copyItems);
      res = await UpdateGroupFixtures(values);
      data = await res.json();
      if (data?.type === "success") {
        console.log("swap api", data);
        success("Schedule Edited Succesfully");
      } else {
        error(data?.result);
      }
      setSwappedEle({
        status: "idle",
        ele: []
      });
    } catch (e) {
      console.log(e);
      error(e.message);
      setSwappedEle({
        status: "idle",
        ele: []
      });
    }
  };
  //Match Swap Api
  const canmovetokonckout = true;

  const MoveToknockoutApiCall = async () => {
    try {
      if (!canmovetokonckout) return Info("winners must be selected first!");
      var values = {
          eventId: id
        },
        res = null,
        data = null;
      dispatch(load());
      res = await MoveToKnockOuts(values);
      data = await res.json();
      if (data?.type === "success") {
        console.log("move api", data);
        dispatch(unload());
        success(data?.result);
        navigate(`/organizer/event/${id}/knock-out-matches`, {
          replace: true,
          state
        });
      } else {
        dispatch(unload());
        error(data?.result);
      }
    } catch (e) {
      console.log(e);
      error(e.message);
      dispatch(unload());
    }
  };

  return (
    <Fragment>
      <Fragment>
        {SwappedEle?.status === "loading" ? (
          <Loader small noShadow />
        ) : (
          <div className="">
            <Row justify="center">
              <Col xl={16} lg={24} xs={24} sm={24} md={24} className="ceinputformpadding">
                <h2
                  style={{
                    borderBottom: "1px solid  #282828",
                    paddingBottom: "5px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingTop: "30px"
                  }}>
                  Fixtures
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      gap: "5px",
                      flexWrap: "wrap"
                    }}>
                    {isNotWinner ? (
                      <Fragment>
                        {SwappedEle?.ele?.length > 0 ? (
                          <Button
                            onClick={() => SwapAPI()}
                            style={{ minWidth: "100px", margin: "unset" }}
                            className="btnMobileDimensions blueButton equalWidhtButton m-0"
                            type="primary">
                            save
                          </Button>
                        ) : null}
                        <Button
                          className="btnMobileDimensions blueButton equalWidhtButton m-0"
                          type="primary"
                          style={{ minWidth: "100px", margin: "unset" }}
                          onClick={() => setIsEditble((e) => !e)}>
                          {isEditable ? "Cancel" : "Edit"}
                        </Button>
                      </Fragment>
                    ) : (
                      <Button
                        className="btnMobileDimensions blueButton equalWidhtButton m-0"
                        type="primary"
                        style={{ margin: "unset", minWidth: "max-content" }}
                        onClick={MoveToknockoutApiCall}>
                        Move to Knockout
                      </Button>
                    )}
                  </span>
                </h2>
              </Col>
            </Row>
          </div>
        )}
      </Fragment>

      <Row justify="center">
        {loading ? (
          <Col xl={16} lg={24} xs={24} sm={24} md={24} className="ceinputformpadding">
            <Loader smaller loading={"white"} />
          </Col>
        ) : (
          <Col
            xl={16}
            lg={24}
            xs={24}
            sm={24}
            md={24}
            className="  SmallScroll  ceinputformpadding"
            style={{
              paddingTop: "20px",
              marginBottom: "20px",
              maxHeight: "500px",
              overflowY: "scroll"
            }}>
            <CSSTransition in={isEditable} timeout={100} classNames="fade" unmountOnExit>
              <Fragment>
                {isEditable && (
                  <DraggableFixtures
                    items={items}
                    copy={copyItems}
                    setcopy={setcopyItems}
                    setSwappedEle={setSwappedEle}
                    SwappedEle={SwappedEle}
                    sortedpoints={sortedpoints}
                  />
                )}
              </Fragment>
            </CSSTransition>

            <CSSTransition in={isEditable === false} timeout={100} classNames="fade" unmountOnExit>
              <Fragment>
                {isEditable === false && (
                  <StaticFixtures items={items} sortedpoints={sortedpoints} />
                )}
              </Fragment>
            </CSSTransition>
          </Col>
        )}
      </Row>
    </Fragment>
  );
};

export default FixtureWrapper;
