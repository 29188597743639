import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  event: {},
  loading: false
};

export const EventSlice = createSlice({
  name: "event",
  initialState,
  reducers: {
    setEVENT: (state, action) => {
      state.event = action.payload;
    },
    load: (state) => {
      state.loading = true;
    },
    unload: (state) => {
      state.loading = false;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setEVENT, load, unload } = EventSlice.actions;
export const EVENT = (state) => state.event.event;
export const isLoading = (state) => state.event.loading;

export default EventSlice.reducer;
