import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { calculateIndividualRankPoints } from "../../utils/utilityFunctions";

/**
 *
 * @param {*} firstRanksPoints
 * @param {*} lastRanksPoints
 */
function getEventIndexesAndRankPoints(firstRankPoints, lastRankPoints) {
  const eventData = useSelector((state) => state.matches);
  const [individualRankPoints, setIndividualRankPoints] = useState([]);

  // get current match and group index
  const currentRoundIndex = eventData?.currntgroupindex?.currentourndindex;
  const currentGroupIndex = eventData?.currntgroupindex?.gindex;
  const currentMatchIndex = eventData?.currntgroupindex?.submatchindex;
  // current group data by index
  const currentGroupData = eventData?.matches?.rounds?.[currentRoundIndex]?.[currentGroupIndex];
  // get current match day teams in a group details from the group by index
  const teamsInGroup = currentGroupData?.matches?.[currentMatchIndex]?.table?.length;

  // calculate individual rank points
  useEffect(() => {
    const rankPoints = calculateIndividualRankPoints({
      firstRankPoints,
      lastRankPoints,
      teamsInGroup
    });
    setIndividualRankPoints(rankPoints);
  }, [firstRankPoints, lastRankPoints, teamsInGroup, eventData]);

  return {
    teamsInGroup,
    currentRoundIndex,
    currentGroupIndex,
    currentMatchIndex,
    individualRankPoints
  };
}

export default getEventIndexesAndRankPoints;
