import React, { Fragment, useState, useEffect } from "react";
import { Col, Row, Button, Grid, Tabs, Space } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";

import { KEYS } from "../../../Config/Keys";
import Loader from "../../../common/cards/Loader";
import {
  followFunction,
  unFollowProfile,
  getactivefollowfollowers,
  checkIsAccepted,
  fallbackprofile
} from "../../../common/Utils";
import { useDispatch, useSelector } from "react-redux";
import { PROFILE, setProfile } from "../../../Store/Features/ProfileSlice";
import { useFetchProfile } from "../../../common/hooks/useFetchProfile";
import { useCurrentPage } from "../../../common/hooks/useCurrentPage";
import FadeInImage from "../../../common/Images/FadeInImage";
import useVariables from "../../../common/hooks/useVariables";
import { ROLES } from "../../../Config/helpers/constants";

const { useBreakpoint } = Grid;
const { TabPane } = Tabs;

const PlayerTLNav = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setloading] = useState(false);
  const [isFollowing, setisFollowing] = useState(false);
  const [accepted, setaccpted] = useState(false);

  const { id } = useParams();
  const { url, other } = useCurrentPage();
  const { viewer, PID } = useVariables();

  //id =other user, viewer=current user
  const fetchid = id ? id : viewer?.id;

  //api call for fetching profile
  const { profileLoding } = useFetchProfile(fetchid, "player", setisFollowing);

  //fetched player
  let player = useSelector(PROFILE);
  const followers = getactivefollowfollowers(player?.followers);
  const followings = getactivefollowfollowers(player?.followings);

  useEffect(() => {
    // if the player is viewing it's own profile navigate him to his screen
    if (id === PID) {
      navigate("/player/timeline");
    }
  }, [id, PID]);

  useEffect(() => {
    //checking for follow request
    checkIsAccepted(setaccpted, followers, viewer);
  }, [followers?.length, isFollowing]);

  const FollowFollowingButton = () => {
    return (
      <Row justify="center">
        <p>
          <Button
            onClick={() =>
              navigate(
                `/${
                  viewer?.flag === ROLES.player
                    ? "player"
                    : viewer?.flag === ROLES.sponsor
                    ? "sponsor"
                    : "organizer"
                }/messages/chat`,
                {
                  state: {
                    id: id,
                    role: "player",
                    profilepic: player.profile,
                    name: player.name
                  }
                }
              )
            }
            className="profilebuttons blueButton btnMobileDimensions"
            style={{
              height: "auto",
              padding: "6px 10px",
              margin: "0",
              color: "white",
              marginRight: "5px"
            }}>
            Chat
          </Button>
        </p>
        <p>
          {isFollowing ? (
            <Button
              loading={loading === "unfollow" && true}
              onClick={() => {
                unFollowProfile(
                  player,
                  null,
                  "player",
                  setloading,
                  setisFollowing,
                  "otherprofile",
                  setProfile,
                  dispatch,
                  setaccpted
                );
              }}
              className="profilebuttons blueButton btnMobileDimensions"
              style={{
                height: "auto",
                padding: "6px 10px",
                margin: "0",
                color: "white"
              }}>
              {accepted ? "UnFollow" : "Cancel Request"}
            </Button>
          ) : (
            <Button
              loading={loading === "follow" && true}
              onClick={() =>
                followFunction(
                  id,
                  setloading,
                  setisFollowing,
                  null,
                  setProfile,
                  dispatch,
                  setaccpted
                )
              }
              className="addtocalendar profilebuttons btnMobileDimensions"
              style={{
                height: "auto",
                padding: "6px 10px",
                margin: "0",
                color: "white"
              }}>
              Follow
            </Button>
          )}
        </p>
      </Row>
    );
  };
  const EditButton = () => {
    return (
      <p>
        <Button
          onClick={() => navigate(`/player/editprofile/${player?._id}`, { state: "player" })}
          className="addtocalendar profilebuttons btnMobileDimensions"
          style={{
            height: "auto",
            padding: "6px 10px",
            margin: "0",
            color: "white"
          }}>
          Edit Profile
        </Button>
      </p>
    );
  };

  //checking if current user id !== fetched user id, (to toggle follow and edit buttons)
  const condition = id !== PID;
  return (
    <Fragment>
      {profileLoding ? <Loader fullpage loading="white" /> : null}
      <Row>
        <Col span={24} className="toheader">
          <FadeInImage
            preview
            type={"Fadecover"}
            className="tocover"
            src={KEYS.API + "/" + player?.cover}
          />

          <FollowersFollowingDataComp
            url={url}
            other={other}
            player={player}
            followers={followers}
            followings={followings}
            id={id}
          />
        </Col>
      </Row>
      <Row wrap="wrap" align="top" justify="space-between" className="tosubnav tosubnav1">
        <Col className="tosubnavcenter" xl={3} lg={3} md={4} sm={6} xs={24}>
          <div className="abs">
            <FadeInImage
              preview
              type="Fadeprofile"
              fallaback={fallbackprofile}
              src={KEYS.API + "/" + player?.profile}
            />
          </div>

          <Space direction="vertical" size="small">
            <h2
              className="toname"
              style={{
                textAlign: "center",
                margin: "0px 0 0 0",
                minWidth: "170px",
                fontSize: 20
              }}>
              {player?.name || "Dummy Name"}{" "}
            </h2>
            {player?.username && (
              <p style={{ fontSize: "12px", color: "gray", margin: "0px" }}>
                ( {player?.username} )
              </p>
            )}
          </Space>
        </Col>
        <SubLinksWrapper
          player={player}
          other={other}
          url={url}
          PID={PID}
          id={id}
          condition={condition}
          EditButton={EditButton}
          FollowFollowingButton={FollowFollowingButton}
        />
      </Row>
      {/* Wrapped Row for Follow And Edit Buttons in mobile Devices */}
      <XsButtonsRow
        other={other}
        condition={condition}
        EditButton={EditButton}
        FollowFollowingButton={FollowFollowingButton}
      />
    </Fragment>
  );
};

const FollowersFollowingDataComp = ({ url, other, player, followers, followings, id }) => {
  return (
    <div className="tofollower">
      <div className={url === "followers" ? "totitle totitleBlue" : "totitle"}>
        {other ? (
          <Link to={`/view/player/${player?._id || id}/followers`}>
            <p className="toFollow">Followers</p>
            <p className="tocount">{followers?.length}</p>
          </Link>
        ) : (
          <Link to={`/player/followers`}>
            <p className="toFollow">Followers</p>
            <p className="tocount"> {followers?.length}</p>
          </Link>
        )}
      </div>
      <div className={url === "followings" ? "totitle totitleBlue" : "totitle"}>
        {other ? (
          <Link to={`/view/player/${player?._id || id}/followings`}>
            <p className="toFollow"> Followings</p>
            <p className="tocount"> {followings?.length}</p>
          </Link>
        ) : (
          <Link to={`/player/followings`}>
            <p className="toFollow"> Followings</p>
            <p className="tocount"> {followings?.length}</p>
          </Link>
        )}
      </div>
    </div>
  );
};
const XsButtonsRow = ({ condition, other, EditButton, FollowFollowingButton }) => {
  return condition ? (
    <Row className="tosubnav d-none d-md-block">
      <Col span={24}>
        {other ? (
          <Col style={{ marginLeft: "auto" }} className="tosubnavcenter">
            <FollowFollowingButton />
          </Col>
        ) : (
          <Col style={{ marginLeft: "auto" }} className="tosubnavcenter">
            <EditButton />
          </Col>
        )}
      </Col>
    </Row>
  ) : null;
};

const SubLinksWrapper = ({
  player,
  other,
  url,
  PID,
  id,
  condition,
  EditButton,
  FollowFollowingButton
}) => {
  const screens = useBreakpoint();
  return (
    <Fragment>
      {screens["sm"] ? (
        <SubLinksDesktop
          player={player}
          other={other}
          url={url}
          PID={PID}
          id={id}
          condition={condition}
          EditButton={EditButton}
          FollowFollowingButton={FollowFollowingButton}
        />
      ) : (
        <SubLinksTabs player={player} other={other} url={url} id={id} />
      )}
    </Fragment>
  );
};
const SubLinksDesktop = ({
  player,
  other,
  url,
  PID,
  id,
  condition,
  EditButton,
  FollowFollowingButton
}) => {
  return (
    <Col
      xl={20}
      lg={19}
      md={17}
      sm={15}
      xs={24}
      style={{ paddingTop: other && player?._id === PID ? "7px" : undefined }}>
      <Row className="subnavLinks noScroll">
        <Col className="tosubnavcenter">
          <Link to={other ? `/view/player/${player?._id || id}/timeline` : "/player/timeline"}>
            <p className={url === "timeline" ? "toactive" : null}> Timeline</p>
          </Link>
        </Col>

        <Col className="tosubnavcenter">
          <Link to={other ? `/view/player/${player?._id || id}/media` : "/player/media"}>
            <p className={url === "media" ? "toactive" : null}> Media </p>
          </Link>
        </Col>
        <Col className="tosubnavcenter">
          <Link to={other ? `/view/player/${player?._id || id}/about` : "/player/about"}>
            <p className={url === "about" ? "toactive" : null}>About</p>
          </Link>
        </Col>
        {!other && (
          <Col className="tosubnavcenter">
            <Link to="/player/wallet">
              <p className={url === "wallet" ? "toactive" : null}>Wallet</p>
            </Link>
          </Col>
        )}
        {condition ? (
          <Fragment>
            {other ? (
              <Col style={{ marginLeft: "auto" }} className="tosubnavcenter d-md-none">
                <FollowFollowingButton />
              </Col>
            ) : (
              <Col style={{ marginLeft: "auto" }} className="tosubnavcenter d-md-none">
                <EditButton />
              </Col>
            )}
          </Fragment>
        ) : null}
      </Row>
    </Col>
  );
};
const SubLinksTabs = ({ player, other, url, id }) => {
  const arr = [
    {
      label: "timeline",
      url: other ? `/view/player/${player?._id || id}/timeline` : `/player/timeline`,
      key: "timeline"
    },
    {
      label: "media",
      url: other ? `/view/player/${player?._id || id}/media` : `/player/media`,
      key: "media"
    },
    {
      label: "about",
      url: other ? `/view/player/${player?._id || id}/about` : `/player/about`,
      key: "about"
    }
  ];

  if (!other) {
    arr?.push({
      label: "wallet",
      url: "/player/wallet",
      key: "wallet"
    });
  }

  const navigate = useNavigate();

  return (
    <Tabs
      tabPosition={"top"}
      centered
      activeKey={url}
      size="small"
      onChange={(e) => {
        navigate(e);
      }}
      className="MobileSubNavTabLinks">
      {arr.map(({ label, key }) => (
        <TabPane tab={label} key={`${key}`} />
      ))}
    </Tabs>
  );
};

export default PlayerTLNav;
