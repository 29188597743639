import React, { Fragment, useState } from "react";
import { Col, Row, Card, Skeleton } from "antd";
import { error, Info, success } from "../../../../../../common/Alerts/Message";
import { AssignWinner } from "../../../../../../Config/API/events";
import { useParams } from "react-router-dom";
import MatchTeam from "./EditBtns/Team";
import VS from "./EditBtns/VS";
import { BiXCircle } from "react-icons/bi";

const EditMatchesCard = ({
  item,
  index, //index of this element
  Orderloading, //loading while swapping,Button Loading
  SwappedEle, //API loading, Skeleton Loading
  sortedpoints,
  seteditresult
}) => {
  const { id } = useParams();
  const t1 = item?.match["team1"]?._id;
  const lose = item?.match?.win === t1 ? "t2" : "t1";
  const draw = item?.match?.win === "draw";

  const [loading, setloading] = useState(false);

  //assign winner api call
  const assignWinner = async (item, teamno) => {
    try {
      const matchId = item?.match?._id;

      let winner = teamno === 1 ? item?.match["team1"]?._id : item?.match["team2"]?._id;
      let loser = teamno === 1 ? item?.match["team2"]?._id : item?.match["team1"]?._id;

      if (!winner || !loser || !id || !matchId) return Info("variables are missing");

      setloading(true);
      var values = {
          matchId,
          loser,
          winner,
          eventId: id,
          winnerExistCheck: "exist",
          winPrevious: draw ? "draw" : "nodraw"
        },
        res = null,
        data = null;
      res = await AssignWinner(values);
      data = await res.json();
      console.log(data);
      if (res.status === 409 || data?.type === "conflict") {
        return Info(data?.result);
      } else if (data?.type === "success") {
        success(data?.result);
        await sortedpoints(data?.data[0]);
        seteditresult((e) => !e);
      } else {
        error(data?.result);
      }
      setloading(false);
    } catch (e) {
      setloading(false);
      console.log(e);
      error(e.message);
    }
  };

  const CardHead = () => {
    return (
      <div>
        <span>Edit Cancel</span>
        <BiXCircle style={{ color: "var(--red)" }} onClick={() => seteditresult((e) => !e)} />
      </div>
    );
  };

  return (
    <Fragment>
      <Card
        title={<CardHead />}
        className="tablesBoxShadow MatchCard"
        bordered={false}
        style={{
          padding: "0px",
          marginTop: "10px"
        }}>
        {SwappedEle?.ele?.includes(index) && SwappedEle?.status === "loading" ? (
          <Skeleton.Button
            active
            style={{ minHeight: "78px", width: "100%" }}
            size="large"
            shape="square"
            block></Skeleton.Button>
        ) : (
          <Row>
            <Col lg={10} xs={8} sm={8} md={8}>
              <MatchTeam
                t={1}
                draw={draw}
                item={item}
                lose={lose}
                loading={loading}
                Orderloading={Orderloading}
                assignWinner={assignWinner}
              />
            </Col>
            <Col lg={4} md={8} sm={8} xs={8}>
              <VS
                item={item}
                index={index}
                loading={loading}
                setloading={setloading}
                Orderloading={Orderloading}
                seteditresult={seteditresult}
              />
            </Col>
            <Col lg={10} xs={8} sm={8} md={8}>
              <MatchTeam
                t={2}
                draw={draw}
                lose={lose}
                item={item}
                loading={loading}
                Orderloading={Orderloading}
                assignWinner={assignWinner}
              />
            </Col>
          </Row>
        )}
      </Card>
    </Fragment>
  );
};

export default EditMatchesCard;
