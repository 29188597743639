import React, { useState } from "react";
import { Info, error } from "../../../../../../../common/Alerts/Message";
import { MultiplayerPublishRound } from "../../../../../../../Config/API/multiplayerSchedule";
import { Modal, Typography } from "antd";
const { Text } = Typography;

function PublishRoundConfirmationModal({ id, round, setOpen, open, getData }) {
  const [loading, setLoading] = useState(false);
  const publishRound = async () => {
    setLoading(true);
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));

    const values = {
      //   text: `Object:'{"url":"${window.location.origin}/view/event/${id}/multiplayermatches?round=${round}","for":"Round Published","round":${round}}'`,
      text: `Object:'{"url":"${window.location.origin}/view/event/${id}/multiplayermatches?round=${round}","for":"Round Published","round":${round}}'`,
      eventId: id,
      user: userDetails?.id,
      userRole: "Organizer"
    };
    try {
      const res = await MultiplayerPublishRound(values);
      await res.json();
      Info("Round Published successfully");
      // get updated data again
      getData();
      setLoading(false);
      setOpen(false);
    } catch (e) {
      console.log(e);
      error(e.message);
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <Modal
      title="Publish Round Confirmation"
      open={open}
      onOk={publishRound}
      confirmLoading={loading}
      onCancel={handleCancel}>
      <Text strong>Are you sure you want to publish current round ?</Text>
      <blockquote>
        Once Published a post will be created and all teams and players will be notified
      </blockquote>
    </Modal>
  );
}

export default PublishRoundConfirmationModal;
