import React from "react";
import { Typography, Row, Col, Form, Input, Radio } from "antd";
import { useEffect, useState } from "react";
import Loader from "../../cards/Loader";
const { Title } = Typography;

const ProfileSetting = ({ loading, profile, toggleprofile }) => {
  const [status, setstatus] = useState();
  const [pnumber, setpnumber] = useState();
  const [fname, setfname] = useState();
  const [lname, setlname] = useState();
  const [nickname, setnickname] = useState();
  const [role, setrole] = useState();

  useEffect(() => {
    setstatus(profile?.profileType);
    // const name = String(profile?.name).split(" ")

    setfname(profile?.user?.firstName);
    setlname(profile?.user?.lastName);
    // setlname(name[1])
    setnickname(profile?.nickname || profile?.user?.nickName);
    setpnumber(profile?.user?.phone);
    setrole(profile?.user?.primaryRole);

    return () => {
      setstatus("");
      setfname("");
      setlname("");
      setnickname(null);
      setpnumber(null);
      setrole(null);
    };
  }, [profile]);
  return (
    <div className="settingsComponent list">
      <Typography className="title">
        <Title level={3}>Profile Setting</Title>
      </Typography>
      {loading ? (
        <Loader noShadow height="405px" />
      ) : (
        <div className="formorganizer">
          <Row justify="space-between">
            <Col lg={11} md={11} xs={24} sm={24} className="ceinputformpadding">
              <Form
                name="basic"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                autoComplete="off">
                <Form.Item className="profileItems border">
                  <label className="boldlabel">First Name</label>
                  <Input
                    value={fname}
                    onChange={(e) => setfname(e.currentTarget.value)}
                    autoComplete="false"
                    type="text"
                    className="playerformfield"
                    placeholder="Enter your First Name"

                    // style={{ width: "100%" }}
                  />
                </Form.Item>
                <Form.Item className="profileItems border">
                  <label className="boldlabel">Last Name</label>
                  <Input
                    value={lname}
                    onChange={(e) => setlname(e.currentTarget.value)}
                    autoComplete="false"
                    type="text"
                    className="playerformfield"
                    placeholder="Enter your Last Name"

                    // style={{ width: "100%" }}
                  />
                </Form.Item>

                {String(
                  JSON.parse(localStorage.getItem("userDetails"))?.role === "player"
                ).toLowerCase() ? (
                  <Form.Item className="profileItems border">
                    <label className="boldlabel">NickName</label>

                    <Input
                      value={nickname}
                      onChange={(e) => setnickname(e.currentTarget.value)}
                      className="playerDob"
                      placeholder="e.g Islamabad"
                    />
                  </Form.Item>
                ) : null}
              </Form>
            </Col>
            <Col sm={24} xs={24} md={11} lg={11} className="ceinputformpadding">
              {" "}
              <Form labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                <Form.Item
                  help={
                    pnumber?.length === 0
                      ? ""
                      : pnumber?.length !== 10
                      ? "length should be 10 digits"
                      : null
                  }
                  validateStatus={
                    pnumber?.length === 0 ? "" : pnumber?.length === 10 ? "success" : "error"
                  }
                  hasFeedback
                  name="phone"
                  className="profileItems">
                  <label className="boldlabel">Phone Number</label>
                  <Input
                    value={pnumber}
                    className="inputnumber"
                    onChange={(e) => setpnumber(e.currentTarget.value)}
                    type="number"
                    style={{
                      borderRadius: "5px",
                      lineHeight: "38px"
                    }}
                    placeholder="Enter your number"
                    addonBefore="+92"
                  />
                </Form.Item>
                <Form.Item name="radio-group" className="profileItems">
                  <label className="boldlabel">Primary Role</label>
                  <Radio.Group
                    value={role}
                    onChange={(e) => setrole(e.target.value)}
                    style={{ marginTop: "9px" }}>
                    <Radio value="Player">Player</Radio>
                    <Radio value="Organizer">Organizer</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item name="radio-group" className="profileItems">
                  <label className="boldlabel">Profile Status</label>
                  <Radio.Group
                    value={status}
                    onChange={(e) => {
                      const val = e.target.value;
                      setstatus(val);
                      toggleprofile(val);
                    }}
                    style={{ marginTop: "9px" }}>
                    <Radio value="public">Public</Radio>
                    <Radio value="private">Private</Radio>
                  </Radio.Group>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default ProfileSetting;
