import React, { useState, useEffect } from "react";
import { Row, Col, Tabs } from "antd";
import SearchBar from "./SearchBar";
// import InvitedPanelMembers from './InvitedPanelMembers'
import AllPanelMembers from "./AllPanelMembers";
import { useLocation, useNavigate } from "react-router-dom";
const { TabPane } = Tabs;
function AddPanelMembers() {
  const navigate = useNavigate();
  const [inputState, setInputState] = useState("");
  const onChange = (e) => {
    console.log(e);
  };

  //  state is panel id coming from panel members component

  const { state } = useLocation();
  const { id, members } = state;

  useEffect(() => {
    id === null && navigate("/");
  }, [navigate, id]);

  return (
    <div className="toheader" style={{ marginTop: "0", marginBottom: "50px" }}>
      <h1 className="ceheading"> Add Panel Members </h1>
      <Row className="formorganizer">
        <SearchBar inputState={inputState} setInputState={setInputState} />
        <Col span={24}>
          <Tabs defaultActiveKey="1" onChange={onChange}>
            <TabPane tab="All" key="1">
              <AllPanelMembers input={inputState} panelId={id} members={members} />
            </TabPane>
            {/* <TabPane tab="Invited" key="2">
                            <InvitedPanelMembers input={inputState} />
                        </TabPane> */}
          </Tabs>
        </Col>
      </Row>
    </div>
  );
}

export default AddPanelMembers;
