import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "antd";
import Loader from "../../../../common/cards/Loader";
import { GetTeams } from "../../../../Config/API/teams";

import TeamCard from "../Card/Card";
import NoData from "../../../../common/cards/noData";
import { useLocation } from "react-router-dom";
import { error } from "../../../../common/Alerts/Message";
import useVariables from "../../../../common/hooks/useVariables";
import { EVENT_AND_TEAM_ROLES } from "../../../../Config/helpers/constants";

function MyTeams() {
  const [TEAMS, setTEAMS] = useState([]);
  const [JoinedTeams, setJoinedTeams] = useState([]);
  const [owned, setOwned] = useState("primary");
  const [joined, setJoined] = useState("");
  const [loading, setloading] = useState(false);

  const { viewer } = useVariables();
  var counter = 0;
  const id = viewer?.id;

  const getTeams = async () => {
    try {
      counter++;
      setloading(true);

      const res = await GetTeams({ id });
      const data = await res.json();
      if (data.type !== "success") {
        if (counter < 2) getTeams();
        else {
          error(data?.result);
          setloading(false);
        }
      } else {
        // teams which were created by the player or was made captain of the team
        const myTeams = data?.result?.filter(
          (e) =>
            e?.profile?._id === id ||
            e?.members?.find(
              (member) =>
                member?.player?._id === id && member?.role === EVENT_AND_TEAM_ROLES.captain
            )
        );
        setTEAMS(myTeams);
        const joinedTeams = data?.result?.filter(
          (e) =>
            e?.profile?._id !== id &&
            e?.members?.find(
              (member) =>
                member?.player?._id === id && member?.role !== EVENT_AND_TEAM_ROLES.captain
            )
        );
        setJoinedTeams(joinedTeams);
      }
      setloading(false);
    } catch (e) {
      if (counter < 2) getTeams();
      else {
        setloading(false);
        error(e.message || e);
      }
    }
  };

  const { pathname } = useLocation();
  useEffect(() => {
    getTeams();
    return () => {
      setTEAMS(null);
      setJoinedTeams(null);
      setJoined(null);
      setOwned(null);
      setloading(null);
    };
  }, [pathname]);

  const CardsContainer = () => (
    <Row style={{ marginTop: "15px" }}>
      {owned === "primary" ? (
        TEAMS?.length > 0 ? (
          TEAMS?.map((e, i) => <TeamCard key={i} type={"primary"} data={e} />)
        ) : (
          <div style={{ width: "95%", margin: "auto" }}>
            <NoData noTeam />
          </div>
        )
      ) : null}
      {joined === "primary" ? (
        JoinedTeams?.length > 0 ? (
          JoinedTeams?.map((e, i) => <TeamCard view key={i} type={"primary"} data={e} />)
        ) : (
          <div style={{ width: "95%", margin: "auto" }}>
            <NoData noTeam />
          </div>
        )
      ) : null}
    </Row>
  );

  return (
    <Row className="rowPadding" justify="center">
      {loading && <Loader fullpage loading="white" />}
      <Col xs={24} sm={24} md={24} lg={14} xl={16}>
        <Row className="mdWidth">
          <Col lg={14} md={20} sm={20} xs={24}>
            <Button
              style={{ marginLeft: "0", marginTop: ".2rem" }}
              className="buttonpo"
              onClick={() => {
                setOwned("primary");
                setJoined("");
              }}
              type={owned}>
              My Teams
            </Button>
            <Button
              style={{ marginLeft: "0", marginTop: ".2rem" }}
              className="buttonpo"
              type={joined}
              onClick={() => {
                setOwned("");
                setJoined("primary");
              }}>
              Joined Teams
            </Button>
          </Col>
          {loading ? (
            <Loader />
          ) : (
            <Col span={24}>
              <CardsContainer />
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
}

export default MyTeams;
