import { useEffect, useState } from "react";
import { error } from "../Alerts/Message";
import { PlayerGetProfileAPI } from "../../Config/API/player";
import { GetProfileAPI } from "../../Config/API/organizer";
import { useDispatch, useSelector } from "react-redux";
import {
  PROFILE,
  setProfile as set,
  setinitialloading,
  isInitalloading
} from "../../Store/Features/ProfileSlice";
import { useNavigate } from "react-router-dom";
import { ROLES } from "../../Config/helpers/constants";
import { GetSponsorProfileAPI } from "../../Config/API/sponsor";
import { GetUserWallet } from "../../Config/API/Wallets";
import { SetFlexToken } from "../../Store/Features/WalletSlice";

export function useFetchInitialProfile(role) {
  const profileLoding = useSelector(isInitalloading);
  const Profile = useSelector(PROFILE);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [profileCompleted, setProfileCompleted] = useState(false);

  const getUserWallet = async (userId) => {
    const response = await GetUserWallet(role, userId);
    const data = await response.json();
    if (data?.type === "success") {
      const walletData = Array.isArray(data?.result) ? data?.result?.[0] : data?.result;

      // to show amount in header dropdown
      if (ROLES.organizer || ROLES.player) {
        dispatch(SetFlexToken(walletData?.balance || 0));
      }
    }
  };

  const GetProfile = async (controller) => {
    dispatch(set({}));
    dispatch(setinitialloading(true));
    try {
      let res, data;
      if (role === ROLES.player) {
        res = await PlayerGetProfileAPI(controller);
      } else if (role === ROLES.organizer) {
        res = await GetProfileAPI(controller);
      } else if (role === ROLES.sponsor) {
        res = await GetSponsorProfileAPI(controller);
      }
      if (res?.statusText === "Unauthorized") {
        // Info("You are not authorized! Login Again")
        localStorage.removeItem("userDetails");
        localStorage.removeItem("userToken");
        navigate("/signin", { state: "error" });
      }
      data = await res.json();
      if (data.type !== "success") {
        dispatch(setinitialloading(false));
        error(data?.result);
      } else {
        // setProfile(data?.result || [])
        if (data?.data === true) {
          localStorage.setItem("ProfileID", data?.profile._id);
          const d2 = {
            id: data?.profile?._id,
            flag: role,
            name: data?.profile?.name,
            profile: data?.profile?.profilepic,
            cover: data?.profile?.coverpic,
            description: data?.profile?.description
          };
          localStorage.setItem("ProfileID", data?.profile?._id);
          localStorage.setItem("viewer", JSON.stringify(d2));

          if (role === ROLES.player || role === ROLES.organizer) {
            await getUserWallet(data?.profile?._id);
          }

          dispatch(
            set({
              ...data?.profile,
              profile: data?.profile?.profilepic,
              cover: data?.profile?.coverpic
            })
          );
        }
        dispatch(setinitialloading(false));

        if (role === ROLES.sponsor && data?.data === true) {
          setProfileCompleted(true);
        }

        if (
          data?.result === "player has completed profile" ||
          data?.result === "Organizer has completed profile"
        ) {
          if (role === ROLES.player) {
            navigate("/player/feed");
          } else if (role === ROLES.organizer) {
            navigate("/organizer/feed");
          }
        } else {
          if (role !== ROLES.sponsor && data?.data !== true) {
            dispatch(set(data?.user));
          }

          dispatch(setinitialloading(false));
        }
      }
    } catch (e) {
      dispatch(setinitialloading(false));
      console.log(e);
      if (!String(e.message).includes("Unauthorized")) error(e.message);
    }
  };
  const setprofileLoding = (action) => dispatch(setinitialloading(action));
  useEffect(() => {
    const controller = new AbortController();
    GetProfile(controller);
    return () => {
      controller.abort();
      dispatch(setinitialloading(true)); //rest loader to true
    };
  }, []);
  return {
    Profile,
    profileLoding,
    setprofileLoding,
    profileCompleted
  };
}
