import React, { Fragment, useEffect, useRef, useState } from "react";
import { Row, Col, Form, Button, DatePicker, Image, Select } from "antd";
const { Option } = Select;

import { fallbackcover } from "../../../common/Utils";
import { CreatePanel as CreatePanelApi } from "../../../Config/API/panel";

import Loader from "../../../common/cards/Loader";
import { error, success } from "../../../common/Alerts/Message";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import useVariables from "../../../common/hooks/useVariables";
import moment from "moment";
import useFieldsVaildator from "../../../common/hooks/useFieldsVaildator";
import { Formik } from "formik";
import { createPanelValidations } from "../../../Config/helpers/validations";
import InputTextField from "../../../common/FormItem/InputTextField";
import { LOCATIONS, ROLES } from "../../../Config/helpers/constants";
import UserNameField from "../../../common/FormItem/UserNameField";
import { useSelector } from "react-redux";
import { PROFILE } from "../../../Store/Features/ProfileSlice";
import useEditProfile from "../../../common/hooks/useEditProfile";
import { KEYS } from "../../../Config/Keys";
import { useFetchProfile } from "../../../common/hooks/useFetchProfile";

const CreatePanelOrganizer = () => {
  const [loading, setLoading] = useState(false);
  const { viewer } = useVariables();
  const { id } = useParams();
  const { inputTextVaildator } = useFieldsVaildator();
  const organizerId = viewer?.id;

  const [editloading, edit] = useEditProfile();
  let profileData, profileLoading;

  if (id) {
    const { profileLoding } = useFetchProfile(id, ROLES.panel);
    profileData = useSelector(PROFILE);
    profileLoading = profileLoding;
  }

  const navigate = useNavigate();
  // Profile
  const [profile, setProfileImage] = useState({
    file: {},
    preview: ""
  });
  // Cover
  const [cover, setCoverImage] = useState({
    file: {},
    preview: ""
  });

  const isProfile = useRef();
  const isCover = useRef();
  // input type image handler
  const onImageFileChange = (e) => {
    const file = e.target.files[0];
    if (e.target.name === "profileImage") {
      setProfileImage({
        file: file,
        preview: URL.createObjectURL(file)
      });
      isProfile.current = true;
    } else {
      setCoverImage({
        file: file,
        preview: URL.createObjectURL(file)
      });
      isCover.current = true;
    }
  };

  useEffect(() => {
    setCoverImage({
      file: {},
      preview: KEYS.API + "/" + profileData?.data?.coverpic
    });
    setProfileImage({
      file: {},
      preview: KEYS.API + "/" + profileData?.data?.profilepic
    });
  }, [profileData]);

  const createPanel = async (values) => {
    if (!inputTextVaildator(profile?.preview)) {
      return error("Please Upload Profile Picture");
    }
    if (!inputTextVaildator(cover?.preview)) {
      return error("Please Upload Cover Picture");
    }
    if ((organizerId || id) && cover.preview && profile.preview) {
      try {
        if (!id) {
          setLoading(true);
        }
        const formdata = new FormData();
        formdata.append("name", values?.name);
        if (profileData?.data?.username !== values?.username) {
          formdata.append("username", values?.username);
        }
        formdata.append("description", values?.description);
        formdata.append("location", values?.location);
        formdata.append("establish", JSON.stringify(values?.establishedDate));
        if (isCover.current === true) {
          formdata.append("cover", cover.file);
        }

        if (isProfile.current === true) {
          formdata.append("profile", profile.file);
        }
        if (id) {
          formdata.append("id", id);
          edit(formdata, ROLES.panel, id);
        } else {
          formdata.append("id", organizerId);
          const response = await CreatePanelApi(formdata);
          const data = await response.json();
          if (data.type === "success") {
            success("Panel Created Successfully");
            navigate("/organizer/panels");
          } else {
            error("An error occured");
            Swal.fire({
              icon: "error",
              text: "An error occured"
            });
          }
          setLoading(false);
        }
      } catch (e) {
        console.log(e);
        error("An error occured");
        Swal.fire({
          icon: "error",
          text: "An error occured"
        });
        if (!id) {
          setLoading(false);
        }
      }
    } else {
      alert(JSON.stringify({ organizerId, cover, profile }));
      Swal.fire({
        icon: "error",
        text: "One of the required fields are missing"
      });
    }
  };

  const initialValues = {
    name: profileData?.data?.name || "",
    username: profileData?.data?.username || "",
    description: profileData?.data?.description || "",
    location: profileData?.data?.location || "",
    establishedDate: profileData?.establish || profileData?.data?.establish || ""
  };

  return (
    <Fragment>
      {loading ||
        (profileLoading && (
          <Loader
            fullpage
            text={
              profileLoading
                ? "Getting panel data, please wait!"
                : "Panel is being created please wait!"
            }
            loading="white"
          />
        ))}
      <div>
        <h1 className="headingPlayer">{id ? "Edit" : "Create"} Your Panel</h1>
        <Row>
          <Col sm={24} xs={24} md={24} style={{ position: "relative" }}>
            <div
              style={{
                position: "relative",
                width: "96%",
                margin: "auto",
                background: "#DADADA",
                borderRadius: "8px"
              }}
              className="CoverImageWrapper">
              {cover?.preview !== "" ? (
                <Image
                  wrapperClassName="CoverImageWrapper"
                  className="CoverImage"
                  preview={false}
                  src={cover?.preview}
                  style={{ objectFit: "cover" }}
                  fallback={fallbackcover}
                />
              ) : null}
              <div className="profileuploaders">
                <label htmlFor="coveruploader" style={{}}>
                  <img src="/images/icons/camera.png" alt="" />
                  {cover?.preview === "" ? <span>Add your Cover image</span> : null}
                </label>
                <input
                  type="file"
                  name="CoverImage"
                  className="uploaderInput"
                  id="coveruploader"
                  accept="image/*"
                  onChange={onImageFileChange}
                />
              </div>
            </div>
            <div className="profileuploaderForProfile">
              {profile?.preview !== "" ? (
                <Image
                  preview={false}
                  wrapperClassName="profileImageWrapper"
                  className="ProfileImage"
                  src={profile?.preview}
                  fallback={fallbackcover}
                />
              ) : null}
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  borderRadius: "50%"
                }}>
                <label htmlFor="profileuploader">
                  <img src="/images/icons/camerasmall.png" alt="" />
                  {profile?.preview === "" ? <span>Add Profile</span> : null}
                </label>
                <input
                  type="file"
                  name="profileImage"
                  className="uploaderInput"
                  id="profileuploader"
                  accept="image/*"
                  onChange={onImageFileChange}
                />
              </div>
            </div>
          </Col>
        </Row>
        <div className="formorganizer">
          <Formik
            initialValues={initialValues}
            validationSchema={createPanelValidations}
            enableReinitialize
            onSubmit={createPanel}>
            {({ values, errors, handleSubmit, isValid, dirty, setFieldValue }) => (
              <Form
                name="basic"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                onFinish={handleSubmit}
                autoComplete="off">
                <Row justify="space-between">
                  <Col lg={11} md={11} sm={24} xs={24} className="ceinputformpadding">
                    {/* name */}
                    <InputTextField
                      name="name"
                      label="Name of Panel"
                      required
                      placeholder="e.g   “ Spartans Club “"
                    />

                    {/* USERNAME */}
                    <UserNameField
                      error={errors?.username}
                      value={values?.username}
                      setFieldValue={setFieldValue}
                      type={ROLES.panel}
                      prevUserName={profileData?.data?.username || ""}
                    />

                    {/* description */}
                    <InputTextField
                      name="description"
                      label="Description"
                      placeholder="e.g   “ Cyberpunks FTS Collectibles “"
                      type="textArea"
                    />
                  </Col>
                  <Col lg={11} md={11} sm={24} xs={24} className="ceinputformpadding">
                    {/* establish in */}
                    <Form.Item
                      name="establishedDate"
                      label="Established In"
                      required
                      className=" profileItems"
                      help={errors?.establishedDate ? errors?.establishedDate : undefined}
                      validateStatus={errors?.establishedDate ? "error" : "success"}>
                      <DatePicker
                        value={
                          values?.establishedDate ? moment(values?.establishedDate) : undefined
                        }
                        inputReadOnly
                        disabledDate={(current) => {
                          return current && current > moment().add(0, "day");
                        }}
                        style={{ marginTop: "8px" }}
                        onChange={(_e, formated) => setFieldValue("establishedDate", formated)}
                      />
                    </Form.Item>
                    {/* location */}
                    <Form.Item
                      required
                      label="Location"
                      help={errors?.location ? errors?.location : undefined}
                      validateStatus={errors?.location ? "error" : "success"}>
                      <Select
                        className="mb-2 w-100"
                        aria-required
                        value={values?.location}
                        placeholder="Location"
                        title="Location"
                        bordered={true}
                        name="location"
                        onChange={(value) => setFieldValue("location", value)}>
                        {LOCATIONS.map((item, index) => (
                          <Option key={index} value={item.value}>
                            {item.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify="center">
                  <Button
                    loading={editloading}
                    htmlType="submit"
                    className="cpcreatebutton"
                    type="primary"
                    disabled={!(isValid && dirty) || editloading}>
                    {id ? "Edit" : " Create"}
                  </Button>
                </Row>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Fragment>
  );
};

export default CreatePanelOrganizer;
