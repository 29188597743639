import React, { Fragment } from "react";
import { Button } from "antd";
import { KEYS } from "../../../Config/Keys";
import { fallbackprofile } from "../../Utils";
import { useNavigate } from "react-router-dom";
import useVariables from "../../hooks/useVariables";
const RegisterTeamCard = ({ team }) => {
  const navigate = useNavigate();
  const { viewer } = useVariables();

  const navigatetoteam = async () => {
    const teamCaptain = team?.team?.profile;
    const yourid = viewer?.id;

    if (teamCaptain === yourid) {
      navigate(`/player/team/${team?.team?._id}/timeline`);
    } else if (team?.members) {
      team?.members?.forEach((element) => {
        if (element?.player === yourid) {
          navigate(`/player/team/${team?.team?._id}/timeline`);
        }
      });
    } else {
      navigate(`/view/team/${team?.team?._id}/timeline`, { state: "team" });
    }
  };
  return (
    <Fragment>
      <div className="timelinereg" style={{ paddingTop: "15px" }}>
        <img
          alt="img"
          className=" "
          style={{
            borderRadius: "50%",
            height: "30px",
            width: 30,
            // background: "red",
            objectFit: "cover"
          }}
          onError={(e) => {
            e.currentTarget.src = fallbackprofile;
          }}
          src={KEYS.API + "/" + team?.team?.profilepic}
        />
        <div className="truncate timelineTeamName">{team?.team?.name || "Dummy Name"}</div>
        <span className="timelinefloat">
          <Button
            // style={{ paddingInline: "10%" }}
            onClick={() => navigatetoteam()}
            type="primary"
            className="timelineViewbutton">
            View
          </Button>
        </span>
      </div>
    </Fragment>
  );
};

export default RegisterTeamCard;
