// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.headingPlayer {
  text-align: center;
  font-size: 36px;
  font-weight: bold;
  padding: 3%;
  margin-top: 50px;
}
.playerformPadding {
  padding: 2% 5% 2% 5%;
}
.playerformfield {
  border-radius: 5px;
  line-height: 28px;
  margin-top: 11px;
}
.playerDob {
  margin-top: 8px;
}
.playerselectfield {
  line-height: 28px;
  margin-top: 11px;
}
.playertagfield {
  line-height: 28px;
  margin-top: 11px;
  width: 100%;
}
.playerendorsement {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.playerReqButton {
  margin-top: 11px;
  border-radius: 5px;
}
.playerCreateBUtton {
  margin-top: 11px;
  border-radius: 5px;
  height: 60px;
  width: 30%;
  margin: 50px 0 50px 0;
  font-weight: 550;
}
`, "",{"version":3,"sources":["webpack://./src/styles/player/createplayerprofile.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,eAAe;EACf,iBAAiB;EACjB,WAAW;EACX,gBAAgB;AAClB;AACA;EACE,oBAAoB;AACtB;AACA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,gBAAgB;AAClB;AACA;EACE,eAAe;AACjB;AACA;EACE,iBAAiB;EACjB,gBAAgB;AAClB;AACA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,WAAW;AACb;AACA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;AACA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;AACA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,YAAY;EACZ,UAAU;EACV,qBAAqB;EACrB,gBAAgB;AAClB","sourcesContent":[".headingPlayer {\n  text-align: center;\n  font-size: 36px;\n  font-weight: bold;\n  padding: 3%;\n  margin-top: 50px;\n}\n.playerformPadding {\n  padding: 2% 5% 2% 5%;\n}\n.playerformfield {\n  border-radius: 5px;\n  line-height: 28px;\n  margin-top: 11px;\n}\n.playerDob {\n  margin-top: 8px;\n}\n.playerselectfield {\n  line-height: 28px;\n  margin-top: 11px;\n}\n.playertagfield {\n  line-height: 28px;\n  margin-top: 11px;\n  width: 100%;\n}\n.playerendorsement {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n.playerReqButton {\n  margin-top: 11px;\n  border-radius: 5px;\n}\n.playerCreateBUtton {\n  margin-top: 11px;\n  border-radius: 5px;\n  height: 60px;\n  width: 30%;\n  margin: 50px 0 50px 0;\n  font-weight: 550;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
