import React, { Fragment } from "react";
import { Row } from "antd";
import { SideBar } from "../../../../common/sidebar";

function TimeLineAbout() {
  return (
    <Fragment>
      <Row className="rowPadding">
        <SideBar description ratingPanel location big />
      </Row>
    </Fragment>
  );
}

export default TimeLineAbout;
