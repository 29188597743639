import { Form, Popover, Radio, Select } from "antd";
import React, { Fragment } from "react";
import {
  E_SPORTS_LIST,
  PHYSICAL_SPORTS_LIST,
  SPORTS_TYPES,
  SPORTS_TYPES_LIST
} from "../../../../../Config/helpers/constants";

function NameAndType({ setFieldValue, values, errors, isEdit }) {
  const sportsOptions =
    values?.sportsType === SPORTS_TYPES?.eSports
      ? E_SPORTS_LIST
      : values?.sportsType === SPORTS_TYPES.physicalSports
      ? PHYSICAL_SPORTS_LIST
      : [];

  const handleSelectSport = (sport) => {
    setFieldValue("typeOfMatch", "");
    const gameMode = sportsOptions?.find((option) => option.value === sport);
    // set game mode
    if (gameMode) {
      setFieldValue("gameMode", gameMode?.mode);
    }
    setFieldValue("selectedSport", sport);
  };
  return (
    <Fragment>
      <Form.Item className="profileItems">
        <label className="boldlabel required">Type of Sport</label>

        <Popover title="Type of Sports">
          <Radio.Group
            disabled={isEdit}
            onChange={(e) => {
              setFieldValue("selectedSport", "");
              setFieldValue("sportsType", e.target.value);
            }}
            value={values?.sportsType}
            className="mt9">
            {SPORTS_TYPES_LIST.map((sport, index) => (
              <Radio key={index} value={sport.value} disabled={isEdit}>
                {sport.label}
              </Radio>
            ))}
          </Radio.Group>
        </Popover>
      </Form.Item>
      <Form.Item
        required
        className="halfwidth profileItems"
        help={
          errors?.selectedSport || errors?.gameMode || errors?.sportsType
            ? errors?.selectedSport || errors?.gameMode || errors?.sportsType
            : undefined
        }
        validateStatus={errors?.selectedSport ? "error" : "success"}>
        <label className="boldlabel required">Name of Sports</label>
        <Select
          disabled={isEdit}
          className="ceinputfieldnos"
          title="Teams"
          bordered={true}
          value={values?.selectedSport}
          onChange={(e) => handleSelectSport(e)}
          options={sportsOptions}></Select>
      </Form.Item>
    </Fragment>
  );
}

export default NameAndType;
