import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Button, Modal, Typography, Grid } from "antd";
import { Link } from "react-router-dom";
const { Text } = Typography;
import { FaCalendarAlt, FaLocationArrow } from "react-icons/fa";
import { KEYS } from "../../../../../../Config/Keys";
import { useNavigate } from "react-router-dom";
import FadeInImage from "../../../../../../common/Images/FadeInImage";
import { CloseRegistration } from "../../../../../../Config/API/events";
import Loader from "../../../../../../common/cards/Loader";
import { Info, error, success } from "../../../../../../common/Alerts/Message";
import { useDispatch } from "react-redux";
import { setEVENT } from "../../../../../../Store/Features/EventSlice";
import { Truncate } from "../../../../../../common/Text/Truncate";
import BookmarkEvent from "../../../../../../common/Buttons/BookmarkEvent";
import { combineWildCardSlots } from "../../../../../../utils/utilityFunctions";
import ViewRatings from "../../../../../../common/Model/ViewRatings";
import { ROLES } from "../../../../../../Config/helpers/constants";
const { useBreakpoint } = Grid;

const TopBar = ({ data, eventId, isOrg }) => {
  const screens = useBreakpoint();
  const [loading, setloading] = useState(false);
  const [remaining, setremaining] = useState(false);
  const [isClosed, setIsClosed] = useState(data?.registrationComplete); //event registration
  const [closeRegistrationModal, setCloseRegistrationModal] = useState(false);
  const registeredTeamsWithoutWildCards = data?.registerteams?.filter((team) => !team?.wildCard);
  const wildCardSlots = combineWildCardSlots(data?.wildCards);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (data?.registrationComplete) setIsClosed(true);
    else setIsClosed(false);
  }, [data?.registrationComplete]);

  const closeEventRegistration = async (deadlineReached = false) => {
    // check if min required teams are registered or not
    if (!deadlineReached && data?.minParicipants > registeredTeamsWithoutWildCards?.length) {
      return Info(`Minimum ${data?.minParicipants} Teams required before proceeding`);
    }
    try {
      setloading("closing");
      const res = await CloseRegistration({
        action: true,
        id: eventId
      });
      let d = await res.json();
      console.log(d);
      if (d?.type === "success") {
        success(d?.result);
        dispatch(setEVENT(d?.data));
        setCloseRegistrationModal(false);
      } else {
        error(d?.result || "Event action not completed");
      }
      setloading(false);
    } catch (e) {
      setloading(false);
      console.log(e.message);
    }
  };

  // not needed yet
  // check if Group Stage type event has enough teams
  // const CheckGroupStageCloseRegistration = async () => {
  //   const noOfTeams = data?.registerteams?.length;
  //   if (noOfTeams === 0) return Info(` ${data?.minParicipants} Registered Teams required!`);
  //   if ((noOfTeams & (noOfTeams - 1)) !== 0 || noOfTeams < 2)
  //     return Info("Registered teams should be 2,4,8,16,32,64....");
  //   else {
  //     return closeEventRegistration();
  //   }
  // };
  // const CheckDKOCloseRegistration = async () => {
  //   const noOfTeams = data?.registerteams?.length;
  //   if (noOfTeams === 0 || noOfTeams < 4) return Info(` minimum 4 Teams are required!`);
  //   else {
  //     return closeEventRegistration();
  //   }
  // };
  useEffect(() => {
    setremaining(
      parseInt(data?.maxParicipants) - parseInt(registeredTeamsWithoutWildCards?.length)
    );
  }, [data?.maxParicipants, data?.registerteams?.length]);

  // auto close registration after deadline
  // commenting for now
  // useEffect(() => {
  //   if (data?.deadline && eventId && !data?.registrationComplete) {
  //     const currentDate = moment().startOf("day");
  //     const deadline = moment(data?.deadline).startOf("day");

  //     // Compare the dates
  //     const isDeadlinePassed = currentDate.isAfter(deadline);

  //     console.log("deadline", deadline, "current:", currentDate);
  //     // if registration deadline is complete close the registration
  //     if (isDeadlinePassed) {
  //       closeEventRegistration(true);
  //     }
  //   }
  // }, [data, eventId]);

  useEffect(() => {
    return () => {
      setloading(null);
      setIsClosed(null);
    };
  }, []);
  const OrganizerButtons = () => {
    return isOrg ? (
      <Col className="timelineemptyslots" lg={12} md={24} xs={24} sm={24}>
        <div className="EventPlayerButtons">
          <div>
            {data?.scheduleComplete && data?.complete ? (
              <Button
                onClick={() => navigate(`/organizer/event/${eventId}/event-awards`)}
                style={{
                  width: "45%",
                  margin: "0"
                }}
                className="registerbutton btnMobileDimensions"
                type="primary">
                View Awards
              </Button>
            ) : !data?.scheduleComplete ? (
              <Button
                onClick={() => navigate(`/organizer/editevent/${eventId}`)}
                style={{
                  width: "45%",
                  margin: "0"
                }}
                className="registerbutton btnMobileDimensions"
                type="primary">
                Edit
              </Button>
            ) : null}
            {isClosed ? (
              <Button
                style={{
                  width: "45%",
                  margin: "0",
                  background: "var(--blue)",
                  borderColor: "var(--blue)"
                }}
                onClick={() => {
                  navigate(`/organizer/event/${eventId}/schedule`, { state: data });
                }}
                className="registerbutton btnMobileDimensions"
                type="primary">
                Schedule
              </Button>
            ) : (
              <Button
                style={{
                  width: "45%",
                  margin: "0"
                }}
                onClick={() => setCloseRegistrationModal(true)}
                // onClick={() => {
                //   if (data?.typeofmatch === "ko" || data?.typeofmatch === "knockout") {
                //     closeEventRegistration();
                //   } else if (data?.typeofmatch === "doubleelimination") {
                //     CheckDKOCloseRegistration();
                //   } else if (data?.typeofmatch === "multiplayer") {
                //     closeEventRegistration();
                //   } else {
                //     if (data?.registerteams?.length >= 2) CheckGroupStageCloseRegistration();
                //     else Info("Minimum 2 teams are required for schedule!");
                //   }
                // }}
                className="registerbutton closeButton btnMobileDimensions"
                type="primary">
                Close Registration
              </Button>
            )}
            <div className="d-block-786" style={{ display: "none", alignItems: "center" }}>
              <BookmarkEvent
                style={{
                  marginBottom: "0px",
                  position: "unset"
                }}
              />
            </div>
          </div>
          <p
            className="E_fontS"
            style={{
              fontSize: "13px",
              margin: "10px 0 0 0",
              textAlign: "right",
              fontWeight: "500"
            }}>
            Registration Deadline :{/* timelineredtext */}
            <span className="timelineredtext" style={{ margin: "0 5px" }}>
              {new Date(data?.deadline).toDateString()}
            </span>
          </p>
          <Row justify="end">
            <BookmarkEvent />
          </Row>
        </div>
      </Col>
    ) : null;
  };

  return (
    <Fragment>
      {loading === "closing" ? <Loader fullpage /> : null}
      <Row className="toheader">
        <Col className="timelinept" span={24}>
          <FadeInImage radius preview type={"Fadecover"} src={KEYS.API + "/" + data?.file?.file} />
        </Col>
        <Col
          className="timelinetopbarshadow"
          style={{ background: "whtie" }}
          lg={isOrg ? 12 : 24}
          md={24}
          xs={24}
          sm={24}>
          <div className="timelinetopbarpadding">
            <Row justify="space-between">
              <Col>
                <p className="timelinetopcolor E_fontS">
                  <FaCalendarAlt />
                  <span style={{ marginLeft: "5px" }}>
                    {new Date(data?.startDate).toDateString() +
                      " - " +
                      new Date(data?.endDate).toDateString()}
                  </span>
                </p>
              </Col>
              {screens.xs && (
                <Col>
                  <p className=" flexcenter">
                    <span className="sideItemsText">
                      Event by{" "}
                      <Link
                        state="panel"
                        to={`/view/panel/${data?.panel?._id}/timeline`}
                        style={{ color: "#0085ff", paddingRight: "0.5rem" }}>
                        {data?.panel?.name}
                      </Link>
                    </span>
                  </p>
                </Col>
              )}
            </Row>

            <Truncate text={data?.name} lines={2} level={4} className="timelinefonttitle E_font " />
            <div style={{ color: "#6F6F6F" }}>
              <p className="E_fontS E_m_S">
                <FaLocationArrow />
                <span style={{ marginLeft: "10px" }}>{data?.location || "dummy location"}</span>
              </p>
              <ViewRatings type={ROLES?.event} id={data?._id} />

              <Row>
                {data?.maxParicipants ? (
                  <Col lg={8} sm={12} xs={24} md={12}>
                    <p className="E_slot">
                      Max Slots :<span className="timelineslotscolor">{data?.maxParicipants}</span>
                    </p>
                  </Col>
                ) : null}
                {data?.minParicipants ? (
                  <Col lg={8} sm={12} xs={24} md={8}>
                    <p className="E_slot">
                      Min Slots :<span className="timelineslotscolor">{data?.minParicipants}</span>
                    </p>
                  </Col>
                ) : null}
              </Row>

              <Row>
                <Col lg={8} sm={12} xs={24} md={12}>
                  <p className="E_slot">
                    Occupied Slots:
                    <span className="timelineslotscolor">
                      {registeredTeamsWithoutWildCards?.length}
                    </span>
                  </p>
                </Col>
                {data?.maxParicipants ? (
                  <Col lg={8} sm={12} xs={24} md={8}>
                    <p className="E_slot">
                      Remaining Slots :<span className="timelineslotscolor">{remaining}</span>
                    </p>
                  </Col>
                ) : null}
              </Row>

              {/* wild card slots */}
              {data?.allowWildCard && wildCardSlots?.noOfSlots > 0 && (
                <Row>
                  {data?.maxParicipants ? (
                    <Col lg={8} sm={12} xs={24} md={12}>
                      <p className="E_slot">
                        Wild Card Slots :
                        <span className="timelineslotscolor">{wildCardSlots?.noOfSlots}</span>
                      </p>
                    </Col>
                  ) : null}
                  {data?.minParicipants ? (
                    <Col lg={8} sm={12} xs={24} md={8}>
                      <p className="E_slot">
                        Occupied Wild Slots :
                        <span className="timelineslotscolor">{wildCardSlots?.occupiedSlots}</span>
                      </p>
                    </Col>
                  ) : null}
                </Row>
              )}
            </div>
          </div>
        </Col>
        <OrganizerButtons />
      </Row>

      {closeRegistrationModal && (
        <Modal
          title="Close Registration Confirmation"
          open={closeRegistrationModal}
          onOk={closeEventRegistration}
          onCancel={() => setCloseRegistrationModal(false)}>
          <Text strong>Are you sure you want to close registration ?</Text>
          <blockquote>Once registrations are closed, they can not be reopened.</blockquote>
        </Modal>
      )}
    </Fragment>
  );
};

export default TopBar;
