import { Col, Row } from "antd";
import React from "react";

const HowItWorksCard = ({ step, des, title, imgUrl, player }) => {
  return (
    <>
      <Row
        style={{
          borderRadius: "0.5rem",
          padding: "10px 5px",
          backgroundColor: "#efefef",
          height: "180px"
        }}
        wrap={false}
        align="middle"
        gutter={20}>
        <Col xs={9} sm={10}>
          <img width="100" height="100" src={`/images/howitworkicon/${imgUrl}.svg`} />
          <div
            style={{
              textAlign: "start",
              marginTop: "1rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}>
            <span
              style={{
                padding: "0.7px 0.5rem",
                borderRadius: "50%",
                border: "3px solid transparent",
                background: `linear-gradient(#efefef, #efefef) padding-box,
              linear-gradient(to bottom, #68d5ff, #3f5dff) border-box`
              }}>
              {step}
            </span>
            <span style={{ marginLeft: "0.4rem", fontWeight: "500", fontSize: "14px" }}>
              {title}
            </span>
          </div>
        </Col>
        <Col align="start">
          <p style={{ lineHeight: "1.7" }}>{des}</p>
        </Col>
      </Row>
      {step < 5 && (
        <div
          style={{
            color: `${player ? "#68d5ff" : "#3f5dff"}`,
            fontSize: "4rem",
            fontWeight: "500"
          }}>
          &#x2193;
        </div>
      )}
    </>
  );
};

export default HowItWorksCard;
