import React from "react";
import FilterBar from "./FilterBar";
import FilterBarAccordian from "./FilterBarAccrodian";

function Index({ filter, setFilter }) {
  const handleFilterChange = (name, value) => {
    setFilter((prev) => ({ ...prev, [name]: value }));
  };
  return (
    <div style={{}}>
      <div className="d-md-block d-none">
        <FilterBarAccordian filter={filter} handleFilterChange={handleFilterChange} />
      </div>
      <div className="d-md-none">
        <FilterBar filter={filter} handleFilterChange={handleFilterChange} />
      </div>
    </div>
  );
}

export default Index;
