import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Row, Col, Button, Dropdown, Space, Rate, Grid } from "antd";
import { Link } from "react-router-dom";
import { FaCalendarAlt, FaLocationArrow } from "react-icons/fa";
import { KEYS } from "../../../../Config/Keys";
import TeamRegisterModal from "./TeamRegisterModal";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import FadeInImage from "../../../../common/Images/FadeInImage";
import { GetTeamsOfOwner } from "../../../../Config/API/teams";
import Loader from "../../../../common/cards/Loader";
import useVariables from "../../../../common/hooks/useVariables";
import { Truncate } from "../../../../common/Text/Truncate";
import BookmarkEvent from "../../../../common/Buttons/BookmarkEvent";
import { EVENT_AND_TEAM_ROLES, GAMES_MODES, ROLES } from "../../../../Config/helpers/constants";
import { GetEventWildCards } from "../../../../Config/API/events";
import WildCardRegistrationModal from "./WildCardRegistrationModal";
import { combineWildCardSlots } from "../../../../utils/utilityFunctions";
import useRating from "../../../../common/hooks/useRating";
import RatingModal from "../../../../common/Model/RatingModal";
import { GetPanelRatingsList } from "../../../../Config/API/panel";
import ViewRatings from "../../../../common/Model/ViewRatings";
const { useBreakpoint } = Grid;
const TopBar = ({ data, eventId, viewOnly, getEvent }) => {
  const screens = useBreakpoint();
  const [loading, setloading] = useState(false);
  const [open, setopen] = useState(false);
  const [remaining, setremaining] = useState(false);
  const [teamm, setTeams] = useState([]);
  const [rating, setRating] = useState(0);
  const [wildCardData, setWildCardData] = useState([]);
  const [wildCardModal, setWildCardModal] = useState(false);
  const { viewer } = useVariables();
  const registeredTeamsWithoutWildCards = data?.registerteams?.filter((team) => !team?.wildCard);
  const wildCardSlots = combineWildCardSlots(data?.wildCards);

  const { isVisible, showRatingModal, comment, setComment, handleOk, handleCancel, ratingLoading } =
    useRating({
      defaultRating: 0,
      rating,
      setRating,
      eventId
    });

  const isPlayer = viewer?.flag === "player";
  const navigate = useNavigate();

  useEffect(() => {
    setremaining(
      parseInt(data?.maxParicipants) - parseInt(registeredTeamsWithoutWildCards?.length)
    );
    if (isPlayer && !viewOnly) get();
  }, [data?.file?.file, data?.organizer?._id, isPlayer]);

  // get event wild card data
  useEffect(() => {
    const getEventWildCards = async () => {
      const res = await GetEventWildCards(eventId);
      const response = await res.json();
      if (response?.type === "success" && response?.result?.length > 0) {
        setWildCardData(response?.result);
      }
    };

    if (!viewOnly) {
      getEventWildCards();
    }
  }, [eventId]);

  useEffect(() => {
    return () => {
      setTeams(null);
      setloading(null);
      setopen(null);
    };
  }, []);

  // check user has added ratings before in this event or not
  useEffect(() => {
    const getPanelRatings = async () => {
      const res = await GetPanelRatingsList(data?.panel?._id);
      const ratingData = await res.json();
      if (ratingData?.type === "success") {
        if (ratingData?.data?.length > 0) {
          // check if the current user has already rated this event
          const userDetails = localStorage.getItem("userDetails");
          const userId = userDetails ? JSON.parse(userDetails)?.id : "";
          const userRating = ratingData?.data?.find((rate) => rate?.userId === userId);
          if (userRating) {
            setRating(userRating?.rating);
          }
        }
      }
    };

    if (data?.panel?._id) {
      getPanelRatings();
    }
  }, [data?.panel?._id]);

  // team owner
  const checkowner = (t) => {
    const team = teamm?.find((e) => e._id === t);
    console.log(team);
    const id = viewer?.id;
    let isOwner = false;

    if (team?.members?.length > 0) {
      for (let i = 0; i < team.members.length; i++) {
        const element = team.members[i];
        if (element?.player?._id === id && element?.role === EVENT_AND_TEAM_ROLES.captain) {
          isOwner = true;
          break; // Stop the loop when isOwner becomes true
        } else {
          isOwner = false;
        }
      }
    } else {
      isOwner = true;
    }

    return isOwner;
  };
  // get teams of player
  const get = async () => {
    try {
      setloading(true);
      const id = viewer?.id;

      const res = await GetTeamsOfOwner({ id });
      let d = await res.json();
      d = d?.result;
      const filter = [];
      d.forEach((e) => {
        // let found = false;
        data?.registerteams?.forEach((r) => {
          if (e?._id === r?.team?._id) {
            // found = true;
            return;
          }
        });
        // if (!found) filter.push(e)
        filter.push(e);
      });
      setTeams(filter || []);
      setloading(false);
    } catch (e) {
      setloading(false);
      console.log(e.message);
    }
  };

  const openmodel = () => {
    if (remaining === 0) {
      return Swal.fire({
        icon: "info",
        text: "All slots are reserved!"
      });
    } else {
      setopen("register");
    }
  };

  // only captains of registered teams will be allowed to rate an event
  const checkAllowRating = () => {
    const registeredTeams = data?.registerteams;
    if (registeredTeams?.length > 0) {
      const playerTeam = registeredTeams?.find((team) =>
        team?.team?.members?.some((member) => member?.player === viewer?.id)
      );

      if (playerTeam) {
        return true;
      }
    }

    return false;
  };
  const allowRating = useMemo(() => checkAllowRating(), [data?.registerteams]);

  const PlayerButtons = () => {
    const isFullSlots =
      data?.maxParicipants && registeredTeamsWithoutWildCards?.length === data?.maxParicipants;

    const canregister =
      new Date(data?.deadline).setHours(24) > new Date() &&
      data?.registrationComplete === false &&
      !isFullSlots;

    return (
      <Col className="timelineemptyslots" lg={12} md={24} xs={24} sm={24}>
        <div className="EventPlayerButtons">
          {/* don't show register, schedule buttons on draft events */}
          {data?.draft || viewOnly ? null : (
            <div>
              {/* view awards button */}
              {data?.scheduleComplete && data?.complete ? (
                <>
                  <Button
                    style={{
                      width: "45%",
                      margin: "0",
                      background: "var(--blue)",
                      borderColor: "var(--blue)"
                    }}
                    onClick={() => {
                      navigate(`/view/event/${eventId}/event-awards`);
                    }}
                    className="registerbutton btnMobileDimensions"
                    type="primary">
                    View Awards
                  </Button>
                </>
              ) : null}
              {isPlayer ? (
                <Fragment>
                  {canregister ? (
                    wildCardData?.length > 0 &&
                    wildCardSlots?.occupiedSlots < wildCardSlots?.noOfSlots ? (
                      <Dropdown
                        trigger={["click"]}
                        menu={{
                          items: [
                            {
                              key: "1",
                              label: <span onClick={() => openmodel()}>Normal Registration</span>
                            },
                            {
                              key: "2",
                              label: (
                                <span onClick={() => setWildCardModal(true)}>Wild Card Entry</span>
                              )
                            }
                          ]
                        }}
                        placement="bottomLeft"
                        arrow>
                        <Button
                          style={{
                            width: "45%",
                            margin: "0",
                            color: "white"
                          }}
                          className="registerbutton btnMobileDimensions">
                          Register
                        </Button>
                      </Dropdown>
                    ) : (
                      <Button
                        onClick={() => openmodel()}
                        style={{
                          width: "45%",
                          margin: "0"
                        }}
                        className="registerbutton btnMobileDimensions"
                        type="primary">
                        Register
                      </Button>
                    )
                  ) : (
                    <>
                      <Button
                        style={{
                          width: "45%",
                          background: "var(--grey)",
                          border: "none",
                          cursor: "not-allowed",
                          margin: "0"
                        }}
                        className="registerbutton btnMobileDimensions"
                        type="primary">
                        {isFullSlots ? "Slots Full" : "Registration Closed"}
                      </Button>
                      {/* only show this if other slots are full */}
                      {wildCardData?.length > 0 &&
                        wildCardSlots?.occupiedSlots < wildCardSlots?.noOfSlots && (
                          <Button
                            onClick={() => setWildCardModal(true)}
                            style={{
                              width: "45%",
                              margin: "0"
                            }}
                            className="registerbutton btnMobileDimensions"
                            type="primary">
                            Wild Card Entry
                          </Button>
                        )}
                    </>
                  )}
                </Fragment>
              ) : null}

              {data?.scheduleComplete && (
                <Button
                  style={{
                    width: "45%",
                    border: "none",
                    margin: "0"
                  }}
                  className="registerbutton btnMobileDimensions"
                  type="primary"
                  onClick={() => {
                    if (data?.typeofmatch === "league") {
                      navigate(`/view/event/${eventId}/schedule`, { state: data });
                    } else if (
                      data?.typeofmatch === "ko" ||
                      data?.typeofmatch === "knockout" ||
                      data?.typeofmatch === "doubleelimination"
                    ) {
                      navigate(`/view/event/${eventId}/knock-out-matches`, { state: data });
                    } else if (data?.typeofmatch === GAMES_MODES.multi) {
                      navigate(`/view/event/${eventId}/multiplayermatches`, { state: data });
                    }
                  }}>
                  {isFullSlots ? "Schedule" : "Schedule"}
                </Button>
              )}
            </div>
          )}
          <p
            className="E_fontS"
            style={{
              fontSize: "13px ",
              margin: "10px 0 0 0",
              textAlign: "right",
              fontWeight: "500"
            }}>
            Registration Deadline :{/* timelineredtext */}
            <span className="timelineredtext" style={{ margin: "0 5px" }}>
              {new Date(data?.deadline).toDateString()}
            </span>
          </p>
          <Row justify="end">
            <BookmarkEvent />
          </Row>
        </div>
      </Col>
    );
  };

  return (
    <Fragment>
      {loading === "closing" ? <Loader fullpage /> : null}
      {/* <Row className="timelineTopbar"> */}
      <Row className="toheader">
        <Col className="timelinept" span={24}>
          <FadeInImage radius preview type={"Fadecover"} src={KEYS.API + "/" + data?.file?.file} />
        </Col>
        <Col
          className="timelinetopbarshadow"
          style={{ background: "whtie" }}
          lg={12}
          md={24}
          xs={24}
          sm={24}>
          <div className="timelinetopbarpadding">
            <Row justify="space-between">
              <Col>
                <p className="timelinetopcolor E_fontS">
                  <FaCalendarAlt />
                  <span style={{ marginLeft: "5px" }}>
                    {new Date(data?.startDate).toDateString() +
                      " - " +
                      new Date(data?.endDate).toDateString()}
                  </span>
                </p>
              </Col>
              {screens.xs && (
                <Col>
                  <p className=" flexcenter">
                    <span className="sideItemsText">
                      Event by{" "}
                      <Link
                        state="panel"
                        to={`/view/panel/${data?.panel?._id}/timeline`}
                        style={{ color: "#0085ff", paddingRight: "0.5rem" }}>
                        {data?.panel?.name}
                      </Link>
                    </span>
                  </p>
                </Col>
              )}
            </Row>
            <Truncate text={data?.name} lines={2} level={4} className="timelinefonttitle E_font " />
            <div style={{ color: "#6F6F6F" }}>
              <p className="E_fontS E_m_S">
                <FaLocationArrow />
                <span style={{ marginLeft: "10px" }}>{data?.location || "dummy location"}</span>
              </p>

              <Space direction="horizontal" size="middles" align="center">
                {allowRating && (
                  <>
                    <p className="mr-2 mb-0">Rate</p>
                    <Rate
                      disabled={rating > 0}
                      allowHalf
                      value={rating}
                      defaultValue={0}
                      onChange={(e) => {
                        setRating(e);
                        showRatingModal();
                      }}
                    />
                  </>
                )}

                <ViewRatings type={ROLES?.event} id={data?._id} />
              </Space>

              <Row>
                {data?.maxParicipants ? (
                  <Col lg={8} sm={12} xs={24} md={12}>
                    <p className="E_slot">
                      Max Slots :<span className="timelineslotscolor">{data?.maxParicipants}</span>
                    </p>
                  </Col>
                ) : null}
                {data?.minParicipants ? (
                  <Col lg={8} sm={12} xs={24} md={8}>
                    <p className="E_slot">
                      Min Slots :<span className="timelineslotscolor">{data?.minParicipants}</span>
                    </p>
                  </Col>
                ) : null}
              </Row>

              <Row>
                <Col lg={8} sm={12} xs={24} md={12}>
                  <p className="E_slot">
                    Occupied Slots :
                    <span className="timelineslotscolor">
                      {registeredTeamsWithoutWildCards?.length}
                    </span>
                  </p>
                </Col>
                {data?.maxParicipants ? (
                  <Col lg={8} sm={12} xs={24} md={8}>
                    <p className="E_slot">
                      Remaining Slots :<span className="timelineslotscolor">{remaining}</span>
                    </p>
                  </Col>
                ) : null}
              </Row>

              {/* wild card slots */}
              {data?.allowWildCard && wildCardSlots?.noOfSlots > 0 && (
                <Row>
                  {data?.maxParicipants ? (
                    <Col lg={8} sm={12} xs={24} md={12}>
                      <p className="E_slot">
                        Wild Card Slots :
                        <span className="timelineslotscolor">{wildCardSlots?.noOfSlots}</span>
                      </p>
                    </Col>
                  ) : null}
                  {data?.minParicipants ? (
                    <Col lg={8} sm={12} xs={24} md={8}>
                      <p className="E_slot">
                        Occupied Wild Slots :
                        <span className="timelineslotscolor">{wildCardSlots?.occupiedSlots}</span>
                      </p>
                    </Col>
                  ) : null}
                </Row>
              )}
            </div>
          </div>
        </Col>
        <Fragment>
          <PlayerButtons />
        </Fragment>
      </Row>

      <TeamRegisterModal
        data={teamm}
        open={open}
        setopen={setopen}
        loading={loading}
        checkowner={checkowner}
        eventId={eventId}
        setremaining={setremaining}
        setTeams={setTeams}
        teams={teamm}
        registerteams={data?.registerteams}
        getEvent={getEvent}
      />

      {/* Wild card registration modal */}
      {wildCardModal && (
        <WildCardRegistrationModal
          eventId={eventId}
          wildCards={wildCardData}
          open={wildCardModal}
          setOpen={setWildCardModal}
          teams={teamm}
        />
      )}

      <RatingModal
        isVisible={isVisible}
        handleCancel={handleCancel}
        handleOk={handleOk}
        comment={comment}
        setComment={setComment}
        setRating={setRating}
        rating={rating}
        ratingLoading={ratingLoading}
      />
    </Fragment>
  );
};

export default TopBar;
