import React from "react";
import { Row, Col } from "antd";
import { useSelector } from "react-redux";
import ScrollToTop from "../../../../common/cards/ScrollToTop";
import RightBar from "../../../../common/righbar";
import { SideBar } from "../../../../common/sidebar";
import { PROFILE } from "../../../../Store/Features/ProfileSlice";
import DiscussionPanel from "./DiscussionPanel";
import { ROLES } from "../../../../Config/helpers/constants";
import { useLocation } from "react-router-dom";
function Home() {
  const { pathname } = useLocation();
  const userDetails = useSelector(PROFILE);
  return (
    <Row justify="space-between" className="rowPadding">
      <ScrollToTop />
      <SideBar description ratingPanel location />
      <Col xs={24} sm={24} md={24} lg={11}>
        {userDetails?._id ? <DiscussionPanel /> : null}
      </Col>
      {pathname.includes(ROLES.panel) ? (
        <RightBar
          noExploreEvents
          eventHistoryLInk={`/view/panel/${userDetails?._id}/eventhistory`}
          current={ROLES.panel}
        />
      ) : (
        <RightBar />
      )}
    </Row>
  );
}

export default Home;
