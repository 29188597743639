import { Typography, Row, Col, Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import Loader from "../../cards/Loader";
const { Title } = Typography;

const SecuritySetting = ({ loading }) => {
  const [oldpass, setoldpass] = useState("");
  const [VPass, setVPass] = useState("");
  const [CVPass, setCVPass] = useState("");
  const [Password, setPassword] = useState("");
  const [confirmpass, setconfirmpass] = useState("");

  useEffect(() => {
    return () => {
      setVPass(null);
      setCVPass(null);
      setoldpass(null);
      setPassword(null);
      setconfirmpass(null);
    };
  }, []);

  useEffect(() => {
    if (Password?.length > 7) setVPass("success");
    else if (Password?.trim().length === 0) setVPass("");
    else setVPass("error");

    if (confirmpass?.trim().length === 0) setCVPass("");
    else if (Password?.length > 7 && Password === confirmpass) setCVPass("success");
    else setCVPass("error");
  }, [confirmpass, Password]);

  return (
    <div className="settingsComponent list">
      <Typography className="title">
        <Title level={3}>Security Setting</Title>
      </Typography>
      {loading ? (
        <Loader noShadow />
      ) : (
        <div className="formorganizer">
          <Row justify="space-between">
            <Col lg={11} md={11} xs={24} sm={24} className="ceinputformpadding">
              <Form
                name="basic"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                autoComplete="off">
                <Form.Item name="oldpassword" className="profileItems border">
                  <label className="boldlabel">Old Password</label>
                  <Input.Password
                    value={oldpass}
                    autoComplete="new-password"
                    onChange={(e) => setoldpass(e.target.value)}
                    style={{ lineHeight: "38px" }}
                    placeholder="Enter your password"
                  />
                </Form.Item>

                <Form.Item
                  name="password"
                  className="profileItems border"
                  help={VPass === "error" ? "length should exceed 7 characters" : null}
                  hasFeedback
                  validateStatus={VPass}>
                  <label className="boldlabel">New Password</label>
                  <Input.Password
                    className="padd"
                    value={Password}
                    autoComplete="new-password"
                    onChange={(e) => setPassword(e.target.value)}
                    style={{ lineHeight: "38px" }}
                    placeholder="Enter your password"
                  />
                </Form.Item>

                <Form.Item
                  name="cpassword"
                  className="profileItems border"
                  help={CVPass === "error" ? "Use the same password in both fields" : null}
                  hasFeedback
                  validateStatus={CVPass}>
                  <label className="boldlabel">Confirm Password</label>
                  <Input.Password
                    className="padd"
                    value={confirmpass}
                    onChange={(e) => setconfirmpass(e.target.value)}
                    style={{ lineHeight: "38px" }}
                    placeholder="Enter your confirm password"
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default SecuritySetting;
