import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { setTeams } from "../../../../../../../Store/Features/kobracketslice";
import { useRoundsBYESeed } from "./byeKSeed";
import { useDKOSEED } from "./dkseed";
import { useKSEED } from "./kseed";
import { useDKOBYESEED } from "./dkseedbye";

export const useRoundsUtility = (setteams) => {
  const { KSEED } = useKSEED(setteams);
  const { DKOWINNERS, DKOLOSERS, DKOFINALIST } = useDKOSEED(setteams);
  const { CustomBYESeed } = useRoundsBYESeed(setteams);
  const { DKOWINNERSBYE, DKOLOSERSBYE, DKOFINALISTBYE } = useDKOBYESEED();

  const active = useSelector((state) => state.ko.activeround);
  const rounds = useSelector((state) => state.ko.brackets);
  const isBYE = useSelector((state) => state.ko.isBYE);
  const dispatch = useDispatch();
  const { state } = useLocation();

  const isDobuleEle = state["typeofmatch"] === "doubleelimination";
  useEffect(() => {
    if (!rounds) return;
    set();
  }, [rounds, dispatch, active, useSelector((state) => state.ko.loading)]);
  const set = () => {
    console.log({ active });
    const a = rounds[active]
      ?.map((e) => e?.match?.win === "" && e?.match?.team1)
      ?.filter((e) => e?._id);
    const b = rounds[active]
      ?.map((e) => e?.match?.win === "" && e?.match?.team2)
      ?.filter((e) => e?._id);
    let c = a?.concat(b);
    c?.sort((a, b) => a.name?.localeCompare(b?.name));
    dispatch(setTeams(c));
  };
  if (isDobuleEle) {
    return {
      CustomSeed: isBYE ? DKOWINNERSBYE : DKOWINNERS,
      CustomSeed2: isBYE ? DKOLOSERSBYE : DKOLOSERS,
      CustomSeed3: isBYE ? DKOFINALISTBYE : DKOFINALIST
    };
  } else return { CustomSeed: isBYE ? CustomBYESeed : KSEED };
};
