import React from "react";

function Container({ children, isPaddingInline = true, isPaddingBlock = true }) {
  //   const size = "5.5%";
  //   const sizeY = `1.6%`;
  let classes = isPaddingInline ? "paddX " : "";
  classes += isPaddingBlock ? "paddY" : "";
  return <div className={`${classes} Container`}>{children}</div>;
}

export default Container;
