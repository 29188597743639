import React from "react";
import { Fragment, useState } from "react";
import { Button, Input, Modal } from "antd";
import { error, success } from "../../../../common/Alerts/Message";
import { AssignRole } from "../../../../Config/API/panel";
import Loader from "../../../../common/cards/Loader";
import { useParams } from "react-router-dom";

function EditModalCard({ setopen, open, organizerId, setpanel, r }) {
  const [role, setrole] = useState(r);
  const [loading, setloading] = useState(false);
  const { id } = useParams();

  const assignRole = async () => {
    try {
      setloading(true);

      if (id && role !== "" && organizerId) {
        const res = await AssignRole({
          panelId: id,
          organizerId,
          role
        });
        const d = await res.json();
        if (d?.type === "failue") {
          error(d?.result);
        }
        if (d?.type === "success") {
          success("Role Assigned");
          const orgnaizer = {
            organizer: {
              _id: d?.data?._id,
              profilepic: d?.data?.profilepic,
              name: d?.data?.name
            },
            role: "Panel Creator",
            active: true,
            owner: true
          };
          console.log(d);
          d?.data?.members?.unshift(orgnaizer);

          setpanel(d?.data);
        }
        setrole("");
        setopen(false);
      } else throw new Error("One of the id's are missing try again later!");
      setloading(false);
    } catch (e) {
      console.log(e);
      error(e);
      setloading(false);
      console.log(e);
      setrole("");
    }
  };

  return (
    <Modal
      className="editPost"
      confirmLoading
      mask
      maskClosable
      onCancel={() => {
        setopen(false);
      }}
      closable={false}
      centered
      destroyOnClose={true}
      visible={open}
      open={open}
      footer={false}
      // style={{ minWidth: '150px' }}
    >
      {loading ? (
        <Loader smaller center noShadow />
      ) : (
        <Fragment>
          <h1
            style={{
              textAlign: "center",
              fontWeight: 700,
              fontSize: "30px"
            }}>
            Assign Role
          </h1>
          <Input
            allowClear
            style={{
              margin: "10px 0",
              borderRadius: "10px",
              padding: "10px"
            }}
            className="TeamSelect"
            value={role}
            onChange={(e) => setrole(e.currentTarget.value)}
            placeholder="Manager ..."
          />
          <Button
            style={{ width: "100px", margin: "0 auto 20px auto" }}
            loading={loading ? true : false}
            onClick={assignRole}
            type="primary"
            className="blueButton">
            Done
          </Button>
        </Fragment>
      )}
    </Modal>
  );
}

export default EditModalCard;
