import React, { Fragment } from "react";
import { KEYS } from "../../../../Config/Keys";
import { fallbackprofile } from "../../../../common/Utils";
import FadeInImage from "../../../../common/Images/FadeInImage";
import { useNavigate } from "react-router-dom";
const InviteCard = ({ data, current }) => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <div
        onClick={() =>
          navigate(
            `/view/${data?.player ? "player" : "organizer"}/${
              data?.player?._id || data?.organizer?._id
            }/timeline`,
            { state: current === "team" ? "player" : "organizer" }
          )
        }
        className="pomemberShadow membercard">
        <div className="popadd">
          <FadeInImage
            className="poprofilepic"
            src={KEYS.API + "/" + (data?.player?.profilepic || data?.organizer?.profilepic)}
            fallaback={fallbackprofile}
            type={"Fadegcard"}
          />
        </div>

        <p className="namepoinvite">{data?.player?.name || data?.organizer?.name}</p>
        <p className="nameporesp">Waiting for response...</p>
      </div>
    </Fragment>
  );
};

export default InviteCard;
