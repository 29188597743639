import React from "react";
import { Collapse } from "antd";
import { IoFilterSharp } from "react-icons/io5";
import { Typography } from "antd";
const { Panel } = Collapse;
const { Title } = Typography;

const FilterBarAccrodian = ({ active, setactive }) => {
  return (
    <Collapse
      bordered={false}
      style={{ width: "100%", border: " 1px solid #e1e1e1" }}
      defaultActiveKey={["FilterAccordiant"]}
      expandIcon={({ isActive }) => (
        <IoFilterSharp
          style={{ fontSize: "1.3rem", marginTop: ".19rem" }}
          rotate={isActive ? 90 : 0}
        />
      )}
      className="sideItem">
      <Panel
        header={
          <Title style={{ margin: "0" }} level={4}>
            {" "}
            Settings{" "}
          </Title>
        }
        key="1"
        className={`site-collapse-custom-panel`}>
        <div className="FilterBar settingsComponent" style={{ border: "none" }}>
          <Title
            onClick={() => setactive(1)}
            level={5}
            className={active === 1 ? "active" : undefined}>
            Profile
          </Title>

          <Title
            onClick={() => setactive(2)}
            level={5}
            className={active === 2 ? "active" : undefined}>
            Security and Privacy
          </Title>
        </div>
      </Panel>
    </Collapse>
  );
};

export default FilterBarAccrodian;
