import React from "react";
import "./EventHistoryCard.css";
import { fallbackcover } from "../Utils";
import FadeInImage from "../../common/Images/FadeInImage";

function TimelineDot({ img }) {
  return (
    <div className="timeline-dot">
      <FadeInImage radiusAll fallback={fallbackcover} src={img} alt="event-img" />
    </div>
  );
}

export default TimelineDot;
