// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.commentsDownButton {
  border: none;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0;
  box-shadow: none;
  color: var(--blue);
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}
.commentsDownButton::after {
  display: none;
  content: "";
}
.commentsDownButton svg {
  transition: all 0.2s ease-in-out;
}
.commentsDownButton.true svg {
  transform: rotate(180deg);
}

/* Post-araa */
.likes_comments {
  border-top: 1px solid #c0c0c0;
  border-bottom: 1px solid #c0c0c0;
  display: flex;
  align-items: center;
  gap: 1rem;

  justify-content: space-evenly;
  height: max-content;
  padding: 0.6rem 0;
}

.likes_comments span {
  border: none;
  font-size: 0.8rem;
  color: #939393;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}

.likes_comments span svg {
  font-size: 1.4rem;
}
`, "",{"version":3,"sources":["webpack://./src/styles/Comments/Comments.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,QAAQ;EACR,UAAU;EACV,gBAAgB;EAChB,kBAAkB;EAClB,eAAe;EACf,yBAAiB;UAAjB,iBAAiB;AACnB;AACA;EACE,aAAa;EACb,WAAW;AACb;AACA;EACE,gCAAgC;AAClC;AACA;EACE,yBAAyB;AAC3B;;AAEA,cAAc;AACd;EACE,6BAA6B;EAC7B,gCAAgC;EAChC,aAAa;EACb,mBAAmB;EACnB,SAAS;;EAET,6BAA6B;EAC7B,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,cAAc;EACd,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,eAAe;EACf,yBAAiB;UAAjB,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".commentsDownButton {\n  border: none;\n  display: flex;\n  align-items: center;\n  gap: 5px;\n  padding: 0;\n  box-shadow: none;\n  color: var(--blue);\n  cursor: pointer;\n  user-select: none;\n}\n.commentsDownButton::after {\n  display: none;\n  content: \"\";\n}\n.commentsDownButton svg {\n  transition: all 0.2s ease-in-out;\n}\n.commentsDownButton.true svg {\n  transform: rotate(180deg);\n}\n\n/* Post-araa */\n.likes_comments {\n  border-top: 1px solid #c0c0c0;\n  border-bottom: 1px solid #c0c0c0;\n  display: flex;\n  align-items: center;\n  gap: 1rem;\n\n  justify-content: space-evenly;\n  height: max-content;\n  padding: 0.6rem 0;\n}\n\n.likes_comments span {\n  border: none;\n  font-size: 0.8rem;\n  color: #939393;\n  display: flex;\n  align-items: center;\n  gap: 0.5rem;\n  cursor: pointer;\n  user-select: none;\n}\n\n.likes_comments span svg {\n  font-size: 1.4rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
