import React from "react";
import { Route, Routes } from "react-router-dom";
import SponsorLayout from "../layout/sponsorLayout/SponsorLayout";
import PageNotFound from "../../common/cards/PageNotFound/PageNotFound";
import CreateSponsorProfile from "../Sponsor/CreateSponsorProfile/CreateSponsorProfile";
import AuthSponsor from "../Sponsor/AuthSponsor/index";
import SearchPage from "../SearchPage";

import SponsorTLayout from "../layout/sponsorTLLayout/SponsorTLayout";
import SponsorTimeline from "./Timeline";
import CreateCampaign from "./Campaigns/CreateCampaign";
import DraftCampaigns from "./Campaigns/DraftCampaigns";
import ChatRoom from "../ChatRoom";

const Index = () => {
  return (
    <Routes>
      <Route element={<SponsorLayout />}>
        <Route index path="/" element={<CreateSponsorProfile />} />
      </Route>
      <Route path="/" element={<AuthSponsor />}>
        <Route path="/" element={<SponsorLayout />}>
          <Route path="/search/:query" element={<SearchPage />} />
          <Route path="/editprofile" element={<CreateSponsorProfile />} />
          {/* campaigns */}
          <Route index path="/create-campaign" element={<CreateCampaign />} />
          <Route path="/edit-campaign/:campaignId" element={<CreateCampaign isEdit={true} />} />
          <Route path="/messages/chat" element={<ChatRoom />} />

          {/* timeline and related pages */}
          <Route path="/" element={<SponsorTLayout />}>
            <Route index path="/timeline" element={<SponsorTimeline />} />
            <Route path="/drafted-campaigns" element={<DraftCampaigns />} />
          </Route>
        </Route>
      </Route>

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default Index;
