import React from "react";
import { Col, Row, Grid } from "antd";
const { useBreakpoint } = Grid;

import HowItWorksCard from "./HowItWorksCard";

const Data = [
  {
    step: 1,
    title1: "Create Profile/Signup",
    title2: "Create Profile/Signup",
    des1: `Easily create your profile to access and join sports events effortlessly. Set your preferences and get ready to compete!`,
    des2: `Easily create your profile to access and join sports events effortlessly. Set your preferences and get ready to compete!`,
    imgUrl1: "profile",
    imgUrl2: "profile"
  },
  {
    step: 2,
    title1: "Connect",
    title2: "Connect",
    des1: `Easily create your profile to join and participate in sports events. Connect with other players, set your preferences, and get ready to compete!`,
    des2: `Easily create your profile to join and participate in sports events. Connect with other players, set your preferences, and get ready to compete!`,
    imgUrl1: "connect",
    imgUrl2: "connect"
  },
  {
    step: 3,
    title1: "Explore Events",
    title2: "Create an Event",
    des1: `Browse through a variety of sports events, view details, and find opportunities that match your interests and skills.`,
    des2: `Set up and customize your sports events with details like date, time, location, and event specifics. Easily manage everything.`,
    imgUrl1: "explore",
    imgUrl2: "create"
  },
  {
    step: 4,
    title1: "Participate",
    title2: "Gather Audience",
    des1: `Join events with a single click, and take part in the action—compete, have fun, and showcase your skills!`,
    des2: `Promote your events to attract participants and spectators. Use the app’s tools to reach a wide audience and generate excitement.`,
    imgUrl1: "participate",
    imgUrl2: "gather"
  },
  {
    step: 5,
    title1: "Flex your awards",
    title2: "Manage",
    des1: `Show off your achievements and awards on your profile to highlight your skills and successes!`,
    des2: `Oversee all aspects of your events, from registrations and schedules to communication with participants and handling logistics.`,
    imgUrl1: "award",
    imgUrl2: "manage"
  }
];

const HowItWorks = () => {
  const screens = useBreakpoint();
  return (
    <>
      <Row justify="center" vertical style={{ margin: "5rem 0" }}>
        <Col align="center">
          <h2 style={{ fontSize: "40px", fontWeight: "600", marginBottom: "0.2rem" }}>
            How it works?
          </h2>
          <p style={{ fontSize: "12px", margin: `0 ${screens.sm ? "5rem" : "1rem"}` }}>
            &quot;Elevate Your Game - Discover How Our Platform Power Your Athletic Journey.&quot;
          </p>
          {screens.md && (
            <div
              style={{
                marginTop: "10px",
                border: "#3f5dff 3px solid",
                width: "fit-content",
                height: "20px"
              }}></div>
          )}
          {screens.md && (
            <div
              style={{
                border: "#3f5dff 5px solid",
                width: "100%",
                padding: "15px",
                borderBottom: "0"
              }}></div>
          )}
        </Col>
        <Row
          justify={screens.md ? "space-between" : "center"}
          style={{ marginTop: "1rem" }}
          wrap={screens.md ? false : true}>
          <Col xs={24} sm={16} md={11} lg={9} xl={8} align="center">
            <p style={{ color: "#68d5ff", fontSize: "18px", fontWeight: "500", marginBottom: "0" }}>
              For Player
            </p>
            <div
              style={{
                color: "#68d5ff",
                fontSize: "4rem",
                fontWeight: "500"
              }}>
              &#x2193;
            </div>
            {Data.map((el, index) => (
              <HowItWorksCard
                player={true}
                step={el.step}
                title={el.title1}
                des={el.des1}
                key={index + el.step}
                imgUrl={el.imgUrl1}
              />
            ))}
          </Col>
          <Col xs={24} sm={16} md={11} lg={9} xl={8} align="center">
            <p
              style={{
                color: "#3f5dff",
                fontSize: "18px",
                fontWeight: "500",
                marginBottom: "0",
                marginTop: `${screens.md ? " " : "1rem"}`
              }}>
              For Organizer
            </p>
            <div
              style={{
                color: "#3f5dff",
                fontSize: "4rem",
                fontWeight: "500"
              }}>
              &#x2193;
            </div>
            {Data.map((el, index) => (
              <HowItWorksCard
                step={el.step}
                title={el.title2}
                des={el.des2}
                key={index + el.step}
                imgUrl={el.imgUrl2}
              />
            ))}
          </Col>
        </Row>
      </Row>
    </>
  );
};

export default HowItWorks;
