import { useState, useEffect, useRef } from "react";
import { verifyUserName } from "../../Config/API/user";
import { minUserNameLength } from "../../Config/helpers/constants";

const useCheckUsernameAvailability = (username, type, prevUserName) => {
  const [isAvailable, setIsAvailable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const timeoutRef = useRef(null);

  const checkAvailability = async () => {
    setIsLoading(true);
    setError(null);

    const payload = {
      type,
      username
    };
    try {
      const response = await verifyUserName(payload);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setIsAvailable(
        data?.type === "success" && !data?.result?.toLowerCase()?.includes("already exist")
          ? true
          : false
      );
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (username !== prevUserName && username?.length >= minUserNameLength) {
      // Clear any previous timeouts
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      // Create a new timeout
      timeoutRef.current = setTimeout(() => {
        checkAvailability();
      }, 500); // Adjust delay as needed (in milliseconds)
    }

    if (username?.length < minUserNameLength) {
      setIsAvailable(false);
    }

    // Cleanup function to clear timeout on unmount
    return () => clearTimeout(timeoutRef.current);
  }, [username, prevUserName]);

  return { isAvailable, isLoading, error };
};

export default useCheckUsernameAvailability;
