import React from "react";
import { Truncate } from "../../../../../../common/Text/Truncate";
import { VIEW_TEAM } from "../../../../../../utils/page-urls";

export const QualifyingColumn = [
  {
    title: "Qualifiers",
    dataIndex: "Q",
    key: "Qualifiersq",
    onCell: () => {
      return {
        style: { color: "var(--blue)" },
        key: new Date().getMilliseconds()
      };
    },

    render: (status) => <span key={new Date().getTime()}>{status ? "Q" : ""}</span>
  },
  {
    title: "Teams",
    dataIndex: "team",
    key: "Teamsq",
    onCell: () => {
      return {
        style: { color: "var(--blue)" },
        key: new Date().getMilliseconds()
      };
    },

    render: (_, record) => (
      <Truncate
        text={record?.team?.name}
        onClick={() => {
          window.open(VIEW_TEAM + `/${record?.team?._id}`, "_blank");
        }}
        className="tableteamname no-style-link"
        key={new Date().getTime()}
      />
    )
  },
  {
    title: "WWCD",
    dataIndex: "wwcd",
    key: "wwcd"
  },
  {
    title: "Kills",
    dataIndex: "kills",
    key: "killsq"
  },
  {
    title: "Points",
    dataIndex: "points",
    key: "Pointq"
  }
];

export const Column = [
  {
    title: "Teams",
    dataIndex: "team",
    onCell: () => {
      return {
        style: { color: "var(--blue)" },
        key: new Date().getMilliseconds()
      };
    },

    render: (_, record) => (
      <Truncate
        text={record?.team?.name}
        onClick={() => {
          window.open(VIEW_TEAM + `/${record?.team?._id}`, "_blank");
        }}
        className="tableteamname no-style-link"
        key={new Date().getTime()}
      />
    )
  },
  {
    title: "Rank",
    dataIndex: "rank",
    key: "rank"
  },
  {
    title: "Kills",
    dataIndex: "kills",
    key: "kills"
  },
  {
    title: "Points",
    dataIndex: "points",
    key: "Point"
  }
];
