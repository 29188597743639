import { Timeline, Grid } from "antd";
import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { PROFILE } from "../../../Store/Features/ProfileSlice";
import Loader from "../../../common/cards/Loader";
import { useEventHistory } from "../../../common/hooks/useEventHistory";
import { ROLES } from "../../../Config/helpers/constants";
import EventHistoryCard from "../../../common/EventHistroy/EventHistoryCard";
import "../../../common/EventHistroy/EventHistoryCard.css";
import TimelineDot from "../../../common/EventHistroy/TimelineDot";
import { KEYS } from "../../../Config/Keys";
function OrganizerEventsHistory() {
  const { useBreakpoint } = Grid;
  const breakpoints = useBreakpoint();

  const userDetails = useSelector(PROFILE);

  const { historyLoading, historyData } = useEventHistory(ROLES.organizer, userDetails?._id);

  return (
    <Fragment>
      <h3 style={{ fontWeight: 550, marginTop: "4px", textAlign: "center" }}>Event History</h3>
      {historyLoading ? (
        <Loader />
      ) : (
        <div className="event-history-wrapper">
          {historyData?.length > 0 ? (
            breakpoints.md ? (
              <Timeline mode={"alternate"}>
                {historyData?.reverse().map((event, index) => (
                  <Timeline.Item
                    key={index}
                    dot={
                      <TimelineDot
                        img={
                          event?.file?.file
                            ? KEYS.API + "/" + event?.file?.file
                            : "/images/homesports.png"
                        }
                      />
                    }>
                    <EventHistoryCard data={event} historyFor={ROLES.organizer} />
                  </Timeline.Item>
                ))}
              </Timeline>
            ) : (
              historyData.map((event, index) => (
                <EventHistoryCard key={index} data={event} historyFor={ROLES.organizer} />
              ))
            )
          ) : (
            <h3 style={{ textAlign: "center" }}>Event History Not Found</h3>
          )}
        </div>

        // <Chrono
        //   items={historyData}
        //   mode="VERTICAL_ALTERNATING"
        //   focusActiveItemOnLoad
        //   activeItemIndex={selected}
        //   cardHeight={90}
        //   hideControls
        // />
      )}
    </Fragment>
  );
}

export default OrganizerEventsHistory;
