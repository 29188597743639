import { Modal, Row, Col } from "antd";
import React from "react";
import Card from "../../Cards/RegisteredTeamModalCard";

const App = ({ modalVisible, setModalVisible, teams }) => {
  return (
    <React.Fragment>
      <Modal
        style={{ width: "95%" }}
        centered
        visible={modalVisible}
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        bodyStyle={{
          overflowY: "auto",
          height: "70vh",
          width: "100%",
          maxHeight: "500px"
        }}
        className="teamsmodaloverflow"
        closable={false}
        destroyOnClose
        footer={false}>
        <h2
          style={{ textAlign: "center", fontWeight: "700", userSelect: "none", marginTop: "10px" }}>
          Registered Teams
        </h2>
        <Row justify="space-evenly">
          {teams?.map((e, i) => {
            return (
              <Col key={i} lg={7} md={11} sm={24} xs={24}>
                <Card team={e} />
              </Col>
            );
          })}
        </Row>
      </Modal>
    </React.Fragment>
  );
};

export default App;
