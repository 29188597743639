import React from "react";
import { Col, Typography, Grid } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const { Title } = Typography;
const { useBreakpoint } = Grid;

function Loader({ fullpage, text, center, small, smaller, noShadow, PB, height }) {
  const stylefull = {
    position: "fixed",
    left: "0",
    top: "0",
    width: "100vw",
    height: "100vh",
    background: "white",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    gap: "15px",
    alignItems: "center",
    zIndex: 1001
  };
  const style = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    borderRadius: "8px",
    background: "white"
  };
  const smallprops = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  };

  const screens = useBreakpoint();

  return (
    <div
      className={smaller ? "" : "NoDataWrapper"}
      style={{
        boxShadow: noShadow === true ? "unset" : "0px 3.94082px 14.7781px rgb(0 0 0 / 9%)",
        paddingBottom: PB ? PB : undefined,
        border: PB ? "none" : undefined,
        height: PB ? "100px" : height ? height : undefined
      }}>
      {smaller && (
        <div
          style={{
            ...smallprops,
            display: "inline-block",
            textAlign: center ? "center" : undefined,
            padding: center ? "4rem 1rem" : undefined,
            width: center ? "100%" : undefined
          }}>
          <LoadingOutlined style={{ fontSize: 24 }} spin />
        </div>
      )}
      {small && (
        <Col span={24} style={smallprops}>
          <LoadingOutlined style={{ fontSize: 24 }} spin />
          <Typography
            style={{
              display: text ? "block" : "none",
              marginTop: text ? "20px" : "none"
            }}>
            <Title level={screens["xs"] ? 5 : 3}>{text}</Title>
          </Typography>
        </Col>
      )}
      {!small && !smaller && (
        <Col span={24} style={fullpage ? { ...stylefull } : { ...style }}>
          <LoadingOutlined style={{ fontSize: 24 }} spin />
          <Typography
            style={{
              padding: "10px",
              display: text ? "block" : "none",
              marginTop: text ? "20px" : "none",
              textAlign: "center"
            }}>
            <Title level={screens["xs"] ? 4 : 3}>{text}</Title>
          </Typography>
        </Col>
      )}
    </div>
  );
}

export default Loader;
