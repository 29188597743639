import React, { Fragment, useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { Row, Col, Input, Badge, Menu, Dropdown, Button, Form } from "antd";
import Notification from "../../../common/Notification/NotificationDesktop";
import { FaSearch, FaBell, FaAngleDown, FaBars } from "react-icons/fa";
import PlayerDrawer from "../drawer/PlayerDrawer";
import { fallbackprofile } from "../../../common/Utils";

import { KEYS } from "../../../Config/Keys";
import { useCurrentPage } from "../../../common/hooks/useCurrentPage";
import FadeInImage from "../../../common/Images/FadeInImage";
import { Info } from "../../../common/Alerts/Message";
import useVariables from "../../../common/hooks/useVariables";
import { AiTwotoneMessage } from "react-icons/ai";
import { useSelector } from "react-redux";

const PlayerNav = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { query, id } = useParams();
  const [text, settext] = useState(query);
  const [visible, setVisible] = useState(false);
  const [open, setOpen] = useState(false); // notification List
  const { url } = useCurrentPage();
  const flexTokens = useSelector((state) => state.wallet.flexTokens);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const { viewer } = useVariables();

  var currentflag = "player",
    currentid = viewer?.id;
  const notClickable = pathname === "/player";

  const UserItems = [
    {
      label: (
        <Link style={{ color: "var(--green)" }} to="/organizer">
          Switch to Organizer
        </Link>
      ),
      key: "switch",
      onClick: () => {
        localStorage.removeItem("ProfileID");
        const userDetails = JSON.parse(localStorage.getItem("userDetails"));
        userDetails.role = "Organizer";
        localStorage.setItem("userDetails", JSON.stringify(userDetails));
      }
    },
    {
      type: "divider"
    },

    notClickable
      ? null
      : {
          label: <Link to="/player/wallet">FlexTokens: {flexTokens}</Link>,
          key: "wallet"
        },
    {
      type: "divider"
    },
    notClickable
      ? null
      : {
          label: <Link to="/player/timeline">My Profile</Link>,
          key: "profile"
        },
    {
      type: "divider"
    },
    notClickable
      ? null
      : {
          label: (
            <Link
              state={{ currentflag, currentid }}
              to={currentflag === "team" ? `/player/team/${id}/settings` : "/player/settings"}>
              Settings and Privacy
            </Link>
          ),
          key: "PrivacyandSettings"
        },
    {
      type: "divider"
    },
    {
      label: <Link to="/signin">Log out</Link>,
      key: "Logout",
      onClick: () => {
        localStorage.removeItem("ProfileID");
        localStorage.removeItem("userToken");
        localStorage.removeItem("userDetails");
        localStorage.removeItem("panel");
        localStorage.removeItem("viewer");
        localStorage.removeItem("CoverPic");
        localStorage.removeItem("ProfilerPic");
      }
    }
  ];

  const Events = [
    {
      label: <Link to="/player/events/pastevents">Past Events</Link>,
      key: "pastevents"
    },
    {
      type: "divider"
    },
    {
      label: <Link to="/player/events">Current Events</Link>,
      key: "currentevents"
    },
    {
      type: "divider"
    },
    {
      label: <Link to="/player/events/markedevents">Marked Events</Link>,
      key: "MarkedEvents"
    }
  ];

  const Teams = [
    {
      label: (
        <Link to="/player/search/teams" state={"teams"}>
          Join Team
        </Link>
      ),
      key: "JoinTeams"
    },

    {
      type: "divider"
    },
    {
      label: <Link to="/player/teams">My Teams</Link>,
      key: "teams"
    },
    {
      type: "divider"
    },

    {
      label: <Link to="/player/createteam">Create Team</Link>,
      key: "createTeams"
    }
  ];

  const u = useRef();
  const t = useRef();
  const e = useRef();
  useEffect(() => {
    window.addEventListener("load", onClose);
    window.addEventListener("resize", onClose);
    return () => {
      window.removeEventListener("load", onClose);
      window.removeEventListener("resize", onClose);
    };
  }, []);

  useEffect(() => {
    onClose();
  }, [pathname]);
  useEffect(() => {
    settext(query);
  }, [query]);

  const ALERT = () => Info("Create Profile First");

  return (
    <Fragment>
      <PlayerDrawer onClose={onClose} visible={visible} />
      <Row className="mobileNav">
        <Col lg={4} md={4}>
          <Link to="/">
            <img alt="logo" className="navlogo" height="40px" src="/images/logo.png" />
          </Link>
        </Col>
        <Col className="mobileNav" span={2}>
          {notClickable ? (
            <div onClick={ALERT} className={`Nav_a`}>
              Home
            </div>
          ) : (
            <Link to="/player/feed" className={`Nav_a ${url === "feed" && "active"}`}>
              Home
            </Link>
          )}
        </Col>
        <Col className="mobileNav" span={2} ref={e}>
          {notClickable ? (
            <div onClick={ALERT}>
              <span className={`Nav_a`}>
                Events <FaAngleDown style={{ verticalAlign: "middle" }} />
              </span>
            </div>
          ) : (
            <Dropdown
              placement="bottom"
              getPopupContainer={() => e.current}
              overlay={<Menu items={Events} />}
              trigger={["click"]}>
              <a className={`ant-dropdown-link Nav_a ${url === "event" && "active"}`}>
                Events
                <FaAngleDown style={{ verticalAlign: "middle" }} />
              </a>
            </Dropdown>
          )}
        </Col>
        <Col className="mobileNav" span={2} ref={t}>
          {notClickable ? (
            <div onClick={ALERT}>
              <span className={`Nav_a`}>
                Teams <FaAngleDown style={{ verticalAlign: "middle" }} />
              </span>
            </div>
          ) : (
            <Dropdown
              placement="bottom"
              getPopupContainer={() => t.current}
              overlay={<Menu items={Teams} />}
              trigger={["click"]}>
              <a className={`ant-dropdown-link Nav_a ${url === "team" && "active"}`}>
                Teams <FaAngleDown style={{ verticalAlign: "middle" }} />
              </a>
            </Dropdown>
          )}
        </Col>
        <Col className="mobileNav" span={2}>
          {notClickable ? (
            <div onClick={ALERT} className={`Nav_a`}>
              Messages
            </div>
          ) : (
            <Link to="/player/messages/chat" className={`Nav_a ${url === "messages" && "active"}`}>
              Messages
            </Link>
          )}
        </Col>
        <Col className="mobileNav" span={6}>
          <Form
            style={{ display: "flex", alignItems: "center", height: "100%" }}
            onFinish={() => {
              if (text?.trim().length > 0 && !notClickable) {
                navigate(`/player/search/${text}`, { state: text });
              } else if (notClickable) {
                ALERT();
              }
            }}>
            <Form.Item style={{ margin: "0px", width: "85%" }} name="search">
              <Input
                size="middle"
                className="navinput"
                defaultValue={query}
                value={text}
                onChange={(e) => settext(e.currentTarget.value)}
                placeholder="Search"
                prefix={
                  <FaSearch
                    onClick={() => {
                      if (text?.trim().length > 0 && !notClickable) {
                        navigate(`/player/search/${text}`, { state: text });
                      } else if (notClickable) {
                        ALERT();
                      }
                    }}
                    style={{
                      cursor: "pointer",
                      color: "#8F8F8F",
                      marginRight: "10px",
                      width: "25px",
                      height: "16px"
                    }}
                  />
                }
              />
            </Form.Item>
          </Form>
        </Col>

        <Col className="mobileNav mobileNavFlex" span={6}>
          <Badge className="belldot" dot>
            <div style={{ position: "relative" }}>
              <FaBell
                style={{
                  marginLeft: "0",
                  fontSize: 22,
                  cursor: "pointer"
                }}
                onClick={() => {
                  if (!notClickable) setOpen((e) => !e);
                  else ALERT();
                }}
              />
              {open ? <Notification open={open} setOpen={setOpen} /> : null}
            </div>
          </Badge>
          <div
            ref={u}
            style={{
              position: "relative",
              marginLeft: "10px",
              display: "flex",
              alignItems: "center"
            }}>
            <Dropdown
              placement="bottom"
              getPopupContainer={() => u.current}
              overlayStyle={{ minWidth: "170px" }}
              overlay={<Menu items={UserItems} />}
              trigger={["click"]}>
              <a
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  marginLeft: "10px",
                  overflowY: "hidden"
                }}
                className={`ant-dropdown-link Nav_a`}>
                <FadeInImage
                  type="Fadenavprofile"
                  fallaback={fallbackprofile}
                  src={KEYS.API + "/" + viewer?.profile}
                />
                <span
                  style={{
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    maxWidth: "150px",
                    display: "inline-block",
                    userSelect: "none",
                    fontSize: "15px",
                    fontWeight: "600",
                    marginLeft: "10px"
                  }}>
                  {viewer?.name}
                </span>
                <span>
                  <FaAngleDown
                    style={{
                      marginLeft: "5px",
                      verticalAlign: "middle"
                    }}
                  />
                </span>
              </a>
            </Dropdown>
          </div>
        </Col>
      </Row>
      <div className="mobileNavhide">
        <Link to="/">
          <img alt="logo" className="navlogo" height="40px" src="/images/logo.png" />
        </Link>
        <Row align="center" style={{ padding: "0" }}>
          <Row
            style={{
              gap: "15px",
              alignItems: "center"
            }}>
            <Col
              className="drawerpadding"
              style={{ paddingInline: "0", paddingTop: "0", position: "relative", top: "6px" }}>
              {notClickable ? (
                <div></div>
              ) : (
                <Link to="/player/messages/chat">
                  <AiTwotoneMessage
                    style={{
                      marginLeft: "0",
                      fontSize: 17.5,
                      cursor: "pointer"
                    }}
                  />
                </Link>
              )}
            </Col>
            <Col
              className="drawerpadding"
              style={{ paddingInline: "0", paddingTop: "0", position: "relative", top: "6px" }}>
              {notClickable ? (
                <div onClick={ALERT}>{}</div>
              ) : (
                <Link to={"/player/notifications"}>
                  <Badge dot>
                    <FaBell
                      style={{
                        marginLeft: "0",
                        fontSize: 17.5,
                        cursor: "pointer"
                      }}
                    />
                  </Badge>
                </Link>
              )}
            </Col>
          </Row>

          <Button className="drawertogglebutton" type="primary" onClick={showDrawer}>
            <FaBars style={{ verticalAlign: "middle" }} />
          </Button>
        </Row>
      </div>
    </Fragment>
  );
};

export default PlayerNav;
