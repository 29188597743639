import React, { Fragment, useState } from "react";
import EditMatchesCard from "./EditMatchesCard";
import MatchesCard from "./MatchesCard";
import HistoryMatchCard from "./HistoryMatchCard";

function MatchCardWrapper({
  item,
  index,
  history, //index of this element
  SwappedEle, //API loading, Skeleton Loading
  Orderloading, //loading while swapping,Button Loading
  sortedpoints
}) {
  const [editresult, seteditresult] = useState(false);

  return history ? (
    <HistoryMatchCard item={item} index={index} />
  ) : (
    <Fragment>
      {editresult ? (
        <EditMatchesCard
          item={item}
          index={index}
          SwappedEle={SwappedEle}
          Orderloading={Orderloading}
          sortedpoints={sortedpoints}
          seteditresult={seteditresult}
        />
      ) : (
        <MatchesCard
          item={item}
          index={index}
          SwappedEle={SwappedEle}
          Orderloading={Orderloading}
          sortedpoints={sortedpoints}
          seteditresult={seteditresult}
        />
      )}
    </Fragment>
  );
}

export default MatchCardWrapper;
