import React, { Fragment, useState, useEffect } from "react";
import { KEYS } from "../../../../Config/Keys";
import Loader from "../../../../common/cards/Loader";
import FadeInImage from "../../../../common/Images/FadeInImage";
import { useNavigate } from "react-router-dom";
import { fallbackprofile } from "../../../../common/Utils";
const InviteCard = ({ team }) => {
  const [loading, setloading] = useState(false);
  useEffect(() => {
    if (!team) setloading(true);
    else setloading(false);
    return () => {
      setloading(false);
    };
  }, [team]);
  const navigate = useNavigate();
  return (
    <Fragment>
      {loading ? <Loader fullpage loading={"white"} /> : null}
      <div
        className=" membercard"
        style={{ cursor: "pointer" }}
        onClick={() => navigate(`/view/player/${team?.player?._id}/timeline`, { state: "player" })}>
        <div className="popadd">
          <FadeInImage
            src={KEYS.API + "/" + team?.player?.profilepic}
            className="poprofilepic"
            fallaback={fallbackprofile}
            type={"Fadegcard"}
          />
        </div>

        <p className="namepoinvite">{team?.player?.name}</p>
        <p className="nameporesp">Waiting for player to accept invitation</p>
      </div>
    </Fragment>
  );
};

export default InviteCard;
