import React, { Fragment } from "react";
import { Row, Col, Avatar, Typography } from "antd";

import { FaCalendarAlt, FaLocationArrow } from "react-icons/fa";
import { SiNike } from "react-icons/si";
import { useNavigate, useLocation } from "react-router-dom";
import { KEYS } from "../../../Config/Keys";
import FadeInImage from "../../Images/FadeInImage";
import moment from "moment";
import { fallbackcover } from "../../Utils";

const EventCard = ({ data, drafted }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const currentUser = pathname.includes("player") ? "player" : "organizer";
  const eid = data?._id || 123;

  return (
    <Fragment>
      <Row
        className="eventcard"
        justify="space-between"
        style={{ background: `${drafted ? "#ccfae5" : "white"}` }}
        onClick={() => {
          if (currentUser === "player" && !drafted) {
            navigate(`/view/event/${eid}`);
          } else if (currentUser === "organizer") {
            if (drafted) {
              navigate(`/${currentUser}/editevent/${eid}`);
            } else {
              navigate(`/${currentUser}/event/${eid}`);
            }
          }
        }}>
        <Col span={24}>
          <FadeInImage
            radius
            EventCard
            type={"media"}
            fallaback={fallbackcover}
            src={KEYS.API + "/" + data?.file?.file}
          />
        </Col>

        <Col lg={10} md={10} xs={24} sm={24}>
          <div style={{ padding: "15px 0px 5px 15px" }}>
            <p style={{ fontSize: "11px", color: "red", margin: "0px" }}>
              <FaCalendarAlt />
              <span style={{ marginLeft: "5px" }}>
                {new Date(data?.startDate).toDateString() +
                  " - " +
                  new Date(data?.endDate).toDateString()}
              </span>
            </p>
            <p style={{ fontWeight: "500", marginTop: "20px" }}>
              {data?.name}{" "}
              {drafted && (
                <span
                  style={{
                    fontSize: "10px",
                    padding: "2px 10px",
                    backgroundColor: "white",
                    fontWeight: "700",
                    borderRadius: "5px"
                  }}>
                  Drafted
                </span>
              )}
            </p>

            <Typography.Text
              autoCapitalize
              style={{
                fontSize: "11px",
                display: "flex",
                alignItems: "center",
                gap: "5px"
              }}>
              <FaLocationArrow /> {data?.city || data?.location || "Location Missing"}
            </Typography.Text>
          </div>
        </Col>

        <Col lg={14} md={14} xs={24} sm={20} className="eventCardRightSide">
          <p>
            <b>Category : </b>
            {data?.typeofsports || " - "}
          </p>
          <p>
            {data?.registrationComplete ||
            moment(new Date(data?.deadline).setHours(24)) < new Date() ? (
              <span>
                <b>Registration : </b>
                Closed
              </span>
            ) : (
              <span>
                <b>Closing : </b>
                <span>{moment(new Date(data?.deadline).setHours(24)).from()}</span>
              </span>
            )}
          </p>

          <Avatar.Group
            maxCount={4}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              position: "relative"
            }}
            max={{
              count: 4,
              style: {
                color: "#f56a00",
                backgroundColor: "#fde3cf"
              }
            }}>
            {data?.registerteams.map((item, i) => (
              <Avatar src={`${process.env.REACT_APP_API_URL}/${item?.team?.profilepic}`} key={i} />
            ))}{" "}
          </Avatar.Group>

          <div
            className=""
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",

              marginTop: "10px"
            }}>
            <div style={{ display: "flex", alignItems: "flex-start", columnGap: "10px" }}>
              <p
                style={{
                  fontSize: "10px",
                  fontWeight: "700"
                }}>
                Sponsors
              </p>

              <SiNike />

              <SiNike />
            </div>
            <div
              style={{ borderLeft: "1px solid #ffacac", height: "40px", margin: "0 10px" }}></div>
            <div style={{ display: "flex", alignItems: "center", columnGap: "20px" }}>
              <p
                style={{
                  fontSize: "10px",
                  fontWeight: "700"
                }}>
                Awards{" "}
              </p>
              <div>
                <p
                  style={{
                    justifyContent: "flex-start",
                    color: "#ff3a00",
                    fontWeight: "600",
                    fontSize: "10px"
                  }}>
                  💵 {data?.fee || 0} Rs
                </p>

                <p
                  style={{
                    justifyContent: "flex-start",
                    color: "#ff3a00",
                    fontWeight: "600",
                    fontSize: "10px"
                  }}>
                  🛡️ {data?.shield || 0} Shield
                </p>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

export default EventCard;
