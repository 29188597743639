import React from "react";
import { Space } from "antd";
import { useEditProfile } from "../Functions";
import ProfileSetting from "./ProfileSetting";
import Security from "./Security";

function SearchPageMain({ active }) {
  const {
    loading,
    profile,
    setprofile,
    setstatus,
    status,
    setloading,
    ChangeProfileStatus,
    setlname,
    pnumber,
    setpnumber,
    fname,
    setfname,
    lname,
    role,
    setrole,
    nickname,
    setnickname
  } = useEditProfile();
  return (
    <Space direction="vertical" style={{ width: "100%" }} size="large">
      {active === 1 ? (
        <ProfileSetting
          loading={loading}
          setloading={setloading}
          profile={profile}
          setprofile={setprofile}
          toggleprofile={ChangeProfileStatus}
          active={active}
          status={status}
          setstatus={setstatus}
          pnumber={pnumber}
          setpnumber={setpnumber}
          setfname={setfname}
          fname={fname}
          lname={lname}
          setlname={setlname}
          role={role}
          setrole={setrole}
          nickname={nickname}
          setnickname={setnickname}
        />
      ) : null}
      {active === 2 ? <Security loading={loading} setloading={setloading} /> : null}
    </Space>
  );
}

export default SearchPageMain;
