import { configureStore } from "@reduxjs/toolkit";
import ProfileSliceReducer from "./Features/ProfileSlice";
import PostSliceReducer from "./Features/PostSlice";
import EventSliceReducer from "./Features/EventSlice";
import MatchesSliceReducer from "./Features/MatchesSlice";
import kobracketsliceReducer from "./Features/kobracketslice";
import walletSliceReducer from "./Features/WalletSlice";

export const store = configureStore({
  reducer: {
    profile: ProfileSliceReducer,
    post: PostSliceReducer,
    event: EventSliceReducer,
    matches: MatchesSliceReducer,
    ko: kobracketsliceReducer,
    wallet: walletSliceReducer
  }
});
