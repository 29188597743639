import React from "react";
import { Col, Row, Typography } from "antd";
import { FaCalendarAlt } from "react-icons/fa";
import FadeInImage from "../Images/FadeInImage";
import dayjs from "dayjs";
import { KEYS } from "../../Config/Keys";

const PastEventCard = ({ data }) => {
  return (
    <Row className="sideItem">
      <FadeInImage
        radiusAll
        type={"FadesideItem"}
        src={data?.file?.file ? KEYS.API + "/" + data?.file?.file : "/images/homesports.png"}
        className="sideImageHeight"
        fallaback="/images/subevent.png"
      />
      <Col lg={24} md={24} xs={24} sm={24}>
        <div style={{ padding: "0px 20px" }}>
          <p
            style={{
              fontSize: "11px",
              marginTop: "10px",
              marginBottom: "2px",
              fontWeight: "500"
            }}>
            {data?.name || data?.eventName || "-"}
          </p>
          <p className="bold mb-1">
            <span>{data?.numberOfParticipants}</span> Participants
          </p>
          <p style={{ fontSize: "11px", marginTop: "10px" }}>
            <FaCalendarAlt />{" "}
            {data?.startDate ? dayjs(data?.startDate).format("DD MMM YYYY") : "- no date"}
          </p>
          <p
            style={{
              fontSize: "11px",
              marginTop: "10px",
              color: "#0085FF"
            }}>
            Panel: <Typography.Text type="secondary">{data?.panel?.name}</Typography.Text>
          </p>
        </div>
      </Col>
    </Row>
  );
};

export default PastEventCard;
