// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toinputpic {
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
}
.toinputdiv {
  width: 100%;
  background-color: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  justify-content: flex-start;
  /* box-shadow: 0px 4px 8px rgb(0 0 0 / 4%); */

  padding: 3% 2% 2% 4%;
}
.toinputpics {
  width: 100%;
  background-color: white;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  justify-content: flex-start;
  padding: 3% 2% 2% 4%;
  margin-bottom: 3%;
  border-top: 2px solid var(--lightGrey);
}
.uploaddedMediaTimeline {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}
.uploaddedMediaTimeline img,
.uploaddedMediaTimeline video {
  border-radius: 20px;
  max-height: 400px;
  min-height: 200px;
  /* object-fit: contain; */
  object-fit: cover;
  position: relative;
}

.tomt {
  padding-top: 3.5%;
}
.totextarea {
  border: none;
}
/* .toinputpics span, */
.toinputpic span,
.toinputpics .ant-upload button span {
  font-size: 11px;
  margin-left: 8px;
  color: #888888;
}
.topaddingeh {
  margin-top: 5%;
  margin-left: 8%;
}
`, "",{"version":3,"sources":["webpack://./src/styles/organizer/TimelineOrganizer.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,eAAe;AACjB;AACA;EACE,WAAW;EACX,uBAAuB;EACvB,2BAA2B;EAC3B,4BAA4B;EAC5B,aAAa;EACb,2BAA2B;EAC3B,6CAA6C;;EAE7C,oBAAoB;AACtB;AACA;EACE,WAAW;EACX,uBAAuB;EACvB,8BAA8B;EAC9B,+BAA+B;EAC/B,aAAa;EACb,2BAA2B;EAC3B,oBAAoB;EACpB,iBAAiB;EACjB,sCAAsC;AACxC;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,SAAS;EACT,WAAW;AACb;AACA;;EAEE,mBAAmB;EACnB,iBAAiB;EACjB,iBAAiB;EACjB,yBAAyB;EACzB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB;AACA;EACE,YAAY;AACd;AACA,uBAAuB;AACvB;;EAEE,eAAe;EACf,gBAAgB;EAChB,cAAc;AAChB;AACA;EACE,cAAc;EACd,eAAe;AACjB","sourcesContent":[".toinputpic {\n  border-radius: 50%;\n  object-fit: cover;\n  cursor: pointer;\n}\n.toinputdiv {\n  width: 100%;\n  background-color: white;\n  border-top-left-radius: 8px;\n  border-top-right-radius: 8px;\n  display: flex;\n  justify-content: flex-start;\n  /* box-shadow: 0px 4px 8px rgb(0 0 0 / 4%); */\n\n  padding: 3% 2% 2% 4%;\n}\n.toinputpics {\n  width: 100%;\n  background-color: white;\n  border-bottom-left-radius: 8px;\n  border-bottom-right-radius: 8px;\n  display: flex;\n  justify-content: flex-start;\n  padding: 3% 2% 2% 4%;\n  margin-bottom: 3%;\n  border-top: 2px solid var(--lightGrey);\n}\n.uploaddedMediaTimeline {\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  gap: 1rem;\n  width: 100%;\n}\n.uploaddedMediaTimeline img,\n.uploaddedMediaTimeline video {\n  border-radius: 20px;\n  max-height: 400px;\n  min-height: 200px;\n  /* object-fit: contain; */\n  object-fit: cover;\n  position: relative;\n}\n\n.tomt {\n  padding-top: 3.5%;\n}\n.totextarea {\n  border: none;\n}\n/* .toinputpics span, */\n.toinputpic span,\n.toinputpics .ant-upload button span {\n  font-size: 11px;\n  margin-left: 8px;\n  color: #888888;\n}\n.topaddingeh {\n  margin-top: 5%;\n  margin-left: 8%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
