import React, { Fragment, useState } from "react";
import { Card, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { fallbackprofile, loadimage } from "../../Utils";
import { KEYS } from "../../../Config/Keys";
import { useEffect } from "react";
import useVariables from "../../hooks/useVariables";
const { Meta } = Card;
const RegisteredTeamModalCard = ({ team }) => {
  const [loading, setloading] = useState(true);
  const navigate = useNavigate();
  const { viewer } = useVariables();

  useEffect(() => {
    loadimage(KEYS.API + "/" + team?.team?.profilepic, setloading);
    return () => setloading(null);
  }, [team?.team?.profilepic]);

  const name = team?.team?.name;
  const checkyourteam = () => {
    const teamCaptain = team?.team?.profile;
    const yourid = viewer?.id;

    if (teamCaptain === yourid) {
      navigate(`/player/team/${team?.team?._id}/timeline`);
    } else if (team?.members) {
      team?.members?.forEach((element) => {
        if (element?.player === yourid) {
          navigate(`/player/team/${team?.team?._id}/timeline`);
        }
      });
    } else {
      navigate(`/view/team/${team?.team?._id}/timeline`, { state: "team" });
    }
  };
  return (
    <Fragment>
      <div
        className="pomemberShadow"
        style={{
          width: "100%",
          borderRadius: 40,
          boxShadow: "0px 0px 39.6215px 1.15431px rgba(0, 0, 0, 0.05)"
        }}
        onClick={() => {
          checkyourteam();
        }}>
        <Card
          bordered={false}
          loading={loading}
          hoverable
          style={{
            padding: 3,
            borderRadius: "40px",
            height: "250px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            userSelect: "none"
          }}>
          <Meta
            style={{
              display: "grid",
              placeContent: "center",
              background: ""
            }}
            avatar={
              <img
                src={KEYS.API + "/" + team?.team?.profilepic}
                onErrorCapture={(e) => (e.currentTarget.src = fallbackprofile)}
                alt=""
                style={{
                  width: 100,
                  height: 100,
                  objectFit: "cover",
                  marginBottom: "15px",
                  borderRadius: "50%",
                  marginRight: "-10px"
                }}
              />
            }
          />
          <div className="additional">
            <h3 style={{ fontWeight: "550" }}>
              {String(name).length >= 25 ? name.substring(0, 25) + " ..." : name}
            </h3>
            {team?.wildCard && <Typography.Text>(wild Card)</Typography.Text>}
          </div>
        </Card>
      </div>
    </Fragment>
  );
};

export default RegisteredTeamModalCard;
