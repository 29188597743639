// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sideItem.activecard {
  transition: all 0.3s ease-in-out;
  background-color: #2c4257;
}
.sideItem.activecard .timelineAboutpadding {
  color: white;
}
.sideItem.activecard .datetimerow {
  background-color: transparent;
}
.sideItem.activecard .datetimerow p {
  color: white !important;
}
`, "",{"version":3,"sources":["webpack://./src/styles/Event/mulitplayer/groupcard.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;EAChC,yBAAyB;AAC3B;AACA;EACE,YAAY;AACd;AACA;EACE,6BAA6B;AAC/B;AACA;EACE,uBAAuB;AACzB","sourcesContent":[".sideItem.activecard {\n  transition: all 0.3s ease-in-out;\n  background-color: #2c4257;\n}\n.sideItem.activecard .timelineAboutpadding {\n  color: white;\n}\n.sideItem.activecard .datetimerow {\n  background-color: transparent;\n}\n.sideItem.activecard .datetimerow p {\n  color: white !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
