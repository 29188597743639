import React, { Fragment, useState, useEffect } from "react";
import { Row, Col, Form, Button, DatePicker, Select, Image } from "antd";

import { useLocation, useNavigate } from "react-router-dom";
import { fallbackcover } from "../../../common/Utils";

import Swal from "sweetalert2";
import { serverError } from "../../../common/Alerts/serverError";
import { CreateProfileSponsorAPI } from "../../../Config/API/sponsor";
import { useFetchInitialProfile } from "../../../common/hooks/useFetchInitialProfile";

import Loader from "../../../common/cards/Loader";
import { success } from "../../../common/Alerts/Message";
import { Formik } from "formik";
import InputTextField from "../../../common/FormItem/InputTextField";
import dayjs from "dayjs";
import { createSponsorProfileValidations } from "../../../Config/helpers/validations";
import { ROLES } from "../../../Config/helpers/constants";
import { KEYS } from "../../../Config/Keys";
import moment from "moment";
import useEditProfile from "../../../common/hooks/useEditProfile";

const { Option } = Select;

const CreateSponsorProfile = () => {
  const [isEdit, setIsEdit] = useState(window.location.pathname.includes("/editprofile"));
  const { state } = useLocation();

  const { Profile, profileLoding, setprofileLoding, profileCompleted } = useFetchInitialProfile(
    ROLES.sponsor
  );

  // edit profile
  const [editloading, edit] = useEditProfile();

  useEffect(() => {
    if (window.location.pathname.includes("/editprofile")) {
      setIsEdit(true);
    }

    if (
      Profile &&
      profileCompleted &&
      !isEdit &&
      !window.location.pathname.includes("/editprofile")
    ) {
      navigate("/sponsor/timeline");
    }
  }, [Profile, profileCompleted, isEdit, window.location.pathname]);

  console.log(Profile);

  // Profile
  const [profileImage, setProfileImage] = useState({
    file: {},
    preview: ""
  });

  const [coverImage, setCoverImage] = useState({
    file: {},
    preview: ""
  });

  const initialValues = {
    name: Profile?.firstName || Profile?.organization || Profile?.name || "",
    location: isEdit ? Profile?.location || "" : "",
    DOB: isEdit ? Profile?.dob || "" : "",
    businessType: isEdit ? Profile?.businessType || "" : "",
    description: isEdit ? Profile?.description || "" : ""
  };

  useEffect(() => {
    if (isEdit) {
      setCoverImage({
        file: {},
        preview: KEYS.API + "/" + Profile?.coverpic
      });
      setProfileImage({
        file: {},
        preview: KEYS.API + "/" + Profile?.profilepic
      });
    }
    return () => {
      setCoverImage({
        file: null,
        preview: ""
      });
      setProfileImage({
        file: null,
        preview: ""
      });
    };
  }, [isEdit]);

  const children = [];
  for (let i = 10; i < 36; i++) {
    children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
  }
  const navigate = useNavigate();

  // input type image handler
  const onImageFileChange = async (e) => {
    const file = e.target.files[0];
    if (file.size > 5000000) {
      return Swal.fire({
        icon: "error",
        title: "Uploading Error",
        text: "Image size should be less than 5mb",
        confirmButtonColor: "#0085FF",
        allowOutsideClick: false
      });
    }
    if (e.target.name === "profileImage") {
      setProfileImage({
        file: file,
        preview: URL.createObjectURL(file)
      });
    } else {
      setCoverImage({
        file: file,
        preview: URL.createObjectURL(file)
      });
    }
  };

  const onFinish = async (values) => {
    if (profileImage?.preview === "") {
      return Swal.fire({
        icon: "error",
        title: "Upload Logo photo first"
      });
    }

    const formData = new FormData();

    formData.append("name", values?.name);
    formData.append("dob", JSON.stringify(dayjs(values?.DOB).format("YYYY-MM-DD")) || "");
    formData.append("location", values?.location || "");
    formData.append("businessType", values?.businessType || "");
    formData.append("description", values?.description || "");

    if (profileImage?.preview) {
      formData.append("profile", profileImage?.file);
    }
    if (coverImage?.preview) {
      formData.append("cover", coverImage?.file);
    }

    if (isEdit) {
      edit(formData, state, Profile?._id);
    } else {
      let response;
      try {
        setprofileLoding(true);
        response = await CreateProfileSponsorAPI(formData);
        const data = await response.json();
        setprofileLoding(false);
        if (data.type === "success") {
          success("Profile Created Succesfully");
          navigate("/sponsor/timeline");
        }
      } catch (err) {
        console.log(err);
        setprofileLoding(false);

        serverError();
      }
    }
  };

  // handle non-formik field changes
  function handleChanges(name, value, setFieldValue) {
    setFieldValue(name, value);
  }
  return (
    <Fragment>
      <div>
        {profileLoding || (editloading && <Loader fullpage loading={"white"} />)}
        <h1 className="headingPlayer"> {isEdit && "Edit"} Sponsor</h1>
        <Row>
          <Col sm={24} xs={24} md={24} style={{ position: "relative" }}>
            <div
              style={{
                position: "relative",
                width: "96%",
                margin: "auto",
                background: "#DADADA",
                borderRadius: "8px"
              }}
              className="CoverImageWrapper">
              {coverImage?.preview !== "" ? (
                <Image
                  wrapperClassName="CoverImageWrapper"
                  className="CoverImage"
                  preview={false}
                  src={coverImage?.preview}
                  style={{ objectFit: "cover" }}
                  fallback={fallbackcover}
                />
              ) : null}
              <div className="profileuploaders">
                <label htmlFor="coveruploader" style={{}}>
                  <img src="/images/icons/camera.png" alt="" />
                  {coverImage?.preview === "" ? <span>Add your Cover image</span> : null}
                </label>
                <input
                  type="file"
                  name="CoverImage"
                  className="uploaderInput"
                  id="coveruploader"
                  accept="image/*"
                  onChange={onImageFileChange}
                />
              </div>
            </div>
            <div className="profileuploaderForProfile">
              {profileImage?.preview !== "" ? (
                <Image
                  preview={false}
                  wrapperClassName="profileImageWrapper"
                  className="ProfileImage"
                  src={profileImage?.preview}
                  fallback={fallbackcover}
                />
              ) : null}
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  borderRadius: "50%"
                }}>
                <label htmlFor="profileuploader">
                  <img src="/images/icons/camerasmall.png" alt="" />
                  {profileImage?.preview === "" ? <span>Add Logo</span> : null}
                </label>
                <input
                  type="file"
                  name="profileImage"
                  className="uploaderInput"
                  id="profileuploader"
                  accept="image/*"
                  onChange={onImageFileChange}
                />
              </div>
            </div>
          </Col>
        </Row>
        <div className="formorganizer">
          <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={onFinish}
            validationSchema={createSponsorProfileValidations}>
            {({ errors, values, setFieldValue, handleSubmit, isValid, dirty }) => (
              <Form
                name="basic"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                autoComplete="off"
                onFinish={handleSubmit}>
                <Row justify="space-between">
                  <Col lg={12} md={12} xs={24} sm={24} className="ceinputformpadding">
                    {console.log(values?.DOB)} {/* FirstName */}
                    <InputTextField
                      name="name"
                      required
                      label="Name"
                      placeholder="Enter your name"
                    />
                    {/* date */}
                    <Form.Item
                      className="profileItems"
                      required
                      label="Date of Birth"
                      help={errors?.DOB ? errors?.DOB : undefined}
                      validateStatus={errors?.DOB ? "error" : "success"}>
                      <DatePicker
                        initialValue={undefined}
                        value={values?.DOB ? moment(values?.DOB) : undefined}
                        // value={values?.DOB}
                        className="playerDob"
                        name="DOB"
                        showToday
                        inputReadOnly
                        onChange={(value) => handleChanges("DOB", value, setFieldValue)}
                      />
                    </Form.Item>
                    {/* location */}
                    <InputTextField
                      name="location"
                      label="Location"
                      placeholder="e.g Islamabad"
                      required
                    />
                  </Col>
                  <Col lg={12} md={12} xs={24} sm={24} className="ceinputformpadding">
                    {/* location */}
                    <InputTextField
                      name="businessType"
                      label="Business Type"
                      placeholder="Business Type"
                    />
                    {/* description */}
                    <InputTextField
                      name="description"
                      label="Description"
                      placeholder="Something about yourself..."
                      type="textArea"
                    />
                  </Col>
                </Row>

                <Row justify="center">
                  <Button
                    htmlType="submit"
                    disabled={!(isValid && dirty)}
                    className="playerCreateBUtton"
                    type="primary">
                    {isEdit ? "Update" : "Create"}
                  </Button>
                </Row>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Fragment>
  );
};

export default CreateSponsorProfile;
