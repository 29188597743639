import React, { useEffect, useState } from "react";
import { Modal, Button } from "antd";
import { getAdminBankInfo } from "../../Config/API/Wallets";
import { error } from "../../common/Alerts/Message";

function AdminAccountInfoModal() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [infoData, setInfoData] = useState(null);
  const [loading, setLoading] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const fetchAdminBankInfo = async () => {
    try {
      setLoading(false);
      const res = await getAdminBankInfo();
      const result = await res.json();
      if (result?.length > 0) {
        setInfoData(result?.[result?.length - 1]);
      }
    } catch (e) {
      error("Error occured while fetching admin info");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAdminBankInfo();
  }, []);

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Show Admin Account Information
      </Button>
      <Modal
        title="Admin Wallet Account Information"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleOk}>
        {loading ? (
          "Loading Account Information..."
        ) : (
          <>
            <p>
              <strong>Account Title:</strong> {infoData?.title}
            </p>
            <p>
              <strong>Bank Name:</strong> {infoData?.bank}
            </p>
            <p>
              <strong>Account Number:</strong> {infoData?.accountNo}
            </p>
            <p>
              <strong>IBAN:</strong> {infoData?.iBan}
            </p>
            <p>
              <strong>Contact Info:</strong> {infoData?.contact}
            </p>
          </>
        )}
      </Modal>
    </>
  );
}

export default AdminAccountInfoModal;
