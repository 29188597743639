// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ceheading {
  text-align: center;
  font-size: 36px;
  font-weight: bold;
  padding: 3%;
  margin-top: 50px;
  word-wrap: break-word;
}
.cetextcount {
  text-align: center;
  margin-top: 10px;
}
.ceinputfield {
  border-radius: 5px;
  line-height: 28px;
  margin-top: 11px;
}
.ceinputfielddesc {
  border-radius: 5px;
  line-height: 28px;
  margin-top: 15px;
}
.ceinputfieldnos {
  line-height: 28px;
  margin-top: 11px;
}
.ceinputformpadding {
  padding: 4% 6.5% 2% 6.5%;
}

.ceinputfieldtag {
  width: 100%;
  line-height: 28px;
  margin-top: 7px;
}
.ceinputformpaddingtwo {
  padding: 2% 5% 1% 5%;
}
.cemaxinput {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ceprimarybutton {
  margin-top: 11px;
  margin-right: 25px;
  border-radius: 5px;
  height: 60px;
  width: 30%;
  margin: 50px 0 50px 0;
  float: right;
  min-width: 150px;
}

.ant-popover-inner {
}
.ant-popover-title {
  border-color: transparent;
  text-align: center;
  position: relative;
  top: 10px;
}
.ant-popover-inner-content {
}
`, "",{"version":3,"sources":["webpack://./src/styles/Event/createevent/index.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,eAAe;EACf,iBAAiB;EACjB,WAAW;EACX,gBAAgB;EAChB,qBAAqB;AACvB;AACA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,gBAAgB;AAClB;AACA;EACE,iBAAiB;EACjB,gBAAgB;AAClB;AACA;EACE,wBAAwB;AAC1B;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,eAAe;AACjB;AACA;EACE,oBAAoB;AACtB;AACA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;AACA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,kBAAkB;EAClB,YAAY;EACZ,UAAU;EACV,qBAAqB;EACrB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;AACA;AACA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,kBAAkB;EAClB,SAAS;AACX;AACA;AACA","sourcesContent":[".ceheading {\n  text-align: center;\n  font-size: 36px;\n  font-weight: bold;\n  padding: 3%;\n  margin-top: 50px;\n  word-wrap: break-word;\n}\n.cetextcount {\n  text-align: center;\n  margin-top: 10px;\n}\n.ceinputfield {\n  border-radius: 5px;\n  line-height: 28px;\n  margin-top: 11px;\n}\n.ceinputfielddesc {\n  border-radius: 5px;\n  line-height: 28px;\n  margin-top: 15px;\n}\n.ceinputfieldnos {\n  line-height: 28px;\n  margin-top: 11px;\n}\n.ceinputformpadding {\n  padding: 4% 6.5% 2% 6.5%;\n}\n\n.ceinputfieldtag {\n  width: 100%;\n  line-height: 28px;\n  margin-top: 7px;\n}\n.ceinputformpaddingtwo {\n  padding: 2% 5% 1% 5%;\n}\n.cemaxinput {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n.ceprimarybutton {\n  margin-top: 11px;\n  margin-right: 25px;\n  border-radius: 5px;\n  height: 60px;\n  width: 30%;\n  margin: 50px 0 50px 0;\n  float: right;\n  min-width: 150px;\n}\n\n.ant-popover-inner {\n}\n.ant-popover-title {\n  border-color: transparent;\n  text-align: center;\n  position: relative;\n  top: 10px;\n}\n.ant-popover-inner-content {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
