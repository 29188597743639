import { error, success } from "../Alerts/Message";
import { PanelDeleteFollowRequest } from "../../Config/API/panel";
import { TeamDeleteFollowRequest } from "../../Config/API/teams";
import { PlayerDeleteFollowRequest } from "../../Config/API/player";
import { OrganizerDeleteFollowRequest } from "../../Config/API/organizer";

import { FollowApi } from "../../Config/API/Follow";

export const followFunction = async (
  id,
  setloading,
  setisFollowing,
  setobject,
  setProfile,
  dispatch,
  setaccpted
) => {
  try {
    const viewer = JSON.parse(localStorage.getItem("viewer"));
    if (!viewer?.id || !viewer?.flag || !id) {
      throw new Error("Refresh page to follow this id");
    }
    if (setloading) {
      setloading("follow");
    }

    const res = await FollowApi({
      followerId: viewer?.id,
      followingId: id,
      flag: viewer?.flag
    });
    const data = await res.json();
    if (data?.type === "success") {
      const d = {
        ...data?.data,
        profileId: data?.data?.profile,
        cover: data?.data?.coverpic,
        profile: data?.data?.profilepic,
        _id: data?.data?._id,
        name: data?.data?.name,
        user: data?.data?.user,
        followers: data?.data?.followers,
        followings: data?.data?.followings,
        description: data?.data?.description
      };
      if (setobject) {
        setobject(d);
      }
      dispatch(setProfile(d));
      await checkIfFollowed(data?.data?.followers, setisFollowing, setaccpted);
      // success(data?.result);
      success("Followed successfully");
    }
    if (data?.type === "failure") {
      error(data?.result);
    }
    if (setloading) {
      setloading(false);
    }
  } catch (e) {
    if (setloading) {
      setloading(false);
    }
    console.log(e);
  }
};
export const unFollowProfile = async (
  item,
  setitem,
  name,
  setloading,
  setisFollowing,
  type,
  setProfile,
  dispatch,
  setaccpted

  //  panel,
  // null,
  // "panel",
  // setloading,
  // setisFollowing,
  // 'otherprofile',
  // setProfile,
  // dispatch,
  // setaccpted
) => {
  try {
    const viewer = JSON.parse(localStorage.getItem("viewer"));
    let id = item?._id;

    if (id && name && viewer?.id) {
      setloading("unfollow");
      const current = viewer?.flag;
      let response;
      if (current === "team") {
        response = await TeamDeleteFollowRequest({
          followerId: viewer?.id,
          followingId: id,
          flag: name,
          type
        });
      } else if (current === "player") {
        response = await PlayerDeleteFollowRequest({
          followerId: viewer?.id,
          followingId: id,
          flag: name,
          type
        });
      } else if (current === "organizer") {
        response = await OrganizerDeleteFollowRequest({
          followerId: viewer?.id,
          followingId: id,
          flag: name,
          type
        });
      } else if (current === "panel") {
        response = await PanelDeleteFollowRequest({
          followerId: viewer?.id,
          followingId: id,
          flag: name,
          type
        });
      }
      const data = await response.json();
      if (data.type === "success") {
        // success(data?.result);
        success("Un followed successfully");
        const d = {
          ...data?.data,
          profileId: data?.data?.profile,
          cover: data?.data?.coverpic,
          profile: data?.data?.profilepic,
          _id: data?.data?._id,
          name: data?.data?.name,
          user: data?.data?.user,
          followers: data?.data?.followers,
          followings: data?.data?.followings,
          description: data?.data?.description
        };
        if (setitem) setitem(d);
        dispatch(setProfile(d));
        if (setisFollowing) setisFollowing(false);
        if (setaccpted) setaccpted(false);
      } else {
        error("request error");
      }

      setloading(false);
    } else {
      throw new Error("Try again later");
    }
  } catch (e) {
    console.log(e);
    error(e.message);
  }
};

export const checkIfFollowed = async (array, setisFollowing, setaccpted) => {
  const viwer = JSON.parse(localStorage.getItem("viewer"));
  try {
    const yourid = viwer?.id;
    if (yourid) {
      for (let i = 0; i < array?.length; i++) {
        //for checking on load
        if (array[i]?.organizer?._id === yourid) {
          setisFollowing(true);
          if (array[i]?.accept) {
            setaccpted(true);
          }
        } else if (array[i]?.player?._id === yourid) {
          setisFollowing(true);
          if (array[i]?.accept) {
            setaccpted(true);
          }
        } else if (array[i]?.team?._id === yourid) {
          setisFollowing(true);
          if (array[i]?.accept) {
            setaccpted(true);
          }
        } else if (array[i]?.panel?._id === yourid) {
          setisFollowing(true);
          if (array[i]?.accept) {
            setaccpted(true);
          }
        } else if (array[i]?.user === yourid) {
          setisFollowing(true);
          if (array[i]?.accept) {
            setaccpted(true);
          }
        }
      }
    }
  } catch (e) {
    console.log(e);
  }
};
