// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.homeSeachBar {
}
.homeSeachBar .ant-select {
  width: 100%;
  border: 1px solid #d5d5d5 !important;
  border-radius: 8px;
}
.homeSeachBar .ant-select .ant-select-selection-overflow {
  min-height: 40px !important;
}
.homeSeachbarDropdown .ant-select-item-option-content img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 0.6rem;
}
.homeSeachbarDropdown .ant-select-item-option-content img + span {
  font-size: 14px;
  font-weight: 500;
}
.homeSeachbarDropdown .ant-select-item-empty {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/Landing/Searchbar.css"],"names":[],"mappings":"AAAA;AACA;AACA;EACE,WAAW;EACX,oCAAoC;EACpC,kBAAkB;AACpB;AACA;EACE,2BAA2B;AAC7B;AACA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,oBAAoB;AACtB;AACA;EACE,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;AAClB","sourcesContent":[".homeSeachBar {\n}\n.homeSeachBar .ant-select {\n  width: 100%;\n  border: 1px solid #d5d5d5 !important;\n  border-radius: 8px;\n}\n.homeSeachBar .ant-select .ant-select-selection-overflow {\n  min-height: 40px !important;\n}\n.homeSeachbarDropdown .ant-select-item-option-content img {\n  width: 35px;\n  height: 35px;\n  border-radius: 50%;\n  margin-right: 0.6rem;\n}\n.homeSeachbarDropdown .ant-select-item-option-content img + span {\n  font-size: 14px;\n  font-weight: 500;\n}\n.homeSeachbarDropdown .ant-select-item-empty {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  min-height: 60px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
