import { KEYS } from "../Keys";

export const UploadProfileImageAPI = async (values) => {
  const response = await fetch(KEYS.API + "/organizer/profileimageupload", {
    method: "POST",
    body: values,
    headers: {
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    }
  });
  return response;
};
export const UploadCoverImageAPI = async (values) => {
  const response = await fetch(KEYS.API + "/organizer/profilecoverimageupload", {
    method: "POST",
    body: values,
    headers: {
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    }
  });
  return response;
};
export const GetProfileAPI = async () => {
  const response = await fetch(KEYS.API + "/organizer/getprofile", {
    // signal: controller.signal,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    }
  });
  return response;
};
export const EditProfileOrgAPI = async (values) => {
  const response = await fetch(KEYS.API + "/organizer/profileupdate", {
    method: "POST",
    headers: {
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: values
  });
  return response;
};
export const EditProfileImageAPI = async (values) => {
  const response = await fetch(KEYS.API + "/organizer/updateprofileimageupload", {
    method: "POST",
    body: values,
    headers: {
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    }
  });
  return response;
};
export const EditCoverImageAPI = async (values) => {
  const response = await fetch(KEYS.API + "/organizer/updateprofilecoverimageupload", {
    method: "POST",
    body: values,
    headers: {
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    }
  });
  return response;
};
export const CreateProfileOrganizerAPI = async (values) => {
  const response = await fetch(KEYS.API + "/organizer/profilecreate", {
    method: "POST",
    headers: {
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: values
  });
  return response;
};

export const GetAllOrganizers = async (offset, limit, search) => {
  const response = await fetch(
    KEYS.API + `/organizer/getallorganizers?skip=${offset}&limit=${limit}&search=${search || ""}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("userToken")}`
      }
    }
  );
  return response;
};

// /organizer/getorganizerposts
export const GetOrganizerPosts = async (values) => {
  const response = await fetch(KEYS.API + "/organizer/getorganizerposts", {
    // signal: controller.signal,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify({ userId: values })
  });
  return response;
};

// Delete Post

export const DeleteOrganizerPost = async (values) => {
  const response = await fetch(KEYS.API + "/organizer/deleteorganizerpost", {
    // signal: controller.signal,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};
export const EditOrganizerPost = async (values) => {
  const response = await fetch(KEYS.API + "/post/updatepost", {
    method: "POST",
    headers: {
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: values
  });
  return response;
};

export const GetSingleOrganizer = async (values) => {
  const response = await fetch(KEYS.API + "/organizer/getsingleorganizer", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: typeof values === "string" ? JSON.stringify({ id: values }) : JSON.stringify(values)
  });
  return response;
};

export const OrganizerAcceptFollowrequest = async (values) => {
  const response = await fetch(KEYS.API + "/organizer/acceptfollowrequest", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

export const OrganizerRejectFollowrequest = async (values) => {
  const response = await fetch(KEYS.API + "/organizer/cancelfollowrequest", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

export const OrganizerDeleteFollowRequest = async (values) => {
  const response = await fetch(KEYS.API + "/organizer/deletefollowrequest", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

export const OrganizerDeleteFollower = async (values) => {
  const response = await fetch(KEYS.API + "/organizer/deletefollowerorganizer", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

export const OrganizerGetFollowersAPI = async () => {
  const response = await fetch(KEYS.API + "/organizer/getfollowers", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    }
  });
  return response;
};

export const GetOrganizerEventHistory = async (id, setData) => {
  try {
    const payload = {
      organizerId: id
    };
    const response = await fetch(KEYS.API + "/organizer/getorganizereventshistory", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("userToken")}`
      },
      body: JSON.stringify(payload)
    });
    const result = await response.json();
    if (result?.type === "success") {
      setData(result?.result || []);
    }
  } catch (error) {
    return await error;
  }
};
