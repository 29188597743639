import React from "react";
import { Col, Row } from "antd";
import ActiveCard from "../Card/ActiveCard";

const ActiveMembers = ({ team, setteam, status, setstatus }) => {
  const arr = team?.members?.filter((e) => e?.active);

  return (
    <Row>
      {arr?.map((e, i) => {
        return e?.player !== null ? (
          <Col
            className="pomemberShadow TPcard"
            key={i}
            xxl={6}
            xl={8}
            lg={{ span: 12 }}
            md={{ span: 12 }}
            sm={24}
            xs={24}
            style={{
              display: "flex",
              alignItems: "stretch"
            }}>
            <ActiveCard
              // object={object}
              team={e}
              memberParent={team?._id}
              setteam={setteam}
              status={status}
              members={arr}
              setstatus={setstatus}
            />
          </Col>
        ) : null;
      })}
    </Row>
  );
};

export default ActiveMembers;
