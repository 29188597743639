import React from "react";
import { Modal, Rate, Input, Button } from "antd";

function RatingModal({
  isVisible,
  handleCancel,
  handleOk,
  rating,
  setRating,
  comment,
  setComment,
  ratingLoading
}) {
  return (
    <>
      <Modal
        title="Rate and Comment"
        open={isVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button disabled={ratingLoading} key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            disabled={ratingLoading}
            loading={ratingLoading}
            key="submit"
            type="primary"
            onClick={handleOk}>
            Submit
          </Button>
        ]}>
        <Rate allowHalf value={rating} onChange={setRating} defaultValue={0} />
        <Input.TextArea
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          placeholder="Leave a comment..."
          rows={4}
          style={{ marginTop: "16px" }}
        />
      </Modal>
    </>
  );
}

export default RatingModal;
