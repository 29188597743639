import React from "react";
import { Route, Routes } from "react-router-dom";
import CurrentEvents from "./CurrentEvents";
import MarkedEvents from "./MarkedEvents";
import PastEvents from "./PastEvents";
import DraftEvents from "./DraftEvents";

const Events = () => {
  return (
    <Routes>
      <Route path="/" element={<CurrentEvents />} />
      <Route path="/pastevents" element={<PastEvents />} />
      <Route path="/markedevents" element={<MarkedEvents />} />
      <Route path="/draftevents" element={<DraftEvents />} />
    </Routes>
  );
};

export default Events;
