import React, { Fragment } from "react";
import { Col, Row, Typography } from "antd";
import PastEventCard from "./PastEventCard";
import EventHistoryAccordian from "./Accordians/EventHistoryAccordian";
import { Link, useLocation, useParams } from "react-router-dom";
import { useEventHistory } from "../hooks/useEventHistory";
import { ROLES } from "../../Config/helpers/constants";
import { useSelector } from "react-redux";
import { PROFILE } from "../../Store/Features/ProfileSlice";
const { Title } = Typography;

export default function EventHistory({ linkTo, current }) {
  const userDetails = useSelector(PROFILE);
  const { id } = useParams();

  const { historyLoading, historyData } = useEventHistory(
    current ?? ROLES.player,
    id ?? userDetails?._id
  );

  return (
    <Fragment>
      <div className="d-md-block d-none mb-20">
        <EventHistoryAccordian
          linkTo={linkTo}
          historyData={historyData}
          historyLoading={historyLoading}
        />
      </div>
      <div className="d-md-none mb-20">
        <EventHistoryDesktop
          linkTo={linkTo}
          historyData={historyData}
          historyLoading={historyLoading}
        />
      </div>
    </Fragment>
  );
}

const EventHistoryDesktop = ({ linkTo, historyData, historyLoading }) => {
  const { pathname } = useLocation();
  return (
    <Fragment>
      <Row>
        <Col span={24}>
          <Typography>
            <Title
              level={4}
              className="toeventhistory"
              style={{ padding: "0 .5% 5% .5%", width: "85%", margin: "auto" }}>
              Events History
            </Title>
          </Typography>
        </Col>
        <Col span={24}>
          {historyLoading
            ? "Loading"
            : historyData?.length > 0
            ? historyData
                ?.slice(0, 4)
                ?.map((data, index) => <PastEventCard key={index} data={data} />)
            : "No Data Found"}
        </Col>
        <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
          <Link
            to={
              linkTo ??
              `${pathname.includes("player") ? "/player/eventhistory" : "/organizer/eventhistory"}`
            }
            style={{
              fontSize: "12px",
              marginTop: "15px",
              color: "#0085FF",
              fontWeight: "600",
              cursor: "pointer"
            }}>
            Show More
          </Link>
        </Col>
      </Row>
    </Fragment>
  );
};
