import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import React from "react";
import { Rate } from "antd";
import ViewRatings from "../../Model/ViewRatings";

const { Panel } = Collapse;

const RatingPanelAccordian = ({ Rating, type, id }) => (
  <Collapse
    bordered={false}
    defaultActiveKey={["Rating"]}
    expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
    className="site-collapse-custom-collapse  sideItem">
    <Panel header="Rating" key="1" className="site-collapse-custom-panel">
      <div className="timelinedesc">
        <Rate disabled allowHalf defaultValue={Rating || 0} value={Rating || 0} />
        {type && id ? <ViewRatings type={type} id={id} /> : null}
      </div>
    </Panel>
  </Collapse>
);

export default RatingPanelAccordian;
