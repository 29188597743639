import { Col, Row } from "antd";
import React, { Fragment } from "react";
import NoData from "../../../common/cards/noData";
import ActiveCard from "./Card/ActiveCard";

const ActiveMembers = ({ data, setdata, current }) => {
  return (
    <Fragment>
      <Row>
        {data?.members?.length >= 1 ? (
          data?.members?.map((e, i) => {
            return e.active === true ? (
              <Col
                key={i}
                xxl={6}
                xl={8}
                lg={{ span: 12 }}
                md={{ span: 12 }}
                sm={24}
                xs={24}
                style={{
                  display: "flex",
                  alignItems: "stretch"
                }}>
                <ActiveCard current={current} data={e} memberParent={data?._id} setteam={setdata} />
              </Col>
            ) : null;
          })
        ) : (
          <Col
            span={24}
            style={{
              display: "flex",
              alignItems: "stretch"
            }}>
            <NoData noMembers />
          </Col>
        )}
      </Row>
    </Fragment>
  );
};

export default ActiveMembers;
