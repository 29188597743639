import React, { useState, useEffect, useRef, useCallback } from "react";
import { Row } from "antd";
import ViewerFeedCard from "../../../common/cards/ViewerFeedCard";
import { useParams } from "react-router-dom";
import Loader from "../../../common/cards/Loader";
import NoData from "../../../common/cards/noData";
import { useFetchOthersPosts } from "../../../common/hooks/useFetchOthersPosts";

const ViewProfileDiscussion = ({ current }) => {
  const { id } = useParams();
  const { posts = [], postloading, setPage, page, totalPages } = useFetchOthersPosts(id, current);

  const [POSTS, setPOSTS] = useState([]);

  const observer = useRef();
  const isThrottled = useRef(false);

  useEffect(() => {
    const fetchPosts = async () => {
      setPOSTS((prevPosts) => [...prevPosts, ...(posts && posts?.length > 0 ? posts : [])]);
    };
    fetchPosts();
  }, [posts]);

  const lastPostElementRef = useCallback(
    (node) => {
      if (postloading || isThrottled.current) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          isThrottled.current = true;
          setTimeout(() => {
            isThrottled.current = false;
          }, 500);
          if (page < totalPages) setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [postloading, page]
  );

  return (
    <Row className="mobileto">
      {postloading ? (
        <Loader />
      ) : POSTS?.length > 0 ? (
        POSTS?.map((e, i) => {
          if (POSTS.length === i + 1) {
            return (
              <ViewerFeedCard
                current={current}
                data={e}
                POSTS={POSTS}
                setPOSTs={setPOSTS}
                key={i}
                index={i}
                ref={lastPostElementRef}
              />
            );
          } else {
            return (
              <ViewerFeedCard
                POSTS={POSTS}
                index={i}
                current={current}
                data={e}
                setPOSTs={setPOSTS}
                key={i}
              />
            );
          }
        })
      ) : (
        <NoData />
      )}
    </Row>
  );
};

export default ViewProfileDiscussion;
