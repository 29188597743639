import { KEYS } from "../Keys";

export const FollowApi = async (values) => {
  const response = await fetch(KEYS.API + "/follow/followrequest", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};
