import React, { Fragment, useState, useEffect } from "react";
import { Card, Col, Skeleton } from "antd";
import { useNavigate } from "react-router-dom";
import { KEYS } from "../../../../Config/Keys";
import moment from "moment";
import { fallbackprofile, loadimage } from "../../../../common/Utils";

const { Meta } = Card;
const RequestCard = ({ data }) => {
  useEffect(() => {
    if (!data) setloading(true);
    else loadimage(KEYS.API + "/" + data?.profilepic, setloading);
    return () => {
      setloading(null);
    };
  }, [data]);

  const [loading, setloading] = useState(true);
  const navigate = useNavigate();
  const name = data?.name || "Sarge Basketball Panel";

  return (
    <Fragment>
      <Col
        className="pomemberShadow TPcard"
        xxl={6}
        xl={8}
        lg={{ span: 12 }}
        md={{ span: 12 }}
        sm={24}
        xs={24}
        onClick={() => {
          if (!loading) {
            localStorage.setItem("panel", data?._id);
            navigate(`/organizer/panel/${data?._id}/timeline`);
          }
        }}>
        <Card>
          <Skeleton className="cardskelton" loading={loading} avatar active>
            <Meta
              style={{
                display: "grid",
                placeContent: "center",
                background: ""
              }}
              avatar={
                <img
                  src={KEYS.API + "/" + data?.profilepic}
                  onErrorCapture={(e) => (e.currentTarget.src = fallbackprofile)}
                  alt=""
                  style={{
                    width: 90,
                    height: 90,
                    objectFit: "cover",
                    marginBottom: "20px",
                    borderRadius: "50%"
                  }}
                />
              }
            />
            <div className="additional">
              <h4 style={{ fontWeight: "550", fontSize: "17px" }}>
                {String(name).length >= 25 ? name.substring(0, 25) + " ..." : name}
              </h4>
              <p className="timelineslotscolor" style={{ margin: "5px auto", fontWeight: "500" }}>
                {data?.location || "dummy location"}
              </p>

              <small className="timelineredtext" style={{ margin: ".4rem auto 0 auto" }}>
                created {moment(data?.createdAt).fromNow()}
              </small>
            </div>
          </Skeleton>
        </Card>
      </Col>
    </Fragment>
  );
};

export default RequestCard;
