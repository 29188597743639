import React, { useState, useEffect, Fragment } from "react";
import { Skeleton, List } from "antd";
import { Getnotifications } from "../../Config/API/notifications";

import {
  get, //getnotifcations
  textChecks, //includes button actions (accept,reject requests)
  checkProfileImage
} from "./function";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { fallbackprofile, getuserlink } from "../Utils";
import FadeInImage from "../Images/FadeInImage";

function NotificationDesktop({ open, setOpen }) {
  const [loading, setloading] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate();

  console.log(notifications);
  useEffect(() => {
    // get()
    get(setloading, Getnotifications, setNotifications);
    return () => {
      setNotifications([]);
      setloading(null);
    };
  }, []);
  return (
    <Fragment>
      <div
        onClick={() => setOpen(false)}
        style={{
          display: open ? "block" : "none",
          width: "100vw",
          height: "100vh",
          position: "fixed",
          left: "0",
          top: "0",
          zIndex: 10
        }}
      />
      <List
        style={{
          padding: "10px 0",
          display: open ? "block" : "none",
          position: "absolute",
          left: "50%",
          top: "40px",
          transform: "translate(-50%,00%)",
          maxHeight: "400px",
          overflowY: "scroll",
          zIndex: 11
        }}
        // loading={{ spinning: loading, size: "large", }}
        loading={loading}
        className="NotificationList"
        itemLayout={"horizontal"}
        dataSource={notifications || []}
        renderItem={(item) => (
          <List.Item
            className="ListItem"
            style={{
              alignItems: "flex-start",
              flexDirection: "column",
              gap: "5px",
              padding: "10px 15px"
            }}>
            <Skeleton avatar title={false} loading={loading}>
              <List.Item.Meta
                style={{ width: "100%", alignItems: "baseline" }}
                avatar={
                  <div
                    onClick={() =>
                      navigate(getuserlink(item)?.url, { state: getuserlink(item)?.name })
                    }
                    style={{
                      position: "relative",
                      top: "20px",
                      cursor: "pointer"
                    }}>
                    <FadeInImage
                      type={"Fadenavprofile"}
                      radiusAll
                      fallaback={fallbackprofile}
                      src={checkProfileImage(item)}
                    />
                  </div>
                }
                title={textChecks(item, navigate, setloading, setNotifications)}
              />

              <div style={{ marginLeft: "50px", color: "#A5ACB8" }}>
                <small>{moment(item?.createdAt).fromNow()}</small>
              </div>
            </Skeleton>
          </List.Item>
        )}
      />
    </Fragment>
  );
}

export default NotificationDesktop;
