import React, { Fragment } from "react";
import Media from "../../../common/Media";

function MediaContainer({ current }) {
  return (
    <Fragment>
      <Media current={current} />
    </Fragment>
  );
}

export default MediaContainer;
