import React from "react";
import FilterBar from "./FilterBar";
import FilterBarAccordian from "./FilterBarAccrodian";

function INDEX({ active, setactive }) {
  return (
    <div style={{ height: "100%" }}>
      <div className="d-md-block d-none">
        <FilterBarAccordian active={active} setactive={setactive} />
      </div>
      <div style={{ height: "100%" }} className="d-md-none">
        <FilterBar active={active} setactive={setactive} />
      </div>
    </div>
  );
}

export default INDEX;
