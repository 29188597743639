// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./groupcard.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.schedulemodal {
  min-width: 80vw !important;
  margin-block-end: 2rem;
}

.schedulemodal .ant-modal-content {
  width: 100% !important;
}

.schedulemodal .ant-modal-body {
  margin: 0px;
  max-width: 100%;
  display: block !important;
}

.mulitiplayerbutton {
  border-radius: 10px;
  padding: 0.5rem 1rem;
  height: unset;
}

.editbutton {
  margin: 20px auto 0 auto;
  /* max-width: 200px; */
}

.editbutton .ant-btn {
  height: max-content;
  border-radius: 8px;
}

.settingsicon {
  cursor: pointer;
  font-size: 1.5rem;
  margin-right: 0.8rem;
}

.tabletitlerow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.groupediticon {
  font-size: 1.2rem;
  cursor: pointer;
}

.groupediticon:active,
.settingsicon:active {
  transition: all 0s ease-in-out;
  transform: scale(1.05);
}

.killsinput {
  max-width: 50px;
  border: 1px solid #443a3a;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/styles/Event/mulitplayer/index.css"],"names":[],"mappings":"AAEA;EACE,0BAA0B;EAC1B,sBAAsB;AACxB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,mBAAmB;EACnB,oBAAoB;EACpB,aAAa;AACf;;AAEA;EACE,wBAAwB;EACxB,sBAAsB;AACxB;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,iBAAiB;EACjB,eAAe;AACjB;;AAEA;;EAEE,8BAA8B;EAC9B,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,yBAAyB;EACzB,kBAAkB;AACpB","sourcesContent":["@import \"./groupcard.css\";\n\n.schedulemodal {\n  min-width: 80vw !important;\n  margin-block-end: 2rem;\n}\n\n.schedulemodal .ant-modal-content {\n  width: 100% !important;\n}\n\n.schedulemodal .ant-modal-body {\n  margin: 0px;\n  max-width: 100%;\n  display: block !important;\n}\n\n.mulitiplayerbutton {\n  border-radius: 10px;\n  padding: 0.5rem 1rem;\n  height: unset;\n}\n\n.editbutton {\n  margin: 20px auto 0 auto;\n  /* max-width: 200px; */\n}\n\n.editbutton .ant-btn {\n  height: max-content;\n  border-radius: 8px;\n}\n\n.settingsicon {\n  cursor: pointer;\n  font-size: 1.5rem;\n  margin-right: 0.8rem;\n}\n\n.tabletitlerow {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.groupediticon {\n  font-size: 1.2rem;\n  cursor: pointer;\n}\n\n.groupediticon:active,\n.settingsicon:active {\n  transition: all 0s ease-in-out;\n  transform: scale(1.05);\n}\n\n.killsinput {\n  max-width: 50px;\n  border: 1px solid #443a3a;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
