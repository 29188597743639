// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cpheading {
  text-align: center;
  font: 36px;
  font-weight: bold;
  padding: 3%;
}
.cpcreatebutton {
  margin-top: 11px;
  border-radius: 5px;
  height: 60px;
  width: 30%;
  margin: 50px 0 50px 0;
}
`, "",{"version":3,"sources":["webpack://./src/styles/organizer/createpanel.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,UAAU;EACV,iBAAiB;EACjB,WAAW;AACb;AACA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,YAAY;EACZ,UAAU;EACV,qBAAqB;AACvB","sourcesContent":[".cpheading {\n  text-align: center;\n  font: 36px;\n  font-weight: bold;\n  padding: 3%;\n}\n.cpcreatebutton {\n  margin-top: 11px;\n  border-radius: 5px;\n  height: 60px;\n  width: 30%;\n  margin: 50px 0 50px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
