import React from "react";
import { Route, Routes } from "react-router-dom";
import OrganizerLayout from "../layout/organizerLayout/OrganizerLayout";
import CreateOrganizerProfile from "./CreateOrganizerProfile/CreateOrganizerProfile";
import CreatePanelOrganizer from "./CreatePanelOrganizer/CreatePanelOrganizer";
import Panel from "./Panel/Panel";

import EventHistory from "./EventHistory";
import AddPanelMembers from "./Panel/AddPanelMembers/AddPanelMembers";
import NotificationMobile from "../../common/Notification/NotificatinonMobile";
import NotificationMobileTP from "../../common/Notification/TeamPanelNotifications/NotificatinonMobile";

import ChatRoom from "../ChatRoom";
import PageNotFound from "../../common/cards/PageNotFound/PageNotFound";

import OrganizerTLayout from "../layout/organizerTLLayout/OrganizerTLayout";
import About from "./About/About";
import Media from "../../common/Media";
import Home from "./Home";
import Timeline from "./Timeline";
import Follow from "../../common/Follow";
import MyPanels from "./Panel/MyPanels/MyPanels";
// import EditProfile from "./EditProfile/EditProfile"; organizer and panel edit, not used anymore

import Settings from "../../common/Settings";
import SearchPage from "../SearchPage";

import Schedule from "./Panel/Event/Components/EventScheduling";
import Matches from "./Panel/Event/Components/League";
import KOBrackets from "./Panel/Event/Components/KO";
import EventHome from "./Panel/Event/Components/Home";
import History from "./Panel/Event/Components/History";
import Multiplayermatches from "./Panel/Event/Components/multiplayermatches";

import AuthOrganizer from "./AuthOrganizer";
import EventAuth from "./EventAuth";

import CreateEvent from "./Event/CreateEvent";
// import EditEvent from "./Event/EditEvent";
import Events from "./Events";
import UserWallet from "../Wallet/UserWallet";
import { ROLES } from "../../Config/helpers/constants";
import useVariables from "../../common/hooks/useVariables";
import EventAwards from "../ViewEvents/Components/EventAssignedAwards/EventAwards";

const Index = () => {
  const { PID } = useVariables();

  return (
    <Routes>
      <Route element={<OrganizerLayout />}>
        <Route index path="/" element={<CreateOrganizerProfile />} />
      </Route>
      <Route path="/" element={<AuthOrganizer />}>
        <Route path="/" element={<OrganizerLayout />}>
          <Route path="/createpanel" element={<CreatePanelOrganizer />} />
          <Route path="/editpanel/:id" element={<CreatePanelOrganizer />} />
          <Route path="/editprofile/:id" element={<CreateOrganizerProfile />} />
          <Route path="/eventhistory" element={<EventHistory />} />
          <Route path="panel/:id/*" element={<Panel />} />
          <Route path="/event/:id" element={<EventHome />} />

          <Route path="/" element={<EventAuth />}>
            <Route path="/event/:id/history" element={<History />} />
            <Route path="/event/:id/schedule" element={<Schedule />} />
            <Route path="/event/:id/matches" element={<Matches />} />
            <Route path="/event/:id/multiplayermatches" element={<Multiplayermatches />} />
            <Route path="/event/:id/knock-out-matches" element={<KOBrackets />} />
            <Route path="/event/:id/event-awards" element={<EventAwards />} />
          </Route>

          <Route path="panel/addmembers" element={<AddPanelMembers />} />
          <Route path="notifications" element={<NotificationMobile />} />
          <Route path="/panel/:id/notifications" element={<NotificationMobileTP />} />
          <Route path="/messages/chat" element={<ChatRoom />} />
          <Route path="settings" element={<Settings />} />
          <Route path="/panel/:id/settings" element={<Settings />} />
          <Route path="/search/:query" element={<SearchPage />} />
          <Route path="/feed" element={<Home />} />
          <Route path="/" element={<OrganizerTLayout />}>
            <Route path="/panels" element={<MyPanels />} />
            <Route index path="/timeline" element={<Timeline />} />
            <Route path="about" element={<About />} />
            <Route path="media" element={<Media />} />
            <Route path="/followers" element={<Follow />} />
            <Route path="/followings" element={<Follow />} />
            <Route path="/events/*" element={<Events />} />
            <Route path="wallet" element={<UserWallet userType={ROLES.organizer} userId={PID} />} />
          </Route>

          <Route path="/createevent" element={<CreateEvent />} />
          <Route path="/editevent/:eventID" element={<CreateEvent />} />
        </Route>
      </Route>

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default Index;
