import React, { Fragment } from "react";
import { Col, Row } from "antd";
import DiscussionTeam from "./DiscussionTeam";
import RightBar from "../../../../common/righbar";
import { SideBar } from "../../../../common/sidebar";
import ScrollToTop from "../../../../common/cards/ScrollToTop";
const Home = () => {
  return (
    <Fragment>
      <ScrollToTop />
      <Row justify="space-between" className="rowPadding">
        <SideBar description ratingPanel location />
        <Col xs={24} sm={24} md={24} lg={11}>
          <DiscussionTeam />
        </Col>
        <RightBar />
      </Row>
    </Fragment>
  );
};

export default Home;
