import React from "react";
import { Fragment } from "react";
import { error, success } from "../Alerts/Message";
import Swal from "sweetalert2";
import { Button } from "antd";
import { KEYS } from "../../Config/Keys";

import { AcceptPanelRequest, CancelOrDeletePanelRequest } from "../../Config/API/panel";
import { AccepTeamRequest, CancelOrDeleteTeamRequest } from "../../Config/API/teams";
import { PlayerAcceptFollowrequest, PlayerRejectFollowrequest } from "../../Config/API/player";
import {
  OrganizerAcceptFollowrequest,
  OrganizerRejectFollowrequest
} from "../../Config/API/organizer";
import { Link } from "react-router-dom";
import { getuserlink } from "../Utils";

export const get = async (setloading, Getnotifications, setNotifications) => {
  try {
    const id = JSON.parse(localStorage.getItem("viewer"))?.id;
    console.log(id);
    if (id === null || id === undefined || id === "") {
      setloading(false);
      return null;
    }
    setloading(true);
    let role = JSON.parse(localStorage.getItem("userDetails"))?.role;
    role = String(role).toLowerCase();
    const viewer = {
      id: id,
      flag: role
    };

    const response = await Getnotifications({
      id: viewer?.id,
      type: viewer?.flag
    });
    const data = await response.json();

    setNotifications([...data.data]);
    setloading(false);
  } catch (e) {
    console.log(e);
    setloading(false);
    error(e.message);
  }
};

export const checkProfileImage = (item) => {
  if (Object.prototype.hasOwnProperty.call(item, "organizerSent"))
    return KEYS.API + "/" + item?.organizerSent?.profilepic;
  else if (Object.prototype.hasOwnProperty.call(item, "playerSent"))
    return KEYS.API + "/" + item?.playerSent?.profilepic;
  else if (Object.prototype.hasOwnProperty.call(item, "panelSent"))
    return KEYS.API + "/" + item?.panelSent?.profilepic;
  else if (Object.prototype.hasOwnProperty.call(item, "teamSent"))
    return KEYS.API + "/" + item?.teamSent?.profilepic;
  else return KEYS.API + "/" + item?.user?.profilepic;
};

export const textChecks = (item, navigate, setloading, setNotifications) => (
  <Fragment>
    {/* Team-Panel Invitation */}
    <Fragment>
      {item?.type === "RequestPanel" || item?.type === "RequestTeam" ? (
        <Fragment>
          <Link to={getuserlink(item)?.url} state={getuserlink(item)?.name}>
            <b>{item?.organizerSent?.name || item?.playerSent?.name}</b>
          </Link>
          {item?.organizerReceive
            ? " sent panel Invitation request to "
            : " sent Team Invitation request  to "}
          <b> {" you"} </b>

          <div
            style={{
              display: "flex",
              gap: "5px"
            }}>
            <Button
              className="addtocalendar"
              onClick={() => accept(item, setloading, setNotifications)}
              key="Accept"
              style={{
                maxWidth: 80,
                minWidth: "40px",
                height: "30px",
                color: "white",
                display: "grid",
                placeContent: "center",
                margin: "5px 0 0 0 "
              }}>
              Accept
            </Button>
            <Button
              onClick={() => cancelRequest(item, setloading, setNotifications)}
              className="addtocalendar"
              key="Reject"
              style={{
                minWidth: "40px",
                maxWidth: 80,
                height: "30px",
                display: "grid",
                placeContent: "center",
                background: "#FFFFFF",
                border: "1px solid #DDDEE1",
                margin: "5px 0 0 0 "
              }}>
              Reject
            </Button>
          </div>
        </Fragment>
      ) : null}
    </Fragment>

    {/* Team-Panel Joining Request */}
    <Fragment>
      {item?.type === "JoinPanel" || item?.type === "JoinTeam" ? (
        <Fragment>
          <Link to={getuserlink(item)?.url} state={getuserlink(item)?.name}>
            <b>{item?.organizerSent?.name || item?.playerSent?.name}</b>
          </Link>
          {item?.organizerReceive
            ? " sent panel Joining request  to "
            : " sent Team Joining request to "}
          <b> {" you"} </b>

          <div
            style={{
              display: "flex",
              gap: "5px"
            }}>
            <Button
              className="addtocalendar"
              onClick={() => acceptJoin(item, setloading, setNotifications)}
              key="Accept"
              style={{
                maxWidth: 80,
                minWidth: "40px",
                height: "30px",
                color: "white",
                display: "grid",
                placeContent: "center",
                margin: "5px 0 0 0 "
              }}>
              Accept
            </Button>
            <Button
              onClick={() => cancelJoin(item, setloading, setNotifications)}
              className="addtocalendar"
              key="Reject"
              style={{
                minWidth: "40px",
                maxWidth: 80,
                height: "30px",
                display: "grid",
                placeContent: "center",
                background: "#FFFFFF",
                border: "1px solid #DDDEE1",
                margin: "5px 0 0 0 "
              }}>
              Reject
            </Button>
          </div>
        </Fragment>
      ) : null}
    </Fragment>

    {/* Follow Profiles */}
    <Fragment>
      {item?.type === "RequestFollow" ? (
        <Fragment>
          <Link to={getuserlink(item)?.url} state={getuserlink(item)?.name}>
            {item?.teamSent?.name ||
              item?.panelSent?.name ||
              item?.playerSent?.name ||
              item?.organizerSent?.name}
          </Link>
          {" Requested to Follow"}
          <b> {" you"} </b>

          <div
            style={{
              display: "flex",
              gap: "5px"
            }}>
            <Button
              className="addtocalendar"
              onClick={() => acceptFollow(item, setloading, setNotifications)}
              key="Accept"
              style={{
                maxWidth: 80,
                minWidth: "40px",
                height: "30px",
                color: "white",
                display: "grid",
                placeContent: "center",
                margin: "5px 0 0 0 "
              }}>
              Accept
            </Button>
            <Button
              onClick={() => rejectFollow(item, setloading, setNotifications)}
              className="addtocalendar"
              key="Reject"
              style={{
                minWidth: "40px",
                maxWidth: 80,
                height: "30px",
                display: "grid",
                placeContent: "center",
                background: "#FFFFFF",
                border: "1px solid #DDDEE1",
                margin: "5px 0 0 0 "
              }}>
              Reject
            </Button>
          </div>
        </Fragment>
      ) : null}
    </Fragment>
    {/* Request team-captain to join event */}
    <Fragment>
      {item?.type === "RequestCaptain" ? (
        <Fragment>
          {/* <Link to={getuserlink(item)?.url} state={getuserlink(item)?.name}>
                            {item?.playerSent?.name + " "}
                        </Link> */}
          {item?.playerSent && item?.text}
          <Button
            className="addtocalendar"
            onClick={() => navigate(`/view/event/${item?.data?.eventId}`)}
            key="Accept"
            style={{
              maxWidth: 80,
              minWidth: "40px",
              height: "30px",
              color: "white",
              display: "grid",
              placeContent: "center",
              margin: "5px 0 0 0 "
            }}>
            Join Event
          </Button>
        </Fragment>
      ) : null}
    </Fragment>
    {/*  Other user action/response to your request */}
    <Fragment>
      {item?.type === "ActionPanel" ||
      item?.type === "ActionTeam" ||
      item?.type === "ActionFollow" ? (
        <Fragment>
          {/* {" " + item?.text + " by "}
                            <b> {
                                item?.organizerSent?.name ||
                                item?.playerSent?.name
                            }</b> */}
          {" " + item?.text}
        </Fragment>
      ) : null}
    </Fragment>

    {/*  Other user action/response to your request */}
    <Fragment>
      {item?.type === "teamAction" || item?.type === "organizerAction" ? (
        <Fragment>
          <Link to={"/view/event/" + item?.data?._id + "/multiplayermatches"}>
            {" " + item?.text}
          </Link>
        </Fragment>
      ) : null}
    </Fragment>

    <Fragment>
      {item?.type === "panelAction" ? (
        <Fragment>
          <Link to={"/view/panel/" + item?.panelReceive?._id + "/timeline"}>
            {" " + item?.text}
          </Link>
        </Fragment>
      ) : null}
    </Fragment>

    <Fragment>
      {item?.type === "eventAction" ? (
        <Fragment>
          <Link to={"/view/event/" + item?.data?._id + "/timeline"}>{" " + item?.text}</Link>
        </Fragment>
      ) : null}
    </Fragment>
  </Fragment>
);

// Profiles Follow request, accept-reject
const acceptFollow = async (item, setloading, setNotifications) => {
  try {
    const pid = JSON.parse(localStorage.getItem("viewer"))?.id;

    if (
      item?.data?.panelid ||
      item?.data?.organizerid ||
      item?.data?.teamid ||
      item?.data?.playerid
    ) {
      setloading(true);
      let flag;
      if (item?.organizerSent) flag = "organizer";
      else if (item?.playerSent) flag = "player";
      else if (item?.teamSent) flag = "team";
      else if (item?.panelSent) flag = "panel";
      if (item && flag) {
        let role = JSON.parse(localStorage.getItem("userDetails"))?.role;
        role = String(role).toLowerCase();
        const viewer = {
          id: pid,
          flag: role
        };
        let response = null;
        let id =
          item?.data?.playerid ||
          item?.data?.organizerid ||
          item?.data.panelid ||
          item?.data?.teamid;

        if (item?.playerReceive) {
          console.log("here player call");
          response = await PlayerAcceptFollowrequest({
            followerId: id,
            followingId: viewer?.id,
            notId: item?._id,
            flag: flag
          });
        } else if (item?.organizerReceive) {
          console.log("here organixer call");
          response = await OrganizerAcceptFollowrequest({
            followerId: id,
            followingId: viewer?.id,
            notId: item?._id,
            flag: flag
          });
        }
        const data = await response.json();
        console.log("accept api : ", data);
        if (data.type === "success") {
          success(data?.result);
          setNotifications([...data.data]);
        } else {
          error("request error");
        }
      }
      setloading(false);
    } else {
      throw new Error("Try again later");
    }
  } catch (e) {
    console.log(e);
    error(e.message);
    setloading(false);
  }
};
const rejectFollow = async (item, setloading, setNotifications) => {
  const viewer = JSON.parse(localStorage.getItem("viewer"));

  try {
    if (
      item?.data?.panelid ||
      item?.data?.organizerid ||
      item?.data?.teamid ||
      item?.data?.playerid
    ) {
      setloading(true);
      let flag;
      if (item?.organizerSent) flag = "organizer";
      else if (item?.playerSent) flag = "player";
      else if (item?.teamSent) flag = "team";
      else if (item?.panelSent) flag = "panel";
      if (item && flag) {
        let response;
        let id =
          item?.data?.playerid ||
          item?.data?.organizerid ||
          item?.data.panelid ||
          item?.data?.teamid;
        if (item?.playerReceive) {
          console.log("here player call");
          response = await PlayerRejectFollowrequest({
            followerId: id,
            followingId: viewer?.id,
            notId: item?._id,
            flag: flag
          });
        } else if (item?.organizerReceive) {
          console.log("here organizer call");
          response = await OrganizerRejectFollowrequest({
            followerId: id,
            followingId: viewer?.id,
            notId: item?._id,
            flag: flag
          });
        }
        const data = await response.json();
        console.log("reaject api : ", data);
        if (data.type === "success") {
          success(data?.result);
          setNotifications([...data.data]);
        } else {
          error("request error");
        }
      }
      setloading(false);
    } else {
      throw new Error("Try again later");
    }
  } catch (e) {
    console.log(e);
    error(e.message);
    setloading(false);
  }
};

// accept joining  request
const acceptJoin = async (item, setloading, setNotifications) => {
  try {
    if (
      (!item?.data?.panelid || !item?.organizerReceive?._id) &&
      (!item?.data?.teamid || !item?.playerReceive?._id)
    ) {
      throw new Error("Try again later");
    }
    setloading(true);
    if (item) {
      let response;
      if (item?.organizerReceive?._id)
        response = await AcceptPanelRequest({
          panelId: item?.data?.panelid,
          id: item?.organizerSent?._id,
          senderId: item?.organizerReceive?._id,
          notId: item?._id,
          type: "Add"
        });
      else if (item?.playerReceive?._id) {
        console.log("here");
        response = await AccepTeamRequest({
          teamId: item?.data.teamid,
          id: item?.playerSent?._id,
          senderId: item?.playerReceive?._id,
          notId: item?._id,
          type: "Add"
        });
      }
      const data = await response.json();
      console.log("accept api : ", data);
      if (data.type === "success") {
        if (item?.data?.panelid) success("Panel joined!");
        else if (item?.data.teamid) success("Team Joined");
        setNotifications([...data.data]);
      } else {
        error("request error");
      }
    }
    setloading(false);
  } catch (e) {
    console.log(e);
    setloading(false);
    error(e.message);
  }
};
// cancel joining request
const cancelJoin = async (item, setloading, setNotifications) => {
  try {
    if (
      (item?.data?.panelid && item?.organizerReceive?._id) ||
      (item?.data?.teamid && item?.playerReceive?._id)
    ) {
      Swal.fire({
        icon: "warning",
        text: "Are you sure you want to Reject Joining Request?",
        showCancelButton: true,
        cancelButtonColor: "var(--red)",
        confirmButtonText: "Reject Request"
      }).then(async (e) => {
        if (e.isConfirmed) {
          try {
            setloading(true);
            let response;
            if (item?.organizerReceive?._id)
              response = await CancelOrDeletePanelRequest({
                id: item.organizerSent._id,
                senderId: item?.organizerReceive._id,
                panelId: item.data.panelid,
                flag: "request",
                notId: item._id
              });
            else if (item?.playerReceive?._id) {
              console.log(
                item?.playerReceive?._id,
                item?.playerSent?._id,
                item?.data?.teamid,
                item?._id
              );
              if (
                item?.playerReceive?._id &&
                item?.playerSent?._id &&
                item?.data?.teamid &&
                item?._id
              )
                response = await CancelOrDeleteTeamRequest({
                  id: item?.playerSent._id,
                  senderId: item?.playerReceive._id,
                  teamId: item?.data.teamid,
                  flag: "request",
                  notId: item?._id
                });
              else {
                setloading(false);
                return error("one of the fields is missing try again later");
              }
            }
            const dataa = await response.json();
            console.log(dataa);
            if (dataa.type === "success") {
              success("Join Request Rejected!");
              setNotifications([...dataa.data]);
            } else {
              error(dataa?.result);
            }
            setloading(false);
          } catch (e) {
            setloading(false);
            console.log(e);
            error("An error occured");
          }
        }
      });
    } else {
      error("no Member id found! try again later");
    }
  } catch (e) {
    error(e?.message);
    setloading(false);
  }
};

// accept invitaion request
const accept = async (item, setloading, setNotifications) => {
  try {
    if (
      (!item?.data?.panelid || !item?.organizerReceive?._id) &&
      (!item?.data?.teamid || !item?.playerReceive?._id)
    ) {
      throw new Error("Try again later");
    }
    setloading(true);
    if (item) {
      let response;
      if (item?.organizerReceive?._id)
        response = await AcceptPanelRequest({
          panelId: item?.data?.panelid,
          id: item?.organizerReceive?._id,
          senderId: item?.organizerSent?._id,
          notId: item?._id,
          type: "Add"
        });
      else if (item?.playerReceive?._id) {
        console.log("here");
        response = await AccepTeamRequest({
          teamId: item?.data.teamid,
          id: item?.playerReceive?._id,
          senderId: item?.playerSent?._id,
          notId: item?._id,
          type: "Add"
        });
      }
      const data = await response.json();
      console.log("accept api : ", data);
      if (data.type === "success") {
        if (item?.data?.panelid) success("Panel joined!");
        else if (item?.data.teamid) success("Team Joined");
        setNotifications([...data.data]);
      } else {
        error("request error");
      }
    }
    setloading(false);
  } catch (e) {
    console.log(e);
    setloading(false);
    error(e.message);
  }
};
// cancel invitaion request
const cancelRequest = async (item, setloading, setNotifications) => {
  try {
    if (
      (item?.data?.panelid && item?.organizerReceive?._id) ||
      (item?.data?.teamid && item?.playerReceive?._id)
    ) {
      Swal.fire({
        icon: "warning",
        text: "Are you sure you want to Reject Joining Invitation?",
        showCancelButton: true,
        cancelButtonColor: "var(--red)",
        confirmButtonText: "Reject Request"
      }).then(async (e) => {
        if (e.isConfirmed) {
          try {
            setloading(true);
            let response;
            if (item?.organizerReceive?._id)
              response = await CancelOrDeletePanelRequest({
                id: item?.organizerReceive._id,
                senderId: item.organizerSent._id,
                panelId: item.data.panelid,
                flag: "request",
                notId: item._id
              });
            else if (item?.playerReceive?._id) {
              console.log(
                item?.playerReceive?._id,
                item?.playerSent?._id,
                item?.data?.teamid,
                item?._id
              );
              if (
                item?.playerReceive?._id &&
                item?.playerSent?._id &&
                item?.data?.teamid &&
                item?._id
              )
                response = await CancelOrDeleteTeamRequest({
                  id: item?.playerReceive._id,
                  senderId: item.playerSent._id,
                  teamId: item?.data.teamid,
                  flag: "request",
                  notId: item?._id
                });
              else {
                setloading(false);
                return error("one of the fields is missing try again later");
              }
            }
            const dataa = await response.json();
            console.log(dataa);
            if (dataa.type === "success") {
              success("Join Request Rejected!");
              setNotifications([...dataa.data]);
            } else {
              error(dataa?.result);
            }
            setloading(false);
          } catch (e) {
            setloading(false);
            console.log(e);
            error("An error occured");
          }
        }
      });
    } else {
      error("no Member id found! try again later");
    }
  } catch (e) {
    error(e?.message);
    setloading(false);
  }
};
