import React from "react";
import CommingSoon from "../../../../common/cards/ComingSoon/CommingSoon";
function index() {
  return (
    <div>
      <CommingSoon />
    </div>
  );
}

export default index;
