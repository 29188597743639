function useVariables() {
  const PID = localStorage.getItem("ProfileID");
  const panelid = localStorage.getItem("panel");
  const teamid = localStorage.getItem("team");
  const user = JSON.parse(localStorage.getItem("userDetails"));
  const USERID = user?.id;
  const FLAG = String(user?.role).toLowerCase();
  const viewer = JSON.parse(localStorage.getItem("viewer"));
  const token = localStorage.getItem("userToken");

  return { FLAG, PID, USERID, viewer, panelid, teamid, token };
}
export default useVariables;
