import React, { Fragment } from "react";
import { Col, Row } from "antd";
import DiscussionTeam from "./DiscussionTeam";
import RightBar from "../../../../common/righbar";
import ScrollToTop from "../../../../common/cards/ScrollToTop";
import { PROFILE } from "../../../../Store/Features/ProfileSlice";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ROLES } from "../../../../Config/helpers/constants";
const TimeLine = () => {
  const { pathname } = useLocation();
  const userDetails = useSelector(PROFILE);
  return (
    <Fragment>
      <ScrollToTop />
      <Row justify="center" className="rowPadding">
        <Col
          xs={{ span: 24, order: 3 }}
          sm={{ span: 24, order: 3 }}
          md={{ span: 24, order: 3 }}
          lg={{ span: 14, order: 2 }}>
          {userDetails?._id ? <DiscussionTeam /> : null}
        </Col>
        {pathname.includes("team") ? (
          <RightBar
            current={ROLES.team}
            noExploreEvents
            eventHistoryLInk={`/view/team/${userDetails?._id}/eventhistory`}
          />
        ) : (
          <RightBar />
        )}
      </Row>
    </Fragment>
  );
};

export default TimeLine;
