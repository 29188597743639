import { Avatar, List } from "antd";
import React from "react";
import { error } from "../../common/Alerts/Message";
import { KEYS } from "../../Config/Keys";
import { BiMoney } from "react-icons/bi";

function Beneficiary({ beneficiaries, setSelectedUser }) {
  return (
    <div>
      <h3></h3>
      <List
        dataSource={beneficiaries}
        renderItem={(beneficiary) => (
          <List.Item
            onClick={
              beneficiary?.walletId?._id
                ? () =>
                    setSelectedUser({
                      name: beneficiary?.walletId?.ownerId?.name,
                      profilepic: "",
                      user: beneficiary?.walletId?.ownerId?._id,
                      walletId: beneficiary?.walletId?._id,
                      isBeneficiary: true
                    })
                : () => error("Error selecting this user")
            }
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between"
            }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              {/* Avatar at the start */}
              <Avatar
                src={beneficiary?.profilepic ? KEYS.API + `/${beneficiary?.profilepic}` : null}
              />
              {/* User name */}
              <span style={{ marginLeft: "1rem" }}>{beneficiary?.walletId?.ownerId?.name}</span>
            </div>
            {/* Icon at the end */}
            {beneficiary?.walletId?._id && <BiMoney />}
          </List.Item>
        )}
        style={{ marginBottom: "1rem", maxWidth: "400px", overflowX: "auto" }} // Set max width and enable horizontal scroll
      />
    </div>
  );
}

export default Beneficiary;
