import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Drawer, Collapse, Form, Input } from "antd";
import { FaSearch } from "react-icons/fa";
import React, { useState } from "react";
import { KEYS } from "../../../Config/Keys";
import { fallbackprofile } from "../../../common/Utils";
import FadeInImage from "../../../common/Images/FadeInImage";
import useVariables from "../../../common/hooks/useVariables";
import { Info } from "../../../common/Alerts/Message";
import { useSelector } from "react-redux";

const PlayerDrawer = ({ onClose, visible }) => {
  const { query, id } = useParams();
  const [text, settext] = useState(query);
  const navigate = useNavigate();
  const flexTokens = useSelector((state) => state.wallet.flexTokens);

  const { viewer } = useVariables();
  const { pathname } = useLocation();

  var currentflag = "player",
    currentid = viewer?.id;

  const { Panel } = Collapse;
  const notClickable = pathname === "/player";
  const ALERT = () => Info("Create Profile First");
  return (
    <Drawer
      bodyStyle={{ marginLeft: "5px" }}
      title="Spoflex"
      placement="right"
      onClose={onClose}
      visible={visible}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <FadeInImage
          type="Fadenavprofile"
          fallaback={fallbackprofile}
          src={KEYS.API + "/" + viewer?.profile}
        />
        <span style={{ paddingLeft: "20px", userSelect: "none", fontSize: "15", fontWeight: 600 }}>
          {viewer?.name}
        </span>
      </div>
      <Form
        style={{ margin: "20px 0 0 0" }}
        onFinish={() => {
          if (text?.trim().length > 0 && !notClickable) {
            onClose();
            navigate(`/player/search/${text}`, { state: text });
          } else if (notClickable) ALERT();
        }}>
        <Form.Item style={{ margin: "0px", width: "100%" }} name="search">
          <Input
            size="middle"
            value={text}
            defaultValue={query}
            onChange={(e) => settext(e.currentTarget.value)}
            placeholder="Search"
            style={{ borderRadius: "20px", padding: "8px 5px" }}
            prefix={
              <FaSearch
                onClick={() => {
                  if (text?.trim().length > 0 && !notClickable) {
                    onClose();
                    navigate(`/player/search/${text}`, { state: text });
                  } else if (notClickable) ALERT();
                }}
                style={{
                  cursor: "pointer",
                  color: "#8F8F8F",
                  marginRight: "10px",
                  width: "25px",
                  height: "16px"
                }}
              />
            }
          />
        </Form.Item>
      </Form>
      <div className="drawerpadding" onClick={onClose}>
        {notClickable ? <div onClick={ALERT}>Home</div> : <Link to="/player/feed">Home</Link>}
      </div>
      <div className="drawerpadding" onClick={onClose}>
        {notClickable ? (
          <div onClick={ALERT}>Profile</div>
        ) : (
          <Link to="/player/timeline">Profile</Link>
        )}
      </div>
      <div style={{ paddingTop: "5px" }}>
        {notClickable ? (
          <div className="Nav_a" style={{ marginTop: "-6px" }} onClick={ALERT}>
            Events
          </div>
        ) : (
          <Collapse className="customz" style={{ padding: "0px" }} ghost>
            <Panel className="custom" header="Events" key="1">
              <div onClick={onClose}>
                <Link className="drawerpadding" to="/player/events/pastevents">
                  Past Events
                </Link>
              </div>
              <div className="drawerpadding" onClick={onClose}>
                <Link className="drawerpadding" to="/player/events">
                  Current Events
                </Link>{" "}
              </div>
              <div className="drawerpadding" onClick={onClose}>
                <Link className="drawerpadding" to="/player/events/markedevents">
                  Marked Events
                </Link>{" "}
              </div>{" "}
            </Panel>
          </Collapse>
        )}
      </div>
      <div style={{ paddingTop: "5px" }}>
        {notClickable ? (
          <div className="Nav_a" style={{ marginTop: "-6px" }} onClick={ALERT}>
            Teams
          </div>
        ) : (
          <Collapse className="customz" style={{ padding: "0px !important" }} ghost>
            <Panel className="custom" header="Teams" key="1">
              <div onClick={onClose}>
                <Link className="drawerpadding" state={"teams"} to="/player/search/team">
                  Join Team
                </Link>{" "}
              </div>
              <div className="drawerpadding" onClick={onClose}>
                <Link className="drawerpadding" to="/player/teams">
                  My Teams
                </Link>
              </div>
              <div className="drawerpadding" onClick={onClose}>
                <Link className="drawerpadding" to="/player/createteam">
                  Create Team
                </Link>{" "}
              </div>
            </Panel>
          </Collapse>
        )}
      </div>
      <div className="drawerpadding">
        {!notClickable && <Link to="/player/wallet">FlexTokens: {flexTokens}</Link>}
      </div>
      <div className="drawerpadding">
        <Link
          onClick={() => {
            const userDetails = JSON.parse(localStorage.getItem("userDetails"));
            userDetails.role = "Organizer";
            localStorage.setItem("userDetails", JSON.stringify(userDetails));
            localStorage.removeItem("ProfileID");
          }}
          style={{ color: "var(--green)" }}
          to="/organizer">
          Switch to Organizer
        </Link>
      </div>
      <div className="drawerpadding" onClick={onClose}>
        {notClickable ? (
          <div onClick={ALERT}>Settings and Privacy</div>
        ) : (
          <Link
            state={{ currentflag, currentid }}
            to={currentflag === "team" ? `/player/team/${id}/settings` : "/player/settings"}>
            Settings and Privacy
          </Link>
        )}
      </div>
      <div className="drawerpadding">
        <Link
          to="/signin"
          onClick={() => {
            localStorage.removeItem("ProfileID");
            localStorage.removeItem("userToken");
            localStorage.removeItem("userDetails");
            localStorage.removeItem("panel");
            localStorage.removeItem("viewer");
            localStorage.removeItem("CoverPic");
            localStorage.removeItem("ProfilerPic");
          }}>
          Log out
        </Link>
      </div>
    </Drawer>
  );
};

export default PlayerDrawer;
