import {
  CreateEvent as CreateEventApi,
  EditEvent,
  GetAwardsBySportsType,
  GetSingleEvent
} from "../../../../Config/API/events";
import { error, success } from "../../../../common/Alerts/Message";
import useVariables from "../../../../common/hooks/useVariables";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../../../common/cards/Loader";
import Buttons from "../Components/Buttons/index";
import EventFiles from "../Components/FIles";
import Page1 from "../Components/Page1";
import Page2 from "../Components/Page2";
import { Formik } from "formik";
import { Form } from "antd";
import { createEventValidations } from "../../../../Config/helpers/validations";
import { useDispatch } from "react-redux";
import { unload } from "../../../../Store/Features/MatchesSlice";
import { GAMES_MODES } from "../../../../Config/helpers/constants";
import dayjs from "dayjs";
import { KEYS } from "../../../../Config/Keys";

const CreateEvent = () => {
  const { eventID } = useParams();
  const dispatch = useDispatch();
  const [eventData, setEventData] = useState(null);
  const [Count, setCount] = useState(1);
  const [loading, setloaing] = useState(false);
  const [eventLoading, setEventLoading] = useState(false);
  const [panelModal, setPanelModal] = useState(false);
  const [sportAwards, setSportAwards] = useState([]);
  const [eventAddedAwards, setEventAddedAwards] = useState([]);

  const { viewer } = useVariables();
  const pid = viewer?.id; //organizer
  const { id } = useParams(); //panel id
  const [file, setfile] = useState({
    file: {},
    preview: ""
  });

  // input type image handler
  const onImageFileChange = (e) => {
    const file = e.target.files[0];
    setfile({
      file: file,
      isChanged: eventID ? true : false,
      preview: URL.createObjectURL(file)
    });
  };
  const navigate = useNavigate();

  // convert awards to the same object as when user selects it when coming from get api
  function combineAwardsByAwardId(data) {
    const combinedAwards = [];

    if (data?.length > 0) {
      // Iterate through the data array
      data.forEach((item) => {
        // Check if an object with the same title exists in combinedAwards
        const existingAward = combinedAwards.find((award) => award?.awardId === item?.awardId);

        if (existingAward) {
          // If an object with the same title exists, add the current award to its 'awards' array
          existingAward.awards.push(item);
        } else {
          // If no object with the same title exists, create a new object and push it to combinedAwards
          combinedAwards.push({
            awardId: item?.awardId,
            awards: [
              {
                _id: item?._id,
                rank: item?.rank,
                awardType: item?.awardType,
                awardValue: item?.awardValue
              }
            ]
          });
        }
      });

      // Sort awards within each object based on their ranks
      combinedAwards?.forEach((award) => {
        award.awards.sort((a, b) => a.rank - b.rank);
      });
    }

    setEventAddedAwards(combinedAwards || "");
  }

  const handleCreateEvent = async (values, draft = false) => {
    const {
      name,
      location,
      city,
      description,
      typeOfMatch,
      sportsType,
      gameMode,
      panel,
      selectedSport,
      awards,
      startDate,
      endDate,
      deadLineDate,
      fee,
      rules,
      minParticipants,
      maxParticipants,
      coordinates,
      wildCard
    } = values;
    // check if cover picture was uploaded
    if (!file?.preview?.length > 0 && !file.file) {
      // do not show this error when event is drafteds
      if (!draft) {
        return error("Upload cover photo first");
      }
    }
    if (values?.valTeam !== "error") {
      setloaing(true);
      const formdata = new FormData();
      formdata.append("name", name);
      formdata.append("description", description);
      formdata.append("location", location);
      formdata.append("city", city);
      formdata.append("typeofsports", sportsType);
      formdata.append("nameofsports", selectedSport);
      formdata.append("startDate", JSON.stringify(startDate));
      formdata.append("endDate", JSON.stringify(endDate));
      formdata.append("deadline", JSON.stringify(deadLineDate));
      formdata.append("coordinate", JSON.stringify(coordinates));
      formdata.append("fee", fee > 0 ? fee : 0);
      formdata.append("awards", JSON.stringify(awards));
      formdata.append("maxParicipants", maxParticipants || undefined);
      formdata.append("minParicipants", minParticipants);
      formdata.append("typeofmatch", typeOfMatch);
      formdata.append("gamemode", gameMode);
      formdata.append("rules", rules);
      if (eventID) {
        if (file?.isChanged) {
          formdata.append("file", file.file);
        }
      } else if (!eventID) {
        formdata.append("file", file.file);
      }
      formdata.append("organizer", pid);
      formdata.append("panelId", panel);
      // only add wildCard data if checkbox is checked
      if (values?.wildCardEntry) {
        formdata.append("wildCard", JSON.stringify(wildCard));
      } else {
        formdata.append("wildCard", []);
      }

      if (eventID) {
        formdata.append("id", eventID);
      }

      if (draft) {
        formdata.append("draft", true);
      } else {
        formdata.append("draft", false);
      }

      try {
        let response = {};
        if (eventID) {
          response = await EditEvent(formdata);
        } else {
          response = await CreateEventApi(formdata);
        }
        const data = await response.json();
        if (data.type === "success") {
          success(`Event ${draft ? "drafted" : eventID ? "updated" : "created"} successfully ✨`);
          if (draft) {
            // navigate to draft events listing if event is drafted
            navigate("/organizer/events/draftevents");
          } else {
            navigate(`/organizer/event/${eventID || data?.result?._id || id}`);
          }
        } else {
          error("An error occured");
          setloaing(false);
        }
      } catch (e) {
        setloaing(false);
        error(e.message);
      }
    }
  };

  // initial form values
  const initialValues = {
    name: eventData?.name || "",
    description: eventData?.description || "",
    location: eventData?.location || "",
    city: eventData?.city || "",
    sportsType: eventData?.typeofsports || "",
    selectedSport: eventData?.nameofsports || "",
    startDate: eventData?.startDate ? dayjs(eventData?.startDate).format("YYYY-MM-DD") : "",
    endDate: eventData?.endDate ? dayjs(eventData?.endDate).format("YYYY-MM-DD") : "",
    deadLineDate: eventData?.deadline ? dayjs(eventData?.deadline).format("YYYY-MM-DD") : "",
    fee: eventData?.fee || "",
    awards: eventAddedAwards || "",
    maxParticipants: eventData?.maxParicipants || "",
    minParticipants: eventData?.minParicipants || "",
    typeOfMatch: eventData?.typeofmatch || "",
    gameMode: eventData?.typeofmatch === GAMES_MODES.multi ? GAMES_MODES.multi : "",
    rules: eventData?.rules || "",
    panel: eventData?.panel?._id || "",
    coordinates: eventData?.coordinates || {
      latitude: 33.738045,
      longitude: 73.084488
    },
    draft: eventData?.draft || false,
    wildCardEntry: eventData?.wildCards?.length > 0 ? true : false,
    wildCard: eventData?.wildCards?.length > 0 ? eventData?.wildCards : []
  };

  const getEventData = async () => {
    try {
      setEventLoading(true);
      const res = await GetSingleEvent({ eventId: eventID });
      const data = await res.json();

      if (data?.type === "success") {
        setEventData(data?.result);
        setfile({
          file: {},
          changed: false,
          preview: KEYS.API + "/" + data?.result?.file?.file
        });

        if (data?.result?.awardsDetail?.length > 0 && data?.result?.nameofsports) {
          const response = await GetAwardsBySportsType(data?.result?.nameofsports);
          const awardsData = await response.json();
          if (awardsData) {
            setSportAwards(awardsData);
          }
        }

        combineAwardsByAwardId(data?.result?.awardsDetail);
      }
      setEventLoading(false);
    } catch (e) {
      dispatch(unload());
      setEventLoading(false);
    }
  };

  useEffect(() => {
    if (eventID) {
      getEventData();
    }
  }, [eventID]);

  if (eventLoading)
    return <Loader fullpage text={"Getting Event data please wait"} loading="white" />;

  return (
    <Fragment>
      <div>
        <h1 className="ceheading" style={{ paddingBottom: Count === 2 ? "20px" : undefined }}>
          {eventID ? "Update" : "Create"} Event
        </h1>
        <EventFiles isVisible={Count === 1} file={file} onImageFileChange={onImageFileChange} />
        <h4 className="cetextcount">{"Page " + Count + " of 2"}</h4>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={createEventValidations}
          onSubmit={handleCreateEvent}>
          {({ values, errors, dirty, isValid, setFieldValue, handleSubmit }) => (
            <Form
              name="basic"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              initialValues={{ remember: true }}
              autoComplete="off"
              onFinish={handleSubmit}>
              <div className="formorganizer" style={{ marginTop: "2%" }}>
                <Page1
                  isVisible={Count === 1}
                  setFieldValue={setFieldValue}
                  values={values}
                  errors={errors}
                  panelModal={panelModal}
                  setPanelModal={setPanelModal}
                  sportAwards={sportAwards}
                  setSportAwards={setSportAwards}
                  registrationComplete={eventData?.registrationComplete}
                />
                <Page2
                  isVisible={Count === 2}
                  values={values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  eventID={eventID}
                />
              </div>
              <Buttons
                Count={Count}
                setCount={setCount}
                values={values}
                disabled={eventID ? false : !(isValid && dirty) || loading}
                loading={loading}
                callback={handleCreateEvent}
                edit={eventID ? true : false}
                isDrafted={eventData?.draft}
              />
            </Form>
          )}
        </Formik>
      </div>
    </Fragment>
  );
};

export default CreateEvent;
