import React from "react";
import { DeletePanelPost, EditPanelPost } from "../../../Config/API/panel";
import Loader from "../../../common/cards/Loader";
import Tl from "../../../common/cards/TPTimelineCard";
import NoData from "../../../common/cards/noData";
import { Row } from "antd";
import { useFetchProfilePosts } from "../../../common/hooks/useFetchProfilePosts";
import UploadPostcard from "../../../common/cards/UploadPostcard";
import { Fragment } from "react";
import { useCurrentPage } from "../../../common/hooks/useCurrentPage";
import { useCreatePost } from "../../../common/hooks/useCreatePost";

const DiscussionPanel = () => {
  const { posts, setPosts, postloading } = useFetchProfilePosts("panel");
  const { other } = useCurrentPage();
  const {
    uploadloading,
    IMAGE,
    VIDEO,
    setimage,
    setvideo,
    text,
    setText,
    uploadingMedia,
    setuploadingMedia,
    CreatePost,
    setLuploading,
    Luploading
  } = useCreatePost(setPosts, "Panel");

  return (
    <Row className={`mobileto`}>
      <Fragment>
        {other ? null : (
          <UploadPostcard
            uploadingMedia={uploadingMedia}
            setuploadingMedia={setuploadingMedia}
            setText={setText}
            text={text}
            IMAGE={IMAGE}
            setimage={setimage}
            VIDEO={VIDEO}
            setvideo={setvideo}
            onPost={CreatePost}
            loading={uploadloading}
            setLuploading={setLuploading}
            Luploading={Luploading}
          />
        )}
      </Fragment>
      {postloading ? (
        <Loader />
      ) : posts?.length > 0 ? (
        posts?.map((data, index) => {
          return (
            <Tl
              Editing
              key={index}
              data={data}
              setPOSTs={setPosts}
              editpost={EditPanelPost}
              deletepost={DeletePanelPost}
            />
          );
        })
      ) : (
        <NoData />
      )}
    </Row>
  );
};

export default DiscussionPanel;
