import React from "react";
import { Layout } from "antd";
import HomeNav from "./HomeNav";
import HomeFooter from "./HomeFooter";
import { Outlet } from "react-router-dom";
import Container from "../../../common/Container/Container";
const { Header, Footer, Content } = Layout;
const HomeLayout = () => {
  return (
    <Layout>
      <Header
        className="homenav"
        style={{
          backgroundColor: "white",
          padding: "0px",
          lineHeight: "0px",
          zIndex: "1"
        }}>
        <Container isPaddingBlock={true}>
          <HomeNav />
        </Container>
      </Header>
      <Content className="homeContent" style={{ backgroundColor: "white" }}>
        <Container isPaddingBlock={false}>
          <Outlet />
        </Container>
      </Content>
      <Footer style={{ backgroundColor: "#2F89FC" }}>
        <HomeFooter />
      </Footer>
    </Layout>
  );
};

export default HomeLayout;
