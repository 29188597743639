import { Col, Row } from "antd";
import React, { Fragment } from "react";
import Card from "./Card/Card";
import NoData from "../../common/cards/noData";

const Following = ({ data, setData }) => {
  const activedata = data?.filter((e) => e?.accept);
  return (
    <Fragment>
      <Row gutter={[15, 0]}>
        {activedata?.length > 0 ? (
          activedata?.map((e, i) => (
            <Col
              key={i}
              xxl={6}
              xl={8}
              lg={{ span: 12 }}
              md={{ span: 12 }}
              sm={24}
              xs={24}
              style={{
                display: "flex",
                alignItems: "stretch"
              }}>
              <Card type={"danger"} setData={setData} data={e} buttonText="unfollow" />
            </Col>
          ))
        ) : (
          <Col span={24}>
            <NoData noMedia />
          </Col>
        )}
      </Row>
    </Fragment>
  );
};

export default Following;
