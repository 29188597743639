import { Checkbox, Rate, List } from "antd";

import { Link } from "react-router-dom";
import React from "react";
import { FiUsers } from "react-icons/fi";
import { KEYS } from "../../../Config/Keys";
import { useNavigate } from "react-router-dom";

import { FaMapMarkerAlt } from "react-icons/fa";
import { SiNike } from "react-icons/si";
import dayjs from "dayjs";
import classes from "./index.module.css";

const Events = ({ loading, Events, selectedOptions, handleCheckboxChange }) => {
  const navigate = useNavigate();

  // Function to extract city and country
  const extractCityCountry = (locationString) => {
    const words = locationString.split(" ");
    const cityCountry = words.slice(-2).join(" "); // Extract second last and last word
    return cityCountry;
  };

  return (
    <div className="">
      <div className={classes.checkboxContainer}>
        <Checkbox
          className={classes.checkboxitem}
          name="registrationStatus"
          checked={selectedOptions.registrationStatus}
          onChange={(e) => handleCheckboxChange(e.target.name, e.target.checked)}>
          Registration Open
        </Checkbox>
        <Checkbox
          className={classes.checkboxitem}
          name="allowWildCard"
          checked={selectedOptions.allowWildCard}
          onChange={(e) => handleCheckboxChange(e.target.name, e.target.checked)}>
          Wildcards
        </Checkbox>
        <Checkbox
          className={classes.checkboxitem}
          name="prizeMoney"
          checked={selectedOptions.prizeMoney}
          onChange={(e) => handleCheckboxChange(e.target.name, e.target.checked)}>
          Prize Money
        </Checkbox>
        <Checkbox
          className={classes.checkboxitem}
          name="sponsors"
          checked={selectedOptions.sponsors}
          onChange={(e) => handleCheckboxChange(e.target.name, e.target.checked)}>
          Sponsers
        </Checkbox>
      </div>
      <List
        itemLayout="horizontal"
        className="searchedlistItemParent"
        loading={loading}
        dataSource={Events || []}
        renderItem={(item, index) => {
          return (
            <List.Item
              key={index}
              onClick={() => {
                navigate(`/view/event/${item?._id}`, { state: "event" });
              }}
              className={classes.listContainer}>
              {item.typeofsports && (
                <div className={classes.topTitle}>
                  {item.typeofsports + "-"} <span>{item?.nameofsports?.toUpperCase()}</span>
                </div>
              )}

              <div className={classes.fileContainer}>
                <img
                  src={KEYS?.API + "/" + item?.file?.file}
                  alt=""
                  style={{ borderRadius: "50%" }}
                />
              </div>
              <div style={{ flex: 1, marginTop: "1rem" }}>
                <h3 className={classes.title}>
                  {" "}
                  {item?.name} <Rate count={1} defaultValue={item?.ratings || 2.5} />{" "}
                  <span>{item?.ratings || 2.5}</span>
                </h3>
                <h3 className={classes.panel}>By: {item?.panel?.name}</h3>
                <p className={classes.eventDate}>
                  {" "}
                  {item?.startDate ? dayjs(item?.startDate)?.format("DD MMMM ") : ""}
                </p>
                <p className={classes.eventLocation}>
                  {" "}
                  <FaMapMarkerAlt />
                  {item?.location ? extractCityCountry(item?.location) : ""}
                </p>
                <div className={classes.searchListItemInfo}>
                  <span>
                    <FiUsers />
                  </span>
                  {item?.registerteams?.length + "/" + item?.totalSlots || 0} Teams
                </div>
                <div className={classes.btn}>
                  {item?.allowWildCard && (
                    <Link to="/" style={{ background: "#1890ff" }}>
                      Wildcards
                    </Link>
                  )}
                  {item?.registrationComplete === false && (
                    <Link to="/" style={{ background: "green" }}>
                      Resgistration Open
                    </Link>
                  )}
                </div>
              </div>
              <div className={classes.rewardContainer}>
                <div className={classes.reward}>
                  <p>
                    {" "}
                    <span>💵</span> {item?.fee || 0} Rs
                  </p>
                  <p>
                    {" "}
                    <span>🏅</span> {item?.certificate || 0} Certificate
                  </p>
                  <p>
                    {" "}
                    <span>🛡️</span> {item?.shield || 0} Shield
                  </p>
                </div>
                <div className={classes.sponsors}>
                  <p>Sponsors:</p>
                  <div>
                    <SiNike />
                  </div>
                  <div
                    style={{
                      borderLeft: "0.5px solid #c3c3c3",
                      paddingLeft: "15px"
                    }}>
                    <SiNike />
                  </div>
                </div>
              </div>
            </List.Item>
          );
        }}
      />
    </div>
  );
};

export default Events;
