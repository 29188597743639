import React from "react";
import { Button, Space, List, Typography, Tag } from "antd";
import { TRANSACTION_CONSTANTS } from "../../Config/helpers/constants";
import dayjs from "dayjs";
const { Text } = Typography;

function TransactionsHistory({ transactions, loading, userId, filter, setFilter }) {
  return (
    <>
      <Space>
        <Button
          className="buttonpo"
          style={{ margin: "0 2.5% 3% 0" }}
          type={filter === null && "primary"}
          onClick={() => setFilter(null)}>
          All
        </Button>
        <Button
          className="buttonpo"
          style={{ margin: "0 2% 1% 0" }}
          type={filter === TRANSACTION_CONSTANTS.pending && "primary"}
          onClick={() => setFilter(TRANSACTION_CONSTANTS.pending)}>
          Pending
        </Button>
        <Button
          className="buttonpo"
          style={{ margin: "0 2% 1% 0" }}
          type={filter === TRANSACTION_CONSTANTS.completed && "primary"}
          onClick={() => setFilter(TRANSACTION_CONSTANTS.completed)}>
          Approved
        </Button>
      </Space>
      <section className="mt-4">
        <List
          loading={loading}
          itemLayout="vertical"
          style={{ maxWidth: "600px" }}
          dataSource={transactions}
          bordered
          renderItem={(item) => (
            <List.Item style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <Text strong style={{ textTransform: "capitalize" }}>
                  {item?.type === TRANSACTION_CONSTANTS.topUp ? "Top Up" : item?.type}
                </Text>
                <br />
                <Text>
                  {item?.updatedAt ? dayjs(item?.updatedAt).format("YYYY-MM-DD hh:mma") : "-"}
                </Text>

                <Tag
                  style={{ textTransform: "capitalize" }}
                  className="ml-2"
                  color={item?.status === TRANSACTION_CONSTANTS.pending ? "red" : "green"}>
                  {item?.status}
                </Tag>
                <br />
                {/* don't show sent to/received from incase of top and withdraw */}
                {item?.type === TRANSACTION_CONSTANTS.topUp ||
                item?.type === TRANSACTION_CONSTANTS.withdraw ? null : (
                  <Text type="secondary">
                    {item?.senderId === userId ? "Sent to" : "Received from"}
                    {item?.senderId === userId
                      ? `: ${item?.receiverWalletId?.ownerId?.name} (${item?.receiverWalletId?.ownerType})`
                      : `:${item?.senderWalletId?.ownerId?.name} (${item?.senderWalletId?.ownerType})`}
                  </Text>
                )}
                <br />
              </div>

              <Space direction="vertical">
                <Text>FlexTokens</Text>
                <Text
                  type={
                    item?.type === TRANSACTION_CONSTANTS.topUp ||
                    (item?.type === TRANSACTION_CONSTANTS.transfer && item?.senderId !== userId)
                      ? "success"
                      : "danger"
                  }>
                  {item.amount.toFixed(2)}
                </Text>
              </Space>
            </List.Item>
          )}
        />
      </section>
    </>
  );
}

export default TransactionsHistory;
