import React, { Fragment } from "react";
import { Col, Row } from "antd";
import DiscussionOrganizer from "./DiscussionOrganizer";
import RightBar from "../../../common/righbar";
import ScrollToTop from "../../../common/cards/ScrollToTop";
import { useSelector } from "react-redux";
import { PROFILE } from "../../../Store/Features/ProfileSlice";
import { ROLES } from "../../../Config/helpers/constants";
const Profile = () => {
  const id = useSelector(PROFILE);

  return (
    <Fragment>
      <Row justify="center" className="rowPadding">
        <ScrollToTop />
        <Col
          xs={{ span: 24, order: 3 }}
          sm={{ span: 24, order: 3 }}
          md={{ span: 24, order: 3 }}
          lg={{ span: 14, order: 2 }}>
          {id?._id ? <DiscussionOrganizer /> : null}
        </Col>
        <RightBar current={ROLES.organizer} />
      </Row>
    </Fragment>
  );
};

export default Profile;
