import React from "react";
import { Typography } from "antd";
const { Title, Text } = Typography;
function CommingSoon() {
  return (
    <div
      style={{
        width: "100%",
        height: "300px",
        display: "grid",
        placeContent: "center",
        textAlign: "center",
        paddingInline: "1.5rem"
      }}>
      <Typography>
        <Title level={2} style={{ textAlign: "center" }}>
          Coming Soon!
        </Title>
        <Text style={{ textAlign: "center", fontSize: ".8rem" }}>
          Sorry for the inconvenience, This page is under construction Please visit this page some
          other time
        </Text>
      </Typography>
    </div>
  );
}

export default CommingSoon;
