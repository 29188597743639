import React, { Fragment, useState } from "react";
import { Button, Select, Modal } from "antd";
import { error, success } from "../../../../common/Alerts/Message";
import { AssignRole } from "../../../../Config/API/teams";
import Loader from "../../../../common/cards/Loader";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import {
  EVENT_AND_TEAM_ROLES,
  EVENT_AND_TEAM_ROLES_LIST
} from "../../../../Config/helpers/constants";

function EditModalCard({ setopen, open, playerId, setteam, r, setstatus, closable = true }) {
  const [role, setrole] = useState(r || "");
  const [loading, setloading] = useState(false);
  const { id } = useParams();

  const assignRole = async () => {
    try {
      setloading(true);

      if (id && role !== "" && playerId) {
        const res = await AssignRole({
          teamId: id,
          playerId,
          role
        });
        const data = await res.json();
        if (data?.type === "failure") {
          error(data?.result);
        }
        if (data?.type === "success") {
          success("Role Assigned");
          // const d = {
          //   player: {
          //     _id: data?.data?.profile?._id,
          //     profilepic: data?.data?.profile?.profilepic,
          //     name: data?.data?.profile?.name
          //   },
          //   role: "Team Creator",
          //   active: true,
          //   owner: true
          // };
          // data?.data?.members?.unshift(d);
          setteam(data?.data);
          // if another player was assign as a captain remove captain status from current player
          if (role === EVENT_AND_TEAM_ROLES.captain) {
            setstatus(false);
          }
        }
        setrole("");
        setopen(false);
      } else throw new Error("One of the id's are missing try again later!");
      setloading(false);
    } catch (e) {
      console.log(e);
      error(e);
      if (setloading) setloading(false);
      if (setrole) setrole("");
    }
  };

  useEffect(() => {
    return () => {
      setloading(null);
      setrole(null);
    };
  }, []);

  return (
    <Modal
      className="editPost"
      confirmLoading
      mask
      maskClosable={closable}
      onCancel={() => {
        setopen(false);
      }}
      closable={false}
      centered
      destroyOnClose={true}
      open={open}
      footer={false}
      // style={{ minWidth: '150px' }}
    >
      {loading ? (
        <Loader smaller center noShadow />
      ) : (
        <Fragment>
          <h1
            style={{
              textAlign: "center",
              fontWeight: 700,
              fontSize: "30px"
            }}>
            Assign Role
          </h1>
          {/* <Input
            allowClear
            style={{
              margin: "10px 0",
              borderRadius: "10px",
              padding: "10px"
            }}
            className="TeamSelect"
            value={role}
            onChange={(e) => setrole(e.currentTarget.value)}
            placeholder="Manager ..."
          /> */}
          <Select onChange={(e) => setrole(e)}>
            {EVENT_AND_TEAM_ROLES_LIST.map((role, index) => (
              <Select.Option key={index} value={role.value}>
                {role.label}
              </Select.Option>
            ))}
          </Select>
          <Button
            style={{ width: "100px", margin: "0 auto 20px auto" }}
            loading={loading ? true : false}
            onClick={assignRole}
            type="primary"
            className="blueButton">
            Done
          </Button>
        </Fragment>
      )}
    </Modal>
  );
}

export default EditModalCard;
