import React, { Fragment, useState, useEffect, useRef } from "react";
import { Row, Col, Form, Button, DatePicker, Select, Image } from "antd";

import { useNavigate, useParams } from "react-router-dom";
import { fallbackcover } from "../../../common/Utils";

import Swal from "sweetalert2";
import { serverError } from "../../../common/Alerts/serverError";
import { CreateProfileOrganizerAPI } from "../../../Config/API/organizer";
import { useFetchInitialProfile } from "../../../common/hooks/useFetchInitialProfile";

import Loader from "../../../common/cards/Loader";
import { error, success } from "../../../common/Alerts/Message";
// import ExperienceModal from "../../../common/Model/ExperienceModal";
// import { IoAddSharp } from "react-icons/io5";
// import useFieldsVaildator from "../../../common/hooks/useFieldsVaildator";
import { Formik } from "formik";
import InputTextField from "../../../common/FormItem/InputTextField";
import dayjs from "dayjs";
import { createPlayerOrganizerValidations } from "../../../Config/helpers/validations";
import { LOCATIONS, ROLES } from "../../../Config/helpers/constants";
import UserNameField from "../../../common/FormItem/UserNameField";
import useEditProfile from "../../../common/hooks/useEditProfile";
import { useFetchProfile } from "../../../common/hooks/useFetchProfile";
import { useSelector } from "react-redux";
import { PROFILE } from "../../../Store/Features/ProfileSlice";
import moment from "moment";
import { KEYS } from "../../../Config/Keys";

const { Option } = Select;

const CreateOrganizerProfile = () => {
  const { id } = useParams();
  const [editloading, edit] = useEditProfile();
  let profileData, profileDataLoading, setProfileDataLoading;
  if (!id) {
    // if creating profile fetch initial profile data
    const { Profile, profileLoding, setprofileLoding } = useFetchInitialProfile(ROLES.organizer);

    profileData = Profile;
    profileDataLoading = profileLoding;
    setProfileDataLoading = setprofileLoding;
  } else {
    // if editing profile
    const { profileLoding } = useFetchProfile(id, ROLES.organizer);
    const Profile = useSelector(PROFILE);
    profileData = Profile;
    profileDataLoading = profileLoding;
  }

  // const { inputTextVaildator, imageFileValidator } = useFieldsVaildator();

  // Profile
  const [profileImage, setProfileImage] = useState({
    file: {},
    preview: ""
  });

  const [coverImage, setCoverImage] = useState({
    file: {},
    preview: ""
  });

  // const [showExperienceModal, setShowExperienceModal] = useState(false);

  const initialValues = {
    firstName: profileData?.firstName || "",
    lastName: profileData?.lastName || "",
    name: id ? profileData?.name : "",
    username: id ? profileData?.username || "" : profileData?.username || "",
    location: id ? profileData?.location || "" : "",
    DOB: profileData?.dob || "",
    description: id ? profileData?.description || "" : ""
    // awards: "",
    // endorsements: "",
    // experience: {
    //   name: "",
    //   date: "",
    //   location: "",
    //   panelName: "",
    //   role: "",
    //   awards: "",
    //   description: "",
    //   pictures: ""
    // }
  };

  useEffect(() => {
    if (id) {
      setCoverImage({
        file: {},
        preview: KEYS.API + "/" + profileData.cover
      });
      setProfileImage({
        file: {},
        preview: KEYS.API + "/" + profileData.profile
      });
    }

    return () => {
      setCoverImage({
        file: null,
        preview: ""
      });
      setProfileImage({
        file: null,
        preview: ""
      });
    };
  }, [profileData]);

  const children = [];
  for (let i = 10; i < 36; i++) {
    children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
  }
  const navigate = useNavigate();

  const isProfile = useRef();
  const isCover = useRef();
  // input type image handler
  const onImageFileChange = async (e) => {
    const file = e.target.files[0];
    if (file?.size > 5000000) {
      return Swal.fire({
        icon: "error",
        title: "Uploading Error",
        text: "Image size should be less than 5mb",
        confirmButtonColor: "#0085FF",
        allowOutsideClick: false
      });
    }
    if (file) {
      if (e.target.name === "profileImage") {
        isProfile.current = true;
        setProfileImage({
          file: file,
          preview: URL.createObjectURL(file)
        });
      } else {
        isCover.current = true;
        setCoverImage({
          file: file,
          preview: URL.createObjectURL(file)
        });
      }
    }
  };

  const onFinish = async (values) => {
    if (profileImage?.preview === "") {
      return Swal.fire({
        icon: "error",
        title: "Upload profile photo first"
      });
    }

    // const obj = {
    //   name: values?.experience?.name,
    //   role: values?.experience?.role,
    //   date: JSON.stringify(dayjs(values?.experience?.date)),
    //   location: values?.experience?.location,
    //   awards: values?.experience?.awards,
    //   description: values?.experience?.description,
    //   panelName: values?.experience?.teamName
    // };
    const formData = new FormData();

    if (id) {
      formData.append("name", values?.name);
    } else {
      formData.append("name", values?.firstName + " " + values?.lastName);
    }

    if (profileData?.username !== values?.username) {
      formData.append("username", values?.username || "");
    }
    formData.append("dob", JSON.stringify(dayjs(values?.DOB).format("YYYY-MM-DD")) || "");
    formData.append("awards", values?.awards?.toString() || "");
    formData.append("location", values?.location || "");
    // if (inputTextVaildator(values?.experience?.name)) {
    //   formData.append("experience", JSON.stringify(obj));
    //   if (imageFileValidator(values?.experience?.pictures?.file)) {
    //     formData.append("exp_image", values?.experience?.pictures?.file);
    //   }
    // }
    formData.append("description", values?.description);
    if (profileImage?.preview && isProfile.current === true) {
      formData.append("profile", profileImage?.file);
    }
    if (coverImage?.preview && isCover.current === true) {
      formData.append("cover", coverImage?.file);
    }
    let response;
    if (id) {
      try {
        formData.append("id", id);
        edit(formData, ROLES.organizer, id);
      } catch (e) {
        error(e.message);
        Swal.fire({
          icon: "error",
          text: e.message
        });
      }
    } else {
      try {
        setProfileDataLoading(true);
        response = await CreateProfileOrganizerAPI(formData);
        const data = await response.json();
        setProfileDataLoading(false);
        if (data.type === "success") {
          success("Profile Created Succesfully");
          navigate("/organizer/feed");
        }

        if (data?.type === "failure") {
          error(data?.result);
        }
      } catch (err) {
        console.log(err);
        setProfileDataLoading(false);

        serverError();
      }
    }
  };

  // handle non-formik field changes
  function handleChanges(name, value, setFieldValue) {
    setFieldValue(name, value);
  }
  return (
    <Fragment>
      <div>
        {profileDataLoading && <Loader fullpage loading={"white"} />}
        <h1 className="headingPlayer">Organizer</h1>
        <Row>
          <Col sm={24} xs={24} md={24} style={{ position: "relative" }}>
            <div
              style={{
                position: "relative",
                width: "96%",
                margin: "auto",
                background: "#DADADA",
                borderRadius: "8px"
              }}
              className="CoverImageWrapper">
              {coverImage?.preview !== "" ? (
                <Image
                  wrapperClassName="CoverImageWrapper"
                  className="CoverImage"
                  preview={false}
                  src={coverImage?.preview}
                  style={{ objectFit: "cover" }}
                  fallback={fallbackcover}
                />
              ) : null}
              <div className="profileuploaders">
                <label htmlFor="coveruploader" style={{}}>
                  <img src="/images/icons/camera.png" alt="" />
                  {coverImage?.preview === "" ? <span>Add your Cover image</span> : null}
                </label>
                <input
                  type="file"
                  name="CoverImage"
                  className="uploaderInput"
                  id="coveruploader"
                  accept="image/*"
                  onChange={onImageFileChange}
                />
              </div>
            </div>
            <div className="profileuploaderForProfile">
              {profileImage?.preview !== "" ? (
                <Image
                  preview={false}
                  wrapperClassName="profileImageWrapper"
                  className="ProfileImage"
                  src={profileImage?.preview}
                  fallback={fallbackcover}
                />
              ) : null}
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  borderRadius: "50%"
                }}>
                <label htmlFor="profileuploader">
                  <img src="/images/icons/camerasmall.png" alt="" />
                  {profileImage?.preview === "" ? <span>Add Profile</span> : null}
                </label>
                <input
                  type="file"
                  name="profileImage"
                  className="uploaderInput"
                  id="profileuploader"
                  accept="image/*"
                  onChange={onImageFileChange}
                />
              </div>
            </div>
          </Col>
        </Row>
        <div className="formorganizer">
          <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={onFinish}
            validationSchema={createPlayerOrganizerValidations(id ? true : false)}>
            {({ errors, values, setFieldValue, handleSubmit, isValid, dirty }) => (
              <Form
                name="basic"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                autoComplete="off"
                onFinish={handleSubmit}>
                <Row justify="space-between">
                  <Col lg={12} md={12} xs={24} sm={24} className="ceinputformpadding">
                    {id ? (
                      <InputTextField
                        name="name"
                        required
                        label="Full name"
                        placeholder="Enter your full name"
                      />
                    ) : (
                      <>
                        {/* FirstName */}
                        <InputTextField
                          name="firstName"
                          required
                          label="First Name"
                          placeholder="Enter your first name"
                        />
                        {/* lastName */}
                        <InputTextField
                          name="lastName"
                          required
                          label="Last Name"
                          placeholder="Enter your last name"
                        />
                      </>
                    )}

                    {/* date */}
                    <Form.Item
                      className="profileItems"
                      required
                      label="Date of Birth"
                      help={errors?.DOB ? errors?.DOB : undefined}
                      validateStatus={errors?.DOB ? "error" : "success"}>
                      <DatePicker
                        value={values?.DOB ? moment(values?.DOB) : undefined}
                        className="playerDob"
                        name="DOB"
                        showToday
                        inputReadOnly
                        onChange={(value) => handleChanges("DOB", value, setFieldValue)}
                      />
                    </Form.Item>
                    {/* location */}
                    <Form.Item
                      required
                      label="Location"
                      help={errors?.location ? errors?.location : undefined}
                      validateStatus={errors?.location ? "error" : "success"}>
                      <Select
                        className="mb-2 w-100"
                        aria-required
                        value={values?.location}
                        placeholder="Location"
                        title="Location"
                        bordered={true}
                        name="location"
                        onChange={(value) => setFieldValue("location", value)}>
                        {LOCATIONS.map((item, index) => (
                          <Option key={index} value={item.value}>
                            {item.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>

                    {/* Experiences */}
                    {/* <Form.Item
                      className="profileItems border"
                      label="Experience"
                      help={errors?.experience ? errors?.experience : undefined}
                      validateStatus={errors?.experience ? "error" : "success"}>
                      <ExperienceModal
                        organizer={false}
                        showExperienceModal={showExperienceModal}
                        setShowExperienceModal={setShowExperienceModal}
                        values={values?.experience}
                        setFieldValue={setFieldValue}
                        handleChanges={handleChanges}
                      />
                      <Row justify="start" align="middle" gutter="1rem">
                        <span>
                          {values?.experience?.name.length > 0
                            ? `Experience ${values?.experience?.name} Added`
                            : "No Experience"}
                        </span>
                        <Button
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginLeft: "10px"
                          }}
                          type="primary"
                          shape="circle"
                          onClick={() => setShowExperienceModal(true)}
                          icon={<IoAddSharp />}
                          size={"large"}
                        />
                      </Row>
                    </Form.Item> */}
                  </Col>
                  <Col lg={12} md={12} xs={24} sm={24} className="ceinputformpadding">
                    {/* USERNAME */}
                    <UserNameField
                      error={errors?.username}
                      value={values?.username}
                      setFieldValue={setFieldValue}
                      type={ROLES.organizer}
                      prevUserName={id ? profileData?.username || "" : ""} //incase of edit
                    />

                    {/* Endorsements */}
                    {/* <Form.Item label="Endorsements (if any)">
                      <Row justify="space-between">
                        <Select
                          mode="tags"
                          value={values?.endorsements}
                          className="w-70"
                          aria-required
                          placeholder="e.g   “ Search “"
                          name="selectedSport"
                          onChange={(value) => setFieldValue("endorsements", value)}>
                          {children}
                        </Select>
                        <Button
                          style={{
                            marginTop: "11px",
                            borderRadius: "5px",
                            marginLeft: "10px"
                          }}
                          type="primary">
                          Send Requests
                        </Button>
                      </Row>
                    </Form.Item> */}
                    {/* description */}
                    <InputTextField
                      name="description"
                      label="Description"
                      placeholder="Something about yourself..."
                      type="textArea"
                    />
                  </Col>
                </Row>

                <Row justify="center">
                  <Button
                    loading={editloading}
                    htmlType="submit"
                    disabled={!(isValid && dirty) || editloading}
                    className="playerCreateBUtton"
                    type="primary">
                    {id ? "Edit" : "Create"}
                  </Button>
                </Row>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Fragment>
  );
};

export default CreateOrganizerProfile;
