import { Button, Row } from "antd";
import React, { Fragment } from "react";

function Buttons({ edit, Count, setCount, callback, disabled, values, loading, isDrafted }) {
  const saveAsDraftButton = (
    <Button
      loading={loading}
      disabled={loading}
      onClick={edit && !isDrafted ? null : () => callback(values, true)}
      style={{ maxWidth: "280px", margin: 0 }}
      className="draftbutton"
      htmlType="button">
      <b>Save as Draft</b>
    </Button>
  );
  return (
    <Row justify="center" style={{ margin: "80px auto", gap: "15px 30px", width: "85%" }}>
      {Count === 1 ? (
        <>
          {edit && !isDrafted ? null : saveAsDraftButton}

          <Button
            disabled={loading}
            style={{ maxWidth: "400px", margin: "0" }}
            className="ceprimarybutton blueButton"
            type="primary"
            onClick={() => setCount(Count + 1)}>
            <b>Next</b>
          </Button>
        </>
      ) : (
        <Button
          disabled={loading}
          style={{ maxWidth: "280px", margin: "0", width: "undefined" }}
          className="ceprimarybutton blueButton"
          type="primary"
          onClick={() => setCount(Count - 1)}>
          <b>Previous</b>
        </Button>
      )}
      {Count === 2 ? (
        <Fragment>
          {edit && !isDrafted ? null : saveAsDraftButton}
          <Button
            loading={loading}
            style={{ maxWidth: "280px", margin: 0 }}
            className="draftbutton"
            type="primary"
            onClick={() => callback(values, false)}
            disabled={disabled}>
            <b>{edit ? "Update" : "Create"}</b>
          </Button>
        </Fragment>
      ) : null}
    </Row>
  );
}

export default Buttons;
