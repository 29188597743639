import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import UploadPostcard from "../UploadPostcard";
import { useLocation } from "react-router-dom";
import { error } from "../../Alerts/Message";
import { KEYS } from "../../../Config/Keys";
import Loader from "../Loader";
import useVariables from "../../../common/hooks/useVariables";
import { useCurrentPage } from "../../../common/hooks/useCurrentPage";
import { EditPostWithMedia, EditPostWithoutMedia } from "../../../Config/API/posts";
import { EditEventPosts } from "../../../Config/API/events";

function EditModal({ setPOSTS, data, setopen, open, eventId }) {
  const [text, setText] = useState("");
  const [uploading, setuploading] = useState(false);
  const [uploadingMedia, setuploadingMedia] = useState("");
  const [initialLoading, setinitialLoading] = useState(false);
  const [isFileRemoved, setIsFileRemoved] = useState(false);
  const [IMAGE, setimage] = useState({
    file: {},
    type: "image",
    preview: ""
  });
  const [VIDEO, setvideo] = useState({
    file: {},
    type: "video",
    preview: ""
  });

  const [Luploading, setLuploading] = useState(false);
  const { pathname } = useLocation();
  const { PID } = useVariables();
  const { isPlayer, isOrganizer, isEvent } = useCurrentPage();

  // Update Post API
  const onPost = async () => {
    const err = "Add Something before updating post";
    try {
      let user = "";
      let file = null;
      let vals = new FormData(); // for media
      let values = {
        text: text,
        id: data?._id
      }; // for post without media
      const checkfile = () => {
        if (
          IMAGE?.file !== null &&
          IMAGE?.preview &&
          Object?.keys(IMAGE).indexOf("file") > -1 &&
          Object?.keys(IMAGE.file).length !== 0
        ) {
          file = IMAGE.file;
        } else if (
          VIDEO?.file !== null &&
          VIDEO?.preview &&
          Object?.keys(VIDEO).indexOf("file") > -1 &&
          Object?.keys(VIDEO.file).length !== 0
        ) {
          file = VIDEO.file;
        }
        if (String(text).trim().length === 0 && !IMAGE?.preview && !VIDEO?.preview)
          throw new Error(err);
      };
      await checkfile();

      if (file !== null) vals.append("fileType", uploadingMedia);
      if (file !== null) vals.append("file", file);
      vals.append("text", text);
      vals.append("id", data?._id);

      setinitialLoading(true);
      if (!pathname.includes("event")) {
        user = PID;
        if (isOrganizer) {
          vals.append("organizeruserId", user);
          vals.append("userRole", "Organizer");
          vals.append("flag", "organizerTimeline");

          values["organizeruserId"] = user;
          values["userRole"] = "Organizer";
          values["flag"] = "organizerTimeline";
        } else if (isPlayer) {
          vals.append("playeruserId", user);
          vals.append("userRole", "Player");
          vals.append("flag", "playerTimeline");

          values["playeruserId"] = user;
          values["userRole"] = "Player";
          values["flag"] = "playerTimeline";
        }
      }
      if (isEvent && eventId) {
        vals.append("userRole", "Organizer");
        vals.append("eventId", eventId);
        const res = await EditEventPosts(vals);
        const d = await res.json();
        console.log("edited");
        setPOSTS(d?.data);
        setinitialLoading(false);
        setopen(false);
        return;
      }
      if (user && data?._id) {
        if (file === null && !isFileRemoved) {
          console.log("without");
          console.log(values);
          const res = await EditPostWithoutMedia(values);
          const d = await res.json();
          setPOSTS(d?.data);
        } else if (file !== null || isFileRemoved) {
          console.log("with media");
          const res = await EditPostWithMedia(vals);
          const d = await res.json();
          setPOSTS(d?.data);
        }
        setuploading(false);
        setinitialLoading(false);
        setopen(false);
      } else {
        error("some fields are missing refresh or relogin");
        setuploading(false);
      }
      setopen(false);
      setinitialLoading(false);
    } catch (e) {
      error(String(e.message));
      console.log(e.message);
      setinitialLoading(false);
      if (e.message !== err) setopen(false);
    }
  };

  //set data
  useEffect(() => {
    if (!data || Object.entries(data)?.length === 0 || data === undefined || data === null) {
      setinitialLoading(true);
    } else {
      setText(data?.text);
      if (data?.file?.type !== "") {
        if (data?.file?.type === "image") {
          setuploadingMedia("image");
          setimage({
            file: null,
            type: "image",
            preview: KEYS.API + "/" + data?.file?.file
          });
        }
        if (data?.file?.type === "video") {
          setuploadingMedia("video");
          setvideo({
            file: null,
            type: "video",
            preview: KEYS.API + "/" + data?.file?.file
          });
        }
      }
      setinitialLoading(false);
    }
    setinitialLoading(false);
    return () => {
      setimage(null);
      setvideo(null);
      setuploading(null);
      setuploadingMedia(null);
      setinitialLoading(null);
      setText(null);
      setLuploading(null);
    };
  }, [data]);
  //changing media files
  useEffect(() => {
    if (uploadingMedia === "video") {
      setimage({
        file: {},
        type: "image",
        preview: ""
      });
    } else if (uploadingMedia === "image") {
      setvideo({
        file: {},
        type: "video",
        preview: ""
      });
    } else if (uploadingMedia === "error") {
      setimage({
        file: {},
        type: "image",
        preview: ""
      });
      setvideo({
        file: {},
        type: "video",
        preview: ""
      });
    }
  }, [uploadingMedia]);
  if (!open) {
    return null;
  }
  return (
    <Modal
      title="Edit Post"
      className="editPost"
      confirmLoading
      mask
      maskClosable
      onCancel={() => {
        setopen(false);
      }}
      closable={false}
      centered
      destroyOnClose={true}
      visible={open}
      footer={false}>
      {initialLoading ? (
        <Loader noShadow={true} />
      ) : (
        <UploadPostcard
          edit="EditModalPost"
          setuploading={setuploading}
          setIsFileRemoved={setIsFileRemoved}
          user={data?.user}
          IMAGE={IMAGE}
          setimage={setimage}
          VIDEO={VIDEO}
          setvideo={setvideo}
          loading={uploading}
          border
          onPost={onPost}
          setText={setText}
          text={text}
          uploadingMedia={uploadingMedia}
          setuploadingMedia={setuploadingMedia}
          Luploading={Luploading}
          setLuploading={setLuploading}
        />
      )}
    </Modal>
  );
}

export default EditModal;
