import { useEffect, useState } from "react";
import { error } from "../Alerts/Message";
import { ROLES } from "../../Config/helpers/constants";
import { GetOrganizerEventHistory } from "../../Config/API/organizer";
import { GetPlayerEventHistory } from "../../Config/API/player";
import { GetTeamEventHistory } from "../../Config/API/teams";
import { GetPanelEventHistory } from "../../Config/API/panel";

/**
 *
 * @param {*} role Event history for player, organizer, panel or team
 * @param {*} id  Id of player, organizer, panel or team
 * @returns data and loading
 */
export function useEventHistory(role, id) {
  const [historyLoading, setHistoryLoading] = useState(false);
  const [data, setData] = useState([]);

  const getEventHistory = async () => {
    try {
      setHistoryLoading(true);
      if (role === ROLES.organizer) {
        await GetOrganizerEventHistory(id, setData);
      } else if (role === ROLES.player) {
        await GetPlayerEventHistory(id, setData);
      } else if (role === ROLES.team) {
        await GetTeamEventHistory(id, setData);
      } else if (role === ROLES.panel) {
        await GetPanelEventHistory(id, setData);
      }

      setHistoryLoading(false);
    } catch (e) {
      error(e.message);
      setHistoryLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getEventHistory();
    }
  }, [id]);

  return { historyLoading, historyData: data };
}
