import React from "react";
import { useLocation } from "react-router-dom";
import MultiplayerSchedule from "./multiplayerSchedule/multiplayerSchedule";
import SimpleSchdule from "./simpleSchedule";
import { GAMES_MODES } from "../../../../../../Config/helpers/constants";
function ScheduleWrapper() {
  const { state } = useLocation();
  if (state?.typeofmatch === GAMES_MODES.multi) {
    return <MultiplayerSchedule />;
  } else {
    return <SimpleSchdule />;
  }
}

export default ScheduleWrapper;
