// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.heading {
  color: white;
  font-size: 38px;
  font-weight: 400;
}
.iconShow {
  font-size: 24px;
  color: white;
}

.iconShow.socialIcons:hover {
  cursor: pointer;
}

.textColor {
  color: white;
}
@media screen and (max-width: 575px) {
  .heading {
    margin-bottom: 20px !important;
  }
  .iconShow {
    margin-bottom: 20px !important;
  }
  .footer-icons {
    margin-bottom: 40px !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/layoutCss/footer.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,eAAe;EACf,YAAY;AACd;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,YAAY;AACd;AACA;EACE;IACE,8BAA8B;EAChC;EACA;IACE,8BAA8B;EAChC;EACA;IACE,8BAA8B;EAChC;AACF","sourcesContent":[".heading {\n  color: white;\n  font-size: 38px;\n  font-weight: 400;\n}\n.iconShow {\n  font-size: 24px;\n  color: white;\n}\n\n.iconShow.socialIcons:hover {\n  cursor: pointer;\n}\n\n.textColor {\n  color: white;\n}\n@media screen and (max-width: 575px) {\n  .heading {\n    margin-bottom: 20px !important;\n  }\n  .iconShow {\n    margin-bottom: 20px !important;\n  }\n  .footer-icons {\n    margin-bottom: 40px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
