import React, { Fragment, useState, useEffect } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import CommentForm from "./Components/CommentForm";
import { fallbackprofile, getuserlink } from "../../Utils";
import EditModal from "./Components/Modal";
import { Comment, Skeleton } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { poststate, setempty, setpost } from "../../../Store/Features/PostSlice";
import {
  checkProfileImage,
  checkProfileName,
  checkCommentDelete,
  checkCommentEdit
} from "../../Utils";
import moment from "moment";
import Loader from "../Loader";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { error, success } from "../../Alerts/Message";
import { PROFILE } from "../../../Store/Features/ProfileSlice";
import { useCurrentPage } from "../../hooks/useCurrentPage";

import {
  EditComment as EditCommentApi,
  EditReply as EditReplyAPi,
  DeleteComment as DeleteCommentApi,
  DeleteReply as DeleteReplyAPi
} from "../../../Config/API/posts";

import {
  EditCommentPost as EventEditCommentApi,
  EditReplyCommentPost as EventEditReplyAPi,
  DeleteCommentPost as EventDeleteCommentAPi,
  DeleteReplyCommentPost as EventDeleteReplyAPi
} from "../../../Config/API/events";

import FadeInImage from "../../Images/FadeInImage";

export default function COMMENTCOMPONENT({
  comments,
  onFinish,
  setPOSTs,
  postId,
  loading,
  setloading,
  FLAG,
  PID,
  eventId = null,
  replacePostDataWithNewData
}) {
  const [showreply, setshowreply] = useState([]); // replies toggle
  const [edit, setedit] = useState(""); // edit toggle
  const [cardloading, setcardloading] = useState(false);

  const dispatch = useDispatch();
  let post = useSelector(poststate);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  var G_Id = useSelector(PROFILE);
  G_Id = G_Id?._id; //current team or panel id

  const { isTeam, isPanel, isOrganizer, isPlayer } = useCurrentPage();

  // timeline flags
  const Getflag = () => {
    if (isTeam) return "teamTimeline";
    else if (isPanel) return "panelTimeline";
    else if (isPlayer) return "playerTimeline";
    else if (isOrganizer) return "organizerTimeline";
  };

  //Edit CommentAPI
  const EditComment = async (e, id) => {
    try {
      let values = {
        userRole: FLAG, //(FLAG===organizer || FLAG==="PLAYEr")
        user: PID,
        id,
        comment: e,
        postId
      };
      let flag = "";

      if (pathname.includes("timeline")) {
        flag = Getflag();
        if (flag === "organizerTimeline") values["organizeruserId"] = PID;
        else if (flag === "playerTimeline") values["playeruserId"] = PID;
        else if (flag === "teamTimeline") values["teamuserid"] = G_Id;
        else if (flag === "panelTimeline") values["paneluserid"] = G_Id;
        values["flag"] = flag;
      }
      if (eventId) values["eventId"] = eventId;
      else values["flag"] = FLAG;

      // console.log(String(e).trim().length > 0);
      if (String(e).trim().length > 0 && id) {
        setloading(id + 1);
        var r = null;

        // event comment api
        if (eventId) r = await EventEditCommentApi(values);
        // player,organizer timeline post comment edit api
        else r = await EditCommentApi(values);
        const data = await r.json();
        if (data.type === "success") {
          replacePostDataWithNewData(data?.data);
          setloading(false);
        }
      } else {
        setloading(false);
        Swal.fire({
          icon: "error",
          title: "Field can't be empty"
        });
      }
    } catch (e) {
      console.log(e);
      error(e.message);
      setloading(false);
    }
    setedit(false);
  };

  //Edit ReplyAPI
  const EditReply = async (e, id) => {
    try {
      let values = {
        userRole: FLAG, //(FLAG===organizer || FLAG==="PLAYEr")
        user: PID,
        id,
        comment: e,
        postId
      };
      let flag = "";
      if (pathname.includes("timeline")) {
        flag = Getflag();
        if (flag === "organizerTimeline") values["organizeruserId"] = PID;
        else if (flag === "playerTimeline") values["playeruserId"] = PID;
        else if (flag === "teamTimeline") values["teamuserid"] = G_Id;
        else if (flag === "panelTimeline") values["paneluserid"] = G_Id;
        values["flag"] = flag;
      } else values["flag"] = FLAG;
      if (eventId) values["eventId"] = eventId;

      if (String(e).trim().length > 0 && id) {
        setloading(id + 1);

        var r = null;

        // event comment api
        if (eventId) r = await EventEditReplyAPi(values);
        // player,organizer timeline post comment edit api
        else r = await EditReplyAPi(values);

        const data = await r.json();
        if (data.type === "success") {
          replacePostDataWithNewData(data?.data);
        }
        setloading(false);
      } else {
        Swal.fire({
          icon: "error",
          title: "Field can't be empty"
        });
      }
    } catch (e) {
      setloading(false);
      console.log(e);
      error(e.message);
    }
    setedit(false);
  };

  // Comment DeleteCommentAPi
  const DeleteComment = async (id) => {
    const d_id = id;
    dispatch(setpost(d_id));
    try {
      let values = {
        id,
        postId,
        user: PID
      };
      let flag = "";
      if (pathname.includes("timeline")) {
        flag = Getflag();
        if (flag === "organizerTimeline") values["organizeruserId"] = PID;
        else if (flag === "playerTimeline") values["playeruserId"] = PID;
        else if (flag === "teamTimeline") values["teamuserid"] = G_Id;
        else if (flag === "panelTimeline") values["paneluserid"] = G_Id;
        values["flag"] = flag;
      } else values["flag"] = FLAG;

      if (eventId) values["eventId"] = eventId;

      if (id && postId) {
        var res = null;
        if (eventId) res = await EventDeleteCommentAPi(values);
        else res = await DeleteCommentApi(values);
        const data = await res.json();
        if (data.type === "success") {
          replacePostDataWithNewData(data?.data);
          success("Comment deleted successfully...");
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "On of the fields is missing"
        });
      }
    } catch (e) {
      console.log(e);
      error(e.message);
    }
    dispatch(setpost(d_id));
  };

  // Reply DeleteReplyApi
  const DeleteReply = async (id) => {
    const d_id = id;
    dispatch(setpost(d_id));
    try {
      let values = {
        user: PID,
        id,
        postId
      };
      let flag = "";
      if (pathname.includes("timeline")) {
        flag = Getflag();
        if (flag === "organizerTimeline") values["organizeruserId"] = PID;
        else if (flag === "playerTimeline") values["playeruserId"] = PID;
        else if (flag === "teamTimeline") values["teamuserid"] = G_Id;
        else if (flag === "panelTimeline") values["paneluserid"] = G_Id;
        values["flag"] = flag;
      } else values["flag"] = FLAG;

      if (eventId) values["eventId"] = eventId;

      if (id && postId) {
        var res = null;
        if (eventId) res = await EventDeleteReplyAPi(values);
        else res = await DeleteReplyAPi(values);
        const data = await res.json();
        if (data.type === "success") {
          replacePostDataWithNewData(data?.data);
          success("Reply deleted successfully...");
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "On of the fields is missing"
        });
      }
    } catch (e) {
      console.log(e);
      error(e.message);
    }
    dispatch(setpost(d_id));
  };

  useEffect(() => {
    return () => {
      setshowreply([]);
      setedit(null);
      setcardloading(null);
      dispatch(setempty());
    };
  }, [dispatch]);

  //currently opened comments
  const togglereplyvisible = (c) => {
    const SR = showreply;
    if (SR.includes(c?._id)) {
      const a = SR.filter((e) => e !== c?._id);
      setshowreply(a);
      return;
    } else setshowreply([...showreply, c?._id]);
  };
  const cardloadingstyle = {
    opacity: cardloading ? "0" : "1",
    maxHeight: cardloading ? "1px" : "unset",
    overflowY: cardloading ? "hidden" : "unset"
  };
  return (
    <Fragment>
      {!FLAG || !PID || comments === null || comments === undefined ? (
        <Loader small />
      ) : (
        <Fragment>
          {comments?.map((comment, i) => {
            return post?.includes(comment?._id) ? (
              <Skeleton active avatar key={i} />
            ) : (
              <Fragment key={i}>
                {cardloading ? <Skeleton style={{ padding: "5px" }} avatar active /> : null}
                <div style={cardloadingstyle}>
                  <Comment
                    author={
                      <span style={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
                        <span
                          onClick={() => {
                            const link = getuserlink(comment);
                            navigate(link.url, { state: link.name });
                            console.log(comment);
                          }}
                          style={{
                            color: "black",
                            fontWeight: "600",
                            fontSize: "13px",
                            cursor: "pointer"
                          }}>
                          {checkProfileName(comment)}
                        </span>
                        <span className="timelinedate">{moment(comment?.date).fromNow()}</span>
                        {/* checks if user can delete/edit comment/reply */}
                        {checkCommentEdit(comment, setedit)}
                        {checkCommentDelete(comment, DeleteComment, DeleteReply)}
                      </span>
                    }
                    avatar={
                      <FadeInImage
                        type={"Fadepostprofile"}
                        fallaback={fallbackprofile}
                        src={checkProfileImage(comment)}
                        onClick={() => {
                          const link = getuserlink(comment);
                          navigate(link.url, { state: link.name });
                        }}
                        radiusAll
                      />
                    }
                    content={
                      <Fragment>
                        <p style={{ whiteSpace: "normal" }}>{comment.comment}</p>
                        {/* Button for viewing comment replies */}
                        {comment?.reply && (
                          <span
                            className={`commentsDownButton 
                             ${showreply?.includes(comment?._id) ? "true" : "false"}`}
                            onClick={() => togglereplyvisible(comment)}>
                            {comment.reply.length === 0 && " Add reply"}
                            {comment.reply.length === 1 && " 1 reply"}
                            {comment.reply.length > 1 && comment.reply.length + " replies"}
                            <MdKeyboardArrowDown />
                          </span>
                        )}

                        {/* recursive Component call for replies */}
                        {/* Component for viewing comment replies */}

                        {
                          comment?.reply?.length > 0 && showreply?.includes(comment?._id) && (
                            <COMMENTCOMPONENT
                              comments={comment.reply}
                              postId={postId}
                              setPOSTs={setPOSTs}
                              loading={loading}
                              setloading={setloading}
                              FLAG={FLAG}
                              PID={PID}
                              eventId={eventId}
                              replacePostDataWithNewData={replacePostDataWithNewData}
                            />
                          ) // recursive calling comment
                        }

                        {/* This Code Matches current comment id with the id the reply is clicked on by user */}
                        {/* Component for replying on comments  */}
                        {showreply?.includes(comment?._id) && (
                          <CommentForm
                            onFinish={onFinish}
                            commentId={comment?._id}
                            loading={loading}
                            status="Reply"
                          />
                        )}
                      </Fragment>
                    }
                  />

                  {/* Comments and replies edit component */}
                  <EDITCOMPONENT
                    edit={edit}
                    setedit={setedit}
                    comment={comment}
                    PID={PID}
                    FLAG={FLAG}
                    EditComment={EditComment}
                    EditReply={EditReply}
                    loading={loading}
                  />
                </div>
              </Fragment>
            );
          })}
        </Fragment>
      )}
    </Fragment>
  );
}

// Comments and replies edit component
const EDITCOMPONENT = ({ edit, setedit, comment, PID, FLAG, EditReply, EditComment, loading }) => {
  return (
    <Fragment>
      {/* Editing Comment */}
      <CommentEdit
        edit={edit}
        setedit={setedit}
        comment={comment}
        PID={PID}
        FLAG={FLAG}
        EditComment={EditComment}
        loading={loading}
      />
      {/* Editing Reply */}
      <ReplyEdit
        edit={edit}
        setedit={setedit}
        comment={comment}
        PID={PID}
        FLAG={FLAG}
        EditReply={EditReply}
        loading={loading}
      />
    </Fragment>
  );
};

const CommentEdit = ({ edit, setedit, comment, PID, EditComment, loading }) => {
  return (
    <Fragment>
      {edit === comment?._id &&
        (comment?.organizer?._id === PID ||
          comment?.player?._id === PID ||
          comment?.panel?._id === PID ||
          comment?.team?._id === PID) &&
        comment?.reply && (
          <EditModal
            isOpen={edit}
            setIsOPen={setedit}
            comment={comment}
            editComment={EditComment}
            loading={loading}
            text={"Edit Comment"}
          />
        )}
    </Fragment>
  );
};

const ReplyEdit = ({ edit, setedit, comment, PID, EditReply, loading }) => {
  return (
    <Fragment>
      {edit === comment?._id &&
        (comment?.organizer?._id === PID ||
          comment?.player?._id === PID ||
          comment?.panel?._id === PID ||
          comment?.team?._id === PID) &&
        (comment?.reply === null || comment?.reply === undefined) && (
          <EditModal
            isOpen={edit}
            setIsOPen={setedit}
            comment={comment}
            editComment={EditReply}
            text="Edit Reply"
            loading={loading}
          />
        )}
    </Fragment>
  );
};
