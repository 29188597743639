import React from "react";
import "./EventHistoryCard.css";
import { Avatar, Rate, Space, Grid, Typography } from "antd";
import dayjs from "dayjs";
import { ROLES, awardTypesIcons } from "../../Config/helpers/constants";
import { MdGroups } from "react-icons/md";
import { KEYS } from "../../Config/Keys";
import { BiUser } from "react-icons/bi";
import TimelineDot from "./TimelineDot";
const { useBreakpoint } = Grid;

function EventHistoryCard({ data, historyFor = "organizer" }) {
  const returnEventName = { player: "eventName", organizer: "name", panel: "name" };
  const breakpoints = useBreakpoint();
  const groupedAwards =
    data?.awards?.length > 0 ? Object.groupBy(data?.awards, ({ awardType }) => awardType) : [];

  // group awards by getting total awards and cash
  const awards = Object.keys(groupedAwards)?.map((key) => {
    if (key === "cash") {
      let cashSum = 0;
      groupedAwards[key]?.forEach((element) => {
        cashSum += Number(element?.awardValue) || 0;
      });

      return {
        awardType: key,
        awardValue: cashSum
      };
    } else {
      return {
        awardType: key,
        awardValue: groupedAwards[key]?.length
      };
    }
  });

  return (
    <div className="wrapper">
      <h2>{data?.[returnEventName?.[historyFor] || ""]}</h2>
      {/* on small screens show event image below title */}
      {!breakpoints?.md && (
        <TimelineDot
          img={data?.file?.file ? KEYS.API + "/" + data?.file?.file : "/images/homesports.png"}
        />
      )}
      {historyFor !== ROLES.panel && data?.panel?.name && (
        <Space direction="horizontal" align="center" className="mb-2">
          <p className="subHeading">By: {data?.panel?.name}</p>
          {/* panel image goes here */}
          <Avatar size={40} icon={<MdGroups />} src={KEYS.API + "/" + data?.panel?.profilepic} />
        </Space>
      )}
      <div className="flex-between align-center">
        <p className="bold mb-1">
          <span>{data?.numberOfParticipants}</span> Participants
        </p>
        <p className="date bold mb-1">
          {dayjs(data?.startDate).format("DD MMM YYYY") || "- no date"}
        </p>
      </div>
      <Space>
        <Avatar.Group maxCount={6} size="default">
          {Array.from({ length: data?.numberOfParticipants }).map((_, i) => (
            <Avatar key={i} size="small" icon={<BiUser />} />
          ))}
        </Avatar.Group>
      </Space>
      <Rate allowHalf value={data?.rating ? Number(data?.rating) : 0} disabled />
      <div className="flex-between mt-1 text-center">
        <div>
          <p className="bold">Sponsored</p>
          <Typography.Text type="secondary"> - </Typography.Text>
        </div>
        <div>
          <p className="bold">Awards</p>
          <Space direction="vertical" align="start">
            {awards?.length > 0
              ? awards?.map((award, index) => (
                  <React.Fragment key={index}>
                    <Typography.Text type="secondary">
                      {" "}
                      <img
                        style={{ width: "24px", height: "24px" }}
                        src={`/images/icons/${
                          awardTypesIcons.includes(award?.awardType)
                            ? `${award?.awardType}`
                            : "certificate"
                        }.svg`}
                      />{" "}
                      {award?.awardValue} {award?.awardType !== "cash" && award?.awardType}
                    </Typography.Text>
                  </React.Fragment>
                ))
              : "-"}
          </Space>
        </div>
      </div>
    </div>
  );
}

export default EventHistoryCard;
