import React, { Fragment, useEffect, useState } from "react";
import { Button } from "antd";
import { KEYS } from "../../../../Config/Keys";
import { error, success } from "../../../../common/Alerts/Message";
import Swal from "sweetalert2";
import { CancelOrDeleteTeamRequest } from "../../../../Config/API/teams";
import EditModalCard from "./EditModalCard";
import Loader from "../../../../common/cards/Loader";
import FadeInImage from "../../../../common/Images/FadeInImage";
import { useNavigate } from "react-router-dom";
import { fallbackprofile } from "../../../../common/Utils";
import useVariables from "../../../../common/hooks/useVariables";

const ActiveCard = ({ team, memberParent, setteam, status, setstatus }) => {
  const [open, setopen] = useState(false);
  const [loading, setloading] = useState(false);
  const { viewer } = useVariables();
  const [person, setperson] = useState(team.player?._id === viewer?.id);

  const cancelRequest = async () => {
    if (status && person) {
      // if the logged in member is a captain and wants to leave
      error("Choose other player as a captain before leaving.");
      return;
    }
    if (team?._id && memberParent) {
      Swal.fire({
        icon: "warning",
        text: `Are you sure you want to Remove ${team?.player?.name}!`,
        showCancelButton: true,
        cancelButtonColor: "var(--red)",
        confirmButtonText: "Remove Member"
      }).then(async (e) => {
        if (e.isConfirmed) {
          try {
            setloading(true);
            const response = await CancelOrDeleteTeamRequest({
              id: team.player._id,
              teamId: memberParent,
              flag: "delete"
            });
            const dataa = await response.json();
            if (dataa.type === "success") {
              success("Member Removed!");

              // dataa?.data?.members?.unshift(object);

              setteam({ ...dataa.data });
            } else {
              error(dataa?.result);
            }
            setloading(false);
          } catch (e) {
            setloading(false);
            console.log(e);
            error("An error occured");
          }
        }
      });
    } else {
      error("no Member id found! try again later");
    }
  };

  useEffect(() => {
    return () => {
      setopen(null);
      setperson(null);
    };
  }, []);
  const navigate = useNavigate();
  return (
    <Fragment>
      {loading && <Loader fullpage loading="white" text="please wait removing member...!" />}
      <div className="membercard">
        <div
          style={{ cursor: "pointer" }}
          onClick={() =>
            navigate(`/view/player/${team?.player?._id}/timeline`, { state: "player" })
          }>
          <div className="popadd">
            <FadeInImage
              src={KEYS.API + "/" + team?.player?.profilepic}
              className="poprofilepic"
              fallaback={fallbackprofile}
              type={"Fadegcard"}
            />
          </div>

          <p className="namepo">
            {team?.player?.name + (person ? " (you)" : "") || "fallback name"}
          </p>
          <p
            style={{
              color: "#7D7C7C",
              textAlign: "center",
              fontSize: "12px",
              marginTop: "8px"
            }}>
            {team?.role || "No Role Assigned"}
          </p>
        </div>
        {!team?.owner ? (
          <Fragment>
            {status ? (
              <div className="pobuttons">
                <Button className="rejpo" type="primary" onClick={cancelRequest}>
                  <img
                    alt=""
                    style={{ margin: "-5px 8px 0px 0px" }}
                    src="/images/icons/delete.png"
                    height="14px"
                    width="12px"
                  />
                  Delete
                </Button>
                {!person && (
                  <Button onClick={() => setopen(true)} className="accpo" type="primary">
                    <img
                      alt=""
                      style={{ margin: "-5px 8px 0px 0px" }}
                      src="/images/icons/edit.png"
                      height="16px"
                      width="16px"
                    />
                    Edit{" "}
                  </Button>
                )}
              </div>
            ) : (
              <div style={{ minHeight: "15px" }} />
            )}
          </Fragment>
        ) : (
          <div style={{ marginBottom: "20px" }} />
        )}
      </div>
      {open && (
        <EditModalCard
          r={team?.role}
          open={open}
          setopen={setopen}
          playerId={team?.player?._id}
          setteam={setteam}
          setstatus={setstatus}
        />
      )}
    </Fragment>
  );
};

export default ActiveCard;
