import React from "react";
import { RearrangeKnockoutFixtures } from "../../../../../../../Config/API/events";
import useVariables from "../../../../../../../common/hooks/useVariables";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  load,
  unload,
  ROUNDES,
  setRoundes,
  setTeams
} from "../../../../../../../Store/Features/kobracketslice";
import { Info, error, success } from "../../../../../../../common/Alerts/Message";
import { Breadcrumb, Button, Col, Row, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Fragment, useState } from "react";
import { useEffect } from "react";
import { setcurrentgroupindex } from "../../../../../../../Store/Features/MatchesSlice";

const useRoundsButtons = () => {
  var Rounds = useSelector(ROUNDES);
  const Matches = useSelector((state) => state.matches.matches);
  const currntgroupindex = useSelector((state) => state.matches.currntgroupindex);

  const { state } = useLocation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const teams = useSelector((state) => state.ko.teams);

  const activeRound = useSelector((state) => state.ko.activeround);
  const [Team1, setTeam1] = useState(null);
  const [Team2, setTeam2] = useState(null);

  const replaceteam = async () => {
    if (!Team1?.value || !Team2?.value) return Info("Select Teams first");
    if (Team1?.value === Team2?.value) return Info("You can't select same team!");

    const found = (() => {
      let found = false;
      Rounds[activeRound]?.forEach((e) => {
        let t1 = e?.match?.team1?._id;
        let t2 = e?.match?.team2?._id;
        if (t1 === Team1.value && t2 === Team2.value) {
          return (found = true);
        } else if (t1 === Team2.value && t2 === Team1.value) {
          return (found = true);
        }
      });
      return found;
    })();
    if (found) return Info("Already Playing Against this team!");

    const rearragneteams = async () => {
      var i1t = "";
      var i2t = "";
      Rounds[activeRound]?.forEach((e) => {
        let t1 = e?.match?.team1;
        let t2 = e?.match?.team2;
        if (t1?._id === Team1?.value) {
          i1t = e?.match?.team1;
        }
        if (t2?._id === Team1?.value) {
          i1t = e?.match?.team2;
        }

        if (t1?._id === Team2?.value) {
          i2t = e?.match?.team1;
        }
        if (t2?._id === Team2?.value) {
          i2t = e?.match?.team2;
        }
      });
      let newarr = [];
      Rounds[activeRound]?.forEach((e) => {
        let m = e?.match;
        let newm = {};
        let newe = {};
        if (m?.team1?._id === i1t?._id) {
          newm = { ...m, team1: i2t };
        }
        if (m?.team2?._id === i1t?._id) {
          newm = { ...m, team2: i2t };
        }

        if (m?.team1?._id === i2t?._id) {
          newm = { ...m, team1: i1t };
        }
        if (m?.team2?._id === i2t?._id) {
          newm = { ...m, team2: i1t };
        }
        newe = { ...e, match: Object.keys(newm)?.length > 0 ? newm : e?.match };
        newarr.push(newe);
      });
      newarr = Rounds?.map((e, i) => (i === activeRound ? newarr : e));
      return newarr;
    };

    const newarr = await rearragneteams();
    console.log(newarr);
    dispatch(load());

    try {
      var response = null,
        data = null,
        values = null;
      values = {
        eventId: id,
        matches: newarr
      };
      response = await RearrangeKnockoutFixtures(values);
      data = await response.json();
      console.log(data);

      const a = await newarr[activeRound]
        ?.map((e) => e?.match?.win === "" && e?.match?.team1)
        ?.filter((e) => e?._id);
      const b = await newarr[activeRound]
        ?.map((e) => e?.match?.win === "" && e?.match?.team2)
        ?.filter((e) => e?._id);

      let c = a?.concat(b);
      c?.sort((a, b) => a?.name.localeCompare(b?.name));
      dispatch(setTeams(c));

      if (data?.type === "success") {
        dispatch(setRoundes(newarr));
        success(data?.result);
      } else {
        error(data?.result);
      }
      dispatch(unload());
    } catch (e) {
      console.log(e);
      error(e.message);
      dispatch(unload());
    }
  };

  const BracketsReplaceButtons = () => {
    return (
      // length <= 5 &&
      <Col className="replacesection" xl={10} lg={12} md={14} sm={24} xs={24}>
        <Row justify="space-between" gutter={[40, 10]}>
          <Col span={12} sm={12} xs={24} className="profileItems">
            <label className="boldlabel">Replace Team</label>
            <Select
              showSearch
              dropdownStyle={{
                zIndex: 10
              }}
              allowClear
              placeholder="Select a team"
              optionFilterProp="children"
              style={{
                marginTop: "20px",
                borderRadius: "10px",
                height: "40px"
              }}
              onChange={(e, o) => {
                setTeam1(o);
              }}
              onClear={() => setTeam1(null)}
              value={Team1}
              filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
              }
              options={teams?.map((e, i) => {
                return {
                  key: e?._id + i,
                  value: e?._id,
                  label: e?.name,
                  index: i
                };
              })}
            />
          </Col>
          <Col span={12} sm={12} xs={24} className="profileItems">
            <label className="boldlabel">Replace Team</label>
            <Select
              showSearch
              dropdownStyle={{
                zIndex: 10
              }}
              allowClear
              placeholder="Select a team"
              optionFilterProp="children"
              onChange={(e, o) => {
                setTeam2(o);
              }}
              onClear={() => setTeam2(null)}
              value={Team2}
              style={{
                marginTop: "20px",
                borderRadius: "10px",
                height: "40px"
              }}
              filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
              }
              options={teams?.map((e, i) => {
                return {
                  value: e?._id,
                  label: e?.name,
                  index: i,
                  key: e?._id + e?._id
                };
              })}
            />
          </Col>
          <Col span={24}>
            <Button
              onClick={replaceteam}
              type="primary"
              className="btnMobileDimensions blueButtonSmall ">
              Replace
            </Button>
          </Col>
        </Row>
      </Col>
    );
  };

  const HistoryButtons = ({ origin, loading }) => {
    const { FLAG } = useVariables();
    const panel = localStorage.getItem("panel");
    if (loading) return null;
    return (
      <Breadcrumb separator=">" style={{ padding: "0 0 2% 2.5%" }}>
        <Breadcrumb.Item>
          <Link to={`/${FLAG}`}>Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={`/organizer/${panel ? "panel/" + panel + "/timeline" : "panels"}`}>Panel</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={`/organizer/${"event/" + id}`}>Event</Link>
        </Breadcrumb.Item>
        {origin === "schedule" ? (
          <Breadcrumb.Item>
            <Link style={state} to={`/organizer/${"event/schedule"}`}>
              Schedule
            </Link>
          </Breadcrumb.Item>
        ) : null}
        {origin === "groupstages" ? (
          <Breadcrumb.Item>
            <Link state={state} to={`/organizer/event/${id}/matches`}>
              Matches
            </Link>
          </Breadcrumb.Item>
        ) : null}
        {origin === "multiplayer" ? (
          <Fragment>
            {Matches?.rounds?.map((match, index) => {
              return (
                <Breadcrumb.Item
                  key={index}
                  className={currntgroupindex?.currentourndindex === index ? "activecrumb" : ""}
                  onClick={() =>
                    dispatch(
                      setcurrentgroupindex({ ...currntgroupindex, currentourndindex: index })
                    )
                  }>
                  <span style={{ cursor: "pointer" }}>Round {index + 1}</span>
                </Breadcrumb.Item>
              );
            })}
          </Fragment>
        ) : null}
        {origin === "history" ? (
          <Fragment>
            <Breadcrumb.Item>
              <Link state={state} to={`/organizer/event/${id}/knock-out-matches`}>
                Knockouts
              </Link>
            </Breadcrumb.Item>
            {state?.typeofmatch === "league" ? (
              <Breadcrumb.Item>
                <Link state={state} to={`/organizer/event/${id}/history`}>
                  History
                </Link>
              </Breadcrumb.Item>
            ) : null}
          </Fragment>
        ) : null}
        {/* knockouts */}
        {
          <Fragment>
            {origin === "knockouts" ? (
              <Fragment>
                {state?.typeofmatch === "league" ? (
                  <Breadcrumb.Item>
                    <Link state={state} to={`/organizer/event/${id}/history`}>
                      History
                    </Link>
                  </Breadcrumb.Item>
                ) : null}

                <Breadcrumb.Item>
                  <Link state={state} to={`/organizer/event/${id}/knock-out-matches`}>
                    Knockouts
                  </Link>
                </Breadcrumb.Item>
              </Fragment>
            ) : null}
          </Fragment>
        }
      </Breadcrumb>
    );
  };

  useEffect(() => {
    return () => {
      setTeam1(null);
      setTeam2(null);
    };
  }, [Rounds]);

  return { BracketsReplaceButtons, HistoryButtons };
};

export default useRoundsButtons;
