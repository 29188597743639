import React from "react";
import { Truncate } from "../../../../../../common/Text/Truncate";

export const QualifyingColumn = [
  {
    title: "Qualifiers",
    dataIndex: "Q",
    onCell: () => {
      return {
        style: { color: "var(--blue)" },
        key: new Date().getMilliseconds()
      };
    },

    render: (status) => <span key={new Date().getTime()}>{status ? "Q" : ""}</span>
  },
  {
    title: "Teams",
    dataIndex: "team",
    onCell: () => {
      return {
        style: { color: "var(--blue)" },
        key: new Date().getMilliseconds()
      };
    },

    render: (_, record) => (
      <Truncate text={record?.team?.name} className="tableteamname" key={new Date().getTime()} />
    )
  },
  {
    title: "Played",
    dataIndex: "played",
    key: "Played"
  },
  {
    title: "Win",
    dataIndex: "win",
    key: "Win"
  },
  {
    title: "Losses",
    dataIndex: "lose",
    key: "Losses"
  },
  {
    title: "Points",
    dataIndex: "points",
    key: "Point"
  }
];

export const Column = [
  {
    title: "Teams",
    dataIndex: "team",
    onCell: () => {
      return {
        style: { color: "var(--blue)" },
        key: new Date().getMilliseconds()
      };
    },

    render: (_, record) => (
      <Truncate text={record?.team?.name} className="tableteamname" key={new Date().getTime()} />
    )
  },
  {
    title: "Played",
    dataIndex: "played",
    key: "Played"
  },
  {
    title: "Win",
    dataIndex: "win",
    key: "Win"
  },
  {
    title: "Losses",
    dataIndex: "lose",
    key: "Losses"
  },
  {
    title: "Points",
    dataIndex: "points",
    key: "Point"
  }
];
