import React, { Fragment, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { Row, Col, Input, Badge, Menu, Dropdown, Button, Form } from "antd";
import Notification from "../../../common/Notification/NotificationDesktop";
import { FaSearch, FaBell, FaAngleDown, FaBars } from "react-icons/fa";
import { AiTwotoneMessage } from "react-icons/ai";
import SponsorDrawer from "../drawer/SponsorDrawer";
import { KEYS } from "../../../Config/Keys";
import FadeInImage from "../../../common/Images/FadeInImage";
import { useCurrentPage } from "../../../common/hooks/useCurrentPage";
import { fallbackprofile } from "../../../common/Utils";
import { Info } from "../../../common/Alerts/Message";
import useVariables from "../../../common/hooks/useVariables";
import {
  ALL_CAMPAIGNS,
  CREATE_CAMPAIGN,
  DRAFTED_CAMPAIGNS
} from "../../../Config/helpers/sponsorRoutes";

const SponsorNav = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { query, id } = useParams();
  const [text, settext] = useState(query);
  const [visible, setVisible] = useState(false);
  const [open, setOpen] = useState(false); // notification List
  const { url } = useCurrentPage();

  const { viewer, panelid } = useVariables();
  const notClickable = pathname === "/sponsor";

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };
  const u = useRef(null);
  const p = useRef(null);
  const e = useRef(null);

  var currentflag = "sponsor",
    currentid = viewer?.id;

  const UserItems = [
    {
      type: "divider"
    },
    notClickable
      ? null
      : {
          label: <Link to="/sponsor/timeline">My Profile</Link>,
          key: "profile"
        },
    {
      type: "divider"
    },
    notClickable
      ? null
      : {
          label: (
            <Link
              state={{ currentflag, currentid }}
              to={
                currentflag === "panel" && id
                  ? `/sponsor/panel/${panelid || id}/settings`
                  : "/sponsor/settings"
              }>
              Settings and Privacy
            </Link>
          ),
          key: "PrivacyandSettings"
        },
    {
      type: "divider"
    },
    {
      label: <Link to="/signin">Log out</Link>,
      key: "Logout",
      onClick: () => {
        localStorage.removeItem("ProfileID");
        localStorage.removeItem("userToken");
        localStorage.removeItem("userDetails");
        localStorage.removeItem("panel");
        localStorage.removeItem("viewer");
        localStorage.removeItem("CoverPic");
        localStorage.removeItem("ProfilerPic");
      }
    }
  ];

  const Panel = [
    {
      label: (
        <Link to="/sponsor/search/panels" state={"panels"}>
          Join Panel
        </Link>
      ),
      key: "paneljoin"
    },
    {
      type: "divider"
    },
    {
      label: <Link to="/sponsor/panels">My Panels</Link>,
      key: "panel"
    },
    {
      type: "divider"
    },
    {
      label: <Link to="/sponsor/createpanel">Create Panel</Link>,
      key: "createPanel"
    }
  ];
  const Campains = [
    {
      label: <Link to={ALL_CAMPAIGNS}>Campaigns</Link>,
      key: "allcampaigns"
    },
    {
      type: "divider"
    },
    {
      label: <Link to={DRAFTED_CAMPAIGNS}>Draft Campaigns</Link>,
      key: "draftcampaigns"
    },
    {
      type: "divider"
    },
    {
      label: <Link to={CREATE_CAMPAIGN}>Create Campaign</Link>,
      key: "createCampaigns"
    }
  ];

  useEffect(() => {
    window.addEventListener("load", onClose);
    window.addEventListener("resize", onClose);
    return () => {
      window.removeEventListener("load", onClose);
      window.removeEventListener("resize", onClose);
    };
  }, [pathname]);
  useEffect(() => {
    onClose();
  }, [pathname]);
  useEffect(() => {
    settext(query);
  }, [query]);

  // const formlength = (id === undefined || !id) && !panelid ? 8 : 6;

  const ALERT = () => Info("Create Profile First");
  return (
    <Fragment>
      <SponsorDrawer onClose={onClose} visible={visible} />
      <Row className="mobileNav">
        <Col lg={4} md={4}>
          <Link to="/">
            <img className="navlogo" height="40px" src="/images/logo.png" alt="img" />
          </Link>
        </Col>
        <Col className="mobileNav" span={2}>
          {notClickable ? (
            <div onClick={ALERT} className={`Nav_a`}>
              Home
            </div>
          ) : (
            <Link to="/sponsor/timeline" className={`Nav_a ${url === "home" && "active"}`}>
              Home
            </Link>
          )}
        </Col>
        {
          // (pathname.includes("panel") && (id !== undefined || panelid)) ?
          id !== undefined || panelid ? (
            <Col className="mobileNav" span={2} ref={e}>
              {notClickable ? (
                <div onClick={ALERT}>
                  <span className={`Nav_a`}>
                    Campaign <FaAngleDown style={{ verticalAlign: "middle" }} />
                  </span>
                </div>
              ) : (
                <Dropdown
                  placement="bottom"
                  getPopupContainer={() => e.current}
                  overlay={<Menu items={Campains} />}
                  trigger={["click"]}>
                  <a className={`ant-dropdown-link Nav_a ${url === "event" && "active"}`}>
                    Campaign <FaAngleDown style={{ verticalAlign: "middle" }} />
                  </a>
                </Dropdown>
              )}
            </Col>
          ) : (
            <Col className="mobileNav" span={2} ref={e}>
              {notClickable ? (
                <div onClick={ALERT}>
                  <span className={`Nav_a`}>
                    Campaign <FaAngleDown style={{ verticalAlign: "middle" }} />
                  </span>
                </div>
              ) : (
                <Dropdown
                  placement="bottom"
                  getPopupContainer={() => e.current}
                  overlay={<Menu items={Campains} />}
                  trigger={["click"]}>
                  <a className={`ant-dropdown-link Nav_a ${url === "event" && "active"}`}>
                    Campaign <FaAngleDown style={{ verticalAlign: "middle" }} />
                  </a>
                </Dropdown>
              )}
            </Col>
          )
        }
        <Col className="mobileNav" span={2} ref={p}>
          {notClickable ? (
            <div onClick={ALERT}>
              <span className={`Nav_a`}>
                Contracts <FaAngleDown style={{ verticalAlign: "middle" }} />
              </span>
            </div>
          ) : (
            <Dropdown
              placement="bottom"
              getPopupContainer={() => p.current}
              overlay={<Menu items={Panel} />}
              trigger={["click"]}>
              <a className={`ant-dropdown-link Nav_a ${url === "panel" && "active"}`}>
                Contracts <FaAngleDown style={{ verticalAlign: "middle" }} />
              </a>
            </Dropdown>
          )}
        </Col>
        <Col className="mobileNav" span={2}>
          {notClickable ? (
            <div onClick={ALERT} className={`Nav_a`}>
              Messages
            </div>
          ) : (
            <Link to="/sponsor/messages/chat" className={`Nav_a ${url === "messages" && "active"}`}>
              Messages
            </Link>
          )}
        </Col>
        <Col className="mobileNav" span={6}>
          <Form
            style={{ display: "flex", alignItems: "center", height: "100%" }}
            onFinish={() => {
              if (text?.trim().length > 0 && !notClickable) {
                navigate(`/sponsor/search/${text}`, { state: text });
              } else if (notClickable) ALERT();
            }}>
            <Form.Item
              style={{
                margin: "0px",
                width: "85%"
              }}
              name="search">
              <Input
                size="middle"
                className="navinput"
                defaultValue={query}
                value={text}
                onChange={(e) => settext(e.currentTarget.value)}
                placeholder="Search"
                prefix={
                  <FaSearch
                    onClick={() => {
                      if (text?.trim().length > 0 && !notClickable) {
                        navigate(`/sponsor/search/${text}`, { state: text });
                      } else if (notClickable) ALERT();
                    }}
                    style={{
                      cursor: "pointer",
                      color: "#8F8F8F",
                      marginRight: "10px",
                      width: "25px",
                      height: "16px"
                    }}
                  />
                }
              />
            </Form.Item>
          </Form>
        </Col>

        <Col className="mobileNav mobileNavFlex" span={6}>
          <Badge className="belldot" dot>
            <div style={{ position: "relative" }}>
              <FaBell
                style={{
                  marginLeft: "0",
                  fontSize: 22,
                  cursor: "pointer"
                }}
                onClick={() => {
                  if (notClickable) ALERT();
                  else setOpen((e) => !e);
                }}
              />
              {open && !notClickable ? <Notification open={open} setOpen={setOpen} /> : null}
            </div>
          </Badge>
          <div
            ref={u}
            style={{
              position: "relative",
              marginLeft: "10px",
              display: "flex",
              alignItems: "center"
            }}>
            <Dropdown
              getPopupContainer={() => u.current}
              overlayStyle={{ minWidth: "170px" }}
              placement="bottom"
              overlay={<Menu items={UserItems} />}
              trigger={["click"]}>
              <a
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  marginLeft: "10px",
                  overflowY: "hidden"
                }}
                className={`ant-dropdown-link Nav_a`}
                onClick={(e) => {
                  e.preventDefault();
                }}>
                <FadeInImage
                  type="Fadenavprofile"
                  fallaback={fallbackprofile}
                  src={KEYS.API + "/" + viewer?.profile}
                />
                <span
                  style={{
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    maxWidth: "150px",
                    display: "inline-block",
                    userSelect: "none",
                    fontSize: "15px",
                    fontWeight: "600",
                    marginLeft: "10px"
                  }}>
                  {viewer?.name}
                </span>
                <span>
                  <FaAngleDown
                    style={{
                      marginLeft: "5px",
                      verticalAlign: "middle"
                    }}
                  />
                </span>
              </a>
            </Dropdown>
          </div>
        </Col>
      </Row>
      <div className="mobileNavhide">
        <Link to="/">
          <img className="navlogo" height="40px" src="/images/logo.png" alt="logo" />
        </Link>

        <Row align="center" style={{ padding: "0" }}>
          <Row
            style={{
              gap: "15px"
            }}>
            <Col
              className="drawerpadding"
              style={{
                paddingInline: "0",
                paddingTop: "0",
                position: "relative",
                top: "6px"
              }}>
              {notClickable ? (
                <div></div>
              ) : (
                <Link to="/sponsor/messages/chat">
                  <AiTwotoneMessage
                    style={{
                      marginLeft: "0",
                      fontSize: 17.5,
                      cursor: "pointer"
                    }}
                  />
                </Link>
              )}
            </Col>
            <Col
              className="drawerpadding"
              style={{
                paddingInline: "0",
                paddingTop: "0",
                position: "relative",
                top: "6px"
              }}>
              {" "}
              {notClickable ? (
                <div onClick={ALERT}>{}</div>
              ) : (
                <Link to={"/sponsor/notifications"}>
                  <Badge dot>
                    <FaBell
                      style={{
                        marginLeft: "0",
                        fontSize: 17.5,
                        cursor: "pointer"
                      }}
                    />
                  </Badge>
                </Link>
              )}
            </Col>
          </Row>

          <Button className="drawertogglebutton" type="primary" onClick={showDrawer}>
            <FaBars style={{ verticalAlign: "middle" }} />
          </Button>
        </Row>
      </div>
    </Fragment>
  );
};

export default SponsorNav;
