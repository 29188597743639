import { Typography, List, Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { PlayerGetFollowersAPI } from "../../../Config/API/player";
import { OrganizerGetFollowersAPI } from "../../../Config/API/organizer";
import { KEYS } from "../../../Config/Keys";
import FadeInImage from "../../../common/Images/FadeInImage";
import { fallbackprofile } from "../../../common/Utils";
import { GetInboxData } from "../../../Config/API/chat";
import moment from "moment";
import { ROLES } from "../../../Config/helpers/constants";

const { Title } = Typography;

const LeftBar = ({ active, setactive, socket, setopenChat, setRoom, setList, list }) => {
  const location = useLocation();
  const [initLoading, setInitLoading] = useState(true);
  const [listFilter, setListFilter] = useState([]);
  const profileID = localStorage.getItem("ProfileID");
  const role = JSON.parse(localStorage.getItem("userDetails"))?.role.toLowerCase();
  console.log("list", list);

  useEffect(() => {
    fetchAllDATA();
  }, []);
  useEffect(() => {
    console.log("location", location.state);
    let chatData = location.state;
    if (chatData) {
      if (localStorage.getItem("room") === null) {
        localStorage.setItem("room", profileID + "@!@!2!@!@" + chatData.id);
      }
    }
  }, [location]);

  const getFollowersData = async () => {
    var res = role === "player" ? await PlayerGetFollowersAPI() : await OrganizerGetFollowersAPI();
    var data = await res.json();

    const filter = data?.result?.filter((e) => e?.organizer || e?.player);

    setListFilter(filter);
  };
  const getInboxData = async () => {
    var res = await GetInboxData(profileID);
    var data = await res.json();
    console.log("inbox");
    console.log(data);
    if (data.type === "success") {
      let chatData = location.state;
      console.log("chat data", data?.result);

      if (chatData) {
        var localDataProps = {
          sender: { [role]: { _id: profileID } },
          receiver: {
            [chatData.role]: {
              _id: chatData.id,
              name: chatData.name,
              profilepic: chatData.profilepic
            }
          },
          room: profileID + "@!@!2!@!@" + chatData.id,
          messages: []
        };
        const find = data.result?.find(
          (a) =>
            a?.sender?.organizer?._id === chatData.id ||
            a?.sender?.player?._id === chatData.id ||
            a?.sender?.panel?._id === chatData.id ||
            a?.sender?.sponsor?._id === chatData.id ||
            a?.receiver?.organizer?._id === chatData.id ||
            a?.receiver?.player?._id === chatData.id ||
            a?.receiver?.panel?._id === chatData.id ||
            a?.receiver?.sponsor?._id === chatData.id
        );
        if (find) {
          setList(data.result);
          dataSet(find, 1);
        } else {
          setList([localDataProps, ...data.result]);
          dataSet(localDataProps, 1);
        }
      } else {
        setList(data.result);
      }
    } else {
      console.log("Error");
    }
  };
  const fetchAllDATA = async () => {
    await getInboxData();
    if (role === ROLES.player || role === ROLES.organizer) {
      await getFollowersData();
    }

    setInitLoading(false);
  };

  const dataSet = (item) => {
    // setactive("chat" + i);
    console.log(item);
    console.log("item");

    let roomName;
    if (
      item.sender.organizer?._id === profileID ||
      item.sender.player?._id === profileID ||
      item?.sender?.sponsor?._id === profileID
    ) {
      roomName = item.room;
      setactive(
        item.receiver.player?._id || item.receiver.organizer?._id || item?.receiver?.sponsor?._id
      );

      setopenChat(item);
      // sort(item, item?.organizer?._id, list)
    } else {
      roomName = item.room;

      setactive(
        item.sender.player?._id || item.sender.organizer?._id || item?.sender?.sponsor?._id
      );
      setopenChat(item);
      // sort(item, item?.player?._id, list)
    }
    setRoom(roomName);
    localStorage.setItem("room", roomName);
    socket.emit("room", { room: roomName });
    socket.on("joined", (data) => {
      console.log("i have joined", data.room);
    });
  };

  // const sort = (item, id, list) => {
  //   const filter = list?.filter((e) =>
  //     e?.organizer ? e?.organizer?._id !== id : e?.player?._id !== id
  //   );
  //   filter.unshift(item);
  //   setList(filter);
  // };

  const onSearch = (value) => {
    console.log("search:", value);
  };
  return (
    <div className="ChatRoomLeftBar flex">
      <Form name="basic" className="SearchForm flex1">
        <Form.Item name="search" style={{ margin: "0 10px 30px 10px" }}>
          <Select
            className="ChatSearchBar"
            placeholder="Search Organizers and Players"
            allowClear
            showArrow={false}
            onDeselect={() => setactive(null)}
            dropdownClassName="ChatSearchBarDropDown"
            value={active ? active : undefined}
            onChange={(e) => {
              console.log(e);
              console.log("listFilter", listFilter);
              if (active !== e) {
                // setactive(e);

                const find_user_role = listFilter.find(
                  (a) => a?.organizer?._id == e || a?.player?._id == e || a?.sponsor?._id
                );
                console.log(find_user_role);
                const find = list?.find(
                  (a) =>
                    a?.sender?.organizer?._id === e ||
                    a?.sender?.player?._id === e ||
                    a?.sender?.sponsor?._id === e ||
                    a?.receiver?.organizer?._id === e ||
                    a?.receiver?.player?._id === e ||
                    a?.receiver?.sponsor?._id === e
                );
                let localData;
                if (!find) {
                  setactive(e);
                  localData = {
                    sender: { [role]: { _id: profileID } },
                    receiver: {
                      [find_user_role.player ? "player" : "organizer"]: {
                        _id: e,
                        name:
                          find_user_role.player?.name ||
                          find_user_role.organizer?.name ||
                          find_user_role?.sponsor?.name,
                        profilepic:
                          find_user_role.player?.profilepic ||
                          find_user_role.organizer?.profilepic ||
                          find_user_role?.sponsor?.profilepic
                      }
                    },
                    room: profileID + "@!@!2!@!@" + e,
                    messages: []
                  };
                  setList((list) => [localData, ...list]);
                  dataSet(localData, 1);
                } else {
                  dataSet(find, 1);
                }
                // const findList = list.find((x) => x._id == find._id);
                console.log(find);
                // setopenChat(find);
                // if (!findList) {
                //   setList((list) => [...list, find]);
                // }
                // dataSet(find, 1);
                // console.log(find);
                // sort(find, e, list)
              }
            }}
            // suffixIcon={
            //   <FiSearch size={"large"} style={{ color: "var(--blue)" }} />
            // }
            optionFilterProp="children"
            onSearch={onSearch}
            showSearch
            filterOption={(input, option) =>
              (option?.children?.props?.children[1].props?.children ?? "")
                .toLowerCase()
                .includes(input.toLowerCase())
            }>
            {listFilter?.map(
              (e, i) =>
                (e?.player || e?.organizer) && (
                  <option value={e?.player?._id || e?.organizer?._id || e?.sponsor?._id} key={i}>
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: ".5rem"
                      }}>
                      <FadeInImage
                        type={"Fadepostprofile"}
                        fallaback={fallbackprofile}
                        src={
                          KEYS.API + "/" + e?.player?.profilepic ||
                          e?.organizer?.profilepic ||
                          e?.sponsor?.profilepic
                        }
                      />
                      <h4 style={{ marginBottom: "0" }}>
                        {e?.player?.name || e?.organizer?.name || e?.sponsor?.name}
                      </h4>
                    </span>
                  </option>
                )
            )}
          </Select>
        </Form.Item>
      </Form>
      <List
        loading={initLoading}
        itemLayout="horizontal"
        className="ChatList flex9"
        dataSource={list}
        renderItem={(item, i) => (
          <>
            {item.sender.organizer?._id === profileID ||
            item.sender.player?._id === profileID ||
            item.sender?.sponsor?._id === profileID ? (
              <List.Item
                id={
                  item?.receiver?.player?._id ||
                  item?.receiver?.organizer?._id ||
                  item?.receiver?.sponsor?._id
                }
                key={"chat" + i}
                className={`chatItem ${
                  active ===
                  (item?.receiver?.player?._id ||
                    item?.receiver?.organizer?._id ||
                    item?.receiver?.sponsor?._id)
                    ? "activeChat"
                    : undefined
                } `}
                onClick={() => {
                  dataSet(item, 1);
                }}>
                <List.Item.Meta
                  className="imageAndName"
                  avatar={
                    item.receiver.organizer ? (
                      <FadeInImage
                        type={"s40"}
                        fallaback={fallbackprofile}
                        src={KEYS.API + "/" + item.receiver.organizer.profilepic}
                      />
                    ) : item.receiver.player || item.receiver?.sponsor ? (
                      <FadeInImage
                        type={"s40"}
                        fallaback={fallbackprofile}
                        src={
                          KEYS.API + "/" + item?.receiver?.player
                            ? item.receiver.player.profilepic
                            : item.receiver?.sponsor.profilepic
                        }
                      />
                    ) : null
                  }
                  title={
                    <Typography>
                      <Title level={5} ellipsis style={{ marginBottom: "0" }}>
                        {item.receiver.organizer ? item.receiver.organizer.name : null}
                        {item.receiver.player ? item.receiver.player.name : null}
                        {item.receiver.sponsor ? item.receiver.sponsor.name : null}
                      </Title>
                    </Typography>
                  }
                />
                {item.messages.length !== 0 ? (
                  <div className="dateAndtime">
                    {moment(item.messages[item.messages.length - 1].createdAt).fromNow()}
                  </div>
                ) : (
                  <div className="dateAndtime">{"-"}</div>
                )}
              </List.Item>
            ) : (
              <List.Item
                id={
                  item?.sender?.player?._id ||
                  item?.sender?.organizer?._id ||
                  item?.sender?.sponsor?._id
                }
                key={"chat" + i}
                className={`chatItem ${
                  active ===
                  (item?.sender?.player?._id ||
                    item?.sender?.organizer?._id ||
                    item?.sender?.sponsor?._id)
                    ? "activeChat"
                    : undefined
                } `}
                onClick={() => {
                  dataSet(item, 1);
                }}>
                <List.Item.Meta
                  className="imageAndName"
                  avatar={
                    item.sender.organizer ? (
                      <FadeInImage
                        type={"s40"}
                        fallaback={fallbackprofile}
                        src={KEYS.API + "/" + item.sender.organizer.profilepic}
                      />
                    ) : item.sender.player ? (
                      <FadeInImage
                        type={"s40"}
                        fallaback={fallbackprofile}
                        src={KEYS.API + "/" + item.sender.player.profilepic}
                      />
                    ) : item.sender.sponsor ? (
                      <FadeInImage
                        type={"s40"}
                        fallaback={fallbackprofile}
                        src={KEYS.API + "/" + item.sender.sponsor.profilepic}
                      />
                    ) : null
                  }
                  title={
                    <Typography>
                      <Title level={5} ellipsis style={{ marginBottom: "0" }}>
                        {item.sender.organizer ? item.sender.organizer.name : null}
                        {item.sender.player ? item.sender.player.name : null}
                        {item.sender.sponsor ? item.sender.sponsor.name : null}
                      </Title>
                    </Typography>
                  }
                />
                {item.messages.length !== 0 ? (
                  <div className="dateAndtime">
                    {moment(item.messages[item.messages.length - 1].createdAt).fromNow()}
                  </div>
                ) : (
                  <div className="dateAndtime">{"-"}</div>
                )}
              </List.Item>
            )}
          </>
        )}
      />
    </div>
  );
};

export default LeftBar;
