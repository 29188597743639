import { useState, useEffect } from "react";
import { GetPosts } from "../../Config/API/posts";
import Swal from "sweetalert2";
import { isLoading, PROFILE } from "../../Store/Features/ProfileSlice";
import { useSelector } from "react-redux";
import useVariables from "./useVariables";

export function useFetchAllPosts(role) {
  const [fetchloading, setfetchloading] = useState(true);
  const [POSTS, setPOSTS] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const loading = useSelector(isLoading);
  const profile = useSelector(PROFILE);
  const { viewer, PID } = useVariables();
  const [fetchTime, setFetchTime] = useState(500); // Default value of 500ms

  const GetAllPosts = async () => {
    try {
      var user = "";
      if (role === "player" || role === "organizer") user = PID;
      else user = profile?._id;
      var flag = role;

      const startTime = performance.now(); // Start timing

      const res = await GetPosts(user, flag, page);

      const endTime = performance.now(); // End timing
      setFetchTime(endTime - startTime); // Set fetch time

      if (!res.ok) {
        throw new Error(res.statusText + " - " + res.url);
      }
      const data = await res.json();
      setfetchloading(false);
      if (data.type !== "success") {
        Swal.fire({
          icon: "error",
          title: data?.result
        });
      } else {
        setPOSTS(data?.result || []);
        setTotalPages(data?.metaData?.totalPages || 1);
      }
      setfetchloading(false);
    } catch (e) {
      console.log(e.name === "AbortError" ? "FetchPostsRequest canceled" : e.message);
      setfetchloading(false);
    }
  };
  useEffect(() => {
    const controller = new AbortController();
    if (viewer?.id && !loading) {
      GetAllPosts(controller);
    }
    return () => {
      controller.abort();
      setfetchloading(null);
      setPOSTS([]);
    };
  }, [viewer?.id, viewer?.flag, page]);

  return {
    fetchloading,
    POSTS,
    setPOSTS,
    setPage,
    totalPages,
    page,
    fetchTime // Return fetch time
  };
}
