import {
  Avatar,
  Button,
  Checkbox,
  Drawer,
  Input,
  List,
  Pagination,
  Space,
  Switch,
  Typography
} from "antd";
const { Text } = Typography;
import React, { useEffect, useState } from "react";
import { ROLES } from "../../Config/helpers/constants";
import { error, success } from "../../common/Alerts/Message";
import { GetAllSearchResults } from "../../Config/API/search";
import TopBar from "../SearchPage/TopBar/TopBar";
import { BiMoney } from "react-icons/bi";
import { KEYS } from "../../Config/Keys";
import { TransferTokensToOtherWallet } from "../../Config/API/Wallets";
import { GiCancel } from "react-icons/gi";
import Beneficiary from "./Beneficiary";

function TransferTokens({
  open,
  setOpen,
  wallet,
  getWallet,
  getUserTransactionHistory,
  userType,
  userId
}) {
  const [searchTerm, setSearchTerm] = useState("");
  const [type, setType] = useState(ROLES.player);
  const [data, setData] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [amount, setAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [addBeneficiary, setAddBeneficiary] = useState(false);
  const [transferLoading, setTransferLoading] = useState(false);
  const [showBeneficiary, setShowBeneficiary] = useState(true);

  //   pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const limit = 15;

  const getSearchData = async (page) => {
    try {
      if (searchTerm !== "" || searchTerm !== null) {
        setLoading(true);
        const newOffset = (page - 1) * limit;

        const response = await GetAllSearchResults(
          { text: searchTerm, searchFor: type },
          newOffset,
          limit
        );
        const data = await response.json();
        if (data?.type === "failure") {
          error(data.result);
        } else if (data?.type === "success") {
          // remove current user from data if exists
          const filteredData =
            data?.searchData?.length > 0
              ? data?.searchData?.filter((users) => users?._id !== userId)
              : [];
          setData(filteredData);
          setTotalPages(Math.ceil(data?.totalCount / limit));
          setCurrentPage(page);
        }
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      error(e.message);
    }
  };

  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  const handleTransfer = async () => {
    setTransferLoading(true);
    if (amount > wallet?.balance) {
      error("Insufficient funds");
      setTransferLoading(false);
    } else if (amount > 0 && selectedUser?.walletId) {
      const response = await TransferTokensToOtherWallet(
        selectedUser?.walletId,
        amount,
        userType,
        addBeneficiary,
        userId
      );

      const data = await response.json();

      if (data?.type === "success") {
        success("Transaction initiated Successfully");
        // get latest transactions again
        getWallet();
        getUserTransactionHistory();
        setAddBeneficiary(false);
        setTransferLoading(false);
        setOpen(false);
      } else {
        error(data?.result?.message || data?.result?.error || data?.result);
        setTransferLoading(false);
      }
    }
  };

  const goToPage = (pageNumber) => {
    getSearchData(pageNumber);
  };

  useEffect(() => {
    getSearchData(1);
  }, [type]);

  return (
    <>
      <Drawer
        title="Transfer Tokens"
        placement="right"
        maskClosable={false}
        closable={true}
        onClose={() => setOpen(false)}
        open={open}
        width="100%">
        {!showBeneficiary && (
          <Input.Search
            placeholder="Search for a user"
            value={searchTerm}
            onChange={(e) => handleSearch(e.target.value)}
            style={{ marginBottom: "1rem" }}
            onSearch={getSearchData}
          />
        )}
        {showBeneficiary ? null : <TopBar setactive={setType} active={type} forWallet={true} />}
        <Switch
          title="Show Beneficiary"
          checkedChildren={<span>Beneficiary</span>}
          unCheckedChildren={<span></span>}
          onChange={(e) => {
            setShowBeneficiary(e);
          }}
          checked={showBeneficiary}
        />

        {!selectedUser && !showBeneficiary && (
          <>
            <List
              loading={loading}
              dataSource={data}
              renderItem={(user) => (
                <List.Item
                  onClick={
                    user?.walletId
                      ? () => setSelectedUser(user)
                      : () => error("Error selecting this user")
                  }
                  style={{
                    cursor: "pointer",
                    backgroundColor: user === selectedUser ? "#f0f0f0" : "transparent",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                  }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {/* Avatar at the start */}
                    <Avatar src={user?.profilepic ? KEYS.API + `/${user?.profilepic}` : null} />
                    {/* User name */}
                    <span style={{ marginLeft: "1rem" }}>
                      {user.name}{" "}
                      {user?.username && (
                        <span style={{ fontSize: "12px", color: "gray" }}>
                          ( {user?.username} )
                        </span>
                      )}{" "}
                    </span>
                  </div>
                  {/* Icon at the end */}
                  {user?.walletId && <BiMoney />}
                </List.Item>
              )}
              style={{ marginBottom: "1rem", maxWidth: "400px", overflowX: "auto" }} // Set max width and enable horizontal scroll
            />
            <Pagination
              current={currentPage}
              total={totalPages * limit}
              pageSize={limit}
              showSizeChanger={false}
              onChange={goToPage}
            />
          </>
        )}
        {showBeneficiary && !selectedUser && (
          <Beneficiary beneficiaries={wallet?.beneficiary} setSelectedUser={setSelectedUser} />
        )}
        {selectedUser && (
          <div className="mt-2 mb-2">
            <Text type="success" strong>
              Selected User:
            </Text>{" "}
            {selectedUser?.name} <GiCancel onClick={() => setSelectedUser(null)} />
          </div>
        )}
        {selectedUser && (
          <Space>
            <Input
              className="mt-2"
              placeholder="Amount"
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              style={{ marginBottom: "1rem" }}
            />
            {!selectedUser?.isBeneficiary && (
              <Checkbox
                type="checkbox"
                name="addBeneficiary"
                value={addBeneficiary}
                checked={addBeneficiary}
                onChange={(e) => setAddBeneficiary(e?.target?.checked)}>
                Add as Beneficiary
              </Checkbox>
            )}
          </Space>
        )}
        <br />

        <Button
          loading={transferLoading}
          type="primary"
          disabled={amount === 0 || !amount || transferLoading || !selectedUser}
          onClick={handleTransfer}>
          Transfer
        </Button>
        <div style={{ marginTop: "1rem" }}>
          <Text strong>Your Balance:</Text> {wallet?.balance} FlexTokens
        </div>
      </Drawer>
    </>
  );
}

export default TransferTokens;
