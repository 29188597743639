import React from "react";
import { Avatar } from "antd";
import moment from "moment";
import { useParams } from "react-router-dom";
import { KEYS } from "../../../../Config/Keys";
function Sender({ item, openChat }) {
  const { viewer } = useParams();
  const profileID = viewer?.id;

  return (
    <div className="MessageSender">
      {openChat.sender?.player?._id === profileID ||
      openChat?.sender?.organizer?._id == profileID ||
      openChat.sender?.sponsor?._id === profileID ? (
        <Avatar
          size={"large"}
          src={
            KEYS.API +
            "/" +
            (openChat.receiver.player?.profilepic ||
              openChat.receiver.organizer?.profilepic ||
              openChat.receiver?.sponsor?.profilepic)
          }
          style={{ minWidth: "40px" }}
        />
      ) : (
        <Avatar
          size={"large"}
          src={
            KEYS.API +
            "/" +
            (openChat.sender.player?.profilepic ||
              openChat.sender.organizer?.profilepic ||
              openChat.sender?.sponsor?.profilepic)
          }
          style={{ minWidth: "40px" }}
        />
      )}

      <div className="MessageSenderTextWrapper">
        <span className="time">{moment(item?.createdAt).fromNow()}</span>
        <p className="MessagSenderText"> {item.text} </p>
      </div>
    </div>
  );
}

export default Sender;
