import moment from "moment";
import React, { Fragment } from "react";
import { FaCalendarAlt } from "react-icons/fa";
import MatchesCard from "../../../cards";

function StaticFixtures({ items, sortedpoints }) {
  const getdate = (item) =>
    moment(item?.start).format("MMM") + " " + moment(item?.start)?.date() + " AT ";

  const gettime = (item) => {
    let time = "";
    let i = String(item?.start);
    let temp = i.slice(i.indexOf("T") + 1, i.indexOf("T") + 6);
    time = temp + " - ";
    i = String(item?.end);
    temp = i.slice(i.indexOf("T") + 1, i.indexOf("T") + 6);
    return (time += temp);
  };

  return (
    <Fragment>
      {items?.map((item, index) => (
        <Fragment key={index}>
          <p
            style={{
              fontSize: "12px",
              color: "red",
              margin: "15px 0px 0px 5px"
            }}>
            <FaCalendarAlt />
            <span
              style={{
                fontWeight: "550",
                margin: "0 .3rem 0 .7rem"
              }}>
              {getdate(item)}
            </span>
            <span style={{ color: "#129B30", fontWeight: "550" }}>
              <span>{gettime(item)}</span>
            </span>
          </p>

          <MatchesCard item={item} index={index} sortedpoints={sortedpoints} />
        </Fragment>
      ))}
    </Fragment>
  );
}

export default StaticFixtures;
