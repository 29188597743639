import React from "react";
import { Col, Row } from "antd";
import ActiveCard from "../cards/ActiveCard";

const ActiveMembers = ({ panel, setpanel, status, object }) => {
  const arr = panel?.members?.filter((e) => e?.active);
  return (
    <Row>
      {arr?.map((e, i) => {
        return e?.organizer !== null ? (
          <Col
            className="pomemberShadow TPcard"
            key={i}
            xxl={6}
            xl={8}
            lg={{ span: 12 }}
            md={{ span: 12 }}
            sm={24}
            xs={24}
            style={{
              display: "flex",
              alignItems: "stretch"
            }}>
            <ActiveCard
              object={object}
              panel={e}
              memberParent={panel?._id}
              setpanel={setpanel}
              status={status}
            />
          </Col>
        ) : null;
      })}
    </Row>
  );
};

export default ActiveMembers;
