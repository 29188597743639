import React, { Fragment, useEffect, useState } from "react";
import { Col, Row } from "antd";
import RightBar from "../../../../common/righbar";
import { SideBar } from "../../../../common/sidebar";
import MarkedEventCard from "../../../../common/cards/Events/MarkedEvents";
import { GetOrganizerMarkedEvents } from "../../../../Config/API/events";
import { error } from "../../../../common/Alerts/Message";
import useVariables from "../../../../common/hooks/useVariables";
import { useSelector } from "react-redux";
import { PROFILE } from "../../../../Store/Features/ProfileSlice";
import Loader from "../../../../common/cards/Loader";
import NoData from "../../../../common/cards/noData";

const MarkedEvents = () => {
  const ID = useSelector(PROFILE);
  const panelid = localStorage.getItem("panel");
  const [Events, setEvents] = useState([]);
  const [loading, setloading] = useState(false);
  const { viewer } = useVariables();
  const getallevents = async () => {
    try {
      setloading(true);
      const response = await GetOrganizerMarkedEvents({
        organizerId: viewer?.id
      });
      const data = await response.json();

      console.log(data);
      if (data.type === "success") {
        setEvents(data?.result);
      }
      setloading(false);
    } catch (e) {
      error(e || "An Error Occured");
      setloading(false);
    }
  };
  useEffect(() => {
    if (ID?._id || panelid) {
      getallevents();
    }
    return () => setEvents([]);
  }, [ID?._id, panelid]);

  return (
    <Fragment>
      {loading && <Loader fullpage loading={"white"} />}
      <Row justify="space-between" className="rowPadding">
        <SideBar description experience />
        <Col
          xs={{ span: 24, order: 3 }}
          sm={{ span: 24, order: 3 }}
          md={{ span: 24, order: 3 }}
          lg={{ span: 11, order: 2 }}>
          <Row className="  mobileto">
            {/* <Col span={24}>
                            <h1
                                style={{
                                    fontWeight: 600,
                                    fontSize: 25,
                                    marginTop: "-5px",
                                }}>Marked Events</h1>
                        </Col> */}
            {Events?.map((e, i) => (
              <MarkedEventCard key={i} data={e} />
            ))}
            {Events?.length === 0 ? <NoData noEvent /> : null}
          </Row>
        </Col>
        <RightBar />
      </Row>
    </Fragment>
  );
};

export default MarkedEvents;
