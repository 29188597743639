import Swal from "sweetalert2";
import { error, success } from "../../../Alerts/Message";
import { EditComment, EditReply, DeleteComment, DeleteReply } from "../../../../Config/API/posts";

export const editComment = async (
  e,
  id,
  checkrole,
  postId,
  setloading,
  setPOSTs,
  current,
  userid
) => {
  try {
    let values = {
      userRole: checkrole?.role,
      user: checkrole?.id,
      id,
      comment: e,
      postId
    };
    let flag = "";
    if (current === "panel") {
      flag = "panelTimeline";
      values["paneluserid"] = userid;
    } else if (current === "team") {
      flag = "teamTimeline";
      values["teamuserid"] = userid;
    } else if (current === "player") {
      flag = "playerTimeline";
      values["playeruserId"] = userid;
    } else if (current === "organizer") {
      flag = "organizerTimeline";
      values["organizeruserId"] = userid;
    }
    values["flag"] = flag;

    console.log(values, current);

    if (e !== "" && id && checkrole.id && checkrole?.role) {
      setloading(id + 1);
      const res = await EditComment(values);
      const data = await res.json();
      if (data.type === "success") {
        setPOSTs([...data.data]);
      }
      console.log(data);
      setloading(false);
    } else {
      Swal.fire({
        icon: "error",
        title: "Field can't be empty"
      });
    }
  } catch (e) {
    console.log(e);
    error(e.message);
    setloading(false);
  }
};
export const editReply = async (
  e,
  id,
  checkrole,
  postId,
  setloading,
  setPOSTs,
  current,
  userid
) => {
  try {
    let values = {
      userRole: checkrole?.role,
      user: checkrole?.id,
      id,
      comment: e,
      postId
    };
    let flag = "";
    if (current === "panel") {
      flag = "panelTimeline";
      values["paneluserid"] = userid;
    } else if (current === "team") {
      flag = "teamTimeline";
      values["teamuserid"] = userid;
    } else if (current === "player") {
      flag = "playerTimeline";
      values["playeruserId"] = userid;
    } else if (current === "organizer") {
      flag = "organizerTimeline";
      values["organizeruserId"] = userid;
    }
    values["flag"] = flag;

    if (e !== "" && id && checkrole.id && checkrole?.role) {
      setloading(id + 1);
      const res = await EditReply(values);
      const data = await res.json();
      console.log(data);
      if (data.type === "success") {
        setPOSTs([...data.data]);
      }
      setloading(false);
    } else {
      Swal.fire({
        icon: "error",
        title: "On of the fields is missing"
      });
    }
  } catch (e) {
    setloading(false);
    console.log(e);
    error(e.message);
  }
};

export const delteReply = async (id, checkrole, postId, setPOSTs, current, userid) => {
  try {
    let values = { id, postId, user: checkrole?.id };

    let flag = "";
    if (current === "panel") {
      flag = "panelTimeline";
      values["paneluserid"] = userid;
    } else if (current === "team") {
      flag = "teamTimeline";
      values["teamuserid"] = userid;
    } else if (current === "player") {
      flag = "playerTimeline";
      values["playeruserId"] = userid;
    } else if (current === "organizer") {
      flag = "organizerTimeline";
      values["organizeruserId"] = userid;
    }
    values["flag"] = flag;
    if (id && postId) {
      const res = await DeleteReply(values);
      const data = await res.json();
      console.log(data);
      if (data.type === "success") {
        setPOSTs([...data.data]);
        success("Reply deleted successfully...");
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "On of the fields are missing"
      });
    }
  } catch (e) {
    console.log(e);
    error(e.message);
  }
};
export const deleteComment = async (id, checkrole, postId, setPOSTs, current, userid) => {
  try {
    if (!checkrole?.role) return error("No role found");

    let values = { id, postId, user: checkrole?.id };
    let flag = "";
    if (current === "panel") {
      flag = "panelTimeline";
      values["paneluserid"] = userid;
    } else if (current === "team") {
      flag = "teamTimeline";
      values["teamuserid"] = userid;
    } else if (current === "player") {
      flag = "playerTimeline";
      values["playeruserId"] = userid;
    } else if (current === "organizer") {
      flag = "organizerTimeline";
      values["organizeruserId"] = userid;
    }
    values["flag"] = flag;

    if (id && postId) {
      const res = await DeleteComment(values);
      const data = await res.json();
      if (data.type === "success") {
        setPOSTs([...data.data]);
        success("Comment deleted successfully...");
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Field can't be empty"
      });
    }
  } catch (e) {
    console.log(e);
    error(e.message);
  }
};
