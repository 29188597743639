import React, { Fragment } from "react";
import { Col, Grid } from "antd";
import Description from "./Description";
import Experience from "./Experience";
import Awards from "./Awards";
import Location from "./Location";
import About from "./About";
import RatingPanel from "./RatingPanel";

import ExperienceAccordian from "./Accordians/ExperienceAccordian";
import AboutAccordian from "./Accordians/AboutAccordian";
import DescriptionAccordian from "./Accordians/DescriptionAccordiant";
import RatingPanelAccordian from "./Accordians/RatingPanelAccordian";
import LocaitonAccordian from "./Accordians/LocationAccordian";
import AwardsAccordian from "./Accordians/AwardsAccordian";
import { useSelector } from "react-redux";
import { PROFILE } from "../../Store/Features/ProfileSlice";
import { EVENT } from "../../Store/Features/EventSlice";
import { ROLES } from "../../Config/helpers/constants";
import { useLocation } from "react-router-dom";

function SideBar({
  about,
  description,
  ratingPanel,
  experience,
  location,
  md,
  awards = true,
  contracts,
  big,
  type,
  data
}) {
  var currentProfile = useSelector(PROFILE);
  var event = useSelector(EVENT);
  const des = JSON.parse(localStorage.getItem("viewer"))?.description;
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const { pathname } = useLocation();

  var currentPageFor = pathname.includes("view/panel") ? ROLES.panel : null;

  return (
    <Fragment>
      <Col className="scroll" xs={24} sm={24} md={24} lg={big ? 24 : md ? 9 : 7} xl={big ? 24 : 7}>
        {description && (
          <Fragment>
            <div className="d-md-block d-none">
              <DescriptionAccordian description={currentProfile?.description || des} />
            </div>
            <div className="d-md-none">
              <Description description={currentProfile?.description || des} />
            </div>
          </Fragment>
        )}
        {/* About */}
        {about && (
          <Fragment>
            <div className="d-md-block d-none">
              <AboutAccordian data={event} />
            </div>
            <div className="d-md-none">
              <About data={event} />
            </div>
          </Fragment>
        )}
        {/* Rating Panel */}
        {ratingPanel && (
          <Fragment>
            {screens.md && (
              <div className="d-md-block d-none">
                <RatingPanelAccordian
                  Rating={currentProfile?.rating?.averageRating || 0}
                  id={currentProfile?._id}
                  type={currentPageFor}
                />
              </div>
            )}
            <div className="d-md-none">
              <RatingPanel
                Rating={currentProfile?.rating?.averageRating || 0}
                id={currentProfile?._id}
                type={currentPageFor}
              />
            </div>
          </Fragment>
        )}
        {experience ? (
          <Fragment>
            <div className="d-md-block d-none">
              <ExperienceAccordian />
            </div>
            <div className="d-md-none">
              <Experience />
            </div>
          </Fragment>
        ) : null}

        {contracts && contracts}

        {/* Awards */}
        {awards && (
          <Fragment>
            <div className="d-md-block d-none">
              <AwardsAccordian type={type} data={type === "event" ? data : null} />
            </div>
            <div className="d-md-none">
              <Awards type={type} data={type === "event" ? data : null} />
            </div>
          </Fragment>
        )}
        {/* Location */}
        {location ? (
          <Fragment>
            <div className="d-md-block d-none">
              <LocaitonAccordian data={event?.location ? event : currentProfile} />
            </div>
            <div className="d-md-none">
              <Location data={event?.location ? event : currentProfile} />
            </div>
          </Fragment>
        ) : null}
      </Col>
    </Fragment>
  );
}

export default SideBar;
