import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import useVariables from "../../../../common/hooks/useVariables";

function AuthEvent() {
  const { FLAG, PID, panelid } = useVariables();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname.includes("organizer") || pathname.includes("panel")) {
      if (!FLAG || !PID || !panelid) navigate("/organizer/panels", { replace: true });
    } else if (!FLAG || !PID) {
      navigate("/player/events", { replace: true });
    }
  }, [FLAG, PID, navigate, panelid, pathname]);
  if (FLAG && PID) return <Outlet />;
  return null;
}

export default AuthEvent;
