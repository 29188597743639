import React, { Fragment, useState } from "react";
import { Row, Button } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { error, Info, success } from "../../../../../../../common/Alerts/Message";
import { MultiplayerSchedule } from "../../../../../../../Config/API/multiplayerSchedule";
import { useEffect } from "react";
import Loader from "../../../../../../../common/cards/Loader";
import useRoundsButtons from "../../KO/useRoundsUtility/useRoundsButtons";
import { GAMES_MODES } from "../../../../../../../Config/helpers/constants";
import MultiPlayerScheduleFields from "./multiPlayerScheduleFields";

const EventScheduling = () => {
  const [loading, setloading] = useState(false);
  const [dob, setdob] = useState(null);
  const { state } = useLocation();
  const [parralelMatches, setparralelMatches] = useState(2);
  const [valtime, setvaltime] = useState("");
  const [valbtime, setvalbtime] = useState("");
  const [valstartingtime, setvalstartingtime] = useState("");
  const [wantParrallelMatches, setwantParrallelMatches] = useState(false);
  const [Timeforonematch, setTimeforonematch] = useState();
  const [noOfKoGroups, setNoOfKoGroups] = useState(1);
  const [noOfTeamsFromEGroups, setNoOfTeamsFromEGroups] = useState(1);
  const [maxteams, setmaxteams] = useState(1);
  const [minteams, setminteams] = useState(1);
  const [noofMatches, setnoofMatches] = useState(1);
  const [killpoints, setkillpoints] = useState(1);
  const [istrankpoints, setistrankpoints] = useState(1);
  const [lastrankpoints, setlastrankpoints] = useState(0);

  const [playingTime, setPlayingTime] = useState({
    hours: 0,
    minutes: 0
    // am_pm: null
  });
  const [breakTime, setbreakTime] = useState({
    hours: 0,
    minutes: 0
    // am_pm: null
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (!Timeforonematch) return;
    const t = Timeforonematch?.split(":");

    console.log(t, playingTime);
    if (
      parseInt(t[0]) > parseInt(playingTime?.hours) ||
      (parseInt(playingTime?.hours) == parseInt(t[0]) &&
        parseInt(playingTime?.minutes) < parseInt(t[1]))
    ) {
      setvaltime("error");
    } else setvaltime("success");
  }, [playingTime?.hours, playingTime?.minutes, Timeforonematch]);

  useEffect(() => {
    if (!Timeforonematch) return;
    const t = Timeforonematch?.split(":");
    const obj = {
      ph: parseInt(playingTime?.hours),
      pm: parseInt(playingTime?.minutes),
      bh: parseInt(breakTime?.hours),
      bm: parseInt(breakTime?.minutes),
      th: parseInt(t[0]),
      tm: parseInt(t[1])
    };

    if (obj?.ph === obj?.bh + obj?.th && obj?.pm < obj?.bm + obj?.tm) {
      return setvalbtime("error");
    } else if (obj?.ph < obj?.bh + obj?.th) {
      setvalbtime("error");
    } else setvalbtime("success");
  }, [
    playingTime?.hours,
    playingTime?.minutes,
    breakTime?.hours,
    breakTime?.minutes,
    Timeforonematch
  ]);
  useEffect(() => {
    if (!dob) return;
    const t = String(dob?.[1]).split(":");
    const obj = {
      ph: parseInt(playingTime?.hours),
      pm: parseInt(playingTime?.minutes),
      th: parseInt(t[0]),
      tm: parseInt(t[1])
    };
    const totalPlayingTime = obj.ph * 60 + obj.pm;
    const totalStartingTime = obj.th * 60 + obj.tm;

    if (totalPlayingTime + totalStartingTime > 1440) {
      console.log("Playing time and starting time exceed 24 hours");
      setvalstartingtime("error");
    } else {
      setvalstartingtime("success");
    }
  }, [playingTime?.hours, playingTime?.minutes, dob]);

  useEffect(() => {
    if (!state) navigate(-1);

    if (state?._id && state?.scheduleComplete) {
      navigate(`/organizer/event/${state?._id}/multiplayermatches`, {
        replace: true,
        state
      });
    }
  }, [navigate, state, state?.scheduleComplete]);
  useEffect(() => {
    setmaxteams(1);
    setmaxteams(1);
    setNoOfTeamsFromEGroups(1);
  }, [noOfKoGroups]);

  function onSelect(value, options) {
    if (String(options.key).toLowerCase().includes("parralelmatches")) {
      setparralelMatches(value);
    }
    if (String(options.key).toLowerCase().includes("playing")) {
      if (String(options.key).toLowerCase().includes("hours")) {
        setPlayingTime({ ...playingTime, hours: value });
      } else if (String(options.key).toLowerCase().includes("minutes")) {
        setPlayingTime({ ...playingTime, minutes: value });
      }
    }

    if (String(options.key).toLowerCase().includes("break")) {
      if (String(options.key).toLowerCase().includes("hours")) {
        setbreakTime({ ...breakTime, hours: value });
      } else if (String(options.key).toLowerCase().includes("minutes")) {
        setbreakTime({ ...breakTime, minutes: value });
      }
    }

    if (String(options.key).toLowerCase().includes("Maximumteams")) {
      setmaxteams(value);
    } else if (String(options.key).toLowerCase().includes("minimumteams")) {
      setminteams(value);
    }
  }

  const Schedule = async () => {
    try {
      if (
        (!playingTime?.hours === 0 && playingTime?.minutes === 0) ||
        dob === null ||
        dob?.length < 2 ||
        valbtime === "error" ||
        valtime === "error" ||
        valstartingtime === "error"
      )
        return Info("Fill Out the details Correctly!");

      if (noOfKoGroups < 1 || !noOfKoGroups) {
        return Info("Add No. of Groups for Group Stages");
      }
      if (!noOfTeamsFromEGroups) {
        return Info("Select No. of Teams from Each Groups");
      }

      if (!Timeforonematch) {
        return Info("Select Time Required for one match");
      }
      if (maxteams < 1) {
        return Info("Select MaxTeams properly");
      } else if (minteams < 1) {
        return Info("Select MinTeams properly");
      }
      if (minteams > maxteams) {
        return Info("Select MinTeams and Max Teams properly");
      } else if (noofMatches < 1) {
        return Info("Select no of matches properly");
      }
      if (isNaN(killpoints) || isNaN(istrankpoints) || isNaN(lastrankpoints)) {
        return Info("Select Game points correctly");
      }
      setloading("scheule");
      const values = {
        eventId: state?._id,
        totalHours: (playingTime.hours || "00") + ":" + (playingTime.minutes || "00"),
        breakTime: (breakTime.hours || "00") + ":" + (breakTime.minutes || "00"),
        startfirstmatch: dob[0],
        start: dob[1],
        playingTime: Timeforonematch,
        minTeamsPerGroup: minteams,
        noofMatches: noofMatches,
        maxTeamsPerGroup: maxteams,
        noofgroups: noOfKoGroups,
        qualifyingteams: noOfTeamsFromEGroups,
        killpoints,
        istrankpoints,
        lastrankpoints
      };

      if (wantParrallelMatches) {
        values["parallel"] = parralelMatches || 0;
      }

      let res = null,
        data = null;
      if (state?.typeofmatch === GAMES_MODES.multi) {
        res = await MultiplayerSchedule(values);
        data = await res.json();
        console.log(data);
      }
      setloading(false);

      if (data?.type === "success") {
        setloading(false);
        success("Event Scheduled Successfully!");
        navigate(`/organizer/event/${state?._id}/multiplayermatches`, {
          replace: true,
          state: { ...state, koteams: values?.koteams }
        });
      } else if (typeof data === "object") {
        if (data?.result) error(data?.result);
        setloading(false);
      }
    } catch (e) {
      console.log(e);
      error(e.message);
      setloading(false);
    }
  };

  const { HistoryButtons } = useRoundsButtons();

  return (
    <Fragment>
      <div style={{ maxWidth: "100%" }}>
        <h1 className="ceheading">Event Scheduling</h1>
        <HistoryButtons origin={"schedule"} loading={loading} />
        <div className="formorganizer p-3">
          <section style={{ width: "90%", margin: "auto", marginTop: "30px" }}>
            <MultiPlayerScheduleFields
              state={state}
              noOfKoGroups={noOfKoGroups}
              setNoOfKoGroups={setNoOfKoGroups}
              noOfTeamsFromEGroups={noOfTeamsFromEGroups}
              setNoOfTeamsFromEGroups={setNoOfTeamsFromEGroups}
              valbtime={valbtime}
              minteams={minteams}
              setminteams={setminteams}
              maxteams={maxteams}
              setmaxteams={setmaxteams}
              onSelect={onSelect}
              noofMatches={noofMatches}
              setnoofMatches={setnoofMatches}
              wantParrallelMatches={wantParrallelMatches}
              setwantParrallelMatches={setwantParrallelMatches}
              timeforonematch={Timeforonematch}
              setTimeforonematch={setTimeforonematch}
              valstartingtime={valstartingtime}
              setvalstartingtime={setvalstartingtime}
              setdob={setdob}
              dob={dob}
              valtime={valtime}
              istrankpoints={istrankpoints}
              setistrankpoints={setistrankpoints}
              lastrankpoints={lastrankpoints}
              setlastrankpoints={setlastrankpoints}
              killpoints={killpoints}
              setkillpoints={setkillpoints}
              playingTime={playingTime}
              breakTime={breakTime}
              parralelMatches={parralelMatches}
            />
          </section>
        </div>
      </div>
      <Row justify="center" style={{ margin: "50px 10px" }}>
        <Button
          style={{ margin: "0" }}
          className="draftbutton equalWidhtButton"
          type="primary"
          onClick={Schedule}>
          Schedule
        </Button>
      </Row>
      {loading ? <Loader fullpage /> : null}
    </Fragment>
  );
};

export default EventScheduling;
