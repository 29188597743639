import React from "react";
import { Layout } from "antd";
import { Outlet, useLocation } from "react-router-dom";
import SponsorNav from "./SponsorNav";

const { Header, Content } = Layout;
const SponsorLayout = () => {
  const { pathname } = useLocation();

  return (
    <Layout>
      <Header
        className="navbar"
        style={{
          backgroundColor: "white",
          position: "fixed",
          width: "100%",
          left: 0,
          top: 0,
          right: 0,
          zIndex: 1000,
          height: "max-content",
          boxShadow: pathname.includes("search") ? "unset" : " 0px 4px 8px rgb(0 0 0 / 5%)",
          borderBottom: pathname.includes("search") ? "2px solid rgb(226, 226, 226)" : null
        }}>
        <SponsorNav />
      </Header>
      <Content
        style={{
          background: "#F5F7F9",
          minHeight: "100vh",
          paddingTop: pathname.includes("search") ? 0 : "50px"
        }}>
        <Outlet />
      </Content>
    </Layout>
  );
};

export default SponsorLayout;
