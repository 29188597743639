import React from "react";
import { Row, Col, Button, Form, Input } from "antd";

function PasswordForm({ PasswordSubmit, VPass, loading, setPassword, setCPassword }) {
  return (
    <Row justify="center">
      <Col md={2}></Col>
      <Col md={20} xs={24} sm={24}>
        <Form
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          onFinish={PasswordSubmit}
          autoComplete="off">
          <Form.Item
            name="password"
            label="Your Password"
            // rules={[{ required: true }]}
          >
            <Input.Password
              style={{ borderRadius: "5px", lineHeight: "28px" }}
              placeholder="Enter your password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Item>

          <Form.Item
            name="confirmpassword"
            label="Confirm Password"
            hasFeedback
            validateStatus={VPass}
            // rules={[{ required: true }]}
          >
            <Input.Password
              onChange={(e) => setCPassword(e.target.value)}
              style={{ borderRadius: "5px", lineHeight: "28px" }}
              placeholder="Enter your confirm password"
            />
            {VPass === "error" ? (
              <label style={{ color: "red", fontSize: "12px" }}>
                Please enter same password in both fields
              </label>
            ) : null}
          </Form.Item>

          <Form.Item style={{ justifyContent: "center" }}>
            <Button loading={loading} className="submitButton" type="primary" htmlType="submit">
              Done
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
}

export default PasswordForm;
