import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useVariables from "../../../../common/hooks/useVariables";
import FadeInImage from "../../../../common/Images/FadeInImage";
import { fallbackprofile } from "../../../../common/Utils";
import { KEYS } from "../../../../Config/Keys";

import EditModalCard from "./EditModalCard";

const ActiveCard = ({ data, current, setdata }) => {
  const [open, setopen] = useState(false);
  const { viewer } = useVariables();

  const [person, setperson] = useState((data?.player?._id || data?.organizer?._id) === viewer?.id);

  useEffect(() => {
    return () => {
      setopen(null);
      setperson(null);
    };
  }, []);
  const navigate = useNavigate();
  return (
    <Fragment>
      <div
        onClick={() =>
          navigate(
            `/view/${data?.player ? "player" : "organizer"}/${
              data?.player?._id || data?.organizer?._id
            }/timeline`,
            { state: current === "team" ? "player" : "organizer" }
          )
        }
        className="pomemberShadow membercard">
        <div className="popadd">
          <FadeInImage
            className="poprofilepic"
            src={KEYS.API + "/" + (data?.player?.profilepic || data?.organizer?.profilepic)}
            fallaback={fallbackprofile}
            type={"Fadegcard"}
          />
        </div>

        <p className="namepo">
          {(data?.player?.name || data?.organizer?.name) + (person ? " (you)" : "") ||
            "fallback name"}
        </p>
        <p
          style={{
            color: "#7D7C7C",
            textAlign: "center",
            fontSize: "12px",
            marginTop: "8px"
          }}>
          {data?.role || "No Role Assigned"}
        </p>

        <div style={{ marginBottom: "20px" }} />
      </div>
      {open && (
        <EditModalCard
          open={open}
          setopen={setopen}
          playerId={data?.player?._id}
          setteam={setdata}
        />
      )}
    </Fragment>
  );
};

export default ActiveCard;
