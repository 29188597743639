import { Button } from "antd";
import React from "react";
import { AssignDraw } from "../../../../../../../Config/API/GroupStages";
import { useParams } from "react-router-dom";
import { useSortedpoints } from "../../../Components/utility";

function VS({ item, index, loading, setloading, Orderloading, seteditresult }) {
  const classes = "btnMobileDimensions draftbutton equalWidhtButton m-0";
  const winnerExistCheck = item?.match?.win === "draw" ? "nowinner" : "exist";

  const { id } = useParams();
  const { sorteddata } = useSortedpoints();

  const drawapi = async () => {
    try {
      setloading(true);
      let winner, loser;
      if (winnerExistCheck === "exist") {
        let win = item?.match?.win;
        winner =
          win === item?.match?.team1?._id ? item?.match?.team1?._id : item?.match?.team2?._id;
        loser = win === item?.match?.team1?._id ? item?.match?.team2?._id : item?.match?.team1?._id;
      }
      var values = {
        matchId: item?.match?._id,
        loser,
        winner,
        eventId: id,
        winnerExistCheck: winnerExistCheck
      };
      const res = await AssignDraw(values);
      const data = await res.json();
      console.log(data);
      await sorteddata(data?.data[0]);
      setloading(false);
      seteditresult(false);
    } catch (e) {
      setloading(false);
      console.log(e);
    }
  };

  return (
    <div
      className="MatchCardVsInfo"
      style={{
        display: "flex",
        alignItems: "center",
        height: "100%",
        justifyContent: "center",
        flexDirection: "column",
        color: "#515151"
      }}>
      <h1> VS </h1>
      <span>Match {index + 1}</span>
      {winnerExistCheck === "exist" ? (
        <Button
          loading={loading}
          style={{ width: "80px" }}
          type="ghost"
          onClick={() => {
            if (!loading && !Orderloading) {
              drawapi();
            }
          }}
          className={`${classes}  GREY DRAW`}>
          {loading ? "" : "Draw"}
        </Button>
      ) : (
        "(Draw)"
      )}
    </div>
  );
}

export default VS;
