import React, { Fragment } from "react";
import { Row, Col, Avatar } from "antd";
import { FaCalendarAlt, FaLocationArrow } from "react-icons/fa";
import FadeInImage from "../../Images/FadeInImage";
import moment from "moment";
import { fallbackcover } from "../../Utils";
import { useLocation, useNavigate } from "react-router-dom";
import { KEYS } from "../../../Config/Keys";
import { SiNike } from "react-icons/si";

const MarkedEventCard = ({ data }) => {
  const { eventId } = data;
  const panel = eventId?.panel;
  const panelid = localStorage.getItem("panel");
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const currentUser = pathname.includes("player") ? "player" : "organizer";
  const id = eventId?._id || 123;

  return (
    <Fragment>
      <Row
        className="eventcard"
        style={{ background: "#ccfae5" }}
        onClick={() => {
          if (currentUser === "player") {
            navigate(`/view/event/${id}`);
          } else if (currentUser === "organizer") {
            if (panel === panelid) navigate(`/${currentUser}/event/${id}`);
            else navigate(`/view/event/${id}`);
          }
        }}>
        <FadeInImage
          radius
          EventCard
          type={"media"}
          fallaback={fallbackcover}
          src={KEYS.API + "/" + eventId?.file?.file}
        />
        <Col lg={10} md={10} xs={24} sm={24}>
          <div style={{ padding: "15px 0px 5px 15px" }}>
            <p style={{ fontSize: "11px", color: "red", margin: "0px" }}>
              <FaCalendarAlt />
              <span style={{ marginLeft: "5px" }}>
                {new Date(data?.eventId?.startDate).toDateString() +
                  " - " +
                  new Date(data?.eventId?.endDate).toDateString()}
              </span>
            </p>
            <p style={{ fontWeight: "500", marginTop: "20px" }}>
              {data?.eventId?.name}{" "}
              <span
                style={{
                  fontSize: "10px",
                  padding: "2px 10px",
                  backgroundColor: "white",
                  fontWeight: "700",
                  borderRadius: "5px"
                }}>
                Marked
              </span>
            </p>
            <p
              style={{
                fontSize: "11px",
                display: "flex",
                alignItems: "center",
                gap: "5px"
              }}>
              <FaLocationArrow />
              {eventId?.city || eventId?.location || "Missing Location"}
            </p>
          </div>
        </Col>
        <Col lg={14} md={14} xs={24} sm={20} className="eventCardRightSide">
          <p>
            <b>Category : </b>
            {eventId?.typeofsports || " Football"}
          </p>
          {moment(eventId?.deadline) > new Date() ? (
            <p>
              <b>Closing : </b> {moment(eventId?.deadline).from()}
            </p>
          ) : (
            <p>
              {" "}
              <b>Registration : </b> closed{" "}
            </p>
          )}
          <Avatar.Group
            maxCount={4}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              position: "relative"
            }}
            max={{
              count: 4,
              style: {
                color: "#f56a00",
                backgroundColor: "#fde3cf"
              }
            }}>
            {data?.eventId?.registerteams.map((item, i) => (
              <Avatar src={`${process.env.REACT_APP_API_URL}/${item?.team?.profilepic}`} key={i} />
            ))}{" "}
          </Avatar.Group>

          <div
            className=""
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginTop: "10px"
            }}>
            <div style={{ display: "flex", alignItems: "flex-start", columnGap: "10px" }}>
              <p
                style={{
                  fontSize: "10px",
                  fontWeight: "700"
                }}>
                Sponsors
              </p>

              <SiNike />

              <SiNike />
            </div>
            <div
              style={{ borderLeft: "1px solid #ffacac", height: "40px", margin: "0 10px" }}></div>
            <div style={{ display: "flex", alignItems: "center", columnGap: "20px" }}>
              <p
                style={{
                  fontSize: "10px",
                  fontWeight: "700"
                }}>
                Awards{" "}
              </p>
              <div>
                <p
                  style={{
                    justifyContent: "flex-start",
                    color: "#ff3a00",
                    fontWeight: "600",
                    fontSize: "10px"
                  }}>
                  💵 {data?.eventId?.fee || 0} Rs
                </p>

                <p
                  style={{
                    justifyContent: "flex-start",
                    color: "#ff3a00",
                    fontWeight: "600",
                    fontSize: "10px"
                  }}>
                  🛡️ {data?.shield || 0} Shield
                </p>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

export default MarkedEventCard;
