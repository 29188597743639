import React from "react";
import { Fragment, useState } from "react";
import { Row, Col } from "antd";
import OTPForm from "./OTPForm";
import PasswordForm from "./PasswordForm";
import EmailForm from "./EmailForm";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { changePasswordAPI, sendOTPAPI, verifyOTPAPI } from "../../../Config/API/user";
import { useEffect } from "react";

const ForgotPassowrd = () => {
  const [otp, setotp] = useState("");
  const [email, setEmail] = useState("");
  const [visibleForm, setVisibleForm] = useState(1);
  const [loading, setloading] = useState(false);
  const [VPass, setVPass] = useState("");
  const [Password, setPassword] = useState("");
  const [CPassword, setCPassword] = useState("");
  const navigate = useNavigate();

  const [VEmail, setVEmail] = useState("");
  let handleOnEmailChange = (email) => {
    let re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    setEmail(email);
    if (re.test(email)) {
      setVEmail("success");
    } else {
      setVEmail("error");
    }
  };

  useEffect(() => {
    if (Password !== "" && CPassword !== "") {
      if (Password === CPassword) {
        return setVPass("success");
      } else {
        setVPass("error");
      }
    }
    if (Password === "" && CPassword === "") {
      setVPass("");
    }
  }, [CPassword, Password]);
  const MailSubmit = async (e) => {
    // return setVisibleForm(3);
    if (!email) {
      return Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please fill required field",
        confirmButtonColor: "#0085FF",
        allowOutsideClick: false
      });
    }
    if (VEmail !== "success") {
      return Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please enter correct email",
        confirmButtonColor: "#0085FF",
        allowOutsideClick: false
      });
    }

    let response;
    try {
      setloading(true);
      console.log(e);
      response = await sendOTPAPI(e);

      const data = await response.json();
      setloading(false);
      if (data.type === "success") {
        setVisibleForm(2);
      }
      if (data.type === "failure") {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: data.result,
          confirmButtonColor: "#0085FF",
          allowOutsideClick: false
        });
      }
    } catch (err) {
      console.log(err);
      setloading(false);

      serverError();
    }

    // Calculation
  };

  const PasswordSubmit = async () => {
    if (Password === "") {
      return Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please fill required field",
        confirmButtonColor: "#0085FF",
        allowOutsideClick: false
      });
    }
    if (VPass !== "success") {
      return Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please enter same password in both fields. ",
        confirmButtonColor: "#0085FF",
        allowOutsideClick: false
      });
    }
    if (Password.length < 8) {
      return Swal.fire({
        icon: "error",
        title: "Error",
        text: "Password length must be greater than 7 characters",
        confirmButtonColor: "#0085FF",
        allowOutsideClick: false
      });
    }

    let response;
    try {
      setloading(true);
      response = await changePasswordAPI({ email: email, password: Password });
      const data = await response.json();
      setloading(false);
      if (data.type === "success") {
        Swal.fire({
          title: "Password has been changed Succesfully",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
          confirmButtonColor: "#0085FF"
        }).then((result) => result.isConfirmed && navigate("/signin"));
      }
      if (data.type === "failure") {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: data.result,
          confirmButtonColor: "#0085FF",
          allowOutsideClick: false
        });
      }
    } catch (err) {
      console.log(err);
      setloading(false);

      serverError();
    }
    // Calculation
    // navigate("/signin")
  };
  const OTPSubmit = async () => {
    console.log(otp);
    // Calculation

    if (otp.length !== 4) {
      return Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please fill required field",
        confirmButtonColor: "#0085FF",
        allowOutsideClick: false
      });
    }

    let response;
    try {
      setloading(true);
      response = await verifyOTPAPI({ email: email, number: otp });
      const data = await response.json();
      setloading(false);
      if (data.type === "success") {
        setVisibleForm(3);
      }
      if (data.type === "failure") {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: data.result,
          confirmButtonColor: "#0085FF",
          allowOutsideClick: false
        });
      }
    } catch (err) {
      console.log(err);
      setloading(false);

      serverError();
    }
  };

  const serverError = (text) => {
    setloading(false);
    Swal.fire({
      icon: "error",
      title: text || "Internal server error",
      text: "Please try again later",
      allowOutsideClick: false
    });
  };
  return (
    <Fragment>
      <Row
        className="topRowPadding"
        justify="center"
        align="center"
        style={{
          height: "100vh",
          alignItems: "center"
        }}>
        <Col md={12}>
          <Row className="middleRowPadding">
            <Link to="/">
              <img alt="logo" height="100px" src="/images/logo.png" />
            </Link>
          </Row>
          <Row className="downMiddleRowPadding">
            <h1 className="gameFont">Join the Game!</h1>
          </Row>
          <Row className="insidePadding">
            <h1 className="insideFont">Go inside the best gamers social network!</h1>
          </Row>
        </Col>
        <Col md={12}>
          <Row>
            <Col span={24}>
              <h1 className="signInCenter">Forgot Password</h1>
            </Col>
          </Row>
          {visibleForm === 1 && (
            <EmailForm
              VEmail={VEmail}
              MailSubmit={MailSubmit}
              handleOnEmailChange={handleOnEmailChange}
              loading={loading}
            />
          )}
          {visibleForm === 2 && (
            <Row justify="center">
              <Col md={20} xs={24} sm={24}>
                <OTPForm
                  visibleForm={visibleForm}
                  setVisibleForm={setVisibleForm}
                  email={email}
                  otp={otp}
                  VEmail={VEmail}
                  setotp={setotp}
                  OTPSubmit={OTPSubmit}
                  loading={loading}
                />
              </Col>
            </Row>
          )}
          {visibleForm === 3 && (
            <PasswordForm
              setPassword={setPassword}
              setCPassword={setCPassword}
              loading={loading}
              VPass={VPass}
              PasswordSubmit={PasswordSubmit}
            />
          )}
        </Col>
      </Row>
    </Fragment>
  );
};
export default ForgotPassowrd;
