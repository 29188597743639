import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import useVariables from "../../../common/hooks/useVariables";

function AuthPlayer() {
  const { token, FLAG, USERID, PID } = useVariables();
  const navigate = useNavigate();

  useEffect(() => {
    if (!USERID) navigate("/signin");
    else if (FLAG === "organizer" || !PID) navigate("/", { replace: true });
  }, [FLAG, PID, USERID, navigate, token]);
  if (FLAG && PID) return <Outlet />;
  return null;
}

export default AuthPlayer;
