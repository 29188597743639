import { GetDoubleKnockOutEventData } from "../../../../../../../Config/API/events";
import useRoundsButtons from "../useRoundsUtility/useRoundsButtons";
import { error } from "../../../../../../../common/Alerts/Message";
import Loader from "../../../../../../../common/cards/Loader";
import {
  load,
  setBYE,
  unload,
  ROUNDES,
  isLoading,
  setRoundes,
  setactiveround,
  setIsAgainMatch,
  setloserrounds,
  setfinalistrounds,
  setTeams
} from "../../../../../../../Store/Features/kobracketslice";
import { useRoundsUtility } from "../useRoundsUtility";
import { useDispatch, useSelector } from "react-redux";
import React, { Fragment, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Bracket } from "react-brackets";
import { Col, Row } from "antd";
import { useState } from "react";

const DoubleEliminationBrackets = () => {
  const { id } = useParams();

  const dispatch = useDispatch();
  var Rounds = useSelector(ROUNDES);
  var finalistbrackets = useSelector((state) => state.ko.finalistbrackets);
  var loserbrackets = useSelector((state) => state.ko.loserbrackets);
  const loading = useSelector(isLoading);

  const [korounds, setkorounds] = useState([]);
  const IsAgainMatch = useSelector((state) => state.ko.againMatch);

  // apicall for team replace
  const getroundes = async () => {
    try {
      dispatch(load());
      dispatch(setactiveround(null));
      var response = null,
        data = null,
        values = {
          eventId: id,
          flag: "web"
        };
      response = await GetDoubleKnockOutEventData(values);
      data = await response.json();
      console.log(data);
      if (data.type === "success") {
        dispatch(setloserrounds(data?.loserrounds));
        dispatch(setfinalistrounds(data?.finalistrounds));
        dispatch(setBYE(data?.data?.isBYE));
        dispatch(setIsAgainMatch(data?.data?.is2Matches));
        dispatch(setRoundes(data?.result));
      } else {
        error(data?.result);
      }
      dispatch(unload());
    } catch (e) {
      dispatch(unload());

      console.log(e);
      error(e.message);
    }
  };

  const getroundname = (round) => {
    // 1:final
    // 2:semi-final
    // 4:qaurter-final
    // 8:round of 16
    // 16:round of 32
    if (round?.length === 1) return "Final";
    else if (round?.length === 2) return "Semi Final";
    else if (round?.length === 4) return "Quarter Final";
    else {
      const l = round?.length * 2;
      return "Rounds of " + l;
    }
  };
  const getlosersroundsname = (round, lastname) => {
    // 1:final
    // 2:semi-final
    // 4:qaurter-final
    // 8:round of 16
    // 16:round of 32

    if (round?.length === 1) {
      if (lastname === null || lastname !== "Final A") return "Final A";
      else return "Fianl B";
    } else if (round?.length === 2) {
      if (lastname === null || lastname !== "Semi Final A") return "Semi Final A";
      else return "Semi Final B";
    } else if (round?.length === 4) {
      if (lastname === null || lastname !== "Quarter Final A") return "Quarter Final A";
      else return "Quarter Final B";
    } else {
      const l = round?.length * 2;
      if (lastname !== "Rounds of " + l + " A") return "Rounds of " + l + " A";
      else return "Rounds of " + l + " B";
    }
  };
  useEffect(() => {
    const r1 = Rounds[0];
    const r2 = loserbrackets[0];
    const r3 = finalistbrackets[0];
    if (!Array.isArray(r1) || !Array.isArray(r2) || !Array.isArray(r3)) return;
    set();
  }, [[...Rounds], [...loserbrackets], [...finalistbrackets]]);
  const set = () => {
    var r = Rounds?.map((e) => {
      const name = getroundname([...e]);
      return { title: name, seeds: [...e] };
    });
    setkorounds(r);

    let lastname = null;
    r = loserbrackets?.map((e) => {
      lastname = getlosersroundsname([...e], lastname);
      const name = lastname;
      return { title: name, seeds: [...e] };
    });
    dispatch(setloserrounds(r));
    r = finalistbrackets?.map((e, i) => {
      const name = i === 0 ? "Grand Final Round 1" : "Grand Final Round 2";
      return { title: name, seeds: [...e] };
    });
    if (!IsAgainMatch) r = r.filter((e, i) => i === 0 && e);
    dispatch(setfinalistrounds(r));
  };
  useEffect(() => {
    getroundes();
    return () => {
      dispatch(setTeams([]));
      dispatch(setRoundes([]));
      dispatch(setloserrounds([]));
      dispatch(setfinalistrounds([]));
    };
  }, []);

  const { CustomSeed, CustomSeed2, CustomSeed3 } = useRoundsUtility();
  const { BracketsReplaceButtons, HistoryButtons } = useRoundsButtons();
  const bye = useSelector((state) => state.ko.isBYE);

  return (
    <Fragment>
      <h1 className="ceheading">
        Knock out Rounds
        <p className="SubHeading">
          (Double Elimination
          {bye ? <span className="SubHeading"> BYE</span> : null})
        </p>
      </h1>
      <HistoryButtons origin={"knockouts"} loading={loading} />
      <Row className={`formorganizer kobracketsRow ${loading ? " rowloadingevent" : null}`}>
        {loading ? <Loader noShadow /> : null}
        {loading ? null : (
          <Col style={{ display: loading ? "none" : null }} span={24}>
            <Row>
              <BracketsReplaceButtons />

              <Col span={24}>
                <p className="ceheading">WINNERS&apos;S BRACKET </p>
                <Bracket
                  roundClassName="BracketRound"
                  bracketClassName={`Brackets`}
                  rounds={korounds}
                  renderSeedComponent={CustomSeed}
                  swipeableProps={{
                    animateHeight: true,
                    className: "react-brackets-mobile SmallScroll"
                  }}
                />
                <p className="ceheading">LOSERS&apos;S BRACKET </p>

                <Bracket
                  roundClassName="BracketRound"
                  bracketClassName={`Brackets`}
                  rounds={loserbrackets}
                  renderSeedComponent={CustomSeed2}
                  swipeableProps={{
                    animateHeight: true,
                    className: "react-brackets-mobile SmallScroll"
                  }}
                />
                <p className="ceheading">FINALIST&apos;S BRACKET </p>

                <Bracket
                  roundClassName="BracketRound"
                  bracketClassName={`Brackets`}
                  rounds={finalistbrackets}
                  renderSeedComponent={CustomSeed3}
                  swipeableProps={{
                    animateHeight: true,
                    className: "react-brackets-mobile SmallScroll"
                  }}
                />
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </Fragment>
  );
};

export default DoubleEliminationBrackets;
