import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  profile: {},
  loading: false,
  initalloading: true
};

export const ProfileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setProfile: (state, action) => {
      state.profile = action.payload;
    },
    load: (state) => {
      state.loading = true;
    },
    unload: (state) => {
      state.loading = false;
    },
    setinitialloading: (state, action) => {
      state.initalloading = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setProfile, load, unload, setinitialloading } = ProfileSlice.actions;
export const PROFILE = (state) => state.profile.profile;
export const isLoading = (state) => state.profile.loading;
export const isInitalloading = (state) => state.profile.initalloading;

export default ProfileSlice.reducer;
