import React, { Fragment } from "react";
import { Row } from "antd";
import FeedCard from "../../../../common/cards/FeedCard";
import UploadPostcard from "../../../../common/cards/UploadPostcard";
import Loader from "../../../../common/cards/Loader";
import NoData from "../../../../common/cards/noData";
import { useFetchAllPosts } from "../../../../common/hooks/useFetchAllPosts";
import { useCreatePost } from "../../../../common/hooks/useCreatePost";
const DiscussionPanel = () => {
  const { fetchloading, POSTS, setPOSTS } = useFetchAllPosts("panel");
  const {
    uploadloading,
    IMAGE,
    VIDEO,
    setimage,
    setvideo,
    text,
    setText,
    uploadingMedia,
    setuploadingMedia,
    CreatePost,
    setLuploading,
    Luploading
  } = useCreatePost(setPOSTS, "Panel");

  return (
    <Fragment>
      <Row className="mobileto">
        <UploadPostcard
          uploadingMedia={uploadingMedia}
          setuploadingMedia={setuploadingMedia}
          setText={setText}
          text={text}
          IMAGE={IMAGE}
          setimage={setimage}
          VIDEO={VIDEO}
          setvideo={setvideo}
          onPost={CreatePost}
          loading={uploadloading}
          setLuploading={setLuploading}
          Luploading={Luploading}
        />
        {fetchloading ? (
          <Loader />
        ) : POSTS?.length > 0 ? (
          POSTS?.map((data, index) => <FeedCard key={index} data={data} setPOSTs={setPOSTS} />)
        ) : (
          <NoData />
        )}
      </Row>
    </Fragment>
  );
};

export default DiscussionPanel;
