// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .imageuploadermodel .ant-modal-content {
   margin: auto;
}

.imageuploadermodel .ant-modal-content button:has(.ant-modal-close-x) {
  min-width: unset;
}

.imageuploadermodel .ant-modal-body {
  padding-bottom: 1.5rem;
}
.imageuploadermodel .img-crop-control {
  width: unset;
}
.imageuploadermodel .img-crop-control:first-of-type {
  margin-top: unset;
}
.imageuploadermodel .img-crop-control button {
  min-width: 80px;
  font-weight: 600;
  font-size: 19px;
}
.imageuploadermodel .ant-modal-footer {
  display: none;
}
.reactEasyCrop_CropArea {
  height: 100% !important;
  width: 100% !important;
  background-color: #cfcfcf1a;
  border-radius: 8px;
}
.reactEasyCrop_Image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.CoverImage {
  object-fit: unset !important;
}
.img-crop-modal .img-crop-container {
  height: 330px;
  border-radius: 8px;

  width: 100%;
}

.reactEasyCrop_CropAreaGrid::before,
.reactEasyCrop_CropAreaGrid::after {
  border-color: rgb(120, 120, 120) !important;
}

.imagecropuploader {
  width: 100%;
}
.imagecropuploader .ant-upload {
  width: 100%;
} */
`, "",{"version":3,"sources":["webpack://./src/styles/CropImageUploader/CropImageUploader.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;GAwDG","sourcesContent":["/* .imageuploadermodel .ant-modal-content {\n   margin: auto;\n}\n\n.imageuploadermodel .ant-modal-content button:has(.ant-modal-close-x) {\n  min-width: unset;\n}\n\n.imageuploadermodel .ant-modal-body {\n  padding-bottom: 1.5rem;\n}\n.imageuploadermodel .img-crop-control {\n  width: unset;\n}\n.imageuploadermodel .img-crop-control:first-of-type {\n  margin-top: unset;\n}\n.imageuploadermodel .img-crop-control button {\n  min-width: 80px;\n  font-weight: 600;\n  font-size: 19px;\n}\n.imageuploadermodel .ant-modal-footer {\n  display: none;\n}\n.reactEasyCrop_CropArea {\n  height: 100% !important;\n  width: 100% !important;\n  background-color: #cfcfcf1a;\n  border-radius: 8px;\n}\n.reactEasyCrop_Image {\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n}\n.CoverImage {\n  object-fit: unset !important;\n}\n.img-crop-modal .img-crop-container {\n  height: 330px;\n  border-radius: 8px;\n\n  width: 100%;\n}\n\n.reactEasyCrop_CropAreaGrid::before,\n.reactEasyCrop_CropAreaGrid::after {\n  border-color: rgb(120, 120, 120) !important;\n}\n\n.imagecropuploader {\n  width: 100%;\n}\n.imagecropuploader .ant-upload {\n  width: 100%;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
