import React, { useState, useRef, Fragment } from "react";
import { Typography, Layout, Form, Input } from "antd";
import { IoSend } from "react-icons/io5";
import { BiArrowBack } from "react-icons/bi";
import { ImAttachment } from "react-icons/im";
import { useNavigate, useLocation } from "react-router-dom";
import { GoDot } from "react-icons/go";

import Reciver from "./Card/Sender";
import Sender from "./Card/Reciver";

import { useEffect } from "react";
import { error } from "../../../common/Alerts/Message";
import { GetMessagesData } from "../../../Config/API/chat";
import Loader from "../../../common/cards/Loader";
import MainMobile from "../MainMobile";
const { Title, Text } = Typography;
const { Header, Footer, Content } = Layout;

const ChatRoomMain = ({
  setactive,
  socket,
  active,
  openChat,
  online,
  room,
  // setopenChat,
  isDesktop
  // handleReRender
}) => {
  const bottomRef = useRef(null);
  const [MainTextField, setMainTextField] = useState("");
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);

  const profileID = localStorage.getItem("ProfileID");
  const [form] = Form.useForm();

  const onSubmit = () => {
    if (MainTextField == "") {
      return error("Message field can't be empty");
    }
    const users = room.split("@!@!2!@!@");
    const role = JSON.parse(localStorage.getItem("userDetails"))?.role.toLowerCase();

    let localData;

    localData = {
      sender: { [role]: { _id: profileID } },
      receiver: {
        [openChat.receiver.player ? "player" : openChat.receiver.sponsor ? "sponsor" : "organizer"]:
          {
            _id: users[1] == profileID ? users[0] : users[1]
          }
      },
      text: MainTextField,
      createdAt: new Date(),
      room: room
    };
    setMessages((messages) => [...messages, localData]);

    // setopenChat({
    //   ...openChat, // Copy the old fields
    //   messages: [...openChat.messages, localData], // But override this one
    // });
    setMainTextField("");
    form.resetFields();
    socket.emit("send_message", {
      data: localData,
      room: room
    });
  };
  // const navigate = useNavigate();
  // const { pathname } = useLocation();

  useEffect(() => {
    socket.on("receive_message", (data) => {
      console.log("Recieve", data);
      console.log("room", localStorage.getItem("room"));
      console.log(data.room);
      if (data.room == localStorage.getItem("room")) {
        console.log("room data changed");

        setMessages((messages) => [...messages, data]);
        // setopenChat({
        //   ...openChat, // Copy the old fields
        //   messages: [...openChat.messages, data], // But override this one
        // });
      }
    });
  }, [socket, openChat]);

  useEffect(() => {
    console.log("render message");

    setMessages([]);
    getInboxDataMessages();
  }, [active]);

  const getInboxDataMessages = async () => {
    setLoading(true);
    var res = await GetMessagesData(localStorage.getItem("room"));
    var data = await res.json();
    console.log("messages", data);
    if (data?.result === null) {
      console.log("false");
      setMessages([]);
      return setLoading(false);
    }
    console.log(data.result.messages);
    if (data.type === "success") {
      // setList(data.result);
      setMessages(data.result.messages);
      setLoading(false);
      // setopenChat({
      //   ...openChat, // Copy the old fields
      //   messages: [...openChat.messages, data.result.messages], // But override this one
      // });
    } else {
      console.log("Error");
      setLoading(false);
    }

    setLoading(false);
  };
  const AlwaysScrollToBottom = () => {
    const elementRef = useRef();
    useEffect(() => elementRef.current.scrollIntoView());
    return <div ref={elementRef} />;
  };

  return (
    <Fragment>
      {isDesktop ? (
        <MAIN
          setactive={setactive}
          openChat={openChat}
          online={online}
          profileID={profileID}
          loading={loading}
          bottomRef={bottomRef}
          messages={messages}
          onSubmit={onSubmit}
          form={form}
          setMainTextField={setMainTextField}
          MainTextField={MainTextField}
          AlwaysScrollToBottom={AlwaysScrollToBottom}
        />
      ) : (
        <MainMobile active={active} setactive>
          <MAIN
            setactive={setactive}
            openChat={openChat}
            online={online}
            profileID={profileID}
            loading={loading}
            bottomRef={bottomRef}
            messages={messages}
            onSubmit={onSubmit}
            form={form}
            setMainTextField={setMainTextField}
            MainTextField={MainTextField}
            AlwaysScrollToBottom={AlwaysScrollToBottom}
            mobile
          />
        </MainMobile>
      )}
    </Fragment>
  );
};

const MAIN = ({
  setactive,
  openChat,
  online,
  profileID,
  loading,
  bottomRef,
  messages,
  onSubmit,
  form,
  setMainTextField,
  MainTextField,
  AlwaysScrollToBottom,
  mobile
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const mobileLoadingstlyes = {
    position: "fixed",
    left: "0",
    top: "0",
    width: "100vw",
    minHeight: "100vh",
    display: "grid",
    placeContent: "center",
    zIndex: "10",
    background: "white"
  };
  const desktopLoadingStyles = {
    position: "absolute",
    left: "0",
    top: "-50px",
    width: "100%",
    maxWidth: "85%",
    minHeight: "100%",
    display: "grid",
    placeContent: "center",
    zIndex: "1"
  };

  const EmptyMessageCenter = (
    <Typography className="EmptyMessageCenter">
      <Text> No Messages Yet! </Text>{" "}
    </Typography>
  );
  return (
    <Layout className={`ChatRoomMain  ${mobile ? "" : undefined}`}>
      <Header className="Chat_TobBar">
        <Typography>
          <Title level={3} style={{ marginBottom: 0, fontWeight: "bold" }}>
            {online ? (
              <GoDot color="#00FF38" style={{ marginRight: "6px" }} />
            ) : (
              <GoDot color="red" style={{ marginRight: "6px" }} />
            )}

            {openChat?.sender?.player?._id === profileID ||
            openChat?.sender?.organizer?._id == profileID ||
            openChat?.sender?.sponsor?._id === profileID
              ? openChat?.receiver?.player?.name ||
                openChat?.receiver?.organizer?.name ||
                openChat?.receiver?.sponsor?.name
              : openChat?.sender?.player?.name ||
                openChat?.sender?.organizer?.name ||
                openChat?.sender?.sponsor?.name}
          </Title>
        </Typography>
        <Typography>
          <Title level={4}>
            <BiArrowBack
              cursor={"pointer"}
              onClick={() => {
                if (pathname.includes("player")) {
                  setactive("");
                  navigate(`/player/messages/chat`);
                } else if (pathname.includes("sponsor")) {
                  setactive("");
                  navigate(`/sponsor/messages/chat`);
                } else {
                  setactive("");
                  navigate(`/organizer/messages/chat`);
                }
              }}
            />
          </Title>
        </Typography>
      </Header>
      <Content
        className={`ChatContent ${mobile ? "ChatContentMobile" : undefined}`}
        ref={bottomRef}>
        {loading ? (
          <div style={mobile ? mobileLoadingstlyes : desktopLoadingStyles}>
            <Loader noShadow smaller />
          </div>
        ) : (
          <div
            style={{
              zIndex: loading ? "-1" : "1",
              minHeight: loading ? "0px" : undefined,
              overflow: loading ? "hidden" : undefined
            }}>
            {messages?.length === 0 && !loading ? EmptyMessageCenter : null}
            {messages.map((item, i) => (
              <>
                {item.sender?.player?._id === profileID ||
                item.sender.organizer?._id === profileID ||
                item.sender?.sponsor?._id === profileID ? (
                  <Sender key={i} item={item} />
                ) : (
                  <Reciver openChat={openChat} key={i} item={item} />
                )}
              </>
            ))}
          </div>
        )}

        <AlwaysScrollToBottom />
      </Content>

      <Footer className="ChatFooter">
        <Form name="Main" className="MainForm" onFinish={onSubmit} form={form}>
          <Form.Item style={{ flex: 1 }} name="search">
            <Input
              className="ChatSearchBar"
              placeholder="Send Message"
              name="textfield"
              value={MainTextField}
              onChange={(e) => setMainTextField(e.target.value)}
              size="large"
              style={{ padding: "12px 15px" }}
              suffix={
                <IoSend
                  color={"var(--blue)"}
                  cursor="pointer"
                  fontSize={"1.4rem"}
                  onClick={onSubmit}
                />
              }
            />
          </Form.Item>
          <Form.Item>
            <ImAttachment color={"var(--blue)"} cursor="pointer" fontSize={"1.5rem"} />
          </Form.Item>
        </Form>
      </Footer>
    </Layout>
  );
};

export default ChatRoomMain;
