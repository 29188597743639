import { Rate, List, Checkbox } from "antd";
import NumberDropdown from "./NumberDropdown";

import { KEYS } from "../../../Config/Keys";
import React from "react";
import { FiUsers } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { FaCheckCircle } from "react-icons/fa";
import { IoTimerSharp } from "react-icons/io5";
import { SiNike } from "react-icons/si";
import dayjs from "dayjs";
import classes from "./index.module.css";

const Panels = ({ loading, Panels, selectedOptions, handleCheckboxChange }) => {
  const navigate = useNavigate();

  const handleDropdownChange = (name, value) => {
    handleCheckboxChange(name, value);
  };

  return (
    <div className="">
      <div className={classes.checkboxContainer}>
        <NumberDropdown
          onChange={(value) => handleDropdownChange("rating", value)}
          label="Rating"
          selectedValue={selectedOptions.rating}
        />
        <NumberDropdown
          onChange={(value) => handleDropdownChange("eventsOrganized", value)}
          label="No of event Organized"
          selectedValue={selectedOptions.eventsOrganized}
        />

        <Checkbox
          className={classes.checkboxitem}
          name="sponsors"
          checked={selectedOptions.sponsors}
          onChange={(e) => handleCheckboxChange(e.target.name, e.target.checked)}>
          Sponsers
        </Checkbox>
      </div>
      <List
        itemLayout="horizontal"
        className="searchedlistItemParent"
        loading={loading}
        dataSource={Panels || []}
        renderItem={(item, index) => {
          return (
            <List.Item
              key={index}
              onClick={() => {
                navigate(`/view/event/${item?._id}`, { state: "event" });
              }}
              className={classes.listContainer}>
              {item.typeofsports && (
                <div className={classes.topTitle}>
                  {item.typeofsports + "-"} <span>{item?.nameofsports?.toUpperCase()}</span>
                </div>
              )}

              <div className={classes.fileContainer}>
                <img
                  src={KEYS?.API + "/" + item?.profilepic}
                  alt=""
                  style={{ borderRadius: "50%" }}
                />
              </div>
              <div style={{ flex: 1, marginTop: "1rem" }}>
                <h3 className={classes.title}>
                  {" "}
                  {item?.name} <Rate count={1} defaultValue={item?.ratings || 2.5} />{" "}
                  <span>{item?.ratings || 2.5}</span>
                </h3>
                <h3 className={classes.panel}>{item?.username || "username"}</h3>
                <h3 className={classes.panel}> Events Organized:</h3>
                <p className={classes.eventDate}>
                  {" "}
                  {item?.startDate ? dayjs(item?.startDate)?.format("DD MMMM ") : ""}
                </p>
                <div className={classes.task}>
                  <FaCheckCircle style={{ color: "#15b215" }} />
                  <span style={{ color: "#0085ff" }}>{item?.eventsParticipated || 0}</span>
                  <IoTimerSharp style={{ color: "red", marginLeft: "10px" }} />
                  <span style={{ color: "red" }}>{item?.ongoingEvents || 0}</span>
                </div>

                <div className={classes.searchListItemInfo}>
                  <span>
                    <FiUsers />
                  </span>
                  {item?.followers?.length + " Followers"}
                </div>
              </div>
              <div className={classes.rewardContainer}>
                <div className={classes.reward}>
                  <p>
                    {" "}
                    <span>💵</span> {item?.fee || 0} Rs
                  </p>
                  <p>
                    {" "}
                    <span>🏅</span> {item?.certificate || 0} Certificate
                  </p>
                  <p>
                    {" "}
                    <span>🛡️</span> {item?.shield || 0} Shield
                  </p>
                </div>
                <div className={classes.sponsors}>
                  <p>Sponsors:</p>
                  <div>
                    <SiNike />
                  </div>
                  <div
                    style={{
                      borderLeft: "0.5px solid #c3c3c3",
                      paddingLeft: "15px"
                    }}>
                    <SiNike />
                  </div>
                </div>
              </div>
            </List.Item>
          );
        }}
      />
    </div>
  );
};

export default Panels;
