import { Skeleton } from "antd";
import React from "react";

function PostSkelton({ cardloading, ppicloading }) {
  //image and video loader

  return cardloading || ppicloading ? (
    <div style={{ width: "100%" }}>
      <Skeleton style={{ padding: "2rem 1rem 1rem 1rem" }} avatar active />
      <Skeleton style={{ padding: "0rem 1rem 2rem 4.5rem" }} paragraph active />
    </div>
  ) : null;
}

export default PostSkelton;
