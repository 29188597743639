import { GetKnockOutEventData } from "../../../../../../../Config/API/events";
import useRoundsButtons from "../useRoundsUtility/useRoundsButtons";
import { error } from "../../../../../../../common/Alerts/Message";
import Loader from "../../../../../../../common/cards/Loader";
import {
  isLoading,
  setRoundes,
  load,
  ROUNDES,
  unload,
  setBYE
} from "../../../../../../../Store/Features/kobracketslice";
import { useRoundsUtility } from "../useRoundsUtility";
import { useDispatch, useSelector } from "react-redux";
import React, { Fragment, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Bracket } from "react-brackets";
import { Col, Row } from "antd";
import { useState } from "react";

const SingleEliminationBrackets = () => {
  const { id } = useParams();

  const dispatch = useDispatch();
  var Rounds = useSelector(ROUNDES);
  const loading = useSelector(isLoading);

  const [teams, setteams] = useState([]);
  const [korounds, setkorounds] = useState([]);
  // apicall for team replace
  const getroundes = async () => {
    try {
      dispatch(load());
      var response = null,
        data = null,
        values = {
          eventId: id,
          flag: "web"
        };
      response = await GetKnockOutEventData(values);
      data = await response.json();
      console.log({
        data,
        singleEle: true
      });
      if (data.type === "success") {
        dispatch(load());
        dispatch(setRoundes(data?.result));
        dispatch(setBYE(data?.data?.isBYE));
      } else {
        error(data?.result);
      }
      dispatch(unload());
    } catch (e) {
      dispatch(unload());
      console.log(e);
      error(e.message);
    }
  };

  const getroundname = (round) => {
    // 1:final
    // 2:semi-final
    // 4:qaurter-final
    // 8:round of 16
    // 16:round of 32
    if (round?.length === 1) return "Final";
    else if (round?.length === 2) return "Semi Final";
    else if (round?.length === 4) return "Quarter Final";
    else {
      const l = round?.length * 2;
      return "Rounds of " + l;
    }
  };

  useEffect(() => {
    if (!Array.isArray(Rounds)) return;
    if (Rounds?.length > 0) {
      let r = Rounds?.map((e) => {
        const name = getroundname(e);
        return { title: name, seeds: [...e] };
      });

      setkorounds(r);
    }
  }, [Rounds, dispatch]);

  useEffect(() => {
    getroundes();
    return () => {
      setteams([]);
    };
  }, []);

  const { CustomSeed } = useRoundsUtility(setteams);
  const { BracketsReplaceButtons, HistoryButtons } = useRoundsButtons(teams, setteams);

  const bye = useSelector((state) => state.ko.isBYE);
  return (
    <Fragment>
      <h1 className="ceheading">
        Knock out Matches
        {bye ? <p className="SubHeading">(BYE)</p> : null}
      </h1>
      <HistoryButtons origin={"knockouts"} loading={loading} />
      <Row className={`formorganizer kobracketsRow ${loading ? " rowloadingevent" : null}`}>
        {loading ? <Loader noShadow /> : null}
        <Col style={{ display: loading ? "none" : null }} span={24}>
          <BracketsReplaceButtons />
          <Bracket
            roundClassName="BracketRound"
            bracketClassName={`Brackets`}
            rounds={korounds}
            renderSeedComponent={CustomSeed}
            swipeableProps={{
              className: "react-brackets-mobile SmallScroll"
            }}
          />
        </Col>
      </Row>
    </Fragment>
  );
};

export default SingleEliminationBrackets;
