import React, { Fragment, useState } from "react";
import { Row, Col, Form, Button, Select, DatePicker, Switch, TimePicker } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { error, Info, success } from "../../../../../../common/Alerts/Message";
import {
  UpdateEventScheduledata,
  CreateKnockOutSlots,
  CreateKnockOutSlotsBYE,
  CreateDoubleEliminationSlots,
  CreateDoubleEliminationBYESlots
} from "../../../../../../Config/API/events";
import moment from "moment";
import { useEffect } from "react";
import Loader from "../../../../../../common/cards/Loader";
import useRoundsButtons from "../KO/useRoundsUtility/useRoundsButtons";
import Swal from "sweetalert2";
import { GAMES_MODES } from "../../../../../../Config/helpers/constants";

const { Option } = Select;

const EventScheduling = () => {
  const [loading, setloading] = useState(false);
  const [dob, setdob] = useState(null);
  const { state } = useLocation();
  const [parralelMatches, setparralelMatches] = useState(1);
  const [valtime, setvaltime] = useState("");
  const [valbtime, setvalbtime] = useState("");
  const [wantParrallelMatches, setwantParrallelMatches] = useState(false);
  const [Timeforonematch, setTimeforonematch] = useState();
  const [noOfKoGroups, setNoOfKoGroups] = useState();
  const [noOfTeamsFromEGroups, setNoOfTeamsFromEGroups] = useState();

  const [playingTime, setPlayingTime] = useState({
    hours: 0,
    minutes: 0
    // am_pm: null
  });
  const [breakTime, setbreakTime] = useState({
    hours: 0,
    minutes: 0
    // am_pm: null
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (!Timeforonematch) return;
    const t = Timeforonematch?.split(":");

    console.log(t, playingTime);
    if (
      parseInt(t[0]) > parseInt(playingTime?.hours) ||
      (parseInt(playingTime?.hours) == parseInt(t[0]) &&
        parseInt(playingTime?.minutes) < parseInt(t[1]))
    ) {
      setvaltime("error");
    } else setvaltime("success");
  }, [playingTime?.hours, playingTime?.minutes, Timeforonematch]);

  useEffect(() => {
    if (!Timeforonematch) return;
    const t = Timeforonematch?.split(":");
    const obj = {
      ph: parseInt(playingTime?.hours),
      pm: parseInt(playingTime?.minutes),
      bh: parseInt(breakTime?.hours),
      bm: parseInt(breakTime?.minutes),
      th: parseInt(t[0]),
      tm: parseInt(t[1])
    };

    if (obj?.ph === obj?.bh + obj?.th && obj?.pm < obj?.bm + obj?.tm) {
      return setvalbtime("error");
    } else if (obj?.ph < obj?.bh + obj?.th) {
      setvalbtime("error");
    } else setvalbtime("success");
  }, [
    playingTime?.hours,
    playingTime?.minutes,
    breakTime?.hours,
    breakTime?.minutes,
    Timeforonematch
  ]);

  useEffect(() => {
    if (!state) navigate(-1);

    if (state?._id && state?.scheduleComplete) {
      if (state?.typeofmatch === "ko" || state.typeofmatch === "knockout")
        navigate(`/organizer/event/${state?._id}/knock-out-matches`, {
          replace: true,
          state
        });
      else if (state?.typeofmatch === "doubleelimination")
        navigate(`/organizer/event/${state?._id}/knock-out-matches`, {
          replace: true,
          state
        });
      else
        navigate(`/organizer/event/${state?._id}/matches`, {
          replace: true,
          state
        });
    }
  }, [navigate, state, state?.scheduleComplete]);

  function onSelect(value, options) {
    if (String(options.key).toLowerCase().includes("parralelmatches")) {
      setparralelMatches(value);
    }
    if (String(options.key).toLowerCase().includes("playing")) {
      if (String(options.key).toLowerCase().includes("hours")) {
        setPlayingTime({ ...playingTime, hours: value });
      } else if (String(options.key).toLowerCase().includes("minutes")) {
        setPlayingTime({ ...playingTime, minutes: value });
      }
    }

    if (String(options.key).toLowerCase().includes("break")) {
      if (String(options.key).toLowerCase().includes("hours")) {
        setbreakTime({ ...breakTime, hours: value });
      } else if (String(options.key).toLowerCase().includes("minutes")) {
        setbreakTime({ ...breakTime, minutes: value });
      }
    }
  }

  const Schedule = async () => {
    try {
      if (
        (!playingTime?.hours === 0 && playingTime?.minutes === 0) ||
        dob === null ||
        valbtime === "error" ||
        valtime === "error"
      )
        return Info("Fill Out the details Correctly!");

      if (state?.typeofmatch === "league") {
        if (noOfKoGroups < 1 || !noOfKoGroups) {
          return Info("Add No. of Groups for Group Stages");
        }
        if (!noOfTeamsFromEGroups) {
          return Info("Select No. of Teams from Each Groups");
        }
      }
      if (!Timeforonematch) {
        return Info("Select Time Required for one match");
      }
      if (state?.typeofmatch === "doubleelimination") {
        const len = state?.registerteams?.length;
        if ((len & (len - 1)) !== 0 || len === 0) {
          return Info("Number of teams should be a power of 2");
        }
      }
      setloading("scheule");
      const values = {
        eventId: state?._id,
        playhours: (playingTime.hours || "00") + ":" + (playingTime.minutes || "00"),
        breaktime: (breakTime.hours || "00") + ":" + (breakTime.minutes || "00"),
        parallel: parralelMatches || "1",
        startfirstmatch: dob,
        timeforonematch: Timeforonematch
      };
      let res = null,
        data = null;
      if (state?.typeofmatch === "ko" || state.typeofmatch === "knockout") {
        values["noofgroups"] = 1;
        if (state?.registerteams?.length & (state?.registerteams?.length - 1 === 0)) {
          console.log("even");
          res = await CreateKnockOutSlots(values);
          data = await res.json();
        } else {
          await Swal.fire({
            icon: "info",
            title: "Odd number of teams",
            text: "Chose Methods below to continue",
            confirmButtonText: "BYE",
            // showCancelButton: true,
            cancelButtonText: "Mini Group"
          }).then(async (e) => {
            if (e.isConfirmed) {
              res = await CreateKnockOutSlotsBYE(values);
              console.log(values);
              data = await res.json();
              console.log(data);
            } else if (e.dismiss) {
              // Info("API not integrated yet!")
              return false;
            }
          });
        }
      } else if (state?.typeofmatch === "league") {
        values["noofgroups"] = noOfKoGroups;
        values["koteams"] = noOfTeamsFromEGroups * noOfKoGroups;
        res = await UpdateEventScheduledata(values);
        console.log(values);
        data = await res.json();
        console.log(data);
      } else if (state?.typeofmatch === "doubleelimination") {
        values["noofgroups"] = noOfKoGroups;
        values["koteams"] = noOfTeamsFromEGroups * noOfKoGroups;
        if (state?.registerteams?.length & (state?.registerteams?.length - 1 === 0)) {
          console.log("even");
          res = await CreateDoubleEliminationSlots(values);
        } else {
          await Swal.fire({
            icon: "info",
            title: "Odd number of teams",
            text: "Chose Methods below to continue",
            confirmButtonText: "BYE",
            // showCancelButton: true,
            cancelButtonText: "Mini Group"
          }).then(async (e) => {
            if (e.isConfirmed) {
              res = await CreateDoubleEliminationBYESlots(values);
            } else if (e.dismiss) {
              return false;
            }
          });
        }
        console.log(values);
        data = await res.json();
        console.log(data);
      } else if (state?.typeofmatch === GAMES_MODES.multi) {
        values["noofgroups"] = noOfKoGroups;
        values["koteams"] = noOfTeamsFromEGroups * noOfKoGroups;
        if (state?.registerteams?.length & (state?.registerteams?.length - 1 === 0)) {
          console.log("even");
          res = await CreateDoubleEliminationSlots(values);
        } else {
          await Swal.fire({
            icon: "info",
            title: "Odd number of teams",
            text: "Chose Methods below to continue",
            confirmButtonText: "BYE",
            // showCancelButton: true,
            cancelButtonText: "Mini Group"
          }).then(async (e) => {
            if (e.isConfirmed) {
              res = await CreateDoubleEliminationBYESlots(values);
            } else if (e.dismiss) {
              return false;
            }
          });
        }
        console.log(values);
        data = await res.json();
        console.log(data);
      }
      setloading(false);

      if (data?.type === "success") {
        setloading(false);
        success("Event Scheduled Successfully!");
        if (
          state?.typeofmatch === "ko" ||
          state.typeofmatch === "knockout" ||
          state?.typeofmatch === "doubleelimination"
        ) {
          navigate(`/organizer/event/${state?._id}/knock-out-matches`, {
            replace: true,
            state: { ...state, koteams: values?.koteams }
          });
        } else {
          navigate(`/organizer/event/${state?._id}/matches`, {
            replace: true,
            state: { ...state, koteams: values?.koteams }
          });
        }
      } else if (typeof data === "object") {
        if (data?.result) error(data?.result);
        setloading(false);
      }
    } catch (e) {
      console.log(e);
      error(e.message);
      setloading(false);
    }
  };

  // const timeforonematch = state?.timeforonematch
  let message = "max playing time could be (" + Timeforonematch + ")";
  let messageb = "Both Match and break time should not exceed Playing Time";

  const { HistoryButtons } = useRoundsButtons();

  return (
    <Fragment>
      <div style={{ maxWidth: "100%" }}>
        <h1 className="ceheading">Event Scheduling</h1>
        <HistoryButtons origin={"schedule"} loading={loading} />
        <div className="formorganizer">
          <Row>
            <Col lg={12} md={12} sm={24} xs={24} className="ceinputformpadding">
              <label className="boldlabel" style={{ fontSize: "17px" }}>
                Number of play hours each day
              </label>
              <Form
                style={{
                  display: "flex",
                  gap: "8%",
                  marginTop: "35px",
                  position: "relative"
                }}
                name="basic"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                autoComplete="off">
                <Form.Item
                  style={{ position: "absolute", width: "100%", bottom: "0px" }}
                  help={valtime === "error" ? message : null}
                  hasFeedback
                  required
                  validateStatus={valtime}
                />
                <Form.Item className="profileItems">
                  <label className="boldlabel">Hours</label>
                  <Select
                    className="ceinputfieldnos selectSmaller"
                    title="Hours"
                    bordered={true}
                    onSelect={onSelect}>
                    {Array(24)
                      .fill(null)
                      .map((_, i) => (
                        <Option
                          value={i < 10 ? "0" + (i + 1) : `${i + 1}`}
                          key={`playingHours${i + 1}`}>
                          {i + 1}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item className="profileItems">
                  <label className="boldlabel">Minutes</label>
                  <Select
                    className="ceinputfieldnos selectSmaller"
                    title="Minutes"
                    bordered={true}
                    onSelect={onSelect}>
                    {Array(60)
                      .fill(null)
                      .map((_, i) => (
                        <Option value={i < 10 ? "0" + i : i} key={`playingMinutes${i}`}>
                          {i}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Form>
              <br />
              <label className="boldlabel" style={{ fontSize: "17px" }}>
                Break between each match
              </label>
              <Form
                style={{
                  display: "flex",
                  gap: "8%",
                  marginTop: "30px",
                  position: "relative"
                }}
                name="basic"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                autoComplete="off">
                <Form.Item
                  style={{ position: "absolute", width: "100%", bottom: "0px" }}
                  help={valbtime === "error" ? messageb : null}
                  hasFeedback
                  required
                  validateStatus={valbtime}
                />
                <Form.Item className="profileItems">
                  <label className="boldlabel">Hours</label>
                  <Select
                    className="ceinputfieldnos selectSmaller"
                    title="Hours"
                    bordered={true}
                    onSelect={onSelect}>
                    {Array(25)
                      .fill(null)
                      .map((_, i) => (
                        <Option value={i} key={`breakHours${i}`}>
                          {i}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item className="profileItems">
                  <label className="boldlabel">Minutes</label>
                  <Select
                    className="ceinputfieldnos selectSmaller"
                    title="Minutes"
                    bordered={true}
                    onSelect={onSelect}>
                    {Array(60)
                      .fill(null)
                      .map((_, i) => (
                        <Option value={i} key={`breakMinutes${i}`}>
                          {i}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Form>
            </Col>
            <ParralelMatchesComp
              state={state}
              setdob={setdob}
              valbtime={valbtime}
              message={messageb}
              onSelect={onSelect}
              noOfKoGroups={noOfKoGroups}
              setNoOfKoGroups={setNoOfKoGroups}
              timeforonematch={Timeforonematch}
              setTimeforonematch={setTimeforonematch}
              noOfTeamsFromEGroups={noOfTeamsFromEGroups}
              wantParrallelMatches={wantParrallelMatches}
              setNoOfTeamsFromEGroups={setNoOfTeamsFromEGroups}
              setwantParrallelMatches={setwantParrallelMatches}
            />
          </Row>
        </div>
      </div>
      <Row justify="center" style={{ margin: "50px 10px" }}>
        <Button
          style={{ margin: "0" }}
          className="draftbutton equalWidhtButton"
          type="primary"
          onClick={Schedule}>
          Schedule
        </Button>
      </Row>
      {loading ? <Loader fullpage /> : null}
    </Fragment>
  );
};

const ParralelMatchesComp = ({
  state,
  setdob,
  onSelect,
  valbtime,
  message,
  noOfKoGroups,
  setNoOfKoGroups,
  timeforonematch,
  setTimeforonematch,
  noOfTeamsFromEGroups,
  wantParrallelMatches,
  setNoOfTeamsFromEGroups,
  setwantParrallelMatches
}) => {
  const handleChange = (value, variable) => {
    if (value === undefined) return;
    if (variable === "group") {
      if (noOfKoGroups === 0) {
        return setNoOfKoGroups("error");
      }
      if ((noOfKoGroups & (noOfKoGroups - 1)) !== 0 || noOfKoGroups < 2) {
        return setNoOfKoGroups("error");
      } else {
        setNoOfTeamsFromEGroups();
        return setNoOfKoGroups(value);
      }
    } else {
      if (noOfTeamsFromEGroups === 0) {
        return setNoOfTeamsFromEGroups("error");
      }
      if ((noOfTeamsFromEGroups & (noOfTeamsFromEGroups - 1)) !== 0 || noOfTeamsFromEGroups < 2) {
        return setNoOfTeamsFromEGroups("error");
      } else {
        return setNoOfTeamsFromEGroups(value);
      }
    }
  };

  const condition = state?.typeofmatch === "league";
  const condition2 = state?.registerteams?.length;
  const condition3 = condition2 / noOfKoGroups;

  return (
    <Col lg={12} md={12} sm={24} xs={24} className="ceinputformpadding">
      <Form
        style={{ gap: "5%" }}
        name="basic"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        autoComplete="off">
        <label className="boldlabel" style={{ fontSize: "17px" }}>
          {/* parralel Matches */}
          Do you want parallel matches?
          <Switch
            style={{ marginLeft: "5px" }}
            checked={wantParrallelMatches}
            onChange={() => setwantParrallelMatches((e) => !e)}
          />
        </label>
        <Form.Item
          style={{ display: wantParrallelMatches ? "block" : "none" }}
          className="profileItems">
          <Select
            className="ceinputfieldnos selectSmall"
            title="parralelMatches"
            bordered={true}
            defaultValue={1}
            onSelect={onSelect}>
            {Array(10)
              .fill(null)
              .map((_, i) => (
                <Option value={i + 1} key={`parralelMatches${i}`}>
                  {i + 1}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Form>
      <Form
        style={{ display: "flex", gap: "8%", marginTop: "30px" }}
        name="basic"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        autoComplete="off">
        <Form.Item className="profileItems">
          <label className="boldlabel">Starting Time of First Match</label>
          <DatePicker
            disabledDate={(current) => {
              return (
                current < moment(state?.startDate).startOf("day") ||
                current > moment(state?.endDate).startOf("day") ||
                current < moment().startOf("day")
              );
            }}
            format="YYYY-MM-DD HH:mm:ss"
            onChange={(e, o) => setdob(o)}
          />
        </Form.Item>
      </Form>
      <Form.Item
        help={valbtime === "error" ? message : null}
        hasFeedback
        required
        validateStatus={valbtime}
        className="halfwidth profileItems">
        <label className="boldlabel">Time Required for one Match</label>
        <TimePicker
          format={"HH:mm"}
          showNow={false}
          inputReadOnly
          value={timeforonematch ? moment(timeforonematch, "HH:mm") : undefined}
          className="mt8"
          onChange={(e, i) => {
            let a = String(i).split(":");
            if (a[0] === "00" && a[1] === "00") {
              Info("Time can't be 00:00");
              setTimeforonematch(null);
            } else setTimeforonematch(i);
          }}
        />
      </Form.Item>
      {condition ? (
        <Form.Item
          help={noOfKoGroups === "error" ? "groups should be 2,4,8,16,32...." : null}
          hasFeedback
          required
          validateStatus={noOfKoGroups === "error" ? "error" : noOfKoGroups ? "success" : undefined}
          className="halfwidth profileItems">
          <label className="boldlabel">
            {state?.typeofmatch === GAMES_MODES.multi
              ? "No. of Groups"
              : "No. of Groups for Group Stages"}
          </label>

          <Select
            value={noOfKoGroups !== "error" && noOfKoGroups ? noOfKoGroups : undefined}
            placeholder=" 1,2,4,8,16,32...."
            style={{ minWidth: "200px" }}
            allowClear
            onChange={(e) => handleChange(e, "group")}
            onClear={() => {
              console.log("cleared");
              setNoOfKoGroups();
            }}
            options={Array.from({ length: 301 }, (_, i) => {
              const condition = i > 1 && i <= condition2 - 1 && (i & (i - 1)) === 0;
              return condition ? { label: i, value: i } : null;
            }).filter((e) => e)}
          />
        </Form.Item>
      ) : null}
      {condition ? (
        <Form.Item
          help={noOfTeamsFromEGroups === "error" ? "teams should be" + condition3 - 1 : null}
          hasFeedback
          required
          validateStatus={
            noOfTeamsFromEGroups === "error"
              ? "error"
              : noOfTeamsFromEGroups
              ? "success"
              : undefined
          }
          className="halfwidth profileItems">
          <label className="boldlabel">No. of teams moving from each from each group</label>

          <Select
            value={
              noOfTeamsFromEGroups !== "error" && noOfTeamsFromEGroups
                ? noOfTeamsFromEGroups
                : undefined
            }
            placeholder={"1,2,3,4,...."}
            style={{ minWidth: "200px" }}
            allowClear
            onChange={handleChange}
            onClear={() => {
              setNoOfTeamsFromEGroups();
            }}
            options={Array.from({ length: 1000 }, (_, i) => {
              const cond = (i & (i - 1)) === 0 && i > 0;
              return cond && i < condition3 ? { label: i, value: i } : null;
            }).filter((e) => e)}
          />
        </Form.Item>
      ) : null}
    </Col>
  );
};
export default EventScheduling;
