import React, { Fragment, useEffect, useState } from "react";
import { Col, Row } from "antd";
import RightBar from "../../../common/righbar";
import { SideBar } from "../../../common/sidebar";
import { error } from "../../../common/Alerts/Message";
import Loader from "../../../common/cards/Loader";
import NoData from "../../../common/cards/noData";
import { GetAllDraftedCampaigns } from "../../../Config/API/campaigns";
import SponsorContracts from "../../../common/sidebar/SponsorContracts";
import CampaignCard from "./CampaignCard";

const DraftCampaigns = () => {
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setloading] = useState(false);
  const getCampaigns = async () => {
    try {
      setloading(true);
      const response = await GetAllDraftedCampaigns();
      const data = await response.json();

      if (data?.type === "success") {
        setCampaigns(data?.result);
      }
      setloading(false);
    } catch (e) {
      error(e || "An Error Occurred");
      setloading(false);
    }
  };

  useEffect(() => {
    getCampaigns();
  }, []);

  return (
    <Fragment>
      <Row justify="space-between" className="rowPadding">
        {loading && <Loader fullpage loading={"white"} />}
        <SideBar description ratingPanel location awards={false} contracts={<SponsorContracts />} />
        <Col
          xs={{ span: 24, order: 3 }}
          sm={{ span: 24, order: 3 }}
          md={{ span: 24, order: 3 }}
          lg={{ span: 11, order: 2 }}>
          <Row className="mobileto">
            {campaigns?.length > 0 ? (
              campaigns?.map((e, i) => <CampaignCard data={e} key={i} drafted />)
            ) : (
              <NoData noEvent />
            )}
          </Row>
        </Col>
        <RightBar />
      </Row>
    </Fragment>
  );
};

export default DraftCampaigns;
