import React, { useState } from "react";
import { Modal, Form, Select, Button } from "antd";
import FadeInImage from "../../../../common/Images/FadeInImage";
const { Option } = Select;
import { fallbackprofile } from "../../../../common/Utils";
import { KEYS } from "../../../../Config/Keys";
import { EventWildCardRegistration } from "../../../../Config/API/events";
import { error, success } from "../../../../common/Alerts/Message";

function WildCardRegistrationModal({ eventId, wildCards, open, setOpen, teams }) {
  const [payload, setPayload] = useState({ team: "", wildCard: "" });
  const [loading, setLoading] = useState(false);

  const handleWildCardRegistration = async () => {
    try {
      setLoading(true);
      const values = {
        id: eventId,
        teamId: payload.team,
        wildCard: payload.wildCard
      };

      const res = await EventWildCardRegistration(values);
      const result = await res.json();
      if (result?.type === "success") {
        success("Team Registered for wild card entry");
        setLoading(false);
        setOpen(false);
      } else {
        error(result?.result || result?.type?.error?.message || result?.type?.error);
      }
    } catch (error) {
      error(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Modal
        title="Choose Wild Card"
        className="editPost AddTeamModel"
        confirmLoading
        mask
        maskClosable
        onCancel={() => {
          setOpen(false);
        }}
        closable={false}
        centered
        destroyOnClose={true}
        visible={open}
        open={open}
        footer={false}
        bodyStyle={{ padding: "50px 20px 20px 20px" }}>
        <Form.Item>
          <label className="boldlabel">Select Wild Card</label>
          <div>
            <Select
              className="TeamSelect"
              title="Select Wild Card"
              bordered={true}
              placeholder="Select"
              value={payload?.wildCard}
              onSelect={(e) => {
                setPayload({ ...payload, wildCard: e });
              }}>
              {wildCards?.map((card) => (
                <Option value={card?._id} key={card?._id}>
                  {card?.name} | Fee: {card?.registrationFee}
                </Option>
              ))}
            </Select>
          </div>
        </Form.Item>

        <Form.Item>
          <label className="boldlabel">Select team</label>
          <div>
            <Select
              showSearch
              style={{
                marginBottom: "15px",
                width: "100%"
              }}
              className="TeamSelect"
              onChange={(e) => setPayload({ ...payload, team: e })}
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.children?.props?.children[1].props?.children ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }>
              {teams?.map((e) => (
                <Option value={e?._id} key={e?._id}>
                  <span style={{ display: "flex", alignItems: "center", gap: ".5rem" }}>
                    <FadeInImage
                      type={"Fadepostprofile"}
                      fallaback={fallbackprofile}
                      src={KEYS.API + "/" + e?.profilepic}
                    />
                    <h4 style={{ marginBottom: "0" }}>{e?.name}</h4>
                  </span>
                </Option>
              ))}
            </Select>
          </div>
        </Form.Item>

        <Button
          loading={loading}
          disabled={!payload?.team || !payload?.wildCard || loading}
          onClick={handleWildCardRegistration}
          style={{
            margin: "0px auto",
            display: "block",
            fontWeight: "500",
            fontSize: "larger"
          }}
          className="btnMobileDimensions"
          type="primary">
          Wild Card Entry
        </Button>
      </Modal>
    </>
  );
}

export default WildCardRegistrationModal;
