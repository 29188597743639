import { useState } from "react";
import { EditTeamAPI } from "../../Config/API/teams";
import { EditPanelAPI } from "../../Config/API/panel";
import { EditProfilePlayerAPI } from "../../Config/API/player";
import { EditProfileOrgAPI } from "../../Config/API/organizer";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setProfile } from "../../Store/Features/ProfileSlice";
import { success } from "../Alerts/Message";
import { ROLES } from "../../Config/helpers/constants";
import { EditSponsorAPI } from "../../Config/API/sponsor";
function useEditProfile() {
  const [editloading, setEditloading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const edit = async (data, state, id) => {
    try {
      if (!state) {
        throw new Error("Error in state variable");
      }
      setEditloading(true);
      {
        let res;
        if (state === ROLES.team) {
          res = await EditTeamAPI(data);
          data = await res.json();
        } else if (state === ROLES.player) {
          res = await EditProfilePlayerAPI(data);
          data = await res.json();
        } else if (state === ROLES.organizer) {
          res = await EditProfileOrgAPI(data);
          data = await res.json();
        } else if (state === ROLES.panel) {
          res = await EditPanelAPI(data);
          data = await res.json();
        } else if (state === ROLES.sponsor) {
          res = await EditSponsorAPI(data);
        }
        if (data.type === "success") {
          if (state !== "team") {
            const d = {
              ...data?.data,
              cover: data?.data?.coverpic,
              profile: data?.data?.profilepic,
              _id: data?.data?._id,
              name: data?.data?.name,
              user: data?.data?.user,
              followers: data?.data?.followers,
              followings: data?.data?.followings,
              description: data?.data?.description,
              profileId: data?.data?.profile?._id
            };
            const d2 = {
              id: data?.data?._id,
              flag: state,
              name: data?.data?.name,
              profile: data?.data?.profilepic,
              cover: data?.data?.coverpic,
              description: data?.profile?.description
            };
            localStorage.setItem("viewer", JSON.stringify(d2));
            dispatch(setProfile(d));
            localStorage.setItem("ProfilePic", d?.profile);
            localStorage.setItem("CoverPic", d?.cover);
            success("Profile Updated Successfully!");
            localStorage.setItem("name", data?.data?.name);
          }
          if (state === ROLES.team) {
            navigate(`/player/team/${id}/feed`);
          } else if (state === ROLES.panel) {
            navigate(`/organizer/panel/${id}/feed`);
          } else if (state === ROLES.player) {
            navigate("/player/timeline");
          } else if (state === ROLES.organizer) {
            navigate("/organizer/timeline");
          } else if (state === ROLES.sponsor) {
            navigate("/sponsor/timeline");
          }
        }
      }
      setEditloading(false);
    } catch (e) {
      console.log(e.message);
      setEditloading(false);
    }
  };
  useEffect(() => {
    return () => {
      setEditloading(false);
    };
  }, []);
  return [editloading, edit];
}

export default useEditProfile;
