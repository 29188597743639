import React, { useEffect, useState } from "react";
import { Button, Col, Form, Image, Row } from "antd";
import { fallbackcover } from "../../../common/Utils";
import { Formik } from "formik";
import { createCampaignValidations } from "../../../Config/helpers/validations";
import InputTextField from "../../../common/FormItem/InputTextField";
import { error, success, Info } from "../../../common/Alerts/Message";
import CampaignActivity from "./CampaignActivity";
import {
  GetSingleCampaignWithActivities,
  SponsorCreateCampaign,
  SponsorUpdateCampaign
} from "../../../Config/API/campaigns";
import { useParams } from "react-router-dom";
import Loader from "../../../common/cards/Loader";
import { KEYS } from "../../../Config/Keys";

function CreateCampaign({ isEdit }) {
  const [loading, setLoading] = useState(false);
  const [campaignLoading, setCampaignLoading] = useState(false);
  const [campaign, setCampaign] = useState();
  const { campaignId } = useParams();
  const [file, setfile] = useState({
    file: null,
    preview: ""
  });

  // input type image handler
  const onImageFileChange = (e) => {
    const file = e.target.files[0];
    setfile({
      file: file,
      preview: URL.createObjectURL(file)
    });
  };

  const initialValues = {
    name: campaign?.name || "",
    description: campaign?.description || ""
  };

  const handleCreateUpdateCampaign = async (values) => {
    // check if picture was uploaded
    if (!isEdit && file?.preview?.length === 0 && !file.file) {
      return error("Upload picture first");
    }
    setLoading(true);

    const { name, description } = values;

    const formdata = new FormData();
    formdata.append("name", name);
    formdata.append("description", description);

    if (file?.preview?.length > 0 && file.file) {
      formdata.append("file", file.file);
    }

    try {
      let response = null;
      if (isEdit) {
        formdata.append("campaignId", campaign?._id);
        response = await SponsorUpdateCampaign(formdata);
      } else {
        response = await SponsorCreateCampaign(formdata);
      }

      const data = await response.json();
      if (data.type === "success") {
        console.log(data);
        const campaignData = data?.campaign;
        success("Campaign created successfully ✨");
        Info("Add activities");

        setLoading(false);
        setCampaign({
          _id: campaignData?._id,
          sponsorId: campaignData?.sponsor,
          name,
          description
        });
      } else {
        error("An error occurred");
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      error(e.message);
    }
  };

  const getCampaignData = async () => {
    try {
      setCampaignLoading(true);
      const response = await GetSingleCampaignWithActivities(campaignId);
      const data = await response.json();
      if (data.type === "success") {
        setCampaignLoading(false);
        const result = data?.result?.[0];
        setCampaign({
          ...result,
          _id: result?._id,
          sponsorId: result?.sponsor
        });
      } else {
        error("An error occurred while getting campaign");
        setCampaignLoading(false);
      }
    } catch (e) {
      setCampaignLoading(false);
      error(e.message);
    }
  };

  useEffect(() => {
    if (isEdit) {
      getCampaignData();
    }
  }, [isEdit]);

  return (
    <React.Fragment>
      <div>
        {campaignLoading && <Loader fullpage loading={"white"} />}
        <h1 className="ceheading">{isEdit ? "Update" : "Create"} Campaign</h1>
        <Row style={{ marginBottom: "30px" }}>
          <Col sm={24} xs={24} md={24} style={{ position: "relative" }}>
            <div
              style={{
                position: "relative",
                width: "96%",
                margin: "auto",
                background: "#DADADA",
                borderRadius: "8px"
              }}
              className="CoverImageWrapper">
              {file?.preview?.length > 1 || campaign?.picture ? (
                <Image
                  wrapperClassName="CoverImageWrapper"
                  className="CoverImage"
                  preview={false}
                  src={file?.preview || KEYS.API + "/" + campaign?.picture}
                  style={{
                    objectFit: file?.preview ? "cover" : "cover"
                    // transform: coverImage ? "scale(.5)" : "scale(1)"
                  }}
                  fallback={fallbackcover}
                />
              ) : null}
              <div className="profileuploaders">
                <label htmlFor="coveruploader" style={{}}>
                  <img src="/images/icons/camera.png" alt="" />
                  {file?.preview === "" ? <span>Add Image</span> : null}
                </label>
                <input
                  type="file"
                  name="CoverImage"
                  className="uploaderInput"
                  id="coveruploader"
                  accept="image/*"
                  onChange={onImageFileChange}
                />
              </div>
            </div>
          </Col>
          <Col sm={24} xs={24} md={24}>
            {(!campaign || isEdit) && (
              <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={createCampaignValidations}
                onSubmit={handleCreateUpdateCampaign}>
                {({ isValid, dirty, handleSubmit }) => (
                  <Form
                    name="basic"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    onFinish={handleSubmit}>
                    <div
                      className="formorganizer"
                      style={{ marginTop: "2%", padding: "10px 40px" }}>
                      <Row gutter={12}>
                        <Col span={12}>
                          <InputTextField
                            label="Campaign Name"
                            name="name"
                            required
                            type="text"
                            placeholder="Campaign Name"
                          />
                        </Col>
                        <Col span={12}>
                          <InputTextField
                            label="Campaign description"
                            name="description"
                            required
                            type="textArea"
                            placeholder="Campaign description"
                          />
                        </Col>
                        <Button
                          loading={loading}
                          style={{ maxWidth: "280px", margin: "0" }}
                          disabled={!(isValid && dirty) || loading}
                          className="ceprimarybutton blueButton"
                          type="primary"
                          htmlType="submit">
                          {isEdit ? "Update" : "Save and Next"}
                        </Button>
                      </Row>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
            {(campaign || isEdit) && <CampaignActivity campaign={campaign} />}
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}

export default CreateCampaign;
