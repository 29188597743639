import { TRANSACTION_CONSTANTS } from "../helpers/constants";
import { KEYS } from "../Keys";

export const GetUserWallet = async (ownertype, id) => {
  const payload = {
    id, //id of user, team, panel etc
    ownertype //[player, team, panel, organizer]
  };
  const response = await fetch(KEYS.API + "/transaction/getplayerwallet", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(payload)
  });
  return response;
};

/**
 * Top up or withdraw from user wallet
 * @param {String} userId User ID [player,organizer,panel,team etc]
 * @param {String} type Transaction type : top-up, withdraw
 * @param {Number} amount Transaction Amount
 * @param {String} ownerType player, organizer, panel, team etc
 */
export const UserWalletTopUp = async ({ userId, type, values, ownerType }) => {
  const formData = new FormData();
  formData.append("userId", userId);
  formData.append("type", type); //top-up,
  formData.append("amount", values?.amount);
  formData.append("file", values?.file?.[0]);
  formData.append("ownerType", ownerType);

  try {
    let values = formData;
    const response = await fetch(KEYS.API + "/transaction/initiatetransaction", {
      method: "POST",
      headers: {
        authorization: `Bearer ${localStorage.getItem("userToken")}`
      },
      body: values
    });
    return response;
  } catch (error) {
    return await error;
  }
};

/**
 * Transfer coins to another wallet 
 * @param {String} receiverWallet Wallet ID of receiver
 * @param {Number} amount Amount to transfer 

 */
export const TransferTokensToOtherWallet = async (
  receiverWallet,
  amount,
  ownerType,
  addBeneficiary,
  userId
) => {
  const formData = new FormData();

  formData.append("type", TRANSACTION_CONSTANTS.transfer);
  formData.append("walletId", receiverWallet);
  formData.append("amount", amount);
  formData.append("ownerType", ownerType);
  formData.append("status", TRANSACTION_CONSTANTS.completed);
  formData.append("addBeneficiary", addBeneficiary);
  formData.append("userId", userId);

  try {
    let values = formData;
    const response = await fetch(KEYS.API + "/transaction/initiatetransaction", {
      method: "POST",
      headers: {
        authorization: `Bearer ${localStorage.getItem("userToken")}`
      },
      body: values
    });
    return response;
  } catch (error) {
    return await error;
  }
};

/**
 * Get user transaction history
 * @param {String} id User ID [player,organizer,panel,team etc]
 * @param {String} status [complete, pending or null  for both]
 */
export const GetTransactionHistory = async (id, status, offset, limit) => {
  const payload = {
    id
  };

  if (status) {
    payload.status = status;
  }

  try {
    const response = await fetch(
      KEYS.API + `/transaction/gettransactionshistory?skip=${offset}&limit=${limit}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage.getItem("userToken")}`
        },
        body: JSON.stringify(payload)
      }
    );
    return response;
  } catch (error) {
    return await error;
  }
};

/**
 * Initiates a transaction to withdraw funds from a user's wallet.
 *
 * @param {Object} data - An object containing the necessary data for the withdrawal transaction.
 * @param {String} data.userId - The ID of the user initiating the withdrawal.
 * @param {String} data.type - The type of transaction (withdraw).
 * @param {String} data.ownerType - The type of owner (e.g., player, organizer, panel, team).
 * @param {Number} data.amount - The amount to be withdrawn.
 * @param {String} data.accountNumber - The account number for the withdrawal.
 * @param {String} data.accountTitle - The title of the account for the withdrawal.
 * @param {String} data.bankName - The name of the bank for the withdrawal.
 * @param {String} data.mobileNumber - The mobile number associated with the withdrawal.
 * @param {File} data.file - The file associated with the withdrawal (e.g., proof of identity).
 * @return {Promise} A promise that resolves to the response from the transaction initiation API.
 */
export const UserWalletWithdraw = async (data) => {
  const {
    userId,
    type,
    ownerType,
    amount,
    accountNumber,
    accountTitle,
    bankName,
    mobileNumber,
    file
  } = data;
  const formData = new FormData();
  formData.append("userId", userId);
  formData.append("type", type); // withdraw
  formData.append("ownerType", ownerType);
  formData.append("amount", amount);
  formData.append("accountNumber", accountNumber);
  formData.append("accountTitle", accountTitle);
  formData.append("bankName", bankName);
  formData.append("mobileNumber", mobileNumber);
  formData.append("file", file);

  try {
    let values = formData;
    const response = await fetch(KEYS.API + "/transaction/initiatetransaction", {
      method: "POST",
      headers: {
        authorization: `Bearer ${localStorage.getItem("userToken")}`
      },
      body: values
    });
    return response;
  } catch (error) {
    return await error;
  }
};

export const getAdminBankInfo = async () => {
  try {
    const response = await fetch(KEYS.API + "/account/admin", {
      method: "GET",
      headers: {
        authorization: `Bearer ${localStorage.getItem("userToken")}`
      }
    });
    return response;
  } catch (error) {
    return await error;
  }
};
