import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Button } from "antd";

const HomeNav = () => {
  return (
    <Row>
      <Col flex={1}>
        <Link to="/">
          <img className="navlogo" alt="logo" height="40px" src="/images/logo.png" />
        </Link>
      </Col>
      <Col>
        <Link className="navlink customLink" to="/register">
          Register
        </Link>
        <Link className="navlink" to="/signin">
          <Button
            style={{
              height: "34px",
              borderRadius: "6px",
              width: "110px"
            }}
            type="primary">
            Sign in
          </Button>
        </Link>
      </Col>
    </Row>
  );
};

export default HomeNav;
