import { Row, Col, Button } from "antd";
import React, { useState, useEffect, Fragment } from "react";
import ActiveMembers from "./ActiveMembers";
import InviteMembers from "./InviteMembers";
// import RequestMembers from "./RequestMembers";
import { SideBar } from "../../../../common/sidebar";
import { GetSinglePanel } from "../../../../Config/API/panel";
import { Link, useParams, useNavigate } from "react-router-dom";
import { error } from "../../../../common/Alerts/Message";
import Loader from "../../../../common/cards/Loader";
import { AiOutlinePlusCircle } from "react-icons/ai";
import NoData from "../../../../common/cards/noData";
import { useSelector } from "react-redux";
import { PROFILE } from "../../../../Store/Features/ProfileSlice";
import useVariables from "../../../../common/hooks/useVariables";

const PanelMember = () => {
  const [active, setactive] = useState("primary");
  const [invite, setinvite] = useState("");
  const [panel, setpanel] = useState({});
  const [loading, setloading] = useState(false);
  const [status, setstatus] = useState(false);
  const [object, setobject] = useState({});
  const { id } = useParams();
  const navigate = useNavigate();

  const profile = useSelector(PROFILE);
  const { viewer } = useVariables();

  const getpanel = async () => {
    try {
      setloading(true);
      const response = await GetSinglePanel(id);
      const data = await response.json();
      const result = data?.result?.data || data?.result;

      const captain = {
        organizer: {
          _id: result?.profile?._id,
          profilepic: result?.profile?.profilepic,
          name: result?.profile?.name
        },
        role: "Panel Creator",
        active: true,
        owner: true
      };
      setobject(captain);
      result?.members?.unshift(captain);
      setpanel(result);
      setstatus(result.profile._id === viewer?.id);

      setloading(false);
    } catch (e) {
      setloading(false);
      console.log(e);
      error(e.message);
    }
  };
  useEffect(() => {
    if (!id) {
      navigate("/organizer/panels");
    }
    getpanel();
    return () => {
      setactive(null);
      setinvite(null);
      setpanel(null);
      setloading(false);
      setstatus(false);
    };
  }, [id, navigate]);

  //  console.log(state);
  // id is the panelId

  return (
    <Row className="rowPadding">
      <SideBar description ratingPanel md />
      <Col xs={24} sm={24} md={24} lg={14} xl={16}>
        <Row className="mdWidth">
          {/* <Col lg={24} md={24} sm={24} xs={24}>
            <h1
              style={{ paddingLeft: "0" }}
              className="popadding">
              Panel Members
            </h1>
          </Col> */}
          <Col
            span={24}
            className="mobilecenterbutton"
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              gap: "0 70px",
              marginBottom: "10px"
            }}>
            <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
              <Button
                style={{ marginLeft: "0", marginTop: 0 }}
                className="buttonpo"
                onClick={() => {
                  setactive("primary");
                  setinvite("");
                }}
                type={active}>
                Active Members
              </Button>
              <Button
                style={{ marginLeft: "0", marginTop: 0 }}
                className="buttonpo "
                type={invite}
                onClick={() => {
                  setactive("");
                  setinvite("primary");
                }}>
                Invited Members
              </Button>
            </div>
            {status && (
              <Button
                type="primary"
                style={{ marginLeft: "0", marginTop: 0 }}
                className="buttonpo ">
                <Link
                  to="/organizer/panel/addmembers"
                  state={{ id, members: profile?.members }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: ".4rem",
                    justifyContent: "center"
                  }}>
                  Add Member
                  <AiOutlinePlusCircle fontSize={13} />
                </Link>
              </Button>
            )}
          </Col>

          {loading ? (
            <Loader />
          ) : (
            <Col span={24}>
              {panel?.members?.length > 0 ? (
                <Fragment>
                  {invite === "primary" ? <InviteMembers panel={panel} status={status} /> : null}
                  {active === "primary" ? (
                    <ActiveMembers
                      object={object}
                      panel={panel}
                      setpanel={setpanel}
                      status={status}
                    />
                  ) : null}
                </Fragment>
              ) : (
                <NoData noMembers />
              )}
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default PanelMember;
