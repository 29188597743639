import React from "react";
import { Fragment } from "react";
import { Image, Input, Button } from "antd";
import { RiSendPlane2Fill } from "react-icons/ri";
import VideoCard from "../Media/VideoCard";
import MediaUploader from "../../MediaUploader";
import { KEYS } from "../../../Config/Keys";
import { fallbackprofile } from "../../Utils";
import Loader from "../Loader";
import FadeInImage from "../../../common/Images/FadeInImage";
import useVariables from "../../hooks/useVariables";

function UploadPostcard({
  onPost,
  text,
  setText,
  uploadingMedia,
  setuploadingMedia,
  IMAGE,
  setimage,
  VIDEO,
  setvideo,
  loading,
  border,
  edit,
  setLuploading,
  Luploading,
  setIsFileRemoved
}) {
  //     uploadingMedia,
  //     setuploadingMedia,
  const { viewer } = useVariables();

  return (
    <Fragment>
      <div
        className={`toinputdiv ${border && "noPaddMargin"} ${edit}`}
        style={{
          borderColor: Luploading ? "transparent" : undefined,
          transition: "all .2s ease"
        }}>
        <FadeInImage
          type="Fadenavprofile"
          fallaback={fallbackprofile}
          src={KEYS.API + "/" + viewer?.profile}
        />
        <Input.TextArea
          value={text}
          onChange={(e) => setText(e.target.value)}
          className="totextarea mdTextarea"
          rows="1"
          placeholder="What’s on your mind?"
        />
      </div>
      {Luploading ? (
        <div className="toinputpics uploaddedMediaTimeline">
          <Loader small noShadow PB />
        </div>
      ) : null}
      {(uploadingMedia === "image" || uploadingMedia === "video") && (
        <div
          className="toinputpics uploaddedMediaTimeline"
          style={{ opacity: Luploading ? 0 : 1, height: Luploading ? 0 : "auto" }}>
          <Fragment>
            {uploadingMedia === "image" && (
              <Image
                fallback={fallbackprofile}
                wrapperClassName="mask"
                style={{
                  display: IMAGE.preview ? "block" : "none",
                  objectFit: "contain"
                }}
                wrapperStyle={{ width: "100%", borderRadius: "20px" }}
                src={IMAGE.preview}
                alt="image"
              />
            )}
            {uploadingMedia === "video" && VIDEO.preview && (
              <VideoCard src={VIDEO.preview} nodefault />
            )}
          </Fragment>
        </div>
      )}

      <div className={`toinputpics ${border && "noPaddnoBorder"}`}>
        <div>
          <MediaUploader
            setIsFileRemoved={setIsFileRemoved}
            setmedia={setimage}
            media={IMAGE}
            setuploadingMedia={setuploadingMedia}
            setLuploading={setLuploading}
            border={border}>
            <img alt="img" height="20px" width="20px" src="/images/photo.png" />
            <span className="TimelineUploadMediaButtons">Photo</span>
          </MediaUploader>
          <MediaUploader
            setIsFileRemoved={setIsFileRemoved}
            setmedia={setvideo}
            media={VIDEO}
            setuploadingMedia={setuploadingMedia}
            setLuploading={setLuploading}
            border={border}>
            <img alt="img" height="20px" width="20px" src="/images/video.png" />
            <span className="TimelineUploadMediaButtons">Video</span>
          </MediaUploader>
          {(uploadingMedia === "image" || uploadingMedia === "video" || Luploading) && !loading ? (
            <img
              alt="img"
              height="23px"
              style={{
                marginLeft: "11px",
                cursor: "pointer",
                zIndex: "12"
              }}
              onClick={() => {
                if (setIsFileRemoved) {
                  setIsFileRemoved(true);
                }
                setuploadingMedia("error");
              }}
              width="23px"
              src="/images/undo.png"
            />
          ) : null}
        </div>
        <Button
          className="Button PostButton"
          style={{
            marginLeft: "auto",
            display: "flex",
            alignItems: "center",
            gap: ".2rem",
            justifyContent: border ? "center" : "unset",
            minWidth: border ? "unset" : "initial"
          }}
          loading={loading || Luploading}
          icon={<RiSendPlane2Fill fontSize={".86rem"} />}
          type="primary"
          shape="round"
          onClick={() => onPost()}>
          <span>Post</span>
        </Button>
      </div>

      <hr
        style={{
          color: "#A8A8A8",
          backgroundColor: "#A8A8A8",
          height: 0.5,
          border: "none",
          width: "100%",
          marginBottom: "3%"
        }}
      />
    </Fragment>
  );
}

export default UploadPostcard;
