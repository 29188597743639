import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CreateEventPosts } from "../../Config/API/events";
import { CreatePost as API } from "../../Config/API/posts";
import { PROFILE } from "../../Store/Features/ProfileSlice";
import { error, success } from "../Alerts/Message";
import { useCurrentPage } from "./useCurrentPage";
import useVariables from "./useVariables";

export function useCreatePost(setPOSTS, role, POSTS) {
  const [postloading, setPostLoading] = useState(false);
  const [uploadingMedia, setuploadingMedia] = useState(""); //image,video
  const [Luploading, setLuploading] = useState(false); //image,video
  const [text, setText] = useState("");

  const [IMAGE, setimage] = useState({
    file: {},
    type: "image",
    preview: ""
  });
  const [VIDEO, setvideo] = useState({
    file: {},
    type: "video",
    preview: ""
  });
  const { isTeam, isPanel, isEvent } = useCurrentPage();
  const { PID, USERID } = useVariables();
  const ID = useSelector(PROFILE);
  const { id } = useParams();

  const CreatePost = async () => {
    // if (isTeam || isPanel || isEvent)
    var flag = String(role).toLowerCase();

    if (IMAGE?.preview || VIDEO?.preview || String(text).trim().length > 0) {
      const formData = new FormData();
      const user = PID;
      if (uploadingMedia !== "error" && uploadingMedia !== "")
        formData.append("fileType", uploadingMedia);
      else formData.append("fileType", null);
      if (uploadingMedia === "image" || uploadingMedia === "video")
        formData.append("file", uploadingMedia === "image" ? IMAGE.file : VIDEO.file);
      if (String(text).trim().length > 0) formData.append("text", text);
      if (flag === "team" && isTeam) formData.append("teamId", ID?._id);
      else if (flag === "panel" && isPanel) formData.append("panelId", ID?._id);
      else if (flag === "event" && isEvent) {
        console.log(user, "Organizer", flag, USERID, id);
        formData.append("eventId", id);
        formData.append("user", USERID);
        formData.append("userRole", "Organizer");
      }
      if (!isEvent && role !== "event") {
        formData.append("user", user);
        formData.append("userRole", role);
        formData.append("flag", flag);
      }
      setPostLoading(true);
      let res = null;
      if (isEvent && role === "event") {
        res = await CreateEventPosts(formData);
        console.log("isEvent");
      } else {
        res = await API(formData);
        console.log("isEvent not");
      }
      const data = await res.json();

      if (data.type === "success") {
        setPostLoading(false);
        setuploadingMedia("");
        setText("");
        success("Posted successfully!");
        if (POSTS) {
          setPOSTS([...data.data, ...POSTS]);
        } else {
          setPOSTS([...data.data]);
        }
        setPostLoading(false);
      } else {
        setPostLoading(false);
        setuploadingMedia("error");
        setText("");
        error(data?.result || "An Error Occurred!");
      }
      setPostLoading(false);
    } else {
      error("Add something before posting!");
    }
  };
  // for selecting either image or video at a time
  useEffect(() => {
    if (uploadingMedia === "video") {
      setimage({
        file: {},
        type: "image",
        preview: ""
      });
    } else if (uploadingMedia === "image") {
      setvideo({
        file: {},
        type: "video",
        preview: ""
      });
    } else if (uploadingMedia === "error") {
      setimage({
        file: {},
        type: "image",
        preview: ""
      });
      setvideo({
        file: {},
        type: "video",
        preview: ""
      });
    }
  }, [uploadingMedia]);

  useEffect(() => {
    return () => {
      setPostLoading(false);
      setuploadingMedia(false);
      setPOSTS(null);
      setvideo(null);
      setimage(null);
      setText(null);
      setLuploading(false);
    };
  }, []);

  return {
    uploadloading: postloading,
    setPostLoading,
    IMAGE,
    VIDEO,
    setimage,
    setvideo,
    text,
    setText,
    uploadingMedia,
    setuploadingMedia,
    CreatePost,
    Luploading,
    setLuploading
  };
}
