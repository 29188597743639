// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-layout-footer {
  padding: 0px !important;
}
.footer-newsletter input::selection {
  background-color: rgb(40, 97, 157) !important;
}
`, "",{"version":3,"sources":["webpack://./src/styles/Landing/Footer.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;AACzB;AACA;EACE,6CAA6C;AAC/C","sourcesContent":[".ant-layout-footer {\n  padding: 0px !important;\n}\n.footer-newsletter input::selection {\n  background-color: rgb(40, 97, 157) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
