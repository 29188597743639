import React, { Fragment } from "react";
import { Avatar } from "antd";
import { checkProfileImage, checkProfileName, fallbackprofile } from "../../../Utils/index";
import moment from "moment";
import { useEffect } from "react";
import { KEYS } from "../../../../Config/Keys";
import { useNavigate } from "react-router-dom";
function UserInfo({ data, setloading }) {
  const navigate = useNavigate();
  useEffect(() => {
    let i = new Image();
    i.src = KEYS.API + "/" + data?.file?.file;
    i.onload = () => {
      setloading(false);
      i = null;
    };
    i.onerror = () => {
      setloading(false);
      i = null;
    };
  }, [data]);
  return (
    <Fragment>
      <Avatar
        className="profilepic timelineavatar"
        src={
          <img
            onClick={() => {
              navigate(
                `/view/${data?.organizerId ? "organizer" : "player"}/${
                  data?.organizerId ? data?.organizerId?._id : data?.playerId?._id
                }/timeline`,
                {
                  state: data?.organizerId ? "organizer" : "player"
                }
              );
            }}
            style={{ cursor: "pointer" }}
            src={checkProfileImage(data)}
            alt=""
            onErrorCapture={(e) => {
              e.currentTarget.src = fallbackprofile;
              e.currentTarget.style.transform = "scale(1.2)";
            }}
          />
        }
      />
      <div>
        <span
          onClick={() => {
            navigate(
              `/view/${data?.organizerId ? "organizer" : "player"}/${
                data?.organizerId ? data?.organizerId?._id : data?.playerId?._id
              }/timeline`,
              {
                state: data?.organizerId ? "organizer" : "player"
              }
            );
          }}
          style={{ fontWeight: 600, fontSize: "16px", cursor: "pointer" }}>
          {checkProfileName(data)}
        </span>
        <p className="timelinedate">{moment(data?.createdAt).fromNow()}</p>
      </div>
    </Fragment>
  );
}

export default UserInfo;
