import React from "react";
import { Col, Row } from "antd";
import ExperienceCard from "../cards/ExperienceCard/ExperienceCard";
import { FaCalendarAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { setcurrentgroupindex } from "../../Store/Features/MatchesSlice";

const Experience = ({
  data,
  e,
  getOrdinalNum,
  active,
  setactive,
  finalMatch,
  groupOnly = false
}) => {
  const find = e?.matches?.findIndex((obj) => obj?._id === data?._id);
  const string = `${e?.groupno + 1 + " - " + getOrdinalNum?.(find + 1)}`;
  const dispatch = useDispatch();
  const classactive = active === string;
  const currntgroupindex = useSelector((state) => state.matches.currntgroupindex);
  if (getOrdinalNum) {
    return (
      <Row
        className={`sideItem ${classactive ? "activecard" : ""}`}
        onClick={() => {
          setactive?.(string);
          dispatch(
            setcurrentgroupindex({
              ...currntgroupindex,
              gindex: e?.groupno,
              submatchindex: find
            })
          );
        }}
        style={{
          cursor: "pointer"
        }}>
        <Col span={24}>
          <h1 className="timelineAboutpadding">
            {/* if is final score show final title else group and match number */}
            {data?.isFinalStanding || finalMatch
              ? `Group ${e?.groupno + 1} Overall Score`
              : `Group ${e?.groupno + 1} ${groupOnly ? "Teams" : `- ${getOrdinalNum?.(find + 1)}`}`}
          </h1>
          <div
            className="overflowThumb"
            style={{
              height: "35px",
              overflowY: "auto",
              padding: "0 .5rem",
              margin: "auto",
              cursor: "pointer"
            }}>
            <Row
              className="datetimerow"
              style={{
                marginBottom: "2%"
              }}>
              <Col lg={24} md={24} xs={24} sm={24}>
                <div className="">
                  {/* only show date and time for group matches and not for final score */}
                  {!data?.isFinalStanding && !groupOnly && (
                    <p style={{ fontSize: "11px", color: "red", margin: "0px" }}>
                      <FaCalendarAlt /> {data?.date} - {data?.time}
                    </p>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    );
  }
  return (
    <Row className="sideItem">
      <Col span={24}>
        <h1 className="timelineAboutpadding">Experience</h1>
        <div
          className="overflowThumb"
          style={{
            height: "250px",
            overflowY: "auto",
            padding: "0 .5rem",
            margin: "auto"
          }}>
          <ExperienceCard />
          <ExperienceCard />
        </div>
      </Col>
    </Row>
  );
};

export default Experience;
