import { KEYS } from "../Keys";

export const signupAPI = async (values) => {
  const response = await fetch(KEYS.API + "/user/signup", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(values)
  });
  return response;
};
export const signInAPI = async (values) => {
  const response = await fetch(KEYS.API + "/user/signin", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(values)
  });
  return response;
};
export const sendOTPAPI = async (values) => {
  const response = await fetch(KEYS.API + "/user/otpsend", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      email: values.email
    })
  });
  return response;
};
export const verifyOTPAPI = async (values) => {
  const response = await fetch(KEYS.API + "/user/verifyotp", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      email: values.email,
      number: values.number
    })
  });
  return response;
};
export const changePasswordAPI = async (values) => {
  const response = await fetch(KEYS.API + "/user/changepassword", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      email: values.email,
      password: values.password
    })
  });
  return response;
};

export const verifyUserName = async (values) => {
  const response = await fetch(KEYS.API + "/user/verifyusername", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(values)
  });
  return response;
};
