import React, { useEffect, useState } from "react";
import { Modal, Button, Rate, List, Typography } from "antd";
import { GetPanelRatingsList } from "../../Config/API/panel";
import { ROLES } from "../../Config/helpers/constants";
import { GetEventRatingsList } from "../../Config/API/events";
import { error } from "../Alerts/Message";

const { Text } = Typography;

// modal for showing ratings
const RatingModal = ({ loading, visible, onClose, ratingsData }) => {
  return (
    <Modal
      title="User Ratings and Comments"
      open={visible}
      onCancel={onClose}
      footer={[
        <Button key="close" onClick={onClose}>
          Close
        </Button>
      ]}
      centered
      width={600}>
      <List
        loading={loading}
        dataSource={ratingsData}
        renderItem={(item) => (
          <List.Item>
            <div style={{ width: "100%" }}>
              <Rate disabled allowHalf defaultValue={item.rating} />
              {item.comment && (
                <Text style={{ display: "block", marginTop: "8px" }}>{item.comment}</Text>
              )}
            </div>
          </List.Item>
        )}
        pagination={{
          pageSize: 8
        }}
      />
    </Modal>
  );
};

//main component
const ViewRatings = ({ id, type }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [ratingsData, setRatingsData] = useState([]);
  const [loading, setLoading] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleClose = () => {
    setIsModalVisible(false);
  };

  const getRatings = async () => {
    try {
      setLoading(true);
      let data = [];
      if (type === ROLES.panel) {
        const res = await GetPanelRatingsList(id);
        data = await res.json();
      } else if (type === ROLES.event) {
        const res = await GetEventRatingsList(id);
        data = await res.json();
      }

      if (data?.type === "success") {
        if (type === ROLES.panel) {
          setRatingsData(data?.data || []);
        } else if (type === ROLES.event) {
          setRatingsData(data?.data?.ratings || []);
        }
      }
    } catch (e) {
      error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (type && id && !ratingsData?.length > 0 && isModalVisible) {
      getRatings();
    }
  }, [type, id, isModalVisible]);

  return (
    <div>
      <Typography.Text
        style={{ display: "inline" }}
        type="success"
        className="cursor-pointer"
        underline
        onClick={showModal}>
        Show Ratings
      </Typography.Text>
      {isModalVisible && (
        <RatingModal
          loading={loading}
          visible={isModalVisible}
          onClose={handleClose}
          ratingsData={ratingsData}
        />
      )}
    </div>
  );
};

export default ViewRatings;
