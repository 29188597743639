import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Geocode from "react-geocode";
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import { GOOGLE_MAP_API_KEY } from "../../../Config/helpers/constants";

const MapCard = ({ setCoordinate, setLocation, data = {}, padding }) => {
  const map = React.createRef();
  const [center, setCenter] = useState({ latitude: 33.738045, longitude: 73.084488 });
  let zoom = 10;

  useEffect(() => {
    Geocode.setApiKey(GOOGLE_MAP_API_KEY);
    Geocode.enableDebug();
    if (data && Object.keys(data)?.length > 0) {
      const coordinate = data?.coordinate;
      setCenter({
        latitude: coordinate?.latitude,
        longitude: coordinate?.longitude
      });
    }
  }, []);

  function onZoomChanged() {
    zoom = map.current.getZoom();

    // setZoom(map.current.getZoom());
  }
  const MapWithAMarker = withScriptjs(
    withGoogleMap(() => (
      <GoogleMap
        ref={map}
        defaultZoom={8}
        defaultCenter={{ lat: 33.738045, lng: 73.084488 }}
        key={GOOGLE_MAP_API_KEY}>
        <Marker
          position={{ lat: center?.latitude, lng: center?.longitude }}
          defaultZoom={zoom}
          onZoomChanged={onZoomChanged}
          draggable={true}
          onDragEnd={(ev) => {
            Geocode.fromLatLng(ev.latLng.lat(), ev.latLng.lng()).then(
              (response) => {
                const address = response.results[0].formatted_address;
                if (setLocation) {
                  setLocation(address);
                }
              },
              (error) => {
                console.error(error);
              }
            );
            setCenter({ latitude: ev.latLng.lat(), longitude: ev.latLng.lng() });
            if (setCoordinate && ev.latLng.lat() && ev.latLng.lng()) {
              setCoordinate({ latitude: ev.latLng.lat(), longitude: ev.latLng.lng() });
            }
          }}
        />
      </GoogleMap>
    ))
  );

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: "260px", width: "100%", padding: !padding ? "3%" : undefined }}>
      <MapWithAMarker
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={
          <div style={{ height: `260px `, padding: "3% 0", borderRadius: "8px" }} />
        }
        mapElement={<div style={{ height: `100%`, borderRadius: "8px" }} />}
      />
    </div>
  );
};

export default MapCard;
