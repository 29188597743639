import React from "react";
import { Route, Routes } from "react-router-dom";
import PlayerLayout from "../layout/playerlayout/PlayerLayout";
import CreatePlayerProfile from "./CreatePlayerProfile/CreatePlayerProfile";
import CreateTeam from "./CreateTeam/CreateTeam";
import Teams from "./Teams";
import AddTeamMembers from "./AddMember/AddMember";
import EventHistory from "./EventHistory";
import Events from "./Events";
import NotificationMobile from "../../common/Notification/NotificatinonMobile";
import NotificationMobileTP from "../../common/Notification/TeamPanelNotifications/NotificatinonMobile";

import ChatRoom from "../ChatRoom";
import "../../styles/player/ViewEvent.css";
import PageNotFound from "../../common/cards/PageNotFound/PageNotFound";

import PlayerTLayout from "../layout/playerTLLayout/PlayerTLayout";
import ProfilePage from "./Timeline";
import About from "./About";
import Media from "../../common/Media";
import Follow from "../../common/Follow";
import TimeLinePage from "./Timeline";
import Home from "./Home";
// import EditProfile from "./EditProfile/EditProfile"; //player and team edit

import MyTeams from "./Teams/MyTeams/MyTeams";
import Settings from "../../common/Settings";
import SearchPage from "../SearchPage";
import AuthPlayer from "./AuthPlayer";
import UserWallet from "../Wallet/UserWallet";
import { ROLES } from "../../Config/helpers/constants";
import useVariables from "../../common/hooks/useVariables";

const Index = () => {
  const { PID } = useVariables();

  return (
    <Routes>
      <Route element={<PlayerLayout />}>
        <Route index path="/" element={<CreatePlayerProfile />} />
      </Route>
      <Route path="/" element={<AuthPlayer />}>
        <Route path="/" element={<PlayerLayout />}>
          <Route path="/editprofile/:id" element={<CreatePlayerProfile />} />
          <Route path="/editteam/:id" element={<CreateTeam />} />
          <Route path="team/:id/*" element={<Teams />} />
          <Route path="events/*" element={<Events />} />
          <Route path="/createteam" element={<CreateTeam />} />
          <Route path="/addteammembers" element={<AddTeamMembers />} />
          {/* <Route path="/event/:id" element={<ViewEvent />} /> */}
          <Route path="/eventhistory" element={<EventHistory />} />
          <Route path="/notifications" element={<NotificationMobile />} />
          <Route path="/team/:id/notifications" element={<NotificationMobileTP />} />
          <Route path="/feed" element={<Home />} />
          <Route path="settings" element={<Settings />} />
          <Route path="/team/:id/settings" element={<Settings />} />
          <Route path="/search/:query" element={<SearchPage />} />
          <Route path="/messages/chat" element={<ChatRoom />} />
          {/* <Route path="messages/:id" element={<ChatRoom />} /> */}

          <Route path="/" element={<PlayerTLayout />}>
            <Route index path="/" element={<TimeLinePage />} />
            <Route path="/teams" element={<MyTeams />} />
            <Route path="timeline" element={<ProfilePage />} />
            <Route path="about" element={<About />} />
            <Route path="media" element={<Media />} />
            <Route path="/followers" element={<Follow />} />
            <Route path="/followings" element={<Follow />} />
            <Route path="wallet" element={<UserWallet userType={ROLES.player} userId={PID} />} />
          </Route>
        </Route>
      </Route>

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default Index;
