import React from "react";
import { Fragment } from "react";
import { KEYS } from "../../Config/Keys";
import { MdDelete } from "react-icons/md";
import { BiMessageEdit } from "react-icons/bi";
import Swal from "sweetalert2";
import { error } from "../Alerts/Message";
import { EVENT_AND_TEAM_ROLES } from "../../Config/helpers/constants";
export { followFunction, unFollowProfile, checkIfFollowed } from "./ApiCall";

export { fallbackprofile, fallbackcover } from "./Images";

export const checkProfileImage = (comment) => {
  if (comment?.player && Object.prototype.hasOwnProperty.call(comment?.player, "player")) {
    return KEYS.API + "/" + comment?.player?.profilepic;
  } else if (
    comment?.organizer &&
    Object.prototype.hasOwnProperty.call(comment?.organizer, "organizer")
  ) {
    return KEYS.API + "/" + comment?.organizer?.profilepic;
  } else if (comment && Object.prototype.hasOwnProperty.call(comment, "user"))
    return KEYS.API + "/" + comment?.user?.profilepic;
  else if (comment && Object.prototype.hasOwnProperty.call(comment, "organizerId"))
    return KEYS.API + "/" + comment?.organizerId?.profilepic;
  else if (comment && Object.prototype.hasOwnProperty.call(comment, "playerId"))
    return KEYS.API + "/" + comment?.playerId?.profilepic;
  else if (comment && Object.prototype.hasOwnProperty.call(comment, "panel"))
    return KEYS.API + "/" + comment?.panel?.profilepic;
  else if (comment && Object.prototype.hasOwnProperty.call(comment, "team"))
    return KEYS.API + "/" + comment?.team?.profilepic;
  else if (comment?.organizer || comment?.organizerId) {
    return KEYS.API + "/" + comment?.organizer?.profilepic ?? comment?.organizerId?.profilepic;
  } else if (comment?.player || comment?.playerId) {
    return KEYS.API + "/" + comment?.player?.profilepic ?? comment?.playerId?.profilepic;
  } else {
    return "-";
  }
};

export const checkCoverImage = (comment) => {
  if (Object.prototype.hasOwnProperty.call(comment, "user"))
    return KEYS.API + "/" + comment?.user?.coverpic;
  else if (Object.prototype.hasOwnProperty.call(comment, "organizer"))
    return KEYS.API + "/" + comment?.organizer?.coverpic;
  else if (Object.prototype.hasOwnProperty.call(comment, "player"))
    return KEYS.API + "/" + comment?.player?.coverpic;
  else if (Object.prototype.hasOwnProperty.call(comment, "panel"))
    return KEYS.API + "/" + comment?.panel?.coverpic;
  else if (Object.prototype.hasOwnProperty.call(comment, "team"))
    return KEYS.API + "/" + comment?.team?.coverpic;
  else if (Object.prototype.hasOwnProperty.call(comment?.user, "player")) {
    return KEYS.API + "/" + comment?.user?.player?.coverpic;
  } else if (Object.prototype.hasOwnProperty.call(comment?.user, "organizer")) {
    return KEYS.API + "/" + comment?.user?.organizer?.coverpic;
  } else return KEYS.API + "/" + comment?.user?.coverpic;
};

export const checkProfileName = (comment) => {
  if (comment?.playerId && Object.prototype.hasOwnProperty.call(comment, "playerId")) {
    return comment?.playerId?.name;
  } else if (comment?.organizerId && Object.prototype.hasOwnProperty.call(comment, "organizerId")) {
    return comment?.organizerId?.name;
  } else if (comment && Object.prototype.hasOwnProperty.call(comment, "user"))
    return comment?.user?.name;
  else if (comment && Object.prototype.hasOwnProperty.call(comment, "organizer")) {
    return comment?.organizer?.name;
  } else if (comment && Object.prototype.hasOwnProperty.call(comment, "player")) {
    return comment?.player?.name;
  } else if (comment && Object.prototype.hasOwnProperty.call(comment, "team")) {
    return comment?.team?.name;
  } else if (comment && Object.prototype.hasOwnProperty.call(comment, "panel")) {
    return comment?.panel?.name;
  } else {
    return "-";
  }
};
export const getuserlink = (data) => {
  let userr = {
    url: "",
    name: null
  };

  if (Object.prototype.hasOwnProperty.call(data, "organizer"))
    userr = {
      url: `/view/organizer/${data?.organizer?._id}/timeline`,
      name: "organizer"
    };
  else if (Object.prototype.hasOwnProperty.call(data, "player"))
    userr = {
      url: `/view/player/${data?.player?._id}/timeline`,
      name: "player"
    };
  else if (Object.prototype.hasOwnProperty.call(data, "team"))
    userr = {
      url: `/view/team/${data?.team?._id}/timeline`,
      name: "team"
    };
  else if (Object.prototype.hasOwnProperty.call(data, "panel")) {
    userr = {
      url: `/view/panel/${data?.panel?._id}/timeline`,
      name: "panel"
    };
  } else if (Object.prototype.hasOwnProperty.call(data?.user || {}, "player"))
    userr = {
      url: `/view/player/${data?.player?._id}/timeline`,
      name: "player"
    };
  else if (Object.prototype.hasOwnProperty.call(data?.user || {}, "organizer"))
    userr = {
      url: `/view/organizer/${data?.organizer?._id}/timeline`,
      name: "organizer"
    };
  if (userr.url) {
    return {
      url: userr?.url,
      name: userr?.name
    };
  }

  if (Object.prototype.hasOwnProperty.call(data, "organizerSent"))
    userr = {
      url: `/view/organizer/${data?.organizerSent?._id}/timeline`,
      name: "organizer"
    };
  else if (Object.prototype.hasOwnProperty.call(data, "playerSent"))
    userr = {
      url: `/view/player/${data?.playerSent?._id}/timeline`,
      name: "player"
    };
  else if (Object.prototype.hasOwnProperty.call(data, "teamSent"))
    userr = {
      url: `/view/team/${data?.teamSent?._id}/timeline`,
      name: "team"
    };
  else if (Object.prototype.hasOwnProperty.call(data, "panelSent"))
    userr = {
      url: `/view/panal/${data?.panelSent?._id}/timeline`,
      name: "panel"
    };
  else
    return {
      url: `/view/${userr.url}`,
      name: "empty"
    };
  return {
    url: userr?.url,
    name: userr?.name
  };
};
//Image Loader
export const loadimage = (src, setloading) => {
  try {
    if (src) {
      let Img = new Image();
      Img.src = src;
      Img.onload = () => {
        if (setloading) setloading(false);
        Img = null;
      };
      Img.onerror = () => {
        if (setloading) setloading(false);
        Img = null;
      };
    } else throw new Error();
  } catch (e) {
    if (setloading) setloading(false);
    error(e);
  }
};

export const PostMeidaLoader = (data, setcardloading) => {
  if (data?.file?.type === "image") {
    let i = new Image();
    i.src = KEYS.API + "/" + data?.file?.file;
    i.onload = () => {
      setcardloading(false);
      i = null;
    };
    i.onerror = () => {
      setcardloading(false);
      i = null;
    };
  } else if (data?.file?.type === "video") {
    let video = document.createElement("video");
    video.src = KEYS.API + "/" + data?.file?.file;
    video.onloadedmetadata = () => {
      setcardloading(false);
      video = null;
    };
    video.onerror = () => {
      setcardloading(false);
      video = null;
    };
  } else if (data?.text) {
    setcardloading(false);
  }
};

export const getactivefollowfollowers = (data) => {
  return data?.filter((e) => e?.accept === true);
};
export const getactivemembers = (data) => {
  return data?.filter((e) => e?.accept || e?.active === true);
};
export const checkCommentEdit = (comment, setedit) => {
  const id = JSON.parse(localStorage.getItem("viewer"))?.id;
  return (
    <Fragment>
      {id === comment?.organizer?._id && (
        <span
          className="timelinedate Edit"
          onClick={() => {
            setedit((e) => (e === "" ? comment?._id : ""));
          }}>
          {<BiMessageEdit />}
        </span>
      )}
      {id === comment?.player?._id && (
        <span
          className="timelinedate Edit"
          onClick={() => setedit((e) => (e === "" ? comment?._id : ""))}>
          {<BiMessageEdit />}
        </span>
      )}
      {id === comment?.panel?._id && (
        <span
          className="timelinedate Edit"
          onClick={() => setedit((e) => (e === "" ? comment?._id : ""))}>
          {<BiMessageEdit />}
        </span>
      )}
      {id === comment?.team?._id && (
        <span
          className="timelinedate Edit"
          onClick={() => setedit((e) => (e === "" ? comment?._id : ""))}>
          {<BiMessageEdit />}
        </span>
      )}
    </Fragment>
  );
};
export const checkCommentDelete = (comment, deleteComment, delteReply) => {
  const id = JSON.parse(localStorage.getItem("viewer"))?.id;

  return (
    <Fragment>
      {id === comment?.organizer?._id && (
        <span className="timelinedate Edit">
          {
            <MdDelete
              onClick={() => {
                Swal.fire({
                  icon: "warning",
                  text: "Do you really want to delete this comment?",
                  confirmButtonText: "Delete",
                  showCancelButton: true,
                  confirmButtonColor: "var(--red)"
                }).then((e) => {
                  if (e.isConfirmed) {
                    if (comment.reply) {
                      deleteComment(comment._id);
                    } else delteReply(comment._id);
                  }
                });
              }}
            />
          }
        </span>
      )}
      {id === comment?.player?._id && (
        <span className="timelinedate Edit">
          {
            <MdDelete
              onClick={() => {
                Swal.fire({
                  icon: "warning",
                  text: "Do you really want to delete this comment?",
                  confirmButtonText: "Delete",
                  showCancelButton: true,
                  confirmButtonColor: "var(--red)"
                }).then((e) => {
                  if (e.isConfirmed) {
                    if (comment.reply) {
                      deleteComment(comment._id);
                    } else delteReply(comment._id);
                  }
                });
              }}
            />
          }
        </span>
      )}
      {id === comment?.panel?._id && (
        <span className="timelinedate Edit">
          {
            <MdDelete
              onClick={() => {
                Swal.fire({
                  icon: "warning",
                  text: "Do you really want to delete this comment?",
                  confirmButtonText: "Delete",
                  showCancelButton: true,
                  confirmButtonColor: "var(--red)"
                }).then((e) => {
                  if (e.isConfirmed) {
                    if (comment.reply) {
                      deleteComment(comment._id);
                    } else delteReply(comment._id);
                  }
                });
              }}
            />
          }
        </span>
      )}
      {id === comment?.team?._id && (
        <span className="timelinedate Edit">
          {
            <MdDelete
              onClick={() => {
                Swal.fire({
                  icon: "warning",
                  text: "Do you really want to delete this comment?",
                  confirmButtonText: "Delete",
                  showCancelButton: true,
                  confirmButtonColor: "var(--red)"
                }).then((e) => {
                  if (e.isConfirmed) {
                    if (comment.reply) {
                      deleteComment(comment._id);
                    } else delteReply(comment._id);
                  }
                });
              }}
            />
          }
        </span>
      )}
    </Fragment>
  );
};

export const checkIsAccepted = (setaccpted, arr, viewer) => {
  var role = JSON.parse(localStorage.getItem("userDetails"))?.role;
  role = String(role).toLocaleLowerCase();
  arr?.forEach((e) => {
    if (e[role]?._id === viewer?.id && e?.accept) {
      setaccpted(true);
      return;
    }
  });
};

export const checkjoinedstatus = (arr, pid, setisjoined) => {
  arr?.members?.forEach((e) => {
    if (e?.player?._id === pid) {
      if (e?.active === true) setisjoined("joined");
      else if (e?.active === false && e?.type?.toLowerCase() === "add") setisjoined("invited");
      else if (e?.active === false && e?.type?.toLowerCase() === "join") setisjoined("requested");
      return;
    }
  });
};

export const checkIfTeamCaptain = (members, id) => {
  const captain = members?.find((member) => member.role === EVENT_AND_TEAM_ROLES.captain);
  if (captain?.player?._id === id) {
    return true;
  } else {
    return false;
  }
};

export const checkIfPartOfTheTeamOrInvited = (members, id) => {
  let isMember = members?.find((member) => member?.player?._id === id);
  return isMember ? true : false;
};
