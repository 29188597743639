import React from "react";

function VS({ item, index }) {
  const win = item?.match?.win;

  return (
    <div
      className="MatchCardVsInfo"
      style={{
        display: "flex",
        alignItems: "center",
        height: "100%",
        justifyContent: "center",
        flexDirection: "column",
        color: "#515151"
      }}>
      <h1> VS </h1>
      <span>Match {index + 1}</span>
      {win === "draw" ? "(Draw)" : null}
    </div>
  );
}

export default VS;
