import React from "react";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

function EventAuth() {
  const panel = localStorage.getItem("panel");
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const id = useParams();
  useEffect(() => {
    if (state?.panel?._id !== panel) {
      // Info("You are not Authorized")
      // if (panel) navigate('/organizer/panel/' + panel + "/events", { replace: true })
      // else navigate('/organizer/panels', { replace: true })
    }
  }, [state, navigate, panel, pathname, id]);
  return <Outlet />;
}
export default EventAuth;
