import React from "react";
import ForgotPassword from "./Components/User/ForgotPassword/ForgotPassword";
import HomeLayout from "./Components/layout/homelayout/HomeLayout";
import ViewEventWrapper from "./Components/ViewEvents/eventlayout";
import ViewProfile from "./Components/ViewProfile";
import { LocalStorageCheck } from "./Config/Auth";
import { Route, Routes } from "react-router-dom";
import Error404 from "./common/errors/404Error";
import Organizer from "./Components/Organizer";
import Signup from "./Components/User/Signup";
import FontLoader from "./Config/FontLoader";
import Login from "./Components/User/login";
import Home from "./Components/Home/home";
import Player from "./Components/Player";
import Sponsor from "./Components/Sponsor";

function App() {
  return (
    <div className="font-face-gm">
      <LocalStorageCheck />
      <FontLoader />
      <Routes>
        <Route path="/" element={<HomeLayout />}>
          <Route index element={<Home />} />
        </Route>
        <Route path="signin" element={<Login />} />
        <Route path="register" element={<Signup />} />
        <Route path="forgotpassword" element={<ForgotPassword />} />
        <Route path="organizer/*" element={<Organizer />} />
        <Route path="player/*" element={<Player />} />
        <Route path="view/event/:id/*" element={<ViewEventWrapper />} />
        <Route path="view/*" element={<ViewProfile />} />
        <Route path="sponsor/*" element={<Sponsor />} />

        <Route path="*" element={<Error404 />} />
      </Routes>
    </div>
  );
}

export default App;
