import { KEYS } from "../Keys";

export const CreateTeam = async (values) => {
  const response = await fetch(KEYS.API + "/team/createteam", {
    method: "POST",
    headers: {
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: values
  });
  return response;
};
export const EditTeamAPI = async (values) => {
  const response = await fetch(KEYS.API + "/team/updateteam", {
    method: "POST",
    headers: {
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: values
  });
  return response;
};

export const GetTeams = async (values) => {
  const response = await fetch(KEYS.API + "/team/getteamsofowner", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify({ id: values.id })
  });
  return response;
};
export const GetTeamPosts = async (values, controller, page = 1, limit = 10) => {
  const response = await fetch(KEYS.API + `/team/getteamposts?page=${page}&limit=${limit}`, {
    // signal: controller.signal,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify({ teamId: values })
  });
  return response;
};

export const GetSingleTeam = async (values) => {
  const response = await fetch(KEYS.API + "/team/getsingleteam", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: typeof values === "string" ? JSON.stringify({ id: values }) : JSON.stringify(values)
  });
  return response;
};

//invite to team
export const InviteToTeam = async (values) => {
  const response = await fetch(KEYS.API + "/team/requestteam", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

// joining requet to team
export const JoinTeamRequest = async (values) => {
  const response = await fetch(KEYS.API + "/team/jointeam", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

// invitaion accept
export const AccepTeamRequest = async (values) => {
  const response = await fetch(KEYS.API + "/team/acceptteamrequest", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

// invitaion cancel
export const CancelOrDeleteTeamRequest = async (values) => {
  const response = await fetch(KEYS.API + "/team/cancelordeletemember", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

// Join Accept
export const AccepJoinTeamRequest = async (values) => {
  const response = await fetch(KEYS.API + "/team/joinacceptteamrequest", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};
//join cancel
export const CancelOrDeleteTeamJoinRequest = async (values) => {
  const response = await fetch(KEYS.API + "/team/joincancelordeletemember", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

export const DeleteTeamPost = async (values) => {
  const response = await fetch(KEYS.API + "/team/deleteteampost", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

export const EditTeamPost = async (values) => {
  const response = await fetch(KEYS.API + "/post/updatepost", {
    method: "POST",
    headers: {
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: values
  });
  return response;
};

export const GetTeamsOfOwner = async (values) => {
  const response = await fetch(KEYS.API + "/team/getteamsofowner", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

export const AssignRole = async (values) => {
  const response = await fetch(KEYS.API + "/team/assignrole", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

export const TeamAcceptFollowrequest = async (values) => {
  const response = await fetch(KEYS.API + "/team/acceptfollowrequest", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

export const TeamRejectFollowrequest = async (values) => {
  const response = await fetch(KEYS.API + "/team/cancelfollowrequest", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

export const TeamDeleteFollowRequest = async (values) => {
  const response = await fetch(KEYS.API + "/team/deletefollowrequest", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

export const TeamDeleteFollower = async (values) => {
  const response = await fetch(KEYS.API + "/team/deletefollowerteam", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

export const GetTeamEventHistory = async (id, setData) => {
  try {
    const payload = {
      teamId: id
    };
    const response = await fetch(KEYS.API + "/team/getteameventshistory", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("userToken")}`
      },
      body: JSON.stringify(payload)
    });
    const result = await response.json();
    if (result?.type === "success") {
      setData(result?.data || []);
    }
  } catch (error) {
    return await error;
  }
};
