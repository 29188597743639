import React, { Fragment } from "react";
import { Col, Row, Typography } from "antd";
import EventHistoryAccordian from "../righbar/Accordians/EventHistoryAccordian";
import PastEventCard from "../righbar/PastEventCard";
const { Title } = Typography;

export default function SponsorContracts() {
  return (
    <Fragment>
      <div className="d-md-block d-none mb-20">
        <EventHistoryAccordian />
      </div>
      <div className="d-md-none mb-20">
        <SponsorContractsDesktop />
      </div>
    </Fragment>
  );
}

const SponsorContractsDesktop = () => {
  // const { pathname } = useLocation();
  return (
    <Fragment>
      <Row>
        <Col span={24}>
          <Typography>
            <Title
              level={4}
              className="toeventhistory"
              style={{ padding: "0 .5% 5% .5%", width: "85%", margin: "auto" }}>
              Contracts
            </Title>
          </Typography>
        </Col>
        <Col span={24}>
          <PastEventCard />
          <PastEventCard />
          <PastEventCard />
        </Col>
        {/* <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
          <Link
            to={
              linkTo ??
              `${pathname.includes("player") ? "/player/eventhistory" : "/organizer/eventhistory"}`
            }
            style={{
              fontSize: "12px",
              marginTop: "15px",
              color: "#0085FF",
              fontWeight: "600",
              cursor: "pointer"
            }}>
            Show More
          </Link>
        </Col> */}
      </Row>
    </Fragment>
  );
};
