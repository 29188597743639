import { Row, Spin } from "antd";
import TL from "../../../../common/cards/TPTimelineCard";
import { DeleteTeamPost, EditTeamPost } from "../../../../Config/API/teams";
import Loader from "../../../../common/cards/Loader";
import NoData from "../../../../common/cards/noData";
import { useFetchProfilePosts } from "../../../../common/hooks/useFetchProfilePosts";
import { useCreatePost } from "../../../../common/hooks/useCreatePost";
import { useCurrentPage } from "../../../../common/hooks/useCurrentPage";
import React, { Fragment, useState, useEffect, useRef, useCallback } from "react";
import UploadPostcard from "../../../../common/cards/UploadPostcard";
// import { useFetchAllPosts } from "../../../../common/hooks/useFetchAllPosts";

const DiscussionTeam = () => {
  const { posts, setPosts, postloading, setPage, totalPages, page, fetchTime } =
    useFetchProfilePosts("team");
  // const { fetchloading, POSTS, setPOSTS } = useFetchAllPosts("team")

  const { other } = useCurrentPage();
  const {
    uploadloading,
    IMAGE,
    VIDEO,
    setimage,
    setvideo,
    text,
    setText,
    uploadingMedia,
    setuploadingMedia,
    CreatePost,
    setLuploading,
    Luploading
  } = useCreatePost(setPosts, "Team", posts);

  const [loadMorePosts, setLoadMorePosts] = useState(false);
  const [POSTS, setPOSTS] = useState([]);

  const observer = useRef();
  const timer = useRef();
  const isThrottled = useRef(false);

  useEffect(() => {
    const fetchPosts = async () => {
      setPOSTS((prevPosts) => [...prevPosts, ...posts]);

      // Adjust timeout based on fetch time, adding some buffer (e.g., 100ms)
      const timeoutDuration = Math.max(fetchTime + 100, 500); // Minimum of 500ms

      timer.current = setTimeout(() => setLoadMorePosts(false), timeoutDuration);
    };
    fetchPosts();
    return () => clearTimeout(timer.current);
  }, [posts, fetchTime]);

  const lastPostElementRef = useCallback(
    (node) => {
      if (postloading || isThrottled.current) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          isThrottled.current = true;
          setTimeout(() => {
            isThrottled.current = false;
          }, 100);
          if (page < totalPages) setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [postloading, page, totalPages]
  );

  return (
    <Row className="mobileto">
      <Fragment>
        {other ? null : (
          <UploadPostcard
            uploadingMedia={uploadingMedia}
            setuploadingMedia={setuploadingMedia}
            setText={setText}
            text={text}
            IMAGE={IMAGE}
            setimage={setimage}
            VIDEO={VIDEO}
            setvideo={setvideo}
            onPost={CreatePost}
            loading={uploadloading}
            setLuploading={setLuploading}
            Luploading={Luploading}
          />
        )}
      </Fragment>
      {postloading ? (
        <Loader />
      ) : POSTS?.length > 0 ? (
        <>
          {POSTS?.map((data, index) => {
            if (POSTS.length === index + 1) {
              return (
                <TL
                  Editing
                  key={index}
                  data={data}
                  setPOSTs={setPOSTS}
                  editpost={EditTeamPost}
                  deletepost={DeleteTeamPost}
                  POSTS={POSTS}
                  index={index}
                  ref={lastPostElementRef}
                />
              );
            } else {
              return (
                <TL
                  Editing
                  key={index}
                  data={data}
                  setPOSTs={setPOSTS}
                  editpost={EditTeamPost}
                  deletepost={DeleteTeamPost}
                  POSTS={POSTS}
                  index={index}
                />
              );
            }
          })}
          {/* Loader for more posts */}
          {loadMorePosts && page < totalPages && (
            <div style={{ textAlign: "center", margin: "20px", width: "100%" }}>
              <Spin tip="Loading more posts..." style={{ color: "black", fontSize: "12px" }} />
            </div>
          )}
        </>
      ) : (
        <NoData />
      )}
    </Row>
  );
};

export default DiscussionTeam;
