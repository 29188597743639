import React, { Fragment, useMemo, useState } from "react";
import { Row, Col, Form, Button, DatePicker, Image, Select, Radio } from "antd";
import { fallbackcover } from "../../../common/Utils";
import Loader from "../../../common/cards/Loader";
import Swal from "sweetalert2";
import "../../../styles/player/createplayerprofile.css";
import { CreateProfilePlayerAPI } from "../../../Config/API/player";
import { useNavigate, useParams } from "react-router-dom";
import { serverError } from "../../../common/Alerts/serverError";
import { useFetchInitialProfile } from "../../../common/hooks/useFetchInitialProfile";
import { useRef } from "react";
import { error, success } from "../../../common/Alerts/Message";
// import ExperienceModal from "../../../common/Model/ExperienceModal";
// import { IoAddSharp } from "react-icons/io5";
import { Formik } from "formik";
import InputTextField from "../../../common/FormItem/InputTextField";
import {
  E_SPORTS_LIST,
  LOCATIONS,
  PHYSICAL_SPORTS_LIST,
  ROLES,
  SPORTS_TYPES,
  SPORTS_TYPES_LIST
} from "../../../Config/helpers/constants";
const { Option } = Select;
import { createPlayerProfileValidations } from "../../../Config/helpers/validations";
import dayjs from "dayjs";
import UserNameField from "../../../common/FormItem/UserNameField";
import { useSelector } from "react-redux";
import { PROFILE } from "../../../Store/Features/ProfileSlice";
import { useFetchProfile } from "../../../common/hooks/useFetchProfile";
import moment from "moment";
import useEditProfile from "../../../common/hooks/useEditProfile";
import { useEffect } from "react";
import { KEYS } from "../../../Config/Keys";

const CreatePlayerProfile = () => {
  const { id } = useParams();
  const [editloading, edit] = useEditProfile();

  let profileData, profileDataLoading, setProfileDataLoading;
  if (!id) {
    // if creating profile fetch initial profile data
    const { Profile, profileLoding, setprofileLoding } = useFetchInitialProfile("player");

    profileData = Profile;
    profileDataLoading = profileLoding;
    setProfileDataLoading = setprofileLoding;
  } else {
    // if editing profile
    const { profileLoding } = useFetchProfile(id, ROLES.player);
    const Profile = useSelector(PROFILE);
    profileData = Profile;
    profileDataLoading = profileLoding;
  }
  // Profile
  const [profileImage, setProfileImage] = useState({
    file: {},
    preview: ""
  });
  // Cover
  const [coverImage, setCoverImage] = useState({
    file: {},
    preview: ""
  });

  // const [showExperienceModal, setShowExperienceModal] = useState(false);

  const navigate = useNavigate();

  const isProfile = useRef();
  const isCover = useRef();

  const children = [];
  for (let i = 10; i < 36; i++) {
    children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
  }

  // handle non-formik field changes
  function handleChanges(name, value, setFieldValue) {
    setFieldValue(name, value);
  }

  // input type image handler
  const onImageFileChange = async (e) => {
    // check if a file was selected
    if (e.target.files?.length > 0) {
      const file = e.target.files[0];
      if (file?.size > 5000000) {
        return Swal.fire({
          icon: "error",
          title: "Uploading Error",
          text: "Image size should be less than 5mb",
          confirmButtonColor: "#0085FF",
          allowOutsideClick: false
        });
      }
      if (file) {
        if (e.target.name === "profileImage") {
          isProfile.current = true;
          setProfileImage({
            file: file,
            preview: URL.createObjectURL(file)
          });
        } else {
          isCover.current = true;
          setCoverImage({
            file: file,
            preview: URL.createObjectURL(file)
          });
        }
      }
    }
  };
  // onFinish
  const onFinish = async (values) => {
    if (!profileImage?.preview) {
      return Swal.fire({
        icon: "error",
        title: "Upload profile photo first"
      });
    }

    // const obj = {
    //   name: values?.experience?.name,
    //   role: values?.experience?.role,
    //   date: JSON.stringify(dayjs(values?.experience?.date)),
    //   location: values?.experience?.location,
    //   awards: values?.experience?.awards,
    //   description: values?.experience?.description,
    //   panelName: values?.experience?.teamName
    // };

    const formData = new FormData();
    if (id) {
      formData.append("name", values?.name);
    } else {
      formData.append("name", values?.firstName + " " + values?.lastName);
    }
    formData.append("nickname", values?.nickName || "");
    if (profileData?.username !== values?.username) {
      formData.append("username", values?.username || "");
    }
    formData.append("dob", JSON.stringify(dayjs(values?.DOB).format("YYYY-MM-DD")) || "");
    formData.append("awards", values?.awards?.toString() || "");
    formData.append("location", values?.location || "");
    formData.append("description", values?.description || "");
    formData.append("sportsyouplay", values?.selectedSport || "");

    // if (values?.experience?.name) {
    //   formData.append("experience", JSON.stringify(obj));
    //   if (imageFileValidator(values?.experience?.pictures?.file))
    //     formData.append("exp_image", values?.experience?.pictures?.file);
    // }
    if (isProfile.current === true) {
      formData.append("profile", profileImage?.file);
    }
    if (isCover.current === true) {
      formData.append("cover", coverImage?.file);
    }
    let response;
    // if edit
    if (id) {
      try {
        formData.append("id", id);
        edit(formData, ROLES.player, id);
      } catch (e) {
        error(e.message);
        Swal.fire({
          icon: "error",
          text: e.message
        });
      }
    } else {
      // if creating
      try {
        setProfileDataLoading(true);
        response = await CreateProfilePlayerAPI(formData);
        const data = await response.json();
        setProfileDataLoading(false);
        if (data.type === "success") {
          success("Profile Created Succesfully");
          navigate("/player/feed");
        }

        if (data?.type === "failure") {
          error(data?.result);
        }
      } catch (err) {
        setProfileDataLoading(false);

        serverError();
      }
    }
  };

  const findSportType = (sport) => {
    // check in physical sports first
    let sportsType = null;
    for (let i = 0; i < E_SPORTS_LIST.length; i++) {
      if (E_SPORTS_LIST?.[i].value === sport) {
        sportsType = E_SPORTS_LIST?.[i].type;
        return sportsType;
      }
    }

    // if it's not in the esports check it in physical sports
    if (!sportsType) {
      for (let i = 0; i < PHYSICAL_SPORTS_LIST.length; i++) {
        if (PHYSICAL_SPORTS_LIST?.[i].value === sport) {
          sportsType = E_SPORTS_LIST?.[i].type;
          return sportsType;
        }
      }
    }
  };

  const sportsType = useMemo(
    () => findSportType(profileData?.sportsyouplay),
    [profileData?.sportsyouplay]
  );

  const initialValues = {
    images: {
      profileImage: {},
      coverImage: {}
    },
    firstName: profileData?.firstName || "",
    lastName: profileData?.lastName || "",
    name: profileData?.name || "",
    nickName: id
      ? profileData?.user?.nickName || profileData?.nickname || ""
      : profileData?.nickName || "",
    username: id ? profileData?.username || "" : "",
    location: id ? profileData?.location || "" : "",
    // role: id ? profileData?.role || "" : "",
    awards: id ? profileData?.awards || "" : "",
    DOB: profileData?.dob || "",
    description: id ? profileData?.description || "" : "",
    sportsType: id ? sportsType || "" : "",
    selectedSport: id ? profileData?.sportsyouplay || "" : ""
    // endorsements: "",
    // experience: {
    //   name: "",
    //   role: "",
    //   date: "",
    //   location: "",
    //   awards: "",
    //   description: "",
    //   pictures: {},
    //   teamName: ""
    // }
  };

  useEffect(() => {
    if (id) {
      setCoverImage({
        file: {},
        preview: KEYS.API + "/" + profileData.cover
      });
      setProfileImage({
        file: {},
        preview: KEYS.API + "/" + profileData.profile
      });
    }
  }, [profileData]);

  return (
    <Fragment>
      <div>
        <h1 className="headingPlayer">Player</h1>
        {profileDataLoading !== false && <Loader fullpage loading={"white"} />}
        <Row>
          <Col sm={24} xs={24} md={24} style={{ position: "relative" }}>
            <div
              style={{
                position: "relative",
                width: "96%",
                margin: "auto",
                background: "#DADADA",
                borderRadius: "8px"
              }}
              className="CoverImageWrapper">
              {coverImage?.preview !== "" ? (
                <Image
                  wrapperClassName="CoverImageWrapper"
                  className="CoverImage"
                  preview={false}
                  src={coverImage?.preview}
                  style={{ objectFit: "cover" }}
                  fallback={fallbackcover}
                />
              ) : null}
              <div className="profileuploaders">
                <label htmlFor="coveruploader" style={{}}>
                  <img src="/images/icons/camera.png" alt="" />
                  {coverImage?.preview === "" ? <span>Add your Cover image</span> : null}
                </label>
                <input
                  type="file"
                  name="CoverImage"
                  className="uploaderInput"
                  id="coveruploader"
                  accept="image/*"
                  onChange={onImageFileChange}
                />
              </div>
            </div>
            <div className="profileuploaderForProfile">
              {profileImage?.preview !== "" ? (
                <Image
                  preview={false}
                  wrapperClassName="profileImageWrapper"
                  className="ProfileImage"
                  src={profileImage?.preview}
                  fallback={fallbackcover}
                />
              ) : null}
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  borderRadius: "50%"
                }}>
                <label htmlFor="profileuploader">
                  <img src="/images/icons/camerasmall.png" alt="" />
                  {profileImage?.preview === "" ? <span>Add Profile</span> : null}
                </label>
                <input
                  type="file"
                  name="profileImage"
                  className="uploaderInput"
                  id="profileuploader"
                  accept="image/*"
                  onChange={onImageFileChange}
                />
              </div>
            </div>
          </Col>
        </Row>
        <div className="formorganizer">
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={onFinish}
            validationSchema={createPlayerProfileValidations(id ? true : false)}>
            {({ values, errors, setFieldValue, handleSubmit, isValid, dirty }) => (
              <Form
                name="basic"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                onFinish={handleSubmit}
                autoComplete="off">
                <Row justify="space-between">
                  <Col lg={11} md={11} xs={24} sm={24} className="playerformPadding">
                    {id ? (
                      <InputTextField
                        name="name"
                        required
                        label="Full Name"
                        placeholder="Enter your full name"
                      />
                    ) : (
                      <>
                        {" "}
                        {/* FirstName */}
                        <InputTextField
                          name="firstName"
                          required
                          label="First Name"
                          placeholder="Enter your first name"
                        />
                        {/* lastName */}
                        <InputTextField
                          name="lastName"
                          required
                          label="Last Name"
                          placeholder="Enter your last name"
                        />
                      </>
                    )}

                    {/* Nick name */}
                    <InputTextField
                      name="nickName"
                      label="Nick Name"
                      placeholder="Enter your nick name"
                    />

                    {/* date */}
                    <Form.Item
                      className="profileItems"
                      required
                      label="Date of Birth"
                      help={errors?.DOB ? errors?.DOB : undefined}
                      validateStatus={errors?.DOB ? "error" : "success"}>
                      <DatePicker
                        value={values?.DOB ? moment(values?.DOB) : undefined}
                        className="playerDob"
                        name="DOB"
                        showToday
                        inputReadOnly
                        onChange={(value) => handleChanges("DOB", value, setFieldValue)}
                      />
                    </Form.Item>

                    {/* location */}
                    <Form.Item
                      required
                      label="Location"
                      help={errors?.location ? errors?.location : undefined}
                      validateStatus={errors?.location ? "error" : "success"}>
                      <Select
                        className="mb-2 w-100"
                        aria-required
                        value={values?.location}
                        placeholder="Location"
                        title="Location"
                        bordered={true}
                        name="location"
                        onChange={(value) => setFieldValue("location", value)}>
                        {LOCATIONS.map((item, index) => (
                          <Option key={index} value={item.value}>
                            {item.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>

                    {/* description */}
                    <InputTextField
                      name="description"
                      label="Description"
                      placeholder="Something about yourself..."
                      type="textArea"
                    />
                  </Col>
                  {/* seconds form */}
                  <Col lg={11} md={11} xs={24} sm={24} className="playerformPadding">
                    {/* USERNAME */}
                    <UserNameField
                      error={errors?.username}
                      value={values?.username}
                      setFieldValue={setFieldValue}
                      type={ROLES.player}
                      prevUserName={id ? profileData?.username || "" : ""} //incase of edit
                    />

                    {/* Sports type */}
                    <Form.Item label="Sports you play" required>
                      <Radio.Group
                        onChange={(e) => {
                          setFieldValue("sportsType", e.target.value);
                          setFieldValue("selectedSport", "");
                        }}
                        name="sportsType"
                        value={values?.sportsType}>
                        {SPORTS_TYPES_LIST.map((type, index) => (
                          <Radio key={index} value={type.value}>
                            {type.label}
                          </Radio>
                        ))}
                      </Radio.Group>
                    </Form.Item>
                    {/* Sports list based on selected type */}
                    <Form.Item
                      required
                      label="Please select your sport"
                      help={errors?.selectedSport ? errors?.selectedSport : undefined}
                      validateStatus={errors?.selectedSport ? "error" : "success"}>
                      <Select
                        className="mb-2 w-100"
                        aria-required
                        value={values?.selectedSport}
                        placeholder={
                          values?.sportsType === SPORTS_TYPES.physicalSports
                            ? "Physical Sports"
                            : "E-Sports"
                        }
                        title="Teams"
                        bordered={true}
                        name="selectedSport"
                        onChange={(value) => setFieldValue("selectedSport", value)}>
                        {/* Rendered physical sports if physical is selected */}
                        {values?.sportsType === SPORTS_TYPES.physicalSports &&
                          PHYSICAL_SPORTS_LIST.map((sport, index) => (
                            <Option key={index} value={sport.value}>
                              {sport.label}
                            </Option>
                          ))}

                        {/* Rendered E-sports if eSports is selected */}
                        {values?.sportsType === SPORTS_TYPES.eSports &&
                          E_SPORTS_LIST.map((sport, index) => (
                            <Option key={index} value={sport.value}>
                              {sport.label}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                    {/* Awards */}
                    {/* <InputTextField
                      name="awards"
                      label="Awards and Accomplishments"
                      placeholder="eg: Spartan Club"
                    /> */}

                    {/* Role */}
                    {/* <InputTextField name="role" label="Player Role" placeholder="Enter your role" /> */}
                    {/* Experiences */}
                    {/* <Form.Item
                      className="profileItems border"
                      name="experience"
                      label="Experiences"
                      help={errors?.experience ? errors?.experience : undefined}
                      validateStatus={errors?.experience ? "error" : "success"}>
                      <ExperienceModal
                        organizer={false}
                        showExperienceModal={showExperienceModal}
                        setShowExperienceModal={setShowExperienceModal}
                        values={values?.experience}
                        setFieldValue={setFieldValue}
                        handleChanges={handleChanges}
                      />
                      <Row justify="start" align="middle" gutter="1rem">
                        <span>Update Experience</span>
                        <Button
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginLeft: "10px"
                          }}
                          type="primary"
                          shape="circle"
                          onClick={() => setShowExperienceModal(true)}
                          icon={<IoAddSharp />}
                          size={"large"}
                        />
                      </Row>
                    </Form.Item> */}
                    {/* Endorsements */}
                    {/* <Form.Item label="Endorsements (if any)">
                      <Row justify="space-between">
                        <Select
                          mode="tags"
                          value={values?.endorsements}
                          className="w-70"
                          aria-required
                          placeholder="e.g   “ Search “"
                          name="selectedSport"
                          onChange={(value) => setFieldValue("endorsements", value)}>
                          {children}
                        </Select>
                        <Button
                          style={{
                            marginTop: "11px",
                            borderRadius: "5px",
                            marginLeft: "10px"
                          }}
                          type="primary">
                          Send Requests
                        </Button>
                      </Row>
                    </Form.Item> */}
                  </Col>
                </Row>
                <Row justify="center">
                  <Button
                    loading={editloading}
                    htmlType="submit"
                    disabled={!(isValid && dirty) || editloading}
                    className="playerCreateBUtton"
                    type="primary">
                    {id ? "Edit" : "Create"}
                  </Button>
                </Row>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Fragment>
  );
};

export default CreatePlayerProfile;
