import { Button, Checkbox, Col, Form, Input, Modal, Row, Select, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { DeleteEventAward, GetAwardsBySportsType } from "../../../../../Config/API/events";
import { AWARDS_TYPES } from "../../../../../Config/helpers/constants";
import { Info, error } from "../../../../../common/Alerts/Message";
import { MdDelete } from "react-icons/md";

function ChooseAwardsModal({ open, setOpen, values, setFieldValue, sportAwards, setSportAwards }) {
  const [selectedAwards, setSelectedAwards] = useState({});
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [deletedAwards, setDeletedAwards] = useState([]);

  const getAwards = async () => {
    setLoading(true);
    const response = await GetAwardsBySportsType(values?.selectedSport);
    const data = await response.json();
    if (data) {
      setSportAwards(data);
      if (values?.awards?.length > 0) {
        const selected = values?.awards.reduce((acc, curr) => {
          const { awardId, awards } = curr;
          acc[awardId] = awards;
          return acc;
        }, {});

        setSelectedAwards(selected);
      }
    }
    setLoading(false);
  };

  // delete all removed awards that were added in db incase of update event
  const handleDeleteAddedAward = async () => {
    if (deletedAwards?.length > 0) {
      for (let i = 0; i < deletedAwards?.length; i++) {
        try {
          const response = await DeleteEventAward(deletedAwards?.[i]);
          await response.json();
        } catch (error) {
          console.log("Error deleting award");
        }
      }
    }
  };

  //   handle awards check
  const handleAwardCheck = (e) => {
    if (e.target.checked) {
      //  add awards to selected awards
      setSelectedAwards({
        ...selectedAwards,
        [e.target.value]: [{ awardType: "", awardValue: "" }]
      });
    } else {
      // remove award from selected awards
      const updateSelectedAwards = { ...selectedAwards };
      // delete updateSelectedAwards?.[e.target.value];
      if (updateSelectedAwards?.[e.target.value]) {
        const containsAwardId = updateSelectedAwards[e.target.value]?.find((award) => award?._id);
        if (containsAwardId) {
          Info("The following contains awards that were already added, can only be updated");
          return;
        } else {
          delete updateSelectedAwards[e.target.value];
        }
      }

      setSelectedAwards(updateSelectedAwards);
    }
  };

  //   handle awards values and award type change
  const handleValueChange = (value, awardId, singleAwardIndex, name) => {
    const currentAward = selectedAwards?.[awardId];
    if (currentAward) {
      // changing award type
      if (name === "awardType") {
        currentAward[singleAwardIndex].awardType = value;
      } else {
        // adding value for cash
        currentAward[singleAwardIndex].awardValue = value.target.value;
      }

      const updateSelectedAwards = {
        ...selectedAwards,
        [awardId]: currentAward
      };
      setSelectedAwards(updateSelectedAwards);
    }
  };

  // handle add another rank award in the same award
  const handleAddMore = (awardId) => {
    const currentAward = selectedAwards?.[awardId];
    if (currentAward && currentAward?.length > 0) {
      currentAward.push({ awardType: "", awardValue: "" });

      const updateSelectedAwards = {
        ...selectedAwards,
        [awardId]: currentAward
      };
      setSelectedAwards(updateSelectedAwards);
    }
  };

  // handle delete rank award from selected award
  const handleDeleteAward = (awardId, index) => {
    const currentAward = selectedAwards?.[awardId];
    if (currentAward && currentAward?.length > 0) {
      currentAward.splice(index, 1);

      const updateSelectedAwards = {
        ...selectedAwards,
        [awardId]: currentAward
      };
      setSelectedAwards(updateSelectedAwards);
    }
  };

  useEffect(() => {
    if (values?.selectedSport) {
      getAwards();
    }
    return () => {
      setSelectedAwards({});
    };
  }, [values?.selectedSport]);

  const handleSetAwards = async () => {
    const selectedAwardsArray = Object.entries(selectedAwards);
    const currentSelectedAwards = selectedAwardsArray.map(([key, value]) => ({
      awardId: key,
      awards: value?.map((award, index) => {
        return {
          ...award,
          rank: index + 1
        };
      })
    }));

    // check if award type was not selected against any award
    let errors = {};
    selectedAwardsArray?.map((award) =>
      award?.[1].map((subAward, index) => {
        // add errors if award type is missing
        // or if award type is cash but value is missing
        if (
          !subAward?.awardType ||
          subAward?.awardType?.length < 1 ||
          (subAward?.awardType === "cash" &&
            typeof subAward?.awardValue !== "number" &&
            subAward?.awardValue < 1)
        ) {
          if (errors?.[award?.[0]]) {
            errors = { ...errors, [award[0]]: [...errors[award[0]], index] };
          } else {
            errors = { ...errors, [award?.[0]]: [index] };
          }
        }
      })
    );

    if (Object.entries(errors)?.length > 0) {
      error("Select Award type/ add value against added awards");
      setErrors(errors);
    } else {
      setFieldValue("awards", currentSelectedAwards);
      setOpen(false);
      await handleDeleteAddedAward();
    }
  };

  return (
    <>
      <Modal
        title="Choose Awards for event"
        className="editPost AddTeamModel"
        mask
        maskClosable={false}
        onCancel={() => {
          setSelectedAwards({});
          setOpen(false);
        }}
        onOk={handleSetAwards}
        closable={false}
        centered
        destroyOnClose={true}
        open={open}
        bodyStyle={{ padding: "20px" }}>
        <Form
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          autoComplete="off">
          {loading
            ? "Fetching Awards"
            : sportAwards?.length > 0
            ? sportAwards?.map((award, index) => {
                const selected = selectedAwards?.[award?._id];
                return (
                  <>
                    <Row key={index}>
                      <Col className="mb-2" span={24}>
                        <Checkbox
                          style={errors?.[award?._id] && { color: "red" }}
                          value={award?._id}
                          checked={selectedAwards?.[award?._id]}
                          onChange={(e) => handleAwardCheck(e)}>
                          {award?.title}
                        </Checkbox>
                        {selected ? (
                          <Row className="mt-2 mb-2">
                            {selectedAwards?.[award?._id]?.map((singleAward, singleAwardIndex) => {
                              return (
                                <Col span={24} key={singleAwardIndex}>
                                  <Space align="center">
                                    <Typography.Text
                                      style={
                                        errors?.[award?._id]?.includes(singleAwardIndex) && {
                                          color: "red"
                                        }
                                      }>
                                      {/* only show delete option if more than one rank is added against an award */}
                                      {selectedAwards?.[award?._id]?.length > 1 ? (
                                        <MdDelete
                                          style={{ color: "red" }}
                                          className="cursor-pointer"
                                          onClick={() => {
                                            if (singleAward?._id) {
                                              setDeletedAwards((prev) => [
                                                ...prev,
                                                singleAward?._id
                                              ]);
                                            }
                                            handleDeleteAward(award?._id, singleAwardIndex);
                                          }}
                                        />
                                      ) : null}
                                      Award for {singleAwardIndex + 1} rank:
                                    </Typography.Text>
                                    <Select
                                      style={{ width: "120px" }}
                                      value={selected?.[singleAwardIndex]?.awardType}
                                      onChange={(e) =>
                                        handleValueChange(
                                          e,
                                          award?._id,
                                          singleAwardIndex,
                                          "awardType"
                                        )
                                      }
                                      placeholder="Award Type">
                                      {AWARDS_TYPES?.map((type, typeIndex) => (
                                        <Select.Option key={typeIndex} value={type.value}>
                                          {type.label}
                                        </Select.Option>
                                      ))}
                                    </Select>
                                    {/* show award value field for cash */}
                                    {selectedAwards?.[award?._id]?.[singleAwardIndex]?.awardType ===
                                      "cash" && (
                                      <Form.Item className="mb-1">
                                        <Input
                                          size="small"
                                          id="awardValue"
                                          name="awardValue"
                                          type="number"
                                          placeholder="Enter amount"
                                          onChange={(e) =>
                                            handleValueChange(e, award?._id, singleAwardIndex)
                                          }
                                          value={selected?.[singleAwardIndex]?.awardValue}
                                          style={{
                                            borderRadius: "5px",
                                            lineHeight: "30px",
                                            marginTop: "5px"
                                          }}
                                        />
                                      </Form.Item>
                                    )}
                                  </Space>
                                </Col>
                              );
                            })}

                            <Button size="small" onClick={() => handleAddMore(award?._id)}>
                              Add More+
                            </Button>
                          </Row>
                        ) : null}
                      </Col>
                    </Row>
                    <hr />
                  </>
                );
              })
            : "No awards found for sportsType"}
        </Form>
      </Modal>
    </>
  );
}

export default ChooseAwardsModal;
