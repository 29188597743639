import React from "react";
import { Fragment, useEffect, useState } from "react";
import { Row, Col, Form, Button, Radio, Checkbox, DatePicker } from "antd";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import "../../styles/user/user.css";
import { signupAPI } from "../../Config/API/user";
import { serverError } from "../../common/Alerts/serverError";
import {
  ROLES,
  ROLES_LIST,
  SPONSOR_TYPES_LIST,
  sponsorTypes
} from "../../Config/helpers/constants";
import { Formik } from "formik";
import InputTextField from "../../common/FormItem/InputTextField";
import { signUpValidations } from "../../Config/helpers/validations";
import dayjs from "dayjs";
import moment from "moment";

const Signup = () => {
  const [loading, setloading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const isSponsor = searchParams.get("sponsor");

  const navigate = useNavigate();

  useEffect(() => {
    let role = JSON.parse(localStorage.getItem("userDetails"))?.role;
    role = String(role).toLowerCase();
    const token = localStorage.getItem("userToken");
    if (token && role) {
      if (role === ROLES.organizer) {
        navigate("/organizer");
      }

      if (role === ROLES.player) {
        navigate("/player");
      }

      if (role === ROLES.sponsor) {
        navigate("/sponsor");
      }
    }
  }, []);

  // handle submit signUp
  const onFinish = async (values) => {
    const { email, firstName, lastName, nickName, role, password, phoneNumber } = values;
    let payload = {
      email,
      firstName,
      lastName,
      nickName,
      password,
      phone: phoneNumber,
      primaryRole: isSponsor ? ROLES.sponsor : role,
      dob: values?.DOB ? JSON.stringify(dayjs(values?.DOB).format("YYYY-MM-DD")) : ""
    };
    if (isSponsor) {
      payload.isOrganization = values?.sponsorType === sponsorTypes.organization ? true : false;
      payload.organizationName = firstName;
      delete payload.firstName;
    }

    let response;
    try {
      setloading(true);
      response = await signupAPI(payload);
      const data = await response.json();
      setloading(false);
      if (data.type === "success") {
        Swal.fire({
          title: "profile created successfully!",
          text: "Verfication email has been sent to your email please check your inbox or spam folder",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
          confirmButtonColor: "#0085FF"
        }).then(
          (result) => result.isConfirmed && navigate(isSponsor ? "/signin?sponsor=true" : "/signin")
        );
      }
      if (data.type === "emailExist") {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Email or phone number already exist",
          confirmButtonColor: "#0085FF",
          allowOutsideClick: false
        });
      }
    } catch (err) {
      console.log(err);
      setloading(false);

      serverError();
    }
  };

  const initialValues = {
    firstName: "",
    lastName: "",
    nickName: "",
    email: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
    role: ROLES.player,
    organizationName: "",
    sponsorType: sponsorTypes.individual
  };

  return (
    <Fragment>
      <Row style={{ paddingBottom: "2%", paddingTop: "40px" }} justify="center">
        <Col md={12}>
          <Row className="middleRowPadding justifycenter">
            <Link to="/">
              <img alt="logo" className="logouser" height="100px" src="/images/logo.png" />
            </Link>
          </Row>
          <Row className="downMiddleRowPadding justifycenter">
            <h1 className="gameFont">Join the Game!</h1>
          </Row>
          <Row className="insidePadding justifycenter">
            <h1 className="insideFont">Go inside the best gamers social network!</h1>
          </Row>
          <Row className="signinAs justifycenter">
            {isSponsor ? <h1>Sponsor Signup</h1> : <h1>Sign up as</h1>}
          </Row>
          <Row className="buttonsPadding" style={{ justifyContent: "space-evenly" }}>
            <button className="socialMediaButtons">
              <img alt="google.png" height="22px" width="22px" src="/images/google.png"></img>
            </button>
            <button className="socialMediaButtons">
              <img alt="twitter.png" height="21px" width="28px" src="/images/twitter.png"></img>
            </button>
            <button className="socialMediaButtons">
              <img alt="facebook.png" height="24px" width="24px" src="/images/fb.png"></img>
            </button>
            <button className="socialMediaButtons">
              <img
                alt="discort.png"
                height="24px"
                width="24px"
                src="https://assets-global.website-files.com/6257adef93867e50d84d30e2/636e0a6a49cf127bf92de1e2_icon_clyde_blurple_RGB.png"></img>
            </button>
          </Row>
          <Row className="signInLink mt-5" justify="center">
            {!isSponsor ? (
              <p>
                Register as a Sponsor?
                <Link
                  to="/register?sponsor=true"
                  onClick={() => setSearchParams("sponsor=yes")}
                  style={{ color: "#0085FF" }}>
                  {" "}
                  Sponsor Signup
                </Link>
              </p>
            ) : (
              <p>
                Register as a player/organizer ?
                <Link
                  to="/register"
                  onClick={() => setSearchParams("")}
                  style={{ color: "#0085FF" }}>
                  {" "}
                  Signup
                </Link>
              </p>
            )}
          </Row>
        </Col>
        <Col md={12}>
          <Row>
            <Col span={24}>
              <h1 className="signInCenter">Sign Up</h1>
            </Col>
          </Row>
          <Row justify="center">
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={signUpValidations(isSponsor)}
              onSubmit={onFinish}>
              {({ handleChange, values, errors, handleSubmit, setFieldValue, isValid, dirty }) => (
                <>
                  <Form
                    name="basic"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    onFinish={handleSubmit}>
                    <Row>
                      <Col md={10} xs={24} sm={24}>
                        <InputTextField
                          name="email"
                          label="Email"
                          type="email"
                          placeholder="Enter your email"
                          hasFeedback
                          required
                        />

                        <InputTextField
                          name="firstName"
                          label={
                            isSponsor && values?.sponsorType === sponsorTypes.organization
                              ? "Organization Name"
                              : "First Name"
                          }
                          placeholder="Enter Name"
                          required
                        />
                        {values?.sponsorType !== sponsorTypes.organization && (
                          <InputTextField
                            name="lastName"
                            label="Last Name"
                            placeholder="Enter your Last Name"
                            required
                          />
                        )}
                        {/* date */}
                        <Form.Item
                          className="profileItems"
                          required
                          label="Date of Birth"
                          help={errors?.DOB ? errors?.DOB : undefined}
                          validateStatus={errors?.DOB ? "error" : "success"}>
                          <DatePicker
                            value={values?.DOB ? moment(values?.DOB) : undefined}
                            className="playerDob"
                            name="DOB"
                            showToday
                            inputReadOnly
                            onChange={(value) => setFieldValue("DOB", value)}
                          />
                        </Form.Item>
                        {isSponsor && (
                          <Form.Item
                            className="pt-2"
                            label="Primary Role"
                            rules={[{ required: true }]}
                            name="radio-group">
                            <Radio.Group
                              defaultValue={values?.sponsorType}
                              name="sponsorType"
                              value={values?.sponsorType}
                              onChange={handleChange}
                              style={{ marginTop: "9px" }}>
                              {SPONSOR_TYPES_LIST.map((sponsor, index) => (
                                <Radio key={index} value={sponsor?.value}>
                                  {sponsor.name}
                                </Radio>
                              ))}
                            </Radio.Group>
                          </Form.Item>
                        )}
                        {!isSponsor && (
                          <Form.Item
                            className="pt-2"
                            label="Primary Role"
                            rules={[{ required: true }]}
                            name="radio-group">
                            <Radio.Group
                              name="role"
                              value={values?.role}
                              onChange={handleChange}
                              style={{ marginTop: "9px" }}>
                              {ROLES_LIST.filter((role) => role?.value !== ROLES.sponsor).map(
                                (role, index) => (
                                  <Radio key={index} value={role?.value}>
                                    {role.label}
                                  </Radio>
                                )
                              )}
                            </Radio.Group>
                          </Form.Item>
                        )}
                      </Col>
                      <Col md={2} xs={0} sm={0}></Col>
                      <Col md={10} xs={24} sm={24}>
                        {values?.role === ROLES.player && !isSponsor && (
                          <InputTextField
                            label="Nickname"
                            placeholder="Enter your Nick Name"
                            name="nickName"
                          />
                        )}
                        <InputTextField
                          label="Phone Number"
                          hasFeedback
                          required
                          placeholder="Enter your number"
                          name="phoneNumber"
                          addonBefore="+92"
                          className="inputnumber"
                          type="number"
                        />
                        <InputTextField
                          label="Password"
                          hasFeedback
                          required
                          placeholder="Enter your password"
                          name="password"
                          type="password"
                        />
                        <InputTextField
                          label="Confirm Password"
                          hasFeedback
                          required
                          placeholder="Enter your confirm password"
                          name="confirmPassword"
                          type="password"
                        />
                      </Col>
                    </Row>
                    {/* Form Bottom */}
                    <Row justify="center" className="mt-4">
                      <Col md={12}>
                        <Checkbox
                          onChange={(e) => setFieldValue("termsAndConditions", e.target.checked)}
                          checked={values?.termsAndConditions}
                          value={values?.termsAndConditions}>
                          I agree to{" "}
                          <Link to="/" style={{ color: "#0085FF" }}>
                            terms & conditions
                          </Link>
                        </Checkbox>
                        <Checkbox style={{ marginLeft: "0px", marginTop: "2%" }}>
                          I’d like being informed about latest news and tips
                        </Checkbox>
                        <Button
                          className="signUpButton"
                          disabled={!(isValid && dirty)}
                          type="primary"
                          htmlType="submit"
                          loading={loading}>
                          Sign up
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </>
              )}
            </Formik>
          </Row>
          <Row className="signInLink" justify="center">
            <p>
              Do you already have an account?{" "}
              <Link
                to={isSponsor ? "/signin?sponsor=true" : "/signin"}
                style={{ color: "#0085FF" }}>
                Sign in
              </Link>
            </p>
          </Row>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Signup;
