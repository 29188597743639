import React, { Fragment } from "react";
import { Layout } from "antd";
import OrganizerPNav from "./OrganizerPNav";

import { Outlet } from "react-router-dom";
const { Content } = Layout;
const OrganizerPLayout = () => {
  return (
    <Layout>
      <Fragment>
        <OrganizerPNav />
      </Fragment>
      <Content>
        <Outlet />
      </Content>
      {/* <Footer style={{ backgroundColor: "#2F89FC" }}>
        <HomeFooter />
      </Footer> */}
    </Layout>
  );
};

export default OrganizerPLayout;
