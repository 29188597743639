// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NoDataWrapper {
  width: 100% !important;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 12px;
  padding: 20px 20px;
  -webkit-user-select: none;
          user-select: none;
  margin-bottom: 5%;
  box-shadow: 0px 3.94082px 14.7781px rgb(0 0 0 / 9%);
  height: 250px;
}
.NoDataWrapper h2 {
  margin-bottom: 0;
  color: #59a0e8;
  color: #898989;
  font-size: 20px;
}
.NoDataIcon {
  font-size: 75px;
  color: var(--green);
}
.noDataimg {
  max-width: 100%;
  height: 95px;
}

@media screen and (max-width: 576px) {
  .NoDataWrapper {
    padding: 0;
    gap: 10px;
    max-height: 170px;
  }
  .noDataimg {
    height: 70px;
  }
  .NoDataWrapper h2 {
    font-size: 17px;
  }
}
@media screen and (max-width: 320px) {
  .NoDataWrapper {
    padding: 0;
    gap: 10px;
    max-height: 120px;
  }
  .noDataimg {
    height: 50px;
  }
  .NoDataWrapper h2 {
    font-size: 14px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/NoData/noData.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,mBAAmB;EACnB,uBAAuB;EACvB,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,yBAAiB;UAAjB,iBAAiB;EACjB,iBAAiB;EACjB,mDAAmD;EACnD,aAAa;AACf;AACA;EACE,gBAAgB;EAChB,cAAc;EACd,cAAc;EACd,eAAe;AACjB;AACA;EACE,eAAe;EACf,mBAAmB;AACrB;AACA;EACE,eAAe;EACf,YAAY;AACd;;AAEA;EACE;IACE,UAAU;IACV,SAAS;IACT,iBAAiB;EACnB;EACA;IACE,YAAY;EACd;EACA;IACE,eAAe;EACjB;AACF;AACA;EACE;IACE,UAAU;IACV,SAAS;IACT,iBAAiB;EACnB;EACA;IACE,YAAY;EACd;EACA;IACE,eAAe;EACjB;AACF","sourcesContent":[".NoDataWrapper {\n  width: 100% !important;\n  display: flex;\n  flex-direction: column;\n  gap: 30px;\n  align-items: center;\n  justify-content: center;\n  background-color: white;\n  border-radius: 12px;\n  padding: 20px 20px;\n  user-select: none;\n  margin-bottom: 5%;\n  box-shadow: 0px 3.94082px 14.7781px rgb(0 0 0 / 9%);\n  height: 250px;\n}\n.NoDataWrapper h2 {\n  margin-bottom: 0;\n  color: #59a0e8;\n  color: #898989;\n  font-size: 20px;\n}\n.NoDataIcon {\n  font-size: 75px;\n  color: var(--green);\n}\n.noDataimg {\n  max-width: 100%;\n  height: 95px;\n}\n\n@media screen and (max-width: 576px) {\n  .NoDataWrapper {\n    padding: 0;\n    gap: 10px;\n    max-height: 170px;\n  }\n  .noDataimg {\n    height: 70px;\n  }\n  .NoDataWrapper h2 {\n    font-size: 17px;\n  }\n}\n@media screen and (max-width: 320px) {\n  .NoDataWrapper {\n    padding: 0;\n    gap: 10px;\n    max-height: 120px;\n  }\n  .noDataimg {\n    height: 50px;\n  }\n  .NoDataWrapper h2 {\n    font-size: 14px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
