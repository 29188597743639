import { Table, Row, Col } from "antd";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { GetGroupEventData } from "../../../../../../Config/API/events";
import {
  MATCHES,
  load,
  unload,
  isLoading,
  showQaulify
} from "../../../../../../Store/Features/MatchesSlice";
import { error } from "../../../../../../common/Alerts/Message";
import FixtureWrapper from "./Fixtures/index";
import Loader from "../../../../../../common/cards/Loader";
import { Column, QualifyingColumn } from "../../cards/columns";
import { useSortedpoints } from "../utility";
import useRoundsButtons from "../KO/useRoundsUtility/useRoundsButtons";

const History = () => {
  const Matches = useSelector(MATCHES);
  let showq = useSelector(showQaulify);
  const loading = useSelector(isLoading);
  const { id } = useParams();
  const { state } = useLocation();

  const dispatch = useDispatch();
  const { sorteddata } = useSortedpoints();

  const pagesise = 8;

  var columns = [];

  if (showq) {
    columns = QualifyingColumn;
  } else columns = Column;

  const get = async () => {
    try {
      const res = await GetGroupEventData({ eventId: state?._id || id });
      const data = await res.json();

      console.log(data);

      await sorteddata(data?.result[0]);

      dispatch(unload());
    } catch (e) {
      console.log(e);
      error(e.message);
      dispatch(unload());
    }
  };

  useEffect(() => {
    dispatch(load());
    get();
  }, []);
  const { HistoryButtons } = useRoundsButtons();

  return (
    <div>
      <h1 className="ceheading">Matches History </h1>
      <HistoryButtons origin={"history"} loading={loading} />
      <div
        className={`formorganizer ${loading ? "rowloadingevent" : null}`}
        style={{
          marginBottom: "50px",
          paddingBottom: "0"
        }}>
        {loading ? <Loader noShadow /> : null}
        <Row justify="center" style={{ display: loading ? "none" : undefined }}>
          <Col xl={16} lg={24} xs={24} sm={24} md={24} className="ceinputformpadding">
            <h2
              style={{
                borderBottom: "1px solid  #282828",
                paddingBottom: "5px"
              }}>
              Standing
            </h2>
            {Matches?.teams?.map((e, i) => (
              <Table
                key={i}
                title={() => <div className="tableTitle" key={i}>{`Group ${i + 1}`}</div>}
                scroll={{ x: 500 }}
                style={{
                  paddingTop: e?.length < pagesise ? "50px" : "30px",
                  paddingBottom: e?.length < pagesise ? "50px" : null
                }}
                columns={columns}
                dataSource={e}
                size="middle"
                pagination={{
                  total: e?.length,
                  defaultCurrent: 1,
                  defaultPageSize: pagesise,
                  hideOnSinglePage: true,
                  style: { margin: "50px 0 10px auto" }
                }}
                className="tablesBoxShadow scrollAble"
              />
            ))}
          </Col>
        </Row>
        {loading ? null : <FixtureWrapper />}
      </div>
    </div>
  );
};

export default History;
