import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Typography, Card, Tooltip, Pagination } from "antd";
import { GiTakeMyMoney, GiReceiveMoney, GiPayMoney } from "react-icons/gi";
import TransactionsHistory from "./TransactionsHistory";
import { GetTransactionHistory, GetUserWallet } from "../../Config/API/Wallets";
import { error } from "../../common/Alerts/Message";
import TopUpModal from "./TopUpModal";
import { ROLES } from "../../Config/helpers/constants";
import useVariables from "../../common/hooks/useVariables";
import { useParams } from "react-router-dom";
import { SetFlexToken } from "../../Store/Features/WalletSlice";
import { useDispatch } from "react-redux";
import TransferTokens from "./TransferTokens";
import WithDrawTokens from "./WithdrawTokens";
const { Meta } = Card;
const { Title } = Typography;

function UserWallet({ userType }) {
  const [wallet, setWallet] = useState(null);
  const [topUpModal, setTopUpModal] = useState(false);
  const [withdrawalModal, setWithdrawalModal] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [transactionsLoading, setTransactionsLoading] = useState(false);
  const [filter, setFilter] = useState(null);
  const [userId, setUserId] = useState(null);
  const [transferDrawerOpen, setTransferDrawerOpen] = useState(false);

  const { PID } = useVariables();
  const { id } = useParams();
  const dispatch = useDispatch();

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const limit = 15;

  const getUserIdForWallet = (userType) => {
    switch (userType) {
      case ROLES.organizer:
        setUserId(PID);
        break;
      case ROLES.player:
        setUserId(PID);
        break;
      case ROLES.panel:
        setUserId(id);
        break;
      case ROLES.team:
        setUserId(id);
        break;
      default:
        break;
    }
  };

  const getWallet = async () => {
    const response = await GetUserWallet(userType, userId);
    const data = await response.json();
    if (data?.type === "success") {
      const walletData = Array.isArray(data?.result) ? data?.result?.[0] : data?.result;
      setWallet(walletData);
      // to show amount in header dropdown
      if (userType === ROLES.organizer || userType === ROLES.player) {
        dispatch(SetFlexToken(walletData?.balance || 0));
      }
    } else {
      error("Error occurred while fetching wallet");
    }
  };

  const getUserTransactionHistory = async (page) => {
    setTransactionsLoading(true);
    const newOffset = (page - 1) * limit;

    const response = await GetTransactionHistory(userId, filter, newOffset, limit);
    const data = await response.json();
    if (data?.type === "success") {
      setTransactions(data?.result);
      setTotalPages(Math.ceil(data?.totalCount / limit));
      setCurrentPage(page);
    }
    setTransactionsLoading(false);
  };

  useEffect(() => {
    if ((userType, userId)) {
      getWallet();
    }
  }, [userType, userId]);

  useEffect(() => {
    if ((userType, userId)) {
      getUserTransactionHistory(1);
    }
  }, [userType, userId, filter]);

  useEffect(() => {
    if (userType) {
      getUserIdForWallet(userType);
    }
  }, [userType]);

  // transaction pagination
  const goToPage = (pageNumber) => {
    getUserTransactionHistory(pageNumber);
  };

  return (
    <Fragment>
      <Row className="rowPadding" style={{ width: "95%", margin: "auto" }}>
        <Col lg={8} md={20} sm={24} xs={24} className="mr-3 mb-4">
          <Card
            title={`${userType}'s Wallet`}
            actions={[
              <Tooltip key="topUp" title="Top Up">
                <GiTakeMyMoney size="40px" onClick={() => wallet?._id && setTopUpModal(true)} />
              </Tooltip>,
              <Tooltip key="withdraw" title="Withdraw">
                <GiReceiveMoney
                  size="40px"
                  onClick={() => wallet?._id && setWithdrawalModal(true)}
                />
              </Tooltip>,
              <Tooltip key="transfer" title="Transfer Token">
                <GiPayMoney
                  size="40px"
                  onClick={() => wallet?._id && setTransferDrawerOpen(true)}
                />
              </Tooltip>
            ]}>
            <Meta title="FlexTokens" description={wallet?.balance || "-"} />
          </Card>
        </Col>
        <Col lg={14} md={24} sm={24} xs={24}>
          <Title>Transactions </Title>
          <TransactionsHistory
            transactions={transactions}
            loading={transactionsLoading}
            userId={userId}
            setFilter={setFilter}
            filter={filter}
          />
          <Pagination
            className="mt-2"
            current={currentPage}
            total={totalPages * limit}
            pageSize={limit}
            showSizeChanger={false}
            onChange={goToPage}
          />
        </Col>
      </Row>
      {/* Top up modal */}
      <TopUpModal
        open={topUpModal}
        setOpen={setTopUpModal}
        userId={userId}
        userType={userType}
        filter={filter}
        getUserTransactionHistory={() => getUserTransactionHistory(1)}
      />

      {/* Transfer drawer */}
      {transferDrawerOpen && (
        <TransferTokens
          open={transferDrawerOpen}
          setOpen={setTransferDrawerOpen}
          wallet={wallet}
          getWallet={getWallet}
          getUserTransactionHistory={() => getUserTransactionHistory(1)}
          userType={userType}
          userId={userId}
        />
      )}

      {withdrawalModal && (
        <WithDrawTokens
          open={withdrawalModal}
          setOpen={setWithdrawalModal}
          wallet={wallet}
          getUserTransactionHistory={() => getUserTransactionHistory(1)}
          userType={userType}
          userId={userId}
        />
      )}
    </Fragment>
  );
}

export default UserWallet;
