import React from "react";
import { Dropdown, Menu, Button } from "antd";
import classes from "./index.module.css";
import { DownOutlined } from "@ant-design/icons";

const NumberDropdown = ({ onChange, label, selectedValue }) => {
  const menu = (
    <Menu
      className={classes.dropdownMenu}
      style={{
        minWidth: "0"
      }}>
      {Array.from({ length: 5 }, (_, i) => i + 1).map((num) => (
        <Menu.Item
          key={num}
          onClick={() => onChange(num)}
          className={classes.dropdownItem}
          style={{
            borderBottom: `${num < 5 ? "1px solid #dbdbdb" : ""}`
          }}>
          {num}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div className={classes.dropdownContainer}>
      <Dropdown overlay={menu} trigger={["click"]}>
        <Button className={classes.dropdownBtn}>
          {/* Show the selected value, or the dropdown icon if none is selected */}
          {selectedValue ? (
            selectedValue
          ) : (
            <DownOutlined style={{ paddingLeft: "2px", textAlign: "end" }} />
          )}
        </Button>
      </Dropdown>
      <span style={{ marginLeft: "5px", marginRight: "6px" }}>{label}</span>
    </div>
  );
};

export default NumberDropdown;
