import { JoinTeamRequest } from "../../Config/API/teams";
import { JoinPanelRequest } from "../../Config/API/panel";
import { useSelector } from "react-redux";
import { error, success } from "../Alerts/Message";
import { PROFILE, setProfile } from "../../Store/Features/ProfileSlice";
import { useDispatch } from "react-redux";
import useVariables from "./useVariables";

export const useGroupRequests = () => {
  const profile = useSelector(PROFILE);
  const { viewer } = useVariables();

  const dispatch = useDispatch();
  const TeamJoinRequestFun = async (setloading, setisjoined) => {
    try {
      const id = viewer?.id;

      setloading("join");
      const values = {
        id: profile?._id,
        playerId: id,
        type: "Join"
        // playerRecId: profile?.profileId
      };
      const res = await JoinTeamRequest(values);
      const data = await res.json();
      console.log(data);
      if (data.type === "success") {
        success("Team Joining Request Sent");
        setisjoined("requested");
        dispatch(
          setProfile({
            ...data?.data,
            profile: data?.data?.profilepic,
            cover: data?.data?.coverpic
          })
        );
      } else {
        error(data?.result);
      }
      setloading(false);
    } catch (e) {
      console.log(e);
      error(e.message);
      setloading(false);
    }
  };

  const PanelJoinRequestFun = async (setloading, setisjoined) => {
    try {
      const id = viewer?.id;
      setloading("join");
      const values = {
        id: profile?._id,
        organizerid: id,
        type: "Join"
        // organizerRecid: profile?.profileId
      };
      const res = await JoinPanelRequest(values);
      const data = await res.json();
      if (data.type === "success") {
        success("Panel Joining Request Sent");
        setisjoined("requested");
        dispatch(
          setProfile({
            ...data?.data,
            profile: data?.data?.profilepic,
            cover: data?.data?.coverpic
          })
        );
      } else {
        error(data?.result);
      }
      console.log(data);

      setloading(false);
    } catch (e) {
      console.log(e);
      error(e.message);
      setloading(false);
    }
  };
  return {
    TeamJoinRequestFun,
    PanelJoinRequestFun
  };
};
