import React, { Fragment, useEffect, useState } from "react";
import { Button, Card } from "antd";
import {
  checkProfileName,
  checkProfileImage,
  checkCoverImage,
  getuserlink,
  fallbackcover,
  loadimage
} from "../../../common/Utils";
import { removeFollower, unFollow, useDeleteFollower } from "../functions";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { BsEyeFill } from "react-icons/bs";
import { RiUserUnfollowFill } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { PROFILE, setProfile } from "../../../Store/Features/ProfileSlice";
import FadeInImage from "../../../common/Images/FadeInImage";
import { useSelector } from "react-redux";
import useVariables from "../../../common/hooks/useVariables";

const { Meta } = Card;
const RequestCard = ({ type, data }) => {
  const dispatch = useDispatch();

  const [profileload, setprofileload] = useState(true);
  const [removeStatus, setRemoveStatus] = useState(false);
  const [cancelStatus, setCancelStatus] = useState(false);
  const { pathname } = useLocation();

  // remove team/panel follow/following
  const { removeGroupFollower, unfollowGroupFollowing } = useDeleteFollower();

  const navigate = useNavigate();
  const ID = useSelector(PROFILE);
  const { PID } = useVariables();

  useEffect(() => {
    const view = pathname.includes("view"); //other user is viewing
    const following = pathname.includes("followings"); //following page
    const follower = pathname.includes("followers"); // follower page

    if (pathname.includes("team") || pathname.includes("panel")) {
      if (!view && !following && follower && PID === ID?.profileId) {
        console.log(PID === ID?.profileId);
        setRemoveStatus(true);
      } else if (!view && following && PID === ID?.profileId) {
        setCancelStatus(true);
      }
    } else {
      if (!view && !following && follower) {
        setRemoveStatus(true);
      } else if (!view && following) {
        setCancelStatus(true);
      }
    }

    // can't check profile image loading state through image onLoad function , so using a custom function to check if profile image is loaded or not
    loadimage(checkProfileImage(data), setprofileload);
    return () => {
      setCancelStatus(null);
      setRemoveStatus(null);
      setprofileload(null);
    };
  }, [data?._id, pathname, data]);
  let name = checkProfileName(data);

  const cancelRequest = () => {
    Swal.fire({
      icon: "question",
      text: "Are you sure you want to unfollow " + name + " ?"
    }).then((e) => {
      if (e.isConfirmed) {
        //item, setitem, name, setloading, setisFollowing
        let d, name;
        if (data?.player) {
          d = data?.player;
          name = "player";
        }
        if (data?.organizer) {
          d = data?.organizer;
          name = "organizer";
        }
        if (data?.team) {
          d = data?.team;
          name = "team";
        }
        if (data?.panel) {
          d = data?.panel;
          name = "panel";
        }
        if (!pathname.includes("other")) {
          if (!pathname.includes("team") && !pathname.includes("panel")) {
            unFollow(d, setProfile, name, setprofileload, "myprofile", dispatch);
          } else if (pathname.includes("team") || pathname.includes("panel")) {
            unfollowGroupFollowing(d, setProfile, name, setprofileload, "myprofile", dispatch);
          }
        }
      }
    });
  };

  const deletefollower = () => {
    Swal.fire({
      icon: "question",
      text: "Are you sure you want to Delete follower " + name + " ?"
    }).then((e) => {
      if (e.isConfirmed) {
        //teamId followerId  flag
        let id, flag;
        if (data?.player) {
          id = data?.player?._id;
          flag = "player";
        }
        if (data?.organizer) {
          id = data?.organizer?._id;
          flag = "organizer";
        }
        if (data?.team) {
          id = data?.team?._id;
          flag = "team";
        }
        if (data?.panel) {
          id = data?.panel?._id;
          flag = "panel";
        }
        if (!pathname.includes("other")) {
          if (!pathname.includes("team") && !pathname.includes("panel")) {
            removeFollower(id, setProfile, flag, setprofileload, dispatch);
          } else if (pathname.includes("team") || pathname.includes("panel")) {
            removeGroupFollower(id, setProfile, flag, setprofileload, dispatch);
          }
        }
      }
    });
  };
  return (
    <Fragment>
      <Card
        bordered={false}
        loading={profileload}
        hoverable
        className="pomemberShadow FollowCard"
        cover={
          <FadeInImage
            className={"FollowCardCoverImg"}
            src={checkCoverImage(data)}
            type={"FadesideItem150 follow"}
            radiusAll
          />
        }>
        <Meta
          className="followcardmeta"
          style={{
            display: "grid",
            placeContent: "center",
            background: ""
          }}
          avatar={
            <img
              className="FollowCardImg"
              src={checkProfileImage(data)}
              onError={(e) => {
                e.currentTarget.src = fallbackcover;
              }}
              alt=""
            />
          }
        />
        <div className="additional">
          <h4>{name?.length >= 25 ? name?.substring(0, 22) + " ..." : name}</h4>

          {/* Cancel Button */}

          {cancelStatus && data?.accept === false && <p>&quot; (Request Pending)&quot; </p>}
          <div
            style={{
              display: "flex",
              gap: ".5rem"
            }}>
            {cancelStatus && (
              <Button
                type={type}
                onClick={() => cancelRequest()}
                style={{
                  marginBottom: "10px",
                  borderRadius: "20px",
                  width: "50px"
                }}>
                <RiUserUnfollowFill fontSize={18} />
              </Button>
            )}

            {removeStatus && (
              <Button
                type={"danger"}
                onClick={() => {
                  deletefollower();
                }}
                style={{
                  marginBottom: "10px",
                  borderRadius: "20px",
                  width: "50px"
                }}>
                <RiUserUnfollowFill fontSize={18} />
              </Button>
            )}
            <Button
              onClick={() => {
                const link = getuserlink(data);
                navigate(link?.url, { state: link?.name });
              }}
              type={"primary"}
              style={{
                justifySelf: "flex-start",
                // marginBottom: "10px",
                borderRadius: "20px",
                width: "50px"
              }}>
              <BsEyeFill fontSize={18} />
            </Button>
          </div>
        </div>
      </Card>
    </Fragment>
  );
};

export default RequestCard;
