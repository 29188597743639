import React from "react";
import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse, Row, Col } from "antd";
import { Fragment } from "react";
import ExploreEventsImgCard from "../../cards/ExploreEventCard/ExploreEventImgCard";

const { Panel } = Collapse;

const imageProps = {
  className: "roundedImages",
  styles: {
    borderRadius: "10px",
    height: "100px"
  }
};
const ExploreEventsAccordian = () => (
  <Collapse
    bordered={false}
    // style={{ width: fullwidthEvent ? "100%" : "85%" }}
    defaultActiveKey={["Rating"]}
    expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
    className="site-collapse-custom-collapse sideItem  timelineAboutShadow">
    <Panel header="Explore Events " key="1" className="site-collapse-custom-panel">
      <Fragment>
        <Row className=" " style={{ width: "85%", margin: "2% auto" }}>
          <Col span={24}>
            <ExploreEventsImgCard props={imageProps} text1="Cricket" text2="Football" />
            <ExploreEventsImgCard props={imageProps} text1="Volleyball" text2="Ice Hockey" />
            <ExploreEventsImgCard props={imageProps} text1="Cricket" text2="Football" />
            <ExploreEventsImgCard props={imageProps} text1="Volleyball" text2="Ice Hockey" />
          </Col>
          <Col span={24}>
            <p
              style={{
                fontSize: "12px",
                marginTop: "15px",
                color: "#0085FF",
                fontWeight: "600",
                cursor: "pointer",
                textAlign: "center"
              }}>
              Show More
            </p>
          </Col>
        </Row>
      </Fragment>
    </Panel>
  </Collapse>
);

export default ExploreEventsAccordian;
