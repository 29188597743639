// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.d-none {
  display: none;
}

.mb-20 {
  margin-bottom: 20px;
}
.mt-20 {
  margin-top: 25px;
}

/* for player and organizer */
@media screen and (max-width: 992px) {
  .d-md-block {
    display: block !important;
  }
  .d-md-none {
    display: none !important;
  }
  .mdTextarea {
    transition: all 0.3s ease-in-out;
  }
}

/* for team and panels */
@media screen and (max-width: 1200px) {
  .d-md-block.teampanelnav {
    display: block;
  }
  .d-md-none.teampanelnav {
    display: none;
  }
  .mdTextarea {
    transition: all 0.3s ease-in-out;
  }
  .teampanelnav.tosubnav1 p {
    margin-top: 8px;
  }
  .teampanelnav.tosubnav1 {
    border-radius: 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/utils/utils.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,mBAAmB;AACrB;AACA;EACE,gBAAgB;AAClB;;AAEA,6BAA6B;AAC7B;EACE;IACE,yBAAyB;EAC3B;EACA;IACE,wBAAwB;EAC1B;EACA;IACE,gCAAgC;EAClC;AACF;;AAEA,wBAAwB;AACxB;EACE;IACE,cAAc;EAChB;EACA;IACE,aAAa;EACf;EACA;IACE,gCAAgC;EAClC;EACA;IACE,eAAe;EACjB;EACA;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".d-none {\n  display: none;\n}\n\n.mb-20 {\n  margin-bottom: 20px;\n}\n.mt-20 {\n  margin-top: 25px;\n}\n\n/* for player and organizer */\n@media screen and (max-width: 992px) {\n  .d-md-block {\n    display: block !important;\n  }\n  .d-md-none {\n    display: none !important;\n  }\n  .mdTextarea {\n    transition: all 0.3s ease-in-out;\n  }\n}\n\n/* for team and panels */\n@media screen and (max-width: 1200px) {\n  .d-md-block.teampanelnav {\n    display: block;\n  }\n  .d-md-none.teampanelnav {\n    display: none;\n  }\n  .mdTextarea {\n    transition: all 0.3s ease-in-out;\n  }\n  .teampanelnav.tosubnav1 p {\n    margin-top: 8px;\n  }\n  .teampanelnav.tosubnav1 {\n    border-radius: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
