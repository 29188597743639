import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse, Grid } from "antd";
import React from "react";
import { Link } from "react-router-dom";
const { useBreakpoint } = Grid;

const { Panel } = Collapse;

const AboutAccordian = ({ data, fullwidthEvent }) => {
  const screens = useBreakpoint();
  return (
    <Collapse
      bordered={false}
      style={{
        width: fullwidthEvent ? "100%" : "85%"
      }}
      defaultActiveKey={["DescriptionAccordiant"]}
      expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
      className="site-collapse-custom-collapse  sideItem">
      <Panel header="About" key="1" className={`site-collapse-custom-panel`}>
        <p className="sideItemsText" style={{ marginLeft: "1rem" }}>
          {data?.description ||
            `
                It is a long established fact that a reader will be distracted by  the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using
                 `}
        </p>
        <p className="timelineiconpadding flexcenter">
          <img src="/images/icons/2people.PNG" className="timelineiconplacecenter" alt="" />

          <span className="sideItemsText">
            {data?.registerteams?.length === 1
              ? data?.registerteams?.length + " Team "
              : data?.registerteams?.length + "  Teams are "}
            Registered.
          </span>
        </p>
        {screens.sm && (
          <p className="timelineiconpadding flexcenter">
            <img src="/images/icons/peoples.PNG" className="timelineiconplacecenter" alt="" />

            <span className="sideItemsText">
              Event by{" "}
              <Link
                state="panel"
                to={`/view/${data?.panel?._id}/timeline`}
                className="boldBlueColor">
                {data?.panel?.name}
              </Link>
            </span>
          </p>
        )}
        <p className="timelineiconpadding flexcenter">
          <img src="/images/icons/location.PNG" className="timelineiconplacecenter" alt="" />

          <span className="sideItemsText">{data?.location}</span>
        </p>
        <p className="timelineiconpadding flexcenter">
          <img src="/images/icons/money.PNG" className="timelineiconplacecenter" alt="" />

          <span className="sideItemsText">
            {data?.fee !== 0 ? (
              <span>{data?.fee} Rs per Team</span>
            ) : (
              <span>Free Registration</span>
            )}
          </span>
        </p>
        <p className="timelineiconpadding flexcenter">
          <img src="/images/icons/aboutcalender.PNG" className="timelineiconplacecenter" alt="" />
          <span
            className="sideItemsText timelinetopcolor"
            style={{ display: "flex", gap: ".3rem" }}>
            <span>{new Date(data?.startDate).toDateString()}</span>-
            <span>{new Date(data?.endDate).toDateString()}</span>
          </span>
        </p>
        {/* <Row justify="center">
                <Button className="addtocalendar" type="primary">
                    Add to Calendar
                </Button>
            </Row> */}
      </Panel>
    </Collapse>
  );
};
export default AboutAccordian;
