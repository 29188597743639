import React, { Fragment } from "react";
import { Row, Col } from "antd";
import MapCard from "../cards/MapCard/MapCard";
const Location = ({ data, fullwidthEvent }) => {
  return (
    <Fragment>
      <Row className="sideItem" style={{ width: fullwidthEvent ? "100%" : "85%" }}>
        <Col
          style={{
            backgroundColor: "#FFFFFF",
            borderRadius: "8px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.04)"
          }}
          lg={24}
          xs={24}
          sm={24}
          md={24}>
          <h1 className="timelineAboutpadding">Location</h1>
          <p
            className="timelinedesc overflowThumb"
            style={{
              maxHeight: 250,
              overflowY: "auto",
              padding: "0px 0.5rem"
            }}>
            {" "}
            {data?.location || "No location provided"}
          </p>
          <MapCard data={data} />
        </Col>
      </Row>
    </Fragment>
  );
};

export default Location;
