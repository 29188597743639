import React, { Fragment } from "react";
import { Row } from "antd";
import FeedCard from "../../../../common/cards/FeedCard";
import UploadPostcard from "../../../../common/cards/UploadPostcard";
import Loader from "../../../../common/cards/Loader";
import NoData from "../../../../common/cards/noData";
import { useFetchAllPosts } from "../../../../common/hooks/useFetchAllPosts";
import { useCreatePost } from "../../../../common/hooks/useCreatePost";

const DiscussionTeam = () => {
  const { fetchloading, POSTS, setPOSTS } = useFetchAllPosts();
  const {
    uploadloading,
    IMAGE,
    VIDEO,
    setimage,
    setvideo,
    text,
    setText,
    uploadingMedia,
    setuploadingMedia,
    CreatePost,
    setLuploading,
    Luploading
  } = useCreatePost(setPOSTS, "Team");

  return (
    <Fragment>
      <Row className="mobileto">
        <Fragment>
          <UploadPostcard
            uploadingMedia={uploadingMedia}
            setuploadingMedia={setuploadingMedia}
            setText={setText}
            text={text}
            IMAGE={IMAGE}
            setimage={setimage}
            VIDEO={VIDEO}
            setvideo={setvideo}
            onPost={CreatePost}
            loading={uploadloading}
            setLuploading={setLuploading}
            Luploading={Luploading}
          />
        </Fragment>
        {/* <EventCard /> */}
        {fetchloading ? (
          <Loader />
        ) : POSTS?.length > 0 ? (
          POSTS?.map((data, index) => {
            return <FeedCard key={index} data={data} setPOSTs={setPOSTS} />;
          })
        ) : (
          <NoData />
        )}
      </Row>
    </Fragment>
  );
};

export default DiscussionTeam;
