import { Button, Space } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ROLES } from "../../../Config/helpers/constants";

/**
 *
 * @param type past, current, drafted
 * @param user player,organizer
 */
function EventChangeTabs({ type, user }) {
  // user: player,organizer
  // type: past, current, drafted,marked
  const navigate = useNavigate();
  return (
    <>
      <Space className={`"SearchBarRow mt-20"}`} style={{ width: "100%", gap: "0 zpx" }}>
        <Button
          className="buttonpo"
          onClick={() => {
            navigate(`/${user}/events`);
          }}
          type={type === "current" && "primary"}>
          Current Events
        </Button>

        <Button
          className="buttonpo"
          onClick={() => {
            navigate(`/${user}/events/pastevents`);
          }}
          type={type === "past" && "primary"}>
          Past Events
        </Button>
        {user === ROLES.player ? (
          <Button
            className="buttonpo"
            onClick={() => {
              navigate("/player/events/markedevents");
            }}
            type={type === "marked" && "primary"}>
            Marked Events
          </Button>
        ) : (
          <Button
            className="buttonpo"
            onClick={() => {
              navigate("/organizer/events/draftevents");
            }}
            type={type === "drafted" && "primary"}>
            Drafted Events
          </Button>
        )}
      </Space>
    </>
  );
}

export default EventChangeTabs;
