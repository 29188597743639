import React from "react";
import { Row, Col } from "antd";
import VideoCard from "../cards/VideoCard";
import Loader from "../../cards/Loader";
import { KEYS } from "../../../Config/Keys";
import NoData from "../../cards/noData";

function MediaVideoTab({ loading, MEDIA }) {
  // Video Card Takes a prop "src"
  // Either provide it or render default

  const isvideo = MEDIA?.find((e) => e?.file?.type === "video");
  return (
    <Row>
      {!loading ? (
        isvideo ? (
          MEDIA?.map(
            (e, i) =>
              e?.file?.type === "video" && (
                <Col
                  key={i}
                  className="mediaCol"
                  sm={11}
                  xs={24}
                  md={11}
                  lg={11}
                  xl={6}
                  style={{ minHeight: "220px", maxHeight: "220px" }}>
                  <VideoCard s200 src={KEYS?.API + "/" + e?.file?.file} />
                </Col>
              )
          )
        ) : (
          <Col style={{ paddingBlock: "10px" }} span={24}>
            <NoData noMedia />
          </Col>
        )
      ) : (
        <Loader />
      )}
    </Row>
  );
}

export default MediaVideoTab;
