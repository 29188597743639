import React from "react";
import { Layout } from "antd";
import OrganizerTNav from "./OrganizerTNav";

import { Outlet } from "react-router-dom";
import { COLORS } from "../../../Config/Colors";
const { Content } = Layout;
const OrganizerTLayout = () => {
  return (
    <Layout>
      <div style={{ backgroundColor: COLORS.GREY }}>
        <OrganizerTNav />
      </div>
      <Content>
        <Outlet />
      </Content>
      {/* <Footer style={{ backgroundColor: "#2F89FC" }}>
        <HomeFooter />
      </Footer> */}
    </Layout>
  );
};
export default OrganizerTLayout;
