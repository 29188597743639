import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TopBar from "../Home/TopBar";
import { GetEventAssignAwards, GetSingleEvent } from "../../../../Config/API/events";
import Container from "../../../../common/Container/Container";
import Loader from "../../../../common/cards/Loader";
import { Avatar, Badge, Col, List, Row, Space, Typography } from "antd";
import { KEYS } from "../../../../Config/Keys";
import { MdGroups } from "react-icons/md";
import { IoIosPerson } from "react-icons/io";

function EventAwards() {
  const [eventLoading, setEventLoading] = useState(false);
  const [awardsLoading, setAwardsLoading] = useState(false);
  const [eventData, seteventData] = useState(null);
  const [awards, setAwards] = useState({});
  const { id } = useParams();

  function separateByAwardId(dataArray) {
    // Create an object to hold arrays grouped by awardId
    const groupedByAwardId = {};

    // Iterate over each item in the input array
    dataArray?.forEach((item) => {
      const awardId = item.award?._id;
      // Check if the awardId key already exists in the object
      if (!groupedByAwardId[awardId]) {
        // If not, create a new array for this awardId
        groupedByAwardId[awardId] = [];
      }
      // Add the current item to the array for this awardId
      groupedByAwardId[awardId].push(item);
    });

    // Extract the arrays from the object into a single array
    const result = Object.values(groupedByAwardId);

    return result;
  }

  const getEventData = async () => {
    try {
      setEventLoading(true);

      const res = await GetSingleEvent({ eventId: id });
      const data = await res.json();

      if (data?.type === "success") {
        seteventData(data?.result);
      }
      setEventLoading(false);
    } catch (e) {
      setEventLoading(false);
    }
  };

  const getEventAwards = async () => {
    try {
      setAwardsLoading(true);

      const res = await GetEventAssignAwards(id);
      const data = await res.json();

      if (data?.length > 0) {
        const updated = separateByAwardId(data);

        setAwards(updated);
      }
      setAwardsLoading(false);
    } catch (e) {
      setAwardsLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getEventData();
    }
  }, [id]);

  useEffect(() => {
    if (eventData) {
      getEventAwards();
    }
  }, [eventData]);

  if (eventLoading) {
    return <Loader text="Loading event awards" fullpage={true} />;
  }

  const awardTypesIcons = ["cash", "certificate", "shield"];

  return (
    <div className="">
      <TopBar data={eventData} eventId={id} viewOnly={true} />
      <Container className="rowPadding">
        {eventLoading || awardsLoading
          ? "loading awards"
          : awards?.length > 0
          ? awards?.map((award, mainIndex) => {
              return (
                <div key={mainIndex} className="mb-5 mt-4">
                  <Typography.Title>{award?.[0]?.award?.title}</Typography.Title>
                  <List
                    className="event-awards"
                    key={mainIndex}
                    bordered
                    dataSource={award}
                    renderItem={(item) => (
                      <List.Item
                        className="p-3"
                        actions={[
                          <p key={item?._id} style={{ display: "flex", alignItems: "center" }}>
                            <span className="m-3">
                              {item?.awardType?.toLowerCase() === "cash" && item?.awardValue}
                            </span>
                            <img
                              style={{ width: "60px", height: "60px" }}
                              src={`/images/icons/${
                                awardTypesIcons.includes(item?.awardType)
                                  ? `${item?.awardType}`
                                  : "certificate"
                              }.svg`}
                            />
                          </p>
                        ]}>
                        <Row align="middle" gutter={20}>
                          <Col>
                            <Badge
                              offset={[-5, 30]}
                              count={
                                item?.team?.name ? (
                                  <MdGroups
                                    style={{
                                      fontSize: "20px",
                                      background: "#1890FF",
                                      color: "white",
                                      borderRadius: "10px"
                                    }}
                                  />
                                ) : (
                                  <IoIosPerson
                                    style={{
                                      fontSize: "20px",
                                      background: "#1890FF",
                                      color: "white",
                                      borderRadius: "10px"
                                    }}
                                  />
                                )
                              }>
                              <Avatar
                                size="large"
                                src={`${KEYS.API}/${
                                  item?.team?.profilepic ?? item?.player?.profilepic
                                }`}
                              />
                            </Badge>
                          </Col>
                          <Col>
                            <Space direction="vertical">
                              <a
                                style={{ color: "#1890FF", fontSize: "18px" }}
                                href={
                                  item?.team?._id
                                    ? `/view/team/${item?.team?._id}/timeline`
                                    : `/view/player/${item?.player?._id}/timeline`
                                }
                                target="_blank"
                                rel="noreferrer">
                                {item?.team?.name ?? item?.player?.name}
                              </a>
                              <Typography.Text>
                                Award: <span style={{ color: "#FF1818" }}>{item?.awardType}</span>
                              </Typography.Text>
                              <Typography.Text style={{ color: "#1890FF" }}>
                                Rank: {item?.rank}
                              </Typography.Text>
                            </Space>
                          </Col>
                        </Row>
                      </List.Item>
                    )}
                  />
                </div>
              );
            })
          : "No awards assigned yet"}
      </Container>
    </div>
  );
}

export default EventAwards;
