import { KEYS } from "../Keys";

export const PlayerProfileToggleApi = async (values) => {
  const response = await fetch(KEYS.API + "/player/changeprofiletype", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};
export const TeamProfileToggleApi = async (values) => {
  const response = await fetch(KEYS.API + "/team/changeprofiletype", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};
export const OrganizerProfileToggleApi = async (values) => {
  const response = await fetch(KEYS.API + "/organizer/changeprofiletype", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};
export const PanelProfileToggleApi = async (values) => {
  const response = await fetch(KEYS.API + "/panel/changeprofiletype", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};
