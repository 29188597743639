import React from "react";
import { Rate, Row, Col } from "antd";
import ViewRatings from "../Model/ViewRatings";

const RatingPanel = ({ Rating, type, id }) => {
  return (
    <Row className="sideItem">
      <Col span={24}>
        <h1 className="timelineAboutpadding">Rating</h1>
        <div className="timelinedesc">
          <Rate disabled allowHalf value={Rating || 0} defaultValue={Rating || 0} />
          {type && id ? <ViewRatings type={type} id={id} /> : null}
        </div>
      </Col>
    </Row>
  );
};

export default RatingPanel;
