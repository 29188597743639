import React from "react";
import { Row, Col, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { awardTypesIcons } from "../../Config/helpers/constants";

// event awards component
const eventAwards = (data) => {
  return (
    <div className="timelineiconpadding">
      {data?.awardsDetail?.length > 0 ? (
        data?.awardsDetail?.map((award) => {
          return (
            <p key={award?._id} className="timelineiconpadding flexcenter">
              <img
                alt=""
                className="timelineiconplacecenter"
                src={`/images/icons/${
                  awardTypesIcons.includes(award?.awardType) ? `${award?.awardType}` : "certificate"
                }.svg`}
                height="25px"
                width="25px"
              />

              <div>
                <p className="p-0 m-0"> {award?.title}</p>
                <p className="p-0 m-0"> Rank: {award?.rank}</p>
                {award?.awardValue && <p className="p-0 m-0">Cash: {award?.awardValue} </p>}
              </div>
            </p>
          );
        })
      ) : (
        <p>No Awards found</p>
      )}
    </div>
  );
};
const Awards = ({ type, data }) => {
  const navigate = useNavigate();

  return (
    <Row className="sideItem">
      <Col lg={24} xs={24} sm={24} md={24}>
        <h1 className="timelineAboutpadding" style={{ padding: "3% 3% 5% 3%" }}>
          Awards & Achievements
        </h1>

        {type === "event" ? (
          eventAwards(data)
        ) : (
          <>
            <p className="timelineiconpadding flexcenter">
              <img
                alt=""
                className="timelineiconplacecenter"
                src="/images/icons/moneycolor.png"
                height="25px"
                width="25px"
              />
              <span className="sideItemsText">10,000 Rs ( Winning Team )</span>
            </p>
            <p className="timelineiconpadding flexcenter">
              <img
                alt="img"
                className="timelineiconplacecenter"
                src="/images/icons/badge.png"
                height="32px"
                width="23px"
              />

              <span className="sideItemsText ">40 Badges</span>
            </p>
            <p className="timelineiconpadding flexcenter">
              <img
                alt=""
                className="timelineiconplacecenter"
                src="/images/icons/badge-1.png"
                height="25px"
                width="23px"
              />
              <span className="sideItemsText "> 1 Trophy ( Winning Team ) </span>
            </p>
          </>
        )}
        {/* Only show this button when event is not yet completed  */}
        {type === "event" && data?.awardsDetail?.length > 0 && !data?.complete && (
          <Button
            onClick={() => navigate(`/view/event/${data?._id}/event-awards`)}
            style={{
              width: "35%",
              margin: "auto"
            }}
            className="registerbutton btnMobileDimensions"
            type="primary">
            Show More
          </Button>
        )}
      </Col>
    </Row>
  );
};

export default Awards;
