import { Row, Col, Button } from "antd";
import React, { useState, Fragment, useEffect } from "react";
import ActiveMembers from "./ActiveMembers";
import InviteMembers from "./InviteMembers";

import { Link, useParams, useNavigate } from "react-router-dom";
import { error } from "../../../../common/Alerts/Message";
import { GetSingleTeam } from "../../../../Config/API/teams";
import Loader from "../../../../common/cards/Loader";
import NoData from "../../../../common/cards/noData";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { useSelector } from "react-redux";
import { PROFILE } from "../../../../Store/Features/ProfileSlice";
import useVariables from "../../../../common/hooks/useVariables";
import MembersRequest from "./MembersRequest";
import { checkIfPartOfTheTeamOrInvited, checkIfTeamCaptain } from "../../../../common/Utils";

const TeamMembers = () => {
  const [active, setactive] = useState("primary");
  const [invite, setinvite] = useState("");
  const [request, setrequest] = useState("");
  const [team, setteam] = useState({});
  const [loading, setloading] = useState(false);
  const [status, setstatus] = useState(false);
  const { viewer } = useVariables();
  const navigate = useNavigate();
  const { id } = useParams();
  const profile = useSelector(PROFILE);
  const [memberRequestLoading, setMemberRequestLoading] = useState(false);
  // check if current viewer part of the team
  const [isViewerMember, setIsViewerMember] = useState(false);

  const getteam = async () => {
    try {
      setloading(true);
      const response = await GetSingleTeam(id);
      const data = await response.json();

      setstatus(checkIfTeamCaptain(data?.result?.members, viewer?.id));
      setIsViewerMember(checkIfPartOfTheTeamOrInvited(data?.result?.members, viewer?.id));
      setteam(data.result);
      setloading(false);
    } catch (e) {
      setloading(false);
      console.log(e);
      error(e.message);
    }
  };

  useEffect(() => {
    if (!id) {
      navigate("/player/teams");
    }
    getteam();
    return () => {
      setactive(null);
      setinvite(null);
      setrequest(null);
      setteam(null);
      setloading(false);
      setstatus(false);
    };
  }, [id, navigate]);

  return (
    <Fragment>
      <Row className="rowPadding" justify="center">
        <Col xs={24} sm={24} md={24} lg={14} xl={16}>
          {/* <FollowerFollowing /> */}
          <Row className="mdWidth">
            {/* <Col lg={24} md={24} sm={24} xs={24}>
                            <h1
                                style={{ paddingLeft: "0" }}
                                className="popadding">
                                Team Members
                            </h1>
                        </Col> */}
            <Col
              span={24}
              className="mobilecenterbutton"
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
                gap: "0 70px",
                marginBottom: "10px"
              }}>
              <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
                <Button
                  style={{ marginLeft: "0" }}
                  className={`buttonpo ${active === "primary" ? "activeBlue" : ""}`}
                  onClick={() => {
                    setactive("primary");
                    setinvite("");
                    setrequest("");
                  }}
                  type={active}>
                  Active Members
                </Button>
                {isViewerMember && (
                  <Button
                    style={{ marginLeft: "0" }}
                    className={`buttonpo ${invite === "primary" ? "activeBlue" : ""}`}
                    type={invite}
                    onClick={() => {
                      setactive("");
                      setinvite("primary");
                      setrequest("");
                    }}>
                    Invited Members
                  </Button>
                )}
                {isViewerMember && (
                  <Button
                    style={{ marginLeft: "0" }}
                    className={`buttonpo ${request === "primary" ? "activeBlue" : ""}`}
                    type={request}
                    onClick={() => {
                      setactive("");
                      setinvite("");
                      setrequest("primary");
                    }}>
                    Members Requests
                  </Button>
                )}
              </div>
              {status && (
                <Button type="primary" style={{ marginLeft: "0" }} className="buttonpo">
                  <Link
                    to={`/player/addteammembers`}
                    state={{ id: team?._id, members: profile?.members }}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: ".4rem",
                      justifyContent: "center"
                    }}>
                    Add Member
                    <AiOutlinePlusCircle fontSize={13} />
                  </Link>
                </Button>
              )}
            </Col>

            {loading ? (
              <Loader />
            ) : (
              <Col span={24}>
                {team?.members?.length > 0 ? (
                  <Fragment>
                    {invite === "primary" ? <InviteMembers team={team} /> : null}
                    {active === "primary" ? (
                      <ActiveMembers
                        team={team}
                        // object={object}
                        setteam={setteam}
                        status={status}
                        setstatus={setstatus}
                      />
                    ) : null}
                    {request === "primary" ? (
                      <MembersRequest
                        team={team}
                        loading={memberRequestLoading}
                        setLoading={setMemberRequestLoading}
                        setteam={setteam}
                        setstatus={setstatus}
                      />
                    ) : null}
                  </Fragment>
                ) : (
                  <NoData noMembers />
                )}
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </Fragment>
  );
};

export default TeamMembers;
