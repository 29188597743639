import React, { Fragment } from "react";
import Loader from "../../../../../../../common/cards/Loader";
import { useSelector } from "react-redux";
import { MATCHES, isLoading } from "../../../../../../../Store/Features/MatchesSlice";
import { Col, Row } from "antd";

import StaticFixtures from "./StaticFixtures";

const FixtureWrapper = () => {
  const Matches = useSelector(MATCHES);
  const loading = useSelector(isLoading);

  if (Object.keys(Matches)?.length === 0) return null;

  return (
    <Fragment>
      <Fragment>
        {loading ? (
          <Loader small noShadow />
        ) : (
          <div className="">
            <Row justify="center">
              <Col xl={16} lg={24} xs={24} sm={24} md={24} className="ceinputformpadding">
                <h2
                  style={{
                    borderBottom: "1px solid  #282828",
                    paddingBottom: "5px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingTop: "30px"
                  }}>
                  Fixtures
                </h2>
              </Col>
            </Row>
          </div>
        )}
      </Fragment>

      <Row justify="center">
        {loading ? (
          <Col xl={16} lg={24} xs={24} sm={24} md={24} className="ceinputformpadding">
            <Loader smaller loading={"white"} />
          </Col>
        ) : (
          <Col
            xl={16}
            lg={24}
            xs={24}
            sm={24}
            md={24}
            className="  SmallScroll  ceinputformpadding"
            style={{
              paddingTop: "20px",
              marginBottom: "20px",
              maxHeight: "500px",
              overflowY: "scroll"
            }}>
            <StaticFixtures items={Matches?.matchesSlot} />
          </Col>
        )}
      </Row>
    </Fragment>
  );
};

export default FixtureWrapper;
