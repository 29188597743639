import React from "react";
import { Row, Col } from "antd";
import ScrollToTop from "../../../../common/cards/ScrollToTop";
import RightBar from "../../../../common/righbar";
import { SideBar } from "../../../../common/sidebar";
import DiscussionPanel from "./Discussion";
function Timeline() {
  return (
    <Row className="rowPadding">
      <ScrollToTop />
      <SideBar description ratingPanel location />
      <Col xs={24} sm={24} md={24} lg={11}>
        <DiscussionPanel />
      </Col>
      <RightBar />
    </Row>
  );
}

export default Timeline;
