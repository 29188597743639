import { Col, Row } from "antd";
import React, { Fragment } from "react";
import RequestCard from "../Card/RequestCard";
import NoData from "../../../../common/cards/noData";

const MembersRequest = ({ team, loading, setLoading, setteam, setstatus }) => {
  const arr = team?.members?.filter((e) => !e?.active && e?.type?.toLowerCase() === "join");
  return (
    <Fragment>
      <Row>
        {arr?.length > 0 ? (
          arr.map((e, i) => (
            <Col
              className="pomemberShadow TPcard"
              key={i}
              xxl={6}
              xl={8}
              lg={{ span: 12 }}
              md={{ span: 12 }}
              sm={24}
              xs={24}>
              <RequestCard
                player={e}
                teamId={team?._id}
                loading={loading}
                setLoading={setLoading}
                setstatus={setstatus}
                setteam={setteam}
              />
            </Col>
          ))
        ) : (
          <NoData noMembers />
        )}
      </Row>
    </Fragment>
  );
};

export default MembersRequest;
