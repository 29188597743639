import { Form, Input, Typography } from "antd";
const { Text } = Typography;
import React from "react";
import useCheckUsernameAvailability from "../hooks/useCheckUsernameAvailability";

function UserNameField({ setFieldValue, value, error, type, prevUserName = "" }) {
  const { isAvailable, isLoading } = useCheckUsernameAvailability(value, type, prevUserName);
  const handleChange = (e) => {
    const value = e?.target?.value;
    if (value?.startsWith(`${prefix?.[type]}_`)) {
      setFieldValue("username", value);
    } else {
      if (value === prefix?.[type]) {
        setFieldValue("username", `${prefix?.[type]}_`);
      } else {
        setFieldValue("username", `${prefix?.[type]}_${value}`);
      }
    }
  };

  const prefix = { player: "pl", team: "tm", panel: "pn", organizer: "org" };
  return (
    <Form.Item
      label="User Name*"
      required={true}
      help={error || error ? error || error : undefined}
      validateStatus={error || error ? "error" : "success"}>
      <Input
        id="username"
        name="username"
        type="text"
        placeholder="Enter user name"
        onChange={handleChange}
        value={value}
        style={{
          borderRadius: "5px",
          lineHeight: "38px",
          marginTop: "5px"
        }}
      />
      {isLoading && !error && <Text type="secondary">Checking availability...</Text>}
      {isAvailable && !isLoading && value?.length > 0 && !error && (
        <Text type="success">Username available!</Text>
      )}
      {!isAvailable && !isLoading && value !== prevUserName && !error && (
        <Text type="danger">Username unavailable.</Text>
      )}
    </Form.Item>
  );
}

export default UserNameField;
