import { createSlice } from "@reduxjs/toolkit";

const initialState = [];
//this slice is storing id's of currently open comments, commentform. replies, replyform as well as id's of currently deleting comment and replies
export const PostSlice = createSlice({
  name: "post",
  initialState,
  reducers: {
    setpost: (state, action) => {
      if (state?.indexOf(action.payload) !== -1) {
        state = state?.splice(action.payload, 1);
      } else {
        let newstate = state;
        newstate = newstate.push(action.payload);
        state = newstate;
      }
    },
    setempty: (state) => {
      state = [];
      // this will probably never run
      if (state === "") {
        console.log(state);
      }
    }
  }
});

// Action creators are generated for each case reducer function
export const { setpost, setempty } = PostSlice.actions;
export const poststate = (state) => state.post;

export default PostSlice.reducer;
