import React from "react";
import { Image } from "antd";
import { fallbackcover } from "../Utils";
import { KEYS } from "../../Config/Keys";

const OneImage = ({ src, isEvent }) => {
  return (
    <Image
      width={"100%"}
      className="timelineimage"
      wrapperStyle={{
        borderRadius: isEvent ? "8px" : undefined,
        marginBottom: isEvent ? "-8px" : undefined
      }}
      style={{
        borderTopRightRadius: "8px",
        borderTopLeftRadius: "8px",
        borderRadius: isEvent ? "8px" : undefined
      }}
      src={KEYS.API + "/" + src}
      fallback={fallbackcover}
    />
  );
};

export default OneImage;
