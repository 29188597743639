import React, { useEffect, useState } from "react";
import { Button, Col, Form, InputNumber, Modal, Row } from "antd";
import Loader from "../../../../../../../common/cards/Loader";
import { useDispatch, useSelector } from "react-redux";
import { MultiplayerUpdateGroupPoints } from "../../../../../../../Config/API/multiplayerSchedule";
import {
  setMatchObj,
  seteventcomplete,
  setroundvariables
} from "../../../../../../../Store/Features/MatchesSlice";
import { error, Info, success } from "../../../../../../../common/Alerts/Message";
import getEventIndexesAndRankPoints from "../../../../../../../common/hooks/getEventIndexesAndRankPoints";
import { VIEW_TEAM } from "../../../../../../../utils/page-urls";

function ScheduleGroupPointsModal({ currentround, group, setgroup, eventId, getData }) {
  const [requesting, setrequesting] = useState(false);
  // const [waiting, setwating] = useState(false);
  const [gdata, setgdata] = useState();
  const roundvariables = useSelector((state) => state.matches?.roundvariables);
  const currentroundvariables = roundvariables[currentround?.currentourndindex];
  const [replicatingRankAt, setReplicatingRankAt] = useState([]);
  const dispatch = useDispatch();

  // individual ranks points based on istrankpoints,lastrankpoints
  const istrankpoints = currentroundvariables?.istrankpoints;
  const lastrankpoints = currentroundvariables?.lastrankpoints;
  const { individualRankPoints } = getEventIndexesAndRankPoints(istrankpoints, lastrankpoints);

  useEffect(() => {
    if (group?.open && Array.isArray(group?.data?.table)) {
      let newgroup = [];
      if (group?.data?.table) {
        newgroup = [...group.data.table];
      }
      newgroup?.sort((a, b) => a.rank - b.rank);
      newgroup = newgroup.map((e) => {
        return {
          ...e,
          points:
            individualRankPoints?.find((rank) => rank?.rank === e.rank)?.points +
            e?.kills * currentroundvariables?.killpoints
        };
      });
      setgdata((e) => {
        return { ...e, data: { ...e?.data, table: newgroup } };
      });
    }
    return () => {
      setReplicatingRankAt([]);
    };
  }, [group]);

  function findDuplicateRanks(arr) {
    let rankMap = new Map();
    let duplicates = [];

    // Loop through the array and track the indexes of each rank
    for (let i = 0; i < arr.length; i++) {
      let rank = arr[i].rank;

      if (rank !== undefined) {
        if (rankMap.has(rank)) {
          // If the rank is already in the map, push the current index to the duplicates array
          duplicates.push(i);
          // Also add the first occurrence index if it's not already in the duplicates array
          if (!duplicates.includes(rankMap.get(rank))) {
            duplicates.push(rankMap.get(rank));
          }
        } else {
          // Otherwise, store the index of the rank
          rankMap.set(rank, i);
        }
      }
    }

    setReplicatingRankAt(duplicates);

    return duplicates?.length > 0 ? true : false;
  }

  const updategroup = async () => {
    setReplicatingRankAt([]);
    try {
      setrequesting(true);
      let found = false;

      // const checkrank = () => {
      //   const data = gdata.data.table;
      //   data.forEach((obj) => {
      //     if (found) return;

      //     data.forEach((obj2, index) => {
      //       if (obj2.team._id != obj.team._id) {
      //         if (obj.rank == obj2.rank) {
      //           found = true;
      //           setReplicatingRankAt((prev) => [...prev, index]);
      //           return;
      //         }
      //       }
      //     });
      //   });
      // };
      // checkrank();
      found = findDuplicateRanks(gdata.data.table);
      if (found) {
        setrequesting(false);
        return Info("2 teams can't have same rank. kindly add unique rank");
      }
      const sorteddata = gdata.data.table;
      sorteddata.sort((a, b) => {
        if (a.rank === b.rank) {
          return b.kills - a.kills;
        } else return a.rank - b.rank;
      });
      const groupno = currentround?.gindex;
      const matchno = currentround?.submatchindex;
      const object = {
        match: { table: sorteddata },
        groupno,
        matchno,
        eventId,
        roundindex: currentround?.currentourndindex
      };

      const res = await MultiplayerUpdateGroupPoints(object);
      const data = await res.json();
      if (data.type === "success") {
        dispatch(setMatchObj({ matches: data?.newdata }));
        dispatch(setroundvariables(data?.newdata?.roundvariables));
        dispatch(seteventcomplete(data?.newdata?.complete));
        setgroup((e) => {
          return { ...e, open: false };
        });
        success("Group updated sucessfully!");
        // get updated data again
        getData();
      } else {
        error("couldn't update the group");
      }
      setrequesting(false);
    } catch (e) {
      setrequesting(false);
      error("couldn't update the group");
    }
  };

  if (!group?.open) return null;
  const btnstyles = {
    fontWeight: "500",
    fontSize: "larger",
    width: "100%",
    paddingInline: "0",
    minWidth: "unset"
  };
  const Title = () => {
    return <div>{`Update Match Points`}</div>;
  };

  const handlechange = (obj) => {
    const team = obj?.team;
    const kills = parseInt(obj?.kills);
    const rank = parseInt(obj?.rank);
    const updatedGroup = gdata?.data?.table?.map((e) => {
      if (obj?.rank) return e.team._id === team._id ? { ...e, rank, points: 0 } : e;
      else {
        return e.team._id === team._id ? { ...e, kills, points: 0 } : e;
      }
    });

    setgdata((prevState) => ({ ...prevState, data: { ...prevState?.data, table: updatedGroup } }));
    //  Delay the sorting until the user has finished typing
    //  clearTimeout(typingTimeout);
    //  typingTimeout = setTimeout(() => {
    handleSorting(updatedGroup);
    //  }, 500);
    //  Adjust the delay as needed (e.g., 2000 milliseconds)
  };
  const handleSorting = (updatedGroup) => {
    const newgroupdata = updatedGroup?.map((e) => {
      const kills = e?.kills;
      const rank = e?.rank;
      let points = "";
      const currentRankPoint = individualRankPoints?.find((item) => item?.rank === rank);
      if (!currentRankPoint) {
        Info("Rank can not be more than the no of teams in group");
      } else {
        points = currentroundvariables?.killpoints * kills + currentRankPoint?.points;
      }

      return { ...e, points, kills };
    });

    setgdata((prevState) => ({ ...prevState, data: { ...prevState?.data, table: newgroupdata } }));
  };

  return (
    <Modal
      title={<Title />}
      className="editPost AddTeamModel"
      style={{
        marginBlock: "2rem"
      }}
      confirmLoading
      mask
      maskClosable={!requesting}
      onCancel={() => {
        setgroup((e) => {
          return { ...e, open: false };
        });
        setrequesting(false);
      }}
      closable={false}
      centered
      destroyOnClose={true}
      visible={group?.open}
      open={group?.open}
      footer={`Round ${currentround?.currentourndindex + 1} group ${
        currentround?.gindex + 1
      } Match ${currentround?.submatchindex + 1}`}
      bodyStyle={{
        padding: "0px 0px 20px 0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}>
      {requesting ? (
        <Loader smaller noShadow center />
      ) : (
        <Row gutter={[5, 5]} style={{ width: "100%" }}>
          <Col span={24}>
            <Row gutter={[5, 5]}>
              <Col span={24} style={{ marginBlock: ".5rem", fontWeight: "500" }}>
                <Row justify="space-between">
                  <Col md={8} xm={9} xs={7} className="truncate1">
                    Team{" "}
                  </Col>
                  <Col md={4} sm={4} xs={3} style={{ textAlign: "center" }}>
                    Rank
                  </Col>
                  <Col md={6} sm={6} xs={5} style={{ textAlign: "center" }}>
                    Points
                  </Col>
                  <Col md={6} sm={5} xs={7} style={{ textAlign: "center" }}>
                    Kills
                  </Col>
                </Row>
              </Col>
              {gdata?.data?.table?.map((e, i) => {
                return (
                  <Col span={24} key={i}>
                    <Row justify="space-between">
                      <Col md={8} sm={9} xs={7} className="truncate1">
                        <a
                          className="no-style-link"
                          target="_blank"
                          rel="noreferrer"
                          href={VIEW_TEAM + `/${e?.team?._id}`}>
                          {e?.team?.name}{" "}
                        </a>
                      </Col>
                      <Col md={4} sm={4} xs={3} style={{ textAlign: "center" }}>
                        <Form.Item
                          help={replicatingRankAt?.includes(i) ? "Rank repeated" : ""}
                          validateStatus={replicatingRankAt?.includes(i) ? "error" : "success"}>
                          <InputNumber
                            type={"number"}
                            value={e?.rank}
                            defaultValue={e?.rank}
                            onChange={(value) => {
                              value = Math.abs(parseInt(value));
                              if (value > individualRankPoints?.length) {
                                Info("Rank can not be greater than no of teams in a group");
                                return;
                              }
                              if (isNaN(value)) value = 1;
                              else if (value < 0) {
                                value = 1;
                              } else if (value == 0) {
                                value = 1;
                              }
                              handlechange({
                                team: e?.team,
                                rank: value,
                                points: e?.points
                              });
                            }}
                            className="killsinput"
                          />
                        </Form.Item>
                      </Col>
                      <Col md={6} sm={6} xs={5} style={{ textAlign: "center" }}>
                        {e.points}
                      </Col>
                      <Col
                        md={6}
                        sm={5}
                        xs={7}
                        style={{ display: "flex", gap: ".5rem", justifyContent: "space-between" }}>
                        <span style={{ fontSize: "10px", fontWeight: "700" }}>
                          {`${
                            individualRankPoints?.find((rank) => rank?.rank === e.rank)?.points
                          } + (${e?.kills}*${currentroundvariables?.killpoints} )`}
                        </span>

                        <InputNumber
                          type={"number"}
                          value={e?.kills}
                          defaultValue={e?.kills}
                          onChange={(value) => {
                            value = Math.abs(parseInt(value));
                            if (isNaN(value)) value = 0;
                            else if (value < 0) {
                              value = 0;
                            }
                            handlechange({ team: e?.team, kills: value });
                          }}
                          className="killsinput"
                        />
                      </Col>
                    </Row>
                  </Col>
                );
              })}
            </Row>
          </Col>

          <Col span={24} className="editbutton">
            <Button
              style={btnstyles}
              loading={requesting ? true : false}
              onClick={() => {
                updategroup();
              }}
              className="btnMobileDimensions"
              type="primary">
              Update Group
            </Button>
          </Col>
        </Row>
      )}
    </Modal>
  );
}

export default ScheduleGroupPointsModal;
