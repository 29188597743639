import { KEYS } from "../Keys";

export const CreatePanel = async (values) => {
  const response = await fetch(KEYS.API + "/panel/createpanel", {
    method: "POST",
    headers: {
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: values
  });
  return response;
};

export const GetPanelsOfOwner = async (values) => {
  const response = await fetch(KEYS.API + "/panel/getpanelsofowner", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify({ id: values })
  });
  return response;
};
export const EditPanelAPI = async (values) => {
  const response = await fetch(KEYS.API + "/panel/updatepanel", {
    method: "POST",
    headers: {
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: values
  });
  return response;
};

export const GetSinglePanel = async (values) => {
  const response = await fetch(KEYS.API + "/panel/getsinglepanel", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: typeof values === "string" ? JSON.stringify({ id: values }) : JSON.stringify(values)
  });
  return response;
};

export const InviteToPanel = async (values) => {
  const response = await fetch(KEYS.API + "/panel/requestpanel", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

// sent a request to panel to join them as a member
export const JoinPanelRequest = async (values) => {
  const response = await fetch(KEYS.API + "/panel/joinpanel", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

export const AcceptPanelRequest = async (values) => {
  const response = await fetch(KEYS.API + "/panel/acceptpanelrequest", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

export const CancelOrDeletePanelRequest = async (values) => {
  const response = await fetch(KEYS.API + "/panel/cancelordeletemember", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

// Join Accept
export const AccepJoinPanelRequest = async (values) => {
  const response = await fetch(KEYS.API + "/panel/joinacceptpanelrequest", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};
//join cancel
export const CancelOrDeletePanelJoinRequest = async (values) => {
  const response = await fetch(KEYS.API + "/panel/joincancelordeletemember", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

export const GetPanelPosts = async (values, controller, page = 1, limit = 10) => {
  const response = await fetch(KEYS.API + `/panel/getpanelposts?page=${page}&limit=${limit}`, {
    // signal: controller.signal,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify({ panelId: values })
  });
  return response;
};

export const DeletePanelPost = async (values) => {
  const response = await fetch(KEYS.API + "/panel/deletepanelpost", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

export const EditPanelPost = async (values) => {
  const response = await fetch(KEYS.API + "/post/updatepost", {
    method: "POST",
    headers: {
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: values
  });
  return response;
};

export const AssignRole = async (values) => {
  const response = await fetch(KEYS.API + "/panel/assignrole", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

export const PanelAcceptFollowrequest = async (values) => {
  const response = await fetch(KEYS.API + "/panel/acceptfollowrequest", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

export const PanelRejectFollowrequest = async (values) => {
  const response = await fetch(KEYS.API + "/panel/cancelfollowrequest", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};
export const PanelDeleteFollowRequest = async (values) => {
  const response = await fetch(KEYS.API + "/panel/deletefollowrequest", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

export const PanelDeleteFollower = async (values) => {
  const response = await fetch(KEYS.API + "/panel/deletefollowerpanel", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

export const GetPanelEventHistory = async (id, setData) => {
  try {
    const payload = {
      panelId: id
    };
    const response = await fetch(KEYS.API + "/panel/getpaneleventshistory", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("userToken")}`
      },
      body: JSON.stringify(payload)
    });
    const result = await response.json();
    if (result?.type === "success") {
      setData(result?.result || []);
    }
  } catch (error) {
    return await error;
  }
};

export const GetAllPanels = async () => {
  const response = await fetch(KEYS.API + "/panel/getallpanels", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    }
  });
  return response;
};

export const GetPanelRatingsList = async (panelId) => {
  const response = await fetch(KEYS.API + `/panel/getpanelrating`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify({ panelId })
  });
  return response;
};
