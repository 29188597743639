// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.truncate {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 3;
  -webkit-line-clamp: 3;
}

.truncate {
  --lines: 3;
  line-height: inherit;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 3;
  -webkit-line-clamp: 3;
}

.truncate1 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  /* Vendor prefixes for older browser support */
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  -moz-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
}
`, "",{"version":3,"sources":["webpack://./src/styles/utils/truncate/truncate.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,sBAAsB;EACtB,gBAAgB;EAChB,uBAAuB;EACvB,aAAa;EACb,qBAAqB;AACvB;;AAEA;EACE,UAAU;EACV,oBAAoB;EACpB,aAAa;EACb,eAAe;EACf,sBAAsB;EACtB,gBAAgB;EAChB,uBAAuB;EACvB,aAAa;EACb,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;;EAEvB,8CAA8C;EAC9C,0BAA0B;EAC1B,2BAA2B;EAC3B,4BAA4B;EAC5B,+BAA+B;AACjC","sourcesContent":[".truncate {\n  display: flex;\n  flex-wrap: wrap;\n  flex-direction: column;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  line-clamp: 3;\n  -webkit-line-clamp: 3;\n}\n\n.truncate {\n  --lines: 3;\n  line-height: inherit;\n  display: flex;\n  flex-wrap: wrap;\n  flex-direction: column;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  line-clamp: 3;\n  -webkit-line-clamp: 3;\n}\n\n.truncate1 {\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n\n  /* Vendor prefixes for older browser support */\n  -o-text-overflow: ellipsis;\n  -ms-text-overflow: ellipsis;\n  -moz-text-overflow: ellipsis;\n  -webkit-text-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
