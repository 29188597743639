import React, { Fragment, useState } from "react";
import { Col, Row, Button, Tabs, Grid } from "antd";
import "../../../styles/organizer/organizerheader.css";
import { Link, useNavigate, useParams } from "react-router-dom";

import { fallbackprofile } from "../../../common/Utils";
import Loader from "../../../common/cards/Loader";
import { KEYS } from "../../../Config/Keys";
import FadeInImage from "../../../common/Images/FadeInImage";
import { useFetchProfile } from "../../../common/hooks/useFetchProfile";
import { useCurrentPage } from "../../../common/hooks/useCurrentPage";
import useVariables from "../../../common/hooks/useVariables";
import { ROLES } from "../../../Config/helpers/constants";
import { useSelector } from "react-redux";
import { PROFILE } from "../../../Store/Features/ProfileSlice";

const { useBreakpoint } = Grid;
const { TabPane } = Tabs;

const SponsorTNav = () => {
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [isFollowing, setisFollowing] = useState(false);

  const { id } = useParams();
  const { viewer, PID } = useVariables();
  const { url, other } = useCurrentPage();

  //id =other user, viewer=current user
  const fetchid = id ? id : viewer?.id;

  //api call for fetching profile
  const { profileLoding } = useFetchProfile(fetchid, ROLES.sponsor, setisFollowing);

  // //fetched organizer
  let sponsor = useSelector(PROFILE);
  // const followers = getactivefollowfollowers(organizer?.followers);
  // const followings = getactivefollowfollowers(organizer?.followings);

  // useEffect(() => {
  //   //checking for follow request
  //   checkIsAccepted(setaccpted, followers, viewer);
  // }, [followers?.length, isFollowing]);

  // const FollowFollowingButton = () => {
  //   return (
  //     <Row justify="center">
  //       <p>
  //         {/* {alert(viewer)} */}
  //         <Button
  //           onClick={() =>
  //             navigate(`/${viewer?.flag === "player" ? "player" : "organizer"}/messages/chat`, {
  //               state: {
  //                 id: id,
  //                 role: "organizer",
  //                 profilepic: organizer.profile,
  //                 name: organizer.name
  //               }
  //             })
  //           }
  //           className="profilebuttons blueButton btnMobileDimensions"
  //           style={{
  //             height: "auto",
  //             padding: "6px 10px",
  //             margin: "0",
  //             color: "white",
  //             marginRight: "5px"
  //           }}>
  //           Chat
  //         </Button>
  //       </p>
  //       <p>
  //         {isFollowing ? (
  //           <Button
  //             loading={loading === "unfollow" && true}
  //             onClick={() => {
  //               unFollowProfile(
  //                 organizer,
  //                 null,
  //                 "organizer",
  //                 setloading,
  //                 setisFollowing,
  //                 "otherprofile",
  //                 setProfile,
  //                 dispatch,
  //                 setaccpted
  //               );
  //             }}
  //             className="  profilebuttons blueButton btnMobileDimensions"
  //             style={{
  //               height: "auto",
  //               padding: "6px 10px",
  //               margin: "0",
  //               color: "white"
  //             }}>
  //             {accepted ? "UnFollow" : "Cancel Request"}
  //           </Button>
  //         ) : (
  //           <Button
  //             loading={loading === "follow" && true}
  //             onClick={() =>
  //               followFunction(
  //                 id,
  //                 setloading,
  //                 setisFollowing,
  //                 null,
  //                 setProfile,
  //                 dispatch,
  //                 setaccpted
  //               )
  //             }
  //             className="addtocalendar profilebuttons btnMobileDimensions"
  //             style={{
  //               height: "auto",
  //               padding: "6px 10px",
  //               margin: "0",
  //               color: "white"
  //             }}>
  //             Follow
  //           </Button>
  //         )}
  //       </p>
  //     </Row>
  //   );
  // };
  const EditButton = () => {
    return (
      <p>
        <Button
          onClick={() =>
            navigate("/sponsor/editprofile", {
              state: "sponsor"
            })
          }
          className="addtocalendar profilebuttons btnMobileDimensions"
          style={{
            height: "auto",
            padding: "6px 10px",
            margin: "0",
            color: "white"
          }}>
          Edit Profile
        </Button>
      </p>
    );
  };

  //checking if current user id !== fetched user id, (to toggle follow and edit buttons)
  const condition = id !== viewer?.id;
  return (
    <Fragment>
      {profileLoding ? <Loader fullpage loading="white" /> : null}

      <Row>
        <Col span={24} className="toheader">
          <FadeInImage
            type={"Fadecover"}
            preview
            className="tocover"
            src={KEYS.API + "/" + sponsor?.cover || sponsor?.coverpic}
          />
          {/* <FollowersFollowingDataComp
            url={url}
            other={other}
            organizer={organizer}
            followers={followers}
            followings={followings}
            id={id}
          /> */}
        </Col>
      </Row>

      <Row wrap="wrap" align="top" justify="space-between" className="tosubnav tosubnav1">
        <Col
          style={{
            paddingTop: other && sponsor?._id === PID ? "6px" : undefined
          }}
          className="tosubnavcenter"
          xl={3}
          lg={3}
          md={4}
          sm={6}
          xs={24}>
          <div className="abs">
            <FadeInImage
              preview
              type="Fadeprofile"
              fallaback={fallbackprofile}
              src={KEYS.API + "/" + viewer?.profile}
            />
          </div>
          <h2
            className="toname"
            style={{
              textAlign: "center",
              margin: "0px 0 0 0",
              minWidth: "170px",
              fontSize: 20
            }}>
            {sponsor?.name || "Dummy name"}
          </h2>
        </Col>
        <SubLinksWrapper
          organizer={sponsor}
          other={other}
          url={url}
          PID={PID}
          id={id}
          condition={condition}
          EditButton={EditButton}
          // FollowFollowingButton={FollowFollowingButton}
        />
      </Row>
      <XsButtonsRow
        other={other}
        condition={condition}
        EditButton={EditButton}
        // FollowFollowingButton={FollowFollowingButton}
      />
    </Fragment>
  );
};

const XsButtonsRow = ({ condition, other, EditButton, FollowFollowingButton }) => {
  return condition ? (
    <Row className="tosubnav d-none d-md-block">
      <Col span={24}>
        {other ? (
          <Col style={{ marginLeft: "auto" }} className="tosubnavcenter">
            <FollowFollowingButton />
          </Col>
        ) : (
          <Col style={{ marginLeft: "auto" }} className="tosubnavcenter">
            <EditButton />
          </Col>
        )}
      </Col>
    </Row>
  ) : null;
};

const SubLinksWrapper = ({
  organizer,
  other,
  url,
  PID,
  id,
  condition,
  EditButton,
  FollowFollowingButton
}) => {
  const screens = useBreakpoint();
  return (
    <Fragment>
      {screens["sm"] ? (
        <SubLinksDesktop
          organizer={organizer}
          other={other}
          url={url}
          PID={PID}
          id={id}
          condition={condition}
          EditButton={EditButton}
          FollowFollowingButton={FollowFollowingButton}
        />
      ) : (
        <SubLinksTabs organizer={organizer} other={other} url={url} id={id} />
      )}
    </Fragment>
  );
};
const SubLinksDesktop = ({
  organizer,
  other,
  url,
  PID,
  id,
  condition,
  EditButton,
  FollowFollowingButton
}) => {
  return (
    <Col
      xl={20}
      lg={19}
      md={17}
      sm={15}
      xs={24}
      style={{
        paddingTop: other && organizer?._id === PID ? "7px" : undefined
      }}>
      <Row className="subnavLinks noScroll">
        <Col className="tosubnavcenter">
          <Link
            to={other ? `/view/organizer/${organizer?._id || id}/timeline` : "/organizer/timeline"}>
            <p className={url === "timeline" ? "toactive" : null}> Timeline</p>
          </Link>
        </Col>

        <Col className="tosubnavcenter">
          <Link to={other ? `/view/organizer/${organizer?._id || id}/media` : "/organizer/media"}>
            <p className={url === "media" ? "toactive" : null}> Media </p>
          </Link>
        </Col>
        <Col className="tosubnavcenter">
          <Link to={other ? `/view/organizer/${organizer?._id || id}/about` : "/organizer/about"}>
            <p className={url === "about" ? "toactive" : null}>About</p>
          </Link>
        </Col>
        {condition ? (
          <Fragment>
            {other ? (
              <Col style={{ marginLeft: "auto" }} className="tosubnavcenter d-md-none">
                <FollowFollowingButton />
              </Col>
            ) : (
              <Col style={{ marginLeft: "auto" }} className="tosubnavcenter d-md-none">
                <EditButton />
              </Col>
            )}
          </Fragment>
        ) : null}
      </Row>
    </Col>
  );
};
const SubLinksTabs = ({ organizer, other, url, id }) => {
  const arr = [
    {
      label: "timeline",
      url: other ? `/view/organizer/${organizer?._id || id}/timeline` : `/organizer/timeline`,
      key: "timeline"
    },
    {
      label: "media",
      url: other ? `/view/organizer/${organizer?._id || id}/media` : `/organizer/media`,
      key: "media"
    },
    {
      label: "about",
      url: other ? `/view/organizer/${organizer?._id || id}/about` : `/organizer/about`,
      key: "about"
    }
  ];

  const navigate = useNavigate();

  return (
    <Tabs
      tabPosition={"top"}
      centered
      activeKey={url}
      size="small"
      onChange={(e) => {
        navigate(e);
      }}
      className="MobileSubNavTabLinks">
      {arr.map(({ label, key }) => (
        <TabPane tab={label} key={`${key}`} />
      ))}
    </Tabs>
  );
};

export default SponsorTNav;
