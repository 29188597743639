// GOOGLE MAP API
// export const GOOGLE_MAP_API_KEY = "AIzaSyDylgz71-L7P0sEBxk3eNuqrPuimfoDppw";
export const GOOGLE_MAP_API_KEY = "AIzaSyCrHDL5ekIgFVPQxb4FMZnhfviGBIWe1OQ"; // my maps project
export const minUserNameLength = 8;

// user roles
export const ROLES = {
  organizer: "organizer",
  player: "player",
  panel: "panel",
  team: "team",
  sponsor: "sponsor",
  event: "event",
  beneficiary: "beneficiary"
};

// roles list
export const ROLES_LIST = [
  {
    label: "Player",
    value: "player"
  },
  {
    label: "Organizer",
    value: "organizer"
  },
  {
    label: "Sponsor",
    value: "sponsor"
  }
];

// sports types
export const SPORTS_TYPES = {
  physicalSports: "physicalSports",
  eSports: "eSports"
};

// sports type list
export const SPORTS_TYPES_LIST = [
  {
    label: "Physical Sports",
    value: SPORTS_TYPES.physicalSports
  },
  {
    label: "E-Sports",
    value: SPORTS_TYPES.eSports
  }
];

// type of match
export const GAMES_MODES = {
  single: "single",
  multi: "multi",
  knockOut: "knockout",
  ko: "ko",
  league: "league",
  schedule: "schedule",
  doubleElimination: "doubleelimination"
};

// physical sports list
export const PHYSICAL_SPORTS_LIST = [
  {
    label: "Cricket",
    value: "cricket",
    mode: GAMES_MODES.single,
    type: SPORTS_TYPES.physicalSports
  },
  {
    label: "Football",
    value: "football",
    mode: GAMES_MODES.single,
    type: SPORTS_TYPES.physicalSports
  },
  {
    label: "Badminton",
    value: "badminton",
    mode: GAMES_MODES.single,
    type: SPORTS_TYPES.physicalSports
  },
  {
    label: "Basketball",
    value: "basketball",
    mode: GAMES_MODES.single,
    type: SPORTS_TYPES.physicalSports
  },
  {
    label: "Volleyball",
    value: "volleyball",
    mode: GAMES_MODES.single,
    type: SPORTS_TYPES.physicalSports
  }
];

// e-sports list
export const E_SPORTS_LIST = [
  {
    label: "PUB G",
    value: "pubg",
    mode: GAMES_MODES.multi,
    type: SPORTS_TYPES.eSports
  },
  {
    label: "FreeFire",
    value: "freeFire",
    mode: GAMES_MODES.multi,
    type: SPORTS_TYPES.eSports
  },
  {
    label: "Fortnite",
    value: "fortnite",
    mode: GAMES_MODES.multi,
    type: SPORTS_TYPES.eSports
  },
  {
    label: "CallOfDuty",
    value: "callOfDuty",
    mode: GAMES_MODES.multi,
    type: SPORTS_TYPES.eSports
  },
  {
    label: "Valorant",
    value: "valorant",
    mode: GAMES_MODES.single,
    type: SPORTS_TYPES.eSports
  },
  {
    label: "TEKKEN 7",
    value: "tekken_7",
    mode: GAMES_MODES.single,
    type: SPORTS_TYPES.eSports
  },
  {
    label: "Counter Strike",
    value: "counterStrike",
    mode: GAMES_MODES.single,
    type: SPORTS_TYPES.eSports
  },
  {
    label: "DOTA 2",
    value: "dota_2",
    mode: GAMES_MODES.single,
    type: SPORTS_TYPES.eSports
  },
  {
    label: "League of Legends",
    value: "leagueOfLegends",
    mode: GAMES_MODES.single,
    type: SPORTS_TYPES.eSports
  }
];

// all sports combined
export const ALL_SPORTS_COMBINED = [...PHYSICAL_SPORTS_LIST, ...E_SPORTS_LIST];

// TOURNAMENT TYPES
export const TOURNAMENT_TYPES = {
  league: "league",
  knockOut: "knockout",
  doubleElimination: "doubleElimination"
};
// tournament types
export const TOURNAMENT_TYPES_LIST = [
  {
    label: "League",
    value: TOURNAMENT_TYPES.league
  },
  {
    label: "knock out",
    value: TOURNAMENT_TYPES.knockOut
  },
  {
    label: "Double Elimination",
    value: TOURNAMENT_TYPES.doubleElimination
  }
];

// LOCATIONS
export const LOCATIONS = [
  { label: "Karachi", value: "karachi" },
  { label: "Lahore", value: "lahore" },
  { label: "Islamabad", value: "islamabad" },
  { label: "Faisalabad", value: "faisalabad" },
  { label: "Rawalpindi", value: "rawalpindi" },
  { label: "Multan", value: "multan" },
  { label: "Gujranwala", value: "gujranwala" },
  { label: "Hyderabad", value: "hyderabad" },
  { label: "Peshawar", value: "peshawar" },
  { label: "Quetta", value: "quetta" }
];

export const sponsorTypes = {
  individual: "individual",
  organization: "organization"
};

export const SPONSOR_TYPES_LIST = [
  { label: "Individual", value: sponsorTypes.individual },
  { label: "Organization", value: sponsorTypes.organization }
];

export const ACTIVITY_TYPES = {
  ceremony: "ceremony",
  concert: "concert",
  conference: "conference",
  event: "event"
};

export const ACTIVITY_TYPES_LIST = [
  { label: "Ceremony", value: ACTIVITY_TYPES.ceremony },
  { label: "Concert", value: ACTIVITY_TYPES.concert },
  { label: "Conference", value: ACTIVITY_TYPES.conference },
  { label: "Event", value: ACTIVITY_TYPES.event }
];

export const EVENT_AND_TEAM_ROLES = {
  captain: "captain",
  manager: "manager",
  player: "player"
};

export const AWARDS_TYPES = [
  { label: "Cash", value: "cash" },
  { label: "Certificate", value: "certificate" },
  { label: "Shield", value: "shield" }
];

export const awardTypesIcons = ["cash", "certificate", "shield"];

export const EVENT_AND_TEAM_ROLES_LIST = [
  { label: "Captain", value: EVENT_AND_TEAM_ROLES.captain },
  { label: "Manager", value: EVENT_AND_TEAM_ROLES.manager },
  { label: "Player", value: EVENT_AND_TEAM_ROLES.player }
];

export const TRANSACTION_CONSTANTS = {
  topUp: "top-up",
  withdraw: "withdraw",
  transfer: "transfer",
  pending: "pending",
  completed: "completed"
};
