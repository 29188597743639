import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button, Col, Modal, Row, Select } from "antd";
import { error, success } from "../../../../common/Alerts/Message";
import { RegisterEvents, NotifyCaptain, UnRegisterEvents } from "../../../../Config/API/events";
import Loader from "../../../../common/cards/Loader";
// import { setEVENT } from "../../../../Store/Features/EventSlice";
// import { useDispatch } from "react-redux";
import FadeInImage from "../../../../common/Images/FadeInImage";
import { fallbackprofile } from "../../../../common/Utils";
import { KEYS } from "../../../../Config/Keys";
import useVariables from "../../../../common/hooks/useVariables";
const { Option } = Select;

function TeamRegisterModal({
  data,
  setopen,
  open,
  loading,
  checkowner,
  eventId,
  setremaining,
  // setTeams,
  // teams,
  registerteams,
  getEvent
}) {
  // const dispatch = useDispatch();
  const [id, setid] = useState(null);
  const [status, setStatus] = useState("");
  const [requesting, setrequesting] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const { viewer } = useVariables();

  useEffect(() => {
    if (id !== "" && id) {
      setStatus(checkowner(id) === true ? "1" : "2");
    }
    const a = registerteams?.find((e) => e?.team?._id === id);
    setIsRegistered(a);
  }, [id]);

  const registerTeam = async () => {
    try {
      setrequesting(true);

      if (eventId && id) {
        const teamId = id;
        const res = await RegisterEvents({
          id: eventId,
          teamId
        });
        const d = await res.json();
        if (d?.type === "failure") {
          error(d?.result || "Team already joined");
        }
        if (d?.type === "success") {
          getEvent();
          setremaining(
            parseInt(d?.data[0]?.maxParicipants) - parseInt(d?.data[0]?.registerteams?.length)
          );
          success("Event joined");
        }

        setrequesting(false);
        setid("");
        setStatus("");
        setopen(false);
      } else throw new Error("One of the id's are missing try again later!");
    } catch (e) {
      error(e);
      setrequesting(false);
      console.log(e);
      setStatus("");
      setid("");
    }
  };

  const notify = async () => {
    const ownid = viewer?.id; //current user id
    const find = data?.find((e) => e?._id === id);
    let captainId = find?.profile?._id;
    try {
      setrequesting(true);
      if (eventId && id && captainId) {
        const res = await NotifyCaptain({
          eventId,
          captainId,
          id: ownid
        });
        const d = await res.json();
        if (d?.type === "failue") {
          error("An Error Occured joined");
        }
        if (d?.type === "success") {
          success("Captain Notified");
        }
        console.log(d);
        setid("");
        setStatus("");
        setopen(false);
        setrequesting(false);
      } else throw new Error("One of the id's are missing try again later!");
    } catch (e) {
      console.log(e);
      setStatus("");
      setid("");
      setrequesting(false);
      error(e);
    }
  };

  const unregisterTeam = async () => {
    try {
      setrequesting(true);

      if (eventId && id) {
        const teamId = id;
        const res = await UnRegisterEvents({
          id: eventId,
          teamId
        });
        const d = await res.json();
        if (d?.type === "failure") {
          error(data?.result);
        }
        if (d?.type === "success") {
          getEvent();
          setremaining(
            parseInt(d?.data[0]?.maxParicipants) - parseInt(d?.data[0]?.registerteams?.length)
          );
          success("Event un-registered");
        }

        setrequesting(false);
        setid("");
        setStatus("");
        setopen(false);
      } else throw new Error("One of the id's are missing try again later!");
    } catch (e) {
      error(e);
      setrequesting(false);
      console.log(e);
      setStatus("");
      setid(null);
    }
  };

  const ref = useRef(null);
  if (open !== "register") return null;
  return (
    <Modal
      title="Select Team"
      className="editPost AddTeamModel"
      confirmLoading
      mask
      maskClosable
      onCancel={() => {
        setopen(false);
      }}
      closable={false}
      centered
      destroyOnClose={true}
      visible={open}
      open={open}
      footer={false}
      bodyStyle={{ padding: "50px 20px 20px 20px" }}>
      {loading ? (
        <Loader smaller noShadow center />
      ) : (
        <Row>
          <Col span={24} ref={ref}>
            <Select
              getPopupContainer={() => ref.current}
              showSearch
              allowClear
              style={{
                marginBottom: "15px",
                width: "100%"
              }}
              className="TeamSelect"
              onChange={(e) => setid(e)}
              onClear={() => setid(null)}
              onDeselect={() => setid(null)}
              dropdownClassName="TeamSelectDropDown"
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.children?.props?.children[1].props?.children ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }>
              {data?.map((e) => (
                <Option value={e?._id} key={e?._id}>
                  <span style={{ display: "flex", alignItems: "center", gap: ".5rem" }}>
                    <FadeInImage
                      type={"Fadepostprofile"}
                      fallaback={fallbackprofile}
                      src={KEYS.API + "/" + e?.profilepic}
                    />
                    <h4 style={{ marginBottom: "0" }}>{e?.name}</h4>
                  </span>
                </Option>
              ))}
            </Select>
            {id === null && (
              <Button
                style={{
                  width: "100px",
                  margin: "0px auto",
                  opacity: "0",
                  userSelect: "none"
                }}
                type="primary"
                className="registerbutton">
                Register
              </Button>
            )}
            {status === "1" && id ? (
              <Fragment>
                {isRegistered ? (
                  <Button
                    loading={requesting}
                    onClick={() => unregisterTeam()}
                    style={{
                      margin: "0px auto",
                      maxWidth: "150px",
                      display: "block",
                      fontWeight: "500",
                      fontSize: "larger"
                    }}
                    className="btnMobileDimensions"
                    type="primary">
                    Un-Register
                  </Button>
                ) : (
                  <Button
                    style={{
                      margin: "0px auto",
                      maxWidth: "150px",
                      display: "block"
                    }}
                    loading={requesting ? true : false}
                    onClick={() => {
                      registerTeam();
                    }}
                    type="primary"
                    className="registerbutton btnMobileDimensions">
                    Register
                  </Button>
                )}
              </Fragment>
            ) : null}
            {status === "2" && id && (
              <Button
                style={{
                  margin: "0px auto",
                  maxWidth: "150px",
                  display: "block",
                  fontWeight: "500",
                  fontSize: "larger"
                }}
                loading={requesting ? true : false}
                onClick={() => {
                  notify();
                }}
                className="btnMobileDimensions"
                type="primary">
                Request Captain
              </Button>
            )}
          </Col>
        </Row>
      )}
    </Modal>
  );
}

export default TeamRegisterModal;
