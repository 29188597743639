import { KEYS } from "../Keys";
export const CreateProfileSponsorAPI = async (values) => {
  const response = await fetch(KEYS.API + "/sponsor/profilecreate", {
    method: "POST",
    headers: {
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: values
  });
  return response;
};

export const GetSponsorProfileAPI = async () => {
  const response = await fetch(KEYS.API + "/sponsor/getprofile", {
    // signal: controller.signal,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    }
  });
  return response;
};

export const EditSponsorAPI = async (values) => {
  const response = await fetch(KEYS.API + "/sponsor/profileupdate", {
    method: "POST",
    headers: {
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: values
  });
  return response;
};

export const GetSingleSponsor = async (values) => {
  const response = await fetch(KEYS.API + "/sponsor/getsinglesponsor", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: typeof values === "string" ? JSON.stringify({ id: values }) : JSON.stringify(values)
  });
  return response;
};

export const SponsorAddActivity = async (values) => {
  const response = await fetch(KEYS.API + "/sponsor/campaign/activity", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

export const SponsorUpdateActivity = async (values) => {
  const response = await fetch(KEYS.API + "/sponsor/campaign/updateActivity", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

export const SponsorDeleteActivity = async (id) => {
  const response = await fetch(KEYS.API + "/sponsor/campaign/deleteActivity/" + id, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    }
  });
  return response;
};
