import React, { Fragment, useState, useEffect, useRef, useCallback } from "react";
import { Row, Spin } from "antd";
import Loader from "../../../common/cards/Loader";
import UploadPostcard from "../../../common/cards/UploadPostcard";
import ShowCards from "../../../common/cards/FeedCard";
import NoData from "../../../common/cards/noData";
import { useFetchAllPosts } from "../../../common/hooks/useFetchAllPosts";
import { useCreatePost } from "../../../common/hooks/useCreatePost";

const DiscussionOrganizer = () => {
  const { fetchloading, POSTS, setPOSTS, setPage, totalPages, page, fetchTime } =
    useFetchAllPosts("organizer");
  const {
    uploadloading,
    IMAGE,
    VIDEO,
    setimage,
    setvideo,
    text,
    setText,
    uploadingMedia,
    setuploadingMedia,
    CreatePost,
    setLuploading,
    Luploading
  } = useCreatePost(setPOSTS, "Organizer", POSTS);

  const [posts, setPosts] = useState([]);
  const [loadMorePosts, setLoadMorePosts] = useState(false);

  const observer = useRef();
  const timer = useRef();
  const isThrottled = useRef(false);

  useEffect(() => {
    const fetchPosts = async () => {
      setPosts((prevPosts) => [...prevPosts, ...POSTS]);

      // Adjust timeout based on fetch time, adding some buffer (e.g., 100ms)
      const timeoutDuration = Math.max(fetchTime + 100, 500); // Minimum of 500ms

      timer.current = setTimeout(() => setLoadMorePosts(false), timeoutDuration);
    };
    fetchPosts();
    return () => clearTimeout(timer.current);
  }, [POSTS, fetchTime]);

  const lastPostElementRef = useCallback(
    (node) => {
      if (fetchloading || isThrottled.current) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            setLoadMorePosts(true);
            isThrottled.current = true;
            setTimeout(() => {
              isThrottled.current = false;
            }, 100);
            if (page < totalPages) setPage((prevPage) => prevPage + 1);
          }
        },
        {
          root: null,
          threshold: 0.1
        }
      );
      if (node) observer.current.observe(node);
    },
    [fetchloading, page, totalPages]
  );

  return (
    <Fragment>
      <Row className="mobileto">
        <UploadPostcard
          uploadingMedia={uploadingMedia}
          setuploadingMedia={setuploadingMedia}
          setText={setText}
          text={text}
          IMAGE={IMAGE}
          setimage={setimage}
          VIDEO={VIDEO}
          setvideo={setvideo}
          onPost={CreatePost}
          loading={uploadloading}
          setLuploading={setLuploading}
          Luploading={Luploading}
        />

        {fetchloading ? (
          <Loader />
        ) : posts.length > 0 ? (
          <>
            {posts.map((data, index) => {
              if (posts.length === index + 1) {
                return (
                  <ShowCards
                    key={index}
                    index={index}
                    data={data}
                    POSTS={posts}
                    setPOSTs={setPosts}
                    ref={lastPostElementRef}
                  />
                );
              } else {
                return (
                  <ShowCards
                    key={index}
                    index={index}
                    data={data}
                    POSTS={posts}
                    setPOSTs={setPosts}
                  />
                );
              }
            })}
            {/* Loader for more posts */}
            {loadMorePosts && page < totalPages && (
              <div style={{ textAlign: "center", margin: "20px", width: "100%" }}>
                <Spin tip="Loading more posts..." style={{ color: "black", fontSize: "12px" }} />
              </div>
            )}
          </>
        ) : (
          <NoData />
        )}
      </Row>
    </Fragment>
  );
};

export default DiscussionOrganizer;
