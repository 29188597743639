import React, { Fragment, useState, useEffect, useCallback, useRef } from "react";
import TimelineCard from "../../../../../../common/cards/TimelineCard";
import { Row, Spin, Col } from "antd";
import UploadPostcard from "../../../../../../common/cards/UploadPostcard";
import {
  GetEventPosts,
  EditEventPosts,
  DeleteEventPosts
} from "../../../../../../Config/API/events";
import Loader from "../../../../../../common/cards/Loader";
import Swal from "sweetalert2";
import { error } from "../../../../../../common/Alerts/Message";
import NoData from "../../../../../../common/cards/noData";
import { useCreatePost } from "../../../../../../common/hooks/useCreatePost";

const Discussion = ({ isOrg, eventId, event }) => {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const observer = useRef();
  const isThrottled = useRef(false);

  // Temporary variables for uploading either image or video
  const {
    uploadloading,
    IMAGE,
    VIDEO,
    setimage,
    setvideo,
    text,
    setText,
    uploadingMedia,
    setuploadingMedia,
    CreatePost,
    Luploading,
    setLuploading
  } = useCreatePost(setPosts, "event", posts);

  const getAllPosts = useCallback(async () => {
    try {
      console.log("event orginer");
      setLoading(true);
      if (!eventId) return error("Event ID is missing.");
      const res = await GetEventPosts({ eventId: eventId }, page);
      const data = await res.json();
      if (data.type !== "success") {
        Swal.fire({
          icon: "error",
          title: "An error occurred! Check your internet connection."
        });
      } else {
        setPosts((prevPosts) => [...prevPosts, ...data.result]);
        setTotalPages(data?.metaData?.totalPages || 1);
      }

      setLoading(false);
    } catch {
      setLoading(false);
    }
  }, [eventId, page]);

  useEffect(() => {
    getAllPosts();
  }, [page, getAllPosts]);

  const lastPostElementRef = useCallback(
    (node) => {
      if (loading || isThrottled.current) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          isThrottled.current = true;
          setTimeout(() => {
            isThrottled.current = false;
          }, 100);
          if (page < totalPages) setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading]
  );

  return (
    <Fragment>
      <Row className="mobileto">
        {isOrg && new Date(event?.deadline).setHours(24) >= new Date() && (
          <UploadPostcard
            uploadingMedia={uploadingMedia}
            setuploadingMedia={setuploadingMedia}
            setText={setText}
            text={text}
            IMAGE={IMAGE}
            setimage={setimage}
            VIDEO={VIDEO}
            setvideo={setvideo}
            onPost={CreatePost}
            loading={uploadloading}
            setLuploading={setLuploading}
            Luploading={Luploading}
          />
        )}
        {loading && page === 1 ? (
          <Loader />
        ) : posts.length > 0 ? (
          <>
            {posts.map((e, i) => {
              if (posts.length === i + 1) {
                return (
                  <TimelineCard
                    Editing={isOrg}
                    data={e}
                    ref={lastPostElementRef}
                    setPOSTs={setPosts}
                    key={i}
                    deletepost={DeleteEventPosts}
                    editpost={EditEventPosts}
                    eventId={eventId}
                    border
                  />
                );
              } else {
                return (
                  <TimelineCard
                    Editing={isOrg}
                    data={e}
                    setPOSTs={setPosts}
                    key={i}
                    deletepost={DeleteEventPosts}
                    editpost={EditEventPosts}
                    eventId={eventId}
                    border
                  />
                );
              }
            })}
            {/* Loader for more posts */}
            {loading && page < totalPages && (
              <div style={{ textAlign: "center", margin: "20px", width: "100%" }}>
                <Spin tip="Loading more posts..." style={{ color: "black", fontSize: "12px" }} />
              </div>
            )}
          </>
        ) : (
          <Col span={24}>
            <NoData />
          </Col>
        )}
      </Row>
    </Fragment>
  );
};

export default Discussion;
