import React, { Fragment, useState, useEffect } from "react";
import { KEYS } from "../../../../Config/Keys";
import Loader from "../../../../common/cards/Loader";
import FadeInImage from "../../../../common/Images/FadeInImage";
import { fallbackprofile } from "../../../../common/Utils";
import { useNavigate } from "react-router-dom";
const InviteCard = ({ panel }) => {
  const [loading, setloading] = useState(false);
  useEffect(() => {
    if (!panel) setloading(true);
    else setloading(false);
    return () => {
      setloading(false);
    };
  }, [panel]);

  const navigate = useNavigate();
  return (
    <Fragment>
      {loading ? <Loader fullpage loading={"white"} /> : null}
      <div
        className="  membercard"
        onClick={() =>
          navigate(`/view/organizer/${panel?.organizer?._id}/timeline`, { state: "organizer" })
        }>
        <div className="popadd">
          <FadeInImage
            className="poprofilepic"
            src={KEYS.API + "/" + panel?.organizer?.profilepic}
            fallaback={fallbackprofile}
            type={"Fadegcard"}
          />
        </div>

        <p className="namepoinvite">{panel?.organizer?.name}</p>
        <p className="nameporesp">Waiting for response...</p>
      </div>
    </Fragment>
  );
};

export default InviteCard;
