import React, { Fragment, useEffect, useRef, useState } from "react";
import { Row, Col, Form, Button, DatePicker, Image, Select, Radio } from "antd";

import { fallbackcover } from "../../../common/Utils";

import { CreateTeam as CreateTeamApi } from "../../../Config/API/teams";
import Loader from "../../../common/cards/Loader";
import { useNavigate, useParams } from "react-router-dom";
import "../../../styles/player/CreateTeam.css";
import { error, success } from "../../../common/Alerts/Message";
import moment from "moment";
import useVariables from "../../../common/hooks/useVariables";
import { Formik } from "formik";
import InputTextField from "../../../common/FormItem/InputTextField";
import {
  E_SPORTS_LIST,
  LOCATIONS,
  PHYSICAL_SPORTS_LIST,
  ROLES,
  SPORTS_TYPES,
  SPORTS_TYPES_LIST
} from "../../../Config/helpers/constants";
import { createTeamValidations } from "../../../Config/helpers/validations";
import UserNameField from "../../../common/FormItem/UserNameField";
import { useFetchProfile } from "../../../common/hooks/useFetchProfile";
import { PROFILE } from "../../../Store/Features/ProfileSlice";
import { useSelector } from "react-redux";
import useEditProfile from "../../../common/hooks/useEditProfile";
import { KEYS } from "../../../Config/Keys";
const { Option } = Select;
const CreateTeam = () => {
  const { id } = useParams();
  const [editloading, edit] = useEditProfile();

  const [loading, setloding] = useState(false);
  let profileLoading = false;
  let profileData = null;
  if (id) {
    const { profileLoding } = useFetchProfile(id, ROLES.team);
    profileLoading = profileLoding;
    const Profile = useSelector(PROFILE);
    profileData = Profile;
  }

  // Profile
  const [profileImage, setProfileImage] = useState({
    file: {},
    preview: ""
  });
  // Cover
  const [coverImage, setCoverImage] = useState({
    file: {},
    preview: ""
  });

  const { viewer } = useVariables();

  const navigate = useNavigate();

  // handle non-formik field changes
  function handleChanges(name, value, dateString, setFieldValue) {
    if (dateString) {
      setFieldValue(name, dateString);
    } else {
      setFieldValue(name, value);
    }
  }

  const isCover = useRef();
  const isProfile = useRef();
  // input type image handler
  const onImageFileChange = (e) => {
    const file = e.target.files[0];
    if (e.target.name === "profileImage") {
      setProfileImage({
        file: file,
        preview: URL.createObjectURL(file)
      });
      isProfile.current = true;
    } else {
      setCoverImage({
        file: file,
        preview: URL.createObjectURL(file)
      });
      isCover.current = true;
    }
  };

  useEffect(() => {
    if (id) {
      setCoverImage({
        file: {},
        preview: KEYS.API + "/" + profileData.cover
      });
      setProfileImage({
        file: {},
        preview: KEYS.API + "/" + profileData.profile
      });
    }
  }, [profileData]);

  const createTeam = async (values) => {
    try {
      setloding(true);

      const playerId = viewer?.id;

      if (playerId !== null || playerId !== undefined) {
        const formData = new FormData();
        formData.append("id", id ? id : playerId);
        formData.append("name", values?.name);
        if (profileData?.username !== values?.username) {
          formData.append("username", values?.username);
        }
        formData.append("location", values?.location);
        formData.append("establish", values?.establishedDate);
        formData.append("description", values?.description);
        formData.append("typeofsports", values?.sportsType);
        formData.append("nameofsports", values?.selectedSport);
        if (isProfile.current === true) {
          formData.append("profile", profileImage.file);
        }
        if (isCover.current === true) {
          formData.append("cover", coverImage.file);
        }
        if (id) {
          edit(formData, ROLES.team, id);
        } else {
          const res = await CreateTeamApi(formData);
          const data = await res.json();
          if (data.type === "success") {
            success("Team Created Successfully");
            navigate(`/player/teams`);
          } else {
            error(data?.result || "An error occured");
          }
        }
      } else {
        error("Error occurred, missing ID");
      }

      if (!id) {
        setloding(false);
      }
    } catch (e) {
      console.log(e.message);
      if (!id) {
        setloding(false);
      }
    } finally {
      setloding(false);
    }
  };

  const initialValues = {
    name: profileData?.name || "",
    username: profileData?.username || "",
    description: profileData?.description || "",
    sportsType: profileData?.typeofsports || "",
    selectedSport: profileData?.nameofsports || "",
    location: profileData?.location || "",
    establishedDate: profileData?.establish || ""
  };

  if (loading || profileLoading)
    return (
      <Loader
        fullpage
        text={
          profileLoading ? "Getting team data, please wait!" : "Team is being created please wait!"
        }
        loading="white"
      />
    );

  return (
    <Fragment>
      <div>
        <h1 className="headingPlayer">{id ? "Edit" : " Create"} Your Team</h1>
        <Row>
          <Col sm={24} xs={24} md={24} style={{ position: "relative" }}>
            <div
              style={{
                position: "relative",
                width: "96%",
                margin: "auto",
                background: "#DADADA",
                borderRadius: "8px"
              }}
              className="CoverImageWrapper">
              {coverImage?.preview !== "" ? (
                <Image
                  wrapperClassName="CoverImageWrapper"
                  className="CoverImage"
                  preview={false}
                  src={coverImage?.preview}
                  style={{ objectFit: "cover" }}
                  fallback={fallbackcover}
                />
              ) : null}
              <div className="profileuploaders">
                <label htmlFor="coveruploader" style={{}}>
                  <img src="/images/icons/camera.png" alt="" />
                  {coverImage?.preview === "" ? <span>Add your Cover image</span> : null}
                </label>
                <input
                  type="file"
                  name="CoverImage"
                  className="uploaderInput"
                  id="coveruploader"
                  accept="image/*"
                  onChange={onImageFileChange}
                />
              </div>
            </div>
            <div className="profileuploaderForProfile">
              {profileImage?.preview !== "" ? (
                <Image
                  preview={false}
                  wrapperClassName="profileImageWrapper"
                  className="ProfileImage"
                  src={profileImage?.preview}
                  fallback={fallbackcover}
                />
              ) : null}
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  borderRadius: "50%"
                }}>
                <label htmlFor="profileuploader">
                  <img src="/images/icons/camerasmall.png" alt="" />
                  {profileImage?.preview === "" ? <span>Add Profile</span> : null}
                </label>
                <input
                  type="file"
                  name="profileImage"
                  className="uploaderInput"
                  id="profileuploader"
                  accept="image/*"
                  onChange={onImageFileChange}
                />
              </div>
            </div>
          </Col>
        </Row>
        <div className="formorganizer">
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={createTeamValidations}
            onSubmit={createTeam}>
            {({ values, errors, setFieldValue, isValid, dirty, handleSubmit }) => (
              <Form
                name="basic"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                autoComplete="off"
                onFinish={handleSubmit}>
                <Row justify="space-between">
                  <Col lg={11} md={11} sm={24} xs={24} className="cteamfpadding">
                    {/* Name */}
                    <InputTextField
                      required
                      name="name"
                      label="Name of Team"
                      placeholder="e.g   “ Spartans Club “"
                    />

                    {/* User name */}
                    <UserNameField
                      setFieldValue={setFieldValue}
                      value={values?.username}
                      error={errors?.username}
                      type={ROLES.team}
                      prevUserName={profileData?.username || ""}
                    />

                    {/* Description */}
                    <InputTextField
                      name="description"
                      label="Description"
                      placeholder="e.g   “ Cyberpunks FTS Collectibles “"
                    />

                    {/* Sports type */}
                    <Form.Item label="Type of Sport" required>
                      <Radio.Group
                        onChange={(e) => {
                          setFieldValue("sportsType", e.target.value);
                          setFieldValue("selectedSport", "");
                        }}
                        name="sportsType"
                        value={values?.sportsType}>
                        {SPORTS_TYPES_LIST.map((type, index) => (
                          <Radio key={index} value={type.value}>
                            {type.label}
                          </Radio>
                        ))}
                      </Radio.Group>
                    </Form.Item>
                  </Col>

                  {/* Sports list based on selected type */}
                  <Col lg={11} md={11} sm={24} xs={24} className="cteamfpadding">
                    <Form.Item
                      required
                      label="Name of Sport"
                      help={errors?.selectedSport ? errors?.selectedSport : undefined}
                      validateStatus={errors?.selectedSport ? "error" : "success"}>
                      <Select
                        className="mb-2 w-100"
                        aria-required
                        value={values?.selectedSport}
                        placeholder={
                          values?.sportsType === SPORTS_TYPES.physicalSports
                            ? "Physical Sports"
                            : "E-Sports"
                        }
                        title="Teams"
                        bordered={true}
                        name="selectedSport"
                        onChange={(value) => setFieldValue("selectedSport", value)}>
                        {/* Rendered physical sports if physical is selected */}
                        {values?.sportsType === SPORTS_TYPES.physicalSports &&
                          PHYSICAL_SPORTS_LIST.map((sport, index) => (
                            <Option key={index} value={sport.value}>
                              {sport.label}
                            </Option>
                          ))}
                        {/* Rendered E-sports if eSports is selected */}
                        {values?.sportsType === SPORTS_TYPES.eSports &&
                          E_SPORTS_LIST.map((sport, index) => (
                            <Option key={index} value={sport.value}>
                              {sport.label}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>

                    {/* location */}
                    <Form.Item
                      required
                      label="Location"
                      help={errors?.location ? errors?.location : undefined}
                      validateStatus={errors?.location ? "error" : "success"}>
                      <Select
                        className="mb-2 w-100"
                        aria-required
                        value={values?.location}
                        placeholder="Location"
                        title="Location"
                        bordered={true}
                        name="location"
                        onChange={(value) => setFieldValue("location", value)}>
                        {LOCATIONS.map((item, index) => (
                          <Option key={index} value={item.value}>
                            {item.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>

                    {/* established in */}
                    <Form.Item
                      className="dob"
                      required
                      label="Establish In"
                      help={errors?.establishedDate ? errors?.establishedDate : undefined}
                      validateStatus={errors?.establishedDate ? "error" : "success"}>
                      <DatePicker
                        value={values?.DOB ? moment(values?.DOB) : undefined}
                        className="mt8"
                        name="establishedDate"
                        showToday
                        inputReadOnly
                        disabledDate={(current) => {
                          return current && current > moment().add(0, "day");
                        }}
                        onChange={(value, dateString) =>
                          handleChanges("establishedDate", value, dateString, setFieldValue)
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify="center">
                  <Button
                    loading={editloading}
                    className="teamcreateButton"
                    htmlType="submit"
                    type="primary"
                    disabled={!(isValid && dirty) || editloading}>
                    {id ? "Edit" : "Create"}
                  </Button>
                </Row>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Fragment>
  );
};

export default CreateTeam;
