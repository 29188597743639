import { Suspense, useState } from "react";
import { useEffect } from "react";
import PlayerLayout from "../../layout/playerlayout/PlayerLayout";
import OrganizerLayout from "../../layout/organizerLayout/OrganizerLayout";
import { Routes, Route } from "react-router-dom";
import Error404 from "../../../common/errors/404Error";
import Loader from "../../../common/cards/Loader";
import React from "react";
import AuthEvent from "./AuthEvent";
import EventAwards from "../Components/EventAssignedAwards/EventAwards";

const Home = React.lazy(() => import("../Components/Home"));
const Schedule = React.lazy(() => import("../Components/League"));
const KOBrackets = React.lazy(() => import("../Components/KO"));
const MultiplayerSchedule = React.lazy(() => import("../Components/multiplayermatches"));
const History = React.lazy(() => import("../Components/History"));

function EventLayoutWrapper() {
  const [current, setcurrent] = useState("");
  useEffect(() => {
    let viewer = JSON.parse(localStorage.getItem("userDetails"))?.role;
    viewer = String(viewer).toLowerCase();
    setcurrent(viewer === "player" ? "player" : "organizer");

    return () => {
      setcurrent(null);
    };
  }, []);
  return (
    <Suspense fallback={<Loader fullpage />}>
      <Routes>
        <Route element={<AuthEvent />}>
          <Route path="/" element={current === "player" ? <PlayerLayout /> : <OrganizerLayout />}>
            <Route path="/history" element={<History />} />
            <Route index element={<Home />} />
            <Route path="/schedule" element={<Schedule />} />
            <Route path="/knock-out-matches" element={<KOBrackets />} />
            <Route path="/multiplayermatches" element={<MultiplayerSchedule />} />
            <Route path="/event-awards" element={<EventAwards />} />

            <Route path="*" element={<Error404 />} />
          </Route>
        </Route>
      </Routes>
    </Suspense>
  );
}

export default EventLayoutWrapper;
