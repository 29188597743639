import React from "react";
import { Col, Form, Row, Select, Checkbox, Button, Input, Divider } from "antd";
import InputTextField from "../../../../../common/FormItem/InputTextField";
import { GAMES_MODES, TOURNAMENT_TYPES_LIST } from "../../../../../Config/helpers/constants";
import { FieldArray } from "formik";
import { MdDelete } from "react-icons/md";
const { Option } = Select;
function Page2({ isVisible, values, errors, setFieldValue, eventID }) {
  if (!isVisible) return null;
  const data = { name: "", registrationFee: "", noOfSlots: 1 };

  return (
    <Row>
      <Col sm={24} xs={24} md={24} className="ceinputformpadding" style={{ paddingBottom: "0" }}>
        <InputTextField
          required
          type="textArea"
          label="Rules and regulation"
          name="rules"
          rows="5"
        />
      </Col>

      <Col lg={12} md={12} className="ceinputformpadding">
        <Form.Item
          className="halfwidth profileItems"
          help={
            errors?.maxParticipants || errors?.minParticipants
              ? errors?.maxParticipants || errors?.minParticipants
              : undefined
          }
          validateStatus={errors?.maxParticipants || errors?.minParticipants ? "error" : "success"}>
          <label className="boldlabel required">Max/Min Number of Participants</label>
          <div
            className="cemaxinput"
            style={{
              justifyContent: "flex-start",
              gap: "10px",
              marginTop: "20px"
            }}>
            <div>
              <label className="boldlabel" style={{ fontSize: "14px" }}>
                MAX
              </label>
            </div>
            <div>
              <Input
                onChange={(e) => {
                  if (!Number(e?.currentTarget?.value)) return;
                  let num = e?.currentTarget?.value;
                  if (num && num > 0) setFieldValue("maxParticipants", Math.abs(num));
                  e.target.onkeydown = (a) => {
                    if (a.key === "Backspace" && e?.target?.value?.length === 1) {
                      setFieldValue("maxParticipants", null);
                    }
                  };
                }}
                value={values?.maxParticipants}
                style={{
                  maxWidth: "75px",
                  maxHeight: "33px",
                  marginTop: "0",
                  borderRadius: "8px",
                  marginRight: "25px"
                }}
                type="number"
                className="ceinputfield"
              />
            </div>
            <div>
              <label className="boldlabel" style={{ fontSize: "14px" }}>
                MIN
              </label>
            </div>
            <div>
              <Input
                onChange={(e) => {
                  if (!Number(e?.target?.value)) return;
                  let num = e?.currentTarget?.value;
                  if (num && num > 0) setFieldValue("minParticipants", Math.abs(num));
                  e.target.onkeydown = (a) => {
                    if (a.key === "Backspace" && e?.target?.value?.length === 1) {
                      setFieldValue("minParticipants", undefined);
                    }
                  };
                }}
                disabled={eventID ? true : false}
                value={values?.minParticipants}
                style={{
                  maxWidth: "75px",
                  maxHeight: "33px",
                  marginTop: "0",
                  borderRadius: "8px"
                }}
                type="number"
                className="ceinputfielddesc"
              />
            </div>
          </div>
        </Form.Item>
      </Col>
      <Col md={12} lg={12} className="ceinputformpadding">
        <Form.Item
          className="halfwidth profileItems"
          help={errors?.typeOfMatch ? errors?.typeOfMatch : undefined}
          validateStatus={errors?.typeOfMatch ? "error" : "success"}>
          <label className="boldlabel required">Type of Match Schedule</label>
          <Select
            className="ceinputfieldnos"
            title="league"
            onChange={(e) => {
              setFieldValue("typeOfMatch", e);
              // if (e === "knockout") setValTeam("");
            }}
            value={values?.typeOfMatch}
            bordered={true}
            disabled={eventID ? true : false}>
            {values?.gameMode === GAMES_MODES.multi ? (
              <>
                <Option value={GAMES_MODES.multi}>Multiplayer</Option>
              </>
            ) : (
              <>
                {TOURNAMENT_TYPES_LIST.map((game, index) => (
                  <Option key={index} value={game.value}>
                    {game.label}
                  </Option>
                ))}
              </>
            )}
          </Select>
        </Form.Item>
      </Col>
      <Col span={24} className="ceinputformpadding pt-0 pb-0">
        <Checkbox
          onChange={(e) => setFieldValue("wildCardEntry", e.target.checked)}
          value={values?.wildCardEntry}
          checked={values?.wildCardEntry}>
          Wild Card Entry
        </Checkbox>
        <p className="customError">{typeof errors?.wildCard === "string" && errors?.wildCard}</p>
        {values?.wildCardEntry && (
          <section className="mt-2">
            {" "}
            <FieldArray
              name="wildCard"
              render={(arrayHelpers) => (
                <div>
                  {values.wildCard &&
                    values.wildCard.length > 0 &&
                    values.wildCard.map((card, index) => {
                      // don't show wildCard that has isDeleted:true
                      if (card?._id && card?.isDeleted) {
                        return null;
                      }
                      return (
                        <Row key={index} align="middle" justify="start" gutter={4}>
                          <Col sm={24} md={12}>
                            <InputTextField
                              name={`wildCard.${index}.name`}
                              type="text"
                              placeholder="Wild Card Name"
                              value={values?.wildCard?.[index]?.name}
                              error={errors?.wildCard?.[index]?.name}
                            />
                          </Col>
                          <Col sm={24} md={12}>
                            <Row align="middle">
                              <Col span={12}>
                                <InputTextField
                                  name={`wildCard.${index}.registrationFee`}
                                  type="number"
                                  placeholder="Entry Fee"
                                  value={values?.wildCard?.[index]?.registrationFee}
                                  error={errors?.wildCard?.[index]?.registrationFee}
                                />
                              </Col>
                              <Col span={8}>
                                <InputTextField
                                  name={`wildCard.${index}.noOfSlots`}
                                  type="number"
                                  placeholder="No of slots"
                                  value={values?.wildCard?.[index]?.noOfSlots}
                                  error={errors?.wildCard?.[index]?.noOfSlots}
                                />
                              </Col>
                              {console.log(values?.wildCard)}
                              <Col span={4}>
                                <Button
                                  danger
                                  type="button"
                                  onClick={() => {
                                    // incase of on edit event screen add delete:True to wildCard so that it can be deleted from database
                                    if (values?.wildCard?.[index]?._id) {
                                      arrayHelpers.replace(index, {
                                        ...values.wildCard[index],
                                        isDeleted: true
                                      });
                                    } else {
                                      arrayHelpers.remove(index);
                                    }
                                  }}>
                                  <MdDelete />
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                          <Divider orientation="left" plain></Divider>
                        </Row>
                      );
                    })}
                  <Button type="button" onClick={() => arrayHelpers.push(data)}>
                    {/* show this when user has removed all friends from the list */}
                    Add Wild Card
                  </Button>
                </div>
              )}
            />
          </section>
        )}
      </Col>
    </Row>
  );
}

export default Page2;
