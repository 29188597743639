import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { Drawer, Collapse, Form, Input } from "antd";
import { FaSearch } from "react-icons/fa";
import React, { useState } from "react";
import { KEYS } from "../../../Config/Keys";
import { fallbackprofile } from "../../../common/Utils";
import FadeInImage from "../../../common/Images/FadeInImage";
import { Info } from "../../../common/Alerts/Message";
import useVariables from "../../../common/hooks/useVariables";
import { useSelector } from "react-redux";

const OrganizerDrawer = ({ onClose, visible }) => {
  const navigate = useNavigate();
  const { query, id } = useParams();
  // const { panelid } = useVariables();
  const { pathname } = useLocation();
  const [text, settext] = useState(query);
  const flexTokens = useSelector((state) => state.wallet.flexTokens);

  const { Panel } = Collapse;
  const { viewer } = useVariables();

  var currentflag = "organizer",
    currentid = viewer?.id;

  const notClickable = pathname === "/organizer";
  const ALERT = () => Info("Create Profile First");

  return (
    <Drawer
      bodyStyle={{ marginLeft: "5px" }}
      title="Spoflex"
      placement="right"
      onClose={onClose}
      visible={visible}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <FadeInImage
          type="Fadenavprofile"
          fallaback={fallbackprofile}
          src={KEYS.API + "/" + viewer?.profile}
        />
        <span
          style={{
            paddingLeft: "20px",
            userSelect: "none",
            fontSize: "15",
            fontWeight: 600
          }}>
          {viewer?.name}
        </span>
      </div>
      <Form
        style={{ margin: "20px 0 0 0" }}
        onFinish={() => {
          if (text?.trim().length > 0 && !notClickable) {
            onClose();
            navigate(`/organizer/search/${text}`, { state: text });
          } else if (notClickable) ALERT();
        }}>
        <Form.Item style={{ margin: "0px", width: "100%" }} name="search">
          <Input
            size="middle"
            value={text}
            defaultValue={query}
            onChange={(e) => settext(e.currentTarget.value)}
            placeholder="Search"
            style={{ borderRadius: "20px", padding: "8px 5px" }}
            prefix={
              <FaSearch
                onClick={() => {
                  if (text?.trim().length > 0) {
                    onClose();
                    navigate(`/organizer/search/${text}`, { state: text });
                  }
                }}
                style={{
                  cursor: "pointer",
                  color: "#8F8F8F",
                  marginRight: "10px",
                  width: "25px",
                  height: "16px"
                }}
              />
            }
          />
        </Form.Item>
      </Form>

      <div className="drawerpadding" onClick={onClose}>
        {notClickable ? <div onClick={ALERT}>Home</div> : <Link to="/organizer/feed">Home</Link>}
      </div>
      <div className="drawerpadding" onClick={onClose}>
        {notClickable ? (
          <div onClick={ALERT}>Profile</div>
        ) : (
          <Link to="/organizer/timeline">Profile</Link>
        )}
      </div>
      <div style={{ paddingTop: "5px" }}>
        {notClickable ? (
          <div className="Nav_a" style={{ marginTop: "-6px" }} onClick={ALERT}>
            Events
          </div>
        ) : (
          <Collapse className="customz" style={{ padding: "0px" }} ghost>
            <Panel className="custom" header="Events" key="1">
              <div>
                <Link
                  onClick={onClose}
                  className="drawerpadding"
                  to={`/organizer/events/pastevents`}>
                  Past Events
                </Link>
              </div>
              <div className="drawerpadding" onClick={onClose}>
                <Link className="drawerpadding" to={`/organizer/events`}>
                  Current Events
                </Link>{" "}
              </div>
              {/* <div className="drawerpadding" onClick={onClose}>
                <Link
                  className="drawerpadding"
                  to={`/organizer/panel/${panelid || id}/events/markedevents`}>
                  Marked Events
                </Link>{" "}
              </div>{" "} */}
              <div className="drawerpadding" onClick={onClose}>
                <Link className="drawerpadding" to={`/organizer/events/draftevents`}>
                  Draft Events
                </Link>{" "}
              </div>{" "}
              <div className="drawerpadding" onClick={onClose}>
                <Link className="drawerpadding" to={`/organizer/createevent`}>
                  Create Events
                </Link>{" "}
              </div>
            </Panel>
          </Collapse>
        )}
      </div>

      <div style={{ paddingTop: "5px" }}>
        {notClickable ? (
          <div className="Nav_a" style={{ marginTop: "-6px" }} onClick={ALERT}>
            Panels
          </div>
        ) : (
          <Collapse className="customz" style={{ padding: "0px" }} ghost>
            <Panel className="custom" header="Panels" key="1">
              <div onClick={onClose}>
                <Link className="drawerpadding" state={"panels"} to="/organizer/search/panels">
                  Join Panel
                </Link>
              </div>
              <div className="drawerpadding" onClick={onClose}>
                <Link className="drawerpadding" to="/organizer/panels">
                  My Panels
                </Link>
              </div>
              <div className="drawerpadding" onClick={onClose}>
                <Link className="drawerpadding" to="/organizer/createpanel">
                  Create Panel
                </Link>{" "}
              </div>
            </Panel>
          </Collapse>
        )}
      </div>
      <div className="drawerpadding">
        {!notClickable && <Link to="/organizer/wallet">FlexTokens: {flexTokens}</Link>}
      </div>
      <div className="drawerpadding">
        <Link
          onClick={() => {
            const userDetails = JSON.parse(localStorage.getItem("userDetails"));
            userDetails.role = "Player";
            localStorage.setItem("userDetails", JSON.stringify(userDetails));
            localStorage.removeItem("ProfileID");
          }}
          style={{ color: "var(--green)" }}
          to="/player">
          Switch to Player
        </Link>
      </div>
      <div className="drawerpadding" onClick={onClose}>
        {notClickable ? (
          <div onClick={ALERT}>Settings and Privacy</div>
        ) : (
          <Link
            state={{ currentflag, currentid }}
            to={
              currentflag === "panel" ? `/organizer/panel/${id}/settings` : "/organizer/settings"
            }>
            Settings and Privacy
          </Link>
        )}
      </div>
      <div className="drawerpadding">
        <Link
          to="/signin"
          onClick={() => {
            localStorage.removeItem("ProfileID");
            localStorage.removeItem("userToken");
            localStorage.removeItem("userDetails");
            localStorage.removeItem("panel");
            localStorage.removeItem("viewer");
            localStorage.removeItem("CoverPic");
            localStorage.removeItem("ProfilerPic");
          }}>
          Log out
        </Link>
      </div>
    </Drawer>
  );
};

export default OrganizerDrawer;
