import { KEYS } from "../Keys";

export const GetAllDraftedCampaigns = async () => {
  const response = await fetch(KEYS.API + "/sponsor/campaign/drafted", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    }
  });
  return response;
};

export const GetSingleCampaignWithActivities = async (id) => {
  const response = await fetch(KEYS.API + "/sponsor/campaign/getsinglecampaign", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify({ id })
  });
  return response;
};

// formdata
export const SponsorCreateCampaign = async (values) => {
  const response = await fetch(KEYS.API + "/sponsor/campaign", {
    method: "POST",
    headers: {
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: values
  });
  return response;
};

// formdata
export const SponsorUpdateCampaign = async (values) => {
  const response = await fetch(KEYS.API + "/sponsor/updateCampaign", {
    method: "POST",
    headers: {
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: values
  });
  return response;
};
