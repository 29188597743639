import { Skeleton, List } from "antd";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { Getnotifications } from "../../../Config/API/notifications";
import { checkProfileImage, useGetNotifications, useActions } from "./function";
import { fallbackprofile, getuserlink } from "../../Utils";
import FadeInImage from "../../Images/FadeInImage";

function NotificationMobile() {
  const [loading, setloading] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { get } = useGetNotifications(setloading, Getnotifications, setNotifications);
  const { textChecks } = useActions();

  useEffect(() => {
    get();
    return () => {
      setNotifications([]);
      setloading(null);
    };
  }, []);
  useEffect(() => {
    checkWidth();
    window.addEventListener("resize", checkWidth);
    window.addEventListener("load", checkWidth);
    // replace the timer with get request logic
    let timer = setTimeout(() => setloading(false), 3000);
    return () => {
      window.removeEventListener("resize", checkWidth);
      window.removeEventListener("load", checkWidth);
      clearTimeout(timer);
    };
  }, []);

  const checkWidth = () => {
    if (window.innerWidth > 992 && pathname.includes("notifications")) {
      navigate(-1); //redirects to previous oage
    }
  };
  return (
    <List
      style={{
        padding: "0px 0 10px 0",
        width: "100%",
        margin: "0px auto 0px auto",
        boxShadow: "none"
      }}
      className="NotificationList mobile"
      itemLayout={"horizontal"}
      dataSource={notifications || []}
      loading={loading}
      renderItem={(item) => (
        <List.Item
          className="ListItem"
          style={{
            alignItems: "flex-start",
            flexDirection: "column",
            gap: "5px",
            padding: "10px 40px"
          }}>
          <Skeleton avatar title={false} loading={loading} active>
            <List.Item.Meta
              style={{
                width: "100%",
                alignItems: "baseline",
                flexDirection: "row",
                padding: "10px 5px 0 5px"
              }}
              avatar={
                <div
                  onClick={() =>
                    navigate(getuserlink(item)?.url, { state: getuserlink(item)?.name })
                  }
                  style={{
                    position: "relative",
                    top: "20px",
                    cursor: "pointer"
                  }}>
                  <FadeInImage
                    type={"Fadenavprofile"}
                    radiusAll
                    fallaback={fallbackprofile}
                    src={checkProfileImage(item)}
                  />
                </div>
              }
              title={textChecks(item, navigate, setloading, setNotifications)}
            />

            <div style={{ marginLeft: "50px", color: "#A5ACB8" }}>
              <small>{moment(item?.createdAt).fromNow()}</small>
            </div>
          </Skeleton>
        </List.Item>
      )}
    />
  );
}

export default NotificationMobile;
