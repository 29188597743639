import React, { useState } from "react";
import { Row, Col, Button } from "antd";
import FilterBar from "./FilterBar/index";
import Main from "./Main";

import { Route, Routes } from "react-router-dom";
import PlayerLayout from "../../Components/layout/playerlayout/PlayerLayout";
import OrganizerLayout from "../../Components/layout/organizerLayout/OrganizerLayout";
import { useCurrentPage } from "../hooks/useCurrentPage";

const Settings = () => {
  const [active, setactive] = useState(1);
  const { isTeam, isPanel, isOrganizer, isPlayer } = useCurrentPage();
  var role = "";
  if (isTeam || isPlayer) role = "player";
  else if (isPanel || isOrganizer) role = "organizer";

  return (
    <div style={{ background: "#F5F7F9", minHeight: "80vh" }}>
      <Routes>
        <Route
          element={
            role === "player" ? (
              <PlayerLayout SearchPageStyles />
            ) : (
              <OrganizerLayout SearchPageStyles />
            )
          }>
          <Route
            element={
              <Row
                justify="center"
                style={{ paddingBottom: "20px", alignItems: "stretch" }}
                className="SearchPage">
                <Col xs={24} sm={24} md={24} lg={6}>
                  <FilterBar active={active} setactive={setactive} />
                </Col>
                <Col xs={24} sm={24} md={24} lg={17}>
                  <Main active={active} />
                </Col>

                <Col
                  lg={{
                    span: 18,
                    offset: 6
                  }}
                  md={{
                    offset: 0,
                    span: 24
                  }}
                  sm={{
                    offset: 0,
                    span: 24
                  }}
                  xs={{
                    offset: 0,
                    span: 24
                  }}
                  style={{
                    padding: "30px 20px"
                    // margin: "auto",
                  }}>
                  <Button
                    className="blueButton"
                    type="primary"
                    style={{ width: "min(70% , 350px)", display: "block", margin: "0 auto" }}>
                    Save Changes
                  </Button>
                </Col>
              </Row>
            }
            path="/"></Route>
        </Route>
      </Routes>
    </div>
  );
};

export default Settings;
