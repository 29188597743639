import React from "react";
import { Typography } from "antd";

const { Text, Title } = Typography;

export const Truncate = ({
  text,
  className,
  styles = {},
  lines,
  level = 5,
  title,
  onClick,
  wrapperclassName
}) => {
  return (
    <Typography className={wrapperclassName}>
      {title ? (
        <Title
          onClick={onClick}
          level={level}
          style={styles}
          className={className}
          ellipsis={{
            rows: lines,
            tooltip: {
              title: text,
              destroyTooltipOnHide: true,
              arrowPointAtCenter: true,
              overlayStyle: {
                width: "70vw",
                minWidth: "250px"
              },
              overlayInnerStyle: {
                width: "70vw",
                minWidth: "250px",
                fontSize: "larger"
              }
            }
          }}>
          {text}
        </Title>
      ) : (
        <Text
          onClick={onClick}
          level={level}
          style={styles}
          className={className}
          ellipsis={{
            rows: lines,
            tooltip: {
              title: text,
              destroyTooltipOnHide: true,
              arrowPointAtCenter: true,
              overlayStyle: {
                width: "70vw",
                minWidth: "250px"
              },
              overlayInnerStyle: {
                width: "70vw",
                minWidth: "250px",
                fontSize: "larger"
              }
            }
          }}>
          {text}
        </Text>
      )}
    </Typography>
  );
};
