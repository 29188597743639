import React, { Fragment, useState } from "react";
import { Image } from "antd";
import { KEYS } from "../../../../Config/Keys";

const RequestCard = ({ data }) => {
  const [visible, setVisible] = useState(false);

  return (
    <Fragment>
      <div className="pomemberShadow membercard">
        <div className="popadd">
          <img
            className="poprofilepic"
            src={KEYS.API + "/" + data?.player?.profilepic}
            onClick={() => setVisible(true)}
            height="100px"
            width="100px"
            alt="img"></img>
        </div>
        <div style={{ display: "none" }}>
          <Image
            preview={{
              visible: visible,
              onVisibleChange: (vis) => setVisible(vis)
            }}
            src="/images/profilepic.png"
            height="150px"
            width="150px"></Image>
        </div>

        <p className="namepo">{data?.player?.name}</p>
        <p className="nameporesp">Waiting for captain to accept join request...</p>
      </div>
    </Fragment>
  );
};

export default RequestCard;
