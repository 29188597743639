import React, { Fragment } from "react";
import { Avatar } from "antd";
import { checkProfileImage, checkProfileName, fallbackprofile } from "../../../Utils/index";
import moment from "moment";
import { useEffect } from "react";
import { KEYS } from "../../../../Config/Keys";
import { useNavigate } from "react-router-dom";
import { useCurrentPage } from "../../../hooks/useCurrentPage";
function UserInfo({ data, setloading }) {
  useEffect(() => {
    let i = new Image();
    i.src = KEYS.API + "/" + data?.file?.file;
    i.onload = () => {
      setloading(false);
      i = null;
    };
    i.onerror = () => {
      setloading(false);
      i = null;
    };
  }, [data]);

  const navigate = useNavigate();
  const { isEvent } = useCurrentPage();
  return (
    <Fragment>
      <Avatar
        className="profilepic timelineavatar"
        src={
          <img
            src={checkProfileImage(data) || "-"}
            onClick={() => {
              if (isEvent) {
                navigate(`/view/organizer/${data?.user?.organizer?._id}/timeline`);
              }
            }}
            alt=""
            onErrorCapture={(e) => {
              e.currentTarget.src = fallbackprofile;
              e.currentTarget.style.transform = "scale(1.2)";
            }}
          />
        }
      />
      <div>
        <span
          onClick={() => {
            if (isEvent) {
              navigate(`/view/organizer/${data?.user?.organizer?._id}/timeline`);
            }
          }}
          style={{ fontWeight: 600, fontSize: "16px" }}>
          {checkProfileName(data) || "-"}
        </span>
        <p className="timelinedate">{moment(data?.createdAt).fromNow()}</p>
      </div>
    </Fragment>
  );
}

export default UserInfo;
