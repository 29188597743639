import { KEYS } from "../Keys";

export const Getnotifications = async (values) => {
  const response = await fetch(KEYS.API + "/notification/getallnotifications", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify({ ...values })
  });
  return response;
};

// notification/deletenotification

export const Deletenotifications = async (values) => {
  const response = await fetch(KEYS.API + "/notification/deletenotification", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};
