import React, { Fragment, useState } from "react";
import { Image, Button } from "antd";
import { KEYS } from "../../../../Config/Keys";
import { fallbackprofile } from "../../../../common/Utils";
import FadeInImage from "../../../../common/Images/FadeInImage";
import { AccepJoinTeamRequest, CancelOrDeleteTeamJoinRequest } from "../../../../Config/API/teams";
import { error } from "../../../../common/Alerts/Message";
import { useNavigate } from "react-router-dom";
import EditModalCard from "./EditModalCard";

const RequestCard = ({ teamId, player, loading, setteam, setLoading, setstatus }) => {
  const [visible, setVisible] = useState(false);
  const [roleModal, setRoleModal] = useState(false);
  const navigate = useNavigate();

  const handleAcceptRejectInvitation = async (flag) => {
    setLoading(true);
    let res = "";
    const payload = {
      teamId: teamId,
      id: player?.player?._id
    };
    if (flag) {
      res = await AccepJoinTeamRequest(payload);
    } else {
      res = await CancelOrDeleteTeamJoinRequest(payload);
    }

    const response = await res.json();

    if (response?.type === "success") {
      setLoading(false);
      if (flag) {
        setRoleModal(true);
      } else {
        window.location.reload();
      }
    } else {
      error("Error Occurred");
    }
    setLoading(false);
  };

  return (
    <Fragment>
      <div className="pomemberShadow membercard">
        <div
          onClick={() =>
            navigate(`/view/player/${player?.player?._id}/timeline`, { state: "player" })
          }>
          <div className="popadd">
            <FadeInImage
              src={KEYS.API + "/" + player?.player?.profilepic}
              className="poprofilepic"
              fallaback={fallbackprofile}
              type={"Fadegcard"}
            />
          </div>
          <div style={{ display: "none" }}>
            <Image
              preview={{
                visible: visible,
                onVisibleChange: (vis) => setVisible(vis)
              }}
              src={KEYS.API + "/" + player?.player?.profilepic}
              height="150px"
              width="150px"></Image>
          </div>
          <p className="namepo">{player?.player?.name}</p>
        </div>
        <div className="pobuttons">
          <Button
            disabled={loading}
            className="rejpo"
            type="primary"
            onClick={() => handleAcceptRejectInvitation(false)}>
            <img
              alt=""
              style={{ margin: "-5px 8px 0px 0px" }}
              src="/images/icons/decline.png"
              height="16px"
              width="16px"
            />
            Reject
          </Button>
          <Button
            disabled={loading}
            className="accpo"
            type="primary"
            onClick={() => handleAcceptRejectInvitation(true)}>
            <img
              alt=""
              style={{ margin: "-5px 8px 0px 0px" }}
              src="/images/icons/approve.png"
              height="16px"
              width="16px"
            />
            Accept
          </Button>
        </div>
      </div>

      {/* assign role */}
      {roleModal && (
        <EditModalCard
          r={player?.role}
          open={roleModal}
          setopen={setRoleModal}
          playerId={player?.player?._id}
          setteam={setteam}
          setstatus={setstatus}
          closable={false}
        />
      )}
    </Fragment>
  );
};

export default RequestCard;
