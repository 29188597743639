import React, { useEffect, useState } from "react";
import { Modal, InputNumber, Typography, Button, Form, Upload, message, Space } from "antd";
import { UserWalletTopUp } from "../../Config/API/Wallets";
import { error, success } from "../../common/Alerts/Message";
import { TRANSACTION_CONSTANTS } from "../../Config/helpers/constants";
import { BiUpload } from "react-icons/bi";
import AdminAccountInfoModal from "./AdminAccountInfoModal";
const { Title } = Typography;

function TopUpModal({ open, setOpen, userId, userType, filter, getUserTransactionHistory }) {
  const [formData, setFormData] = useState({
    amount: 0,
    file: "",
    fileList: ""
  });
  const [loading, setLoading] = useState(false);

  const handleAmountChange = (value) => {
    setFormData({
      ...formData,
      amount: value || 0
    });
  };

  const handleFileChange = (file) => {
    if (file?.file?.status !== "uploading") {
      const isAllowed = ["image/png", "image/jpg", "image/jpeg", "application/pdf"].includes(
        file?.file?.type
      );
      if (isAllowed) {
        setFormData({
          ...formData,
          file: [file?.file],
          fileList: file?.fileList
        });
      }
    }
  };

  useEffect(() => {
    return () => {
      setFormData({
        amount: 0,
        file: [],
        setFile: []
      });
    };
  }, []);

  const walletTopUp = async () => {
    try {
      setLoading(true);
      const response = await UserWalletTopUp({
        userId,
        type: TRANSACTION_CONSTANTS.topUp,
        values: formData,
        ownerType: userType
      });
      const data = await response.json();
      if (data?.type === "success") {
        success("Transaction initiated Successfully");
        // get latest transactions again
        await getUserTransactionHistory(filter);
        setFormData({
          amount: 0,
          file: [],
          setFile: []
        });
        setOpen(false);
      } else {
        error(data?.result?.message || data?.result?.error || data?.result);
      }
    } catch (e) {
      console.log(e);
      error("Error Occurred while top up");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title="Wallet Topup"
      className="editPost AddTeamModel"
      confirmLoading
      mask
      onCancel={() => {
        setOpen(false);
      }}
      closable={false}
      centered
      destroyOnClose={true}
      open={open}
      footer={false}
      bodyStyle={{
        padding: "0px 0px 20px 0px"
      }}>
      <AdminAccountInfoModal />

      <Title level={3}>Enter Amount</Title>
      <InputNumber
        style={{ width: "100%" }}
        placeholder="Enter amount to top up"
        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
        onChange={(e) => handleAmountChange(e)}
        disabled={loading}
        value={formData?.amount || 0}
      />
      {/* upload */}
      <Space direction="vertical">
        <Form.Item
          label="Upload Supporting Document"
          validateStatus={formData?.fileList?.length > 0 ? "success" : "error"}>
          <Upload
            name="file"
            fileList={formData?.fileList || []}
            beforeUpload={(file) => {
              const isAllowed = [
                "image/png",
                "image/jpg",
                "image/jpeg",
                "application/pdf"
              ].includes(file?.type);
              if (!isAllowed) {
                message.error("You can only upload PNG, JPG, JPEG, or PDF files!");
              } else {
                setFormData({
                  ...formData,
                  file: [],
                  fileList: []
                });
              }
              return false;
            }}
            onChange={(file) => handleFileChange(file)}
            onRemove={() => {
              setFormData({
                ...formData,
                file: [],
                fileList: []
              });
            }}>
            <Button icon={<BiUpload />}>Click to Upload</Button>
          </Upload>
        </Form.Item>
      </Space>
      {formData?.fileList?.length > 0 ? null : (
        <Typography.Text type="danger" className="m-0">
          Please upload supporting document
        </Typography.Text>
      )}
      <Button
        type="primary"
        onClick={walletTopUp}
        loading={loading}
        disabled={loading || formData?.amount <= 0 || !formData?.fileList?.length > 0}>
        Top Up
      </Button>
    </Modal>
  );
}

export default TopUpModal;
