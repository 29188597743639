import React from "react";
import { Route, Routes } from "react-router-dom";
import PlayerEventsHistory from "./PlayerEventsHistory";
import PlayerTLayout from "../../layout/playerTLLayout/PlayerTLayout";
import "../../../styles/organizer/TimelineOrganizer.css";

const Timeline = () => {
  return (
    <Routes>
      <Route path="/" element={<PlayerTLayout />}>
        <Route index path="/" element={<PlayerEventsHistory />} />
      </Route>
    </Routes>
  );
};

export default Timeline;
