import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { GetSingleOrganizer, GetProfileAPI } from "../../Config/API/organizer";
import { GetSinglePlayer, PlayerGetProfileAPI } from "../../Config/API/player";
import { setProfile as set } from "../../Store/Features/ProfileSlice";
import { error } from "../Alerts/Message";

export function useFetchProfileFunc() {
  const [profile, setprofile] = useState({});
  const [loading, setloading] = useState(false);
  const dispatch = useDispatch();
  var counter = 0,
    limit = 3,
    flag = false;
  useEffect(() => {
    return () => {
      flag = true;
    };
  }, []);

  //settings
  const GetProfiles = async (id, state) => {
    try {
      if (!id) throw new Error(state + "id error");
      let response, data;
      setloading(true);
      if (state === "player") {
        response = await GetSinglePlayer(id);
        data = await response.json();
        console.log("here1");
      } else if (state === "organizer") {
        response = await GetSingleOrganizer(id);
        data = await response.json();
      }
      if (data?.type === "success") {
        const d = {
          ...data?.result,
          _id: data?.result?._id,
          name: data?.result?.name,
          profileType: data?.result?.profileType
        };
        setloading(false);
        if (!flag) setprofile(d);
        return;
        // window.dispatchEvent(new Event("storage"));
      }
      setloading(false);
      return null;
    } catch (e) {
      counter++;
      if (counter < limit) GetProfiles();
      else {
        console.log(e);
        error(e.message || e);
        setloading(false);
      }
    }
  };

  const SetInititalProfile = async (state) => {
    try {
      let res, data;
      setloading(true);
      if (state === "player") {
        res = await PlayerGetProfileAPI();
      } else if (state === "organizer") {
        res = await GetProfileAPI();
      }
      data = await res.json();
      if (data.type !== "success") {
        if (counter < limit) SetInititalProfile();
      } else {
        // setProfile(data?.result || [])
        if (data?.data === true) {
          dispatch(
            set({
              ...data?.profile,
              profile: data?.profile?.profilepic,
              cover: data?.profile?.coverpic
            })
          );
          console.log(data);
        }
      }
      setloading(false);
    } catch (e) {
      counter++;
      if (counter < limit) SetInititalProfile();
      else {
        setloading(false);
        console.log(e);
        error(e.message);
      }
    }
  };

  return {
    GetProfiles,
    profile,
    setprofile,
    loading,
    setloading,
    SetInititalProfile
  };
}
