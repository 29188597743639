import React, { Fragment, useState, useEffect } from "react";
import { Col, Row } from "antd";
import TopBar from "./TopBar";
import { SideBar } from "../../../../../../common/sidebar";
import Discussion from "./Discussion";
import { useLocation, useParams } from "react-router-dom";
import { EventRegisteredTeams } from "../../../../../../common/righbar/Righbar";
import { GetSingleEvent } from "../../../../../../Config/API/events";
import Loader from "../../../../../../common/cards/Loader";
import {
  setEVENT,
  load,
  unload,
  EVENT,
  isLoading
} from "../../../../../../Store/Features/EventSlice";
import { useDispatch, useSelector } from "react-redux";
import useVariables from "../../../../../../common/hooks/useVariables";
// import { Info } from "../../../../../../common/Alerts/Message";
import { useCurrentPage } from "../../../../../../common/hooks/useCurrentPage";

const ViewEvent = () => {
  const [isOrg, setisOrg] = useState(""); //event organizer
  const { id } = useParams();

  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const event = useSelector(EVENT);
  const loading = useSelector(isLoading);

  const { viewer } = useVariables();
  const { isOrgEvent } = useCurrentPage();

  // const navigate = useNavigate()

  const get = async () => {
    try {
      dispatch(load());
      const res = await GetSingleEvent({ eventId: id });
      const data = await res.json();

      const panel = localStorage.getItem("panel");
      if (data?.result?.panel?._id !== panel && isOrgEvent) {
        // Info("You are not the owner of this event.")
        // navigate(-1)
      }

      if (data?.type === "success") {
        dispatch(setEVENT(data?.result));
        setisOrg(pathname.includes("organizer") && data?.result?.organizer?._id === viewer?.id);
      }
      dispatch(unload());
    } catch (e) {
      dispatch(unload());
    }
  };

  useEffect(() => {
    get();

    return () => {
      setisOrg(null);
    };
  }, []);

  return loading ? (
    <Loader fullpage loading={"white"} text={"Please Wait fetching Event details"} />
  ) : (
    <Fragment>
      <TopBar data={event} eventId={id} isOrg={isOrg} />

      <Row className="rowPadding">
        <SideBar about location fullwidthEvent data={event} type="event" />

        <Col
          xs={{ span: 24, order: 3 }}
          sm={{ span: 24, order: 3 }}
          md={{ span: 24, order: 3 }}
          lg={{ span: 11, order: 2 }}>
          {!loading && Object.keys(event).length > 0 && (
            <Discussion event={event} eventId={id} isOrg={isOrg} />
          )}
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 6, order: 3 }}
          className="scroll">
          <EventRegisteredTeams teams={event?.registerteams} />
        </Col>
      </Row>
    </Fragment>
  );
};

export default ViewEvent;
