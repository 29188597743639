import { KEYS } from "../Keys";

export const CreatePost = async (values) => {
  const response = await fetch(KEYS.API + "/post/createpost", {
    method: "POST",
    headers: {
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: values
  });
  return response;
};

export const GetPosts = async (user, flag, page = 1, limit = 10) => {
  const url = `/post/newsfeed?page=${page}&limit=${limit}`;
  const response = await fetch(KEYS.API + url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify({
      flag,
      user,
      page // Include page in the request body
    })
  });
  return response;
};

export const GetPlayerPosts = async () => {
  const response = await fetch(KEYS.API + `/post/getposts?role=player`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    }
  });
  return response;
};

export const PostComment = async (values) => {
  const response = await fetch(KEYS.API + "/post/commentpost", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

export const CommentReply = async (values) => {
  const response = await fetch(KEYS.API + "/post/replycommentpost", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

export const PostLike = async (values) => {
  const response = await fetch(KEYS.API + "/post/likepost", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

export const PostUnlike = async (values) => {
  const response = await fetch(KEYS.API + "/post/unlikepost", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

export const EditComment = async (values) => {
  const response = await fetch(KEYS.API + "/post/editcommentpost", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

export const EditReply = async (values) => {
  const response = await fetch(KEYS.API + "/post/editreplycommentpost", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

export const DeleteComment = async (values) => {
  const response = await fetch(KEYS.API + "/post/deletecommentpost", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

export const DeleteReply = async (values) => {
  const response = await fetch(KEYS.API + "/post/deletereplycommentpost", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

export const EditPostWithMedia = async (values) => {
  const response = await fetch(KEYS.API + "/post/updatepost", {
    method: "POST",
    headers: {
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: values
  });
  return response;
};
export const EditPostWithoutMedia = async (values) => {
  const response = await fetch(KEYS.API + "/post/updateposttext", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};
