import { useLocation, useNavigate } from "react-router-dom";

export const LocalStorageCheck = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const listener = () => {
    const user = JSON.parse(localStorage.getItem("userDetails"))?.role;
    const token = localStorage.getItem("userToken");
    const viewer = JSON.parse(localStorage.getItem("viewer"));
    const role = String(user)?.toLowerCase();

    const loggedout = user === undefined || !token;
    const authenticating = pathname.includes("signin") || pathname.includes("register");
    const loggedin = user !== undefined && token;
    const isOrg = pathname.includes("organizer");
    const isPlayer = pathname.includes("player");
    const isSearching = pathname.includes("search");
    const isLanding = pathname === "/";

    if (loggedout && (isPlayer || isOrg) && !authenticating) navigate("/signin");
    else if (loggedin) {
      if (isPlayer || isOrg) return;
      if (isLanding) navigate(`/${role}`);
    } else if (!isSearching) {
      if ((isOrg && viewer?.flag !== "organizer") || (isPlayer && viewer?.flag !== "player")) {
        // navigate(`/${role}/`)
      }
    }
  };
  window.addEventListener("storage", listener);
  window.addEventListener("load", listener);
  listener();

  return null;
};
