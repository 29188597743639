import React from "react";
import { useEffect } from "react";
import { Input, Form, Button, Row, Col } from "antd";
import { useState } from "react";
import { error } from "../../../../Alerts/Message";
const { TextArea } = Input;
function CommentForm({ onFinish, postId, commentId, loading, status }) {
  const [val, setval] = useState("");
  const [id, setid] = useState("");
  const [form] = Form.useForm();
  useEffect(() => {
    return () => {
      setval(null);
      setid(null);
    };
  }, []);
  const handlesubmit = async () => {
    if (val !== null && String(val).trim().length !== 0) {
      setid(commentId);
      await onFinish({
        val,
        postId: postId || null,
        commentId: commentId || null
      });
      form.resetFields();
      setval(null);
      setid(null);
    } else {
      // Swal.fire({
      //     icon: "error",
      //     title: "Add something in the text field before proceeding"
      // })

      error("Add something in the text field before proceeding");
    }
  };

  return (
    <Form form={form} onFinish={handlesubmit}>
      <Row justify="space-between" style={{ flexWrap: "wrap", gap: 10 }}>
        <Col span={24}>
          <Form.Item name={"text"} style={{ margin: "15px 0 0 0px" }}>
            <TextArea
              value={val}
              onChange={(e) => setval(e.currentTarget.value)}
              style={{ borderRadius: "10px" }}
              rows={3}
              placeholder="write comment..."
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            style={{
              margin: "15px 0 0 0px",
              display: "flex",
              justifyContent: "flex-end"
            }}>
            <Button
              loading={id === commentId ? true : false}
              htmlType="submit"
              type="primary"
              style={{
                fontSize: "11px",
                borderRadius: "20px",
                width: loading && id === commentId && "60px"
              }}>
              {id === commentId ? "" : status}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export default CommentForm;
