import React, { Fragment, useState } from "react";
import { Row, Col } from "antd";
import RegisterTeamCard from "../Cards/RegisterTeamCard";

import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse } from "antd";

import Modal from "./Modal";
import NoData from "../../cards/noData";
const { Panel } = Collapse;

const EventRegisteredTeamsDesktop = ({ title, teams, setModalVisible }) => {
  return (
    <Fragment>
      <Row className="sideItem">
        <Col
          style={{
            padding: ".5rem .5rem",
            backgroundColor: "#FFFFFF",
            borderRadius: "8px",
            paddingBottom: teams?.length > 0 ? "4%" : "0%"
          }}
          lg={24}
          xs={24}
          sm={24}
          md={24}>
          <h1
            style={{
              marginBottom: teams?.length > 0 ? "inital" : ".22%"
            }}
            className="timelineAboutpadding">
            {teams?.length === 0 ? (
              <NoData noShadow noTeam />
            ) : (
              <div className="truncate truncate2">{title}</div>
            )}
            {teams?.length > 4 && (
              <span
                onClick={() => setModalVisible(true)}
                style={{
                  color: "#0085FF",
                  float: "right",
                  fontWeight: "100",
                  cursor: "pointer",
                  userSelect: "none",
                  fontSize: "small",
                  paddingRight: "4px",
                  paddingTop: "3.4px"
                }}>
                See All
              </span>
            )}
          </h1>

          {teams?.map((e, i) => {
            return i < 4 && <RegisterTeamCard team={e} key={i} />;
          })}
        </Col>
      </Row>
    </Fragment>
  );
};

const EventsRegisteredTeamsAccordion = ({ title, teams, setModalVisible }) => (
  <Collapse
    bordered={false}
    defaultActiveKey={["Registered Teams"]}
    expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
    className="site-collapse-custom-collapse  sideItem">
    <Panel
      header={title}
      key="1"
      style={{ position: "relative" }}
      className="site-collapse-custom-panel ">
      <Fragment>
        {teams?.length > 4 && (
          <h4
            onClick={() => setModalVisible(true)}
            style={{
              padding: "0% 3.5%",
              textAlign: "right",
              color: "#0085FF",
              float: "right",
              fontWeight: "400",
              cursor: "pointer",
              userSelect: "none",
              position: "absolute",
              top: "8px",
              right: "15px"
            }}>
            See All
          </h4>
        )}
      </Fragment>

      {teams?.length === 0 ? <NoData noShadow width="50%" noText /> : null}
      {teams?.map((e, i) => {
        return i < 4 && <RegisterTeamCard team={e} key={i} />;
      })}
    </Panel>
  </Collapse>
);

function EventRegisteredTeams({ teams }) {
  const [modalVisible, setModalVisible] = useState(false);
  const normalRegisteredTeams = teams?.filter((team) => !team?.wildCard);
  const wildCardRegisteredTeams = teams?.filter((team) => team?.wildCard);
  return (
    <Fragment>
      <div className="d-none d-md-block ">
        <EventsRegisteredTeamsAccordion
          teams={normalRegisteredTeams}
          title="Normal Registered Teams"
          setModalVisible={setModalVisible}
        />
      </div>
      <div className="d-md-none">
        <EventRegisteredTeamsDesktop
          teams={normalRegisteredTeams}
          title="Normal Registered Teams"
          setModalVisible={setModalVisible}
        />
      </div>

      {wildCardRegisteredTeams?.length > 0 && (
        <>
          {" "}
          <div className="d-none d-md-block ">
            <EventsRegisteredTeamsAccordion
              teams={wildCardRegisteredTeams}
              title="Wild Card Registered Teams"
              setModalVisible={setModalVisible}
            />
          </div>
          <div className="d-md-none">
            <EventRegisteredTeamsDesktop
              teams={wildCardRegisteredTeams}
              title="Wild Card Registered Teams"
              setModalVisible={setModalVisible}
            />
          </div>
        </>
      )}
      {modalVisible === true && (
        <Modal modalVisible={modalVisible} setModalVisible={setModalVisible} teams={teams} />
      )}
    </Fragment>
  );
}

export default EventRegisteredTeams;
