import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useState } from "react";
import { useEffect } from "react";
import { Col, Button } from "antd";
import { error, success } from "../../common/Alerts/Message";
import { PanelDeleteFollower, PanelDeleteFollowRequest } from "../../Config/API/panel";
import { TeamDeleteFollower, TeamDeleteFollowRequest } from "../../Config/API/teams";
import { PlayerDeleteFollower, PlayerDeleteFollowRequest } from "../../Config/API/player";
import { OrganizerDeleteFollower, OrganizerDeleteFollowRequest } from "../../Config/API/organizer";

export const get = async (setData, setloading, id, Api, dispatch) => {
  try {
    if (setloading) setloading(true);
    // const res = await Api({ id: id })
    const res = await Api(id);
    const data = await res.json();
    console.log("At follow compoent ", data);
    const d = {
      name: data?.result?.name,
      profile: data?.result?.profilepic,
      cover: data?.result?.coverpic,
      _id: data?.result?._id,
      followers: data?.result?.followers,
      followings: data?.result?.followings,
      user: data?.result?.user,
      description: data?.result?.description
    };
    if (data?.type === "success") {
      dispatch(setData(d));
    }
    if (setloading) setloading(false);
  } catch (e) {
    if (setloading) setloading(false);
    console.log(e);
  }
};

export const FollowFollowingButtons = ({ setloading, state, setactive, active, current }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [followerLink, setfollowerLink] = useState("");
  const [followingLink, setfollowingLink] = useState("");
  const { id } = useParams();
  const prepareLinks = () => {
    try {
      setloading(true);
      const view = pathname.includes("view");
      if (pathname.includes("view")) {
        setfollowerLink(`/view/${current}/${id}/followers`);
        setfollowingLink(`/view/${current}/${id}/followings`);
      } else if (!view && pathname.includes("team")) {
        setfollowerLink(`/player/team/${id}/followers`);
        setfollowingLink(`/player/team/${id}/followings`);
      } else if (!view && pathname.includes("panel")) {
        setfollowerLink(`/organizer/panel/${id}/followers`);
        setfollowingLink(`/organizer/panel/${id}/followings`);
      } else if (!view && pathname.includes("player") && !pathname.includes("teams")) {
        setfollowerLink(`/player/followers`);
        setfollowingLink(`/player/followings`);
      } else if (!view && pathname.includes("organizer") && !pathname.includes("panel")) {
        console.log("here");
        setfollowerLink(`/organizer/followers`);
        setfollowingLink(`/organizer/followings`);
      }
      setloading(false);
    } catch (e) {
      setloading(false);
      console.log(e);
      error(e);
    }
  };
  useEffect(() => {
    prepareLinks();
  }, []);
  return (
    <Col lg={14} md={20} sm={20} xs={24}>
      <Button
        className="buttonpo mdWidthButton"
        style={{ marginTop: "0" }}
        onClick={() => {
          setactive(1);
          navigate(followerLink, { state });
        }}
        type={active === 1 && "primary"}>
        Followers
      </Button>
      {!pathname.includes("team") &&
      !pathname.includes("panel") &&
      state !== "panel" &&
      state !== "team" ? (
        <Button
          className="buttonpo mdWidthButton"
          style={{ marginTop: "0" }}
          onClick={() => {
            setactive(2);
            navigate(followingLink, { state });
          }}
          type={active === 2 && "primary"}>
          Following
        </Button>
      ) : null}
    </Col>
  );
};

// player/organizer remove followers
export const removeFollower = async (id, setitem, flag, setloading, dispatch) => {
  try {
    const viewer = JSON.parse(localStorage.getItem("viewer"));
    console.log({
      "my id": viewer?.id,
      "follower profile id": id,
      "follower flag": flag
    });
    if (id && flag && viewer?.id) {
      setloading(true);
      const current = viewer?.flag;
      let response;
      if (current === "team") {
        console.log("here team call");

        response = await TeamDeleteFollower({
          teamId: viewer?.id,
          followerId: id,
          flag
        });
      } else if (current === "player") {
        console.log("here player call");
        response = await PlayerDeleteFollower({
          playerId: viewer?.id,
          followerId: id,
          flag
        });
      } else if (current === "organizer") {
        console.log("here organizer call");

        response = await OrganizerDeleteFollower({
          organizerId: viewer?.id,
          followerId: id,
          flag
        });
      } else if (current === "panel") {
        console.log("here panel call");
        response = await PanelDeleteFollower({
          panelId: viewer?.id,
          followerId: id,
          flag
        });
      }
      const data = await response.json();
      console.log("Delete Follower api : ", data);
      if (data.type === "success") {
        success(data?.result);
        const d = {
          ...data?.data,
          cover: data?.data?.coverpic,
          profile: data?.data?.profilepic
        };
        console.log(d);
        // setitem(d)
        dispatch(setitem(d));
      } else {
        error("request error");
      }

      setloading(false);
    } else {
      throw new Error("Try again later");
    }
  } catch (e) {
    console.log(e);
    error(e.message);
  }
};

//player/organizer remvoe followings
export const unFollow = async (item, setitem, name, setloading, type, dispatch) => {
  try {
    const viewer = JSON.parse(localStorage.getItem("viewer"));
    let id = item?._id;
    console.log(id, name, viewer?.id, type);
    if (id && name && viewer?.id) {
      setloading(true);
      const current = viewer?.flag;
      let response;
      if (current === "team") {
        response = await TeamDeleteFollowRequest({
          followerId: viewer?.id,
          followingId: id,
          flag: name,
          type
        });
      } else if (current === "player") {
        response = await PlayerDeleteFollowRequest({
          followerId: viewer?.id,
          followingId: id,
          flag: name,
          type
        });
      } else if (current === "organizer") {
        response = await OrganizerDeleteFollowRequest({
          followerId: viewer?.id,
          followingId: id,
          flag: name,
          type
        });
      } else if (current === "panel") {
        response = await PanelDeleteFollowRequest({
          followerId: viewer?.id,
          followingId: id,
          flag: name,
          type
        });
      }
      const data = await response.json();
      if (data.type === "success") {
        success(data?.result);
        const d = {
          ...data?.data,
          cover: data?.data?.coverpic,
          profile: data?.data?.profilepic
        };
        dispatch(setitem(d));
      } else {
        error("request error");
      }

      setloading(false);
    } else {
      throw new Error("Try again later");
    }
  } catch (e) {
    console.log(e);
    error(e.message);
  }
};

//Team /panel Followers
export const useDeleteFollower = () => {
  const { pathname } = useLocation();
  const { id } = useParams();
  const removeGroupFollower = async (pid, setitem, flag, setloading, dispatch) => {
    try {
      console.log({
        "my id": id,
        "follower profile id": pid,
        "follower flag": flag
      });
      if (pid && flag && id) {
        setloading(true);
        const current = pathname.includes("team") ? "team" : "panel";
        let response;
        if (current === "team") {
          console.log("here team call");

          response = await TeamDeleteFollower({
            teamId: id,
            followerId: pid,
            flag
          });
        } else if (current === "panel") {
          console.log("here panel call");
          response = await PanelDeleteFollower({
            panelId: id,
            followerId: pid,
            flag
          });
        }
        const data = await response.json();
        console.log("Delete Follower api : ", data);
        if (data.type === "success") {
          success(data?.result);
          const d = {
            ...data?.data,
            cover: data?.data?.coverpic,
            profile: data?.data?.profilepic,
            profileId: data?.data?.profile?._id || data?.data?.profile
          };
          // setitem(d)
          dispatch(setitem(d));
        } else {
          error("request error");
        }

        setloading(false);
      } else {
        throw new Error("Try again later");
      }
    } catch (e) {
      console.log(e);
      error(e.message);
    }
  };

  const unfollowGroupFollowing = async (item, setitem, name, setloading, type, dispatch) => {
    try {
      let itemid = item?._id;
      console.log(itemid, name, id, type);
      if (itemid && name && id) {
        setloading(true);
        const current = pathname.includes("team") ? "team" : "panel";
        let response;
        if (current === "team") {
          console.log("here team call");
          console.log({
            "my id": id,
            "this profile id": itemid,
            flag: name,
            type
          });
          response = await TeamDeleteFollowRequest({
            followerId: id,
            followingId: itemid,
            flag: name,
            type
          });
        } else if (current === "panel") {
          console.log("here panel call");
          response = await PanelDeleteFollowRequest({
            followerId: id,
            followingId: itemid,
            flag: name,
            type
          });
        }
        const data = await response.json();
        console.log("Unfollow api : ", data);
        if (data.type === "success") {
          success(data?.result);
          const d = {
            ...data?.data,
            cover: data?.data?.coverpic,
            profile: data?.data?.profilepic,
            profileId: data?.data?.profile?._id || data?.data?.profile
          };
          dispatch(setitem(d));
        } else {
          error("request error");
        }

        setloading(false);
      } else {
        throw new Error("Try again later");
      }
    } catch (e) {
      console.log(e);
      error(e.message);
    }
  };
  return { removeGroupFollower, unfollowGroupFollowing };
};
