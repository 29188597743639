import { Button } from "antd";
import React, { Fragment } from "react";

function MatchTeam({ t, item, loading, Orderloading, assignWinner }) {
  const Id_1 = item?.match?.team1?._id;
  const Id_2 = item?.match?.team2?._id;
  const win = item?.match?.win;
  const pendingwin = String(win).trim().length === 0 || win === undefined;
  const classes = "btnMobileDimensions draftbutton equalWidhtButton m-0";

  return (
    <div className="MatchCardTeamInfo">
      <div>
        {t === 1 ? item?.match?.team1?.name : item?.match?.team2?.name}
        {win !== "draw" ? (
          <Fragment>
            {win === (t === 1 ? Id_1 : Id_2) ? (
              <p style={{ color: "var(--green)" }}>(Winner)</p>
            ) : null}
            {win === (t === 1 ? Id_2 : Id_1) ? (
              <p style={{ color: "var(--red)" }}>(loser)</p>
            ) : null}
          </Fragment>
        ) : null}
      </div>
      {pendingwin ? (
        <Button
          loading={loading}
          type="primary"
          onClick={() => {
            if (!Orderloading && !loading) {
              if (!win || win === undefined || win === "") assignWinner(item, t);
            }
          }}
          className={`${classes}  ${
            Orderloading || win === (t === 1 ? Id_2 : Id_1) ? "GREY" : undefined
          } `}>
          {loading ? "" : "Winner"}
        </Button>
      ) : (
        <Button
          loading={loading}
          type="primary"
          style={{ opacity: "0", userSelect: "none" }}
          className={`${classes}  ${
            Orderloading || win === (t === 1 ? Id_2 : Id_1) ? "GREY" : undefined
          } `}>
          Winner
        </Button>
      )}
    </div>
  );
}

export default MatchTeam;
