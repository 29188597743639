import { AssignDKWinner, ReAssignDKOWinner } from "../../../../../../../Config/API/events";
import { error, success } from "../../../../../../../common/Alerts/Message";
import {
  setRoundes,
  load,
  unload,
  ROUNDES,
  setloserrounds,
  setfinalistrounds,
  setIsAgainMatch
} from "../../../../../../../Store/Features/kobracketslice";
import { Seed, SeedItem, SeedTeam, SingleLineSeed } from "react-brackets";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { Fragment } from "react";
import { Button } from "antd";
import moment from "moment";
import { useState } from "react";
import { Truncate } from "../../../../../../../common/Text/Truncate";
import { useContext } from "react";

export const useDKOBYESEED = () => {
  const [edit, setedit] = useState([]);
  const { id } = useParams();
  const rounds = useSelector(ROUNDES);
  const loserrounds = useSelector((state) => state.ko.loserbrackets);
  const IsAgainMatch = useSelector((state) => state.ko.againMatch);
  const dispatch = useDispatch();

  const assignbracketswinner = async (M_id, T_id, flag) => {
    try {
      dispatch(load());
      var response = null,
        data = null,
        values = null;
      values = {
        eventId: id,
        matchId: M_id,
        winner: T_id,
        flag: flag
      };
      console.log(values);

      response = await AssignDKWinner(values);
      data = await response.json();
      console.log(data);
      if (data?.type === "success") {
        success("winner Assigned Successfully!");
        const { winnerrounds, loserrounds, finalistrounds } = data.result;
        console.log({ winnerrounds, loserrounds, finalistrounds });
        dispatch(setloserrounds(loserrounds));
        dispatch(setRoundes(winnerrounds));
        dispatch(setIsAgainMatch(data?.data?.is2Matches));
        if (data.data.is2Matches) {
          dispatch(setfinalistrounds(finalistrounds));
        } else {
          const filter = finalistrounds.filter((e, i) => i == 0 && e);
          dispatch(setfinalistrounds(filter));
        }
      } else {
        error(data?.result);
      }
      dispatch(unload());
    } catch (e) {
      dispatch(unload());
      console.log(e);
      error(e.message);
    }
  };

  const Reassignbracketswinner = async (M_id, T_id, reassign, flag) => {
    try {
      dispatch(load());
      var response = null,
        data = null,
        values = null;
      values = {
        eventId: id,
        matchId: M_id,
        winner: T_id,
        reassign,
        flag
      };
      console.log(values);

      response = await ReAssignDKOWinner(values);
      data = await response.json();
      console.log(data);
      if (data?.type === "success") {
        success("winner Re-Assigned Successfully!");
        const { winnerrounds, loserrounds, finalistrounds } = data.result;
        dispatch(setloserrounds(loserrounds));
        dispatch(setRoundes(winnerrounds));
        dispatch(setIsAgainMatch(data?.data?.is2Matches));
        if (data.data.is2Matches) {
          dispatch(setfinalistrounds(finalistrounds));
        } else {
          const filter = finalistrounds.filter((e, i) => i == 0 && e);
          dispatch(setfinalistrounds(filter));
        }
        setedit(edit.filter((e) => e !== M_id));
      } else {
        error(data?.result);
      }
      dispatch(unload());
    } catch (e) {
      dispatch(unload());
      console.log(e);
      error(e.message);
    }
  };

  const SeedObjectContext = React.createContext();

  const SeedObjectsProvider = ({ seed, children }) => {
    const team1 = seed?.match?.team1;
    const team2 = seed?.match?.team2;
    const isBYE1 = seed?.match?.isBYE1;
    const isBYE2 = seed?.match?.isBYE2;
    const M_id = seed?.match?._id;
    const ShowOneBye = isBYE1 && isBYE2;
    const winner = seed?.match?.win;

    const includes1 = winner === team1?._id && team1 !== undefined;
    const includes2 = winner === team2?._id && team2 !== undefined;

    const isWinner = includes1 || includes2;
    const notshowButtons =
      !isWinner && ((team1 && isBYE2) || (team2 && isBYE1) || (team1 && team2));
    // const isLast = seed.isWL || seed.isLL;
    const isLast = false;

    const isEditable = !isBYE1 && !isBYE2;

    const addClass1 = (flag) => {
      assignbracketswinner(M_id, team1?._id, flag);
    };
    const addClass2 = (flag) => {
      assignbracketswinner(M_id, team2?._id, flag);
    };
    const reassign1 = (flag) => {
      Reassignbracketswinner(M_id, team2?._id, team1?._id, flag);
    };
    const reassign2 = (flag) => {
      Reassignbracketswinner(M_id, team1?._id, team2?._id, flag);
    };

    const object = {
      end: seed?.end,
      start: seed?.start,
      notshowButtons,
      ShowOneBye,
      isEditable,
      addClass1,
      addClass2,
      reassign1,
      reassign2,
      includes1,
      includes2,
      isWinner,
      isBYE1,
      isBYE2,
      isLast,
      winner,
      team1,
      team2,
      M_id
    };
    return (
      <SeedObjectContext.Provider value={{ ...object }}>{children}</SeedObjectContext.Provider>
    );
  };

  // seed Teams component Wrapper ,parent of 2 teams
  const SeedTeamsComponent = ({ flag }) => {
    const {
      M_id,
      team1,
      team2,
      isLast,
      isBYE1,
      isBYE2,
      includes1,
      includes2,
      isWinner,
      addClass1,
      addClass2,
      ShowOneBye,
      notshowButtons
    } = useContext(SeedObjectContext);

    if (edit?.includes(M_id)) return null;
    return (
      <div className="seedItemTeamWrapper">
        {ShowOneBye ? (
          <Fragment>
            <SeedTeamWrapper ShowOneBye={ShowOneBye} />
          </Fragment>
        ) : (
          <Fragment>
            <SeedTeamWrapper
              flag={flag}
              team={team1}
              isLast={isLast}
              isBYE1={isBYE1}
              condw={includes1}
              condl={includes2}
              isWinner={isWinner}
              callback={addClass1}
              condb={notshowButtons}
              wonusingBYE={(isBYE1 && team2) || (isBYE2 && team1)}
            />
            <SeedTeamWrapper
              flag={flag}
              team={team2}
              isBYE2={isBYE2}
              isLast={isLast}
              condw={includes2}
              condl={includes1}
              isWinner={isWinner}
              callback={addClass2}
              condb={notshowButtons}
              condition={team2 === "loser"}
              wonusingBYE={((isBYE1 && team2) || (isBYE2 && team1)) && isWinner}
            />
          </Fragment>
        )}
      </div>
    );
  };

  // single teams ,child
  const SeedTeamWrapper = ({
    team,
    flag,
    condw,
    condl,
    isBYE1,
    isBYE2,
    condb,
    isLast,
    callback,
    isWinner,
    condition,
    ShowOneBye,
    wonusingBYE
  }) => {
    const classes = "btnMobileDimensions draftbutton equalWidhtButton m-0";
    if (condition || (wonusingBYE && (isBYE1 || isBYE2))) return null;
    return (
      <SeedTeam className={`seedTeam ${wonusingBYE ? "seedTeamCol" : ""}`}>
        <span>{isBYE1 || isBYE2 || ShowOneBye ? "BYE" : team?.name || "T.B.D"}</span>
        <span style={{ display: isLast ? "none" : undefined }}>
          {condb && !isBYE1 && !isBYE2 ? (
            <Button onClick={() => callback(flag)} className={classes} type="primary">
              winner
            </Button>
          ) : null}
          <Fragment>
            {wonusingBYE ? <p className="seedTeamStatus Winner">(won using BYE)</p> : null}
            {isWinner !== undefined && team && !wonusingBYE ? (
              <Fragment>
                {condw ? (
                  <span
                    style={{
                      color: "var(--green)",
                      fontSize: "smaller",
                      fontWeight: "900",
                      marginRight: "3px"
                    }}>
                    (Winner)
                  </span>
                ) : null}
                {condl ? (
                  <span
                    style={{
                      color: "var(--red)",
                      fontSize: "smaller",
                      fontWeight: "900",
                      marginRight: "3px"
                    }}>
                    (Loser)
                  </span>
                ) : null}
              </Fragment>
            ) : null}
          </Fragment>
        </span>
      </SeedTeam>
    );
  };

  // seed Edit Teams component Wrapper ,parent of 2 teams
  const SeedTeamsEditComponent = ({ flag }) => {
    const { M_id, team1, team2, includes1, includes2, reassign1, reassign2 } =
      useContext(SeedObjectContext);

    if (!edit?.includes(M_id)) return null;
    return (
      <div className="seedItemTeamWrapper">
        {/* {children} */}
        <SeedEditTeamWrapper flag={flag} team={team1} condw={includes1} callback={reassign1} />
        <SeedEditTeamWrapper
          flag={flag}
          team={team2}
          condw={includes2}
          callback={reassign2}
          condition={team2 === "loser"}
        />
      </div>
    );
  };

  //edit single team ,child
  const SeedEditTeamWrapper = ({ team, condw, callback, condition, flag }) => {
    const classes = "btnMobileDimensions draftbutton equalWidhtButton m-0";
    if (condition) return null;
    return (
      <SeedTeam className="seedTeam">
        <span>{team?.name || "T.B.D"}</span>
        <span>
          {condw ? null : (
            <Button
              onClick={() => {
                callback(flag);
              }}
              className={classes}
              type="primary">
              winner
            </Button>
          )}
          <Fragment>
            {condw ? (
              <span
                style={{
                  color: "var(--green)",
                  fontSize: "smaller",
                  fontWeight: "900",
                  marginRight: "3px"
                }}>
                (Winner)
              </span>
            ) : null}
          </Fragment>
        </span>
      </SeedTeam>
    );
  };

  // SeedItem Date
  const SeedDateComponent = () => {
    const { start, team2 } = useContext(SeedObjectContext);
    return (
      <div
        style={{
          opacity: team2 === "loser" ? "0" : "1"
        }}
        className="seedItemDate">
        {team2 !== "loser"
          ? new Date(start).toDateString() +
            "  " +
            new Date(start).toLocaleTimeString() +
            " - " +
            moment(start).fromNow()
          : moment(new Date()).fromNow()}
      </div>
    );
  };

  // toggle edit button callback
  const handleedit = (M_id) => {
    let e = [...edit];
    if (e?.includes(M_id)) {
      setedit(e?.filter((a) => a !== M_id));
    } else {
      e = [...e, M_id];
      setedit(e);
    }
  };

  // edit toggle button
  const EditSeedItemButton = () => {
    const { M_id, isLast, isWinner, isEditable } = useContext(SeedObjectContext);
    if (!isWinner || isLast || !isEditable) return null;
    return (
      <Truncate
        lines={1}
        level={4}
        wrapperclassName="SeedEditButtonWrapper"
        className="SeedEditButton btnMobileDimensions"
        styles={{
          color: edit?.includes(M_id) ? "red" : "green"
        }}
        text={edit?.includes(M_id) ? "Cancel Edit" : "Edit"}
        onClick={() => handleedit(M_id)}
      />
    );
  };

  const DKOWINNERSBYE = ({ seed, breakpoint, roundIndex }) => {
    const isWL = roundIndex === rounds.length - 1;
    return (
      <Seed className="Seed" mobileBreakpoint={breakpoint}>
        <SeedObjectsProvider seed={{ ...seed, isWL }}>
          <SeedItem className="seedItem">
            <EditSeedItemButton />
            <SeedTeamsComponent flag="winner" />
            <SeedTeamsEditComponent flag="winner" />
          </SeedItem>
          <SeedDateComponent />
        </SeedObjectsProvider>
      </Seed>
    );
  };

  const DKOLOSERSBYE = ({ seed, breakpoint, roundIndex }) => {
    const isLineConnector = roundIndex === 0 || roundIndex % 2 === 0;
    const isLL = roundIndex === loserrounds.length - 1;

    const Wrapper = isLineConnector ? SingleLineSeed : Seed;
    return (
      <Wrapper className="Seed" mobileBreakpoint={breakpoint}>
        <SeedObjectsProvider seed={{ ...seed, isLL }}>
          <SeedItem className="seedItem">
            <EditSeedItemButton />
            <SeedTeamsComponent flag="loser" />
            <SeedTeamsEditComponent flag="loser" />
          </SeedItem>
          <SeedDateComponent />
        </SeedObjectsProvider>
      </Wrapper>
    );
  };
  const DKOFINALISTBYE = ({ seed, breakpoint, roundIndex }) => {
    const finalistr = useSelector((state) => state.ko.finalistbrackets);
    const isLineConnector =
      (roundIndex === 0 || roundIndex === 1) && finalistr.length - 1 !== roundIndex;
    const Wrapper = isLineConnector ? SingleLineSeed : Seed;

    if (!IsAgainMatch && roundIndex === 1) return null;
    return (
      <Wrapper
        className={`Seed ${!IsAgainMatch ? "removeLine" : null}`}
        mobileBreakpoint={breakpoint}>
        <SeedObjectsProvider seed={{ ...seed, roundIndex }}>
          <SeedItem className="seedItem">
            <EditSeedItemButton />
            <SeedTeamsComponent flag="finalist" />
            <SeedTeamsEditComponent flag="finalist" />
          </SeedItem>
          <SeedDateComponent />
        </SeedObjectsProvider>
      </Wrapper>
    );
  };
  return { DKOWINNERSBYE, DKOLOSERSBYE, DKOFINALISTBYE };
};
