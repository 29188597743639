import React from "react";
import { Col, Input, Form } from "antd";
function SearchBar({ inputState, setInputState }) {
  return (
    <Col span={24} style={{ borderBottom: ".5px solid #AFAFAF" }}>
      <Form
        style={{
          width: "100%",
          display: "flex",
          padding: "2%",
          columnGap: "1rem",
          alignItems: "center"
        }}>
        <Input
          size="large"
          placeholder="Search People"
          style={{
            borderRadius: "10px",
            background: "rgba(196, 196, 196, 0.27)",
            border: "none",
            padding: "12px 25px"
          }}
          value={inputState}
          onChange={(e) => setInputState(e.target.value)}
        />
        {/* <Button
                    className='boldBlueColor'
                    style={{
                        border: "none",
                        boxShadow: "none"
                    }}
                    htmlType="submit"
                >
                    Search
                </Button> */}
      </Form>
    </Col>
  );
}

export default SearchBar;
