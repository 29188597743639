import { useEffect } from "react";
import { GetSingleOrganizer } from "../../Config/API/organizer";
import { GetSinglePanel } from "../../Config/API/panel";
import { GetSinglePlayer } from "../../Config/API/player";
import { GetSingleTeam } from "../../Config/API/teams";
import { error, Info } from "../Alerts/Message";
import {
  setProfile as SETPROFILE,
  load,
  unload,
  isLoading
} from "../../Store/Features/ProfileSlice";
import { useDispatch, useSelector } from "react-redux";
import useVariables from "./useVariables";
import { useNavigate } from "react-router-dom";
import { ROLES } from "../../Config/helpers/constants";
import { GetSingleSponsor } from "../../Config/API/sponsor";

export function useFetchProfile(id, state, setisFollowing) {
  const dispatch = useDispatch();
  const loading = useSelector(isLoading);
  var counter = 0;
  var flag = false;

  const { viewer } = useVariables();
  const navigate = useNavigate();
  const GetProfiles = async () => {
    try {
      if (!id) throw new Error(state + "id error");
      dispatch(load());
      let response, data;
      if (state === "player") {
        response = await GetSinglePlayer(id);
        data = await response.json();
      }
      if (state === "team") {
        response = await GetSingleTeam(id);
        data = await response.json();
      } else if (state === "organizer") {
        response = await GetSingleOrganizer(id);
        data = await response.json();
      } else if (state === "panel") {
        response = await GetSinglePanel(id);
        data = await response.json();
      } else if (state === ROLES.sponsor) {
        response = await GetSingleSponsor(id);
        data = await response.json();
      }

      if (data?.result === null) {
        Info("Invalid Path");
        // if (state === "panel") {
        // }
        // navigate("/organizer/panels", { replace: true })
        if (state === "team") navigate("/player/teams", { replace: true });
        else if (state === "player" || state === "organizer" || state === "sponsor") {
          // navigate("/", { replace: true })
        }
      }

      if (data?.type === "success" && data?.result !== null) {
        const d = {
          ...data?.result,
          // data sometimes comes as result?.data and not directly in result
          cover: data?.result?.data?.coverpic || data?.result?.coverpic,
          profile: data?.result?.data?.profilepic || data?.result?.profilepic,
          _id: data?.result?.data?._id || data?.result?._id,
          name: data?.result?.data?.name || data?.result?.name,
          user: data?.result?.data?.user || data?.result?.user,
          followers: data?.result?.data?.followers || data?.result?.followers,
          followings: data?.result?.data?.followings || data?.result?.followings,
          description: data?.result?.data?.description || data?.result?.description,
          profileId: data?.result?.data?.profile || data?.result?.profile?._id,
          location: data?.result?.data?.location || data?.result?.location || "",
          rating: data?.result?.data?.panelRatings || data?.result?.panelRatings || "",
          username: data?.result?.data?.username || data?.result?.username || ""
        };

        if (setisFollowing) {
          const yourid = viewer?.id;

          d?.followers?.forEach((e) => {
            if (e?.organizer?._id === yourid) {
              setisFollowing(true);
              return;
            } else if (e?.player?._id === yourid) {
              setisFollowing(true);
              return;
            } else if (e?.team?._id === yourid) {
              setisFollowing(true);
              return;
            } else if (e?.panel?._id === yourid) {
              setisFollowing(true);
              return;
            }
          });
        }

        if (!flag) {
          dispatch(SETPROFILE(d));
        }
        // window.dispatchEvent(new Event("storage"));
      }
      dispatch(unload());
      return;
    } catch (e) {
      counter++;
      if (counter < 3) GetProfiles();
      else {
        dispatch(unload());
        error(e.message || e);
      }
    }
  };
  useEffect(() => {
    dispatch(SETPROFILE({}));
    if (id && state) GetProfiles();
    return () => {
      flag = true;
    };
  }, [id]);

  return {
    profileLoding: loading ? "white" : false
  };
}
