import React, { useRef, useEffect, useState } from "react";
import { Col, Form, Input, Layout, Row, Typography } from "antd";
const { Text, Title } = Typography;
import Loader from "./../../common/cards/Loader";
import { IoSend } from "react-icons/io5";
import Reciver from "../ChatRoom/Main/Card/Reciver";
import Sender from "../ChatRoom/Main/Card/Sender";
import { GetDiscussionMessages } from "../../Config/API/chat";
import { ROLES } from "../../Config/helpers/constants";
import { error } from "../../common/Alerts/Message";
import io from "socket.io-client";
import { KEYS } from "../../Config/Keys";

const mobileLoadingstlyes = {
  position: "fixed",
  left: "0",
  top: "0",
  width: "100vw",
  minHeight: "100vh",
  display: "grid",
  placeContent: "center",
  zIndex: "10",
  background: "white"
};
const desktopLoadingStyles = {
  position: "absolute",
  left: "0",
  top: "-50px",
  width: "100%",
  maxWidth: "85%",
  minHeight: "100%",
  display: "grid",
  placeContent: "center",
  zIndex: "1"
};

const EmptyMessageCenter = (
  <Typography className="EmptyMessageCenter">
    <Text> No Messages Yet! </Text>{" "}
  </Typography>
);

const AlwaysScrollToBottom = () => {
  const elementRef = useRef();
  useEffect(() => elementRef.current.scrollIntoView());
  return <div ref={elementRef} />;
};

/**
 * @function DiscussionRoom
 * @description This component is used to display a chat group for selected type.
 * @param {string} type The type of the chat. Currently supported types are "panel" and "team".
 * @param {string} id The id of team/panel
 * @returns {ReactElement} The chat room component.
 */
function DiscussionRoom({ type, id }) {
  const socket = io.connect(KEYS.API);

  const profileID = localStorage.getItem("ProfileID");

  const [MainTextField, setMainTextField] = useState("");
  const [messages, setMessages] = useState([]);
  const mobile = false;
  const loading = false;
  const room = id; // id of panel or team

  const getDiscussions = async () => {
    let payload;
    if (type === ROLES.panel) {
      payload = { panelId: id };
    } else if (type === ROLES.team) {
      payload = { teamId: id };
    }
    const response = await GetDiscussionMessages(payload);
    const result = await response.json();
    if (result?.type === "success") {
      setMessages(result?.result?.messages || []);
    }
  };

  const onSubmit = () => {
    if (MainTextField == "") {
      return error("Message field can't be empty");
    }

    let localData;

    localData = {
      sender: { [type === ROLES.team ? ROLES.player : ROLES.organizer]: { _id: profileID } },
      receiver: {
        [type]: {
          // type will be panel or team
          _id: id // id of team or panel
        }
      },
      text: MainTextField,
      createdAt: new Date(),
      room: room // id of panel or team
    };
    setMessages((messages) => [...messages, localData]);

    setMainTextField("");
    form.resetFields();
    socket.emit("send_message", {
      data: localData,
      room: room
    });
  };
  const [form] = Form.useForm();
  const bottomRef = useRef(null);

  useEffect(() => {
    if (type && id) {
      getDiscussions();
    }
  }, [type, id]);

  // listener for getting messages
  useEffect(() => {
    if (id && type) {
      socket.on("receive_message", (data) => {
        setMessages((messages) => [...messages, data]);
      });
    }
  }, [socket, id, type]);

  return (
    <Row justify="center" className="p-4">
      <Col span={24}>
        <Layout className={`ChatRoomMain  ${mobile ? "" : undefined}`}>
          <Layout.Header className="Chat_TobBar">
            <Typography>
              <Title
                level={3}
                style={{ marginBottom: 0, fontWeight: "bold", textTransform: "uppercase" }}>
                {type || ""} Discussion
              </Title>
            </Typography>
          </Layout.Header>
          <Layout.Content
            className={`ChatContent ${mobile ? "ChatContentMobile" : undefined}`}
            ref={bottomRef}>
            {loading ? (
              <div style={mobile ? mobileLoadingstlyes : desktopLoadingStyles}>
                <Loader noShadow smaller />
              </div>
            ) : (
              <div
                style={{
                  zIndex: loading ? "-1" : "1",
                  minHeight: loading ? "0px" : undefined,
                  overflow: loading ? "hidden" : undefined
                }}>
                {messages?.length === 0 && !loading ? EmptyMessageCenter : null}
                {messages?.map((item, i) => (
                  <React.Fragment key={i}>
                    {item?.sender?.player?._id === profileID ||
                    item?.sender?.organizer?._id === profileID ? (
                      <Sender item={item} openChat={item} />
                    ) : (
                      <Reciver item={item} />
                    )}
                  </React.Fragment>
                ))}
              </div>
            )}

            <AlwaysScrollToBottom />
          </Layout.Content>

          <Layout.Footer className="ChatFooter">
            <Form name="Main" className="MainForm pl-2 pr-2" onFinish={onSubmit} form={form}>
              <Form.Item style={{ flex: 1 }} name="search">
                <Input
                  className="ChatSearchBar"
                  placeholder="Send Message"
                  name="textfield"
                  value={MainTextField}
                  onChange={(e) => setMainTextField(e.target.value)}
                  size="large"
                  style={{ padding: "12px 15px" }}
                  suffix={
                    <IoSend
                      color={"var(--blue)"}
                      cursor="pointer"
                      fontSize={"1.4rem"}
                      onClick={onSubmit}
                    />
                  }
                />
              </Form.Item>
              {/* <Form.Item>
              <ImAttachment color={"var(--blue)"} cursor="pointer" fontSize={"1.5rem"} />
            </Form.Item> */}
            </Form>
          </Layout.Footer>
        </Layout>
      </Col>
    </Row>
  );
}

export default DiscussionRoom;
