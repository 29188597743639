import { Row, Col, Button } from "antd";
import React, { useState, Fragment } from "react";
import AllMedia from "./AllMedia";
import ImagesMedia from "./ImagesMedia";
import VideosMedia from "./VideosMedia";
import { useGetMedia } from "./function";
import { useEffect } from "react";
import Loader from "../cards/Loader";
import NoData from "../cards/noData";

import { useLocation, useParams } from "react-router-dom";

const Media = ({ current }) => {
  const [all, setall] = useState("primary");
  const [images, setimages] = useState("");
  const [videos, setvideos] = useState("");
  const [MEDIA, setMedia] = useState([]);
  const [Filter, setFilter] = useState([]);
  const [loading, setloading] = useState(true);
  const { pathname } = useLocation();
  const { id } = useParams();
  const { GetMedia } = useGetMedia();
  useEffect(() => {
    GetMedia(setMedia, setloading, current);
    return () => {
      setMedia([]);
      setFilter(null);
      setall(null);
      setimages(null);
      setvideos(null);
      setloading(false);
    };
  }, [current, id, pathname]);
  useEffect(() => {
    setFilter(MEDIA?.filter((e) => e?.file?.type === "image" || e?.file?.type === "video"));
  }, [MEDIA]);
  return (
    <Fragment>
      <Row className="rowPadding " justify="center">
        <Col xs={24} sm={24} md={24} lg={14}>
          <Row className="mdWidth">
            <Col lg={14} md={20} sm={20} xs={24}>
              <Button
                className="buttonpo"
                style={{ margin: "0 2.5% 3% 0" }}
                onClick={() => {
                  setall("primary");
                  setimages("");
                  setvideos("");
                }}
                type={all}>
                All
              </Button>
              <Button
                className="buttonpo"
                style={{ margin: "0 2% 1% 0" }}
                type={images}
                onClick={() => {
                  setall("");
                  setimages("primary");
                  setvideos("");
                }}>
                Images
              </Button>
              <Button
                className="buttonpo"
                style={{ margin: "0 2% 1% 0" }}
                type={videos}
                onClick={() => {
                  setall("");
                  setimages("");
                  setvideos("primary");
                }}>
                Videos
              </Button>
            </Col>
            {/* Tabs */}
            {loading ? (
              <div
                style={{
                  width: "100%",
                  marginTop: Filter?.length === 0 ? "5px" : "0"
                }}>
                <Loader />
              </div>
            ) : Filter?.length > 0 ? (
              <Col lg={24} md={24} xs={24} sm={24}>
                {all === "primary" ? <AllMedia MEDIA={MEDIA} /> : null}
                {images === "primary" ? <ImagesMedia MEDIA={MEDIA} /> : null}
                {videos === "primary" ? <VideosMedia MEDIA={MEDIA} /> : null}
              </Col>
            ) : (
              <Col
                style={{
                  width: "100%",
                  marginTop: Filter?.length === 0 ? "5px" : "0",
                  padding: 10
                }}
                span={24}>
                <NoData noMedia />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Media;
