import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import useVariables from "../../../common/hooks/useVariables";

function AuthOrganizer() {
  const { FLAG, USERID, PID } = useVariables();
  const navigate = useNavigate();

  useEffect(() => {
    if (!USERID) navigate("/signin");
    else if (FLAG === "player" || !PID) navigate("/", { replace: true });
  }, [FLAG, PID, USERID, navigate]);
  if (FLAG && PID) return <Outlet />;
  return null;
}
export default AuthOrganizer;
