import React, { useState } from "react";
import { GAMES_MODES } from "../../../../../../../Config/helpers/constants";
import { Form, Select, Steps, Row, Button, Col, Switch, TimePicker, Space, DatePicker } from "antd";
const { Option } = Select;
import moment from "moment";
import { Info } from "../../../../../../../common/Alerts/Message";

function MultiPlayerScheduleFields({
  state,
  noOfKoGroups,
  setNoOfKoGroups,
  noOfTeamsFromEGroups,
  setNoOfTeamsFromEGroups,
  valbtime,
  minteams,
  setminteams,
  maxteams,
  setmaxteams,
  noofMatches,
  setnoofMatches,
  wantParrallelMatches,
  setwantParrallelMatches,
  timeforonematch,
  setTimeforonematch,
  onSelect,
  valstartingtime,
  setdob,
  dob,
  valtime,
  istrankpoints,
  setistrankpoints,
  lastrankpoints,
  setlastrankpoints,
  killpoints,
  setkillpoints,
  playingTime,
  breakTime,
  parralelMatches
}) {
  // states
  const [currentStep, setCurrentStep] = useState(0);

  // logics and matches data
  // no of register teams without wildCards
  const noOfRegisteredTeams = state?.registerteams?.filter((team) => !team?.wildCard)?.length;
  const condition = state?.typeofmatch === GAMES_MODES.multi;
  const condition3 = noOfRegisteredTeams / noOfKoGroups;

  const averageNoOfTeamsInGroup =
    noOfKoGroups > 1 ? Math.floor(noOfRegisteredTeams / noOfKoGroups) : noOfRegisteredTeams;
  const teamsingroupscouldbe =
    parseInt(state?.registerteams?.length / (noOfKoGroups || 1)) + 3 || 1;

  let message = "max playing time could be (" + timeforonematch + ")";
  let messageb = "Both Match and break time should not exceed Playing Time";

  // handle changes, controllers
  const handleChange = (value, variable) => {
    if (value === undefined) return;
    if (variable === "group") {
      if (noOfKoGroups === 0) {
        return setNoOfKoGroups(1);
      } else {
        setNoOfTeamsFromEGroups();
        return setNoOfKoGroups(value);
      }
    } else {
      if (noOfTeamsFromEGroups === 0) {
        return setNoOfTeamsFromEGroups(1);
      } else {
        return setNoOfTeamsFromEGroups(value);
      }
    }
  };

  const handleChangeStep = (key) => {
    if (key === "next") {
      setCurrentStep((prev) => prev + 1);
    } else {
      setCurrentStep((prev) => prev - 1);
    }
  };

  return (
    <>
      <Steps
        current={currentStep}
        items={[
          {
            title: "Group details"
          },
          {
            title: "Match Timing"
          },
          {
            title: "Score and wild cards"
          }
        ]}
      />

      <main className="mt-5 mb-5 p-5">
        {/* step 0: one group details*/}
        {currentStep === 0 && (
          <Row justify="space-between">
            <Col>
              {condition ? (
                <Form.Item required className="halfwidth profileItems">
                  <label className="boldlabel">
                    {state?.typeofmatch === GAMES_MODES.multi
                      ? "No. of Groups"
                      : "No. of Groups for Group Stages"}
                  </label>

                  <Select
                    value={noOfKoGroups || 1}
                    placeholder=" 1,2,3,4...."
                    style={{ minWidth: "200px", maxWidth: "200px" }}
                    allowClear
                    onChange={(e) => handleChange(e, "group")}
                    onClear={() => {
                      setNoOfKoGroups();
                    }}
                    options={Array.from({ length: noOfRegisteredTeams / 2 }, (_, i) => {
                      const condition = i > 0;
                      return condition ? { label: i, value: i } : null;
                    }).filter((e) => e)}
                  />
                  <label className="mb-2 boldlabel">
                    Total no of Teams in current round: {noOfRegisteredTeams}
                  </label>
                  <label className="mb-2 boldlabel">
                    Average no of Teams in each group: {averageNoOfTeamsInGroup}
                  </label>
                </Form.Item>
              ) : null}
              {/* max min teams */}
              <label className="boldlabel" style={{ fontSize: "17px" }}>
                Minimum and Maximum teams in groups
              </label>
              <Form
                style={{
                  gap: "8%",
                  marginTop: "30px",
                  position: "relative",
                  display: "flex"
                }}
                name="basic"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                autoComplete="off">
                <Form.Item
                  style={{ position: "absolute", width: "100%", bottom: "0px" }}
                  help={valbtime === "error" ? messageb : null}
                  hasFeedback
                  required
                  validateStatus={valbtime}
                />
                <Form.Item className="profileItems">
                  <label className="boldlabel">Minimum</label>
                  <Select
                    className="ceinputfieldnos selectSmaller"
                    title="minimumteams"
                    bordered={true}
                    value={minteams}
                    onSelect={(e) => {
                      setminteams(e);
                    }}>
                    {Array(teamsingroupscouldbe)
                      .fill(null)
                      .map((_, i) => (
                        <Option value={i} key={`minimumteams${i}`}>
                          {i}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item className="profileItems">
                  <label className="boldlabel">Maximum</label>
                  <Select
                    className="ceinputfieldnos selectSmaller"
                    title="Maximumteams"
                    bordered={true}
                    value={maxteams}
                    onSelect={(e) => {
                      setmaxteams(e);
                    }}>
                    {Array(teamsingroupscouldbe)
                      .fill(null)
                      .map((_, i) => (
                        <Option value={i} key={`Maximumteams${i}`}>
                          {i}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Form>
            </Col>

            <Col>
              {" "}
              {/* no of teams moving */}
              {condition ? (
                <Form.Item required className="halfwidth profileItems">
                  <label className="boldlabel">No. of teams moving from each from each group</label>
                  <Select
                    value={noOfTeamsFromEGroups}
                    placeholder={"1,2,3,4,...."}
                    style={{ minWidth: "200px", maxWidth: "200px" }}
                    allowClear
                    onChange={handleChange}
                    onClear={() => {
                      setNoOfTeamsFromEGroups();
                    }}
                    options={Array.from({ length: condition3 }, (_, i) => {
                      return i > 0 && { label: i, value: i };
                    }).filter((e) => e)}
                  />
                </Form.Item>
              ) : null}
              {/* no of matches */}
              <Form
                style={{ display: "flex", gap: "8%", marginTop: "30px" }}
                name="basic"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                autoComplete="off">
                <Form.Item className="halfwidth profileItems">
                  <label className="boldlabel">No of Matches</label>
                  <Select
                    value={noofMatches}
                    placeholder="1,2,3,4,..."
                    style={{ minWidth: "200px" }}
                    allowClear
                    onChange={(e) => setnoofMatches(e)}
                    onClear={() => {
                      setnoofMatches(1);
                    }}
                    options={Array.from({ length: 7 }, (_, i) => {
                      const condition = i > 0;
                      return condition ? { label: i, value: i } : null;
                    }).filter((e) => e)}
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
        )}
        {/* step 1: two match timings*/}

        {currentStep === 1 && (
          <Row justify="space-between">
            <Col>
              {/* start time */}
              <Form
                style={{ display: "flex", gap: "8%" }}
                name="basic"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                autoComplete="off">
                <Form.Item
                  help={
                    valstartingtime === "error"
                      ? `playing hours from starting time should not exceed 24 hours`
                      : ""
                  }
                  hasFeedback
                  validateStatus={valstartingtime}
                  className="profileItems">
                  <label className="boldlabel">Starting Time of First Match</label>
                  <Space>
                    <DatePicker
                      value={dob?.[0] ? moment(dob?.[0]) : ""}
                      showSecond={false}
                      disabledDate={(current) => {
                        return (
                          current < moment(state?.startDate).startOf("day") ||
                          current > moment(state?.endDate).startOf("day") ||
                          current < moment().startOf("day")
                        );
                      }}
                      format="YYYY-MM-DD"
                      onChange={(e, o) => {
                        if (dob?.[1]) {
                          setdob([o, dob?.[1]]);
                        } else {
                          setdob([o, "01:00"]);
                        }
                      }}
                    />
                    <TimePicker
                      value={dob?.[1] ? moment(dob[1], "HH:mm") : ""}
                      renderExtraFooter={() => "Hour :  Min"}
                      format={"HH:mm"}
                      disabled={!dob?.[0]}
                      showNow={false}
                      inputReadOnly
                      onChange={(e, i) => {
                        let a = String(i).split(":");
                        if (a[0] === "00" && a[1] === "00") {
                          Info("Time can't be 00:00");
                        } else {
                          setdob([dob?.[0], i]);
                        }
                      }}
                    />
                  </Space>
                </Form.Item>
              </Form>
              {/* parallel */}
              <Form
                style={{ gap: "5%" }}
                name="basic"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                autoComplete="off">
                <label className="boldlabel" style={{ fontSize: "17px" }}>
                  {/* parralel Matches */}
                  Do you want parallel matches?
                  <Switch
                    style={{ marginLeft: "5px" }}
                    checked={wantParrallelMatches}
                    onChange={() => setwantParrallelMatches((e) => !e)}
                  />
                </label>
                <Form.Item
                  style={{ display: wantParrallelMatches ? "block" : "none" }}
                  className="profileItems halfwidth">
                  <Select
                    className="ceinputfieldnos"
                    title="parralelMatches"
                    style={{ minWidth: "200px", maxWidth: "200px" }}
                    bordered={true}
                    value={parralelMatches}
                    defaultValue={2}
                    onSelect={onSelect}>
                    {Array(10)
                      .fill(null)
                      .map((_, i) => (
                        <Option value={i + 1} key={`parralelMatches${i}`}>
                          {i + 1}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Form>{" "}
              <br />
              <Form.Item
                help={valbtime === "error" ? message : null}
                hasFeedback
                required
                validateStatus={valbtime}
                className="halfwidth profileItems">
                <label className="boldlabel">Time Required for one Match</label>
                <TimePicker
                  renderExtraFooter={() => "Hour :  Min"}
                  format={"HH:mm"}
                  showNow={false}
                  inputReadOnly
                  value={timeforonematch ? moment(timeforonematch, "HH:mm") : undefined}
                  className="mt8"
                  onChange={(e, i) => {
                    let a = String(i).split(":");
                    if (a[0] === "00" && a[1] === "00") {
                      Info("Time can't be 00:00");
                      setTimeforonematch(null);
                    } else setTimeforonematch(i);
                  }}
                />
              </Form.Item>
            </Col>
            <Col>
              {" "}
              <label className="boldlabel" style={{ fontSize: "17px" }}>
                Number of play hours each day
              </label>
              <Form
                style={{
                  display: "flex",
                  gap: "8%",
                  marginTop: "35px",
                  position: "relative"
                }}
                name="basic"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                autoComplete="off">
                <Form.Item
                  style={{ position: "absolute", width: "100%", bottom: "0px" }}
                  help={valtime === "error" ? message : null}
                  hasFeedback
                  required
                  validateStatus={valtime}
                />
                <Form.Item className="profileItems">
                  <label className="boldlabel">Hours</label>
                  <Select
                    className="ceinputfieldnos selectSmaller"
                    title="Hours"
                    bordered={true}
                    onSelect={onSelect}
                    value={playingTime?.hours}>
                    {Array(24)
                      .fill(null)
                      .map((_, i) => (
                        <Option
                          value={i < 10 ? "0" + (i + 1) : `${i + 1}`}
                          key={`playingHours${i + 1}`}>
                          {i + 1}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item className="profileItems">
                  <label className="boldlabel">Minutes</label>
                  <Select
                    className="ceinputfieldnos selectSmaller"
                    title="Minutes"
                    bordered={true}
                    onSelect={onSelect}
                    value={playingTime?.minutes}>
                    {Array(60)
                      .fill(null)
                      .map((_, i) => (
                        <Option value={i < 10 ? "0" + i : i} key={`playingMinutes${i}`}>
                          {i}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Form>
              <br />
              <label className="boldlabel" style={{ fontSize: "17px" }}>
                Break between each match
              </label>
              <Form
                style={{
                  display: "flex",
                  gap: "8%",
                  marginTop: "30px",
                  position: "relative"
                }}
                name="basic"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                autoComplete="off">
                <Form.Item
                  style={{ position: "absolute", width: "100%", bottom: "0px" }}
                  help={valbtime === "error" ? messageb : null}
                  hasFeedback
                  required
                  validateStatus={valbtime}
                />
                <Form.Item className="profileItems">
                  <label className="boldlabel">Hours</label>
                  <Select
                    className="ceinputfieldnos selectSmaller"
                    title="Hours"
                    bordered={true}
                    onSelect={onSelect}
                    value={breakTime?.hours}>
                    {Array(25)
                      .fill(null)
                      .map((_, i) => (
                        <Option value={i} key={`breakHours${i}`}>
                          {i}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item className="profileItems">
                  <label className="boldlabel">Minutes</label>
                  <Select
                    className="ceinputfieldnos selectSmaller"
                    title="Minutes"
                    bordered={true}
                    onSelect={onSelect}
                    value={breakTime?.minutes}>
                    {Array(60)
                      .fill(null)
                      .map((_, i) => (
                        <Option value={i} key={`breakMinutes${i}`}>
                          {i}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        )}

        {currentStep === 2 && (
          <Form
            style={{
              display: "flex",
              gap: "8%",
              marginTop: "0px",
              position: "relative"
            }}
            name="basic"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            initialValues={{ remember: true }}
            autoComplete="off">
            <Form.Item
              style={{ position: "absolute", width: "100%", margin: "0px" }}
              hasFeedback
              required
            />
            <Form.Item className="profileItems">
              <label className="boldlabel">kill points</label>
              <Select
                className="ceinputfieldnos selectSmaller"
                bordered={true}
                onSelect={(e) => setkillpoints(e)}
                value={killpoints}>
                {Array(50)
                  .fill(null)
                  .map((_, i) => (
                    <Option value={i + 1} key={`killpoints${i + 1}`}>
                      {i + 1}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item className="profileItems">
              <label className="boldlabel">Points for 1st rank</label>
              <Select
                className="ceinputfieldnos selectSmaller"
                bordered={true}
                onSelect={(e) => setistrankpoints(e)}
                value={istrankpoints}>
                {Array(51)
                  .fill(null)
                  .map((_, i) => (
                    <Option value={i} key={`1strankpoints${i}`}>
                      {i}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item className="profileItems">
              <label className="boldlabel">Points for Last rank</label>
              <Select
                className="ceinputfieldnos selectSmaller"
                bordered={true}
                onSelect={(e) => setlastrankpoints(e)}
                value={lastrankpoints}>
                {Array(51)
                  .fill(null)
                  .map((_, i) => (
                    <Option value={i} key={`lastrankpoints${i}`}>
                      {i}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Form>
        )}
      </main>

      <Row justify="center">
        <Button
          type="primary"
          onClick={() => handleChangeStep("prev")}
          className="mr-5"
          disabled={currentStep === 0}>
          Previous
        </Button>
        <Button
          type="primary"
          onClick={() => handleChangeStep("next")}
          disabled={currentStep === 2}>
          Next
        </Button>
      </Row>
    </>
  );
}

export default MultiPlayerScheduleFields;
