import React, { Fragment } from "react";
import { Col, Row, Card } from "antd";
import MatchTeam from "./Btns/Team";
import VS from "./Btns/VS";

const HistoryMatchesCard = ({
  item,
  index //index of this element
}) => {
  return (
    <Fragment>
      <Card
        className="tablesBoxShadow MatchCard"
        bordered={false}
        style={{
          padding: "0px",
          marginTop: "10px"
        }}>
        <Row>
          <Col lg={10} xs={8} sm={8} md={8}>
            <MatchTeam t={1} item={item} />
          </Col>
          <Col lg={4} md={8} sm={8} xs={8}>
            <VS index={index} item={item} />
          </Col>
          <Col lg={10} xs={8} sm={8} md={8}>
            <MatchTeam t={2} item={item} />
          </Col>
        </Row>
      </Card>
    </Fragment>
  );
};

export default HistoryMatchesCard;
