import { Col, Row } from "antd";
import React, { Fragment } from "react";
import NoData from "../../../common/cards/noData";
import RequestCard from "./Card/RequestCard";
const MembersRequests = ({ data }) => {
  return (
    <Fragment>
      <Row>
        {data?.members?.length > 1 ? (
          data?.members?.map(
            (e, i) =>
              e.active === false &&
              e?.type.toLowerCase() === "join" && (
                <Col key={i} xxl={6} xl={8} lg={{ span: 12 }} md={{ span: 12 }} sm={24} xs={24}>
                  <RequestCard data={e} />
                </Col>
              )
          )
        ) : (
          <Col
            span={24}
            style={{
              display: "flex",
              alignItems: "stretch"
            }}>
            <NoData noMembers />
          </Col>
        )}
      </Row>
    </Fragment>
  );
};

export default MembersRequests;
