import React, { useState } from "react";
import { Modal, Button, Form, Row, Upload, message } from "antd";
import { ErrorMessage, Formik } from "formik";
import InputTextField from "../../common/FormItem/InputTextField";
import { error, success } from "../../common/Alerts/Message";
import { withdrawalFormValidations } from "../../Config/helpers/validations";
import { BiUpload } from "react-icons/bi";
import { UserWalletWithdraw } from "../../Config/API/Wallets";
import { TRANSACTION_CONSTANTS } from "../../Config/helpers/constants";

const WithDrawTokens = ({ open, setOpen, userType, userId, getUserTransactionHistory }) => {
  // wallet, getUserTransactionHistory
  const [loading, setLoading] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const payload = {
        userId,
        type: TRANSACTION_CONSTANTS.withdraw,
        ownerType: userType,
        ...values
      };

      const response = await UserWalletWithdraw(payload);
      const data = await response.json();
      if (data?.type === "success") {
        success("Transaction initiated Successfully");
        // get latest transactions again
        await getUserTransactionHistory();
        setOpen(false);
      } else {
        error(data?.result?.message || data?.result?.error || data?.result);
      }
      setLoading(false);
    } catch (e) {
      error(e);
    } finally {
      setLoading(false);
    }
  };

  const initialValues = {
    accountNumber: "",
    accountTitle: "",
    bankName: "",
    amount: "",
    mobileNumber: "",
    file: []
  };

  const handleFileChange = (file, setFieldValue) => {
    if (file?.file?.status !== "uploading") {
      const isAllowed = ["image/png", "image/jpg", "image/jpeg", "application/pdf"].includes(
        file?.file?.type
      );
      if (isAllowed) {
        setFieldValue("file", [file?.file]);
        setFieldValue("fileList", file?.fileList);
      }
    }
  };

  return (
    <>
      <Modal
        title="Withdrawal Request"
        open={open}
        onCancel={handleClose}
        centered
        width={500}
        footer={null}>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={withdrawalFormValidations}>
          {({ isValid, dirty, values, errors, setFieldValue }) => (
            <>
              <Form
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                autoComplete="off"
                layout="vertical"
                name="withdrawalForm">
                <InputTextField
                  required
                  name="accountNumber"
                  label="Account Number"
                  placeholder="Enter your bank account number"
                />

                <InputTextField
                  required
                  name="accountTitle"
                  label="Account Title"
                  placeholder="Enter you account title"
                />

                <InputTextField
                  required
                  name="bankName"
                  label="Bank Name"
                  placeholder="Enter your bank name"
                />
                <InputTextField
                  required
                  name="amount"
                  label="Amount to Withdraw"
                  type="number"
                  placeholder="Enter the bank name"
                  min={0}
                />

                <InputTextField
                  name="mobileNumber"
                  label="Contact Info"
                  type="number"
                  placeholder="Enter your contact information"
                />
                {/* upload */}
                <Form.Item
                  label="Upload Supporting Document"
                  help={errors?.file ? errors?.file : undefined}
                  validateStatus={errors?.file ? "error" : "success"}>
                  <Upload
                    name="file"
                    fileList={values?.fileList || []}
                    beforeUpload={(file) => {
                      const isAllowed = [
                        "image/png",
                        "image/jpg",
                        "image/jpeg",
                        "application/pdf"
                      ].includes(file?.type);
                      if (!isAllowed) {
                        message.error("You can only upload PNG, JPG, JPEG, or PDF files!");
                      } else {
                        setFieldValue("file", []);
                        setFieldValue("fileList", []);
                      }
                      return false;
                    }}
                    onChange={(file) => handleFileChange(file, setFieldValue)}
                    onRemove={() => {
                      setFieldValue("fileList", []);
                      setFieldValue("file", "");
                    }}>
                    <Button icon={<BiUpload />}>Click to Upload</Button>
                  </Upload>
                  <ErrorMessage
                    name="fileList"
                    render={(msg) => error(msg)}
                    component="div"
                    style={{ color: "red" }}
                  />
                </Form.Item>
              </Form>
              <Row justify="center">
                <Button
                  onClick={() => handleSubmit(values)}
                  loading={loading}
                  className="teamcreateButton"
                  htmlType="submit"
                  type="primary"
                  disabled={!(isValid && dirty) || loading}>
                  Withdraw
                </Button>
              </Row>
            </>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default WithDrawTokens;
