import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import React from "react";
import MapCard from "../../cards/MapCard/MapCard";

const { Panel } = Collapse;

const LocaitonAccordian = ({ data, fullwidthEvent }) => (
  <Collapse
    bordered={false}
    style={{ width: fullwidthEvent ? "100%" : "85%" }}
    defaultActiveKey={["LocationAccordiant"]}
    expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
    className="site-collapse-custom-collapse  sideItem">
    <Panel header="Location" key="Location" className="site-collapse-custom-panel">
      <p
        className="timelinedesc overflowThumb"
        style={{
          maxHeight: 250,
          overflowY: "auto",
          padding: "0px 0.5rem"
        }}>
        {" "}
        {data?.location || " No location provided"}
      </p>
      <MapCard data={data} />
    </Panel>
  </Collapse>
);

export default LocaitonAccordian;
