/* eslint-disable no-unused-vars */
import { Avatar, Drawer, Dropdown, List, Menu, Modal, Select, Tag, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GetAllTeamsInEvent, GetAwardsForEvent } from "../../../../../../Config/API/events";
// import { UserOutlined } from "@ant-design/icons";
import { KEYS } from "../../../../../../Config/Keys";
import { GetSingleTeam } from "../../../../../../Config/API/teams";
import { error } from "../../../../../../common/Alerts/Message";

// concludetournament prop to get
function AssignAwards({ open, setOpen, concludetournament, setAssignedAwards, assignedAwards }) {
  const [loading, setLoading] = useState({
    teamsLoading: false,
    awardsLoading: false,
    singleTeamLoading: false
  });
  const [awards, setAwards] = useState([]);
  const [teams, setTeams] = useState([]);
  const [players, setPlayers] = useState({});
  const [drawer, setDrawer] = useState(false);
  const [playersDrawer, setPlayersDrawer] = useState(false);
  const [selectedAward, setSelectedAward] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);

  const { id } = useParams();

  const getEventAwards = async () => {
    setLoading({ ...loading, awardsLoading: true });
    const response = await GetAwardsForEvent(id);
    const data = await response.json();
    if (data && data?.length > 0) {
      // only show those awards that would are not default
      const withoutDefault = data?.filter((award) => !award?.isDefault);
      setAwards(withoutDefault);
    }
    setLoading({ ...loading, awardsLoading: false });
  };

  console.log({ awards });

  const getEventTeams = async () => {
    setLoading({ ...loading, teamsLoading: true });
    const response = await GetAllTeamsInEvent(id);
    const data = await response.json();
    if (data && data?.type === "success") {
      setTeams(data?.result?.["0"]?.registerteams || []);
    }
    setLoading({ ...loading, teamsLoading: false });
  };

  const getSingleTeamMembers = async (teamId, teamName) => {
    setSelectedTeam({ _id: teamId, name: teamName });
    if (players?.[teamId]) {
      setPlayersDrawer(true);
    } else {
      try {
        setLoading({ ...loading, singleTeamLoading: true });
        const response = await GetSingleTeam(teamId);
        const data = await response.json();
        if (data?.type === "success") {
          setPlayers({ ...players, [teamId]: data?.result?.members });
          setPlayersDrawer(true);
        } else {
          error("Error occurred while fetching team players");
        }

        setLoading({ ...loading, singleTeamLoading: false });
      } catch (e) {
        setLoading({ ...loading, singleTeamLoading: false });
        error(e.message);
      }
    }
  };

  useEffect(() => {
    // get event added awards
    if (id) {
      getEventAwards();
      getEventTeams();
    }
    return () => {
      setAwards([]);
      setTeams([]);
      setPlayers([]);
      setDrawer(false);
      setSelectedAward(null);
    };
  }, [id]);

  const handleAssignAward = (assignTo, awardId) => {
    setAssignedAwards({ ...assignedAwards, [awardId]: assignTo });
    setDrawer(false);
    setPlayersDrawer(false);
  };

  const handleCompleteAssigningAWards = () => {
    // if all awards are not assigned return error
    if (Object?.entries(assignedAwards)?.length < awards?.length) {
      error("Assign all awards in order to conclude event");
      return;
    } else {
      concludetournament();
    }
  };

  return (
    <>
      <Modal
        title="Assign Event Awards"
        className="editPost AddTeamModel"
        mask
        maskClosable={false}
        onCancel={() => {
          setOpen(false);
          setAssignedAwards({});
        }}
        onOk={handleCompleteAssigningAWards}
        closable={false}
        centered
        destroyOnClose={true}
        open={open}
        bodyStyle={{ padding: "20px" }}>
        {loading.awardsLoading || loading?.teamsLoading ? (
          "Fetching data"
        ) : (
          <>
            <Typography.Text type="success">
              Assign Awards to players/Teams in order to conclude this event!
            </Typography.Text>
            {/* Teams */}
            <List
              dataSource={awards}
              bordered
              renderItem={(item) => (
                <List.Item
                  key={item._id}
                  actions={[
                    <Tag
                      key="team"
                      className="cursor-pointer"
                      color="geekblue"
                      onClick={() => {
                        setSelectedAward(item);
                        setDrawer(open);
                      }}>
                      Choose participant
                    </Tag>,
                    <Tag
                      key="award"
                      color="red"
                      style={{ display: assignedAwards?.[item?._id] ? "block" : "none" }}>
                      {assignedAwards?.[item?._id]?.name}
                    </Tag>
                  ]}>
                  <List.Item.Meta
                    title={item.title}
                    description={`Award Type: ${item?.awardType} and Rank:${item?.rank}`}
                  />
                </List.Item>
              )}
            />
          </>
        )}
        {/* choose team drawer */}
        {drawer && (
          <Drawer
            width={640}
            placement="right"
            closable={false}
            onClose={() => {
              setDrawer(false);
              setSelectedAward(null);
            }}
            open={drawer}>
            <Tag
              color="warning"
              className="cursor-pointer"
              onClick={() => {
                setDrawer(false);
                setSelectedAward(null);
              }}>
              Close
            </Tag>
            <p>Award to assign: {selectedAward?.title}</p>
            <p>Type: {selectedAward?.awardType}</p>
            <p>Award for Rank: {selectedAward?.rank}</p>

            <List
              dataSource={teams}
              bordered
              renderItem={(item) => (
                <List.Item
                  key={item?._id}
                  actions={[
                    <Tag
                      key="team"
                      className="cursor-pointer"
                      color="geekblue"
                      onClick={() =>
                        handleAssignAward({ ...item, type: "team" }, selectedAward?._id)
                      }>
                      Assign award
                    </Tag>,
                    <Tag
                      className="cursor-pointer"
                      key="player"
                      color="green"
                      onClick={() => getSingleTeamMembers(item?._id, item?.name)}>
                      {loading.singleTeamLoading ? "Players loading..." : "Assign to player"}{" "}
                    </Tag>
                  ]}>
                  <List.Item.Meta
                    avatar={
                      <Avatar src={item?.profilepic ? `${KEYS.API}/${item?.profilepic}` : null} />
                    }
                    title={item?.name}
                  />
                </List.Item>
              )}
            />
          </Drawer>
        )}

        {/* choose player drawer */}
        {playersDrawer && (
          <Drawer
            width={640}
            placement="right"
            closable={false}
            onClose={() => {
              setSelectedTeam(null);
              setPlayersDrawer(false);
            }}
            open={playersDrawer}>
            <Tag
              color="warning"
              className="cursor-pointer"
              onClick={() => {
                setSelectedTeam(null);
                setPlayersDrawer(false);
              }}>
              Close
            </Tag>
            <p>Award to assign: {selectedAward?.title}</p>
            <p>Type: {selectedAward?.awardType}</p>
            <p>Award for Rank: {selectedAward?.rank}</p>

            <Typography.Text mark>Players of team: {selectedTeam?.name}</Typography.Text>

            <List
              dataSource={players?.[selectedTeam?._id]}
              bordered
              renderItem={(item) => (
                <List.Item
                  key={item?.player?._id}
                  actions={[
                    <Tag
                      key="team"
                      className="cursor-pointer"
                      color="geekblue"
                      onClick={() =>
                        handleAssignAward({ ...item?.player, type: "player" }, selectedAward?._id)
                      }>
                      Assign award
                    </Tag>
                  ]}>
                  <List.Item.Meta
                    avatar={
                      <Avatar
                        src={
                          item?.player?.profilepic
                            ? `${KEYS.API}/${item?.player?.profilepic}`
                            : null
                        }
                      />
                    }
                    title={item?.player?.name}
                  />
                </List.Item>
              )}
            />
          </Drawer>
        )}
      </Modal>
    </>
  );
}

export default AssignAwards;
