/* eslint-disable */
import Notification from "../../../common/Notification/TeamPanelNotifications";
import { PROFILE, setProfile } from "../../../Store/Features/ProfileSlice";
import { useFetchProfile } from "../../../common/hooks/useFetchProfile";
import { useCurrentPage } from "../../../common/hooks/useCurrentPage";
import { Link, useNavigate, useParams } from "react-router-dom";
import React, { Fragment, useState, useEffect } from "react";
import FadeInImage from "../../../common/Images/FadeInImage";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../common/cards/Loader";
import { KEYS } from "../../../Config/Keys";
import { Col, Row, Button, Tabs, Grid, Rate, Space } from "antd";
import {
  followFunction,
  unFollowProfile,
  getactivefollowfollowers,
  checkIsAccepted,
  checkjoinedstatus,
  fallbackprofile,
  checkIfTeamCaptain
} from "../../../common/Utils";
import { useGroupRequests } from "../../../common/hooks/useGroupRequests";
import useVariables from "../../../common/hooks/useVariables";
import ProfileStatuSwtich from "./profileStatuSwtich";
import { AccepTeamRequest, CancelOrDeleteTeamRequest } from "../../../Config/API/teams";

const { useBreakpoint } = Grid;
const { TabPane } = Tabs;

export default function PlayerTeamsTNav() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const [isFollowing, setisFollowing] = useState(false);
  const [accepted, setaccpted] = useState(false);
  const [joined, setisjoined] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isCaptain, setIsCaptain] = useState(false);

  const breakpoints = useBreakpoint();

  const { id } = useParams();
  const { viewer } = useVariables();
  const pid = viewer?.id;
  const flag = viewer?.flag;

  const { url, other } = useCurrentPage();

  // Group id
  const fetchid = id;
  //fetching profile
  const { profileLoding } = useFetchProfile(fetchid, "team", setisFollowing);
  //Group Profile
  let team = useSelector(PROFILE);

  // discussion will only be displayed to players part of the teams and not on the view page
  const isPartOfTheTeam = team?.members?.find((member) => member?.player?._id === pid) && !other;

  //join request api
  const { TeamJoinRequestFun } = useGroupRequests();

  const followers = getactivefollowfollowers(team?.followers);

  useEffect(() => {
    //checking if the group is joind
    if (team && team?.members > 0) {
      setIsCaptain(checkIfTeamCaptain(team?.members, pid));
    }
    checkjoinedstatus(team, pid, setisjoined);
  }, [team, pid]);
  useEffect(() => {
    //checking if follow request is accetpted
    checkIsAccepted(setaccpted, followers, viewer);
  }, [followers?.length, isFollowing]);

  const acceptRejectTeamRequest = async (flag) => {
    setloading("join");

    let res;
    if (flag) {
      res = await AccepTeamRequest({ teamId: team?._id, id: pid });
    } else {
      res = await CancelOrDeleteTeamRequest({ teamId: team?._id, id: pid });
    }

    const result = await res.json();
    if (result?.type === "success") {
      window.location.reload();
    }
    setloading(false);
  };

  // follow following buttons
  const FollowFollowingButton = () => {
    return (
      <Fragment>
        {flag === "player" && !isCaptain ? (
          <p>
            <Button
              loading={loading === "join" && true}
              onClick={
                joined === "invited"
                  ? () => acceptRejectTeamRequest(true)
                  : joined === "requested"
                  ? null
                  : () => {
                      TeamJoinRequestFun(setloading, setisjoined);
                    }
              }
              className="profilebuttons blueButton btnMobileDimensions"
              style={{
                height: "auto",
                padding: "6px 10px",
                margin: "0",
                color: "white",
                marginRight: "10px"
              }}>
              {joined === "joined"
                ? "Joined"
                : joined === "requested"
                ? "Join Requested"
                : joined === "invited"
                ? "Accept Request"
                : "Join Team"}
            </Button>
            {joined === "invited" && (
              <Button
                loading={loading === "join"}
                type="dashed"
                onClick={() => acceptRejectTeamRequest(false)}>
                Reject Request
              </Button>
            )}
          </p>
        ) : null}
        <p>
          {isFollowing ? (
            <Button
              loading={loading === "unfollow" && true}
              onClick={() => {
                unFollowProfile(
                  team,
                  null,
                  "team",
                  setloading,
                  setisFollowing,
                  "otherprofile",
                  setProfile,
                  dispatch,
                  setaccpted
                );
              }}
              className="profilebuttons blueButton btnMobileDimensions"
              style={{
                height: "auto",
                padding: "6px 10px",
                margin: "0",
                color: "white"
              }}>
              {accepted ? "UnFollow" : "Cancel Follow Request"}
            </Button>
          ) : (
            <Button
              loading={loading === "follow" && true}
              onClick={() =>
                followFunction(
                  id,
                  setloading,
                  setisFollowing,
                  null,
                  setProfile,
                  dispatch,
                  setaccpted
                )
              }
              className="addtocalendar profilebuttons btnMobileDimensions"
              style={{
                height: "auto",
                padding: "6px 10px",
                margin: "0",
                color: "white"
              }}>
              Follow
            </Button>
          )}
        </p>
      </Fragment>
    );
  };
  //Edit Button
  const EditButton = () => {
    return (
      <p style={{ display: "flex", gap: "5px", alignItems: "center" }}>
        <ProfileStatuSwtich />
        <Button
          onClick={() => navigate(`/player/editteam/${team?._id}`, { state: "team" })}
          className="addtocalendar profilebuttons btnMobileDimensions"
          style={{
            height: "auto",
            padding: "6px 10px",
            margin: "0",
            color: "white"
          }}>
          Edit Profile
        </Button>
      </p>
    );
  };
  const isAdmin = team?.profileId === pid;

  return (
    <Fragment>
      {(loading !== "follow" && loading !== "unfollow" && loading !== "join" && loading) ||
      profileLoding ? (
        <Loader fullpage loading="white" />
      ) : null}
      <Row>
        <Col span={24} className="toheader">
          <FadeInImage
            preview
            type={"Fadecover"}
            className="tocover"
            src={KEYS.API + "/" + team?.cover}
          />
          <FollowersFollowingDataComp
            url={url}
            other={other}
            profile={team}
            followers={followers}
            id={id}
          />
        </Col>
      </Row>
      <Row
        wrap="wrap"
        align="top"
        justify="space-between"
        className="tosubnav tosubnav1 teampanelnav">
        <Col className="tosubnavcenter" xl={3} lg={3} md={4} sm={6} xs={24}>
          <div className="abs">
            <FadeInImage
              preview
              type="Fadeprofile"
              fallaback={fallbackprofile}
              src={KEYS.API + "/" + team?.profile}
            />
          </div>
          <Space direction="vertical" size="small">
            {" "}
            <h2
              className="toname"
              style={{
                textAlign: "center",
                margin: "0px 0 0 0",
                minWidth: "170px",
                fontSize: 20
              }}>
              {team?.name || "Dummy name"}{" "}
              {team?.username && (
                <span style={{ fontSize: "12px", color: "gray" }}>( {team?.username} )</span>
              )}
            </h2>
            {team?.username && (
              <p style={{ fontSize: "12px", color: "gray" }}>( {team?.username} )</p>
            )}
          </Space>
        </Col>

        {breakpoints.xs && (
          <Col className="tosubnavcenter" sm={6} xs={24}>
            <div className="timelinedesc">
              {/* <Rate allowHalf defaultValue={Rating || 2.5} /> */}
              <Rate allowHalf defaultValue={2.5} />
            </div>
          </Col>
        )}
        <SubLinksWrapper
          FollowFollowingButton={FollowFollowingButton}
          EditButton={EditButton}
          setIsOpen={setIsOpen}
          profile={team}
          isAdmin={isAdmin}
          isOpen={isOpen}
          other={other}
          flag={flag}
          url={url}
          id={id}
          isPartOfTheTeam={isPartOfTheTeam}
        />
      </Row>
      <XsButtonsRow
        other={other}
        EditButton={EditButton}
        FollowFollowingButton={FollowFollowingButton}
        isAdmin={isAdmin}
      />
    </Fragment>
  );
}

const FollowersFollowingDataComp = ({ url, other, profile, followers, id }) => {
  return (
    <div className="tofollower">
      <div className={url === "followers" ? "totitle totitleBlue" : "totitle"}>
        {other ? (
          <Link to={`/view/team/${profile?._id || id}/followers`}>
            <p className="toFollow"> Followers</p>
            <p className="tocount"> {followers?.length}</p>
          </Link>
        ) : (
          <Link to={`/player/team/${profile?._id || id}/followers`}>
            <p className="toFollow"> Followers</p>
            <p className="tocount"> {followers?.length}</p>
          </Link>
        )}
      </div>
    </div>
  );
};
const XsButtonsRow = ({ other, EditButton, isAdmin, FollowFollowingButton }) => {
  return (
    <Row className="tosubnav d-none teampanelnav d-md-block">
      <Col span={24}>
        {isAdmin && !other ? (
          <Col style={{ marginLeft: "auto" }} className="tosubnavcenter">
            <EditButton />
          </Col>
        ) : (
          <Col style={{ marginLeft: "auto" }} className="tosubnavcenter">
            <FollowFollowingButton />
          </Col>
        )}
      </Col>
    </Row>
  );
};
const SubLinksWrapper = ({
  profile,
  other,
  url,
  setIsOpen,
  id,
  isOpen,
  isAdmin,
  EditButton,
  FollowFollowingButton,
  flag,
  isPartOfTheTeam
}) => {
  const screens = useBreakpoint();
  return (
    <Fragment>
      {screens["sm"] ? (
        <SubLinksDesktop
          FollowFollowingButton={FollowFollowingButton}
          EditButton={EditButton}
          setIsOpen={setIsOpen}
          profile={profile}
          isAdmin={isAdmin}
          isOpen={isOpen}
          other={other}
          flag={flag}
          url={url}
          id={id}
          isPartOfTheTeam={isPartOfTheTeam}
        />
      ) : (
        <SubLinksTabs
          setIsOpen={setIsOpen}
          profile={profile}
          isAdmin={isAdmin}
          isOpen={isOpen}
          other={other}
          url={url}
          id={id}
          isPartOfTheTeam={isPartOfTheTeam}
        />
      )}
    </Fragment>
  );
};
const SubLinksDesktop = ({
  profile,
  other,
  url,
  setIsOpen,
  id,
  isOpen,
  isAdmin,
  EditButton,
  FollowFollowingButton,
  flag,
  isPartOfTheTeam
}) => {
  return (
    <Col xl={20} lg={19} md={17} sm={15} xs={24}>
      <Row className="subnavLinks  subnavLinks2">
        <Col className="tosubnavcenter">
          <Link
            to={
              other
                ? `/view/team/${profile?._id || id}/timeline`
                : `/player/team/${profile?._id || id}/timeline`
            }>
            <p className={url === "timeline" ? "toactive" : null}>Timeline</p>
          </Link>
        </Col>
        <Col className="tosubnavcenter">
          <Link
            to={
              other
                ? `/view/team/${profile?._id || id}/media`
                : `/player/team/${profile?._id || id}/media`
            }>
            <p className={url === "media" ? "toactive" : null}>Media </p>
          </Link>
        </Col>
        <Col className="tosubnavcenter">
          <Link
            to={
              other
                ? `/view/team/${profile?._id || id}/members`
                : `/player/team/${profile?._id || id}/members`
            }>
            <p className={url === "members" ? "toactive" : null}>Members </p>
          </Link>
        </Col>
        <Col className="tosubnavcenter">
          <Link
            to={
              other
                ? `/view/team/${profile?._id || id}/sponsors`
                : `/player/team/${profile?._id || id}/sponsors`
            }>
            <p className={url === "sponsors" ? "toactive" : null}>Sponsors</p>
          </Link>
        </Col>
        <Col className="tosubnavcenter">
          <Link
            to={
              other
                ? `/view/team/${profile?._id || id}/about`
                : `/player/team/${profile?._id || id}/about`
            }>
            <p className={url === "about" ? "toactive" : null}>About</p>
          </Link>
        </Col>
        {/* Buttons */}
        {isAdmin && !other ? (
          <>
            <Col className="tosubnavcenter">
              <p style={{ cursor: "pointer" }} className="notificationsnavbutton">
                <span
                  onClick={(e) => {
                    e.currentTarget.classList.toggle("toactive");
                    setIsOpen(!isOpen);
                  }}>
                  Notifications
                </span>
                {isOpen !== false ? <Notification open={isOpen} setOpen={setIsOpen} /> : null}
              </p>
            </Col>
            <Col className="tosubnavcenter">
              {isAdmin && !other && (
                <Link to={`/player/team/${profile?._id || id}/wallet`}>
                  <p className={url === "wallet" ? "toactive" : null}>Wallet</p>
                </Link>
              )}
            </Col>
          </>
        ) : null}
        {isPartOfTheTeam && (
          <Col className="tosubnavcenter">
            {isAdmin && !other && (
              <Link to={`/player/team/${profile?._id || id}/discussion`}>
                <p className={url === "discussion" ? "toactive" : null}>Discussion</p>
              </Link>
            )}
          </Col>
        )}
        <Fragment>
          {isAdmin && !other ? (
            <Col style={{ marginLeft: "auto" }} className="tosubnavcenter teampanelnav d-md-none">
              <EditButton />
            </Col>
          ) : (
            <Col
              style={{
                marginLeft: "auto",
                minWidth: flag === "player" ? "300px" : undefined,
                justifyContent: "flex-end"
              }}
              className="tosubnavcenter teampanelnav d-md-none"
              lg={3}
              md={4}
              xs={12}
              sm={4}>
              <FollowFollowingButton />
            </Col>
          )}
        </Fragment>
      </Row>
    </Col>
  );
};
const SubLinksTabs = ({ setIsOpen, profile, isAdmin, isOpen, other, url, id, isPartOfTheTeam }) => {
  const arr = [
    {
      label: "timeline",
      url: other
        ? `/view/team/${profile?._id || id}/timeline`
        : `/player/team/${profile?._id || id}/timeline`,
      key: "timeline"
    },
    {
      label: "media",
      url: other
        ? `/view/team/${profile?._id || id}/media`
        : `/player/team/${profile?._id || id}/media`,
      key: "media"
    },
    {
      label: "members",
      url: other
        ? `/view/team/${profile?._id || id}/members`
        : `/player/team/${profile?._id || id}/members`,
      key: "members"
    },
    {
      label: "sponsors",
      url: other
        ? `/view/team/${profile?._id || id}/sponsors`
        : `/player/team/${profile?._id || id}/sponsors`,
      key: "sponsors"
    },
    {
      label: "about",
      url: other
        ? `/view/team/${profile?._id || id}/about`
        : `/player/team/${profile?._id || id}/about`,
      key: "about"
    },
    !other &&
      isAdmin && {
        label: (
          <p style={{ cursor: "pointer" }} className="notificationsnavbutton">
            <span
              onClick={(e) => {
                e.currentTarget.classList.toggle("toactive");
                setIsOpen(!isOpen);
              }}>
              Notifications
            </span>
            {isOpen !== false ? <Notification open={isOpen} setOpen={setIsOpen} /> : null}
          </p>
        ),
        key: "notifications"
      }
  ];

  if (isAdmin && !other) {
    arr.push({
      label: (
        <Link to={`/player/team/${profile?._id || id}/wallet`}>
          <p className={url === "wallet" ? "toactive" : null}>Wallet</p>
        </Link>
      ),
      key: "wallet"
    });
  }

  if (isPartOfTheTeam) {
    arr.push({
      label: (
        <Link to={`/player/team/${profile?._id || id}/discussion`}>
          <p className={url === "discussion" ? "toactive" : null}>Discussion</p>
        </Link>
      ),
      key: "discussion"
    });
  }

  const navigate = useNavigate();

  return (
    <Tabs
      tabPosition={"top"}
      centered
      activeKey={url}
      size="small"
      onChange={(e) => {
        if (!String(e).includes("notifications")) navigate(e);
      }}
      className="MobileSubNavTabLinks">
      {arr.map(({ label, key }) => (
        <TabPane tab={label} key={`${key}`} />
      ))}
    </Tabs>
  );
};
