import { KEYS } from "../Keys";

// formdata
export const CreateEvent = async (values) => {
  const response = await fetch(KEYS.API + "/event/createevent", {
    method: "POST",
    headers: {
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: values
  });
  return response;
};

// formdata
export const EditEvent = async (values) => {
  const response = await fetch(KEYS.API + "/event/editevent", {
    method: "POST",
    headers: {
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: values
  });
  return response;
};
export const GetEvents = async () => {
  const response = await fetch(KEYS.API + "/event/getevents", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    }
  });
  return response;
};

export const RegisterEvents = async (values) => {
  const response = await fetch(KEYS.API + "/event/registerevents", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

export const UnRegisterEvents = async (values) => {
  const response = await fetch(KEYS.API + "/event/unregisterevents", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

//event/requestteamcaptain

export const NotifyCaptain = async (values) => {
  const response = await fetch(KEYS.API + "/event/requestteamcaptain", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

export const GetSingleEvent = async (values) => {
  const response = await fetch(KEYS.API + "/event/getsingleevent", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

// organizer   events

export const GetOrganizerCurrentEvents = async (values) => {
  const response = await fetch(KEYS.API + "/event/getorganizercurrentevents", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

export const GetOrganizerPastEvents = async (values) => {
  const response = await fetch(KEYS.API + "/event/getorganizerpastevents", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

export const CreateEventPosts = async (values) => {
  const response = await fetch(KEYS.API + "/event/createeventpost", {
    method: "POST",
    headers: {
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: values
  });
  return response;
};

export const EventPostLike = async (values) => {
  const response = await fetch(KEYS.API + "/event/likepost", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

// unlikepost

export const EventPostUnLike = async (values) => {
  const response = await fetch(KEYS.API + "/event/unlikepost", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

// commentpost
export const CommentPost = async (values) => {
  const response = await fetch(KEYS.API + "/event/commentpost", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

// replycommentpost
export const ReplyCommentPost = async (values) => {
  const response = await fetch(KEYS.API + "/event/replycommentpost", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

// editcommentpost
export const EditCommentPost = async (values) => {
  const response = await fetch(KEYS.API + "/event/editcommentpost", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

// editreplycommentpost
export const EditReplyCommentPost = async (values) => {
  const response = await fetch(KEYS.API + "/event/editreplycommentpost", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

// deletecommentpost
export const DeleteCommentPost = async (values) => {
  const response = await fetch(KEYS.API + "/event/deletecommentpost", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

// deletereplycommentpost
export const DeleteReplyCommentPost = async (values) => {
  const response = await fetch(KEYS.API + "/event/deletereplycommentpost", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

export const GetEventPosts = async (values, page = 1, limit = 10) => {
  const response = await fetch(KEYS.API + `/event/geteventposts?page=${page}&limit=${limit}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

export const EditEventPosts = async (values) => {
  const response = await fetch(KEYS.API + "/event/editeventpost", {
    method: "POST",
    headers: {
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: values
  });
  return response;
};

export const DeleteEventPosts = async (values) => {
  const response = await fetch(KEYS.API + "/event/deleteeventpost", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};
//event/deleteeventpost

export const UpdateEventScheduledata = async (values) => {
  const response = await fetch(KEYS.API + "/eventschedule/updateeventscheduledata", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

//

export const CloseRegistration = async (values) => {
  const response = await fetch(KEYS.API + "/event/changeregistrationstatus", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

export const ToggleOrgMarkedEvents = async (values) => {
  const response = await fetch(KEYS.API + "/event/addorgmarkedevents", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};
export const TogglePlayerMarkedEvents = async (values) => {
  const response = await fetch(KEYS.API + "/event/addplayermarkedevents", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

// GetOrg Marked Events
export const GetOrganizerMarkedEvents = async (values) => {
  const response = await fetch(KEYS.API + "/event/getorganizermarkedevents", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

// GetOrg Marked Events
export const GetPlayerMarkedEvents = async (values) => {
  const response = await fetch(KEYS.API + "/event/getplayermarkedevents", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};
// Table Data  --leagues
export const GetGroupEventData = async (values) => {
  const response = await fetch(KEYS.API + "/eventschedule/getgroupeventdata", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};
// Rounds Data -- Brackets knockouts
// eventschedule/getknockouteventdata
export const GetKnockOutEventData = async (values) => {
  const response = await fetch(KEYS.API + "/eventschedule/getknockouteventdata", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};
export const GetDoubleKnockOutEventData = async (values) => {
  const response = await fetch(KEYS.API + "/eventschedule/getdoubleknockouteventdata", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};
// Fixtures
export const CreateGroupSlots = async (values) => {
  const response = await fetch(KEYS.API + "/eventschedule/creategroupslots", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

// Create Knockout eventschedule/createknockoutslot

export const CreateKnockOutSlots = async (values) => {
  const response = await fetch(KEYS.API + "/eventschedule/createknockoutslot", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

// Create Knockout eventschedule/createknockoutslot

export const CreateDoubleEliminationSlots = async (values) => {
  const response = await fetch(KEYS.API + "/eventschedule/createdobuleeliminationslot", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

export const CreateDoubleEliminationBYESlots = async (values) => {
  const response = await fetch(KEYS.API + "/eventschedule/createdobuleeliminationslotbye", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};
// Create Knockout BYE eventschedule/createknockoutslot

export const CreateKnockOutSlotsBYE = async (values) => {
  const response = await fetch(KEYS.API + "/eventschedule/createknockoutslotbye", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

// eventschedule/updategroupfixtures
export const UpdateGroupFixtures = async (values) => {
  const response = await fetch(KEYS.API + "/eventschedule/rearrangegroupfixtures", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

// winner(groups,leagues)
export const AssignWinner = async (values) => {
  const response = await fetch(KEYS.API + "/eventschedule/assignwinner", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

export const MoveToKnockOuts = async (values) => {
  const response = await fetch(KEYS.API + "/eventschedule/movetoknockouts", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

//eventschedule/rearrangeknockoutfixtures
export const RearrangeKnockoutFixtures = async (values) => {
  const response = await fetch(KEYS.API + "/eventschedule/rearrangeknockoutfixtures", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

// eventschedule/assignwinnerknockouts
export const AssignKnockOutWinner = async (values) => {
  const response = await fetch(KEYS.API + "/eventschedule/assignwinnerknockouts", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

// eventschedule/assignwinnerknockouts
export const ReAssignKnockOutWinner = async (values) => {
  const response = await fetch(KEYS.API + "/eventschedule/reassignwinnerknockouts", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

// eventschedule/assignwinnerknockouts
export const AssignDKWinner = async (values) => {
  const response = await fetch(KEYS.API + "/eventschedule/assignwinnerdko", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};
// eventschedule/reassignwinnerdko
export const ReAssignDKOWinner = async (values) => {
  const response = await fetch(KEYS.API + "/eventschedule/reassignwinnerdko", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

export const GetOrganizerDraftedEvents = async () => {
  const response = await fetch(KEYS.API + "/event/getdraftevents", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    }
  });
  return response;
};

export const GetAwardsBySportsType = async (type) => {
  const response = await fetch(KEYS.API + `/award?sportType=${type}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    }
  });
  return response;
};

export const GetAwardsForEvent = async (eventId) => {
  const response = await fetch(KEYS.API + `/event-award/${eventId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    }
  });
  return response;
};

export const GetAllTeamsInEvent = async (eventId) => {
  const response = await fetch(KEYS.API + `/event/teams/${eventId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    }
  });
  return response;
};

export const AssignMultiPlayerScheduleEventsAward = async (values) => {
  const response = await fetch(KEYS.API + `/mulitiplayerSchedule/assign-award`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

export const GetEventWildCards = async (eventId) => {
  const response = await fetch(KEYS.API + `/event/wildcard/${eventId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    }
  });
  return response;
};

export const EventWildCardRegistration = async (values) => {
  const response = await fetch(KEYS.API + "/event/registereventsbywildcard", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

export const DeleteEventAward = async (awardId) => {
  const response = await fetch(KEYS.API + `/event-award/${awardId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    }
  });
  return response;
};

export const GetEventAssignAwards = async (eventID) => {
  const response = await fetch(KEYS.API + `/award/event/${eventID}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    }
  });
  return response;
};

/**
 * Get player events , current or past based on type
 * @param {string} playerId
 * @param {string} type //current or past
 */
export const GetPlayerCurrentPastEvents = async (playerId, type) => {
  const values = {
    playerId,
    filter: type
  };
  const response = await fetch(KEYS.API + `/player/getplayerevents`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

// rate an event
export const RateEvent = async ({ rating, comment, eventId }) => {
  const payload = {
    rating,
    comment,
    eventId
  };

  const response = await fetch(KEYS.API + `/event/createrating`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(payload)
  });
  return response;
};

export const GetEventRatingsList = async (eventId) => {
  const response = await fetch(KEYS.API + `/event/getsingleeventrating`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify({ eventId })
  });
  return response;
};
