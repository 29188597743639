import { error, success } from "../../../common/Alerts/Message";
import {
  PlayerProfileToggleApi,
  TeamProfileToggleApi,
  OrganizerProfileToggleApi,
  PanelProfileToggleApi
} from "../../../Config/API/profiles";
import { useEffect, useState } from "react";
import { useFetchProfileFunc } from "../../hooks/useFetchProfileFunc";
import { useLocation, useParams } from "react-router-dom";
import { useCurrentPage } from "../../hooks/useCurrentPage";

export const useEditProfile = () => {
  // const [status, setstatus] = useState()
  // const [pnumber, setpnumber] = useState()
  // const [fname, setfname] = useState()
  // const [lname, setlname] = useState()
  // const [nickname, setnickname] = useState()
  const [role, setrole] = useState();
  const { pathname } = useLocation();
  const { id } = useParams();
  const { state } = useLocation();
  const { currentflag, currentid } = state;
  useEffect(() => {
    GetProfiles(currentid, currentflag);
  }, [id, pathname, state]);
  var { GetProfiles, profile, setprofile, loading, setloading } = useFetchProfileFunc();

  // useEffect(() => {
  //     setstatus(profile?.profileType)
  //     const name = String(profile?.name).split(" ")
  //     setfname(name[0])
  //     setlname(name[1])
  //     setnickname(profile?.nickname || profile?.user?.nickName)
  //     setpnumber(profile?.user?.phone)
  //     setrole(profile?.user?.primaryRole)
  //     console.log(profile);
  // }, [profile])
  const { isPlayer, isOrganizer, isPanel, isTeam } = useCurrentPage();

  // request function
  const request = async (res, data, values, currentflag) => {
    setloading(true);
    if (currentflag === "player") res = await PlayerProfileToggleApi(values);
    if (currentflag === "team") res = await TeamProfileToggleApi(values);
    if (currentflag === "organizer") res = await OrganizerProfileToggleApi(values);
    if (currentflag === "panel") res = await PanelProfileToggleApi(values);

    data = await res.json();
    if (data?.type === "success") {
      setprofile(data?.data);
      success(data?.result);
      console.log(data);
    } else {
      error(data?.result);
    }
    setloading(false);
  };

  const ChangeProfileStatus = async (e) => {
    try {
      console.log(currentid, e);
      var values = {
          id: currentid,
          profileType: e
        },
        res = null,
        data = null;
      if (isTeam || isOrganizer || isPanel || isPlayer) {
        if (e) request(res, data, values, currentflag);
      } else {
        error("params doesn't match with storage values");
        console.log("params doesn't match with storage values");
      }
    } catch (e) {
      error(e?.message);
      setloading(false);
    }
  };
  return {
    profile,
    setprofile,
    loading,
    setloading,
    ChangeProfileStatus,
    role,
    setrole
  };
};
