import { Row, Col, Button } from "antd";
import React, { useState, Fragment, useEffect } from "react";
import ActiveMembers from "./ActiveMembers";
import InviteMembers from "./InviteMembers";
import { SideBar } from "../../../common/sidebar";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { error } from "../../../common/Alerts/Message";
import { GetSingleTeam } from "../../../Config/API/teams";
import { GetSinglePanel } from "../../../Config/API/panel";
import Loader from "../../../common/cards/Loader";
import NoData from "../../../common/cards/noData";
import MembersRequests from "./MembersRequests";
import { checkIfPartOfTheTeamOrInvited } from "../../../common/Utils";
import useVariables from "../../../common/hooks/useVariables";
// import MembersRequests from "./MembersRequests";

const ViewProfileMembers = ({ current }) => {
  const [active, setactive] = useState("primary");
  const [invite, setinvite] = useState("");
  const [request, setrequest] = useState("");
  const [team, setteam] = useState({});
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const { state } = useLocation();
  const { viewer } = useVariables();
  const [isViewerMember, setIsViewerMember] = useState(false);

  const getteam = async () => {
    try {
      setloading(true);
      let response, data, Captain;
      if (current === "team") {
        response = await GetSingleTeam(id);
        data = await response.json();
        // Captain = {
        //   player: {
        //     _id: data.result?.profile?._id,
        //     profilepic: data.result?.profile?.profilepic,
        //     name: data.result?.profile?.name
        //   },
        //   role: "Team Creator",
        //   active: true,
        //   owner: true
        // };
        // data?.result?.members?.unshift(Captain);
      } else if (current === "panel") {
        response = await GetSinglePanel(id);
        data = await response.json();
        Captain = {
          organizer: {
            _id: data.result?.profile?._id,
            profilepic: data.result?.profile?.profilepic,
            name: data.result?.profile?.name
          },
          role: "Panel Creator",
          active: true,
          owner: true
        };
        data?.result?.members?.unshift(Captain);
      }

      setteam(data.result);
      setIsViewerMember(checkIfPartOfTheTeamOrInvited(data?.result?.members, viewer?.id));

      console.log(data?.result);
      setloading(false);
    } catch (e) {
      setloading(false);
      console.log(e);
      error(e.message);
    }
  };

  useEffect(() => {
    if (!id) {
      navigate(-1);
    }
    getteam();
    return () => {
      setactive(null);
      setinvite(null);
      setteam(null);
      setloading(false);
    };
  }, []);
  return (
    <Fragment>
      <Row className="rowPadding">
        <SideBar description ratingPanel location={state === "team" || state === "panel"} />
        <Col xs={24} sm={24} md={24} lg={16}>
          {/* <FollowerFollowing /> */}
          <Row className="mdWidth">
            {/* <Col lg={24} md={24} sm={24} xs={24}>
                            <h1
                                style={{ paddingLeft: "0" }}
                                className="popadding">
                                {
                                    myString.trim().replace(/^\w/, (c) => c.toUpperCase())
                                }
                            </h1>
                        </Col> */}
            <Col className="mobilecenterbutton" lg={14} md={20} sm={20} xs={24}>
              <Button
                style={{ marginLeft: "0" }}
                className="buttonpo"
                onClick={() => {
                  setactive("primary");
                  setinvite("");
                  setrequest("");
                }}
                type={active}>
                Active Members
              </Button>
              {isViewerMember && (
                <Button
                  style={{ marginLeft: "0" }}
                  className="buttonpo"
                  type={invite}
                  onClick={() => {
                    setactive("");
                    setinvite("primary");
                    setrequest("");
                  }}>
                  Invited Members
                </Button>
              )}
              {isViewerMember && (
                <Button
                  style={{ marginLeft: "0" }}
                  className="buttonpo"
                  type={request}
                  onClick={() => {
                    setactive("");
                    setinvite("");
                    setrequest("primary");
                  }}>
                  Members Request
                </Button>
              )}
            </Col>

            {loading ? (
              <Loader />
            ) : (
              <Col lg={24} md={24} xs={24} sm={24}>
                {team?.members?.length > 0 ? (
                  <Fragment>
                    {invite === "primary" ? <InviteMembers current={current} data={team} /> : null}
                    {active === "primary" ? (
                      <ActiveMembers data={team} current={current} setdata={setteam} />
                    ) : null}
                    {request === "primary" ? <MembersRequests data={team} /> : null}
                  </Fragment>
                ) : (
                  <NoData noMembers />
                )}
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </Fragment>
  );
};

export default ViewProfileMembers;
