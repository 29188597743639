import { KEYS } from "../Keys";

// winner(groups,leagues)
export const AssignWinner = async (values) => {
  const response = await fetch(KEYS.API + "/eventschedule/assignwinner", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

// winner(groups,leagues)
export const AssignDraw = async (values) => {
  const response = await fetch(KEYS.API + "/eventschedule/assigndraw", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};
