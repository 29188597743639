import { Button } from "antd";
import React from "react";

function MatchTeam({ t, item }) {
  const Id_1 = item?.match?.team1?._id;
  const Id_2 = item?.match?.team2?._id;
  const win = item?.match?.win;
  const pendingwin = !win || win === undefined || win === "";
  const classes = "btnMobileDimensions draftbutton equalWidhtButton m-0";

  return (
    <div className="MatchCardTeamInfo">
      <div>
        {t === 1 ? item?.match?.team1?.name : item?.match?.team2?.name}
        {win === (t === 1 ? Id_1 : Id_2) ? <p style={{ color: "var(--green)" }}>(Winner)</p> : null}
        {win === (t === 1 ? Id_2 : Id_1) ? <p style={{ color: "var(--red)" }}>(loser)</p> : null}
      </div>
      {/* buttons are placed for spacing */}
      {pendingwin ? null : (
        <Button
          type="primary"
          style={{ opacity: "0", userSelect: "none" }}
          className={`${classes}  ${win === (t === 1 ? Id_2 : Id_1) ? "GREY" : undefined} `}>
          Winner
        </Button>
      )}
    </div>
  );
}

export default MatchTeam;
