import React from "react";
import { Layout } from "antd";
import PlayerTLNav from "./PlayerTLNav";

import { Outlet } from "react-router-dom";
import { COLORS } from "../../../Config/Colors";
const { Content } = Layout;
const PlayerTLayout = () => {
  // Id = view profile -player ,team etc
  return (
    <Layout>
      <div style={{ backgroundColor: COLORS.GREY }}>
        <PlayerTLNav />
      </div>
      <Content>
        <Outlet />
      </Content>
      {/* <Footer style={{ backgroundColor: "#2F89FC" }}>
        <HomeFooter />  
      </Footer> */}
    </Layout>
  );
};

export default PlayerTLayout;
