import React from "react";
import { Form, Input, Modal } from "antd";
import { RiSendPlane2Fill } from "react-icons/ri";
import { useRef, useState } from "react";
import Loader from "../../Loader";
import { useEffect } from "react";

const EditModal = ({ isOpen, comment, editComment, setIsOPen, loading, text }) => {
  const [visible, setvisible] = useState(isOpen);
  const [value, setvalue] = useState(comment.comment);
  const form = useRef(null);
  useEffect(() => {
    return () => {
      setvalue(null);
      setvisible(null);
    };
  }, []);
  return (
    <Modal
      title={text}
      className="editPost"
      footer={false}
      visible={visible}
      destroyOnClose
      cancelButtonProps={{ value: "Close" }}
      centered
      closable={false}
      mask
      maskClosable={true}
      onCancel={() => setvisible(false)}>
      <Form
        style={{ margin: "20px 0" }}
        onFinish={async (e) => {
          await editComment(e.value, comment?._id);
          setvalue("");
          setIsOPen(false);
        }}>
        <Form.Item name={"value"} initialValue={value}>
          <Input
            value={value}
            onChange={(e) => setvalue(e.currentTarget.value)}
            ref={form}
            style={{
              display: "flex",
              alignItems: "center",
              borderRadius: "20px",
              padding: "10px 15px",
              marginLeft: "5px"
            }}
            suffix={
              loading === comment?._id + 1 ? (
                <Loader smaller noShadow />
              ) : (
                <RiSendPlane2Fill
                  color="var(--blue)"
                  cursor={"pointer"}
                  fontSize={20}
                  onClick={async () => {
                    await editComment(value, comment?._id);
                    setvalue("");
                    setIsOPen(false);
                  }}
                />
              )
            }
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditModal;
