import { Col, Row, Image } from "antd";
import React from "react";
import FadeInImage from "../../../../../common/Images/FadeInImage";
import { fallbackcover } from "../../../../../common/Utils";

function EventFiles({ edit, file, isVisible, onImageFileChange }) {
  if (!isVisible) return null;

  if (edit) {
    return (
      <Row style={{ marginBottom: "30px" }}>
        <Col sm={24} xs={24} md={24} style={{ position: "relative" }}>
          <div
            style={{
              position: "relative",
              width: "96%",
              margin: "auto",
              background: "#DADADA",
              borderRadius: "8px"
            }}
            className="CoverImageWrapper">
            <FadeInImage
              className="CoverImage"
              type={"Fadecover"}
              radiusAll
              src={file?.preview === "" ? null : file?.preview}
            />

            <div className="profileuploaders">
              <label htmlFor="coveruploader" style={{}}>
                <img src="/images/icons/camera.png" alt="" />
                {file?.preview.includes("undefined") || !file?.preview ? (
                  <span>Add your Cover image</span>
                ) : null}
              </label>
              <input
                type="file"
                name="CoverImage"
                className="uploaderInput"
                id="coveruploader"
                accept="image/*"
                onChange={onImageFileChange}
              />
            </div>
          </div>
        </Col>
      </Row>
    );
  } else
    return (
      <Row style={{ marginBottom: "30px" }}>
        <Col sm={24} xs={24} md={24} style={{ position: "relative" }}>
          <div
            style={{
              position: "relative",
              width: "96%",
              margin: "auto",
              background: "#DADADA",
              borderRadius: "8px"
            }}
            className="CoverImageWrapper">
            {file?.preview?.length > 1 ? (
              <Image
                wrapperClassName="CoverImageWrapper"
                className="CoverImage"
                preview={false}
                src={file?.preview}
                style={{
                  objectFit: file?.preview ? "cover" : "cover"
                  // transform: coverImage ? "scale(.5)" : "scale(1)"
                }}
                fallback={fallbackcover}
              />
            ) : null}
            <div className="profileuploaders">
              <label htmlFor="coveruploader" style={{}}>
                <img src="/images/icons/camera.png" alt="" />
                {file?.preview === "" ? <span>Add your Cover image</span> : null}
              </label>
              <input
                type="file"
                name="CoverImage"
                className="uploaderInput"
                id="coveruploader"
                accept="image/*"
                onChange={onImageFileChange}
              />
            </div>
          </div>
        </Col>
      </Row>
    );
}

export default EventFiles;
