import React from "react";
import { Form, Button, Input, Row, Col } from "antd";

function EmailForm({ handleOnEmailChange, MailSubmit, VEmail, loading }) {
  return (
    <Row justify="center">
      <Col md={2}></Col>
      <Col md={20} xs={24} sm={24}>
        <Form
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          onFinish={MailSubmit}
          autoComplete="off">
          <Form.Item name="email" hasFeedback validateStatus={VEmail} label="Your Email">
            <Input
              onChange={(e) => handleOnEmailChange(e.target.value)}
              placeholder="Enter your email"
              style={{ borderRadius: "5px", lineHeight: "28px" }}
            />
          </Form.Item>

          <Form.Item style={{ justifyContent: "center" }}>
            <Button loading={loading} className="submitButton" type="primary" htmlType="submit">
              Next
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
}

export default EmailForm;
