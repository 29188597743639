import React from "react";
import { Fragment } from "react";
import { useEventHistory } from "../../../common/hooks/useEventHistory";
import { ROLES } from "../../../Config/helpers/constants";
import { useSelector } from "react-redux";
import { PROFILE } from "../../../Store/Features/ProfileSlice";
import Loader from "../../../common/cards/Loader";
import { useParams } from "react-router-dom";
import EventHistoryCard from "../../../common/EventHistroy/EventHistoryCard";
import TimelineDot from "../../../common/EventHistroy/TimelineDot";
import "../../../common/EventHistroy/EventHistoryCard.css";
import { Grid, Timeline } from "antd";
import { KEYS } from "../../../Config/Keys";
function PlayerEventHistory({ current }) {
  const userDetails = useSelector(PROFILE);
  const { id } = useParams();

  const { historyLoading, historyData } = useEventHistory(
    current ?? ROLES.player,
    id ?? userDetails?._id
  );

  const { useBreakpoint } = Grid;
  const breakpoints = useBreakpoint();

  return (
    <Fragment>
      <h3 style={{ fontWeight: 550, marginTop: "4px", textAlign: "center" }}>Event History</h3>

      {historyLoading ? (
        <Loader />
      ) : historyData?.length > 0 ? (
        <div className="event-history-wrapper">
          {breakpoints?.md ? (
            <Timeline mode={breakpoints.md ? "alternate" : "left"}>
              {historyData?.reverse()?.map((event, index) => (
                <Timeline.Item
                  key={index}
                  dot={
                    <TimelineDot
                      img={
                        event?.file?.file
                          ? KEYS.API + "/" + event?.file?.file
                          : "/images/homesports.png"
                      }
                    />
                  }>
                  <EventHistoryCard data={event} historyFor={current ?? ROLES.player} />
                </Timeline.Item>
              ))}
            </Timeline>
          ) : (
            historyData.map((event, index) => (
              <EventHistoryCard key={index} data={event} historyFor={current ?? ROLES.player} />
            ))
          )}
        </div>
      ) : (
        <h3 style={{ textAlign: "center" }}>Event History Not Found</h3>
      )}
    </Fragment>
  );
}

export default PlayerEventHistory;
