import React, { Fragment } from "react";
import { Col, Row, Typography } from "antd";
import ExploreEventsImgCard from "../cards/ExploreEventCard/ExploreEventImgCard";
import ExploreEventsAccordian from "./Accordians/ExploreEventsAccordian";
const { Title } = Typography;

const ExploreEvents = () => {
  const imageProps = {
    className: "roundedImages",
    styles: {
      borderRadius: "10px",
      height: "100px"
    }
  };
  return (
    <Fragment>
      <Row className=" " style={{ width: "85%", margin: "2% auto", marginTop: "10 0px" }}>
        <Col span={24}>
          <Typography>
            <Title
              level={4}
              className="toeventhistory"
              style={{ padding: "0 .5% 5% .5%", width: "85%", margin: "auto" }}>
              Events History
            </Title>
          </Typography>
        </Col>
        <Col span={24}>
          <ExploreEventsImgCard props={imageProps} text1="Cricket" text2="Football" />
          <ExploreEventsImgCard props={imageProps} text1="Volleyball" text2="Ice Hockey" />
          <ExploreEventsImgCard props={imageProps} text1="Cricket" text2="Football" />
          <ExploreEventsImgCard props={imageProps} text1="Volleyball" text2="Ice Hockey" />
        </Col>
        <Col span={24}>
          <p
            style={{
              fontSize: "12px",
              marginTop: "15px",
              color: "#0085FF",
              fontWeight: "600",
              cursor: "pointer",
              textAlign: "center"
            }}>
            Show More
          </p>
        </Col>
      </Row>
    </Fragment>
  );
};

export default function ExploreEventsDefault() {
  return (
    <Fragment>
      <div className="d-md-block d-none">
        <ExploreEventsAccordian />
      </div>
      <div className="d-md-none">
        <ExploreEvents />
      </div>
    </Fragment>
  );
}
