import React, { Fragment, useEffect } from "react";
import { Routes, Route, useLocation, useParams, useNavigate } from "react-router-dom";
import PageNotFound from "../../common/cards/PageNotFound/PageNotFound";
import useVariables from "../../common/hooks/useVariables";
import OrganizerLayout from "../layout/organizerLayout/OrganizerLayout";
import PlayerLayout from "../layout/playerlayout/PlayerLayout";
import Home from "./Home";
import SponsorLayout from "../layout/sponsorLayout/SponsorLayout";
function ViewWrapper() {
  const { pathname } = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { viewer } = useVariables();
  const token = localStorage.getItem("userToken");

  useEffect(() => {
    if (token && !viewer?.flag) {
      navigate("/signin");
    }
  }, [id, pathname, navigate]);

  return (
    <Routes>
      <Route
        path="/*"
        element={
          <Fragment>
            {viewer?.flag === "player" && <PlayerLayout />}
            {viewer?.flag === "organizer" && <OrganizerLayout />}
            {viewer?.flag === "sponsor" && <SponsorLayout />}
          </Fragment>
        }>
        <Route path="player/:id/*" element={<Home />} />
        <Route path="organizer/:id/*" element={<Home />} />
        <Route path="team/:id/*" element={<Home />} />
        <Route path="panel/:id/*" element={<Home />} />
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  );
}

export default ViewWrapper;
