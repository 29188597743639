import { Route, Routes, useParams } from "react-router-dom";
import Home from "./Home/Home";
import TL from "./Timeline/Timeline";
import Members from "./Members/Members";
import About from "./About";
import Sponsors from "./Sponsors";
import Media from "../../../common/Media";
import Follow from "../../../common/Follow";
import Layout from "../../layout/teamTLayout/PlayerTeamsTLayout";
import React, { Fragment } from "react";
import PlayerEventHistory from "../EventHistory/PlayerEventsHistory";
import UserWallet from "../../Wallet/UserWallet";
import { ROLES } from "../../../Config/helpers/constants";
import DiscussionRoom from "../../DiscussionRoom/DiscussionRoom";
const Teams = () => {
  const { id } = useParams();

  return (
    <Fragment>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/feed" element={<Home />} />
          <Route path="/timeline" element={<TL />} />
          <Route path="/members" element={<Members />} />
          <Route path="/sponsors" element={<Sponsors />} />
          <Route path="/about" element={<About />} />
          <Route path="/media" element={<Media />} />
          <Route path="/followers" element={<Follow />} />
          <Route path="/followings" element={<Follow />} />
          <Route path="/eventhistory" element={<PlayerEventHistory />} />
          <Route path={`/discussion`} element={<DiscussionRoom type={ROLES.team} id={id} />} />
          <Route path="/wallet" element={<UserWallet userType={ROLES.team} userId={id} />} />

          <Route
            path="*"
            element={
              <h1 style={{ textAlign: "center", margin: "90px 0", fontSize: "xx-large" }}>
                Page Not Found
              </h1>
            }
          />
        </Route>
      </Routes>
    </Fragment>
  );
};

export default Teams;
