// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cteamheading {
  text-align: center;
  font-size: 36px;
  font-weight: bold;
  padding: 3%;
}
.cteamfpadding {
  padding: 2% 5% 2% 5%;
}
.teamnamefield {
  border-radius: 5px;
  line-height: 28px;
  margin-top: 11px;
}
.teamdescfield {
  border-radius: 5px;
  line-height: 28px;
  margin-top: 15px;
}
.mt9 {
  margin-top: 9px;
}
.mt8 {
  margin-top: 8px;
}
.nameofsports {
  line-height: 28px;
  margin-top: 11px;
}
.teamlocation {
  border-radius: 5px;
  line-height: 28px;
  margin-top: 11px;
}
.teamcreateButton {
  margin-top: 11px;
  border-radius: 5px;
  height: 60px;
  width: 30%;
  margin: 50px 0 50px 0;
}

.teamsmodaloverflow {
  min-width: min(800px, 90vw);
  scrollbar-width: none;
}
.teamsmodaloverflow::-webkit-scrollbar,
.teamsmodaloverflow .ant-modal-body::-webkit-scrollbar {
  display: none;
}
.teamsmodaloverflow .ant-modal-body {
  scrollbar-width: none;
  border-radius: 20px !important;
  border: 7px solid #e0e0e0;
}
`, "",{"version":3,"sources":["webpack://./src/styles/player/CreateTeam.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,eAAe;EACf,iBAAiB;EACjB,WAAW;AACb;AACA;EACE,oBAAoB;AACtB;AACA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,gBAAgB;AAClB;AACA;EACE,eAAe;AACjB;AACA;EACE,eAAe;AACjB;AACA;EACE,iBAAiB;EACjB,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,gBAAgB;AAClB;AACA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,YAAY;EACZ,UAAU;EACV,qBAAqB;AACvB;;AAEA;EACE,2BAA2B;EAC3B,qBAAqB;AACvB;AACA;;EAEE,aAAa;AACf;AACA;EACE,qBAAqB;EACrB,8BAA8B;EAC9B,yBAAyB;AAC3B","sourcesContent":[".cteamheading {\n  text-align: center;\n  font-size: 36px;\n  font-weight: bold;\n  padding: 3%;\n}\n.cteamfpadding {\n  padding: 2% 5% 2% 5%;\n}\n.teamnamefield {\n  border-radius: 5px;\n  line-height: 28px;\n  margin-top: 11px;\n}\n.teamdescfield {\n  border-radius: 5px;\n  line-height: 28px;\n  margin-top: 15px;\n}\n.mt9 {\n  margin-top: 9px;\n}\n.mt8 {\n  margin-top: 8px;\n}\n.nameofsports {\n  line-height: 28px;\n  margin-top: 11px;\n}\n.teamlocation {\n  border-radius: 5px;\n  line-height: 28px;\n  margin-top: 11px;\n}\n.teamcreateButton {\n  margin-top: 11px;\n  border-radius: 5px;\n  height: 60px;\n  width: 30%;\n  margin: 50px 0 50px 0;\n}\n\n.teamsmodaloverflow {\n  min-width: min(800px, 90vw);\n  scrollbar-width: none;\n}\n.teamsmodaloverflow::-webkit-scrollbar,\n.teamsmodaloverflow .ant-modal-body::-webkit-scrollbar {\n  display: none;\n}\n.teamsmodaloverflow .ant-modal-body {\n  scrollbar-width: none;\n  border-radius: 20px !important;\n  border: 7px solid #e0e0e0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
