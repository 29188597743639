import React from "react";
import { Row, Col } from "antd";
import { useSelector } from "react-redux";
import ScrollToTop from "../../../common/cards/ScrollToTop";
import RightBar from "../../../common/righbar";
import { SideBar } from "../../../common/sidebar";
import { PROFILE } from "../../../Store/Features/ProfileSlice";
import DiscussionPanel from "./DiscussionPanel";
import SponsorContracts from "../../../common/sidebar/SponsorContracts";
function SponsorTimeline() {
  const userDetails = useSelector(PROFILE);

  return (
    <Row justify="space-between" className="rowPadding">
      <ScrollToTop />
      <SideBar description ratingPanel location awards={false} contracts={<SponsorContracts />} />
      <Col xs={24} sm={24} md={24} lg={11}>
        {userDetails?._id ? <DiscussionPanel /> : null}
      </Col>

      <RightBar />
    </Row>
  );
}

export default SponsorTimeline;
