import React, { Fragment } from "react";
import { Row, Col, Typography } from "antd";
// import { FaLocationArrow } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { KEYS } from "../../../Config/Keys";
import { EDIT_SINGLE_CAMPAIGN } from "../../../Config/helpers/sponsorRoutes";
import FadeInImage from "../../../common/Images/FadeInImage";
import { fallbackcover } from "../../../common/Utils";
import { Truncate } from "../../../common/Text/Truncate";
const CampaignCard = ({ data, drafted }) => {
  console.log(data);
  const navigate = useNavigate();

  return (
    <Fragment>
      <Row
        className="eventcard"
        justify="space-between"
        style={{ background: "white" }}
        onClick={() => {
          navigate(`${EDIT_SINGLE_CAMPAIGN}/${data?._id}`);
        }}>
        <Col span={24}>
          <FadeInImage
            radius
            EventCard
            type={"media"}
            fallaback={fallbackcover}
            src={KEYS.API + "/" + data?.picture}
          />
        </Col>
        <Col lg={15} md={15} xs={24} sm={24}>
          <div style={{ padding: "15px 0px 10px 15px" }}>
            {/* <p style={{ fontSize: "11px", color: "red", margin: "0px" }}>
              <FaCalendarAlt />
              <span style={{ marginLeft: "5px" }}>
                {new Date(data?.startDate).toDateString() +
                  " - " +
                  new Date(data?.endDate).toDateString()}
              </span>
            </p> */}
            <Truncate
              title
              level={5}
              lines={1}
              text={data?.name || "--"}
              styles={{ fontWeight: "500" }}
            />
            {/* <p
              style={{
                fontSize: "11px",
                display: "flex",
                alignItems: "center",
                gap: "5px"
              }}>
              <FaLocationArrow /> {data?.location || "Location Missing"}
            </p> */}
            {drafted && <Typography.Text mark>Drafted</Typography.Text>}
          </div>
        </Col>

        <Col lg={9} md={9} xs={24} sm={24} className="eventCardRightSide">
          {/* <p>
            {data?.registrationComplete ||
            moment(new Date(data?.deadline).setHours(24)) < new Date() ? (
              <span>
                <b>Registration : </b>
                Closed
              </span>
            ) : (
              <span>
                <b>Closing : </b>
                <span>{moment(new Date(data?.deadline).setHours(24)).from()}</span>
              </span>
            )}
          </p> */}
        </Col>
      </Row>
    </Fragment>
  );
};

export default CampaignCard;
