import React, { Fragment, useState, useEffect } from "react";
import { Row, Col } from "antd";
import { useLocation, useParams } from "react-router-dom";
import Followers from "./Followers";
import Following from "./Following";

import Loader from "../../common/cards/Loader";
import { FollowFollowingButtons } from "./functions";
import { useSelector } from "react-redux";
import { PROFILE } from "../../Store/Features/ProfileSlice";

function Follower_Following({ current }) {
  const Profile = useSelector(PROFILE);
  const [active, setactive] = useState(null);
  const [loading, setloading] = useState(false);

  const { id } = useParams();
  const { pathname, state } = useLocation();

  useEffect(() => {}, [id]);
  useEffect(() => {
    setactive(pathname.includes("following") ? 2 : 1);
  }, [pathname]);

  return (
    <Row className="mdWidth">
      <FollowFollowingButtons
        setloading={setloading}
        state={state}
        setactive={setactive}
        active={active}
        current={current}
      />
      <Col lg={24} md={24} xs={24} sm={24}>
        {loading ? (
          <Loader />
        ) : (
          <Fragment>
            {active === 1 ? <Followers data={Profile?.followers} /> : null}
            {active === 2 ? <Following data={Profile?.followings} /> : null}
          </Fragment>
        )}
      </Col>
    </Row>
  );
}

export default Follower_Following;
