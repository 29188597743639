import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  flexTokens: 0
};

export const WalletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    SetFlexToken: (state, action) => {
      state.flexTokens = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { SetFlexToken } = WalletSlice.actions;

export default WalletSlice.reducer;
