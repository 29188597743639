import React, { useEffect, useState } from "react";
import { Button, Col, List, Modal, Row } from "antd";
import Loader from "../../../../../../../common/cards/Loader";
import { useDispatch, useSelector } from "react-redux";
import { EditMultiplayerRoundSettings } from "../../../../../../../Config/API/multiplayerSchedule";
import {
  setMatchObj,
  seteventcomplete,
  setroundvariables
} from "../../../../../../../Store/Features/MatchesSlice";
import { error, success } from "../../../../../../../common/Alerts/Message";
import getEventIndexesAndRankPoints from "../../../../../../../common/hooks/getEventIndexesAndRankPoints";
import { InputNumber } from "antd";
import { Form } from "antd";

function ScheduleRounEditModal({ eventId, currentround, open, setopen, getData }) {
  const eventData = useSelector((state) => state.matches);
  const roundvariables = eventData?.roundvariables;

  const currentroundvariables = roundvariables[currentround];
  const [requesting, setrequesting] = useState(false);
  const [killpoints, setkillpoints] = useState();
  const [istrankpoints, setistrankpoints] = useState();
  const [lastrankpoints, setlastrankpoints] = useState();
  const dispatch = useDispatch();

  // individual ranks points based on istrankpoints,lastrankpoints
  const { individualRankPoints } = getEventIndexesAndRankPoints(istrankpoints, lastrankpoints);

  useEffect(() => {
    if (currentroundvariables?.killpoints) {
      setkillpoints(parseInt(currentroundvariables?.killpoints));
      setistrankpoints(parseInt(currentroundvariables?.istrankpoints));
      setlastrankpoints(parseInt(currentroundvariables?.lastrankpoints));
    }
  }, [currentroundvariables]);

  useEffect(() => {
    return () => {
      setkillpoints(null);
      setistrankpoints(null);
      setlastrankpoints(null);
    };
  }, []);

  const updategroup = async () => {
    try {
      if (isNaN(killpoints) || isNaN(istrankpoints) || isNaN(lastrankpoints)) {
        return error("please enter the points correctly");
      }
      setrequesting(true);

      const object = {
        eventId,
        killpoints: parseInt(killpoints),
        istrankpoints: parseInt(istrankpoints),
        lastrankpoints: parseInt(lastrankpoints)
      };

      const res = await EditMultiplayerRoundSettings(object);
      const data = await res.json();
      if (data.type === "success") {
        dispatch(setMatchObj({ matches: data?.result }));
        dispatch(setroundvariables(data?.result?.roundvariables));
        dispatch(seteventcomplete(data?.result?.complete));
        success("Round updated sucessfully!");
        // get updated data again
        getData();
        setopen(false);
      } else {
        error("couldn't update the group");
      }
      setrequesting(false);
    } catch (e) {
      console.log(e);
      setrequesting(false);
      error("couldn't update the group");
    }
  };

  if (!open) return null;
  const btnstyles = {
    fontWeight: "500",
    fontSize: "larger",
    width: "100%",
    paddingInline: "0",
    minWidth: "unset"
  };
  const Title = () => {
    return <div>{`Round ${currentround + 1} Edit`}</div>;
  };

  return (
    <Modal
      title={<Title />}
      className="editPost AddTeamModel"
      confirmLoading
      mask
      maskClosable={!requesting}
      onCancel={() => {
        setopen(false);
      }}
      closable={false}
      centered
      destroyOnClose={true}
      visible={open}
      open={open}
      footer={false}
      bodyStyle={{
        padding: "0px 0px 20px 0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}>
      {requesting ? (
        <Loader smaller noShadow center />
      ) : (
        <Row gutter={[5, 5]} style={{ width: "100%" }}>
          <Col md={24} lg={12}>
            <Row gutter={[5, 5]}>
              <Col span={24} style={{ marginBlock: ".5rem", fontWeight: "500" }}>
                <Row justify="" gutter={[15, 15]}>
                  <Col span={24} style={{ display: "flex", gap: ".5rem", alignItems: "center" }}>
                    Ist Rank Points
                    <InputNumber
                      type="number"
                      value={istrankpoints}
                      defaultValue={istrankpoints}
                      onChange={(value) => {
                        value = Math.abs(parseInt(value));
                        if (isNaN(value)) value = 0;
                        else if (value === "") {
                          value = 0;
                        }
                        setistrankpoints(parseInt(value));
                      }}
                      className="killsinput"
                    />
                  </Col>
                  <Col span={24} style={{ display: "flex", gap: ".5rem", alignItems: "center" }}>
                    <Form.Item
                      label="Last Rank Points"
                      help={
                        lastrankpoints > istrankpoints
                          ? "Last rank points can not be greater than First rank points"
                          : undefined
                      }
                      validateStatus={lastrankpoints > istrankpoints ? "error" : "success"}>
                      <InputNumber
                        type="number"
                        value={lastrankpoints}
                        defaultValue={lastrankpoints}
                        onChange={(value) => {
                          value = Math.abs(parseInt(value));
                          if (isNaN(value)) {
                            value = 0;
                            setlastrankpoints(0);
                          } else if (value === "" || !value) {
                            value = 0;
                            setlastrankpoints(0);
                          } else {
                            setlastrankpoints(parseInt(value));
                          }
                        }}
                        className="killsinput"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24} style={{ display: "flex", gap: ".5rem", alignItems: "center" }}>
                    Kill Points
                    <InputNumber
                      type="number"
                      value={killpoints}
                      defaultValue={killpoints}
                      onChange={(value) => {
                        value = Math.abs(parseInt(value));
                        if (isNaN(value)) value = 0;
                        else if (value === "") {
                          value = 0;
                        }
                        setkillpoints(parseInt(value));
                      }}
                      className="killsinput"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col md={24} lg={12}>
            <List
              size="small"
              header={<h4>Rank based Points Distribution</h4>}
              dataSource={individualRankPoints}
              renderItem={(item) => (
                <List.Item>
                  <span className="fw-bold">Rank {item?.rank} </span>:{item?.points}
                </List.Item>
              )}
            />{" "}
          </Col>

          <Col span={24} className="editbutton">
            <Button
              style={btnstyles}
              loading={requesting ? true : false}
              onClick={() => {
                updategroup();
              }}
              className="btnMobileDimensions"
              type="primary"
              disabled={lastrankpoints > istrankpoints}>
              Update Round
            </Button>
          </Col>
        </Row>
      )}
    </Modal>
  );
}

export default ScheduleRounEditModal;
