import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import React from "react";
import ExperienceCard from "../../cards/ExperienceCard/ExperienceCard";

const { Panel } = Collapse;

const ExperienceAccordian = () => (
  <Collapse
    bordered={false}
    defaultActiveKey={["ExperienceAccordian"]}
    expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
    className="site-collapse-custom-collapse  sideItem">
    <Panel header="Experience" key="1" className="site-collapse-custom-panel">
      <div
        className="overflowThumb"
        style={{
          height: "250px",
          overflowY: "auto",
          padding: "0 .5rem"
        }}>
        <ExperienceCard />
        <ExperienceCard />
      </div>
    </Panel>
  </Collapse>
);

export default ExperienceAccordian;
