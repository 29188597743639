import { Button, Col, DatePicker, Form, Input, Row, Select, Space, TimePicker } from "antd";
import { Formik } from "formik";
import React, { useState } from "react";
import { createCampaignActivityValidations } from "../../../Config/helpers/validations";
import InputTextField from "../../../common/FormItem/InputTextField";
import {
  ACTIVITY_TYPES,
  ACTIVITY_TYPES_LIST,
  SPORTS_TYPES_LIST
} from "../../../Config/helpers/constants";
import moment from "moment";
import { FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

function ActivityForm({ handleSubmit, loading, campaignId, viewActivity }) {
  const [text, setText] = useState("");
  const navigate = useNavigate();

  const initialValues = {
    activityId: viewActivity?._id || null,
    index: viewActivity?.index || 0,
    name: viewActivity?.name || "",
    description: viewActivity?.description || "",
    location: viewActivity?.location || "",
    time: viewActivity?.time || "",
    date: viewActivity?.date || "",
    panel: viewActivity?.panel || "",
    campaignId: campaignId,
    activityType: viewActivity?.activityType || "",
    sportType: viewActivity?.sportType || "",
    details: viewActivity?.details || "",
    registrationFee: viewActivity?.registrationFee || 0
  };
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={createCampaignActivityValidations}
      onSubmit={handleSubmit}>
      {({ isValid, dirty, setFieldValue, errors, values, handleSubmit }) => (
        <Form
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          autoComplete="off"
          onFinish={handleSubmit}>
          <div className="formorganizer" style={{ marginTop: "20px", padding: "10px 40px" }}>
            <Row gutter={12}>
              <Col xs={24} md={24} lg={12}>
                <InputTextField
                  label="Activity Name"
                  name="name"
                  required
                  type="text"
                  placeholder="Activity Name"
                />
              </Col>
              <Col xs={24} md={24} lg={12}>
                <Form.Item
                  className="border profileItems"
                  help={errors?.activityType ? errors?.activityType : undefined}
                  validateStatus={errors?.activityType ? "error" : "success"}>
                  <label className="boldlabel pb-3">Select Activity Type</label>
                  <Select
                    placeholder={"Select Activity Type"}
                    style={{
                      width: "43%",
                      border: "1px solid",
                      borderRadius: "7px",
                      color: "#b1b1b1"
                    }}
                    onChange={(e) => setFieldValue("activityType", e)}
                    options={ACTIVITY_TYPES_LIST}
                    value={values?.activityType}
                  />
                </Form.Item>
              </Col>
              {viewActivity?._id ? (
                <>
                  {values?.activityType === ACTIVITY_TYPES.ceremony ||
                  values?.activityType === ACTIVITY_TYPES.concert ? (
                    <>
                      <Col xs={24} md={24} lg={12}>
                        <InputTextField
                          label="Venue"
                          name="location"
                          required
                          type="text"
                          placeholder="Venue Location"
                        />
                      </Col>
                      <Col xs={24} md={24} lg={12}>
                        <InputTextField
                          label="Registration Fee"
                          name="registrationFee"
                          required
                          type="number"
                          placeholder="Registration Fee"
                        />
                      </Col>
                      <Col xs={24} md={24} lg={12}>
                        <InputTextField
                          label="Details"
                          name="details"
                          required
                          type="textArea"
                          placeholder="Details"
                        />
                      </Col>
                    </>
                  ) : null}
                  {values?.activityType !== ACTIVITY_TYPES.concert &&
                  values?.activityType !== ACTIVITY_TYPES.ceremony ? (
                    <Col xs={24} md={24} lg={12}>
                      <Form.Item
                        className="border profileItems"
                        help={errors?.sportType ? errors?.sportType : undefined}
                        validateStatus={errors?.sportType ? "error" : "success"}>
                        <label className="boldlabel">Select Sports Type</label>
                        <Select
                          placeholder={"Select Sports Type"}
                          style={{
                            width: "43%",
                            border: "1px solid",
                            borderRadius: "7px",
                            color: "#b1b1b1"
                          }}
                          onChange={(e) => setFieldValue("sportType", e)}
                          options={SPORTS_TYPES_LIST}
                          value={values?.sportType}
                        />
                      </Form.Item>
                    </Col>
                  ) : null}
                  <Col xs={24} md={24} lg={12}>
                    <Space>
                      <Input
                        type="text"
                        placeholder="Search Panel"
                        onChange={(e) => setText(e.currentTarget.value)}
                        style={{
                          borderRadius: "5px",
                          lineHeight: "38px",
                          marginTop: "5px"
                        }}
                        prefix={
                          <FaSearch
                            onClick={() => {
                              if (text?.trim().length > 0) {
                                navigate(`/sponsor/search/${text}`, { state: "panels" });
                              }
                            }}
                            style={{
                              cursor: "pointer",
                              color: "#8F8F8F",
                              marginRight: "10px",
                              width: "25px",
                              height: "16px"
                            }}
                          />
                        }
                      />
                      <Button
                        onClick={() => {
                          if (text?.trim().length > 0) {
                            navigate(`/sponsor/search/${text}`, { state: "panels" });
                          }
                        }}>
                        Search
                      </Button>
                    </Space>
                  </Col>
                  <Col xs={24} md={24} lg={12}>
                    <Space>
                      <Form.Item
                        className="halfwidth profileItems"
                        help={errors?.date ? errors?.date : undefined}
                        validateStatus={errors?.date ? "error" : "success"}>
                        <label className="boldlabel required">Date</label>
                        <DatePicker
                          disabledDate={(current) => {
                            return current && current < moment().add(-1, "day");
                          }}
                          inputReadOnly
                          className="mt8"
                          value={values?.date ? moment(values?.date) : undefined}
                          onChange={(_e, formatted) => setFieldValue("date", formatted)}
                        />
                      </Form.Item>
                      <TimePicker
                        format="hh:mm"
                        value={values?.time ? moment(values?.time) : undefined}
                        onChange={(e) => setFieldValue("time", e)}
                      />
                    </Space>
                  </Col>
                </>
              ) : null}
              <Col span={24}>
                <Button
                  loading={loading}
                  style={{ maxWidth: "280px", margin: "0" }}
                  disabled={!(isValid && dirty) || loading}
                  className="ceprimarybutton blueButton"
                  htmlType="submit"
                  type="primary">
                  {loading ? "Loading... " : `${values?.activityId ? "Update" : "Add"} Activity`}
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default ActivityForm;
