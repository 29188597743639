import React, { useEffect } from "react";
import { useState } from "react";
import { Image } from "antd";
import { fallbackcover } from "../Utils";

function FadeInImage({
  src,
  className,
  fallaback,
  radius,
  radiusAll,
  preview = false,
  onClick,
  type,
  EventCard
}) {
  const [loading, setloading] = useState(true);
  useEffect(() => {
    return () => {
      setloading(null);
    };
  }, []);

  let s = "";
  if (type === "Fadesearchedprofile") s = "s80";
  if (type === "FadesideItem150") s = "s150";
  if (type === "Fadepostprofile") s = "s32";
  if (type === "Fadenavprofile") s = "s35";
  if (type === "FadesideItem") s = "s120";
  if (type === "Fadeprofile") s = "s150p";
  if (type === "Fadecover") s = "s320";
  if (type === "Fadegcard") s = "s90";
  if (type === "media") s = "s200 media";
  if (type === "follow") s = "s150 follow";
  if (type === "s40") s = "s40";

  let iC = "";
  let iP = "";
  if (radius) iC = EventCard || type === "Fadecover" ? "r-t8 ret-4" : "r-t8";
  if (radiusAll) iC = "r-a8";
  if (loading) iP = "op0";
  if (!loading) iP = "op1";

  let bgview = loading ? "fadeimgloadingbgBlock" : "fadeimgloadingbgNone";
  const [prev, setpre] = useState(false);
  const onVisibleChange = () => setpre((e) => !e);
  return (
    <div className={`FadeImageWrapper ${s}`}>
      <div className={`${s} ${bgview} ${iC}`} />
      {
        <Image
          onClick={onClick && onClick}
          alt="img"
          width={"100%"}
          preview={{
            visible: preview && prev,
            onVisibleChange: onVisibleChange,
            maskClassName: !preview ? "fadeinNoimagemask" : null
          }}
          wrapperClassName={`${iC} ${s}`}
          rootClassName="a"
          className={`${className ? className : "timelineimage"}  ${iC} ${iP} ${s}`}
          src={src}
          onLoad={() => {
            setloading(false);
          }}
          onError={() => {
            setloading(false);
          }}
          fallback={fallaback || fallbackcover}
        />
      }
    </div>
  );
}

export default FadeInImage;
