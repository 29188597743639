import { KEYS } from "../Keys";

export const GetOrganizerMediaApi = async (values) => {
  const response = await fetch(KEYS.API + "/organizer/getorganizermedia", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify({ userId: values })
  });
  return response;
};

export const GetPlayerMediaApi = async (values) => {
  const response = await fetch(KEYS.API + "/player/getplayermedia", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify({ userId: values })
  });
  return response;
};

export const GetTeamMediaApi = async (values) => {
  const response = await fetch(KEYS.API + "/team/getteammedia", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify({ teamId: values })
  });
  return response;
};

export const GetPanelMediaApi = async (values) => {
  const response = await fetch(KEYS.API + "/panel/getpanelrmedia", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify({ panelId: values })
  });
  return response;
};
