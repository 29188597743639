/* eslint-disable */
import { BsHandThumbsUpFill, BsHandThumbsUp } from "react-icons/bs";
import CommentForm from "../PostComments/Components/CommentForm";
import React, { Fragment, useState, useEffect, forwardRef } from "react";
import { useCurrentPage } from "../../hooks/useCurrentPage";
import { error, success } from "../../Alerts/Message";
import CommentandLikes from "../PostComments/Comments";
import PostSkelton from "../Loader/PostSkelton";
import { PostMeidaLoader } from "../../Utils";
import EventScheduleCard from "../FeedCard/EventScheduleCard";
import EventRoundPublichCard from "../FeedCard/EventRoundPublishCard";
import EventCreateCard from "../FeedCard/EventCreateCard";
import EventCompleteCard from "../FeedCard/EventCompleteCard";

import OneImage from "../../Images/OneImage";
import { KEYS } from "../../../Config/Keys";
import VideoCard from "../Media/VideoCard";
import EditDots from "./Utils/EditDots";
import UserInfo from "./Utils/UserInfo";
import EditModal from "./EditModal";
import { Row, Col } from "antd";
import Swal from "sweetalert2";

import { CommentReply, PostComment, PostUnlike, PostLike } from "../../../Config/API/posts";
import {
  EventPostLike,
  EventPostUnLike,
  CommentPost as EventCommentPost,
  ReplyCommentPost as EventReplyCommentPost
} from "../../../Config/API/events";
import { MdOutlineInsertComment, MdKeyboardArrowDown, MdComment } from "react-icons/md";
import useVariables from "../../hooks/useVariables";
import PublishedRoundCard from "./PublishedRoundCard";

const TimelineCard = forwardRef(
  ({ index, deletepost, editpost, posts, setPOSTs, Editing, eventId, data }, ref) => {
    const [cardloading, setcardloading] = useState(true); //for card
    const [ppicloading, setppicloading] = useState(true); //for profile
    const [commented, setcommented] = useState(false); //comment button clicked
    const [addcomment, setaddcomment] = useState("");
    const [EditPost, setEditPost] = useState(false); //edit post modal
    const [loading, setloading] = useState(false); //for comments
    const [TotalCL, setTotalCL] = useState({
      like: data?.likes?.length,
      comments: data?.comments?.length
    }); //like button clicked
    const [liked, setliked] = useState(false); //like button clicked
    const [publishedRoundPost, setPublishedRoundPost] = useState({
      isPublished: false,
      data: ""
    });
    const [publishedRoundPosts, setPublishedRoundPosts] = useState({
      isPublished: false,
      data: ""
    });
    const [scheduledRoundPosts, setScheduledRoundPosts] = useState({
      isPublished: false,
      data: ""
    });
    const [createdPosts, setCreatedPosts] = useState({
      isPublished: false,
      data: ""
    });
    const [compltedPosts, setCompltedPosts] = useState({
      isPublished: false,
      data: ""
    });

    const { isPlayer, isOrganizer, isEvent } = useCurrentPage();
    const { PID, FLAG } = useVariables();

    // check weather you current user liked the post
    useEffect(() => {
      const id = PID;
      if (id)
        data?.likes?.forEach((e) => {
          if (e.hasOwnProperty("player")) {
            const player = e.hasOwnProperty("player") && e?.player === id;
            if (player) setliked(true);
          } else if (e.hasOwnProperty("organizer")) {
            const org = e.hasOwnProperty("organizer") && e?.organizer === id;
            if (org) setliked(true);
          } else if (e.hasOwnProperty("panel")) {
            const panell = e.hasOwnProperty("panel") && e?.panel === id;
            if (panell) setliked(true);
          } else if (e.hasOwnProperty("team")) {
            const teamm = e.hasOwnProperty("team") && e?.team === id;
            if (teamm === true) setliked(true);
          } else if (e.hasOwnProperty("user")) {
            if (e?.user === id) setliked(true);
          } else {
            setliked(false);
          }
        });
    }, [data?.likes]);

    // check for comments length
    useEffect(() => {
      let length = 0;
      data?.comments?.forEach((e) => {
        length += 1;
        e?.reply?.forEach(() => (length += 1));
      });
      setTotalCL({
        like: TotalCL?.like,
        comments: length
      });
    }, [data?.comments]);

    // post media card loader
    useEffect(() => {
      setcardloading(true);
      PostMeidaLoader(data, setcardloading);
    }, [data?.file?.file, data?.file?.type]);

    useEffect(() => {
      return () => {
        setcommented(null);
        setaddcomment(null);
        setliked(null);
        setloading(null);
        setEditPost(null);
        setppicloading(null);
      };
    }, []);

    // timeline flags
    const Getflag = () => {
      if (isPlayer) return "playerTimeline";
      else if (isOrganizer) return "organizerTimeline";
    };
    // values for likes and unlike
    const getLikeUnlikevalues = () => {
      let flag = Getflag();

      let values = {
        userRole: FLAG,
        id: data?._id,
        user: PID || null,
        flag: flag || null
      };
      if (flag === "organizerTimeline") values["organizeruserId"] = PID;
      else if (flag === "playerTimeline") values["playeruserId"] = PID;
      else if (eventId) values["eventId"] = eventId;

      return values;
    };

    const replacePostDataWithNewData = (updatedData) => {
      let updatedPosts = [...posts];
      updatedPosts[index] = updatedData?.[0] ?? updatedData;
      setPOSTs(updatedPosts);
    };

    // like Like Post
    const LikePost = async () => {
      if (!PID || !FLAG) return error("missing user");
      let values = getLikeUnlikevalues();

      setTotalCL({ like: TotalCL?.like + 1, comments: TotalCL?.comments });
      setliked((e) => !e);

      var res = null;

      if (eventId) {
        res = await EventPostLike({ ...values });
      } else res = await PostLike({ ...values });

      const dataa = await res.json();

      if (dataa.type === "success") {
        replacePostDataWithNewData(dataa.data);
      }

      if (dataa.type === !"success") error(data.result);
    };

    // unlike Like Post
    const UnLikePost = async () => {
      if (!PID || !FLAG) return error("missing user");
      setTotalCL({
        like: TotalCL?.like - 1,
        comments: TotalCL?.comments
      });

      setliked((e) => !e);

      let values = getLikeUnlikevalues();
      var res = null;
      if (eventId) {
        res = await EventPostUnLike({ ...values });
      } else res = await PostUnlike({ ...values });

      const dataa = await res.json();
      if (dataa.type === "success") {
        replacePostDataWithNewData(dataa.data);
      }
      if (dataa.type === !"success") {
        error(data?.result);
      }
    };

    // comment and reply post
    const PostCommentReply = async (obj) => {
      try {
        if (!PID || !FLAG) return error("one of the fields are missing");

        if (String(obj?.val).trim().length === 0) {
          setloading(false);
          obj?.setid("");
          return error("Add something in the text field before proceeding");
        }

        let flag = Getflag();
        let values = {
          userRole: FLAG === "organizer" ? "Organizer" : "Player",
          id: obj?.postId || obj?.commentId,
          postId: data?._id,
          user: PID,
          comment: obj?.text || obj?.val,
          flag: flag ? flag : null
        };

        if (eventId) values["eventId"] = eventId;
        else {
          if (flag === "organizerTimeline") values["organizeruserId"] = PID;
          else if (flag === "playerTimeline") values["playeruserId"] = PID;
        }

        let res = "";

        // event comment and reply
        if (eventId) {
          // reply api
          if (obj?.commentId) {
            res = await EventReplyCommentPost({ ...values });
          }
          // comment api
          else if (obj?.postId) {
            res = await EventCommentPost({ ...values });
          }
        }

        // player, organizer timeline comment and reply
        else {
          if (obj?.commentId) {
            res = await CommentReply({ ...values });
          } else if (obj?.postId) {
            res = await PostComment({ ...values });
          }
        }
        const d = await res.json();

        if (d.type === "success") {
          replacePostDataWithNewData(d?.data);
        } else if (data.type === "failure") {
          error(data?.result);
        }
        setloading(false);
        console.log(d);
      } catch (e) {
        setloading(false);
        error(e.message);
      }
    };

    // Delete Post
    const PostDelete = async () => {
      Swal.fire({
        icon: "question",
        text: "Are you sure you want to delete this post?",
        showCancelButton: true,
        cancelButtonColor: "var(--red)"
      }).then(async (e) => {
        if (e.isConfirmed) {
          if (!deletepost) return error("Api not integrated yet, ");
          let vals = {};
          setcardloading(true);
          if (!isEvent) {
            if (isOrganizer) vals = { organizeruserId: PID, postId: data?._id };
            else if (isPlayer) vals = { playeruserId: PID, postId: data?._id };
          } else if (isEvent) {
            vals = { eventId: eventId, postId: data?._id };
            const res = await deletepost(vals);
            const d = await res.json();
            setPOSTs(d?.data || d?.result || []);
            return;
          }
          try {
            const res = await deletepost(vals);
            const d = await res.json();
            console.log(d);
            if (d?.type !== "failure") {
              console.log("post deleted at Timeline => functions =>220");
              success("Post Deleted Successfully!");
              setPOSTs(d?.data || d?.result || []);
            } else {
              error(d?.result);
            }
          } catch (e) {
            error(e.message);
            setcardloading(false);
          }
          setcardloading(false);
        }
      });
    };

    // Edit and Delete Drop Down Menu
    const PostOptoins = [
      {
        label: "Edit Post",
        key: "EditPost",
        onClick: () => {
          if (editpost) setEditPost(true);
          else error("Api not integrated yet, ");
        }
      },
      {
        type: "divider"
      },
      {
        label: "Delete Post",
        key: "Delete Post",
        onClick: async () => {
          PostDelete();
        }
      }
    ];

    const cardloadingstyle = {
      opacity: cardloading || ppicloading ? "0" : "1",
      maxHeight: cardloading || ppicloading ? "0px" : "unset",
      overflowY: cardloading || ppicloading ? "hidden" : "unset"
    };

    //Post Media Files
    const PostMedia = (
      <Fragment>
        {data?.file?.type === "image" ? (
          <OneImage src={data?.file?.file} isEvent={isEvent} />
        ) : null}
        {data?.file?.type === "video" ? (
          <VideoCard
            isEvent={isEvent}
            nodefault
            className="timelineimage"
            Timeline
            src={KEYS.API + "/" + data?.file?.file}
          />
        ) : null}
      </Fragment>
    );

    // Post and Comments and Likes Section
    const PostLikesCommentsComponent = (
      // eventId ? null
      //   :
      <Row style={{ padding: "3%", paddingTop: "0", userSelect: "none" }}>
        <Col span={24}>
          <p className="timelinePadding">
            <img
              className="timelinelike"
              src="/images/icons/like.png"
              alt="like"
              height="20px"
              width="19px"
            />

            <span className="timelinelikesection">{TotalCL?.like}</span>
            <span style={{ cursor: "pointer" }} onClick={() => setcommented((e) => !e)}>
              <img
                alt="IMG"
                className="timelinelike"
                src="/images/icons/comment.png"
                height="19px"
                width="21px"
              />
              <span className="timelinelikesection">{TotalCL?.comments}</span>
            </span>
          </p>

          <p className="likes_comments">
            {liked ? (
              <span onClick={UnLikePost} style={{ color: "var(--blue)" }}>
                <BsHandThumbsUpFill /> Like
              </span>
            ) : (
              <span onClick={LikePost} style={{ color: "#939393" }}>
                <BsHandThumbsUp /> Like
              </span>
            )}
            <span
              onClick={() => setcommented((e) => !e)}
              style={{ color: commented ? "var(--blue)" : "#939393" }}>
              {commented ? <MdComment /> : <MdOutlineInsertComment />}
              Comment
            </span>
          </p>

          {/* post comments */}
          {commented ? (
            <CommentandLikes
              setPOSTs={setPOSTs}
              onFinish={PostCommentReply}
              postId={data?._id}
              comments={data?.comments}
              loading={loading}
              setloading={setloading}
              FLAG={FLAG}
              PID={PID}
              eventId={eventId}
              replacePostDataWithNewData={replacePostDataWithNewData}
            />
          ) : null}
          {/* post comments Text Area */}
          <Fragment>
            {commented && (
              <span
                className={`
                    commentsDownButton 
                   ${addcomment === data?._id ? "true" : "false"}`}
                onClick={() => setaddcomment((e) => (e === data?._id ? "" : data?._id))}>
                {" Add Comment"}
                <MdKeyboardArrowDown />
              </span>
            )}
            {addcomment === data?._id && commented && (
              <CommentForm
                onFinish={PostCommentReply}
                postId={data?._id}
                loading={loading}
                status="Comment"
              />
            )}
          </Fragment>
        </Col>
      </Row>
    );

    useEffect(() => {
      if (data) {
        //  text string
        const text = data?.text;
        let publishedData = null;

        // Extract the object part from the string
        const objectString = text?.match(/Object:'(.*?)'/)?.[1];

        // Parse the extracted object string into a JavaScript object
        if (objectString) {
          publishedData = JSON.parse(objectString);
          setPublishedRoundPost({
            isPublished: true,
            data: publishedData
          });
          // Output the result
          console.log(objectString);
        }
      }
    }, [data]);

    useEffect(() => {
      if (data) {
        //  text string
        const text = data?.postType;

        if (text === "publish") {
          setPublishedRoundPosts({
            isPublished: true,
            data: data
          });
        } else if (text === "create") {
          setCreatedPosts({
            isPublished: true,
            data: data
          });
        } else if (text === "schedule") {
          setScheduledRoundPosts({
            isPublished: true,
            data: data
          });
        } else if (text === "complete") {
          setCompltedPosts({
            isPublished: true,
            data: data
          });
        }
      }
    }, [data]);

    return (
      <Fragment>
        <Row className="timelineCardShadow" style={{ minWidth: "100%" }}>
          <PostSkelton cardloading={cardloading} ppicloading={ppicloading} />

          <Col span={24} style={cardloadingstyle}>
            <Row style={{ position: "relative", padding: "3%" }}>
              <Col span={24}>
                {/* don't show edit and delete options for published posts */}
                {!publishedRoundPost?.isPublished && (
                  <EditDots Post={PostOptoins} Editing={Editing} />
                )}
                <div className="avatarNameWrapper">
                  <UserInfo setloading={setppicloading} data={data} />
                </div>

                {publishedRoundPosts.isPublished && (
                  <EventRoundPublichCard data={publishedRoundPosts.data} ref={ref} />
                )}
                {createdPosts.isPublished && <EventCreateCard data={createdPosts.data} ref={ref} />}
                {scheduledRoundPosts.isPublished && (
                  <EventScheduleCard data={scheduledRoundPosts.data} ref={ref} />
                )}
                {compltedPosts.isPublished && (
                  <EventCompleteCard data={compltedPosts.data} ref={ref} />
                )}

                {/* default rendering */}
                {!data?.postType && (
                  <p className="timelineparagraph" ref={ref}>
                    {data?.text}
                  </p>
                )}
              </Col>
            </Row>
            {PostMedia}
            {PostLikesCommentsComponent}
          </Col>
        </Row>
        {/* Edit Model */}
        <Fragment>
          {EditPost ? (
            <EditModal
              setPOSTS={setPOSTs}
              EditPost={editpost}
              data={data}
              setopen={setEditPost}
              open={EditPost}
              eventId={eventId}
            />
          ) : null}
        </Fragment>
      </Fragment>
    );
  }
);

export default TimelineCard;
