import React, { forwardRef } from "react";
import { Link } from "react-router-dom";
import { FaMapMarkerAlt } from "react-icons/fa";
import { SiNike } from "react-icons/si";
import classes from "./index.module.css";

const EventCompleteCard = forwardRef(({ data, classRef }, ref) => {
  return (
    <>
      <div
        className={`${classes.eventCard} ${classRef}`}
        ref={ref}
        style={{
          backgroundImage: `url(${process.env.REACT_APP_API_URL}/${data?.event?.file?.file})`
        }}>
        <div className={classes.skewedBackground}></div>
        <div className={classes.eventRound}>
          <h2 id={classes.eventComplete}>Event Closed</h2>
        </div>
        <div className={classes.content}>
          <div className={classes.topHeader}>
            <h2>
              Sport Type: {`${data.event.typeofsports || "-"} `}
              <br />
              Sport: {`${data?.event?.nameofsports || "-"}`.toUpperCase()}
            </h2>
          </div>
          <div>
            <div className={classes.panel}>
              <Link to={`/view/panel/${data?.panel?._id}/timeline`}>By: {data?.panel?.name}</Link>
            </div>
            <div className={classes.eventName}>
              <Link to={`/view/event/${data?.event._id}`}>{data.event?.name}</Link>
            </div>
            <div className={classes.eventLocation}>
              <FaMapMarkerAlt />
              <p>{data?.event?.city || ":Location Missing"}</p>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.eventCardFooter}>
        <div className={classes.sponsors}>
          <p>Sponsors:</p>
          <div>
            <SiNike />
          </div>
          <div
            style={{
              borderLeft: "1px solid white",
              paddingLeft: "15px"
            }}>
            <SiNike />
          </div>
        </div>
        <div className={classes.sponsors}>
          <Link
            to={`/view/event/${data.event._id}/event-awards`}
            className={classes.registerButton}
            target="_blank">
            View Awards
          </Link>
        </div>
        <div className={classes.register}>
          <Link
            className={`${classes.registerButton}`}
            to={`/view/event/${data.event._id}/event-awards`}
            style={{ background: "#02C171" }}>
            {" "}
            View Awards
          </Link>
        </div>
      </div>
    </>
  );
});

export default EventCompleteCard;
