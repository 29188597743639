import React, { Fragment, useState, useEffect } from "react";
import { Col, Row } from "antd";
import RightBar from "../../../../common/righbar";
import { SideBar } from "../../../../common/sidebar";
import PastEventCard from "../../../../common/cards/Events/PastEventCard";
import { GetOrganizerCurrentEvents as GetEventsApi } from "../../../../Config/API/events";
import { error } from "../../../../common/Alerts/Message";
import Loader from "../../../../common/cards/Loader";
import { useSelector } from "react-redux";
import { PROFILE } from "../../../../Store/Features/ProfileSlice";
import NoData from "../../../../common/cards/noData";
import useVariables from "../../../../common/hooks/useVariables";
import EventChangeTabs from "../EventChangeTabs";
import { ROLES } from "../../../../Config/helpers/constants";

const PastEvents = () => {
  const panelid = localStorage.getItem("panel");
  const ID = useSelector(PROFILE);
  const [Events, setEvents] = useState([]);
  const [loading, setloading] = useState(false);
  const { viewer } = useVariables();

  const getallevents = async () => {
    try {
      setloading(true);
      const response = await GetEventsApi({
        organizerId: viewer?.id,
        panelid
      });

      const data = await response.json();
      if (data?.type === "success") {
        const filter = data?.result?.filter((e) => new Date(e?.endDate).setHours(24) < new Date());
        setEvents(filter);
      }

      setloading(false);
    } catch (e) {
      console.log({ e });
      error(e || "An Error Occured");
      setloading(false);
    }
  };
  useEffect(() => {
    if (ID?._id || panelid) getallevents();
    return () => setEvents([]);
  }, [ID?._id]);
  return (
    <Fragment>
      {loading && <Loader fullpage loading={"white"} />}
      <Row justify="space-between" className="rowPadding">
        <SideBar description experience />
        <Col
          xs={{ span: 24, order: 3 }}
          sm={{ span: 24, order: 3 }}
          md={{ span: 24, order: 3 }}
          lg={{ span: 11, order: 2 }}>
          <Row className="  mobileto">
            <EventChangeTabs type="past" user={ROLES.organizer} />

            {Events?.length > 0 ? (
              Events?.map((e, i) => <PastEventCard key={i} data={e} />)
            ) : (
              <NoData noEvent />
            )}
          </Row>
        </Col>
        <RightBar />
      </Row>
    </Fragment>
  );
};

export default PastEvents;
