import React from "react";
import { Col, Row } from "antd";
import Discussion from "./ViewProfileDiscussion";
import RightBar from "../../../common/righbar";
import { SideBar } from "../../../common/sidebar";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { PROFILE } from "../../../Store/Features/ProfileSlice";

const ViewProfileTimeLineDetails = ({ current }) => {
  const { pathname } = useLocation();
  const ID = useSelector(PROFILE);
  return (
    <Row justify="space-between" className="rowPadding">
      <SideBar ratingPanel description experience location />

      <Col
        xs={{ span: 24, order: 3 }}
        sm={{ span: 24, order: 3 }}
        md={{ span: 24, order: 3 }}
        lg={{ span: 11, order: 2 }}>
        {ID?._id ? <Discussion current={current} /> : null}
      </Col>
      {pathname.includes(current) ? (
        <RightBar noExploreEvents eventHistoryLInk={`/view/${current}/${ID?._id}/eventhistory`} />
      ) : (
        <RightBar />
      )}
    </Row>
  );
};

export default ViewProfileTimeLineDetails;
