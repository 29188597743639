import React from "react";
import ReactDOM from "react-dom";

import "antd/dist/antd.min.css";
import "./styles/index.css";
import App from "./App";
import "./styles/global.css";
import "./styles/antd.css";
import "./styles/SearchBar/SearchBar.css";
import "./styles/ChatRoom/ChatRoom.css";
import "./styles/Comments/Comments.css";
import "./styles/user/user.css";

import "./styles/globalStyles.css";

import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { store } from "./Store/store";
import { Provider } from "react-redux";

import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  // <React.StrictMode>
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,
  // </React.StrictMode >,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorker.register();
