import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import HomePage from "./HomePage";

import PlayerTLayout from "../../layout/playerTLLayout/PlayerTLayout";
import OrganizerTLayout from "../../layout/organizerTLLayout/OrganizerTLayout";
import TL from "../../layout/teamTLayout/PlayerTeamsTLayout";
import Pl from "../../layout/panelLayout/OrganizerPLayout";

import Media from "../Media";
import About from "../About";
import Sponsors from "../Sponsors";
import Members from "../members";
import { Fragment } from "react";

import PageNotFound from "../../../common/cards/PageNotFound/PageNotFound";
import Follow from "../../../common/Follow";
import PlayerEventHistory from "../../Player/EventHistory/PlayerEventsHistory";
import { ROLES } from "../../../Config/helpers/constants";
const ViewProfileTimeline = () => {
  const { pathname } = useLocation();

  var current = "";
  if (pathname.includes("view/player")) current = "player";
  else if (pathname.includes("view/team")) current = "team";
  else if (pathname.includes("view/organizer")) current = "organizer";
  else if (pathname.includes("view/panel")) current = "panel";

  return (
    <Fragment>
      <Routes>
        <Route
          path="/"
          element={
            <Fragment>
              {current === ROLES.player && <PlayerTLayout />}
              {current === ROLES.organizer && <OrganizerTLayout />}
              {current === ROLES.team && <TL />}
              {current === ROLES.panel && <Pl />}
            </Fragment>
          }>
          <Route index path="/timeline" element={<HomePage current={current} />} />
          <Route index path="/eventhistory" element={<PlayerEventHistory current={current} />} />

          <Route path={`/followers`} element={<Follow current={current} />} />
          <Route path={`/followings`} element={<Follow current={current} />} />
          <Route path={`/media`} element={<Media current={current} />} />
          <Route path={`/about`} element={<About current={current} />} />
          {current === "team" || current === "panel" ? (
            <Route path={`/members`} element={<Members current={current} />} />
          ) : null}
          {current === "team" || current === "panel" ? (
            <Route path={`/sponsors`} element={<Sponsors />} />
          ) : null}

          <Route path={`/about`} element={<About />} />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Fragment>
  );
};

export default ViewProfileTimeline;
