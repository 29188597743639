import React from "react";
import { Button, Form, Modal, Select, Space, Typography } from "antd";
import { useNavigate } from "react-router-dom";
const { Title } = Typography;

function ChoosePanelModal({ open, setOpen, values, panels, setFieldValue }) {
  const navigate = useNavigate();
  return (
    <Modal
      title="Choose Panel"
      className="editPost AddTeamModel"
      mask
      maskClosable={false}
      closable={false}
      centered
      destroyOnClose={true}
      open={open}
      footer={false}
      bodyStyle={{
        padding: "0px 0px 20px 0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}>
      <Title level={3}>Select a Panel first to create event</Title>
      <Form.Item
        className="border profileItems"
        help={
          values?.panel
            ? undefined
            : panels?.length > 0
            ? "Please Select a panel"
            : panels === null
            ? ""
            : "No panels found"
        }
        validateStatus={values?.panel ? "success" : "error"}>
        <Select
          value={values?.panel}
          placeholder={"Select Panel"}
          style={{
            width: "100%",
            border: "1px solid",
            borderRadius: "7px",
            color: "#b1b1b1"
          }}
          onChange={(e) => setFieldValue("panel", e)}
          options={panels?.length > 0 ? panels : ""}
        />
      </Form.Item>
      <Space>
        <Button onClick={() => navigate(-1)}>Cancel</Button>
        <Button
          type="primary"
          onClick={() => setOpen(false)}
          disabled={!values?.panel || values?.panel?.length < 1}>
          Continue
        </Button>
      </Space>
    </Modal>
  );
}

export default ChoosePanelModal;
