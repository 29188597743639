// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 576px) {
  .homenav .ant-col:nth-of-type(2) {
    display: flex;
    align-items: center;
    margin-top: 5px;
  }

  .homenav .customLink {
    margin-right: 0.5rem;
    float: unset !important;
    display: inline-block;
  }

  .homenav .navlink button {
    width: 80px !important;
    height: 32px !important;
  }
}
.customLink {
  color: black;
  font-weight: 500;
  margin-right: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/styles/Landing/Nav.css"],"names":[],"mappings":"AAAA;EACE;IACE,aAAa;IACb,mBAAmB;IACnB,eAAe;EACjB;;EAEA;IACE,oBAAoB;IACpB,uBAAuB;IACvB,qBAAqB;EACvB;;EAEA;IACE,sBAAsB;IACtB,uBAAuB;EACzB;AACF;AACA;EACE,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":["@media screen and (max-width: 576px) {\n  .homenav .ant-col:nth-of-type(2) {\n    display: flex;\n    align-items: center;\n    margin-top: 5px;\n  }\n\n  .homenav .customLink {\n    margin-right: 0.5rem;\n    float: unset !important;\n    display: inline-block;\n  }\n\n  .homenav .navlink button {\n    width: 80px !important;\n    height: 32px !important;\n  }\n}\n.customLink {\n  color: black;\n  font-weight: 500;\n  margin-right: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
