import { Button, Upload } from "antd";
import React from "react";
import Swal from "sweetalert2";

const MediaUploader = ({
  children,
  setmedia,
  media,
  setuploadingMedia,
  setLuploading,
  border,
  setIsFileRemoved
}) => {
  // action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',

  const onChange = (f) => {
    const file = f.file;
    if (setIsFileRemoved) setIsFileRemoved(true);
    setLuploading(true);
    if (file.status !== "uploading" || file.status === "done") {
      setLuploading(false);
      if (file?.type.includes("image") || file?.type.includes("video")) {
        setmedia({
          file: file.originFileObj,
          type: media.type,
          preview: URL.createObjectURL(file.originFileObj)
        });
        setuploadingMedia(media.type);
        setLuploading(false);
      } else {
        setuploadingMedia("error");
        Swal.fire({
          icon: "error",
          title: "Invalid file type, Please only select image or video"
        });
      }
    } else if (file.status === "error") {
      setLuploading(false);
    }
  };

  return (
    <Upload
      // beforeUpload={() => { setLuploading(true); return true; }}
      maxCount={1}
      onChange={onChange}
      showUploadList={false}
      accept={media?.type === "image" ? "image/*" : "video/*"}>
      <Button
        className={`TimelineUploadButton ${border && "noMinWidth"}`}
        style={{
          border: "none",
          boxShadow: "none",
          userSelect: "none"
        }}>
        {children}
      </Button>
    </Upload>
  );
};

export default MediaUploader;
