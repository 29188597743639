import React, { useState } from "react";
import { Row, Col, Tabs } from "antd";
import SearchBar from "./SearchBar";
// import InvitedTeamMembers from './InvitedTeamMembers'
import AllTeamMembers from "./AllTeamMembers";
import { useLocation } from "react-router-dom";
const { TabPane } = Tabs;
function AddTeamMembers() {
  const { state } = useLocation();
  const [inputState, setInputState] = useState(null);
  const { id, members } = state;

  return (
    <div className="toheader" style={{ marginTop: "0", marginBottom: "50px" }}>
      <h1 className="ceheading"> Add Team Members </h1>
      <Row className="formorganizer">
        <SearchBar inputState={inputState} setInputState={setInputState} />
        <Col span={24}>
          <Tabs defaultActiveKey="1">
            <TabPane tab="All" key="1">
              <AllTeamMembers teamId={id} input={inputState} members={members} />
            </TabPane>
            {/* <TabPane tab="Invited" key="2">
                            <InvitedTeamMembers />
                        </TabPane> */}
          </Tabs>
        </Col>
      </Row>
    </div>
  );
}

export default AddTeamMembers;
