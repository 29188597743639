import { Space } from "antd";
import EventsComponent from "./Events";
import PlayerComponent from "./Player";
import OrganizersComponent from "./Organizers";
import TeamsComponent from "./Teams";
import PanelsComponent from "./Panels";
import React, { Fragment, useState } from "react";
import Loader from "../../../common/cards/Loader";
// import NoData from "../../../common/cards/noData";
import { ROLES } from "../../../Config/helpers/constants";

function SearchPageMain({ active, loading, allSearchData, setactive, setFilter }) {
  const [selectedEventOptions, setSelectedEventOptions] = useState({
    registrationStatus: false,
    allowWildCard: false,
    sponsors: false,
    prizeMoney: false
  });

  const [selectedPlayerOptions, setSelectedPlayerOptions] = useState({
    sponsors: false,
    eventsParticipated: null,
    awardWin: null
  });

  const [selectedOrganizerOptions, setSelectedOrganizerOptions] = useState({
    eventsOrganized: null,
    sponsors: false
  });

  const [selectedTeamOptions, setSelectedTeamOptions] = useState({
    eventsParticipated: null,
    awardWin: null,
    sponsors: false
  });

  const [selectedPanelOptions, setSelectedPanelOptions] = useState({
    eventsOrganized: null,
    sponsors: false,
    rating: null
  });

  const handleEventChange = (name, checked) => {
    setSelectedEventOptions((prev) => ({ ...prev, [name]: checked }));
    setFilter((prev) => ({ ...prev, [name]: checked }));
  };

  const handlePlayerChange = (name, checked) => {
    setSelectedPlayerOptions((prev) => ({ ...prev, [name]: checked }));
    setFilter((prev) => ({ ...prev, [name]: checked }));
  };

  const handleOrganizerChange = (name, checked) => {
    setSelectedOrganizerOptions((prev) => ({ ...prev, [name]: checked }));
    setFilter((prev) => ({ ...prev, [name]: checked }));
  };

  const handleTeamChange = (name, checked) => {
    setSelectedTeamOptions((prev) => ({ ...prev, [name]: checked }));
    setFilter((prev) => ({ ...prev, [name]: checked }));
  };

  const handlePanelChange = (name, checked) => {
    setSelectedPanelOptions((prev) => ({ ...prev, [name]: checked }));
    setFilter((prev) => ({ ...prev, [name]: checked }));
  };

  return (
    <Space direction="vertical" style={{ width: "100%" }} size="large">
      {loading ? <Loader /> : null}
      {/* {active.all === "primary" && !loading ? (
        <Fragment>
          {Events?.length > 0 ? (
            <EventsComponent
              Events={Events}
              loading={loading}
              setactive={setactive}
              filter={filter}
              limit
            />
          ) : null}
          {Players?.length > 0 ? (
            <PlayerComponent
              loading={loading}
              Players={Players}
              setactive={setactive}
              filter={filter}
              limit
            />
          ) : null}
          {Organizers?.length > 0 ? (
            <OrganizersComponent
              loading={loading}
              Organizers={Organizers}
              setactive={setactive}
              filter={filter}
              limit
            />
          ) : null}
          {Panels?.length > 0 ? (
            <PanelsComponent
              Panels={Panels}
              loading={loading}
              setactive={setactive}
              filter={filter}
              limit
            />
          ) : null}
          {Teams?.length > 0 ? (
            <TeamsComponent
              Teams={Teams}
              loading={loading}
              setactive={setactive}
              filter={filter}
              limit
            />
          ) : null}
          {!Events && !Players && !Organizers && !Teams && !Panels && !loading ? (
            <NoData noText />
          ) : null}
        </Fragment>
      ) : null} */}
      {active === ROLES.event && !loading ? (
        <Fragment>
          <EventsComponent
            setactive={setactive}
            Events={allSearchData}
            loading={loading}
            setFilter={setFilter}
            selectedOptions={selectedEventOptions}
            handleCheckboxChange={handleEventChange}
          />
        </Fragment>
      ) : null}
      {active === ROLES.player && !loading ? (
        <Fragment>
          <PlayerComponent
            setactive={setactive}
            Players={allSearchData}
            loading={loading}
            setFilter={setFilter}
            selectedOptions={selectedPlayerOptions}
            handleCheckboxChange={handlePlayerChange}
          />
        </Fragment>
      ) : null}
      {active === ROLES.organizer && !loading ? (
        <Fragment>
          <OrganizersComponent
            setactive={setactive}
            Organizers={allSearchData}
            loading={loading}
            setFilter={setFilter}
            selectedOptions={selectedOrganizerOptions}
            handleCheckboxChange={handleOrganizerChange}
          />
        </Fragment>
      ) : null}
      {active === ROLES.team && !loading ? (
        <Fragment>
          <TeamsComponent
            setactive={setactive}
            Teams={allSearchData}
            loading={loading}
            setFilter={setFilter}
            selectedOptions={selectedTeamOptions}
            handleCheckboxChange={handleTeamChange}
          />
        </Fragment>
      ) : null}
      {active === ROLES.panel && !loading ? (
        <Fragment>
          <PanelsComponent
            setactive={setactive}
            Panels={allSearchData}
            loading={loading}
            setFilter={setFilter}
            selectedOptions={selectedPanelOptions}
            handleCheckboxChange={handlePanelChange}
          />
        </Fragment>
      ) : null}
    </Space>
  );
}

export default SearchPageMain;
