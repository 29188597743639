import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse, Row, Col } from "antd";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import PastEventCard from "../PastEventCard";

const { Panel } = Collapse;

const EventHistoryAccordian = ({ linkTo, historyData, historyLoading }) => {
  const { pathname } = useLocation();
  return (
    <Collapse
      bordered={false}
      defaultActiveKey={["LocationAccordiant"]}
      expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
      className="site-collapse-custom-collapse  sideItem">
      <Panel header="Events History" key="Location" className="site-collapse-custom-panel">
        <Row className=" ">
          <Col span={24}>
            {historyLoading
              ? "Loading"
              : historyData?.length > 0
              ? historyData
                  ?.slice(0, 4)
                  ?.map((data, index) => <PastEventCard key={index} data={data} />)
              : "No Data Found"}
          </Col>
          <Col span={24} style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
            {/* only show, show more button if we have more than 3 events */}
            {historyData?.length > 3 && (
              <Link
                to={
                  linkTo ??
                  `${
                    pathname.includes("player") ? "/player/eventhistory" : "/organizer/eventhistory"
                  }`
                }
                style={{
                  fontSize: "12px",
                  color: "#0085FF",
                  fontWeight: "600",
                  cursor: "pointer"
                }}>
                Show More
              </Link>
            )}
          </Col>
        </Row>
      </Panel>
    </Collapse>
  );
};

export default EventHistoryAccordian;
