import React from "react";
import { Col, Row } from "antd";
import InviteCard from "../cards/InviteCard";
import NoData from "../../../../common/cards/noData";

const InviteMembers = ({ panel }) => {
  const arr = panel?.members?.filter((e) => !e?.active);
  return (
    <Row>
      {arr?.length > 0 ? (
        arr?.map((e, i) =>
          e?.player !== null ? (
            <Col
              className="pomemberShadow TPcard"
              key={i}
              xxl={6}
              xl={8}
              lg={{ span: 12 }}
              md={{ span: 12 }}
              sm={24}
              xs={24}>
              <InviteCard panel={e} />
            </Col>
          ) : null
        )
      ) : (
        <NoData noMembers />
      )}
    </Row>
  );
};

export default InviteMembers;
