import React from "react";
import { Route, Routes } from "react-router-dom";
import PlayerTLayout from "../../layout/playerTLLayout/PlayerTLayout";
import CurrentEvents from "./CurrentEvents";
import MarkedEvents from "./MarkedEvents";
import PastEvents from "./PastEvents";

const Timeline = () => {
  return (
    <Routes>
      <Route path="/" element={<PlayerTLayout />}>
        <Route path="/" element={<CurrentEvents />} />
        <Route path="/pastevents" element={<PastEvents />} />
        <Route path="/markedevents" element={<MarkedEvents />} />
      </Route>
    </Routes>
  );
};

export default Timeline;
