import React from "react";
import { Col, Row } from "antd";
import { Fragment } from "react";
import VideoCard from "../cards/VideoCard";
import { KEYS } from "../../../Config/Keys";
import FadeInImage from "../../Images/FadeInImage";
const MediaAllTab = ({ MEDIA }) => {
  return (
    <Row justify="">
      {MEDIA?.map((e, i) => {
        return (
          (e?.file?.type === "image" || e?.file?.type === "video") && (
            <Fragment key={`media${i}`}>
              <Col
                className="mediaCol"
                sm={11}
                xs={24}
                md={11}
                lg={11}
                xl={6}
                style={{ maxHeight: "220px" }}>
                {e?.file?.type === "video" && (
                  <VideoCard s200 src={KEYS?.API + "/" + e?.file?.file} />
                )}
                {e?.file?.type === "image" && (
                  <FadeInImage
                    type={"media"}
                    radiusAll
                    preview
                    className="MeduaHeight"
                    src={KEYS?.API + "/" + e?.file?.file}
                  />
                )}
              </Col>
            </Fragment>
          )
        );
      })}
    </Row>
  );
};

export default MediaAllTab;
