import React from "react";
import { Col, Row } from "antd";
import { FaCalendarAlt } from "react-icons/fa";
import FadeInImage from "../../Images/FadeInImage";

const ExperienceCard = () => {
  return (
    <Row
      style={{
        background: "white",
        marginBottom: "2%"
      }}>
      <FadeInImage
        radiusAll
        type={"FadesideItem"}
        src="/images/subevent.png"
        className="sideImageHeight"
        fallaback="/images/subevent.png"
      />
      <Col lg={24} md={24} xs={24} sm={24}>
        <div className="experienceCardText">
          <p
            style={{
              fontSize: "12px",
              color: "#0085FF",
              margin: "0px",
              fontWeight: "500"
            }}>
            National Football Tournament
          </p>
          <p
            style={{
              fontSize: "12px",
              padding: "5px 0px",
              margin: "0px"
            }}>
            Head Organzier at Hassanz Panel
          </p>
          <p style={{ fontSize: "11px", color: "red", margin: "0px" }}>
            <FaCalendarAlt /> JAN 14 AT 2 PM - JAN 14 AT 10 PM{" "}
          </p>
        </div>
      </Col>
    </Row>
  );
};

export default ExperienceCard;
