import React, { Fragment } from "react";
import { Row } from "antd";
import { SideBar } from "../../../common/sidebar";

function About() {
  return (
    <Fragment>
      <Row className="rowPadding">
        <SideBar description experience big />
      </Row>
    </Fragment>
  );
}

export default About;
