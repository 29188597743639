import moment from "moment";

export const calculateIndividualRankPoints = ({
  firstRankPoints,
  lastRankPoints,
  teamsInGroup
}) => {
  const difference = (firstRankPoints - lastRankPoints) / (teamsInGroup - 1);
  const rankPoints = [];

  for (let rank = 1; rank <= teamsInGroup; rank++) {
    let actualPoints;

    if (rank === 1) {
      actualPoints = firstRankPoints;
    } else if (rank === teamsInGroup) {
      actualPoints = lastRankPoints;
    } else {
      actualPoints = rankPoints[rank - 2].actualPoints - difference;
    }

    rankPoints.push({ rank, actualPoints, points: Math.round(actualPoints) });
  }
  return rankPoints;
};

export const deepEqualWithIgnoreKey = (obj1, obj2, keyToIgnore) => {
  // Helper function to check if a value is an object
  function isObject(val) {
    return val !== null && typeof val === "object";
  }

  // Recursive function to compare objects
  function deepEqual(objA, objB) {
    // Check if both values are objects
    if (isObject(objA) && isObject(objB)) {
      // Get the keys of each object
      const keysA = Object.keys(objA);
      const keysB = Object.keys(objB);

      // Check if the number of keys is the same
      if (keysA.length !== keysB.length) {
        return false;
      }

      // Iterate over keys and recursively compare values
      for (const key of keysA) {
        // Skip the specified key
        if (key === keyToIgnore) {
          continue;
        }

        // Recursively compare values
        if (!deepEqual(objA[key], objB[key])) {
          return false;
        }
      }

      // If all key-value pairs match, return true
      return true;
    } else {
      // If values are not objects, perform a simple equality check
      return objA === objB;
    }
  }

  // Start the deep comparison
  return deepEqual(obj1, obj2);
};

export const returnStartAndEndTime = (timeRangeString) => {
  if (timeRangeString) {
    const [startTime, endTime] = timeRangeString.split("-");

    // Parse start and end times using Moment.js
    const startTimeMoment = moment(startTime, "HH:mm");
    const endTimeMoment = moment(endTime, "HH:mm");

    // Format the times in AM/PM format
    const formattedStartTime = startTimeMoment.format("hh:mm A");
    const formattedEndTime = endTimeMoment.format("hh:mm A");

    return [formattedStartTime, formattedEndTime];
  }
};

// return total spots and occupied slots
export const combineWildCardSlots = (array) => {
  if (array?.length > 0) {
    return array.reduce(
      (accumulator, current) => {
        return {
          noOfSlots: accumulator.noOfSlots + current.noOfSlots,
          occupiedSlots: accumulator.occupiedSlots + current.occupiedSlots
        };
      },
      { noOfSlots: 0, occupiedSlots: 0 }
    );
  } else {
    return { noOfSlots: 0, occupiedSlots: 0 };
  }
};

export function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}
