import React from "react";
import { useState, useEffect, useRef } from "react";
import { Skeleton, List } from "antd";
import { Getnotifications } from "../../../Config/API/notifications";

import { checkProfileImage, useActions, useGetNotifications } from "./function";
import moment from "moment";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { fallbackprofile, getuserlink } from "../../Utils";
import FadeInImage from "../../Images/FadeInImage";

function TeamPanelNotifications({ open, setOpen }) {
  const [loading, setloading] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const { pathname } = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();

  const { get } = useGetNotifications(setloading, Getnotifications, setNotifications);
  const { textChecks } = useActions();

  useEffect(() => {
    get();
    return () => {
      setNotifications([]);
      setloading(null);
    };
  }, []);
  useEffect(() => {
    window.addEventListener("resize", checkWidth);
    window.addEventListener("load", checkWidth);
    // replace the timer with get request logic

    // window.scrollTo({ top: 0 });
    checkWidth();
    return () => {
      window.removeEventListener("resize", checkWidth);
      window.removeEventListener("load", checkWidth);
    };
  }, []);

  const checkWidth = () => {
    if (window.innerWidth <= 992) {
      const current = pathname.includes("team") ? "team" : "panel";
      navigate(
        current === "panel"
          ? `/organizer/panel/${id}/notifications`
          : `/player/team/${id}/notifications`
      ); //redirects to previous oage
    } else if (pathname.includes("notifications")) {
      navigate(-1);
    }
  };

  const ref = useRef(null);
  const height =
    parseInt(window.innerHeight - ref.current?.getBoundingClientRect().top - 35) + "px";

  return (
    <div ref={ref}>
      <div
        onClick={() => setOpen(false)}
        style={{
          display: open ? "block" : "none",
          width: "100vw",
          height: "100vh",
          position: "fixed",
          left: "0",
          top: "0",
          zIndex: 10
        }}
      />
      <List
        style={{
          padding: "10px 0",
          display: open ? "block" : "none",
          position: "absolute",
          right: "-4%",
          top: "47px",
          minHeight: 70,
          height: 400,
          maxHeight: height,
          overflowY: "scroll",
          zIndex: 12,
          cursor: "default !important"
        }}
        // loading={{ spinning: loading, size: "large", }}
        loading={{
          wrapperClassName: "notificationSpinner",
          spinning: loading
        }}
        className="NotificationList TeamPanelNotifications"
        itemLayout={"horizontal"}
        dataSource={notifications || []}
        renderItem={(item) => {
          return (
            <List.Item
              className="ListItem"
              style={{
                alignItems: "flex-start",
                flexDirection: "column",
                gap: "5px",
                padding: "10px 15px"
              }}>
              <Skeleton active avatar title={false} loading={false}>
                <List.Item.Meta
                  style={{ width: "100%", alignItems: "baseline" }}
                  avatar={
                    <div
                      onClick={() =>
                        navigate(getuserlink(item)?.url, { state: getuserlink(item)?.name })
                      }
                      style={{
                        position: "relative",
                        top: "20px",
                        cursor: "pointer"
                      }}>
                      <FadeInImage
                        type={"Fadenavprofile"}
                        radiusAll
                        fallaback={fallbackprofile}
                        src={checkProfileImage(item)}
                      />
                    </div>
                  }
                  title={textChecks(item, navigate, setloading, setNotifications)}
                />

                <div style={{ marginLeft: "50px", color: "#A5ACB8" }}>
                  <small>{moment(item?.createdAt).fromNow()}</small>
                </div>
              </Skeleton>
            </List.Item>
          );
        }}
      />
    </div>
  );
}

export default TeamPanelNotifications;
