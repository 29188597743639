import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Image, Row, Col } from "antd";

const ExploreEventsImgCard = ({ props, text1, text2 }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  return (
    <Row
      justify="space-evenly"
      style={{
        marginBottom: 5,
        gap: 5
      }}>
      <Col
        xl={11}
        lg={11}
        md={24}
        sm={24}
        xs={24}
        className={props?.className}
        onClick={() => {
          if (pathname.includes("player")) {
            navigate(`/player/search/${String(text1).toLowerCase()}`);
          } else {
            navigate(`/organizer/search/${String(text1).toLowerCase()}`);
          }
        }}>
        <Image
          width="100%"
          className="timelineimage"
          src="/images/eventpic.png"
          preview={false}
          style={{ ...props?.styles }}
        />
        <span style={{ ...props?.styles }}>{text1}</span>
      </Col>

      <Col
        xl={11}
        lg={11}
        md={24}
        sm={24}
        xs={24}
        className={props?.className}
        onClick={() => {
          if (pathname.includes("player")) {
            navigate(`/player/search/${String(text1).toLowerCase()}`);
          } else {
            navigate(`/organizer/search/${String(text1).toLowerCase()}`);
          }
        }}>
        <Image
          width="100%"
          className="timelineimage"
          src="/images/eventpic.png"
          preview={false}
          style={{ ...props?.styles }}
        />
        <span style={{ ...props?.styles }}>{text2}</span>
      </Col>
    </Row>
  );
};

export default ExploreEventsImgCard;
