import { Table, Row, Col, Dropdown, Menu, Collapse, Button } from "antd";
const { Panel } = Collapse;
import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  load,
  unload,
  isLoading,
  setMatchObj,
  setroundvariables,
  seteventcomplete,
  setcurrentgroupindex
} from "../../../../../../Store/Features/MatchesSlice";
import { Info, error, success } from "../../../../../../common/Alerts/Message";
import Loader from "../../../../../../common/cards/Loader";
import {
  MultiplayerConcludeRound,
  MultiplayerConcludetournament,
  MultiplayerDeleteRound,
  MultiplayerScheduleGetData
} from "../../../../../../Config/API/multiplayerSchedule";
import { QualifyingColumn, Column } from "../../cards/columns/multiplayer";
import useRoundsButtons from "../KO/useRoundsUtility/useRoundsButtons";
import { Experience } from "../../../../../../common/sidebar";
import { useState } from "react";
import ScheduleGroupPointsModal from "./Modals/ScheduleGroupPointsModal";
import ScheduleNextRound from "./Modals/schedulenext";
import { FaEdit } from "react-icons/fa";
import { AiFillSetting } from "react-icons/ai";
import Swal from "sweetalert2";
import ScheduleRounEditModal from "./Modals/EditModal";
import PublishRoundConfirmationModal from "./Modals/PublishRoundConfirmation";
import { deepEqualWithIgnoreKey } from "../../../../../../utils/utilityFunctions";
import AssignAwards from "../AssignAwards/AssignAwards";
import { ROLES } from "../../../../../../Config/helpers/constants";
import {
  AssignMultiPlayerScheduleEventsAward,
  GetEventWildCards
} from "../../../../../../Config/API/events";

const Multiplayermatches = () => {
  const Matches = useSelector((state) => state.matches.matches);
  const eventcomplete = useSelector((state) => state.matches.eventcomplete);
  const roundvariables = useSelector((state) => state.matches?.roundvariables);

  const [open, setopen] = useState(false);
  const [assignAwardsOpen, setAssignAwardsOpen] = useState(false);
  const [publishModalOpen, setPublishModalOpen] = useState(false);
  const [scheduleopen, setscheduleopen] = useState(false);
  const [assignedAwards, setAssignedAwards] = useState({});
  const [wildCardData, setWildCardData] = useState(null);
  const [group, setgroup] = useState({
    open: false,
    index: 0,
    data: []
  });

  const currntgroupindex = useSelector((state) => state.matches.currntgroupindex);
  const currentroundvariables = roundvariables[currntgroupindex?.currentourndindex || 0];
  console.log(currentroundvariables);

  const loading = useSelector(isLoading);
  const [active, setactive] = useState("1 - 1st Match");
  // check if the the current added data was published or not
  const [isPublished, setIsPublished] = useState(true);

  const dispatch = useDispatch();
  const { state } = useLocation();
  const { id } = useParams();
  const e = useRef(null);

  const pagesise = 8;

  // var columns = [];
  // if (eventcomplete) {
  //   columns = QualifyingColumn;
  // } else columns = Column;
  const navigate = useNavigate();

  const get = async () => {
    try {
      const res = await MultiplayerScheduleGetData({ eventId: state?._id || id });
      const data = await res.json();
      dispatch(setMatchObj({ matches: data?.result }));
      dispatch(setroundvariables(data?.result?.roundvariables));
      dispatch(seteventcomplete(data?.result?.complete));

      dispatch(
        setcurrentgroupindex({ ...currntgroupindex, currentourndindex: data?.result?.currentround })
      );

      // compare current and published
      const currentData = {
        currentround: data?.result?.currentround,
        roundvariables: data?.result?.roundvariables,
        rounds: data?.result?.rounds
      };
      const publishedData = data?.result?.isPublished;
      const isEqual = deepEqualWithIgnoreKey(currentData, publishedData, "_id");
      setIsPublished(isEqual);

      dispatch(unload());
    } catch (e) {
      console.log(e);
      error(e.message);
      dispatch(unload());
    }
  };

  // get event wild cards
  useEffect(() => {
    const getEventWildCards = async () => {
      const res = await GetEventWildCards(id);
      const result = await res.json();
      if (result?.type === "success") {
        setWildCardData(result?.result);
      }
    };

    if (state?._id) {
      getEventWildCards();
    }
  }, [currentroundvariables]);

  useEffect(() => {
    dispatch(load());
    get();
  }, []);

  useEffect(() => {
    let getFirstGroup = null;
    let overAllScoreIndex = null;
    let find = null;
    let string = null;
    // set overall score table to show as default
    if (Matches?.rounds) {
      getFirstGroup = Matches?.rounds[currntgroupindex?.currentourndindex]?.filter(
        (round) => round?.groupno === 0
      )?.[0];
      overAllScoreIndex = getFirstGroup
        ? getFirstGroup?.matches?.findIndex((match) => match.isFinalStanding === true)
        : null;

      if (overAllScoreIndex) {
        find = getFirstGroup?.matches?.findIndex(
          (match) => match?._id === getFirstGroup?.matches?.[overAllScoreIndex]?._id
        );
        string = `${getFirstGroup?.groupno + 1 + " - " + getOrdinalNum?.(find + 1)}`;
      }
    }

    dispatch(
      setcurrentgroupindex({
        ...currntgroupindex,
        gindex: 0,
        submatchindex: overAllScoreIndex || 0
      })
    );

    setactive(string || "1 - 1st Match");
  }, [currntgroupindex?.currentourndindex]);

  const { HistoryButtons } = useRoundsButtons();

  function getOrdinalNum(num) {
    return (
      num +
      (num % 100 === 11 || num % 100 === 12 || num % 100 === 13
        ? "th Match"
        : num % 10 === 1
        ? "st Match"
        : num % 10 === 2
        ? "nd Match"
        : num % 10 === 3
        ? "rd Match"
        : "th Match")
    );
  }

  // conclude event
  const concludetournament = async (val = true) => {
    try {
      Swal.fire({
        title: val ? "Tournament Conclude!" : "Tournament Resume",
        text: `Do you want to ${val ? "conclude" : "resume"} this tournament?`
      }).then(async (e) => {
        if (e.isConfirmed) {
          let finalizedAwards = [];
          let awardEntries = Object.entries(assignedAwards);
          if (awardEntries?.length > 0) {
            finalizedAwards = awardEntries.map(([id, { type, _id }]) => {
              if (type === ROLES.player) {
                return { playerId: _id, eventAwardId: id };
              } else {
                return { teamId: _id, eventAwardId: id };
              }
            });
          } else {
            error("Assign awards first to compete event.");
            return;
          }

          setAssignAwardsOpen(false);
          dispatch(load());
          const res = await MultiplayerConcludetournament({ eventId: id, complete: true });
          const data = await res.json();
          if (data.type === "success") {
            // call assign awards API here
            const payload = { awards: finalizedAwards };
            await AssignMultiPlayerScheduleEventsAward(payload);
            // dispatch(seteventcomplete(data?.result?.complete));
            // dispatch(setMatchObj({ matches: data?.result }));
            // dispatch(setroundvariables(data?.result?.roundvariables));

            // get updated data again
            get();
            success(`Event ${val ? "Concluded" : "Resumed"}!`);
            // navigate to awards page
            navigate(`/view/event/${id}/event-awards`);
          } else {
            error(data?.result || `Couldn't  ${val ? "Conclude" : "Resumed"} the tournament`);
          }
          dispatch(unload());
        }
      });
    } catch (e) {
      console.log(e);
      error(`Couldn't  ${val ? "Conclude" : "Resumed"} the tournament`);
      dispatch(unload());
    }
  };

  // delete round
  const deleteround = async () => {
    try {
      Swal.fire({
        title: "Delete Round " + (Matches?.currentround + 1),
        text: `Do you want to delete this round?`
      }).then(async (e) => {
        if (e.isConfirmed) {
          dispatch(load());
          const res = await MultiplayerDeleteRound({ eventId: id });
          const data = await res.json();
          if (data?.returntoschedule) {
            success("Schedule Deleted!");
            return navigate(`/organizer/event/${id}`);
          }
          if (data.type === "success") {
            // dispatch(setMatchObj({ matches: data?.result }));
            // dispatch(setroundvariables(data?.result?.roundvariables));
            // dispatch(seteventcomplete(data?.result?.complete));

            dispatch(
              setcurrentgroupindex({
                ...currntgroupindex,
                currentourndindex: data?.result?.currentround
              })
            );

            // get updated data again
            get();
            success("Round Deleted!");
          } else {
            error("Couldn't delete the round");
          }
          dispatch(unload());
        }
      });
    } catch (e) {
      error("Couldn't delete the round");
      dispatch(unload());
    }
  };

  // conclude round
  const concludeRound = async () => {
    try {
      Swal.fire({
        title: "Conclude Round " + (Matches?.currentround + 1),
        text: `Do you want to conclude this round?`,
        showDenyButton: true,
        confirmButtonText: "Conclude",
        denyButtonText: `Cancel`
      }).then(async (e) => {
        if (e.isConfirmed) {
          dispatch(load());
          const res = await MultiplayerConcludeRound(id);
          const data = await res.json();
          console.log("conclude round data", data);

          if (data.type === "success") {
            // get updated data again
            get();
            success("Round Concluded!");
          } else {
            error("Couldn't delete the round");
          }
          dispatch(unload());
        }
      });
    } catch (e) {
      error("Couldn't conclude the round, please try again");
      console.log(e);
    }
  };

  const currentroundsgroupsdata = Matches?.rounds?.[currntgroupindex?.currentourndindex]?.map(
    (e, i) => {
      // const obj = e[0]
      const obj = e;
      return {
        key: i,
        ...obj
      };
    }
  );

  const nextr = Matches?.rounds?.[currntgroupindex?.currentourndindex + 1];
  const prevr = Matches?.rounds?.[currntgroupindex?.currentourndindex - 1];

  const items = [
    !eventcomplete &&
      !currentroundvariables?.roundConcluded && {
        label: (
          <div
            onClick={() => {
              if (currntgroupindex?.currentourndindex !== Matches?.currentround) {
                return Info("Goto Current Rounf first!");
              }
              setopen(true);
            }}>
            Edit Round
          </div>
        ),
        key: "0"
      },
    !eventcomplete && {
      label: (
        <div
          onClick={() => {
            if (eventcomplete) {
              return Info("Resume Event First!");
            } else deleteround();
          }}>
          Delete Round
        </div>
      ),
      key: "1"
    },
    !eventcomplete &&
      currentroundvariables?.roundConcluded && {
        label: (
          <div
            onClick={() => {
              if (eventcomplete) {
                return Info("Resume Event First!");
              } else setscheduleopen(true);
            }}>
            Schedule Next
          </div>
        ),
        key: "2"
      },
    !eventcomplete &&
      !currentroundvariables?.roundConcluded && {
        label: (
          <div
            onClick={() => {
              concludeRound();
            }}>
            Conclude Round
          </div>
        ),
        key: "conclude-round"
      },
    !eventcomplete &&
      currentroundvariables?.roundConcluded && {
        label: (
          <div
            onClick={() => {
              if (eventcomplete) {
                concludetournament(false);
              } else {
                setAssignAwardsOpen(true);
                // concludetournament(true);
              }
            }}>
            {`${eventcomplete ? "Resume" : "Complete"}`} Event
          </div>
        ),
        key: "3"
      }
  ];

  if (nextr) {
    items.push({
      label: (
        <div
          onClick={() => {
            if (!nextr) return Info("No round found");
            dispatch(
              setcurrentgroupindex({
                ...currntgroupindex,
                currentourndindex: currntgroupindex.currentourndindex + 1
              })
            );
          }}>
          Next Round
        </div>
      ),
      key: "next"
    });
  }
  if (prevr) {
    items.push({
      label: (
        <div
          onClick={() => {
            if (!prevr) return Info("No round found");
            dispatch(
              setcurrentgroupindex({
                ...currntgroupindex,
                currentourndindex: currntgroupindex.currentourndindex - 1
              })
            );
          }}>
          Previous Round
        </div>
      ),
      key: "pre"
    });
  }
  if (currntgroupindex?.currentourndindex !== Matches?.currentround) {
    items.push({
      label: (
        <div
          onClick={() => {
            dispatch(
              setcurrentgroupindex({
                ...currntgroupindex,
                currentourndindex: Matches?.currentround
              })
            );
          }}>
          Goto Current Round
        </div>
      ),
      key: "current"
    });
  }

  // const currentoundsdata = Matches?.currentround
  return (
    <>
      <h1 className="ceheading" style={{ opacity: loading ? 0 : 1 }}>
        {`Round ${currntgroupindex?.currentourndindex + 1}`} Matches
      </h1>
      <HistoryButtons origin={"multiplayer"} loading={loading} />
      <Row
        className="formorganizer"
        style={{
          padding: "1rem",
          gap: ".5rem",
          justifyContent: "flex-end",
          opacity: loading ? 0 : 1
        }}>
        {/* only show publish for current round if not published before */}
        {currntgroupindex?.currentourndindex === Matches?.currentround && !isPublished && (
          <Button
            onClick={() => setPublishModalOpen(true)}
            className="btnMobileDimensions"
            type="primary">
            Publish Round
          </Button>
        )}

        <div ref={e}>
          <Dropdown
            placement="bottomLeft"
            getPopupContainer={() => e.current}
            overlay={<Menu items={items} />}
            trigger={["click"]}>
            <AiFillSetting className="settingsicon" />
          </Dropdown>
        </div>
      </Row>
      <div
        className={`formorganizer ${loading ? "rowloadingevent" : null}`}
        style={{
          marginBottom: "50px",
          paddingBottom: "0"
        }}>
        {loading ? <Loader noShadow /> : null}
        <Row justify="center" style={{ display: loading ? "none" : undefined }}>
          <Col
            xl={6}
            lg={7}
            xs={24}
            sm={24}
            md={24}
            className="experiencecol"
            style={{ paddingBlock: "1.5%" }}>
            {/* remove collapse if their is only one group */}
            {currentroundsgroupsdata ? (
              currentroundsgroupsdata.length > 1 ? (
                <Collapse bordered={false} accordion>
                  {currentroundsgroupsdata.map((e, index) => (
                    <React.Fragment key={index}>
                      <Panel header={`Group ${e?.groupno + 1}`}>
                        {/* get final score and show it on top */}
                        {e?.matches
                          ?.filter((final) => final?.isFinalStanding === true)
                          ?.map((fMatch, fIndex) => {
                            if (
                              e?.matches?.filter((final) => final?.isFinalStanding === true)
                                .length > 0
                            ) {
                              return (
                                <Experience
                                  key={fIndex}
                                  data={fMatch}
                                  e={e}
                                  getOrdinalNum={getOrdinalNum}
                                  active={active}
                                  setactive={setactive}
                                />
                              );
                            }
                          })}
                        {/* show rest of the matches except the final */}
                        {e?.matches
                          ?.filter((m) => !m?.isFinalStanding)
                          .map((match, mindex) => (
                            <Experience
                              key={mindex}
                              data={match}
                              e={e}
                              getOrdinalNum={getOrdinalNum}
                              active={active}
                              setactive={setactive}
                            />
                          ))}
                      </Panel>
                    </React.Fragment>
                  ))}
                </Collapse>
              ) : (
                currentroundsgroupsdata.map((e, index) => (
                  <React.Fragment key={index}>
                    {e?.matches?.length > 2
                      ? e?.matches?.map((match, mindex) => (
                          <Experience
                            key={mindex}
                            data={match}
                            e={e}
                            getOrdinalNum={getOrdinalNum}
                            active={active}
                            setactive={setactive}
                          />
                        ))
                      : // if the group has only two matches it means one is the final score in that case only show single match results
                        e?.matches
                          ?.filter((match) => !match.isFinalStanding === true)
                          .map((match, mindex) => (
                            <Experience
                              key={mindex}
                              data={match}
                              e={e}
                              getOrdinalNum={getOrdinalNum}
                              active={active}
                              setactive={setactive}
                              finalMatch={true}
                            />
                          ))}
                  </React.Fragment>
                ))
              )
            ) : null}
          </Col>

          <Col
            xl={18}
            lg={17}
            xs={24}
            sm={24}
            md={24}
            style={{ padding: "1.5% 2%" }}
            className="ceinputformpadding">
            <h2
              style={{
                borderBottom: "1px solid  #282828",
                paddingBottom: "5px"
              }}>
              Standings
            </h2>

            {currentroundsgroupsdata?.map((e, i) => {
              return e?.matches?.map((match, mindex) => {
                return (
                  i === currntgroupindex?.gindex &&
                  currntgroupindex?.submatchindex === mindex && (
                    <Table
                      title={() => {
                        return (
                          <div className="tabletitlerow">
                            {/* if  is final score show final score title else match no */}
                            {match?.isFinalStanding ? (
                              <div className="tableTitle">{` Group  ${i + 1} Overall Score`}</div>
                            ) : (
                              <div className="tableTitle">{` Group  ${i + 1} - ${getOrdinalNum(
                                mindex + 1
                              )}`}</div>
                            )}
                            {eventcomplete ||
                            currntgroupindex?.currentourndindex !== Matches?.currentround ||
                            match?.isFinalStanding ? null : (
                              <FaEdit
                                className="groupediticon"
                                onClick={() => {
                                  setgroup({ data: match, index: i, open: true });
                                }}
                              />
                            )}
                          </div>
                        );
                      }}
                      key={`Group${i + 1}-${mindex}`}
                      scroll={{ x: 400 }}
                      style={{
                        paddingTop: e?.length < pagesise ? "20px" : "30px",
                        paddingBottom: e?.length < pagesise ? "50px" : null
                      }}
                      // show normal columns for other matches and qualifying for overall
                      columns={match?.isFinalStanding ? QualifyingColumn : Column}
                      dataSource={match?.table?.map((e, i) => {
                        if (match?.isFinalStanding && i < currentroundvariables?.qualifyingteams) {
                          return { ...e, key: i, Q: true };
                        }
                        return { ...e, key: i };
                      })}
                      size="middle"
                      pagination={{
                        total: e?.length,
                        defaultCurrent: 1,
                        defaultPageSize: pagesise,
                        hideOnSinglePage: true,
                        style: { margin: "50px 0 10px auto" }
                      }}
                      className="tablesBoxShadow scrollAble"
                    />
                  )
                );
              });
            })}
          </Col>
        </Row>
      </div>
      <ScheduleRounEditModal
        currentround={currntgroupindex?.currentourndindex}
        open={open}
        setopen={setopen}
        eventId={id}
        getData={get}
        wildCardData={wildCardData}
      />
      <ScheduleGroupPointsModal
        currentround={currntgroupindex}
        group={group}
        setgroup={setgroup}
        eventId={id}
        getData={get}
      />
      <ScheduleNextRound
        currentround={currntgroupindex}
        open={scheduleopen}
        setopen={setscheduleopen}
        eventId={id}
        wildCardData={wildCardData}
      />
      {/* Publish round confirmation modal */}
      <PublishRoundConfirmationModal
        id={state?._id || id}
        round={currntgroupindex?.currentourndindex + 1}
        open={publishModalOpen}
        setOpen={setPublishModalOpen}
        getData={get}
      />
      {/* Assign Awards on completion of event */}
      {assignAwardsOpen && (
        <AssignAwards
          open={assignAwardsOpen}
          setOpen={setAssignAwardsOpen}
          concludetournament={concludetournament}
          setAssignedAwards={setAssignedAwards}
          assignedAwards={assignedAwards}
        />
      )}
    </>
  );
};

export default Multiplayermatches;
