import React, { useState } from "react";

import ReactPlayer from "react-player/lazy";
import { BsPlayCircle } from "react-icons/bs";
import { ImCancelCircle } from "react-icons/im";
import { a } from "./videoblob";

function VideoCard({ src, display, Timeline, className, isEvent }) {
  const [playing, setplaying] = useState(false);
  return Timeline ? (
    <StaticUponClickVideo
      src={src}
      isEvent={isEvent}
      playing={playing}
      className={className}
      setplaying={setplaying}
    />
  ) : (
    <FixedUponClickVideo src={src} playing={playing} display={display} setplaying={setplaying} />
  );
}

export default VideoCard;

const StaticUponClickVideo = ({ src, playing, className }) => {
  return (
    <div className={className ? className : null}>
      <ReactPlayer
        playing={playing}
        controls={true}
        width={"100%"}
        height="100%"
        style={{
          maxHeight: "700px"
        }}
        url={[src, a]}
        fallback={a}
      />
    </div>
  );
};

const FixedUponClickVideo = ({ src, setplaying, display, playing }) => {
  return (
    <div style={{ position: "relative", width: "100%", background: "red" }}>
      {/* Player */}
      <ReactPlayer
        style={{
          display: display,
          position: playing ? "fixed" : undefined,
          top: "50%",
          left: "50%",
          transform: playing ? "translate(-50%,-50%)" : undefined,
          padding: playing ? 80 : undefined,
          zIndex: playing ? 1000 : undefined,
          background: "rgba(0,0,0,.5)",
          borderRadius: 15,
          maxHeight: playing ? "100%" : "initial",
          height: playing ? "100%" : "initial"
        }}
        playing={playing}
        controls={playing && true}
        width={playing ? "100vw" : "100%"}
        height={playing ? "100vh" : "200px"}
        url={src ? src : "http://localhost:3000/videos/dummy.mp4"}
      />

      {/* Playin Icon OverlayDiv and Icon */}
      <div
        onClick={() => setplaying(true)}
        className="playiconDiv"
        style={{
          display: playing ? "none" : "grid",
          position: playing ? "fixed" : "absolute",
          top: "50%",
          left: "50%",
          transform: playing ? "translate(-50%,-50%)" : undefined,
          height: "100%"
        }}>
        {playing ? null : <BsPlayCircle />}
      </div>

      {/* Cancel Button */}
      <ImCancelCircle
        onClick={() => setplaying(false)}
        style={{
          display: playing ? "grid" : "none",
          position: playing ? "fixed" : "absolute",
          top: "2%",
          right: "5%",
          zIndex: playing ? 1001 : undefined,
          fontSize: "2.5rem",
          color: "white",
          cursor: "pointer"
        }}
      />
    </div>
  );
};
