import React from "react";
import { DeletePlayerPost, EditPlayerPost } from "../../../Config/API/player";
import Loader from "../../../common/cards/Loader";
import { Row } from "antd";
import Tl from "../../../common/cards/TimelineCard";
import NoData from "../../../common/cards/noData";
import { useFetchProfilePosts } from "../../../common/hooks/useFetchProfilePosts";
import { useCreatePost } from "../../../common/hooks/useCreatePost";
import UploadPostcard from "../../../common/cards/UploadPostcard";

const DiscussionPlayer = () => {
  const { posts, setPosts, postloading } = useFetchProfilePosts("player");
  const {
    uploadloading,
    IMAGE,
    VIDEO,
    setimage,
    setvideo,
    text,
    setText,
    uploadingMedia,
    setuploadingMedia,
    CreatePost,
    setLuploading,
    Luploading
  } = useCreatePost(setPosts, "Player");

  return (
    <Row className="mobileto">
      <UploadPostcard
        uploadingMedia={uploadingMedia}
        setuploadingMedia={setuploadingMedia}
        setText={setText}
        text={text}
        IMAGE={IMAGE}
        setimage={setimage}
        VIDEO={VIDEO}
        setvideo={setvideo}
        onPost={CreatePost}
        loading={uploadloading}
        setLuploading={setLuploading}
        Luploading={Luploading}
      />
      {!postloading ? (
        posts?.length > 0 ? (
          posts?.map((data, index) => {
            return (
              <Tl
                Editing
                key={index}
                index={index}
                data={data}
                setPOSTs={setPosts}
                posts={posts}
                deletepost={DeletePlayerPost}
                editpost={EditPlayerPost}
              />
            );
          })
        ) : (
          <NoData />
        )
      ) : (
        <Loader />
      )}
    </Row>
  );
};

export default DiscussionPlayer;
