import React from "react";
import { Col, Row, Input } from "antd";
import {
  FaInstagram,
  FaFacebook,
  FaTwitter,
  FaLinkedinIn,
  FaArrowCircleRight
} from "react-icons/fa";
import Container from "../../../common/Container/Container";
import "../../../styles/layoutCss/footer.css";
const HomeFooter = () => {
  return (
    <Container>
      <Row>
        <Col xs={24} sm={12} md={6} lg={6}>
          <p className="heading">Spoflex</p>
          <Row>
            <Col xs={4} sm={4} md={6} lg={4}>
              <FaInstagram
                onClick={() => window.open("https://www.instagram.com/spoflexofficial", "_blank")}
                className="iconShow socialIcons"
              />
            </Col>
            <Col xs={4} sm={4} md={6} lg={4}>
              <FaFacebook
                onClick={() => window.open("https://www.facebook.com/spoflexofficial", "_blank")}
                className="iconShow socialIcons"
              />
            </Col>
            <Col xs={4} sm={4} md={6} lg={4}>
              <FaTwitter
                onClick={() => window.open("https://www.twitter.com/spoflex_off", "_blank")}
                className="iconShow socialIcons"
              />
            </Col>
            <Col xs={4} sm={4} md={6} lg={4}>
              <FaLinkedinIn
                onClick={() =>
                  window.open("https://www.linkedin.com/in/spoflex-in-ab8420257/", "_blank")
                }
                className="iconShow socialIcons"
              />
            </Col>
            {/* <Col xs={4} sm={4} md={6} lg={4}>
              <FaYoutube
                onClick={() =>
                  window.open(
                    "https://www.instagram.com/spoflexofficial",
                    "_blank"
                  )
                }
                className="iconShow"
              />
            </Col> */}
            <Col sm={8} xs={8} md={6} lg={8}></Col>
          </Row>
        </Col>
        <Col xs={24} sm={12} md={6} lg={6}>
          <p className="iconShow">Support</p>
          <p className="textColor">Contact Us</p>
          <p className="textColor">FAQ&apos;s</p>
          <p className="textColor">Customer Support</p>
          <p className="textColor footer-icons">Downloads</p>
        </Col>
        <Col xs={24} sm={12} md={6} lg={6}>
          <p className="iconShow">Community</p>
          <p className="textColor">Contact Us</p>
          <p className="textColor">FAQ&apos;s</p>
          <p className="textColor">Customer Support</p>
          <p className="textColor footer-icons">Downloads</p>
        </Col>
        <Col xs={24} sm={12} md={6} lg={6} className="footer-newsletter">
          <p className="iconShow">Join Our Newsletter</p>
          <p className="textColor">
            Subscribe to our newsletter and be the first to know about upcoming Thropic drops.
          </p>
          <Input
            className="footer"
            placeholder="Enter email"
            style={{
              background: "none",
              borderRadius: "20px",
              fontSize: "18px",
              borderColor: "white",
              borderWidth: "1.5px"
            }}
            suffix={<FaArrowCircleRight color="#00CC52" />}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default HomeFooter;
