// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.responsivebutton {
}
.responsivebutton.small {
  padding: 5px 25px !important;
  height: unset !important;
  border-radius: 20px !important;
}
`, "",{"version":3,"sources":["webpack://./src/styles/utils/Buttons/buttons.css"],"names":[],"mappings":"AAAA;AACA;AACA;EACE,4BAA4B;EAC5B,wBAAwB;EACxB,8BAA8B;AAChC","sourcesContent":[".responsivebutton {\n}\n.responsivebutton.small {\n  padding: 5px 25px !important;\n  height: unset !important;\n  border-radius: 20px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
