import { KEYS } from "../Keys";

export const MultiplayerSchedule = async (values) => {
  const response = await fetch(KEYS.API + "/mulitiplayerSchedule/schedule", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

export const MultiplayerScheduleGetData = async (values) => {
  const response = await fetch(KEYS.API + "/mulitiplayerSchedule/getdata", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};
export const MultiplayerUpdateGroupPoints = async (values) => {
  const response = await fetch(KEYS.API + "/mulitiplayerSchedule/assignpoints", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};
export const EditMultiplayerRoundSettings = async (values) => {
  const response = await fetch(KEYS.API + "/mulitiplayerSchedule/editround", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

export const MultiplayerConcludetournament = async (values) => {
  const response = await fetch(KEYS.API + "/mulitiplayerSchedule/concludetournament", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

export const MultiplayerScheduleNext = async (values) => {
  const response = await fetch(KEYS.API + "/mulitiplayerSchedule/schedulenextround", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

export const MultiplayerDeleteRound = async (values) => {
  const response = await fetch(KEYS.API + "/mulitiplayerSchedule/deleteround", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify(values)
  });
  return response;
};

export const MultiplayerPublishRound = async (data) => {
  // Create a new FormData object
  const formData = new FormData();

  // Append each key-value pair to the FormData object
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });

  const response = await fetch(KEYS.API + "/mulitiplayerSchedule/publishround", {
    method: "POST",
    headers: {
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: formData
  });
  return response;
};

export const MultiplayerConcludeRound = async (eventId) => {
  const response = await fetch(KEYS.API + "/mulitiplayerSchedule/concluderound", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("userToken")}`
    },
    body: JSON.stringify({ eventId })
  });
  return response;
};
