import { useEffect } from "react";
import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { error } from "../../common/Alerts/Message";
export const useCurrentPage = () => {
  const { id } = useParams();
  const { pathname } = useLocation();

  const [url, seturl] = useState("");
  const [isPlayer, setiSplayer] = useState(
    pathname.includes("player") && !pathname.includes("team") && !id
  );
  const [isOrganizer, setIsOrganizer] = useState(
    pathname.includes("organizer") && !pathname.includes("event") && !id
  );
  const [isPanel, setIsPanel] = useState(
    pathname.includes("panel") && id && !pathname.includes("event")
  );
  const [isTeam, setIsTeam] = useState(pathname.includes("team") && id);
  const [isEvent, setIsEvent] = useState(pathname.includes("event"));
  const [other, setIsOther] = useState(pathname.includes("view"));
  function get() {
    try {
      if (pathname.includes("feed")) {
        seturl("feed");
        return;
      } else if (pathname.includes("timeline")) {
        seturl("timeline");
        return;
      } else if (pathname.includes("about")) {
        seturl("about");
        return;
      } else if (pathname.includes("media")) {
        seturl("media");
        return;
      } else if (pathname.includes("sponsors")) {
        seturl("sponsors");
        return;
      } else if (pathname.includes("members")) {
        seturl("members");
        return;
      } else if (pathname.includes("followings")) {
        seturl("followings");
        return;
      } else if (pathname.includes("followers")) {
        seturl("followers");
        return;
      } else if (pathname.includes("event")) {
        seturl("event");
        return;
      } else if (pathname.includes("panel") && !pathname.includes("search")) {
        seturl("panel");
        return;
      } else if (pathname.includes("team") && !pathname.includes("search")) {
        seturl("team");
        return;
      } else if (pathname.includes("messages")) {
        seturl("messages");
        return;
      } else if (pathname.includes("wallet")) {
        seturl("wallet");
        return;
      }
      // else throw new Error()
    } catch (e) {
      error(e);
      console.log(e);
    }
  }
  useEffect(() => {
    get();

    return () => {
      seturl("");
    };
  }, [pathname]);
  return {
    url,
    other,
    setIsOther,
    isPanel,
    setIsPanel,
    isOrganizer,
    setIsOrganizer,
    isPlayer,
    setiSplayer,
    isTeam,
    setIsTeam,
    isEvent,
    setIsEvent,
    isOrgEvent: pathname.includes("organizer") && pathname.includes("event"),
    teamPanel: pathname.includes("team") ? "team" : pathname.includes("panel") ? "panel" : false
  };
};
