import React from "react";
import PAGENTFOUND from "../../errors/404Error";

function PageNotFound() {
  return (
    // <div style={{
    //     width: "100%",
    //     height: "500px",
    //     display: "grid",
    //     placeContent: "center",
    //     background: "white"
    // }}>
    //     {
    //         text ?
    //             <Typography >
    //                 <Title level={1} style={{ textAlign: "center", color: "var(--blue)" }}>
    //                     {text}
    //                 </Title>
    //             </Typography>
    //             : <Typography >
    //                 <Title level={1} style={{ textAlign: "center", color: "var(--red)" }}>
    //                     Page Not Found!
    //                 </Title>
    //                 <Text style={{ textAlign: "center" }}>
    //                     Looks Like you have Entered a wrong url!!!
    //                 </Text>
    //             </Typography>
    //     }
    // </div>
    <PAGENTFOUND />
  );
}

export default PageNotFound;
