import React from "react";
import { DeleteOrganizerPost, EditOrganizerPost } from "../../../Config/API/organizer";
import Loader from "../../../common/cards/Loader";
import { Row } from "antd";
import Tl from "../../../common/cards/TimelineCard";
import NoData from "../../../common/cards/noData";
import { useFetchProfilePosts } from "../../../common/hooks/useFetchProfilePosts";
import UploadPostcard from "../../../common/cards/UploadPostcard";
import { useCreatePost } from "../../../common/hooks/useCreatePost";

const DiscussionOrganizer = () => {
  const { posts, setPosts, postloading } = useFetchProfilePosts("organizer");
  const {
    uploadloading,
    IMAGE,
    VIDEO,
    setimage,
    setvideo,
    text,
    setText,
    uploadingMedia,
    setuploadingMedia,
    CreatePost,
    setLuploading,
    Luploading
  } = useCreatePost(setPosts, "Organizer");

  return (
    <Row className="mobileto">
      <UploadPostcard
        uploadingMedia={uploadingMedia}
        setuploadingMedia={setuploadingMedia}
        setText={setText}
        text={text}
        IMAGE={IMAGE}
        setimage={setimage}
        VIDEO={VIDEO}
        setvideo={setvideo}
        onPost={CreatePost}
        loading={uploadloading}
        setLuploading={setLuploading}
        Luploading={Luploading}
      />
      {postloading ? (
        <Loader />
      ) : posts?.length > 0 ? (
        posts?.map((data, index) => {
          return (
            <Tl
              Editing
              key={index}
              data={data}
              deletepost={DeleteOrganizerPost}
              setPOSTs={setPosts}
              editpost={EditOrganizerPost}
            />
          );
        })
      ) : (
        <NoData />
      )}
    </Row>
  );
};

export default DiscussionOrganizer;
