import React, { Fragment } from "react";
import { Col, Row, Card, Skeleton } from "antd";
import { useState } from "react";
import { error, Info, success } from "../../../../../../common/Alerts/Message";
import { AssignWinner } from "../../../../../../Config/API/events";
import { useParams } from "react-router-dom";
import MatchTeam from "./Btns/Team";
import VS from "./Btns/VS";

import { BiEdit } from "react-icons/bi";

const MatchesCard = ({
  item,
  index, //index of this element
  Orderloading, //loading while swapping,Button Loading
  SwappedEle, //API loading, Skeleton Loading
  sortedpoints,
  seteditresult
}) => {
  const [loading, setloading] = useState(false);

  const { id } = useParams();

  //assign winner api call
  const assignWinner = async (item, teamno) => {
    try {
      const matchId = item?.match?._id;
      let winner = teamno === 1 ? item?.match["team1"]?._id : item?.match["team2"]?._id;
      let loser = teamno === 1 ? item?.match["team2"]?._id : item?.match["team1"]?._id;

      if (!winner || !loser || !id || !matchId) return Info("variables are missing");

      setloading(true);

      var values = {
          matchId,
          loser,
          winner,
          eventId: id,
          winnerExistCheck: "notexist"
          // winPrevious: "nodraw"
        },
        res = null,
        data = null;

      res = await AssignWinner(values);
      data = await res.json();
      console.log(data);
      if (data?.type === "success") {
        success(data?.result);
        //  updates data
        await sortedpoints(data?.data[0]);
      } else {
        error(data?.result);
      }
      setloading(false);
    } catch (e) {
      console.log(e);
      error(e.message);
      setloading(false);
    }
  };

  const CardHead = () => {
    return (
      <div>
        <span>Edit</span>
        <BiEdit onClick={() => seteditresult((e) => !e)} />
      </div>
    );
  };

  const t1 = item?.match["team1"]?._id;
  const t2 = item?.match["team2"]?._id;
  const win = item?.match?.win === t1 || item?.match?.win === t2 || item?.match?.win === "draw";

  return (
    <Fragment>
      <Card
        title={win ? <CardHead /> : null}
        className="tablesBoxShadow MatchCard"
        bordered={false}
        style={{
          padding: "0px",
          marginTop: "10px"
        }}>
        {SwappedEle?.ele?.includes(index) && SwappedEle?.status === "loading" ? (
          <Skeleton.Button
            active
            style={{ minHeight: "78px", width: "100%" }}
            size="large"
            shape="square"
            block></Skeleton.Button>
        ) : (
          <Row>
            <Col lg={10} xs={8} sm={8} md={8}>
              <MatchTeam
                t={1}
                item={item}
                loading={loading}
                Orderloading={Orderloading}
                assignWinner={assignWinner}
              />
            </Col>
            <Col lg={4} md={8} sm={8} xs={8}>
              <VS
                index={index}
                item={item}
                loading={loading}
                setloading={setloading}
                Orderloading={Orderloading}
                seteditresult={seteditresult}
              />
            </Col>
            <Col lg={10} xs={8} sm={8} md={8}>
              <MatchTeam
                t={2}
                item={item}
                loading={loading}
                Orderloading={Orderloading}
                assignWinner={assignWinner}
              />
            </Col>
          </Row>
        )}
      </Card>
    </Fragment>
  );
};

export default MatchesCard;
