import React, { Fragment, useState, useEffect } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import CommentForm from "./Components/CommentForm";
import EditModal from "./Components/Modal";
import { Comment, Skeleton } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { poststate, setpost } from "../../../../Store/Features/PostSlice";
import {
  checkProfileImage,
  checkProfileName,
  checkCommentDelete,
  checkCommentEdit,
  fallbackprofile,
  getuserlink
} from "../../../Utils";
import moment from "moment";
import { editComment, editReply, deleteComment, delteReply } from "./utils.js";
import Loader from "../../Loader";
import { useNavigate, useParams } from "react-router-dom";
import FadeInImage from "../../../Images/FadeInImage";

export default function PostComments({
  comments,
  onFinish,
  setPOSTs,
  postId,
  loading,
  setloading,
  current,
  FLAG,
  PID
}) {
  const [showreply, setshowreply] = useState([]); // replies toggle
  const [edit, setedit] = useState(""); // edit toggle
  const [cardloading, setcardloading] = useState(false);
  const checkrole = { role: FLAG, id: PID };
  const dispatch = useDispatch();
  let post = useSelector(poststate);
  const { id } = useParams();
  const navigate = useNavigate();

  const EditComment = async (e, cid) => {
    await editComment(e, cid, checkrole, postId, setloading, setPOSTs, current, id);
    setedit(false);
  };
  const EditReply = async (e, cid) => {
    await editReply(e, cid, checkrole, postId, loading, setloading, setPOSTs, current);
    setedit(false);
  };
  const DeleteComment = async (cid) => {
    const d_id = cid;
    dispatch(setpost(d_id));
    await deleteComment(cid, checkrole, postId, setPOSTs, current, id);
    dispatch(setpost(d_id));
  };
  const DeleteReply = async (cid) => {
    const d_id = cid;
    dispatch(setpost(d_id));
    await delteReply(cid, checkrole, postId, setPOSTs, current, id);
    dispatch(setpost(d_id));
  };

  useEffect(() => {
    return () => {
      setcardloading(null);
      setshowreply(null);
      setedit(false);
    };
  }, []);

  const togglereplyvisible = (c) => {
    const SR = showreply;
    if (SR.includes(c?._id)) {
      const a = SR.filter((e) => e !== c?._id);
      setshowreply(a);
      return;
    } else setshowreply([...showreply, c?._id]);
  };

  const cardloadingstyle = {
    opacity: cardloading ? "0" : "1",
    maxHeight: cardloading ? "1px" : "unset",
    overflowY: cardloading ? "hidden" : "unset"
  };
  return (
    <Fragment>
      {!checkrole.role && !checkrole.id ? (
        <Loader small />
      ) : (
        <Fragment>
          {" "}
          {comments?.map((comment, i) => {
            return post?.includes(comment?._id) ? (
              <Skeleton active avatar key={i} />
            ) : (
              <Fragment key={i}>
                {cardloading ? <Skeleton style={{ padding: "5px" }} avatar active /> : null}
                <div key={i} style={cardloadingstyle}>
                  <Comment
                    author={
                      <span
                        style={{
                          display: "flex",
                          gap: "1rem",
                          flexWrap: "wrap"
                        }}>
                        <span
                          onClick={() => {
                            const link = getuserlink(comment);
                            navigate(link.url, { state: link.name });
                          }}
                          style={{
                            color: "black",
                            fontWeight: "600",
                            fontSize: "13px",
                            cursor: "pointer"
                          }}>
                          {checkProfileName(comment)}
                        </span>
                        <span className="timelinedate">{moment(comment?.date).fromNow()}</span>
                        {checkCommentEdit(comment, setedit)}
                        {checkCommentDelete(comment, DeleteComment, DeleteReply)}
                      </span>
                    }
                    avatar={
                      <FadeInImage
                        type={"Fadepostprofile"}
                        fallaback={fallbackprofile}
                        src={checkProfileImage(comment)}
                        onClick={() => {
                          const link = getuserlink(comment);
                          navigate(link.url, { state: link.name });
                        }}
                        radiusAll
                      />
                    }
                    content={
                      <Fragment>
                        {/* Comment/ Message  */}
                        <p style={{ whiteSpace: "normal" }}>{comment.comment}</p>

                        {/* Button for viewing comment replies */}
                        {comment?.reply && (
                          <span
                            className={`commentsDownButton 
                             ${showreply?.includes(comment?._id) ? "true" : "false"}`}
                            onClick={() => togglereplyvisible(comment)}>
                            {comment.reply.length === 0 && " Add reply"}
                            {comment.reply.length === 1 && " 1 reply"}
                            {comment.reply.length > 1 && comment.reply.length + " replies"}
                            <MdKeyboardArrowDown />
                          </span>
                        )}

                        {/* Component for viewing comment replies */}
                        {
                          comment?.reply?.length > 0 && showreply?.includes(comment?._id) && (
                            <PostComments
                              comments={comment.reply}
                              postId={postId}
                              setPOSTs={setPOSTs}
                              loading={loading}
                              setloading={setloading}
                              current={current}
                              FLAG={FLAG}
                              PID={PID}
                            />
                          ) // recursive calling comment
                        }

                        {/* This Code Matches current comment id with the id the reply is clicked on by user */}
                        {/* Component for replying on comments  */}
                        {showreply?.includes(comment?._id) && (
                          <CommentForm
                            onFinish={onFinish}
                            commentId={comment?._id}
                            loading={loading}
                            status="Reply"
                          />
                        )}
                      </Fragment>
                    }
                  />

                  {/* Comments and replies edit component */}
                  <EDITCOMPONENT
                    edit={edit}
                    setedit={setedit}
                    comment={comment}
                    checkrole={checkrole}
                    EditComment={EditComment}
                    EditReply={EditReply}
                    loading={loading}
                  />
                </div>
              </Fragment>
            );
          })}{" "}
        </Fragment>
      )}
    </Fragment>
  );
}

const EDITCOMPONENT = ({ edit, setedit, comment, checkrole, EditReply, EditComment, loading }) => {
  return (
    <Fragment>
      {/* Editing Comment */}
      <CommentEdit
        edit={edit}
        setedit={setedit}
        comment={comment}
        checkrole={checkrole}
        EditComment={EditComment}
        loading={loading}
      />
      {/* Editing Reply */}
      <ReplyEdit
        edit={edit}
        setedit={setedit}
        comment={comment}
        checkrole={checkrole}
        EditReply={EditReply}
        loading={loading}
      />
    </Fragment>
  );
};

const CommentEdit = ({ edit, setedit, comment, checkrole, EditComment, loading }) => {
  return (
    <Fragment>
      {edit === comment?._id &&
        (comment?.organizer?._id === checkrole.id ||
          comment?.player?._id === checkrole.id ||
          comment?.panel?._id === checkrole?.id ||
          comment?.team?._id === checkrole?.id) &&
        comment?.reply && (
          <EditModal
            isOpen={edit}
            setIsOPen={setedit}
            comment={comment}
            editComment={EditComment}
            loading={loading}
            text={"Edit Comment"}
          />
        )}
    </Fragment>
  );
};

const ReplyEdit = ({ edit, setedit, comment, checkrole, EditReply, loading }) => {
  return (
    <Fragment>
      {edit === comment?._id &&
        (comment?.organizer?._id === checkrole.id ||
          comment?.player?._id === checkrole.id ||
          comment?.panel?._id === checkrole?.id ||
          comment?.team?._id === checkrole?.id) &&
        (comment?.reply === null || comment?.reply === undefined) && (
          <EditModal
            isOpen={edit}
            setIsOPen={setedit}
            comment={comment}
            editComment={EditReply}
            text="Edit Reply"
            loading={loading}
          />
        )}
    </Fragment>
  );
};
