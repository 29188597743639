import React from "react";
import "./publishRoundCard.css";
import { Button } from "antd";
import "./publishRoundCard.css";

function PublishedRoundCard({ data }) {
  return (
    <div className="publishCardWrapper">
      <h1>Round Published!</h1>
      <p>Round {data?.round} of the event has been published</p>
      <Button href={data?.url} type="primary">
        View Round
      </Button>
    </div>
  );
}

export default PublishedRoundCard;
