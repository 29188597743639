import React, { Fragment, useState } from "react";

import ReactPlayer from "react-player";
import { BsPlayCircle } from "react-icons/bs";
import { ImCancelCircle } from "react-icons/im";
import { useEffect } from "react";

function VideoCard({ src, s200 }) {
  const [playing, setplaying] = useState(false);
  useEffect(() => {
    window.addEventListener("keydown", handlekeypress);
    return () => {
      window.removeEventListener("keydown", handlekeypress);
    };
  }, []);

  const handlekeypress = (e) => e.keyCode === 27 && setplaying(false);

  const Wrapper = ({ children }) => (
    <div
      className={`${s200 && !playing ? "s200" : null} ${playing ? "MVF_Wrapper" : "MV_Wrapper"}`}>
      {children}
      {/* Playin Icon OverlayDiv and Icon */}
      <div
        onClick={() => setplaying(true)}
        className={`${playing ? "playiconNone" : "playiconDiv"} `}>
        <BsPlayCircle />
      </div>
    </div>
  );
  return (
    <Fragment>
      {/* Player */}
      <ReactPlayer wrapper={Wrapper} playing={playing} controls={playing && true} loop url={src} />

      {/* Cancel Button */}
      <ImCancelCircle
        className={`${playing ? "MVF_CloseButton" : "MV_CloseButton"}`}
        onClick={() => setplaying(false)}
      />
    </Fragment>
  );
}

export default VideoCard;
