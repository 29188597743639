import React from "react";
import { BackTop } from "antd";
import { BiArrowToTop } from "react-icons/bi";
import "../../../styles/scrolltotop.css";
function ScrollToTop() {
  return (
    <BackTop style={{ zIndex: "12" }}>
      <div className="backtotop">
        <BiArrowToTop className="topicon" />
      </div>
    </BackTop>
  );
}

export default ScrollToTop;
