import React from "react";
import Loader from "../../../../../../../common/cards/Loader";
import ScheduleNexround from "./schedulenext/index";
import { useState } from "react";
import { Modal } from "antd";

function ScheduleRounEditModal({ currentround, open, setopen, wildCardData }) {
  const [requesting, setrequesting] = useState(false);

  if (!open) return null;

  return (
    <Modal
      className="editPost  schedulemodal"
      confirmLoading
      mask
      maskClosable
      onCancel={() => {
        setopen(false);
      }}
      closable={false}
      centered
      destroyOnClose={true}
      visible={open}
      open={open}
      footer={`Current Round ${currentround.currentourndindex + 1} `}
      bodyStyle={{
        padding: "0px 0px 20px 0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}>
      {requesting && <Loader smaller noShadow center />}
      <ScheduleNexround
        loading={requesting}
        setloading={setrequesting}
        setopen={setopen}
        wildCardData={wildCardData}
      />
    </Modal>
  );
}

export default ScheduleRounEditModal;
