// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.backtotop {
  color: rgba(64, 64, 64, 0.6);
  height: 45px;
  width: 45px;
  line-height: 40px;
  border-radius: 50%;
  background-color: var(--blue);
  color: #fff;
  text-align: center;
  font-size: 14;
  display: grid;
  place-content: center;
  box-shadow: 0px 0px 4px 3px #1010102e;
}

.topicon {
  font-size: 1.8rem;
  animation: a 1.2s infinite alternate;
}
@keyframes a {
  0% {
    transform: translateY(3px);
  }
  100% {
    transform: translateY(-3px);
  }
}

@media screen and (max-width: 576px) {
  .backtotop {
    height: 35px;
    width: 35px;
  }
  .topicon {
    font-size: 1.4rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/scrolltotop.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,YAAY;EACZ,WAAW;EACX,iBAAiB;EACjB,kBAAkB;EAClB,6BAA6B;EAC7B,WAAW;EACX,kBAAkB;EAClB,aAAa;EACb,aAAa;EACb,qBAAqB;EACrB,qCAAqC;AACvC;;AAEA;EACE,iBAAiB;EACjB,oCAAoC;AACtC;AACA;EACE;IACE,0BAA0B;EAC5B;EACA;IACE,2BAA2B;EAC7B;AACF;;AAEA;EACE;IACE,YAAY;IACZ,WAAW;EACb;EACA;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".backtotop {\n  color: rgba(64, 64, 64, 0.6);\n  height: 45px;\n  width: 45px;\n  line-height: 40px;\n  border-radius: 50%;\n  background-color: var(--blue);\n  color: #fff;\n  text-align: center;\n  font-size: 14;\n  display: grid;\n  place-content: center;\n  box-shadow: 0px 0px 4px 3px #1010102e;\n}\n\n.topicon {\n  font-size: 1.8rem;\n  animation: a 1.2s infinite alternate;\n}\n@keyframes a {\n  0% {\n    transform: translateY(3px);\n  }\n  100% {\n    transform: translateY(-3px);\n  }\n}\n\n@media screen and (max-width: 576px) {\n  .backtotop {\n    height: 35px;\n    width: 35px;\n  }\n  .topicon {\n    font-size: 1.4rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
