import { useState, useEffect } from "react";

import { GetOrganizerPosts } from "../../Config/API/organizer";
import { GetPlayerPosts } from "../../Config/API/player";
import { GetPanelPosts } from "../../Config/API/panel";
import { GetTeamPosts } from "../../Config/API/teams";

import { useSelector } from "react-redux";
import { PROFILE } from "../../Store/Features/ProfileSlice";

export function useFetchProfilePosts(state) {
  const ID = useSelector(PROFILE);
  const [totalPages, setTotalPages] = useState(0);
  const [postloading, setpostloading] = useState(true);
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(1);
  const [fetchTime, setFetchTime] = useState(500); // Default value of 500ms

  const GetPosts = async (controller) => {
    try {
      if (ID?._id) {
        setpostloading("white");
        let response, data;

        if (state === "player") {
          response = await GetPlayerPosts(ID?._id, controller);
          if (!response.ok) {
            throw new Error(response.statusText + " - " + response.url);
          }
          data = await response.json();
        }
        if (state === "team") {
          response = await GetTeamPosts(ID?._id, controller, page);
          if (!response.ok) {
            console.log("here");
            throw new Error(response.statusText + " - " + response.url);
          }
          data = await response.json();
        } else if (state === "organizer") {
          response = await GetOrganizerPosts(ID?._id, controller);
          if (!response.ok) {
            throw new Error(response.statusText + " - " + response.url);
          }
          data = await response.json();
        } else if (state === "panel") {
          const startTime = performance.now(); // Start timing

          response = await GetPanelPosts(ID?._id, controller, page);

          const endTime = performance.now(); // End timing
          setFetchTime(endTime - startTime); // Set fetch time

          if (!response.ok) {
            throw new Error(response.statusText + " - " + response.url);
          }
          data = await response.json();
        }
        if (data?.type === "success") {
          setPosts(data?.result || data?.data || []);
          setTotalPages(data?.metaData?.totalPages || 1);
          setpostloading(false);
        }
        setpostloading(false);
      } else {
        setpostloading(false);
        setTimeout(() => {
          GetPosts();
        }, 1500);
      }
    } catch (e) {
      setpostloading(false);
      console.log(e);
    } finally {
      setpostloading(false);
    }
  };
  useEffect(() => {
    const controller = new AbortController();
    GetPosts(controller);
    return () => {
      controller.abort();
      setpostloading(null);
      setPosts([]);
    };
  }, [ID?._id]);

  useEffect(() => {
    if (page === 1) return;
    const controller = new AbortController();
    GetPosts(controller);
  }, [page]);

  // }, [ID?._id, state]); // previous before linting

  return {
    posts,
    setPosts,
    postloading,
    setPage,
    totalPages,
    page,
    fetchTime // Return fetch time
  };
}
