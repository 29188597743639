import React, { Fragment } from "react";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";

const About = ({ data }) => {
  return (
    <Fragment>
      <Row className="sideItem">
        <Col span={24}>
          <h1 className="timelineAboutpadding">About</h1>
          <p className="timelinedesc">
            {data?.description ||
              `
                It is a long established fact that a reader will be distracted by  the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using
                 `}
          </p>
          <p className="timelineiconpadding flexcenter">
            <img src="/images/icons/2people.png" className="timelineiconplacecenter" alt="" />

            <span className="sideItemsText">
              {data?.registerteams?.length === 1
                ? data?.registerteams?.length + " Team "
                : data?.registerteams?.length + "  Teams are "}
              Registered.
            </span>
          </p>
          <p className="timelineiconpadding flexcenter">
            <img src="/images/icons/peoples.png" className="timelineiconplacecenter" alt="" />

            <span className="sideItemsText">
              Event by{" "}
              <Link
                state="panel"
                to={`/view/panel/${data?.panel?._id}/timeline`}
                className="boldBlueColor">
                {data?.panel?.name}
              </Link>
            </span>
          </p>
          <p className="timelineiconpadding flexcenter">
            <img src="/images/icons/location.png" className="timelineiconplacecenter" alt="" />

            <span className="sideItemsText">{data?.location}</span>
          </p>
          <p className="timelineiconpadding flexcenter">
            <img src="/images/icons/money.png" className="timelineiconplacecenter" alt="" />

            <span className="sideItemsText">
              {data?.fee !== 0 ? (
                <span>{data?.fee} Rs per Team</span>
              ) : (
                <span>Free Registration</span>
              )}
            </span>
          </p>
          <p className="timelineiconpadding flexcenter">
            <img src="/images/icons/aboutcalender.png" className="timelineiconplacecenter" alt="" />
            <span
              className="sideItemsText timelinetopcolor"
              style={{ display: "flex", gap: ".3rem" }}>
              <span>{new Date(data?.startDate).toDateString()}</span>-
              <span>{new Date(data?.endDate).toDateString()}</span>
            </span>
          </p>
        </Col>
      </Row>
    </Fragment>
  );
};

export default About;
