import { forwardRef } from "react";
import { MdOutlineInsertComment, MdComment, MdKeyboardArrowDown } from "react-icons/md";
import CommentForm from "../PostComments/Components/CommentForm";
import { BsHandThumbsUpFill, BsHandThumbsUp } from "react-icons/bs";
import React, { Fragment, useState, useEffect } from "react";
import CommentandLikes from "../PostComments/Comments";
import { useDispatch } from "react-redux/es/exports";
import { KEYS } from "../../../Config/Keys";
import OneImage from "../../Images/OneImage";
import VideoCard from "../Media/VideoCard";
import { Row, Col, Avatar } from "antd";
import moment from "moment";
import {
  checkProfileImage,
  checkProfileName,
  fallbackcover,
  PostMeidaLoader
} from "../../Utils/index";
import { PostComment, CommentReply, PostLike, PostUnlike } from "../../../Config/API/posts";
import { error } from "../../Alerts/Message";
import { setempty } from "../../../Store/Features/PostSlice";
import PostSkelton from "../Loader/PostSkelton";
import { useNavigate } from "react-router-dom";
import useVariables from "../../hooks/useVariables";
import EventScheduleCard from "./EventScheduleCard";
import EventRoundPublishCard from "./EventRoundPublishCard";
import EventCreateCard from "./EventCreateCard";
import EventCompleteCard from "./EventCompleteCard";

const FeedCard = forwardRef(({ data, index, POSTS, setPOSTs }, ref) => {
  const [loading, setloading] = useState(false); //for comments
  const [cardloading, setcardloading] = useState(true); //for card
  const [ppicloading, setppicloading] = useState(true); //for profile
  const [liked, setliked] = useState(false); //like button clicked
  const [addcomment, setaddcomment] = useState("");
  const [publishedRoundPosts, setPublishedRoundPosts] = useState({
    isPublished: false,
    data: ""
  });
  const [scheduledRoundPosts, setScheduledRoundPosts] = useState({
    isPublished: false,
    data: ""
  });
  const [createdPosts, setCreatedPosts] = useState({
    isPublished: false,
    data: ""
  });
  const [compltedPosts, setCompltedPosts] = useState({
    isPublished: false,
    data: ""
  });

  var length = 0;
  data?.comments?.forEach((e) => {
    length += 1;
    e?.reply?.forEach(() => (length += 1));
  });

  const [TotalCommentsLike, setTotalCommentsLike] = useState({
    like: data?.likes?.length,
    comments: length
  }); //like button clicked
  const [commented, setcommented] = useState(false); //comment button clicked

  const dispatch = useDispatch();
  const { PID, FLAG } = useVariables();
  // check weather you current user liked the post
  useEffect(() => {
    if (PID)
      data?.likes?.forEach((e) => {
        if (Object.prototype.hasOwnProperty.call(e, "player")) {
          const player = e?.player === PID;
          if (player) setliked(true);
        } else if (Object.prototype.hasOwnProperty.call(e, "organizer")) {
          const org = e?.organizer === PID;
          if (org) setliked(true);
        } else if (Object.prototype.hasOwnProperty.call(e, "panel")) {
          const panell = e?.panel === PID;
          if (panell) setliked(true);
        } else if (Object.prototype.hasOwnProperty.call(e, "team")) {
          const teamm = e?.team === PID;
          if (teamm === true) setliked(true);
        }
      });
    else {
      // console.log(id);
    }
  }, [data?.likes]);

  // post media card loader
  useEffect(() => {
    PostMeidaLoader(data, setcardloading);
    return () => {
      setloading(null);
      setliked(null);
      setTotalCommentsLike(null);
      setaddcomment(null);
      setcommented(null);
      setppicloading(null);
      dispatch(setempty());
    };
  }, []);

  // check for comments length
  useEffect(() => {
    let length = 0;
    data?.comments?.forEach((e) => {
      length += 1;
      e?.reply?.forEach(() => (length += 1));
    });
    setTotalCommentsLike({
      like: TotalCommentsLike?.like,
      comments: length
    });
  }, [data?.comments]);

  const replacePostDataWithNewData = (updatedData) => {
    let updatedPosts = [...POSTS];
    updatedPosts[index] = updatedData?.[0] ?? updatedData;
    setPOSTs(updatedPosts);
  };

  useEffect(() => {
    if (data?.postType) {
      const { postType } = data;

      if (postType === "conclude" && !publishedRoundPosts.isPublished) {
        setPublishedRoundPosts({
          isPublished: true,
          data: data
        });
      } else if (postType === "create" && !createdPosts.isPublished) {
        setCreatedPosts({
          isPublished: true,
          data: data
        });
      } else if (postType === "schedule" && !scheduledRoundPosts.isPublished) {
        setScheduledRoundPosts({
          isPublished: true,
          data: data
        });
      } else if (postType === "complete" && !compltedPosts.isPublished) {
        setCompltedPosts({
          isPublished: true,
          data: data
        });
      }
    }
  }, [data?.postType]);
  const getLikeUnlikevalues = () => {
    return {
      userRole: FLAG,
      id: data?._id,
      user: PID,
      flag: FLAG
    };
  };
  // Like Post API
  const LikePost = async () => {
    setTotalCommentsLike({
      like: TotalCommentsLike.like + 1,
      comments: TotalCommentsLike.comments
    });
    setliked((e) => !e);

    const values = getLikeUnlikevalues();
    let res = await PostLike({ ...values });
    const dataa = await res.json();
    if (dataa.type === "success") replacePostDataWithNewData(dataa.data);
    if (dataa.type === !"success") error(data?.result);
  };

  // unLike Post API
  const UnLikePost = async () => {
    setTotalCommentsLike({
      like: TotalCommentsLike?.like > 1 ? TotalCommentsLike?.like - 1 : 0,
      comments: TotalCommentsLike?.comments
    });
    setliked((e) => !e);

    const values = getLikeUnlikevalues();

    let res = await PostUnlike({ ...values });
    const dataa = await res.json();
    if (dataa.type === "success") replacePostDataWithNewData(dataa.data);
    if (dataa.type === !"success") error(data?.result);
  };

  // comment and reply post API
  const PostCommentReply = async (comment) => {
    try {
      if (!PID || !FLAG) return error("one of the fields are missing");
      if (comment.val === "" && comment?.val === null) {
        setloading(false);
        return error("Add something in the text field before proceeding");
      }
      setloading(true);

      const values = {
        userRole: FLAG,
        id: comment.postId || comment.commentId,
        postId: data?._id,
        user: PID,
        comment: comment.val,
        flag: FLAG
      };
      let res = "";

      if (comment?.commentId) res = await CommentReply({ ...values });
      else if (comment?.postId) res = await PostComment({ ...values });
      const d = await res.json();

      if (d.type === "success") replacePostDataWithNewData(d.data);
      if (data.type === !"success") error(data?.result);
      setloading(false);
    } catch (e) {
      setloading(false);

      error(e.message);
    }
  };

  //post card styles while loading
  const cardloadingstyle = {
    opacity: cardloading || ppicloading ? "0" : "1",
    maxHeight: cardloading || ppicloading ? "0px" : "unset",
    overflowY: cardloading || ppicloading ? "hidden" : "unset"
  };

  //Post Media Files
  const PostMedia = (
    <Fragment>
      {data?.file?.type === "image" ? <OneImage src={data?.file?.file} /> : null}
      {data?.file?.type === "video" ? (
        <VideoCard
          nodefault
          className="timelineimage"
          Timeline
          src={KEYS.API + "/" + data?.file?.file}
        />
      ) : null}
    </Fragment>
  );

  // Post and Comments and Likes Section
  const PostLikesCommentsComponent = (
    <Row className="postLikesAndCommentsSection">
      <Col span={24}>
        <p className="timelinePadding pLCsvgWrapper">
          <img src="/images/icons/like.png" alt="like" />
          <span className="timelinelikesection">{TotalCommentsLike?.like}</span>
          <span className="pLCsvgCommentWrapper" onClick={() => setcommented((e) => !e)}>
            <img alt="IMG" src="/images/icons/comment.png" />
            <span className="timelinelikesection">{TotalCommentsLike?.comments}</span>
          </span>
        </p>

        <p className="likes_comments">
          {liked ? (
            <span onClick={UnLikePost} style={{ color: "var(--blue)" }}>
              <BsHandThumbsUpFill /> Like
            </span>
          ) : (
            <span onClick={LikePost} style={{ color: "#939393" }}>
              <BsHandThumbsUp /> Like
            </span>
          )}
          <span
            onClick={() => setcommented((e) => !e)}
            style={{ color: commented ? "var(--blue)" : "#939393" }}>
            {commented ? <MdComment /> : <MdOutlineInsertComment />}
            Comment
          </span>
        </p>

        {/* post comments */}
        {commented ? (
          <CommentandLikes
            setPOSTs={setPOSTs}
            onFinish={PostCommentReply}
            postId={data?._id}
            comments={data?.comments}
            loading={loading}
            setloading={setloading}
            PID={PID}
            FLAG={FLAG}
            replacePostDataWithNewData={replacePostDataWithNewData}
          />
        ) : null}
        {/* post comments Text Area */}
        <Fragment>
          {commented && (
            <span
              className={`
                    commentsDownButton 
                   ${addcomment === data?._id ? "true" : "false"}`}
              onClick={() => setaddcomment((e) => (e === data?._id ? "" : data?._id))}>
              {" Add Comment"}
              <MdKeyboardArrowDown />
            </span>
          )}
          {addcomment === data?._id && commented && (
            <CommentForm
              onFinish={PostCommentReply}
              postId={data?._id}
              loading={loading}
              status="Comment"
            />
          )}
        </Fragment>
      </Col>
    </Row>
  );

  const navigate = useNavigate();

  const UserInfo = (
    <div className="avatarNameWrapper">
      <Avatar
        className="profilepic timelineavatar"
        src={
          <img
            onClick={() => {
              data?.user?._id ||
              data?.user?.playerId ||
              data?.playerId?._id ||
              data?.organizerId?._id
                ? navigate(
                    `/view/${data?.user?.playerId ? "player" : "organizer"}/${
                      data?.user?._id || data?.organizerId?._id
                    }/timeline`,
                    { state: data?.team ? "player" : "organizer" }
                  )
                : null;
            }}
            style={{ cursor: "pointer" }}
            onLoad={() => setppicloading(false)}
            onError={() => setppicloading(false)}
            src={checkProfileImage(data)}
            alt=""
            onErrorCapture={(e) => {
              e.currentTarget.src = fallbackcover;
              e.currentTarget.style.transform = "scale(1.8)";
            }}
          />
        }
      />
      <div>
        <span className="postProfileName">
          <span
            onClick={() => {
              navigate(
                `/view/${data?.user?.sportsyouplay ? "player" : "organizer"}/${
                  data?.playerId?._id || data?.organizerId?._id
                }/timeline`,
                { state: data?.user?.sportsyouplay ? "player" : "organizer" }
              );
            }}>
            <span>{`${data?.organizerId ? "By Org: " : ""} ${checkProfileName(data)}`}</span>
          </span>
          <small
            className="postGroupName"
            onClick={() => {
              if (data?.panel?._id || data?.team?._id)
                navigate(
                  `/view/${data?.team ? "team" : "panel"}/${
                    data?.team?._id || data?.panel?._id
                  }/timeline`,
                  { state: data?.team ? "team" : "panel" }
                );
            }}>
            {data?.team ? "( TM:" + data?.team?.name + ")" : null}
            {data?.panel ? "( PN: " + data?.panel?.name + ")" : null}
          </small>
        </span>
        <p className="timelinedate">{moment(data?.createdAt).fromNow()}</p>
      </div>
    </div>
  );

  return (
    <Fragment>
      <Row className="timelineCardShadow" style={{ minWidth: "100%", height: "100%" }}>
        <PostSkelton cardloading={cardloading} ppicloading={ppicloading} />
        <Col span={24} style={cardloadingstyle}>
          <Row style={{ position: "relative", padding: "3%" }}>
            <Col span={24}>
              {UserInfo}
              {/* Event Cards*/}
              {publishedRoundPosts.isPublished && (
                <EventRoundPublishCard data={publishedRoundPosts.data} ref={ref} />
              )}
              {createdPosts.isPublished && <EventCreateCard data={createdPosts.data} ref={ref} />}
              {scheduledRoundPosts.isPublished && (
                <EventScheduleCard data={scheduledRoundPosts.data} ref={ref} />
              )}
              {compltedPosts.isPublished && (
                <EventCompleteCard data={compltedPosts.data} ref={ref} />
              )}
              {!data?.postType && (
                <p className="posttext timelineparagraph" ref={ref}>
                  {data?.text}{" "}
                </p>
              )}
            </Col>
          </Row>
          {PostMedia}
          {PostLikesCommentsComponent}
        </Col>
      </Row>
    </Fragment>
  );
});

export default FeedCard;
