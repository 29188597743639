// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/font/Poppins-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("/public/font/Poppins-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("/public/font/Poppins-SemiBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("/public/font/Poppins-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
@font-face {
  font-family: "Poppins";
  font-weight: 700;
  src: local("Poppins"), url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
}
@font-face {
  font-family: "Poppins";
  font-weight: 500;
  src: local("Poppins"), url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("truetype");
}
@font-face {
  font-family: "Poppins";
  font-weight: 600;
  src: local("Poppins"), url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("truetype");
}
@font-face {
  font-family: "Poppins";
  font-weight: 400;
  src: local("Poppins"), url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("truetype");
}
.font-face-gm {
  font-family: "Poppins";
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
`, "",{"version":3,"sources":["webpack://./src/styles/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;wEACsE;EACtE,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE,+EAA+E;AACjF;AACA;EACE,sBAAsB;EACtB,gBAAgB;EAChB,iFAA8E;AAChF;AACA;EACE,sBAAsB;EACtB,gBAAgB;EAChB,iFAAgF;AAClF;AACA;EACE,sBAAsB;EACtB,gBAAgB;EAChB,iFAAkF;AACpF;AACA;EACE,sBAAsB;EACtB,gBAAgB;EAChB,iFAAiF;AACnF;AACA;EACE,sBAAsB;AACxB;AACA;;EAEE,wBAAwB;EACxB,SAAS;AACX","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\",\n    \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\", monospace;\n}\n@font-face {\n  font-family: \"Poppins\";\n  font-weight: 700;\n  src: local(\"Poppins\"), url(\"/public/font/Poppins-Bold.ttf\") format(\"truetype\");\n}\n@font-face {\n  font-family: \"Poppins\";\n  font-weight: 500;\n  src: local(\"Poppins\"), url(\"/public/font/Poppins-Medium.ttf\") format(\"truetype\");\n}\n@font-face {\n  font-family: \"Poppins\";\n  font-weight: 600;\n  src: local(\"Poppins\"), url(\"/public/font/Poppins-SemiBold.ttf\") format(\"truetype\");\n}\n@font-face {\n  font-family: \"Poppins\";\n  font-weight: 400;\n  src: local(\"Poppins\"), url(\"/public/font/Poppins-Regular.ttf\") format(\"truetype\");\n}\n.font-face-gm {\n  font-family: \"Poppins\";\n}\ninput[type=\"number\"]::-webkit-inner-spin-button,\ninput[type=\"number\"]::-webkit-outer-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
