import React, { Fragment, useState, useEffect } from "react";
import { Row, Col, Button } from "antd";
import PanelCard from "../cards/Card";
import { GetPanelsOfOwner as GetPanelsOfOwnerApi } from "../../../../Config/API/panel";
import Loader from "../../../../common/cards/Loader";
import { error } from "../../../../common/Alerts/Message";
import NoData from "../../../../common/cards/noData";
import useVariables from "../../../../common/hooks/useVariables";

function MyPanels() {
  const [panels, setPanels] = useState([]);
  const [JoinedPanels, setJoinedPanels] = useState([]);
  const [owned, setOwned] = useState("primary");
  const [joined, setJoined] = useState("");

  const [loading, setLoading] = useState(false);
  const { viewer } = useVariables();
  var counter = 0;
  const getPanels = async () => {
    const id = viewer?.id;

    if (id) {
      try {
        counter++;
        setLoading(true);
        const response = await GetPanelsOfOwnerApi(id);
        const data = await response.json();
        console.log(data);
        if (data.type === "success") {
          setPanels(data?.result);
          setLoading(false);
          var filtered = data?.result?.filter((e) => e?.profile?._id === viewer?.id);
          setPanels(filtered);
          filtered = data?.result?.filter((e) => e?.profile?._id !== viewer?.id);
          setJoinedPanels(filtered);
        } else {
          if (counter < 2) getPanels();
          else {
            error("An error occured");
            setLoading(false);
          }
        }
      } catch (e) {
        if (counter < 2) getPanels();
        setLoading(false);
        console.log(e);
      }
    } else {
      error("Kindly Login Again to Fetch Panels");
    }
  };

  useEffect(() => {
    getPanels();
    return () => {
      setPanels(null);
      setJoinedPanels(null);
      setJoined(null);
      setOwned(null);
      setLoading(null);
    };
  }, []);

  const CardsContainer = () => (
    <Row style={{ marginTop: "15px" }}>
      {owned === "primary" ? (
        panels?.length > 0 ? (
          panels?.map((e, i) => <PanelCard key={i} type={"primary"} data={e} />)
        ) : (
          <div style={{ width: "95%", margin: "auto" }}>
            <NoData noPanel />
          </div>
        )
      ) : null}
      {joined === "primary" ? (
        JoinedPanels?.length > 0 ? (
          JoinedPanels?.map((e, i) => <PanelCard view key={i} type={"primary"} data={e} />)
        ) : (
          <div style={{ width: "95%", margin: "auto" }}>
            <NoData noPanel />
          </div>
        )
      ) : null}
    </Row>
  );

  return (
    <Fragment>
      <Row className="rowPadding" justify="center">
        {loading && <Loader fullpage loading="white" />}
        <Col xs={24} sm={24} md={24} lg={14} xl={16}>
          <Row className="mdWidth">
            <Col lg={14} md={20} sm={20} xs={24}>
              <Button
                style={{ marginLeft: "0", marginTop: ".2rem" }}
                className="buttonpo"
                onClick={() => {
                  setOwned("primary");
                  setJoined("");
                }}
                type={owned}>
                My Panels
              </Button>
              <Button
                style={{ marginLeft: "0", marginTop: ".2rem" }}
                className="buttonpo"
                type={joined}
                onClick={() => {
                  setOwned("");
                  setJoined("primary");
                }}>
                Joined Panels
              </Button>
            </Col>

            {loading ? (
              <Loader />
            ) : (
              <Col span={24}>
                <CardsContainer />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </Fragment>
  );
}

export default MyPanels;
