import React from "react";
import { IoFilterSharp } from "react-icons/io5";
import { Typography } from "antd";

const { Title } = Typography;
function FilterBar({ active, setactive }) {
  return (
    <div className="FilterBar settingsComponent" style={{ height: "100%" }}>
      <Title level={3} style={{ marginBottom: "25px" }}>
        <IoFilterSharp
          style={{
            position: "relative",
            top: "2px",
            marginRight: "15px"
          }}
        />
        Settings
      </Title>

      <Title onClick={() => setactive(1)} level={5} className={active === 1 ? "active" : undefined}>
        Profile
      </Title>

      <Title onClick={() => setactive(2)} level={5} className={active === 2 ? "active" : undefined}>
        Security and Privacy
      </Title>
    </div>
  );
}

export default FilterBar;
