import React, { useEffect, useState } from "react";
import FontFaceObserver from "fontfaceobserver";
import Loader from "../../common/cards/Loader";

function FontLoader() {
  const [loading, setloading] = useState(true);
  useEffect(() => {
    const opensans = new FontFaceObserver("Poppins");
    opensans
      .load()
      .then(() => {
        setloading(false);
      })
      .catch(() => {
        setloading(false);
      });
  }, []);
  return loading ? <Loader fullpage /> : null;
}

export default FontLoader;
