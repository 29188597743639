import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import DoubleEliminationBrackets from "./DoubleEliminationBrackets";
import SingleEliminationBrackets from "./SingleEliminationBrackets";
import { useDispatch } from "react-redux";
import {
  setRoundes,
  setfinalistrounds,
  setloserrounds
} from "../../../../../../Store/Features/kobracketslice";

function KoEliminationWrapper() {
  const { state } = useLocation();
  const type = String(state?.typeofmatch).toLowerCase();
  const condition = type === "league" || type === "knockout";
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(setRoundes([]));
      dispatch(setloserrounds([]));
      dispatch(setfinalistrounds([]));
    };
  }, [dispatch]);
  return condition ? <SingleEliminationBrackets /> : <DoubleEliminationBrackets />;
}

export default KoEliminationWrapper;
