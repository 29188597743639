/* eslint-disable */
import { MdOutlineInsertComment, MdComment, MdKeyboardArrowDown } from "react-icons/md";
import { BsHandThumbsUpFill, BsHandThumbsUp } from "react-icons/bs";
import CommentForm from "./PostComments/Components/CommentForm";
import React, { Fragment, useState, useEffect, forwardRef } from "react";
import CommentandLikes from "./PostComments";
import { Row, Col, Avatar } from "antd";
import OneImage from "../../Images/OneImage";
import { KEYS } from "../../../Config/Keys";
import VideoCard from "../Media/VideoCard";
import { useDispatch } from "react-redux";

import EventScheduleCard from "../FeedCard/EventScheduleCard";
import EventRoundPublichCard from "../FeedCard/EventRoundPublishCard";
import EventCreateCard from "../FeedCard/EventCreateCard";
import EventCompleteCard from "../FeedCard/EventCompleteCard";
import { setempty } from "../../../Store/Features/PostSlice";
import { checkProfileImage, checkProfileName, fallbackprofile, PostMeidaLoader } from "../../Utils";
import moment from "moment";
import PostSkelton from "../Loader/PostSkelton";
import { CommentReply, PostComment, PostLike, PostUnlike } from "../../../Config/API/posts";
import { error } from "../../Alerts/Message";
import useVariables from "../../hooks/useVariables";
import { useNavigate, useParams } from "react-router-dom";

const ViewerFeedCard = forwardRef(({ index, data, POSTS, setPOSTs, current }, ref) => {
  const [publishedRoundPosts, setPublishedRoundPosts] = useState({
    isPublished: false,
    data: ""
  });
  const [scheduledRoundPosts, setScheduledRoundPosts] = useState({
    isPublished: false,
    data: ""
  });
  const [createdPosts, setCreatedPosts] = useState({
    isPublished: false,
    data: ""
  });
  const [compltedPosts, setCompltedPosts] = useState({
    isPublished: false,
    data: ""
  });

  const [liked, setliked] = useState(false); //like button clicked
  var length = 0;
  data?.comments?.forEach((e) => {
    length += 1;
    e?.reply?.forEach(() => (length += 1));
  });
  const [TotalCL, setTotalCL] = useState({
    like: data?.likes?.length,
    comments: length
  }); //total comments and likes
  const [commented, setcommented] = useState(false); //comment button clicked

  const [loading, setloading] = useState(false); //for comments
  const [cardloading, setcardloading] = useState(true); //for card
  const [ppicloading, setppicloading] = useState(true); //for profile
  const [addcomment, setaddcomment] = useState("");

  const { PID, FLAG } = useVariables();
  const dispatch = useDispatch();
  const { id } = useParams();

  const navigate = useNavigate();

  // check weather you current user liked the post
  useEffect(() => {
    if (id)
      data?.likes?.forEach((e) => {
        if (e.hasOwnProperty("player")) {
          const player = e.hasOwnProperty("player") && e?.player === id;
          if (player) setliked(true);
        } else if (e.hasOwnProperty("organizer")) {
          const org = e.hasOwnProperty("organizer") && e?.organizer === id;
          if (org) setliked(true);
        } else if (e.hasOwnProperty("panel")) {
          const panell = e.hasOwnProperty("panel") && e?.panel === id;
          if (panell) setliked(true);
        } else if (e.hasOwnProperty("team")) {
          const teamm = e.hasOwnProperty("team") && e?.team === id;
          if (teamm === true) setliked(true);
        } else if (e.hasOwnProperty("user")) {
          if (e?.user === id) setliked(true);
        } else {
          setliked(false);
        }
      });
  }, [data?.likes]);

  useEffect(() => {
    let length = 0;
    data?.comments?.forEach((e) => {
      length += 1;
      e?.reply?.forEach(() => (length += 1));
    });
    setTotalCL({
      like: TotalCL?.like,
      comments: length
    });
  }, [data?.comments]);

  // post media card loader
  useEffect(() => {
    PostMeidaLoader(data, setcardloading);
  }, []);

  useEffect(() => {
    return () => {
      setloading(null);
      setliked(null);
      setTotalCL(null);
      setaddcomment(null);
      setcommented(null);
      setppicloading(null);
      dispatch(setempty());
    };
  }, []);

  useEffect(() => {
    if (data?.postType) {
      const { postType } = data;

      if (postType === "conclude" && !publishedRoundPosts.isPublished) {
        setPublishedRoundPosts({
          isPublished: true,
          data: data
        });
      } else if (postType === "create" && !createdPosts.isPublished) {
        setCreatedPosts({
          isPublished: true,
          data: data
        });
      } else if (postType === "complete" && !compltedPosts.isPublished) {
        setCompltedPosts({
          isPublished: true,
          data: data
        });
      } else if (postType === "schedule" && !scheduledRoundPosts.isPublished) {
        setScheduledRoundPosts({
          isPublished: true,
          data: data
        });
      }
    }
  }, [data?.postType]);

  const getflag = () => {
    if (current === "team") return "teamTimeline";
    else if (current === "panel") return "panelTimeline";
    else if (current === "player") return "playerTimeline";
    else if (current === "organizer") return "organizerTimeline";
  };

  const getLikeUnlikevalues = () => {
    let flag = getflag();

    let values = {
      userRole: FLAG,
      id: data?._id,
      user: PID || null,
      flag: flag || null
    };
    if (flag === "organizerTimeline") values["organizeruserId"] = id;
    else if (flag === "playerTimeline") values["playeruserId"] = id;
    else if (flag === "teamTimeline") values["teamuserid"] = id;
    else if (flag === "panelTimeline") values["paneluserid"] = id;
    return values;
  };

  const replacePostDataWithNewData = (updatedData) => {
    let updatedPosts = [...POSTS];
    updatedPosts[index] = updatedData?.[0] ?? updatedData;
    setPOSTs(updatedPosts);
  };

  // Like Post
  const LikePost = async () => {
    if (!PID || !FLAG) return error("missing user");

    let values = getLikeUnlikevalues();

    setTotalCL({ like: TotalCL?.like + 1, comments: TotalCL?.comments });
    setliked((e) => !e);

    let res = await PostLike({ ...values });
    const dataa = await res.json();
    if (dataa.type === "success") replacePostDataWithNewData(dataa?.data);
    if (dataa.type === !"success") error(data.result);
  };
  // Unlike Like Post
  const UnLikePost = async () => {
    if (!PID || !FLAG) return error("missing user");

    setTotalCL({ like: TotalCL?.like - 1, comments: TotalCL?.comments });
    setliked((e) => !e);

    let values = getLikeUnlikevalues();

    let res = await PostUnlike({ ...values });
    const dataa = await res.json();

    if (dataa.type === "success") {
      replacePostDataWithNewData(dataa?.data);
    }

    if (dataa.type === !"success") error(data.result);
  };
  // comment and reply post
  const PostCommentReply = async (obj) => {
    try {
      if (!PID || !FLAG) return error("one of the fields are missing");

      if (String(obj?.val).trim().length === 0) {
        setloading(false);
        obj?.setid("");
        return error("Add something in the text field before proceeding");
      }

      setTotalCL({
        like: TotalCL?.like,
        comments: TotalCL?.comments + 1
      });

      let flag = getflag();
      let values = {
        userRole: FLAG === "organizer" ? "Organizer" : "Player",
        id: obj?.postId || obj?.commentId,
        postId: data?._id,
        user: PID,
        comment: obj?.text || obj?.val,
        flag: flag ? flag : null
      };
      if (flag === "organizerTimeline") values["organizeruserId"] = id;
      else if (flag === "playerTimeline") values["playeruserId"] = id;
      else if (flag === "teamTimeline") values["teamuserid"] = id;
      else if (flag === "panelTimeline") values["paneluserid"] = id;

      let res = "";
      if (obj?.commentId) res = await CommentReply({ ...values });
      else if (obj?.postId) res = await PostComment({ ...values });

      setloading(true);

      const d = await res.json();
      if (d.type === "success") replacePostDataWithNewData(d?.data);
      if (data.type === "failure") error(data.result);

      setloading(false);
    } catch (e) {
      console.log(e);
      error(e.message);
      setloading(false);
    }
  };

  const cardloadingstyle = {
    opacity: cardloading || ppicloading ? "0" : "1",
    maxHeight: cardloading || ppicloading ? "0px" : "unset",
    overflowY: cardloading || ppicloading ? "hidden" : "unset"
  };

  //Post Media Files
  const PostMedia = (
    <Fragment>
      {data?.file?.type === "image" ? <OneImage src={data?.file?.file} /> : null}
      {data?.file?.type === "video" ? (
        <VideoCard
          nodefault
          className="timelineimage"
          Timeline
          src={KEYS.API + "/" + data?.file?.file}
        />
      ) : null}
    </Fragment>
  );

  // Post and Comments and Likes Section
  const PostLikesCommentsComponent = (
    <Row style={{ padding: "3%", paddingTop: "0", userSelect: "none" }}>
      <Col span={24}>
        <p className="timelinePadding">
          <img
            className="timelinelike"
            src="/images/icons/like.png"
            alt="like"
            height="20px"
            width="19px"
          />

          <span className="timelinelikesection">{TotalCL?.like}</span>
          <img
            alt="IMG"
            className="timelinelike"
            src="/images/icons/comment.png"
            height="19px"
            width="21px"
          />
          <span className="timelinelikesection">{TotalCL?.comments}</span>
        </p>

        <p className="likes_comments">
          {liked ? (
            <span onClick={UnLikePost} style={{ color: "var(--blue)" }}>
              <BsHandThumbsUpFill /> Like
            </span>
          ) : (
            <span onClick={LikePost} style={{ color: "#939393" }}>
              <BsHandThumbsUp /> Like
            </span>
          )}
          <span
            onClick={() => setcommented((e) => !e)}
            style={{ color: commented ? "var(--blue)" : "#939393" }}>
            {commented ? <MdComment /> : <MdOutlineInsertComment />}
            Comment
          </span>
        </p>

        {/* post comments */}
        {commented ? (
          <CommentandLikes
            setPOSTs={setPOSTs}
            onFinish={PostCommentReply}
            postId={data?._id}
            current={current}
            comments={data?.comments}
            loading={loading}
            setloading={setloading}
            FLAG={FLAG}
            PID={PID}
            data={data}
          />
        ) : null}
        {/* post comments Text Area */}
        <Fragment>
          {commented && (
            <span
              className={`
                    commentsDownButton 
                   ${addcomment === data?._id ? "true" : "false"}`}
              onClick={() => setaddcomment((e) => (e === data?._id ? "" : data?._id))}>
              {" Add Comment"}
              <MdKeyboardArrowDown />
            </span>
          )}
          {addcomment === data?._id && commented && (
            <CommentForm
              onFinish={PostCommentReply}
              postId={data?._id}
              loading={loading}
              status="Comment"
            />
          )}
        </Fragment>
      </Col>
    </Row>
  );

  const UserInfo = (
    <div className="avatarNameWrapper">
      <Avatar
        className="profilepic timelineavatar"
        src={
          <img
            onClick={() => {
              if (current === "team")
                navigate(`/view/player/${data?.playerId?._id}/timeline`, { state: "player" });
              else if (current === "panel")
                navigate(`/view/organizer/${data?.organizerId?._id}/timeline`, {
                  state: "organizer"
                });
            }}
            style={{ cursor: "pointer" }}
            onLoad={() => setppicloading(false)}
            onError={() => setppicloading(false)}
            src={checkProfileImage(data)}
            alt=""
            onErrorCapture={(e) => {
              e.currentTarget.src = fallbackprofile;
              e.currentTarget.style.transform = "scale(1.2)";
            }}
          />
        }
      />
      <div
        onClick={() => {
          if (current === "team")
            navigate(`/view/player/${data?.playerId?._id}/timeline`, { state: "player" });
          else if (current === "panel")
            navigate(`/view/organizer/${data?.organizerId?._id}/timeline`, { state: "organizer" });
        }}
        style={{
          fontWeight: 600,
          fontSize: "16px",
          cursor: "pointer"
        }}>
        <span>{`${data?.organizerId ? "By Org: " : ""} ${checkProfileName(data)}`}</span>
        <br />
        <small
          className="postGroupName"
          onClick={() => {
            if (data?.panel?._id || data?.team?._id)
              navigate(
                `/view/${data?.team ? "team" : "panel"}/${
                  data?.team?._id || data?.panel?._id
                }/timeline`,
                { state: data?.team ? "team" : "panel" }
              );
          }}>
          {data?.team ? "( TM:" + data?.team?.name + ")" : null}
          {data?.panel ? "( PN: " + data?.panel?.name + ")" : null}
        </small>

        <p style={{ fontWeight: "normal" }} className="timelinedate">
          {moment(data?.createdAt).fromNow()}
        </p>
      </div>
    </div>
  );

  return (
    <Fragment>
      <Row className="timelineCardShadow" style={{ minWidth: "100%", height: "100%" }}>
        <PostSkelton cardloading={cardloading} ppicloading={ppicloading} />
        <Col span={24} style={cardloadingstyle}>
          <Row style={{ position: "relative", padding: "3%" }}>
            <Col span={24}>
              {UserInfo}
              {publishedRoundPosts.isPublished && (
                <EventRoundPublichCard data={publishedRoundPosts.data} ref={ref} />
              )}
              {createdPosts.isPublished && <EventCreateCard data={createdPosts.data} ref={ref} />}
              {scheduledRoundPosts.isPublished && (
                <EventScheduleCard data={scheduledRoundPosts.data} ref={ref} />
              )}
              {compltedPosts.isPublished && (
                <EventCompleteCard data={compltedPosts.data} ref={ref} />
              )}
              {!data?.postType && (
                <p className="timelineparagraph" ref={ref}>
                  {" "}
                  {data?.text}{" "}
                </p>
              )}
            </Col>
          </Row>
          {PostMedia}
          {PostLikesCommentsComponent}
        </Col>
      </Row>
    </Fragment>
  );
  // return (
  //   <Fragment>
  //     <Row className="timelineCardShadow" style={{ minWidth: "100%" }}>
  //       {
  //         (cardloading || ppicloading) ?
  //           <div style={{ width: "100%" }}>
  //             <Skeleton style={{ padding: "2rem 1rem 1rem 1rem" }} avatar active />
  //             <Skeleton style={{ padding: "0rem 1rem 2rem 4.5rem" }} paragraph active />
  //           </div>
  //           : null
  //       }
  //       <Col span={24} style={cardloadingstyle}>

  //         <Row style={{ position: "relative", padding: "3%" }}>
  //           <Col span={24}>
  //             <div className="avatarNameWrapper">
  //               <UserInfo setloading={setppicloading} data={data} />
  //             </div>
  //             <p className="timelineparagraph">
  //               {data?.text}
  //             </p>
  //           </Col>
  //         </Row>
  //         {/* Meida Files */}
  //         <Fragment>
  //           {/* Post Image */}
  //           {
  //             data?.file?.type === "image" ?
  //               <OneImage src={data?.file?.file} /> : null
  //           }
  //           {/* Post Video */}

  //           {
  //             data?.file?.type === "video" ?
  //               <VideoCard
  //                 nodefault
  //                 className="timelineimage"
  //                 Timeline
  //                 src={KEYS.API + "/" + data?.file?.file} />
  //               : null
  //           }
  //         </Fragment>
  //         <Row style={{ padding: "3%", paddingTop: "0", userSelect: "none" }}>

  //           <Col span={24}>
  //             <p className="timelinePadding" >
  //               <img
  //                 className="timelinelike"
  //                 src="/images/icons/like.png"
  //                 alt="like"
  //                 height="20px"
  //                 width="19px"
  //               />

  //               <span className="timelinelikesection">{TotalCL?.like}</span>
  //               <img alt="IMG"
  //                 className="timelinelike"
  //                 src="/images/icons/comment.png"
  //                 height="19px"
  //                 width="21px"
  //               />
  //               <span className="timelinelikesection">{TotalCL?.comments}</span>
  //             </p>

  //             <p className="likes_comments">
  //               {
  //                 liked ?
  //                   <span onClick={unlike}
  //                     style={{ color: "var(--blue)" }}>
  //                     <BsHandThumbsUpFill />  Like
  //                   </span>
  //                   :
  //                   <span onClick={like}
  //                     style={{ color: '#939393' }}>
  //                     <BsHandThumbsUp />   Like
  //                   </span>
  //               }
  //               <span onClick={() => setcommented(e => !e)}
  //                 style={{ color: commented ? "var(--blue)" : '#939393' }}>
  //                 {commented ? <MdComment /> : <MdOutlineInsertComment />}
  //                 Comment
  //               </span>
  //             </p>

  //             {/* post comments */}
  //             {commented ?
  //               <CommentandLikes
  //                 setPOSTs={setPOSTs}
  //                 onFinish={onfinish}
  //                 data={data}
  //                 comments={data?.comments}
  //                 loading={loading}
  //                 setloading={setloading}
  //                 USER={checkrole}
  //                 current={current}
  //               />
  //               : null}
  //             {/* post comments Text Area */}
  //             <Fragment>
  //               {
  //                 commented &&
  //                 <span
  //                   className={`
  //                   commentsDownButton
  //                  ${addcomment === data?._id ? "true" : 'false'}`}
  //                   onClick={() =>
  //                     setaddcomment(e => e === data?._id ? "" : data?._id)
  //                   }>
  //                   {" Add Comment"}
  //                   <MdKeyboardArrowDown />
  //                 </span>
  //               }
  //               {
  //                 (addcomment === data?._id && commented) &&
  //                 <CommentForm
  //                   onFinish={onfinish}
  //                   postId={data?._id}
  //                   loading={loading}
  //                   status="Comment"

  //                 />
  //               }
  //             </Fragment>
  //           </Col>

  //         </Row>
  //       </Col>
  //     </Row>
  //   </Fragment >
  // );
});

export default ViewerFeedCard;
