import { useLocation, useParams } from "react-router-dom";
import { error } from "../Alerts/Message";
import {
  GetOrganizerMediaApi,
  GetPanelMediaApi,
  GetPlayerMediaApi,
  GetTeamMediaApi
} from "../../Config/API/Media";
import useVariables from "../hooks/useVariables";

export const useGetMedia = () => {
  const { id } = useParams();
  const { pathname } = useLocation();
  const isPanel = pathname.includes("panel");
  const isTeam = pathname.includes("team");
  const isPlayer = pathname.includes("player") && !pathname.includes("team");
  const isOrganizer = pathname.includes("organizer") && !pathname.includes("panel");
  let counter = 0;

  const { viewer } = useVariables();
  const GetMedia = async (setMedia, setloading, current) => {
    try {
      counter++;
      let res;
      const pid = viewer?.id;
      setloading(true);
      if (isPanel || current === "panel") {
        console.log("panel media request");
        res = await GetPanelMediaApi(id);
        const data = await res?.json();
        if (data?.type === "success") {
          setMedia(data?.result);
          if (data?.result?.length > 0) {
            console.log("panel media fetched");
          }
        } else error("An error occured");
      } else if (isTeam || current === "team") {
        console.log("team media request");
        res = await GetTeamMediaApi(id);
        const data = await res?.json();
        if (data?.type === "success") {
          setMedia(data?.result);
          if (data?.result?.length > 0) {
            console.log("team media request");
          }
        } else error("An error occured");
      } else if (isPlayer || current === "player") {
        console.log("player media request", id || pid);
        res = await GetPlayerMediaApi(id || pid);
        const data = await res?.json();
        if (data?.type === "success") {
          setMedia(data?.result);
          if (data?.result?.length > 0) {
            console.log("player media fetched");
          }
        } else error("An error occured");
      } else if (isOrganizer || current === "organizer") {
        console.log("organizer media request", id || pid);
        res = await GetOrganizerMediaApi(id || pid);
        const data = await res?.json();
        if (data?.type === "success") {
          setMedia(data?.result);
          if (data?.result?.length > 0) {
            console.log("organizer media fetched");
          }
        }
        if (counter < 2) GetMedia();
        else error("An error occured");
      }

      setloading(false);
    } catch (e) {
      if (counter < 2) GetMedia();
      else {
        setloading(false);
        console.log(e);
        error(e);
      }
    }
  };

  return { GetMedia };
};
