import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  matches: {},
  showQaulify: false,
  loading: false,
  loadingbtns: false,
  showQaulifylen: 0,
  roundvariables: {}, //multiplayer
  eventcomplete: false, //multipalyer
  currntgroupindex: {
    //multipalyer
    gindex: 0,
    submatchindex: 0,
    currentourndindex: 0
  }
};

export const MatchesSlice = createSlice({
  name: "matches",
  initialState,
  reducers: {
    setMatches: (state, action) => {
      state.matches = action.payload;
    },
    setMatchObj: (state, action) => {
      state.showQaulify = action.payload.quailfy;
      state.matches = action.payload.matches;
      state.showQaulifylen = action.payload.len;
    },
    load: (state) => {
      state.loading = true;
    },
    unload: (state) => {
      state.loading = false;
    },
    setloadingbtns: (state, action) => {
      state.loadingbtns = action.payload;
    },
    setroundvariables: (state, action) => {
      state.roundvariables = action.payload;
    },
    seteventcomplete: (state, action) => {
      state.eventcomplete = action.payload;
    },
    setcurrentgroupindex: (state, action) => {
      state.currntgroupindex = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  setMatches,
  load,
  unload,
  setMatchObj,
  setloadingbtns,
  setroundvariables,
  seteventcomplete,
  setcurrentgroupindex
} = MatchesSlice.actions;
export const MATCHES = (state) => state.matches.matches;
export const isLoading = (state) => state.matches.loading;
export const showQaulify = (state) => state.matches.showQaulify;
export const showQaulifylen = (state) => state.matches.showQaulifylen;
export default MatchesSlice.reducer;
