import { useState, useEffect } from "react";
import {
  GetOrganizerPosts,
  GetPlayerPosts,
  GetPanelPosts,
  GetTeamPosts
} from "../../Config/API/OtherUser";

import { error } from "../Alerts/Message";

export function useFetchOthersPosts(id, state) {
  const [postloading, setpostloading] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(1);
  const GetPosts = async (controller) => {
    try {
      if (!id) {
        setpostloading(false);
        setTimeout(() => {
          GetPosts();
        }, 1000);
      }
      let response, data;
      if (state === "player") {
        response = await GetPlayerPosts(id, controller);
        data = await response.json();
      }
      if (state === "team") {
        response = await GetTeamPosts(id, controller);
        data = await response.json();
      } else if (state === "organizer") {
        response = await GetOrganizerPosts(id, controller);
        data = await response.json();
      } else if (state === "panel") {
        response = await GetPanelPosts(id, controller, page);
        data = await response.json();
      }
      if (data?.type === "success") {
        setPosts(data?.result || data?.data || []);
        setTotalPages(data?.metaData?.totalPages || 1);
      }
      setpostloading(false);
    } catch (e) {
      setpostloading(false);
      // navigate(-1)
      error(String(e?.message || e));
      console.log(e);
    }
  };
  useEffect(() => {
    const controller = new AbortController();
    GetPosts(controller);
    return () => {
      controller.abort();
      setpostloading(null);
      setPosts(null);
    };
  }, [id, page]);

  return {
    posts,
    setPosts,
    postloading,
    totalPages,
    page,
    setPage
  };
}
