import React, { useEffect, useState } from "react";
import { Avatar, Breadcrumb, Col, List, Row, Typography } from "antd";
import ActivityForm from "./ActivityForm";
import { error, success } from "../../../common/Alerts/Message";
import {
  SponsorAddActivity,
  SponsorDeleteActivity,
  SponsorUpdateActivity
} from "../../../Config/API/sponsor";
import { MdDelete } from "react-icons/md";
import dayjs from "dayjs";

function CampaignActivity({ campaign, panels }) {
  const [current, setCurrent] = useState(0);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const newActivity = {
    name: "",
    activityType: ""
  };
  const [activities, setActivities] = useState([newActivity]);

  const handleAddUpdateActivity = async (values, { resetForm }) => {
    setLoading(true);
    const isEdit = values?.activityId;

    try {
      let response = null;
      let data = null;
      if (isEdit) {
        // update activity
        response = await SponsorUpdateActivity({
          ...values,
          time: values?.time ? dayjs(values?.time).format("hh:mm") : ""
        });
        data = await response.json();
      } else {
        // delete activityId key from payload incase of add
        delete values?.activityId;
        // add activity
        response = await SponsorAddActivity({
          ...values,
          time: values?.time ? dayjs(values?.time).format("hh:mm") : ""
        });
        data = await response.json();
      }
      if (data?.type === "success") {
        let updatedActivities = [...activities];

        if (isEdit) {
          updatedActivities[current] = {
            ...updatedActivities[current],
            ...data?.activity
          };
        } else {
          updatedActivities = updatedActivities?.filter(
            (activity) => activity?.name !== "" && activity?.activityType !== ""
          );
          updatedActivities = [...updatedActivities, data?.activity];
          updatedActivities.push(newActivity);
        }

        setActivities(updatedActivities);
        setCurrent(current + 1);
        success(`Activity ${isEdit ? "updated" : "added"} successfully`);
        setLoading(false);
        resetForm();
      } else {
        setLoading(false);
        error("Error occurred while adding/updating activity");
      }
    } catch (e) {
      setLoading(false);
      error(e.message);
    }
  };

  const handleDeleteActivity = async (id) => {
    try {
      setDeleteLoading(true);
      const response = await SponsorDeleteActivity(id);
      const data = await response.json();
      if (data?.type === "success") {
        const updated = activities?.filter((activity) => activity?._id !== id);
        setActivities(updated);
        success("Activity Deleted Successfully");
      }
      setDeleteLoading(false);
    } catch (error) {
      setDeleteLoading(false);
      error(error.message);
    }
  };

  useEffect(() => {
    if (campaign && campaign?.activities) {
      setActivities([...campaign.activities, newActivity]);
    }
  }, [campaign?.activities]);

  return (
    <div style={{ marginTop: "20px", padding: "10px 40px" }}>
      <Row>
        <Col span={10}>
          <h1>Campaign Name</h1>
          <p>{campaign?.name}</p>
        </Col>
        <Col span={10}>
          <h1>Campaign Description</h1>
          <p>{campaign?.description}</p>
        </Col>
      </Row>
      <hr className="mt-5" />
      {/* Render the form or activity content based on the current activity */}
      <Breadcrumb separator=">" style={{ padding: "0 0 2% 2.5%" }}>
        {activities?.map((activity, index) => {
          return (
            <Breadcrumb.Item
              key={index}
              className={"activecrumb"}
              onClick={() => setCurrent(index)}>
              <span style={{ cursor: "pointer" }}>{activity?.name || "New"}</span>
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb>
      <ActivityForm
        handleSubmit={handleAddUpdateActivity}
        loading={loading}
        campaignId={campaign?._id}
        panels={panels}
        viewActivity={activities?.[current]}
      />

      <hr className="mt-5 mb-5" />
      <Typography.Title type="secondary">Added Activities</Typography.Title>
      <Col xs={24} md={12}>
        <List
          itemLayout="horizontal"
          dataSource={activities?.filter((activity) => activity?._id)}
          renderItem={(item) => (
            <List.Item
              aria-disabled={deleteLoading ? true : false}
              actions={[
                <span
                  className="no-style-link"
                  key="delete-activity"
                  onClick={deleteLoading ? null : () => handleDeleteActivity(item?._id)}>
                  Delete <MdDelete />
                </span>
              ]}>
              <List.Item.Meta
                avatar={<Avatar />}
                title={item?.name}
                description={`Activity Type: ${item?.activityType}`}
              />
            </List.Item>
          )}
        />
      </Col>
    </div>
  );
}

export default CampaignActivity;
